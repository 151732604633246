import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { OrderDetailService, } from '../order-detail.service';
import { PricingBreakdown, PricingBreakdownItem } from "../../../../../../util/pricing";
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from '../../../resources/product';
import { take } from 'rxjs/operators';
import { arrayContentEquals } from '@mobiscroll/angular/dist/js/core/util/misc';

type PricingItem = { name: string, price: number | null, unitPrice: number | null };

@Component({
  selector: 'order-detail-pricing-breakdown',
  templateUrl: './order-detail-pricing-breakdown.component.html',
  styleUrls: ['./order-detail-pricing-breakdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailPricingBreakdownComponent implements OnInit, OnDestroy {

  constructor(private service: OrderDetailService) { }
  public get record() { return this.service.order }
  public get products() { return this.record.products.map(p => p.product) }

  public topLevelIds: string[];
  public topLevelProducts: { [key: string]: Observable<Product> };
  public topLevelQtys: { [key: string]: BehaviorSubject<number> };
  public toggles: { [key: string]: BehaviorSubject<boolean> };
  public breakdowns: { [key: string]: BehaviorSubject<PricingBreakdown> };
  public productTotals: { [key: string]: BehaviorSubject<number> };

  ngOnInit(): void {
    this.topLevelProducts = {};
    this.toggles = {};
    this.topLevelQtys = {};
    this.breakdowns = {};
    this.productTotals = {};

    // Make sure these preserve order, they get shuffled in the topLevelProducts object
    this.topLevelIds = this.products.map((p) => p.productId);

    // Collect the observables for the top-level products only. We need these immediately
    for (let product of this.products) {
      this.topLevelProducts[product.productId] = this.service.getProductObservable(product.productId);

      if (!product.quantitiesMap || !product.quantitiesMap.length) {
        // If we're missing a quantitiesMap, this will crash when calculating quantities later, so set some reasonable defaults
        product.quantitiesMap = [{value: 1, markup: 18, showOnQuote: true}];
      }

      // Set up BehaviorSubjects for each top-level qty map for the button-toggle-group
      this.topLevelQtys[product.productId] = new BehaviorSubject<number>(product.quantitiesMap[0].value);
    }
  }

  public checkToggle(id: string, section: string = '', index: number = -1): BehaviorSubject<boolean> {
    const handle = this.makeHandle(id, section, index);

    if (!(handle in this.toggles)) {
      this.toggles[handle] = new BehaviorSubject<boolean>(false);
    }

    return this.toggles[handle];
  }

  public swapToggle(id: string, section: string = '', index: number = -1) {
    const handle = this.makeHandle(id, section, index);

    if (!(handle in this.toggles)) {
      // Should be impossible to trigger a swap on a toggle that was never checked
      return;
    }

    this.toggles[handle].next(!this.toggles[handle].value);
  }

  public getBreakdown(product: Product, qty: number): BehaviorSubject<PricingBreakdown> {
    const handle = this.makeHandle(product.productId, `${qty}`);

    if (!(handle in this.breakdowns)) {
      const newBreakdown = this.service.getPriceBreakdown(product, qty);
      this.breakdowns[handle] = new BehaviorSubject<PricingBreakdown>(newBreakdown);
    }

    return this.breakdowns[handle];
  }

  public getProductTotal(product: Product, qty: number) {
    const handle = this.makeHandle(product.productId, `${qty}`);

    if (!(handle in this.productTotals)) {
      this.setProductTotal(product, qty);
    }

    return this.productTotals[handle];
  }

  public getChildrenTotal(product: Product, qty: number) {
    let total = 0;

    for (const child of product.childAssemblies) {
      total += this.getProductTotal(child, qty).value;
    }

    return total;
  }

  public setProductTotal(product: Product, qty: number) {
    const handle = this.makeHandle(product.productId, `${qty}`);

    let total = 0;

    // Get direct costs
    const directBreakdown = this.getBreakdown(product, qty).value;

    total += directBreakdown.material?.price ?? 0;
    total += directBreakdown.purchasedItems?.reduce((acc, cur) => acc + cur.price, 0) ?? 0;
    total += directBreakdown.process?.reduce((acc, cur) => acc + cur.price, 0) ?? 0;
    total += directBreakdown.labor?.reduce((acc, cur) => acc + cur.price, 0) ?? 0;

    // Get subassembly costs
    total += this.getChildrenTotal(product, qty);

    if (!(handle in this.productTotals)) {
      this.productTotals[handle] = new BehaviorSubject<number>(total);
    } else {
      this.productTotals[handle].next(total);
    }
  }


  public getCategoryPrice(breakdown: PricingBreakdownItem[]) {
    return breakdown.reduce((acc, cur) => acc + cur.price, 0);
  }

  public getIndent(indent: number) {
    return `${indent*2}%`;
  }

  private makeHandle(id: string, section: string = '', index: number = -1) {
    let handle = id;

    if (section.length)
      handle += '+' + section;

    if (index >= 0)
      handle += '+' + index;

    return handle;
  }

  ngOnDestroy() {
    for (const key in this.topLevelQtys) {
      this.topLevelQtys[key].complete();
    }

    for (const key in this.toggles) {
      this.toggles[key].complete();
    }

    for (const key in this.breakdowns) {
      this.breakdowns[key].complete();
    }

    for (const key in this.productTotals) {
      this.productTotals[key].complete();
    }
  }

  private currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping: false,
  });
  // CSV generation
  public breakdownToArray(product: Product): PricingItem[] {
    let array: PricingItem[] = [];
    let total: number = 0;
    const topLevelQty = this.topLevelQtys[product.topParentAssemblyId]?.value ?? 1;
    array.push({ name: '', price: null, unitPrice: null });
    const totalQuantity = this.service.getTotalQuantity(product, topLevelQty);
    array.push({ name: `${product.partNumber} Rev. ${product.revision} (total qty: ${totalQuantity})`, price: null, unitPrice: null });
    const breakdown = this.getBreakdown(product, topLevelQty)?.value;
    if (!breakdown) return;

    // Material
    if (breakdown.material) {
      const preMarkupPriceItem = breakdown.material.subitems.find(si => si.id === 'PRE_MARKUP');
      array.push({ name: `Material (${breakdown.material.name})`, price: preMarkupPriceItem.value, unitPrice: preMarkupPriceItem.value / totalQuantity })
      total += breakdown.material?.price ?? 0;
      const materialMarkupItem = breakdown.material.subitems.find(si => si.id === 'MARKUP_AMT');
      array.push({ name: `Material Markup of ${product.materialMarkup ?? 0}%`, price:materialMarkupItem.value, unitPrice:materialMarkupItem.value / totalQuantity })
    }
    // Purchased items
    if (breakdown.purchasedItems) {
      for (const item of breakdown.purchasedItems) {
        const preMarkupItem = item.subitems.find(si => si.id === 'PARENT_QUANTITY_MULT');
        array.push({
          name: item.name, price: preMarkupItem.value, unitPrice: item.price / totalQuantity
        });
        const markupPercentItem = item.subitems.find(si => si.id === 'MARKUP_PCT');
        const markupAmountItem = item.subitems.find(si => si.id === 'CALC_MARKUP');
        array.push({
          name: `Markup of ${markupPercentItem.value}%`, price: markupAmountItem.value, unitPrice: markupAmountItem.value / totalQuantity
        });
      }
      const purchasedTotal = this.getCategoryPrice(breakdown.purchasedItems);
      total += purchasedTotal;
      // array.push({ name: 'Purchased Items Total', price: purchasedTotal })
    }
    // Outside Process
    if (breakdown.process) {
      for (const item of breakdown.process) {
        const preMarkupItem = item.subitems.find(si => si.id === 'QUANTITY_MULT');
        array.push({
          name: item.name, price: preMarkupItem.value, unitPrice: preMarkupItem.value / totalQuantity
        });
        const markupPercentItem = item.subitems.find(si => si.id === 'MARKUP_PCT');
        const markupAmountItem = item.subitems.find(si => si.id === 'CALC_MARKUP');
        array.push({
          name: `Markup of ${markupPercentItem.value}%`, price: markupAmountItem.value, unitPrice: markupAmountItem.value / totalQuantity
        });
      }
      const processTotal = this.getCategoryPrice(breakdown.process);
      total += processTotal;
      // array.push({ name: 'Purchased Items Total', price: processTotal })
    }
    // Labor
    if (breakdown.labor) {
      for (const item of breakdown.labor) {
        array.push({
          name: item.name, price: item.price, unitPrice: item.price / totalQuantity
        });
      }
      const laborTotal = this.getCategoryPrice(breakdown.labor);
      total += laborTotal;
      // array.push({ name: 'Purchased Items Total', price: laborTotal })
    }
    // Subassemblies
    for (const subassembly of product.childAssemblies) {
      const subArray = this.breakdownToArray(subassembly);
      const subTotal = subArray[subArray.length - 1]?.price ?? 0;
      array = array.concat(subArray);
      total += subTotal;
    }
    if (product.parentAssemblyId) {
      array.push({ name: `Subassembly total for ${product.partNumber} Rev. ${product.revision}`, price: total, unitPrice: total / totalQuantity });
    } else {
      const markup = product.quantitiesMap.find(q => q.value === topLevelQty)?.markup;
      if (markup) {
        array.push({ name: `Assembly subtotal for ${product.partNumber} Rev. ${product.revision}`, price: total, unitPrice: total / totalQuantity });
        const markupAmount = total * (markup / 100);
        array.push({ name: `Markup of ${markup}%`, price: markupAmount, unitPrice: null });
        array.push({ name: `Assembly total for ${product.partNumber} Rev. ${product.revision}`, price: total + markupAmount, unitPrice: (total + markupAmount) / totalQuantity });
      } else {
        array.push({ name: `Assembly total for ${product.partNumber} Rev. ${product.revision}`, price: total, unitPrice: total / totalQuantity });
      }
    }
    return array;
  }

  public generateCsv(): string {
    let array: PricingItem[] = [];
    for (let product of this.products) {
      array = array.concat(this.breakdownToArray(product));
    }
    let csvHeader = "data:text/csv;charset=utf-8;base64,";

    let csvContent = "Item,Price,Unit Price\r\n";
    array.forEach(a => {
      const escapedName = a.name.replaceAll('"', '""');
      csvContent += `${escapedName},${a.price === null ? '' : this.currencyFormatter.format(a.price)},${a.unitPrice === null ? '' : this.currencyFormatter.format(a.unitPrice)}`;
      csvContent += '\r\n';
    });

    return csvHeader + btoa(csvContent);
  }

  public exportCsv() {
    var encodedUri = this.generateCsv();
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "breakdown.csv");
    const e = document.body.appendChild(link);
    link.click();
    e.remove();
  }

}
