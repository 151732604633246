import { Component, Input, OnInit, TemplateRef, ViewChild, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MaterialService } from '../../../order/services/material.service';
import { DimensionType, DimensionUnit, MaterialType, MaterialTypeDimension } from '../../../order/resources/material';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MaterialDimensionTypeUnitSelectComponent } from '../material-dimension-type-unit-select/material-dimension-type-unit-select.component';
import { VolumeFormulaComponent } from '../../../common/components/volume-formula/volume-formula.component';

@Component({
  selector: 'material-type-editor',
  templateUrl: './material-type-editor.component.html',
  styleUrls: ['./material-type-editor.component.less']
})
export class MaterailTypeEditorComponent implements OnInit {
  public selected: MaterialType = null;
  public saving: boolean = false;
  @Input() sidenav: MatSidenav;
  @Input() forSearch = false;
  @Output() typeCreated = new EventEmitter<MaterialType>();
  @Output() forSearchCancel = new EventEmitter<void>();

  constructor(private navService: NavigationService, private materialSvc: MaterialService, private utilitySvc: UtilityService, private router: Router, private dialog: MatDialog) {
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public select(material: MaterialType): void {
    this.selected = material;
    if (this.selected.displayTemplate) this.validateDisplayTemplate();
  }

  public add(): void {
    this.selected = <MaterialType>{
      materialTypeId: UtilityService.emptyGuid,
      materialTypeDimensions: [],
      name: "",
      volumeFormula: "",
      displayTemplate: "",
    };
  }

  failedValidation = false;
  @ViewChildren('dimensionSelector') dimensionSelectorComponents: QueryList<MaterialDimensionTypeUnitSelectComponent>;
  public async save() {
    // validate
    const validations = this.dimensionSelectorComponents.toArray().map(c => c.validate());
    const failed = validations.some(x => x === false);
    this.failedValidation = failed;
    if (failed) return;

    this.saving = true;

    // const saveList = JSON.parse(JSON.stringify(this.selected.materialTypeDimensions, (key, value) => {
    //   if (key === 'materialType') {
    //     return null;
    //   } else {
    //     return value;
    //   }
    // }));

    try {
      const resultMaterialType = await this.materialSvc.saveMaterialType(this.selected).toPromise();
      if (!resultMaterialType) throw new Error();
      this.getDimensionData();
      // const record = await this.materialSvc.bulkSaveMaterialTypeDimensions(saveList, resultMaterialType).toPromise();
      this.selected = resultMaterialType;
      this.typeCreated.emit(resultMaterialType);
    } catch (e) {
      this.utilitySvc.showAlert("Failed to Save Material Form", "The server encountered an error saving the material form. This may be caused by the formula causing an error only on the server side. It could be a bug.");
    } finally {
      this.saving = false;
    }
  }


  public canRemove(): boolean {
    return this.selected != null && this.selected.materialTypeId != UtilityService.emptyGuid;
  }

  public remove(): void {
    this.utilitySvc.showConfirmation(`Remove Material Form '${this.selected.name} '?`, "<p>Are you sure you want to remove this material form?</p><p class='text-muted'>This is a global change that can affect multiple materials, quotes and workorders!.</p>", r => {
      if (r) {
        this.saving = true;
        this.materialSvc.removeMaterialType(this.selected.materialTypeId).subscribe(_ => { this.saving = false; this.selected = null; });
      }
    });
  }

  public addDimensionTypeUnit() {
    const newPair = <MaterialTypeDimension>{
      materialTypeDimensionId: UtilityService.emptyGuid,
      materialTypeId: this.selected.materialTypeId,
    };
    this.selected.materialTypeDimensions.push(newPair);
    this.volumeFormulaPicker.testFormula();
  }

  public dimensionDeleted(index: number) {
    this.selected.materialTypeDimensions.splice(index, 1);
    this.volumeFormulaPicker.testFormula();
  }

  public dimensionDataLoading = false;
  public dimensionTypes: DimensionType[] = [];
  public dimensionUnits: DimensionUnit[] = [];
  public async getDimensionData() {
    this.dimensionDataLoading = true;
    const [types, units] = await Promise.all([
      this.materialSvc.getDimensionTypes().toPromise(),
      this.materialSvc.getDimensionUnits().toPromise()
    ]);
    this.dimensionTypes = types.results;
    this.dimensionUnits = units.results;
    this.dimensionDataLoading = false;
  }

  ngOnInit(): void {
    if (!this.forSearch) {
      this.navService.clearBreadCrumbs();
      this.navService.pushBreadcrumb("Material Forms");
    }
    this.getDimensionData();
  }

  onDimensionUpdated() {
    if (this.volumeFormulaPicker) this.volumeFormulaPicker.testFormula();
  }

  private newDialogRef?: MatDialogRef<any, any>;

  // New Dimension Type dialog
  @ViewChild('newDimensionTypeDialog', { static: true }) newDimensionTypeDialog: TemplateRef<any>;
  public newDimensionType: DimensionType;
  openNewDimensionType(label: string, indexToSet: number) {
    this.newDimensionType = <DimensionType>{
      dimensionTypeId: UtilityService.emptyGuid,
      label,
    };
    this.newDialogRef = this.dialog.open(this.newDimensionTypeDialog, {
      disableClose: true,
      data: {
        indexToSet,
      }
    });
  }
  async saveNewDimensionType(indexToSet: number) {
    this.newDialogRef.close();
    const x: DimensionType = JSON.parse(JSON.stringify(this.newDimensionType))
    this.dimensionTypes = [...this.dimensionTypes, x];
    this.selected.materialTypeDimensions[indexToSet].dimensionType = x;
    this.selected.materialTypeDimensions[indexToSet].dimensionTypeId = x.dimensionTypeId;
  }

    // New Dimension Unit dialog
    @ViewChild('newDimensionUnitDialog', { static: true }) newDimensionUnitDialog: TemplateRef<any>;
    public newDimensionUnit: DimensionUnit;
    openNewDimensionUnit(label: string, indexToSet: number) {
      this.newDimensionUnit = {
        dimensionUnitId: UtilityService.emptyGuid,
        label,
        abbreviation: '',
        siEquivalent: 0,
      };
      this.newDialogRef = this.dialog.open(this.newDimensionUnitDialog, {
        disableClose: true,
        data: {
          indexToSet,
        }
      });
    }
    async saveNewDimensionUnit(indexToSet: number) {
      this.newDialogRef.close();
      const x: DimensionUnit = JSON.parse(JSON.stringify(this.newDimensionUnit));
      this.dimensionUnits = [...this.dimensionUnits, x];
      this.selected.materialTypeDimensions[indexToSet].dimensionUnit = x;
      this.selected.materialTypeDimensions[indexToSet].dimensionUnitId = x.dimensionUnitId;
    }

    public displayTemplateExample: string;
    public validateDisplayTemplate() {
      this.displayTemplateExample = null;
      const result = this.selected.displayTemplate.replace(/{(.+?)}/g, (match, group) => {
        const matchingDimension = this.selected.materialTypeDimensions.find(mtd =>
          mtd.dimensionType.label === group
        );
        if (!matchingDimension) return match;
        else return '1.500';
      });

      this.displayTemplateExample = result;

    }

    @ViewChild('volumeFormula') volumeFormulaPicker: VolumeFormulaComponent;
    get volumeFormulaIsValid(): boolean {
      return this.volumeFormulaPicker ? !this.volumeFormulaPicker.error : false;
    }

}
