<div class="rounded mat-elevation-z4 overflow-hidden" *ngIf="product$ | async as prodObTest">
  <!-- <div class="mat-dialog-title p-2 mb-2 px-4">{{ product.partNumber }} Rev. {{ product.revision }} </div> -->
  <table mat-table #matTable cdkDropList (cdkDropListDropped)="drop(prodObTest, $event)" [dataSource]="dataSource" class="w-100 quantity-table bg-transparent" [class.mat-table-no-bottom-border]="!editing" style="
    table-layout: fixed;
  ">


    <ng-container matColumnDef="masterFill">
      <td mat-cell colspan="3" *matHeaderCellDef class="text-center">
        <div class="text-muted">—</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="masterFill2">
      <td mat-cell colspan="4" *matHeaderCellDef class="text-center">
        <div class="text-muted">—</div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="masterMarkup">
      <td mat-cell class="pr-1" *matHeaderCellDef class="markup-input-width">
        <mat-form-field appearance="outline" class="markup-input-width dense-field mat-input-no-message rev-field">
          <input placeholder="—" matInput [readonly]="!editing" type="number" step="0.1"
            [ngModel]="getMasterMarkup(prodObTest)"
            (ngModelChange)="setMasterMarkup(prodObTest, $event)"
          >
          <span class="text-muted" matSuffix>%</span>
        </mat-form-field>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="dragHandle">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pr-0">
        <mat-icon class="text-muted drag-handle" cdkDragHandle [class.invisible]="!editing">
          drag_handle
        </mat-icon>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef style="width: 72px;">Quantity</th>
      <td mat-cell *matCellDef="let row" class="pr-1">
        <span class="font-weight-medium">{{ row.value }}</span>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef>Cost</th>
      <td mat-cell *matCellDef="let row" class="pr-1">
        <div *ngIf="getCostForQuantity(prodObTest, row) | async | currency as val; else loading">
          {{ val }}
        </div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="markup">
      <th mat-header-cell *matHeaderCellDef style="width: 92px">Markup</th>
      <td mat-cell *matCellDef="let row" class="pr-2">
        <mat-form-field appearance="outline" class="dense-field mat-input-no-message rev-field markup-input-width">
          <input placeholder="0" matInput [readonly]="!editing || row.priceOverride" type="number" step="0.1" [(ngModel)]="row.markup" (ngModelChange)="service.notifyEdited(prodObTest)">
          <span class="text-muted" matSuffix>%</span>
        </mat-form-field>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="calcPrice">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let row" class="pr-1">
        <div *ngIf="getPriceForQuantity(prodObTest, row) | async | currency as val; else loading">
          <mat-form-field appearance="outline" class="dense-field mat-input-no-message w-100" [class.cursor-pointer]="editing" matTooltipPosition="above" [matTooltip]="editing ? 'Double-click to override' : undefined"
            (dblclick)="editing && editPriceOverride(prodObTest, row)"
          >
            <input matInput [readonly]="true" type="text" min="0" step="0.1" [ngModel]="val" [class.cursor-pointer]="editing" [class.font-weight-bold]="row.priceOverride">
          </mat-form-field>
        </div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="unitPrice">
      <th mat-header-cell *matHeaderCellDef>Unit Price</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="getUnitPriceForQuantity(prodObTest, row) | async | currency as val; else loading">
          {{ val }}
        </div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="extPrice">
      <th mat-header-cell *matHeaderCellDef>Ext. Price</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="getExtPriceForQuantity(prodObTest, row) | async | currency as val; else loading">
          {{ val }}
        </div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let row" class="pr-1">
        <button mat-icon-button (click)="deleteQuantity(prodObTest, row)" *ngIf="editing">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-footer-row *matHeaderRowDef="['masterFill', 'masterMarkup', 'masterFill2']" style="background-color: #efefef"></tr>
    <tr mat-row cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="!editing" *matRowDef="let row, let i = index; columns: displayedColumns">
      <div class="rounded mat-elevation-z3 px-3 bg-white" *cdkDragPreview [matchSize]="true" style="height: 56px; line-height: 56px; font-size: 14px; font-weight: 500; min-width: 300px; left: -28px;">
        {{ row.value }}
      </div>
      <div *cdkDragPlaceholder style="display: table-row">
        <div style="display: table-cell" *ngFor="let col of displayedColumns" class="bg-primary" style="display: table-cell; height: 56px">
        &nbsp;
        </div>
      </div>
    </tr>

    <tr mat-row *matRowDef="let row; columns: ['dragFill', 'stepName', 'stepPrice']; when: isStep"></tr>
    <ng-container matColumnDef="dragFill">
      <td mat-cell *matCellDef="let row">
        &nbsp;
      </td>
    </ng-container>
    <ng-container matColumnDef="stepName">
      <td mat-cell *matCellDef="let row" colspan="5">
        <span class="font-weight-medium">{{ row.name }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="stepPrice">
      <td mat-cell *matCellDef="let row" colspan="2">
        <span>{{ row.price | currency }}</span>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="addInput">
      <td mat-cell colspan="7" *matFooterCellDef class="border-0">
        <mat-form-field appearance="outline" class="w-100 dense-field mat-input-no-message rev-field">
          <input placeholder="Add Quantity" matInput [readonly]="!editing" type="number" min="1" (keydown)="preventNegative($event)" (keydown.enter)="addQuantity(prodObTest, addingQuantity)" [(ngModel)]="addingQuantity">
        </mat-form-field>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="addButton">
      <td mat-cell *matFooterCellDef class="px-2 border-0">
        <button class="mat-square-button" mat-flat-button color="primary" (click)="addQuantity(prodObTest)" [disabled]="!addingQuantity">
          <mat-icon>add</mat-icon>
        </button>
      </td>
    </ng-container>
    
    <tr mat-footer-row *matFooterRowDef="['addInput', 'addButton']" [class.d-none]="!editing"></tr>
  </table>
</div>
<ng-template #loading>
  <div>
    <i class="fa fa-spin fa-circle-notch text-primary"></i>
  </div>
</ng-template>

<ng-template #overridePriceDialog let-data>
  <h2 matDialogTitle>Override Price</h2>
  <mat-dialog-content>
    <p>Please provide a modified price for quantity <b>{{data.qty.value}}</b> of part <b>{{data.product.partNumber}} Rev. {{data.product.revision}}</b> and notes/reasoning for it.</p>
    <mat-form-field class="w-100">
      <mat-label>Price</mat-label>
        <span matPrefix>$</span>
        <input matInput type="number" [(ngModel)]="data.newPrice" [placeholder]="data.basePrice | number">
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Notes</mat-label>
      <textarea matInput [(ngModel)]="data.note"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="secondary">Cancel</button>
    <button mat-button matDialogClose color="secondary" (click)="setOverride(data.product, data.qty.value, null, '')">Clear</button>
    <button mat-button matDialogClose color="danger" [disabled]="!data.newPrice || !data.note" (click)="setOverride(data.product, data.qty.value, data.newPrice, data.note)">Confirm</button>
  </mat-dialog-actions>
</ng-template>