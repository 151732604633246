import { Component, EventEmitter, Output, Input, ViewChild, ElementRef, OnInit, TemplateRef, forwardRef, Inject, Injector } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { Vendor } from '../../../supplier/resources/vendor';
import { PurchasingService } from '../../services/purchasing.service';
import { VendorService } from '../../../supplier/services/vendor.service';
import { UserService } from '../../../common/services/user.service';
import { GenericSearchComponent } from '../../../common/components/generic-search/generic-search.component';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '../../../common/services/utility.service';
import { Customer } from '../../../customer/resources/customer';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
  selector: 'filtered-vendor-list',
  templateUrl: './filtered-vendor-list.component.html',
  styleUrls: ['./filtered-vendor-list.component.less']
})
export class FilteredVendorListComponent implements OnInit {
  @Input() vendor: Vendor;
  @Output() vendorChange: EventEmitter<Vendor> = new EventEmitter<Vendor>();
  @Input() editable: boolean;
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() OnAddVendorClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() typeId: string;
  @Input() typeName: string;
  @Input() customer: Customer | null;
  @ViewChild('vendorInputText', { static: true }) inputTxt: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('findVendorDialogTemplate', { static: true }) findVendorDialogTemplate: TemplateRef<any>;
  private request: Subscription = null;
  public searching: boolean = false;
  public saving: boolean = false;

  vendors: Vendor[] = [];

  constructor(private purchasingSvc: PurchasingService, private vendorSvc: VendorService, private usrSvc: UserService, private utilitySvc: UtilityService, private dialog: MatDialog) { }

  setValue(vendor: Vendor): void {
    this.vendor = vendor;
    if (vendor == null)
      return;

    this.loading.emit(true);
    this.purchasingSvc.get(this.vendor.vendorId).subscribe(c => {
      this.vendor = c;
      this.vendorChange.emit(this.vendor);
      this.loading.emit(false);
    });
  }

  public formatValue(v: Vendor): string {
    return v == null ? "" : v.name;
  }

  public canAddVendor(): boolean {
    return this.usrSvc.canAccess('SupplierUser');
  }

  public async findVendor() {
    const ref = this.dialog.open<any, any, Vendor | null>(this.findVendorDialogTemplate, {
      minWidth: 500,
      data: { hint: this.inputTxt.nativeElement.value, vendor: null }
    })
    const vendor = await ref.afterClosed().toPromise();
    if (!vendor) return;

    const notCapable = this.typeName && (
      (vendor.vendorServiceOfferings.findIndex(vso => vso.stationStationId === this.typeId) === -1) &&
      (vendor.vendorMaterialGroups.findIndex(vso => vso.materialGroupMaterialGroupId === this.typeId) === -1) &&
      (
        this.typeId === 'purchased' &&
        vendor.vendorMaterialGroups.findIndex(vso => vso.materialGroup.groupName === "Hardware") === -1
      )
    )

    const notApproved = this.customer && vendor.approvedSupplierList.findIndex(a => a.customerCustomerId === this.customer.customerId) === -1;

    if (notCapable || notApproved) {

      let confirmText: string;
      if (notApproved && notCapable) {
        confirmText = `This will add <b>${ vendor.name }</b> as an approved vendor for <b>${this.customer.businessName}</b> and mark them as capable of supplying <b>${this.typeName}</b>.`;
      } else if (notApproved) {
        confirmText = `This will add <b>${ vendor.name }</b> as an approved vendor for <b>${this.customer.businessName}</b>.`;
      } else if (notCapable) {
        confirmText = `This will mark <b>${ vendor.name }</b> as as capable of supplying <b>${this.typeName}</b>.`;
      }


      const confirm = await this.utilitySvc.showConfirmationPromise(
        'Add Other Vendor',
        `<p>${confirmText}</p>
        <p>Are you sure you want to do this?</p>`
      );
  
      if (!confirm) return;
      
      this.saving = true;
      await this.vendorSvc.markVendorForItem(vendor, this.typeId, this.customer && this.customer.customerId).toPromise();
      this.saving = false;

    }

    this.setValue(vendor);

  }

  public onAddItem(): void {
    console.log('asdasdasd')
    this.dialog.closeAll();
    this.OnAddVendorClick.emit(true);
  }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.searchVendors(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public testSelection(_: any): void {
    if (!this.vendor) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.vendor.name;
  }

  searchVendors(_: any) {
    this.searching = true;
    if (this.request != null) {
      this.request.unsubscribe();
    }

    this.request = this.purchasingSvc.search(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        this.searching = false;
        this.vendors = result.results.filter(v => {
          return (
            // is capable
            (!this.typeId || (
              (v.vendorServiceOfferings.findIndex(vso => vso.stationStationId === this.typeId) !== -1) ||
              (v.vendorMaterialGroups.findIndex(vso => vso.materialGroupMaterialGroupId === this.typeId) !== -1) ||
              (
                this.typeId === 'purchased' &&
                v.vendorMaterialGroups.findIndex(vso => vso.materialGroup.groupName === "Hardware") !== -1
              )
            )) &&
            // is approved
            (!this.customer || 
              v.approvedSupplierList.findIndex(a => a.customerCustomerId === this.customer.customerId) !== -1
            )
          )
        });
      });
  }

  ngOnInit(): void {
  }
}


@Component({
  selector: 'vendor-search',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../common/components/generic-search/generic-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VendorSearch),
      multi: true
    }
  ],
})
export class VendorSearch extends GenericSearchComponent<Vendor> {

  @Input() placeholder = 'Vendor';

  constructor(private vendorSvc: VendorService, private userSvc: UserService, @Inject(Injector) injector: Injector) {
    super(injector)
  }

  doSearch(searchText: string) {
    return this.vendorSvc.search(searchText, 0, 'name', 'desc').pipe(
      map((v) => v.results)
    )
  }

  public canAdd(): boolean {
    return this.userSvc.canAccess('SupplierUser')
  }

  public get addItemText(): string {
     return 'Add Vendor';
  }

  public get noItemsText(): string {
    return this.canAdd() ? 'Still not seeing your vendor?' : 'No vendors found.';
  }

  public getSearchField(v: Vendor): string {
    return v && v.name;
  }

}
