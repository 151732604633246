import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../../../../common/services/errorHandler.service';
import { MessageService } from '../../../../common/services/message.service';
import { catchError } from 'rxjs/operators';
import { httpService } from '../../../../common/services/http.service';

export interface DashboardCount {
  type: string,
  count: number
}

@Injectable({
  providedIn: 'root',
})
export class OrderStatusInfoService extends httpService {
  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient) {
    super(errorHandler, messages);
    this.serviceName = "Dashboard";
  }
  
  getWaitingOrdersData(): Observable<DashboardCount[]> {
    return this.http.get<DashboardCount[]>('/api/dashboard/getWaitingOrdersData').pipe(
      catchError(this.handleError<any>("Get Dashboard Data (Waiting Orders)", null))
    );
  }
  
  getOverdueOrdersData(): Observable<DashboardCount[]> {
    return this.http.get<DashboardCount[]>('/api/dashboard/getOverdueOrdersData').pipe(
      catchError(this.handleError<any>("Get Dashboard Data (Overdue Orders)", null))
    );
  }
  
  getAtRiskOrdersData(): Observable<DashboardCount[]> {
    return this.http.get<DashboardCount[]>('/api/dashboard/getAtRiskOrdersData').pipe(
      catchError(this.handleError<any>("Get Dashboard Data (At Risk Orders)", null))
    );
  }
}
