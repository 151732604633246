<div class="mat-elevation-z6 rounded overflow-hidden pt-2 position-relative" style="height: 90vh; position: relative;" *ngLet="groupBy$ | async as groupBy">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <div style="position: absolute; top: 5.75em; right: 2em; z-index: 999">
    <ng-content select="[floatingButton]"></ng-content>
  </div>
  <mat-table class="w-100 h-100-t d-flex flex-column" style="overflow-y: scroll" #table [dataSource]="results$">
    <ng-container matColumnDef="searchBar">
      <mat-header-cell class="search-bar-cell" style="font-size: inherit; color: inherit;" *matHeaderCellDef [attr.colspan]="displayedColumns.length">
        <ng-content select="[preSearchContent]"></ng-content>
        <button [matMenuTriggerFor]="groupByMenu" mat-icon-button class="text-muted mr-2" *ngIf="groupableFields?.length > 0">
          <mat-icon [matBadge]="groupBy !== null ? '&#8288;' : undefined" matBadgePosition="below after" matBadgeSize="small" svgIcon="format-list-group"></mat-icon>
        </button>
        <mat-menu #groupByMenu (click)="$event.stopPropagation()" class="p-0" style="min-width: 10em">
          <div class="font-weight-medium mb-2 px-3 pt-1">Group By</div>
          <button mat-menu-item *ngFor="let field of groupableFields" (click)="setGroupBy(field?.name)">
            <mat-checkbox class="mat-checkbox-readonly mat-checkbox-no-margin mr-2" [checked]="groupBy === field?.name"></mat-checkbox>
            <span [class.font-weight-medium]="groupBy === field?.name">{{ field === null ? 'None' : field.title }}</span>
          </button>
        </mat-menu>
        <button [matMenuTriggerFor]="filterMenu" mat-icon-button class="text-muted mr-2" *ngIf="filterParams">
          <mat-icon [matBadge]="filterCount" matBadgePosition="below after" matBadgeSize="small">filter_alt</mat-icon>
        </button>
        <mat-menu #filterMenu (click)="$event.stopPropagation()" class="p-0">
          <div class="w-100 p-3"(click)="$event.stopPropagation()">
            <ng-container *ngFor="let category of filterParams">
              <div class="font-weight-medium mb-2">{{ category.categoryTitle }}</div>
              <mat-chip-list>
                <mat-chip *ngFor="let option of category.options" [ngClass]="option.class" class="button-text filter-chip"
                  [class.filter-disabled]="!filterIsEnabled(category.categoryName, option.value)"
                  (click)="$event.stopPropagation(); toggleFilter(category.categoryName, option.value)"
                >{{ option.title }}</mat-chip>
              </mat-chip-list>
            </ng-container>
          </div>
        </mat-menu>
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message dense-field ml-1" id="search-field" #searchField>
          <mat-icon matPrefix class="text-muted">search</mat-icon>
          <mat-chip-list #chipList
          >
            <mat-chip class="bg-primary text-white" disableRipple removable (removed)="deleteFieldSearch(field.field)" *ngFor="let field of fieldSearches">
              <b>{{ fieldSearchNames[field.field] }}:</b>&nbsp;<span>{{field.string}}</span>
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip disableRipple *ngIf="fieldSearchAddField" class="bg-primary text-white my-0">
              <b>{{ fieldSearchAddName }}:</b>&nbsp;<input type="text" #fieldSearchInput (keydown)="$event.stopPropagation()" (keyup.enter)="processFieldSearch(true)" (blur)="processFieldSearch()" [(ngModel)]="fieldSearchAddText" [style.width]="fieldSearchAddWidth" style="
              padding: 0; border: 0; outline: none; background-color: transparent; color: #fff"/>
            </mat-chip>
            <input [matChipInputFor]="chipList" matInput type="text" [(ngModel)]="searchText" (ngModelChange)="checkFieldSearch()" (keyup.enter)="onSearchChange()" #searchInput />
          </mat-chip-list>
          <button mat-icon-button matSuffix class="text-muted" [ngStyle]="{opacity: (loading || searchText === '') ? '0.5' : '1' }" [disabled]="loading || searchText === ''" style="
            display: inline-block;
            position: relative;
          " (click)="clearSearch()">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-flat-button matSuffix class="button-text" color="primary" [disabled]="loading" style="
            position: relative;
            top: -7px;
            line-height: 32px;
            margin-left: 6px;
            padding: 0 12px;
          " (click)="onSearchChange()">
            Search
          </button>
        </mat-form-field>
        <div class="manager-toggle-field ml-2" *ngIf="showManagerToggle && !alwaysManager">
          <span class="manager-toggle-label">
            Just Mine
          </span>
          <mat-slide-toggle class="mx-2" [ngModel]="managerView" (ngModelChange)="onManagerView($event)">
          </mat-slide-toggle>
          <span class="manager-toggle-label">
            Manager View
          </span>
        </div>
      </mat-header-cell>
    </ng-container>
    <mat-header-row style="border: none; height: 56px !important; padding-top: 6px;" *matHeaderRowDef="['searchBar']; sticky: true"></mat-header-row>
    
    <ng-container matColumnDef="groupName">
      <mat-cell *matCellDef="let item" style="flex-basis: 8%">
        <!-- <button type="button" mat-icon-button class="mr-1" (click)="groupExpansionModel.toggle(item.groupKey); dataTable.renderRows()">
          <mat-icon class="mat-icon-rtl-mirror">
          {{!groupExpansionModel.isSelected(item.groupKey) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button> -->
        <span class="group-header" *ngIf="!groupHeaderTemplate">
          {{item.groupName}}
        </span>
        <ng-container *ngIf="groupHeaderTemplate"
        [ngTemplateOutlet]="groupHeaderTemplate"
        [ngTemplateOutletContext]="{ $implicit: item, groupBy }"
        ></ng-container>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: ['groupName']; when: rowIsGroupHeader"></mat-row>
    
    <mat-header-row *matHeaderRowDef="columnsReady ? processDisplayedColumns(groupBy) : []; sticky: true"></mat-header-row>
    <mat-row matRipple class="cursor-pointer" *matRowDef="let row; columns: columnsReady ? processDisplayedColumns(groupBy) : [];" (click)="onSelect(row)"></mat-row>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-muted small font-italic" [attr.colspan]="displayedColumns.length" *ngIf="!loading" style="width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;">No items were found.</td>
    </tr>
    <mat-footer-row style="min-height: 56px;" class="mt-auto" *matFooterRowDef="['paginator']; sticky: true"></mat-footer-row>


    <ng-container matColumnDef="paginator">
      <mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <mat-paginator #paginator [length]="resultCount$ | async" class="w-100"
              [pageSize]="pageSize"
              [disabled]="loading"
              (page)="onPage($event)"
              aria-label="Select page">
        </mat-paginator>
      </mat-footer-cell>
    </ng-container>
  </mat-table>
</div>
