import { Component, Input, Output, EventEmitter, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '../../../common/services/utility.service';
import { Material } from '../../../order/resources/material';
import { Product, ProductRepairPlan } from '../../../order/resources/product';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { OutsideProcessSpecification, Station } from '../../../order/resources/station';
import { WorkflowStep, WorkflowStepInventoryItem, WorkflowStepInventoryItemType, WorkflowStepType } from '../../../order/resources/workflow';
import { OrderService } from '../../../order/services/order.service';
import { StationService } from '../../../order/services/station.service';
import { Department } from '../../../floor/resources/building';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { OrderStatus } from '../../../order/resources/order';
import { WorkOrder } from '../../../planning/resources/work-order';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PlanningService } from '../../services/planning.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlanningDetailService } from '../planning-detail.service';
import { MaterialBid } from '../../../purchasing/resources/materialBid';

@Component({
  selector: 'workflow-step-items',
  templateUrl: './workflow-step-items.component.html',
  styleUrls: ['./workflow-step-items.component.less']
})
export class WorkflowStepItemsComponent implements OnInit {

  @Input() product: Product;
  @Input() steps: WorkflowStep[];
  @Input() repairPlan: ProductRepairPlan;
  @Input() editing: boolean;
  @Input() workOrder: WorkOrder = null;
  @Input() machineAssignments: MachineAssignment[] = null;
  @Output() machineAssignmentsChange: EventEmitter<MachineAssignment[]> = new EventEmitter<MachineAssignment[]>();
  @Output() workOrderEdit: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() productChange = new EventEmitter<Product>();
  public dirty = false;
  @Input() childProducts: Product[];
  private stationList: Station[] = null;
  public loading = false;
  public selectedStep: WorkflowStep = null;
  public allProducts: Product[] = [];
  public reordering = false;
  @ViewChild('newStepDialogTemplate', { static: true }) newStepDialogTemplate: TemplateRef<any>;

  constructor(
    private detailService: PlanningDetailService,
    private planningService: PlanningService,
    private stationService: StationService,
    private orderService: OrderService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) { }

  private sortWorkflow(): void {
    this.steps = this.steps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
    if (this.repairPlan) this.repairPlan.workflow.workflowSteps = this.repairPlan.workflow.workflowSteps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
  }

  public getAssignment(step: WorkflowStep): MachineAssignment {
    if (step == null) return null;

    return this.machineAssignments.find(a => a != null && a.workflowStepId == step.workflowStepId);
  }

  public saveAssignment(step: WorkflowStep, dept: Department): void {
    if (dept == null || step == null)
      return;

    var assignment = this.getAssignment(step);

    if (assignment == null) {
      assignment = <MachineAssignment>{
        assignmentId: UtilityService.emptyGuid,
        department: dept,
        departmentId: dept.departmentId,
        operation: step,
        workflowStepId: step.workflowStepId,
        workOrder: this.workOrder,
        workOrderId: this.workOrder.workOrderId,
        machine: null,
        machineId: null,
        status: OrderStatus.UNASSIGNED,
        requestedQty: this.product.orderQuantity
      };
      this.dirty = true;
      this.machineAssignments.push(assignment);
    }
    else {
      if (assignment.departmentId !== dept.departmentId) this.dirty = true;
      assignment.departmentId = dept.departmentId;
      assignment.department = dept;
    }

    this.machineAssignmentsChange.emit(this.machineAssignments);

  }

  public getInputItems(step: WorkflowStep): WorkflowStepInventoryItem[] {
    return step.workflowStepInventoryItems.filter(item => item.type === WorkflowStepInventoryItemType.INPUT || item.type === WorkflowStepInventoryItemType.TOOLING).sort((a, b) => (a.type - b.type));
  }

  public getOutputItems(step: WorkflowStep): WorkflowStepInventoryItem[] {
    return step.workflowStepInventoryItems.filter(item => item.type === WorkflowStepInventoryItemType.OUTPUT);
  }

  public getInspectionToolingItems(step: WorkflowStep): WorkflowStepInventoryItem[] {
    return step.workflowStepInventoryItems.filter(item => item.type === WorkflowStepInventoryItemType.INSPECTION_TOOLING).sort((a, b) => (a.type - b.type));
  }

  public get stepInputItems(): WorkflowStepInventoryItem[] {
    if (!this.selectedStep) return [];
    return this.getInputItems(this.selectedStep);
  };

  public get stepOutputItems(): WorkflowStepInventoryItem[] {
    if (!this.selectedStep) return [];
    return this.getOutputItems(this.selectedStep);
  }

  public get stepInspectionToolingItems(): WorkflowStepInventoryItem[] {
    if (!this.selectedStep) return [];
    return this.getInspectionToolingItems(this.selectedStep);
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public async addItem(direction: 'INPUT' | 'OUTPUT' | 'INSPECTION_TOOLING') {
    const ref = this.dialog.open(this.newStepDialogTemplate, {
      minWidth: 500,
      data: {
        direction,
        type: direction === 'INSPECTION_TOOLING' ? 'TOOLING' : 'PURCHASED',
        product: null,
        material: null,
        purchasedItem: null,
        quantity: 1,
        isTooling: false,
      }
    });
    const result: {
      type: 'PURCHASED' | 'PRODUCT' | 'MATERIAL' | 'TOOLING',
      product: Product,
      material: Material,
      purchasedItem: PurchasedItem,
      quantity: number,
    } = await ref.afterClosed().toPromise();
    if (!result) return;

    const isTooling = result.type === 'TOOLING';

    const data = {
      product: null,
      material: null,
      purchasedItem: null,
      quantity: result.quantity,
      type: direction === 'INSPECTION_TOOLING' ? WorkflowStepInventoryItemType.INSPECTION_TOOLING :
      (isTooling ? WorkflowStepInventoryItemType.TOOLING : (direction === 'INPUT' ? WorkflowStepInventoryItemType.INPUT : WorkflowStepInventoryItemType.OUTPUT)),
    };

    if (result.type === 'PRODUCT') {
      data.product = result.product;
    } else if (result.type === 'MATERIAL') {
      data.material = result.material;
    } else if (result.type === 'PURCHASED' || result.type === 'TOOLING') {
      data.purchasedItem = result.purchasedItem;
    }

    this.loading = true;
    await this.orderService.addWorkflowStepItem(this.selectedStep, data).toPromise();
    const step = await this.orderService.getWorkflowStep(this.selectedStep.workflowStepId).toPromise();
    console.log(step.workflowStepInventoryItems)
    this.selectedStep.workflowStepInventoryItems = step.workflowStepInventoryItems
    this.loading = false;
    this.dirty = true;
    this.productChange.emit(this.product);

  }

  public async deleteItem(item: WorkflowStepInventoryItem) {
    const r = await this.utilityService.showConfirmationPromise(`Are you sure you want to delete this item?`, 'This cannot be undone.');
    if (!r) return;
    this.loading = true;
    await this.orderService.deleteWorkflowStepItem(item).toPromise();
    const step = await this.orderService.getWorkflowStep(this.selectedStep.workflowStepId).toPromise();
    console.log(step.workflowStepInventoryItems)
    this.selectedStep.workflowStepInventoryItems = step.workflowStepInventoryItems
    this.loading = false;
    this.dirty = true;
    this.productChange.emit(this.product);
  }

  private getProductChildren(product: Product): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const children = await this.orderService.getChildProducts(product.productId).toPromise()
      product.childAssemblies = children
      if (children.length === 0) {
        resolve()
      } else {
        await Promise.all(product.childAssemblies.map(child => this.getProductChildren(child)))
        resolve()
      }
    })
  }

  public isInteger = Number.isInteger;

  public stations$: Observable<Station[]>;
  ngOnInit() {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
    this.sortWorkflow();
    this.stations$ = this.stationService.stations.pipe(map(sl => sl.filter(s => !s.isDeleted)));
  }
  
  public getStatusText(disposition: number): string {
    return OrderStatus.getStatusText(disposition);
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  @Output() programTab = new EventEmitter<void>();
  public goToProgramming() {
    this.programTab.emit();
  }

  public oldOrder: { [key: string]: number };
  public startSorting() {
    this.oldOrder = this.steps.reduce((acc, x) => {
      acc[x.workflowStepId] = x.stepOrder;
      return acc;
    }, {});
    this.reordering = true;
    this.dirty = true;
  }

  public cancelSorting() {
    this.reordering = false;
    this.steps = this.steps.sort((a, b) => { return this.oldOrder[a.workflowStepId] - this.oldOrder[b.workflowStepId]; });
    this.oldOrder = null;
  }

  public doSort(event: CdkDragDrop<WorkflowStep[]>) {
    moveItemInArray(this.steps, event.previousIndex, event.currentIndex);
    this.steps = this.steps.map((step, i) => ({ ...step, stepOrder: i }));
    console.log(this.steps.map(step => this.getStation(step)?.name));
    console.log(this.steps.map(step => step.stationId));
  }

  public async commitSort() {
    this.loading = true;
    console.log(this.steps);
    const newSteps = await this.orderService.reorderWorkflow(this.steps).toPromise();
    this.steps = newSteps;
    this.sortWorkflow();
    this.selectedStep = this.selectedStep ? this.steps.find(s => s.workflowStepId == this.selectedStep.workflowStepId) : null;
    this.reordering = false;
    this.loading = false;
    this.productChange.emit(this.product);
  }

  public getStatusIcon(status: number) {
    if (status == 0) return 'more_horiz';
    if (status == 1) return 'error';
    if (status == 2) return 'check';
  }

  public getStepStatusText(status: number) {
    if (status == 0) return 'Not Planned';
    if (status == 1) return 'Needs Check';
    if (status == 2) return 'Planned';
  }

  public async onStatusChange(status: number) {
    this.loading = true;
    await this.planningService.setWorkflowStepPlanningStatus(this.selectedStep.workflowStepId, status).toPromise();
    this.loading = false;
  }

  public addingStep = false;
  public addingStepStation: Station = null;

  public startAddingStep() {
    this.addingStepStation = null;
    this.addingStep = true;
  }

  public cancelAddingStep() {
    this.addingStep = false;
    this.addingStepStation = null;
  }

  public get isRepair(): boolean {
    return !!this.product.productRepairPlan;
  }
  
  public finishAddingStep(station: Station) {
      var newStep: WorkflowStep = <WorkflowStep>{
        workflowStepId: UtilityService.emptyGuid,
        stepType: this.isRepair ? WorkflowStepType.Repair : WorkflowStepType.Standard,
        stationId: station.stationId,
        runPrice: station.isOutsourceStep ? null : station.stdCostPerHour,
        runIsPerPart: station.perPart,
        isStandalone: station.isStandalone || false,
        hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
        setupTime: station.defaultSetupTime,
        outsourceMarkup: station.isOutsourceStep ? UtilityService.defaultMarkup : null,
        workflowId: this.product.workflowWorkflowId,
        stepOrder: this.steps.length
      };

      if (station.isPainting) {
        newStep.runTime = 1;
        newStep.runPrice = 0.065;
        newStep.paintCost = 0.125;
        newStep.paintMinPrice = 350;
      }

      this.addingStep = false;
      this.loading = true;
      if (this.isRepair) {
        this.orderService.saveRepairWorkflowStep(this.product.productRepairPlan, newStep).subscribe(result => {
          this.product.productRepairPlan.workflow.workflowSteps.push(result);
          this.loading = false;
        });
      } else {
        this.orderService.saveWorkflowStep(this.product, newStep).subscribe(result => {
          this.product.workflow.workflowSteps.push(result);
          this.loading = false;
        });
      }

  }

  public generateStation(name: string): Partial<Station> {
    // TODO
    return {}
  }
  public getStationName(station: Station) {
    return station?.name;
  }

  public async onNewOPSpec(spec: OutsideProcessSpecification) {
    this.loading = true;
    await this.stationService.createSpec(this.selectedStep.stationId, spec.name, spec.outsideProcessSpecificationId).toPromise();
    this.loading = false;
    this.cdr.detectChanges();
  }

  private switching = false;
  public onStepEdited() {
    if (!this.switching) {
      this.dirty = true;
      // const index = this.product.workflow.workflowSteps.findIndex(s => s.workflowStepId === this.selectedStep.workflowStepId);
      // this.product.workflow.work
      this.productChange.emit(this.product);
    }
  }

  public selectStep(step: WorkflowStep) {
    this.switching = true;
    this.selectedStep = step;
    setTimeout(() => this.switching = false);
  }

  onOpQuote(quote: MaterialBid) {
    this.detailService.addQuotes(quote.stationId, [quote]);
  }
}
