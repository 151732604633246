import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderOverview } from '../../../../planning/resources/work-order';
import { WorkflowStep, WorkflowStepPlanningStatus } from '../../../../order/resources/workflow';
import { Station } from '../../../../order/resources/station';
import { StationService } from '../../../../order/services/station.service';

@Component({
  selector: 'oor-planning',
  templateUrl: './oor-planning.component.html',
  styleUrls: ['./oor-planning.component.less']
})
export class OorPlanningComponent implements OnInit {

  @Input() record: WorkOrderOverview;

  public get workflow() {
    return (this.record.workflowSteps ?? []).sort((a, b) => a.stepOrder - b.stepOrder);
  }

  public isPlanned(step: WorkflowStep) {
    return step.planningStatus === WorkflowStepPlanningStatus.PLANNED;
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public getName(step: WorkflowStep) {
    return this.getStation(step)?.name ?? 'Unknown Station';
  }

  constructor(private stationService: StationService) { }

  public stationList: Station[]
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

}
