<mat-card-title>
    {{ isNew ? 'New Microticket' : 'Edit Microticket' }}
</mat-card-title>
<form [formGroup]="form" (ngSubmit)="doSave(form.value)">
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  <div class="mt-1"></div>
  <div style="position: absolute; bottom: -2em; right: 1em; z-index: 3" class="d-flex" *ngIf="!fixedButtons">
    <button mat-fab class="extended-fab-button button-text bg-dark" color="primary"
      (click)="doCancel()">
      <mat-icon svgIcon="close"></mat-icon>
      Cancel
    </button>
    <button mat-fab class="extended-fab-button button-text bg-success ml-2" color="primary"
     type="submit" [disabled]="form.invalid">
      <mat-icon svgIcon="content-save"></mat-icon>
      Save
    </button>
  </div>
  <div class="d-flex w-100 justify-content-end" *ngIf="fixedButtons">
    <button type="button" mat-flat-button class="button-text bg-dark" color="primary"
      (click)="doCancel()">
      <mat-icon svgIcon="close"></mat-icon>
      Cancel
    </button>
    <button mat-flat-button class="button-text bg-success ml-2" color="primary"
     type="submit" [disabled]="form.invalid">
      <mat-icon svgIcon="content-save"></mat-icon>
      Save
    </button>
  </div>
</form>
