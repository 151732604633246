import { Component, Output, Input, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FloorService } from '../../services/floor.service';
import { ChartComponent } from '../../../common/components/chart/chart.component';

@Component({
  selector: 'machine-future-schedule',
  templateUrl: './machine-future-schedule.component.html',
  styleUrls: ['./machine-future-schedule.component.less']
})
export class MachineFutureScheduleComponent implements OnInit, OnChanges {
  @Input() machineId: string = null;
  @Input() days: number = 30;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  public saving: boolean = false;

  public futureData: any[];
  public futureLabels: string[];
  public chartOptions: any;
  public chartPlugins: any[];
  private futureRawData: any[];

  private dragData: any = {};

  constructor(private floorSvc: FloorService) { }

  public bubbleClick(e: any) {
    this.onClick.emit(e);
  }

  public reload(): void {
    this.setupChart();
  }

  private setupChart(): void {
    var self = this;

    this.chartOptions = {
      responsive: true,
      legend: {
        display: false
      },
      aspectRatio: 4,
      scales: {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }]
      },
      hover: {
        onHover: function (e, el) {
          var section = el[0];
          e.currentTarget.style.cursor = (section && section._model.backgroundColor != 'transparent') ? 'pointer' : 'default';

          if (self.dragData.mouseData) //we're dragging
            e.currentTarget.style.cursor = 'grab';
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + Number(tooltipItem.yLabel).toFixed(2) + ' hrs';
          }
        }
      },
      plugins: {
        labels: {
          render: () => { }
        }
      }
    };

    this.chartPlugins = [{
      afterInit: function (chart, _options) {
        chart.canvas.onmousedown = function (e: MouseEvent): void { self.mouseDown(e, chart); };
        chart.canvas.onmouseup = function (e: MouseEvent): void { self.mouseUp(e, chart); };
        chart.canvas.onmousemove = function (e: MouseEvent): void { self.mouseMove(e, chart); };
        chart.canvas.onmouseleave = function (e: MouseEvent): void { self.mouseUp(e, chart); }; //We won't support dragging data OUT OF this chart
        chart.canvas.onmouseenter = function (e: MouseEvent): void { self.mouseEnter(e, chart) };
      },
      afterDatasetDraw: function (chart, _options) {
        self.showExternalDragData(chart);
      }
    }];

    this.floorSvc.getMachineFutureData(this.machineId).subscribe(result => {
      this.futureRawData = result;

      this.getFutureLabels();
      this.getFutureData();
    });
  }

  private showExternalDragData(chart: any) {
    if (this.dragData.fromExternal) {
      chart.ctx.beginPath();
      chart.ctx.fillStyle = this.dragData.dataPoint.backgroundColor;
      chart.ctx.strokeStyle = this.dragData.dataPoint.borderColor;
      chart.ctx.fillRect(this.dragData.boxElement._view.x - (this.dragData.boxElement._view.width / 2), chart.scales["y-axis-0"].bottom, this.dragData.boxElement._view.width, (chart.scales["y-axis-0"].bottom - chart.scales["y-axis-0"].top) * -0.9);
      chart.ctx.strokeRect(this.dragData.boxElement._view.x - (this.dragData.boxElement._view.width / 2), chart.scales["y-axis-0"].bottom, this.dragData.boxElement._view.width, (chart.scales["y-axis-0"].bottom - chart.scales["y-axis-0"].top) * -0.9);
    }
  }

  private getFutureLabels(): void {
    var labels = [];

    var date = new Date();
    var days = this.days;

    while (days-- >= 0) {
      labels.push(date.toLocaleDateString());
      date.setDate(date.getDate() + 1);
    }

    this.futureLabels = labels;
  }

  private getFutureData(): void {
    var data = [];
    var firstPoint = true;

    var color = 1;
    for (var d in this.futureLabels) {
      var workOrders = this.futureRawData.filter(wo => wo.day == this.futureLabels[d] || (firstPoint && new Date(wo.day).valueOf() <= new Date().setHours(0, 0, 0, 0)));

      for (var i = 0; i < workOrders.length; i++) {
        var WO = workOrders[i];
        var point = data.find(i => i.label == WO.workOrderNumber);
        if (point == null) {
          point = { workOrderId: WO.workOrderId, day: WO.day, label: WO.workOrderNumber, backgroundColor: ChartComponent.getColor(color), borderWidth: 1, borderColor: "#0000002c", data: [] };
          data.push(point);
        }
        point.data[d] = point.data[d] ? point.data[d] + WO.hours : WO.hours;
        color++;
      }
      firstPoint = false;
    }

    data.forEach(i => {
      for (var date in this.futureLabels) {
        i.data[date] = i.data[date] || 0;
      }
    });

    this.futureData = data;
  }

  private mouseDown(e: MouseEvent, chart: any): void {
    var element = chart.getElementAtEvent(e);
    this.dragData = {};

    if (element.length > 0) {
      this.dragData.dataPoint = this.futureData[element[0]._datasetIndex];
      this.dragData.boxElement = element[0];
      this.dragData.mouseData = {
        x: e.x, y: e.y
      };

      this.dragData.dataPoint.originalBackgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement.originalX = this.dragData.boxElement._view.x;
      this.dragData.boxElement.originalY = this.dragData.boxElement._view.y;
    }
  }

  private mouseEnter(e: MouseEvent, chart: any): void {
    const dataKey: string = 'IntraChartData';
    if (sessionStorage.getItem(dataKey)) {
      var data = JSON.parse(sessionStorage.getItem(dataKey));
      sessionStorage.removeItem(dataKey);

      const acceptedSources: string[] = ["MachineTodayScheduleComponent"];

      if (acceptedSources.findIndex(s => s == data.source) < 0) {
        //only accept data from the above charts...
        return;
      }

      this.dragData.fromExternal = true;

      this.dragData.dataPoint = {
        workOrderId: data.data.workOrderId,
        assignmentId: data.data.assignmentId,
        day: data.data.scheduledStart,
        label: data.data.workOrder.workOrderNumber,
        backgroundColor: data.backgroundColor,
        borderWidth: data.borderWidth,
        borderColor: data.borderColor,
        data: []
      };

      this.dragData.mouseData = {
        x: e.x, y: e.y
      };

      var width: number = 0.75 * (chart.scales["x-axis-0"]._gridLineItems[1].x1 - chart.scales["x-axis-0"]._gridLineItems[0].x1);
      this.dragData.dataPoint.originalBackgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement = {};
      this.dragData.boxElement._view = {
        x: this.dragData.mouseData.x,
        y: this.dragData.mouseData.y,
        width: width
      };
      this.dragData.boxElement._model = this.dragData.boxElement._view;
      this.dragData.boxElement.originalX = e.offsetX;
      this.dragData.boxElement.originalY = e.offsetY;
    }
  }

  private mouseUp(e: MouseEvent, chart: any): void {
    if (!this.futureData) return;

    if (this.dragData.dataPoint) {
      this.dragData.dataPoint.backgroundColor = this.dragData.dataPoint.originalBackgroundColor;

      if (this.dragData.fromExternal) {
        if (e.offsetY < 0) {
          this.dragData = {};

          chart.update();
          return;
        }

        this.saving = true;
        this.dragData.dataPoint.day = new Date(this.dragData.dropOnDay);
        const droppedData = JSON.parse(JSON.stringify(this.dragData.dataPoint));
        this.floorSvc.updateSchedule(this.dragData.dataPoint.assignmentId, new Date(this.dragData.dropOnDay)).subscribe(_ => {

          this.floorSvc.getMachineFutureData(this.machineId).subscribe(result => {
            this.futureRawData = result;

            this.getFutureLabels();
            this.getFutureData();

            this.onClick.emit({ event: e, chart: chart, clickedElement: [droppedData] });

            this.saving = false;
          });

        });
      }
      else if (this.dragData.dropOnDay) {
        var data: any[] = this.futureRawData.filter(wo => wo.workOrderId == this.dragData.dataPoint.workOrderId && wo.day == this.dragData.dataPoint.day);
        data.forEach(d => d.day = this.dragData.dropOnDay);

        this.getFutureData();

        this.saving = true;
        this.floorSvc.updateWorkOrderSchedule(this.machineId, this.dragData.dataPoint.workOrderId, new Date(this.dragData.dataPoint.day), new Date(this.dragData.dropOnDay)).subscribe(_ => {
          this.onClick.emit({ event: e, chart: chart, clickedElement: data });
          this.saving = false;
        });
      }
    }

    this.dragData = {};

    chart.update();
  }

  private mouseMove(e: MouseEvent, chart: any): void {
    if (!this.futureData) return;
    var self = this;
    var getNearest = function (x: number, c: any): number {
      const dayWidth = Math.floor(c.scales['x-axis-0'].width / self.days) - 1;
      var day = Math.floor(x / dayWidth) - 1;
      const offset = (self.dragData.boxElement._view.width / 2) + ((dayWidth - self.dragData.boxElement._view.width) / 2);

      if (!c.scales['x-axis-0']._labelItems[day]) day = 0;

      self.dragData.dropOnDay = c.scales['x-axis-0']._labelItems[day].label;

      return c.scales['x-axis-0']._gridLineItems[day].x1 + offset;
    }

    if (this.dragData.mouseData) {
      this.dragData.dataPoint.backgroundColor = ChartComponent.shade(0.4, this.dragData.dataPoint.originalBackgroundColor, "#FFFFFF", true) + "66";

      this.dragData.boxElement._view.backgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement._view.x = getNearest(this.dragData.boxElement.originalX + (e.x - this.dragData.mouseData.x), chart);
      this.dragData.boxElement._view.borderSkipped = "none";

      this.dragData.boxElement._model.backgroundColor = this.dragData.boxElement._view.backgroundColor;
      this.dragData.boxElement._model.x = this.dragData.boxElement._view.x;
      this.dragData.boxElement._model.borderSkipped = "none";

      chart.render({
        duration: 0,
        lazy: false,
        easing: 'easeOutBounce'
      });
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupChart();
  }

  ngOnInit(): void {
    this.setupChart();
  }

}
