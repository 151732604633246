<h2 mat-dialog-title>Copy Part</h2>
<mat-dialog-content class="mat-typography">
  <p>Select a part from this order to copy.</p>
  <mat-form-field appearance="outline" class="w-100">
    <input matInput placeholder="Part Number"
    #searchInput
    [matAutocomplete]="auto"
    [disabled]="!parts"
    [formControl]="searchControl"
    [(ngModel)]="searchText"
    >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.partNumber}} Rev. {{option.revision}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button matDialogClose>Cancel</button>
  <button mat-button class="bg-primary text-white" [disabled]="!selectedPart" [matDialogClose]="selectedPart">Copy Part</button>
</mat-dialog-actions>