<div
  style="overflow-y: scroll"
  class="h-100-t"
>
  <div class="border-bottom p-3 bg-white" style="position: sticky; top: 0; z-index: 5">
    <div style="font-size: 1.25rem">Available Stations</div>
    <div class="mt-2">
      <mat-form-field class="w-100 dense-field" appearance="outline">
          <mat-icon matPrefix class="text-muted">search</mat-icon>
          <input matInput type="text" ngModel #filterModel="ngModel" />
      </mat-form-field>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" [ngStyle]="{opacity: stationService.loaded ? 0 : 1}"></mat-progress-bar>
  <mat-list class="pt-0" cdkDropListSortingDisabled cdkDropList [cdkDropListData]="stationData | async" id="workflowStationListCdkDrop" [cdkDropListConnectedTo]="'workflowWorkflowListCdkDrop'">
    <mat-list-item cdkDrag (dblclick)="stationDblClick.emit(station)" [cdkDragData]="station" *ngFor="let station of stationData | async" class="border-bottom cursor-pointer list-item">
      <div class="w-100 d-flex align-items-center text-ellipsis">
        <mat-icon svgIcon="logout-variant" class="text-muted flex-shrink-0" [class.invisible]="!station.isOutsourceStep"></mat-icon>
        <span class="ml-2" [innerHTML]="station.name | highlight: filterModel.value"></span>
      </div>
      <div *cdkDragPreview style="cursor: grabbing">
        <div class="rounded bg-white mat-elevation-z5 px-3 list-item" style="width: 300px; height: 48px; line-height: 48px; position: relative; left: -2em; top: -1em; cursor: grabbing">
          {{ station.name }}
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>