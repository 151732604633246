<loading *ngIf="!assignment"></loading>
<loading *ngIf="saving">Saving...</loading>

<div class="container-fluid pb-4" *ngIf="assignment">
  <div class="row">
    <div class="col-4 pr-2">
      <div class="bg-primary text-center p-2 rounded" *ngIf="assignment.workOrder">
        <h4>{{assignment.workOrder?.workOrderNumber}}</h4>
      </div>
      <div class="bg-dark text-white text-center p-2 rounded" *ngIf="!assignment.workOrder">
        <h4>No Assignments Found</h4>
      </div>
      <div class="d-flex text-muted w-100 mt-1" *ngIf="assignment.workOrder">
        <div>
          <div style="font-size: 0.75em" class="text-uppercase font-weight-bold">Scheduled Start</div>
          <div [class.text-success]="startTimeDifference >= 0" [class.text-danger]="startTimeDifference < 0">
            <div style="font-size: 2em" class="font-weight-bold">{{ assignment.scheduledStart | date: 'HH:mm' }}</div>
            <div style="margin-top: -14px;">
              <span style="font-size: 0.75em" *ngIf="assignment.actualStart">
                STARTED
              </span>
              <span style="font-size: 0.75em" *ngIf="startTimeDifference >= 0">
                {{ startTimeDifferenceString }} AHEAD
              </span>
              <span style="font-size: 0.75em" *ngIf="startTimeDifference < 0">
                {{ startTimeDifferenceString }} BEHIND
              </span>
            </div>
          </div>
        </div>
        <div class="ml-auto">
          <div style="font-size: 0.75em" class="text-uppercase text-right font-weight-bold">Scheduled End</div>
          <div style="font-size: 2em" class="font-weight-bold text-right">{{ assignment.scheduledEnd | date: 'HH:mm' }}</div>
        </div>
      </div>
      <div class="text-muted">
        <i class="far fa-lg fa-clock" [ngClass]="{'pulse':timerRunning}"></i>
        {{getRuntime()}} / {{ totalTimeString }}
      </div>
      <!-- Markers -->
      <div class="w-100 position-relative" style="font-size: 0.75em; height: 1.2em; margin-bottom: 5px; font-family: monospace;">
        <div *ngIf="setupFinishedTime"
        class="position-absolute"
        [ngStyle]="{
          top: 0,
          left: 'calc(' + (setupFinishedTime / totalTime) * 100 + '% - 0.5ch)'
        }"
        >
          S
          <div class="indicator-bar indicator-bar-setup">
            <div class="indicator-bar-top">&nbsp;</div>
            <div class="indicator-bar-bottom">&nbsp;</div>
          </div>
        </div>
        <ng-container *ngFor="let item of [].constructor(assignment.requestedQty); let i = index">
          <div
          *ngIf="partFinishedMap[i]"
          class="position-absolute"
          [ngStyle]="{
            left: 'calc(' + (partFinishedMap[i] / totalTime) * 100 + '% - 0.5ch)'
          }"
          >
            {{ i }}
            <div class="indicator-bar">
              <div class="indicator-bar-top">&nbsp;</div>
              <div class="indicator-bar-bottom">&nbsp;</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="w-100 position-relative" style="height: 24px;" *ngIf="assignment.workOrder">
        <!-- Progress bar base -->
        <div class="w-100 h-100">
          <div class="h-100 border border-info" style="border-right-color: transparent !important;" [class.d-inline-block]="setupPercent > 0" [class.d-none]="setupPercent == 0" [ngStyle]="{
            width: setupPercent + '%'
          }">&nbsp;</div>
          <div class="d-inline-block h-100 border border-primary" [ngStyle]="{ width: runPercent + '%' }">&nbsp;</div>
        </div>
        <!-- Time spent bar -->
        <div class="w-100 position-absolute h-100 timer-bar" style="top: 0; left: 0;"
          [ngStyle]="{
            'clip-path': 'inset(0 ' + (100 - percentageTimeSpent) + '% 0 0)'
          }"
        >
          <div class="h-100 border border-info bg-info" [class.d-inline-block]="setupPercent > 0" [class.d-none]="setupPercent == 0" [ngStyle]="{ width: setupPercent + '%' }">&nbsp;</div>
          <div class="d-inline-block h-100 border border-primary bg-primary" [ngStyle]="{ width: runPercent + '%' }">&nbsp;</div>
          <!-- Underage bar -->
          <div class="w-100 h-100 position-absolute" style="top:0; left: 0;"
            [ngStyle]="{
              'opacity': setupUnderage === null ? 0 : 1,
              'clip-path': 'inset(0 ' + (100 - setupPercent) + '% 0 ' + ((setupFinishedTime / totalTime) * 100) + '%)'
            }"
          >
            <div class="h-100 border border-success bg-success" [class.d-inline-block]="setupPercent > 0" [class.d-none]="setupPercent == 0" [ngStyle]="{ 
              width: setupPercent + '%',
              'border-width': setupPercent > 0 ? undefined : '0px !important'
            }">&nbsp;</div>
            <!-- <div class="d-inline-block h-100 border border-danger bg-danger" [ngStyle]="{ 
              width: runPercent + '%',
              opacity: setupOverUnder < 0 ? 1 : 0
            }">&nbsp;</div> -->
          </div>
        </div>
      </div>
      <div class="w-100 text-uppercase" style="font-size: .75em;"  *ngIf="assignment.workOrder">
        <div class="d-inline-block text-info" [ngStyle]="{ width: setupPercent + '%'}" *ngIf="setupPercent > 0">Setup</div>
        <div class="d-inline-block text-primary" [ngStyle]="{ width: runPercent + '%' }">Total Runtime</div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="assignment.operation?.runIsPerPart">
        <div [ngClass]="{
            'text-muted': !isOverMetric,
            'text-danger': isOverMetric
        }">
          <div style="font-size: 0.75em; margin-bottom: -12px;" class="text-uppercase font-weight-bold">Current Run Time</div>
          <div>
            <div style="font-size: 2em" class="font-weight-bold text-right">{{ currentRunTimeString }}</div>
          </div>
        </div>
        <div class="ml-2" [ngClass]="{
          'text-muted': (fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds,
          'text-success': (fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds
        }">
          <div style="font-size: 0.75em; margin-bottom: -12px;" class="text-uppercase font-weight-bold">{{
            fastestRunTimeSeconds !== null && (fastestRunTimeSeconds < estimatedRunTimeSeconds) ? 'Fastest Run Time' : 'Estimated Run Time'
          }}</div>
          <div>
            <div style="font-size: 2em" class="font-weight-bold">{{ runTimeMetricString }}</div>
          </div>
        </div>
      </div>
      <div class="w-100 position-relative" style="height: 24px;" *ngIf="assignment.workOrder && assignment.operation?.runIsPerPart">
        <!-- Progress bar base -->
        <div class="d-inline-block h-100 w-100 border" [ngClass]="{
          'border-dark': !isOverMetric && ((fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds),
          'border-success': !isOverMetric && ((fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds)
        }">&nbsp;</div>
        <!-- Time spent bar -->
        <div class="w-100 position-absolute h-100 timer-bar border" style="top: 0; left: 0; box-sizing: content-box;"
          [ngStyle]="{
            'clip-path': 'inset(0 ' + (100 - percentagePartTimeSpent) + '% 0 0)'
          }"
          [ngClass]="{
            'bg-dark': !isOverMetric && ((fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds),
            'border-dark': !isOverMetric && ((fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds),
            'bg-success': !isOverMetric && ((fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds),
            'border-success': !isOverMetric && ((fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds),
            'bg-danger': isOverMetric,
            'border-danger': isOverMetric
          }"
        >
        </div>
      </div>
      <div class="form-group pt-2 text-muted">
        <h5 class="text-secondary text-center">{{currentCount}} / {{assignmentTotal}}</h5>
      </div>
      <div class="form-group text-center op-buttons">
        <button *ngIf="!hasStartedOperation" class="btn btn-lg btn-info" [disabled]="complete || !assignment.workOrder" (click)="startOperation()">
          {{ assignment.actualStart ? 'Resume' : 'Start' }} Operation ({{getAdvanceButtonText(state)}})
        </button>
        <button *ngIf="hasStartedOperation" class="btn btn-lg text-white" [ngClass]="{'bg-success': !timerRunning, 'bg-danger': timerRunning}"
        [disabled]="complete || !assignment.workOrder || (timerRunning) || state === 4"
        (click)="startStop()">
          <mat-icon matPrefix class="mr-1" *ngIf="(timerRunning) || state === 4">
            lock
          </mat-icon>
          {{ timerRunning ? 'Stop Timer' : 'Start Timer' }}
        </button>
      </div>
      <div class="form-group text-center op-buttons" *ngIf="hasStartedOperation">
        <button class="btn btn-lg btn-primary" [disabled]="!timerRunning || complete || !assignment.workOrder || state === 4" (click)="advanceState()">{{getAdvanceButtonText(state)}}</button>
      </div>
      <div class="form-group text-center op-buttons">
        <button class="btn btn-lg" disabled>Send to Inspection</button>
      </div>
      <div class="form-group text-center op-buttons">
        <button class="btn btn-lg" disabled>View LTA</button>
      </div>
      <div class="form-group text-center op-buttons">
        <button class="btn btn-lg" disabled>Add Shop Aid</button>
      </div>
      <div class="form-group text-center op-buttons">
        <button class="btn btn-lg" disabled>Request Tooling</button>
      </div>
    </div>
    <div class="col-8">
      <div class="border-secondary border">
        <div class="border-bottom border-secondary text-center text-primary">
          <h4 class="p-2">
            <span *ngIf="assignment.workOrder">
              {{assignment.workOrder?.product?.partNumber}} / {{assignment.workOrder?.product?.revision}}
            </span>
            <span *ngIf="!assignment.workOrder">
              No Assignment
            </span>
          </h4>
        </div>
        <div class="row">
          <div class="col-6 border-right border-secondary">
            <div class="text-center">
              <h5 class="border-bottom text-primary p-2">Shop Aids</h5>
            </div>
            <div class="min-h-300">
              <div *ngIf="shopDocuments">
                <p class="text-center text-muted font-italic" *ngIf="shopDocuments?.length==0">-- No Documents --</p>
                <file-display-grid [files]="shopDocuments"></file-display-grid>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="text-center">
              <h5 class="border-bottom text-primary p-2">Notes</h5>
              <div class="min-h-300">
                <p class="text-center text-muted font-italic" *ngIf="assignment.workOrder?.product?.privateNote == null || assignment.workOrder?.product?.privateNote?.length==0">-- No Notes --</p>
                <p>{{assignment.workOrder?.product?.privateNote}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
