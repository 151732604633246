<div class="row border border-bottom">
  <div class="col-12 text-center rounded p-4" [ngClass]="{'bg-info text-light': !record.assignedUser}">
    <div *ngIf="!record.assignedUser">
      <h3>Needs Assignment</h3>
      <p>This ticket needs to be assigned to an employee before it can be fulfilled.</p>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-2 text-right" *ngIf="record.assignedUser">
        <span>Assigned To:</span>
      </div>
      <div class="col-6">
        <employee-select [editable]="true" (employeeChange)="assigningEmployee = $event"
          [employee]="record.assignedUser"></employee-select>
      </div>
      <div class="col-2"><button [disabled]="!assigningEmployee" class="btn btn-success"
          (click)="saveAssignment()"><span *ngIf="record.assignedUser">Re-</span>Assign</button></div>
    </div>
  </div>
</div>