import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { MatSidenav } from '@angular/material/sidenav';
import { InspectionTicket } from '../../../quality/resources/inspection-ticket';
import { QualityService } from '../../../quality/service/quality.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { Station } from '../../../order/resources/station';
import { StationService } from '../../../order/services/station.service';
import { WorkflowStep, WorkflowStepType } from '../../../order/resources/workflow';
import { UtilityService } from '../../../common/services/utility.service';
import { Department } from '../../resources/building';
import { ProductMaterialDimension } from '../../../order/resources/product';
import { RMARepairType } from '../../../rma/resources/rma-ticket';

@Component({
  selector: 'failed-inspection-review',
  templateUrl: './failed-inspection-review.component.html',
  styleUrls: ['./failed-inspection-review.component.less', '../../../quality/components/inspection-queue/inspection-queue.component.less', '../../../order/components/workflow-list/workflow-list.component.less']
})
export class FailedInspectionReviewComponent implements OnInit, OnDestroy {

  @Input() sidenav: MatSidenav;
  @Input() inspectionTicketId: string;
  
  public loading = false;
  public record: InspectionTicket;

  public selectedItemIndex: number = 0;

  constructor(private qualityService: QualityService, private navService: NavigationService, private stationService: StationService, private utilityService: UtilityService) {
    navService.pushBreadcrumb('Failed Inspection');
  }

  private openedSub: Subscription;
  private closedSub: Subscription;

  ngOnInit() {
    this.openedSub = this.sidenav.openedStart.subscribe(() => {
      this.qualityService.getInspectionTicket(this.inspectionTicketId).subscribe(t => {
        this.record = t;
        console.log(this.workflowSorted);
        console.log(this.workflowBefore);
        console.log(this.workflowAfter);
      })
    });
    this.closedSub = this.sidenav.closedStart.subscribe(() => {
      this.navService.popBreadCrumb();
    });
  }

  public async selectPart(i: number) {
    const r = await this.resetWorkflowState();
    if (!r) return;
    else this.selectedItemIndex = i;
  }

  public async onRadioGroupChange(item: InspectionTicket['inspectedItems'][0], event: MatRadioChange) {
    const r = await this.resetWorkflowState();
    if (!r) {
      event.source.radioGroup.value = item.disposition;
    }
    else item.disposition = event.value;
  }

  ngOnDestroy() {
    if (this.openedSub) this.openedSub.unsubscribe();
    if (this.closedSub) this.closedSub.unsubscribe();
  }

  public get passedAmount() {
    return this.record && this.record.inspectedItems.filter(i => i.approved).length;
  }

  public get failedAmount() {
    return this.record && this.record.inspectedItems.filter(i => !i.approved).length;
  }

  public getInspectionTypeText(ticket: InspectionTicket) {
    if (ticket.inspectionType == 0 && !ticket.requiresReport) return 'First Part Inspection';
    if (ticket.inspectionType == 0 && ticket.requiresReport) return 'First Article Inspection Report';
    if (ticket.inspectionType == 1) return 'Lot Inspection';
  }

  public getInspectionMethodText(ticket: InspectionTicket) {
    if (ticket.isCMM) return 'CMM Inspection';
    else return 'Manual Inspection';
  }

  public getStation(stationId: string): Station {
    if (!this.stationService.stationList) return null;

    return this.stationService.stationList.find(s => s.stationId == stationId);
  }

  public get selectedItem() {
    if (this.selectedItemIndex === null) return null;
    else return this.record.inspectedItems[this.selectedItemIndex];
  }

  public get workflowStepNumber() {
    return this.record.machineAssignment.operation.stepOrder;
  }

  public get workflowSorted() {
    return this.record.machineAssignment.workOrder.product.workflow.workflowSteps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
  }

  public get workflowBefore() {
    return this.workflowSorted.slice(0, this.workflowStepNumber + 1);
  }

  public get workflowAfter() {
    return this.workflowSorted.slice(this.workflowStepNumber + 1);
  }

  public workflowEditorState: 'list' | 'add' | 'edit' = 'list';
  public workflowEditingIndex: number | null = null;

  public addStep(station: Station) {
    if (!this.selectedItem.reworkWorkflowSteps) this.selectedItem.reworkWorkflowSteps = [];

    const newStep = <WorkflowStep>{
      workflowStepId: UtilityService.newGuid(),
      stepType: WorkflowStepType.Repair,
      stationId: station.stationId,
      runPrice: station.isOutsourceStep ? null : station.stdCostPerHour,
      runIsPerPart: station.perPart,
      isStandalone: station.isStandalone || false,
      isAdministrative: station.isAdministrative,
      isAssembly: station.isAssembly,
      hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
      setupTime: station.defaultSetupTime,
      outsourceMarkup: station.isOutsourceStep ? UtilityService.defaultMarkup : null,
    };

    if (station.isPainting) {
      newStep.runTime = 1;
      newStep.runPrice = 0.065;
      newStep.paintCost = 0.125;
      newStep.paintMinPrice = 350;
    }

    this.selectedItem.reworkWorkflowSteps = [...this.selectedItem.reworkWorkflowSteps, newStep];

    this.workflowDirty = true;
    this.workflowEditingIndex = this.selectedItem.reworkWorkflowSteps.length - 1;
    this.workflowEditorState = 'edit';

  }

  public get editingStep() {
    if (!this.selectedItem.reworkWorkflowSteps || this.workflowEditingIndex === null) return null;
    else return this.selectedItem.reworkWorkflowSteps[this.workflowEditingIndex];
  }

  public onStepSave(step: WorkflowStep) {
    Object.assign(this.selectedItem, step);
    this.selectedItem.reworkWorkflowSteps = [...this.selectedItem.reworkWorkflowSteps];
    this.workflowEditorState = 'list';
    this.workflowDirty = false;
  }

  public workflowDirty = false;
  public async resetWorkflowState(): Promise<boolean> {
    if (this.workflowDirty) {
      const r = await this.utilityService.showConfirmationPromise('Unsaved Changes', 'There are unsaved changes to the current workflow step. They will be lost if you leave this screen.');
      if (!r) return false;
    }
    this.workflowEditorState = 'list';
    this.workflowDirty = false;
    return true;
  }

  public editWorkflowStep(i: number) {
    this.workflowEditingIndex = i;
    this.workflowEditorState = 'edit';
    console.log(this.editingStep);
  }
  public deleteWorkflowStep(i: number) {
    this.selectedItem.reworkWorkflowSteps = this.selectedItem.reworkWorkflowSteps.filter((_, index) => index !== i);
  }

  public copiedStepsIndex = null;
  public copySteps(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.copiedStepsIndex = index;
  }

  public pasteSteps(event: MouseEvent, index: number) {
    event.stopPropagation();
    if (this.copiedStepsIndex === null) return;
    if (!this.record.inspectedItems[this.copiedStepsIndex].reworkWorkflowSteps) return;
    const copiedStepsArray = this.record.inspectedItems[this.copiedStepsIndex].reworkWorkflowSteps;
    const pastingItem = this.record.inspectedItems[index];
    // commented this out - 
    // // clone steps using json, give them new IDs
    // pastingItem.reworkWorkflowSteps = (JSON.parse(JSON.stringify(copiedStepsArray)) as WorkflowStep[]).map(s => ({
    //   ...s,
    //   workflowStepId: UtilityService.newGuid()
    // }));
    pastingItem.reworkWorkflowSteps = copiedStepsArray;
  }

  // returns whether workflow is linked to earlier workflow via copying
  public getLinkedIndex(index: number): number | null {
    var comparingWorkflow = this.record.inspectedItems[index].reworkWorkflowSteps || [];

    var comparingSlice = this.record.inspectedItems.slice(0, index);
    let returnIndex = 0;
    for (const item of comparingSlice) {
      // this comparison should only return true if the two arrays are the same reference
      if (item.reworkWorkflowSteps === comparingWorkflow) return returnIndex;
      returnIndex++;
    }
    return null;
  }

  public departmentAssignmentMap: {
    [key: string]: Department
  } = {};
  public getAssignment(step: WorkflowStep) {
    return this.departmentAssignmentMap[step.workflowStepId] || null;
  }
  public saveAssignment(step: WorkflowStep, dept: Department) {
    this.departmentAssignmentMap[step.workflowStepId] = dept;
  }

  public displayDimensions(dimensions: ProductMaterialDimension[]) {
    const { materialType } = this.record.machineAssignment.workOrder.product.material;
    if (!materialType || !dimensions || !materialType.displayTemplate) return null;

    if (materialType.materialTypeDimensions == null || dimensions.some(md => md.value === null)) return '[MISSING DIMENSIONS]';

    return materialType.displayTemplate.replace(/{(\w+?)}/g, (_match, group) => {
      const matchingDimension = materialType.materialTypeDimensions.find(mtd =>
        mtd.dimensionType.label.toLowerCase().trim() === group.toLowerCase().trim()
      );
      if (!matchingDimension) return '--';

      const dimensionValue = dimensions.find(
        md => md.materialTypeDimensionId === matchingDimension.materialTypeDimensionId
      );

      const out = dimensionValue ? dimensionValue.value.toFixed(3) : 'null';

      return out;
    });
  }

  public get done(): boolean {
    if (!this.record) return false;
    const rejected = this.record.inspectedItems.filter(i => !i.approved)
    return rejected.every(i => i.disposition === RMARepairType.Scrap || (
      i.disposition === RMARepairType.Rework &&
      (i.reworkWorkflowSteps && i.reworkWorkflowSteps.length > 0 && i.reworkWorkflowSteps.every(s => !!(this.departmentAssignmentMap[s.workflowStepId])))
    ))
  }

  public async finish() {
    const r = await this.utilityService.showConfirmationPromise('Is this done?', 'The item dispositions cannot be changed later.')
    if (!r) return;
    this.loading = true;
    await this.qualityService.finishFailedInspectionReview(
      this.record.inspectionTicketId, 
      this.record.inspectedItems, 
      this.departmentAssignmentMap)
      .toPromise();
    this.loading = false;
  }

}
