import { Component, Input, OnInit } from '@angular/core';
import { MaterialBidService } from '../../../../purchasing/services/material-bid.service';
import { UserService } from '../../../../common/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MaterialBid } from '../../../../purchasing/resources/materialBid';
import { SearchData } from '../../../../common/components/search-list-new/search-list-new.component';
import { User } from '../../../../common/resources/user';

@Component({
  selector: 'item-quote-history',
  templateUrl: './item-quote-history.component.html',
  styleUrls: ['./item-quote-history.component.less']
})
export class ItemQuoteHistoryComponent implements OnInit {
  @Input() filterId: string;

  constructor(private quoteService: MaterialBidService, public userService: UserService) { }

  ngOnInit(): void {
  }

  public getUserName(userId: string, list: User[]) {
    if (!list) return 'Unknown User';
    return list.find(u => u.userId === userId)?.fullName ?? 'Unknown User';
  }

  public search(data: SearchData) {
    const { searchText, page, sort } = data;
    return this.quoteService.searchItem(this.filterId, null, searchText, sort.active, sort.direction, page.pageIndex, page.pageSize);
  }

}
