import { Component, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import IWidgetData from '../services/widgets/interface';
import { WidgetService, IWidgetInstance } from '../services/widget.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'widget-item',
  styleUrls: ['./widget-item.component.less'],
  templateUrl: './widget-item.component.html'
})
export class WidgetItemComponent implements OnInit {

  // ID of the widget
  @Input() data: IWidgetInstance
  widget: IWidgetData

  constructor(public elem: ElementRef, private widgetService: WidgetService) {
  }

  ngOnInit() {
    this.widget = this.widgetService.widgetById(this.data.widget)
  }

  get nativeElement(): HTMLElement { return this.elem.nativeElement }
  get id(): string { return this.nativeElement.id }

  @Output() remove = new EventEmitter<ElementRef>();
  emitRemove() {
    this.remove.emit(this.elem)
  }

}
