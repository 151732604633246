import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { UtilityService } from '../../services/utility.service';
import $ from "jquery";


export interface ChartDataSet {
  data: number[];
  label: string;
  backgroundColor: string[];
  dataPointLabels: string[];
}

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.less']
})
export class ChartComponent implements OnInit, OnChanges {
  public static availableColors: Array<string> = ['#DD1818', '#117A8F', '#B0FF0E', '#7D30C1', '#FFB400', '#08AD4B', '#707070', '#CCCCCC', '#FD7037'];
  @Input() configuration: any;
  @Input() dataSet: Array<any>;
  @Input() plugins: Array<any> = [];
  @Input() chartType: string = "pie";
  @Input() width: string = "100%";
  @Input() height: string = "100%";
  @Input() options: any = { responsive: true };
  @Input() chartLabels: string[] = null;
  @Output() onHover: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterLoaded: EventEmitter<any> = new EventEmitter<any>();

  public chart: any = null;
  public data: Array<any>;
  public labels: Array<string>;
  public colors: Array<any>;
  public myId: string;

  constructor() {
    this.myId = `neoChart__${UtilityService.newGuid()}`;
  }

  private getChartDatasets(): Array<any> {
    if (this.dataSet == null) return [{ data: 0, label: "Awaiting Data" }];

    return this.dataSet;
  }

  private getChartLabels(): Array<string> {
    if (this.dataSet == null) return ["Awaiting Data"];

    return this.dataSet.map(i => i.dataPointLabels).reduce((t, v) => { return t.concat(v); }, []);
  }

  public static getColor(position: number): any {
    var lightenBy = 0.25;

    var colors: string[] = ChartComponent.availableColors;

    var i = 0;
    while (colors.length < position) {
      i++;
      colors = colors.concat(ChartComponent.availableColors.map(c => ChartComponent.shade(lightenBy, c, colors[i] == c ? false : colors[i], true)));
    }

    return colors[position];
  }

  private getChartColors(): Array<any> {
    var lightenBy = 0.25;

    if (this.dataSet == null) return [{
      backgroundColor: [ChartComponent.availableColors[0]],
      hoverBackgroundColor: [ChartComponent.shade(lightenBy, ChartComponent.availableColors[0], false, true)],
      borderWidth: 2
    }];

    var length: number = (this.getChartDatasets().length == 0) ? 0 : this.getChartDatasets()[0].data.length;
    var colors: string[] = ChartComponent.availableColors;

    var i = 0;
    while (colors.length < length) {
      i++;
      colors = colors.concat(ChartComponent.availableColors.map(c => ChartComponent.shade(lightenBy, c, colors[i] == c ? false : colors[i], true)));
    }

    colors = colors.slice(0, length);
    var hoverColors: string[] = colors.map(c => ChartComponent.shade(lightenBy, c, false, true));

    return [
      {
        backgroundColor: colors,
        hoverBackgroundColor: hoverColors,
        borderWidth: 2
      }
    ];
  }

  public chartClicked(e: any) {
    if (!this.chart) return;
    this.onClick.emit({ event: e, chart: this.chart, clickedElement: this.chart.getElementAtEvent(e) });
  }

  private mapChartColors(): any {
    return this.data.map(point => point.backgroundColor);
  }

  public setupChart(data?: any, labels?: any): void {
    var context = document.getElementById(this.myId);

    if (context == null) setTimeout(_ => this.setupChart(), 100);
    if (this.chart != null || context == null) return;

    //this.options = Object.assign({}, this.options, { hover: { mode: 'nearest', intersec: true } });
    this.data = this.getChartDatasets();
    this.colors = (this.data.length > 0 && this.data[0].backgroundColor) ? this.mapChartColors() : this.getChartColors();
    this.labels = labels || (this.chartLabels || this.getChartLabels());

    this.data.forEach((value, i) => { if (this.colors[i]) value = Object.assign(value, this.colors[i]); });

    var chartData: any = {
      type: this.chartType,
      data: {
        datasets: data ? data : this.data
      },
      options: this.options
    };

    if (this.labels && this.labels.length > 0) {
      $.extend(true, chartData, { data: { labels: this.labels } });
    }

    $.extend(true, chartData, this.configuration);

    chartData.plugins = this.plugins;

    this.chart = new Chart(context, chartData);
    this.afterLoaded.emit(this.chart);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart != null && changes != null && changes.dataSet != null) {
      if (changes.dataSet.previousValue && JSON.stringify(changes.dataSet.previousValue.map(i => { return { label: i.label, data: i.data, dataPointLabels: i.dataPointLabels } })) == JSON.stringify(changes.dataSet.currentValue))
        return;
    }

    if (this.chart != null) this.chart.destroy();
    this.chart = null;
    this.setupChart();
  }

  ngOnInit(): void {
    this.setupChart();
  }

  public static shade(lightenPercentage: any, color0: string, color1: any, linearBlend: boolean): string {
    let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a: any = typeof (color1) == "string";
    if (typeof (lightenPercentage) != "number" || lightenPercentage < -1 || lightenPercentage > 1 || typeof (color0) != "string" || (color0[0] != 'r' && color0[0] != '#') || (color1 && !a)) return null;
    var pSBCr = (d) => {
      let n = d.length, x: any = {};
      if (n > 9) {
        [r, g, b, a] = d = d.split(","), n = d.length;
        if (n < 3 || n > 4) return null;
        x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
      } else {
        if (n == 8 || n == 6 || n < 4) return null;
        if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
        d = i(d.slice(1), 16);
        if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
        else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
      } return x
    };
    h = color0.length > 9, h = a ? color1.length > 9 ? true : color1 == "c" ? !h : false : h, f = pSBCr(color0), P = lightenPercentage < 0, t = color1 && color1 != "c" ? pSBCr(color1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, lightenPercentage = P ? lightenPercentage * -1 : lightenPercentage, P = 1 - lightenPercentage;
    if (!f || !t) return null;
    if (linearBlend) r = m(P * f.r + lightenPercentage * t.r), g = m(P * f.g + lightenPercentage * t.g), b = m(P * f.b + lightenPercentage * t.b);
    else r = m((P * f.r ** 2 + lightenPercentage * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + lightenPercentage * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + lightenPercentage * t.b ** 2) ** 0.5);
    a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * lightenPercentage : 0;
    if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
    else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
  }
}
