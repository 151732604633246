<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="text-muted fas fa-chevron-down"></i></span>
  <input #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1" (focusout)="testSelection($event)" #countryInputText matInput placeholder="Contact" (change)="setSelection($event.value)" appStaticEdit [editable]="editable" [ngModel]="selectedContact?.contact?.name" class="form-control form-control-sm" (keyup)="filterList()" type="text" max="100" [matAutocomplete]="countryList" />
  <mat-autocomplete (opened)="filterList()" autoActiveFirstOption #countryList="matAutocomplete" (optionSelected)="setSelection($event.option.value)">
    <mat-option *ngFor="let contact of filteredContactList" [value]="contact">
      {{contact?.contact?.name}}
    </mat-option>
    <mat-option *ngIf="filteredContactList.length==0">
      <button class="float-right btn btn-primary btn-sm mt-4" (click)="onAddContact()">
        <i class="fas fa-fw fa-lg fa-plus"></i> Add New Contact
      </button>
      <p class="text-muted small text-center mt-3">
        <em>-- No Matching Contacts Found --</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</div>
