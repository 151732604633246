<mat-card title="Open Document" (click)="openDocument(document?.document)" *ngFor="let document of files" class="d-inline-block mr-2 mb-3">
  <mat-card-header>
    <mat-card-title class="text-center display-block w-100">
      <img *ngIf="isImage(document?.document); else docIcon" [src]="getImageThumbnail(document?.document)" />
      <ng-template #docIcon>
        <i [ngClass]="getFileIcon(document?.document)" class="fa-fw fa-3x text-info"></i>
      </ng-template>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="text-center">
    <button title="Delete Document" *ngIf="editable" (click)="onDelete(document?.document)" class="deleteDocument btn btn-sm position-absolute">
      <i class="fas fa-fw fa-2x fa-times-circle text-danger"></i>
    </button>
    <span class="text-muted docname" [title]="document?.document?.name">{{document?.document?.name}}</span>
    <div>
      <mat-chip-list #chipList aria-label="Document Tags">
        <mat-chip title="Document Tag" class="text-secondary small" *ngFor="let tag of document?.document?.tags"
                  [selectable]="editable"
                  [removable]="editable"
                  (removed)="removeTag(document?.document, tag)">
          {{tag}}
          <i matChipRemove *ngIf="editable" title="Remove Tag" class="fas text-muted fa-fw fa-2x fa-times-circle"></i>
        </mat-chip>
        <div class="form-group">
          <input placeholder="No tags entered." (keyup)="tagInputChanged()" (click)="ignoreOpen($event)" *ngIf="editable" class="form-control form-control-sm" placeholder="New Tag..."
                 #tagInput
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="addNewTag(document?.document, $event)">
        </div>
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag(document?.document, $event)">
        <mat-option *ngFor="let tag of filteredTags">
          {{tag}}
        </mat-option>
      </mat-autocomplete>
    </div>
  </mat-card-content>
</mat-card>
