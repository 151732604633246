<div class="container-fluid">
  <a
    class="position-fixed fixed-bottom btn btn-badge"
    title="Add New Report"
    routerLink="/report-embed/new">
    +
  </a>

  <div class="row border">
    <search-bar
      class="w-100"
      [(searchString)]="searchString"
      (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results">
      <loading></loading>
    </div>

    <search-result
      *ngIf="results"
      [results]="searchResults"
      class="w-100"
      (newPage)="getPage($event)"
      (selected)="onSelect($event)"
      >
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('reportTitle')">
            Title
            <i [ngClass]="getSortIconFor('reportTitle')"></i>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.reportTitle}}</td>
      </ng-template>
    </search-result>
  </div>

</div>
