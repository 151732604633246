import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { Router } from '@angular/router';
import { UtilityService } from '../../../common/services/utility.service';
import { UserService } from '../../../common/services/user.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'global-constants-editor',
  templateUrl: './global-constants-editor.component.html',
})
export class GlobalConstantsEditorComponent implements OnInit {
    @Input() sidenav: MatSidenav;
    public saving = false;

  constructor(private navService: NavigationService, private router: Router, private service: AdminService) {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Global Constants");
  }
  
  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public constants: { id: string, value: string, title: string, category: string }[]
  ngOnInit() {
    this.service.listGlobalConstants().subscribe(o => {
        this.constants = o;
    });
  }
  public getConstant(id: string) {
    return this.constants.find(x => x.id === id);
  }
  public async save() {
    this.saving = true;
    this.constants = await this.service.saveGlobalConstants(this.constants).toPromise();
    this.saving = false;
  }

}
