import { Component, ViewChild, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { Material } from '../../../order/resources/material';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { Station } from '../../../order/resources/station';
import { PseudoPurchasedItemPartNumber, PurchaseOrder } from '../../resources/purchaseOrder';
import { OutsideProcessByProduct, PurchasingService } from '../../services/purchasing.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { OutsideProcessDescription } from '../../resources/outsideProcessDescription';
import { UtilityService } from '../../../common/services/utility.service';
import { Product } from '../../../order/resources/product';

export type NewLineItemData = { type: NewLineItemSelectComponent['type'], purchasedItemPartNumber?: PseudoPurchasedItemPartNumber, material?: Material, outsideProcess?: OutsideProcessDescription, misc?: string }

@Component({
  selector: 'new-line-item-select',
  templateUrl: './new-line-item-select.component.html',
  styleUrls: ['./new-line-item-select.component.less']
})
export class NewLineItemSelectComponent {
  @Input() sidenav: MatSidenav;
  @Input() purchaseOrder: PurchaseOrder;
  public type: 'PURCHASED' | 'MATERIAL' | 'OUTSOURCED' | 'MISC' = 'PURCHASED';
  public item: NewLineItemData = { type: 'PURCHASED' };
  @Output() itemChange = new EventEmitter<NewLineItemData>();
  public selectedPurchasedItemPartNumber: PseudoPurchasedItemPartNumber | null = null;
  public selectedMaterial: Material | null = null;
  public selectedOutsideProcess: OutsideProcessDescription | null = null;
  public saving = false;

  constructor(private navService: NavigationService, private dialog: MatDialog, private service: PurchasingService) {
  }

  @ViewChild('newPurchasedItemDialogTemplate', { static: true }) newPurchasedItemDialogTemplate: TemplateRef<any>;
  public async onAddItem() {
    const ref = this.dialog.open<any, any, { name: string, partNumber: string }>(this.newPurchasedItemDialogTemplate, {
      disableClose: true,
      data: {
        item: {
          name: '',
          partNumber: ''
        }
      }
    });
    const r = await ref.afterClosed().toPromise();
    if (!r) return;
    this.saving = true;
    const item = await this.service.quickAddPurchasedItem(r, this.purchaseOrder.vendor).toPromise();
    this.selectedPurchasedItemPartNumber = item;
    this.saving = false;
  }

  public selectedTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.item.type = 'PURCHASED';
      this.item.material = null;
      this.item.outsideProcess = null;
      this.item.misc = null;
    }
    else if (event.index === 1) {
      this.item.type = 'MATERIAL';
      this.item.purchasedItemPartNumber = null;
      this.item.outsideProcess = null;
      this.item.misc = null;
    }
    else if (event.index === 2) {
      this.item.type = 'OUTSOURCED';
      this.item.purchasedItemPartNumber = null;
      this.item.material = null;
      this.item.misc = null;
      this.item.outsideProcess = null;
    }
    else if (event.index === 3) {
      this.item.type = 'MISC';
      this.item.purchasedItemPartNumber = null;
      this.item.material = null;
      this.item.outsideProcess = null;
      this.item.misc = '';
    }
    this.itemChange.emit(this.item);
  }

  public setPurchased(p: PseudoPurchasedItemPartNumber) {
    this.item.purchasedItemPartNumber = p;
    this.itemChange.emit(this.item);
  }

  public setMaterial(p: Material) {
    this.item.material = p;
    this.itemChange.emit(this.item);
  }

  public setOutsideProcess(p: OutsideProcessDescription) {
    this.item.outsideProcess = p;
    this.itemChange.emit(this.item);
  }

  private getBlankProcess() {
    return {
        outsideProcessDescriptionId: UtilityService.emptyGuid,
        productId: UtilityService.emptyGuid,
        product: null,
        steps: [{
          outsideProcessDescriptionId: UtilityService.emptyGuid,
          outsideProcessDescriptionStepId: UtilityService.emptyGuid,
          order: 0,
          stationId: null,
          station: null,
          outsideProcessSpecificationNames: [],
          outsideProcessSpecifications: [],
          description: ''
        }],
        outsideProcessDescriptionNumber: null
      }  
  }

  public onOPSelected(input: OutsideProcessByProduct | string) {
    let op: OutsideProcessDescription;
    if (typeof input === 'string') {
      op = this.getBlankProcess();
      op.productId = UtilityService.emptyGuid;
      op.product = Product.newEmptyProduct();
      op.product.partNumber = input;
    } else {
      op = input.outsideProcessDescription
      if (!op) {
        op = this.getBlankProcess();
        op.product = input.product;
      }
    }
    this.item.outsideProcess = op;
  }

}
