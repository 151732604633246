import { Injectable } from "@angular/core";
import { WorkOrder } from "../resources/work-order";
import { Product } from "../../order/resources/product";
import { MaterialBid } from "../../purchasing/resources/materialBid";
import { Observable, ReplaySubject } from "rxjs";
import { filter, map, shareReplay, take } from "rxjs/operators";


@Injectable()
export class PlanningDetailService {
    public allProductsFlat: Product[];

    public getProductQuantity(product: Product) {
        if (!product.parentAssemblyId) return product.orderQuantity;
        else {
            const parent = this.allProductsFlat.find(p => p.productId === product.parentAssemblyId);
            return product.quantityAsChild * this.getProductQuantity(parent);
        }
    }

    public quotesMap = new ReplaySubject<{[key:string]: MaterialBid[]}>(1);
    constructor() {
        this.quotesMap.next({});
    }

    public resetQuotes(changes: { [key: string]: MaterialBid[]}) {
        this.quotesMap.pipe(take(1)).subscribe(oldMap => {
            const combined = { ...oldMap, ...changes };
            this.quotesMap.next(combined);
        })
    }
    public addQuotes(itemId: string, newQuotes: MaterialBid[]) {
        this.quotesMap.pipe(take(1)).subscribe(oldMap => {
            const itemQuotes = oldMap[itemId] ?? [];
            itemQuotes.push(...newQuotes);
            const newMap = { ...oldMap, [itemId]: itemQuotes };
            this.quotesMap.next(newMap);
        })
    }
    private quoteObservableCache: { [key: string]: Observable<MaterialBid[]> } = {};
    public getQuotesForItem(itemId: string) {
        if (this.quoteObservableCache[itemId]) return this.quoteObservableCache[itemId];
        const obs = this.quotesMap.pipe(
            map((quotesMap) => quotesMap[itemId] ?? []),
            shareReplay(1)
        );
        this.quoteObservableCache[itemId] = obs;
        return obs;
    }

    public async getQuote(itemId: string, quoteId: string) {
        const quote = await this.getQuotesForItem(itemId).pipe(take(1), map(bids => bids.find(b => b.materialBidId === quoteId))).toPromise();
        return quote;
    }

    public getProductName(productId: string) {
        const prod = this.allProductsFlat.find(p => p.productId === productId);
        if (!prod) return 'Unknown Part';
        else return `${prod.partNumber} Rev. ${prod.revision}`;
    }

    public getChildren(productId: string) {
        const products: Product[] = JSON.parse(JSON.stringify(this.allProductsFlat));
        products.forEach(parent => parent.childAssemblies = products.filter(child => child.parentAssemblyId == parent.productId));
        return products.find(prod => prod.productId === productId)?.childAssemblies;
    }

    public getTopPartWithUpdate(newProduct: Product) {
        const idx = this.allProductsFlat.findIndex(p => p.productId === newProduct.productId);
        this.allProductsFlat.splice(idx, 1, newProduct);
        const products: Product[] = JSON.parse(JSON.stringify(this.allProductsFlat));
        products.forEach(parent => parent.childAssemblies = products.filter(child => child.parentAssemblyId == parent.productId));
        return products.find(prod => !prod.parentAssemblyId);
    }
}