import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: "https://afe4a891c3e01ea766c428fa4ed6530a@o856700.ingest.us.sentry.io/4507233007697920",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [window.location.origin],
        blockAllMedia: false,
        networkCaptureBodies: true,
      }),
      Sentry.feedbackIntegration({
        showBranding: false,
        colorScheme: 'light',
        themeLight: {
          submitBorder: '#FD7037',
          submitBackground: '#FD7037',
        },
        useSentryUser: {
          email: 'email',
          name: 'name'
        },
        showEmail: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        //returns installed service workers
        if (registrations.length) {
          for(let registration of registrations) {
            registration.unregister();
        }
     }
  });
}



platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
