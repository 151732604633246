<div>
  <div class="row">

    <mat-sidenav-container>
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <station-editor *ngIf="showEditor=='stations'" [sidenav]="sidenav"></station-editor>
        <material-editor *ngIf="showEditor=='materials'" [sidenav]="sidenav"></material-editor>
        <department-editor *ngIf="showEditor=='departments'" [sidenav]="sidenav"></department-editor>
        <building-editor *ngIf="showEditor=='buildings'" [sidenav]="sidenav"></building-editor>
        <paint-editor *ngIf="showEditor=='paints'" [sidenav]="sidenav"></paint-editor>
        <purchased-item-editor *ngIf="showEditor=='purchased'" [sidenav]="sidenav"></purchased-item-editor>
        <outside-process-editor *ngIf="showEditor=='process'" [sidenav]="sidenav"></outside-process-editor>
        <qc-clause-editor *ngIf="showEditor=='qcclause'" [sidenav]="sidenav"></qc-clause-editor>
        <global-constants-editor *ngIf="showEditor=='constants'" [sidenav]="sidenav"></global-constants-editor>
      </mat-sidenav>
      <mat-sidenav-content>

        <div class="container-fluid main">
          <div class="row">
            <div class="col-6">
              <div class="m-2 clickable buildings" (click)="showSidebar('departments')">
                <h3>Department Editor</h3>
              </div>
            </div>
            <div class="col-6">
              <div class="m-2 clickable buildings" (click)="showSidebar('buildings')">
                <h3>Building Editor</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="m-2 clickable materials" (click)="showSidebar('materials')">
                <h3>Material Editor</h3>
              </div>
            </div>
            <div class="col-4">
              <div class="m-2 clickable paint" (click)="showSidebar('paints')">
                <h3>Paint Editor</h3>
              </div>
            </div>
            <div class="col-4">
              <div class="m-2 clickable stations" (click)="showSidebar('purchased')">
                <h3>Purchased Item/Hardware Editor</h3>
              </div>
            </div>
            <div class="col">
              <div class="m-2 clickable stations" (click)="showSidebar('stations')">
                <h3>Station Editor</h3>
              </div>
            </div>
            <div class="col">
              <div class="m-2 clickable stations" (click)="showSidebar('process')">
                <h3>Outside Process Editor</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="m-2 clickable stations" (click)="showSidebar('qcclause')">
                <h3>QC Clause Editor</h3>
              </div>
            </div>
            <div class="col">
              <div class="m-2 clickable stations" (click)="showSidebar('constants')">
                <h3>Global Constants</h3>
              </div>
            </div>
          </div>
        </div>

  <button mat-flat-button color="warn" class="button-text" (click)="docrash()">Crash COTS2</button>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
</div>
