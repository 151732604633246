<div>
  <a *ngIf="record && !editing && !insetnav?.opened" class="position-fixed fixed-bottom btn btn-badge has-icon"
    title="Edit Record" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
  <a *ngIf="record && editing && !insetnav?.opened"
    class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
    (click)="toggleEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="record && editing && !insetnav?.opened"
    class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save Record"
    (click)="saveChanges(true)"><i class="fas fa-save"></i></a>
  <a *ngIf="record && !editing && !insetnav?.opened"
    class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Delete/Disable"
    (click)="delete()"><i class="fas fa-minus-circle"></i></a>

  <div class="row">

    <loading *ngIf="!record"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <mat-sidenav-container *ngIf="record">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #insetnav mode="over" position="end">
        <vendor-cert-detail *ngIf="showEditor=='cert'" [sidenav]="insetnav" (certChange)="onCertificateUpdated($event)"
          [vendor]="record" [cert]="selectedCert"></vendor-cert-detail>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="ml-4 pt-4 container-fluid">
          <div class="row form-group mr-2">
            <div class="col-4">
              <input #name="ngModel" minlength="3" [(ngModel)]="record.name" required placeholder="Vendor Name"
                type="text" max="100" class="form-control form-control-lg" appStaticEdit [editable]="editing" />
            </div>
            <div class="col-3 mt-3">
              <h5>Certified Thru: <span
                  [ngClass]="getStatusColorClass(record.certificationDate)">{{record.certificationDate | date}}</span>
              </h5>
            </div>
            <div class="col-5">
              <div class="container-fluid">
                <div class="col-sm-3 d-inline-block align-top">
                  <div class="vendor-score filled">
                    <span class="scoreCirc" [ngStyle]="getScoreStyles(getOverallScore(), true)"></span>
                    <span class="scoreValue">{{getOverallScore()}}</span>
                    <span class="scoreLabel">Overall Score</span>
                  </div>
                </div>
                <div class="col-sm-3 d-inline-block align-top">
                  <div class="vendor-score">
                    <span class="scoreCirc" [ngStyle]="getScoreStyles(record.onTimeScore)"></span>
                    <span class="scoreValue">{{record.onTimeScore}}</span>
                    <span class="scoreLabel">On Time Performance</span>
                  </div>
                </div>
                <div class="col-sm-3 d-inline-block align-top">
                  <div class="vendor-score">
                    <span class="scoreCirc" [ngStyle]="getScoreStyles(record.qualityScore)"></span>
                    <span class="scoreValue">{{record.qualityScore}}</span>
                    <span class="scoreLabel">Product Quality</span>
                  </div>
                </div>
                <div class="col-sm-3 d-inline-block align-top">
                  <div class="vendor-score">
                    <span class="scoreCirc" [ngStyle]="getScoreStyles(record.subjectiveScore)"></span>
                    <span class="scoreValue">
                      <input placeholder="Subjective" type="number" min="0" max="100"
                        class="control-score form-control form-control-lg" appStaticEdit [editable]="editing"
                        [(ngModel)]="record.subjectiveScore" />
                      <span *ngIf="editing" class="small position-absolute editHolder"><i
                          class="fas fa-edit"></i></span>
                    </span>
                    <span class="scoreLabel">
                      Subjective
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mat-stepper [selectedIndex]="selectedTab" (selectionChange)="setSelectedTab($event)"
            [linear]="null" #stepper>
            <ng-template matStepperIcon="edit">
              <i class="fas fa-check fa-fw"></i>
            </ng-template>
            <ng-template matStepperIcon="done">
              <i class="fas fa-check fa-fw"></i>
            </ng-template>
            <mat-step label="Vendor Info">
              <div class="mb-3 mt-1 mr-5 p-2 bg-light">
                <button disabled style="visibility:hidden" class="btn btn-sm btn-default">[placeholder]</button>
                <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Payment Terms</label>
                    <input class="form-control form-control-sm" [class.form-control-plaintext]="!editing"
                           placeholder="Default Payment Terms" [disabled]="!editing" type="text" matInput
                           [(ngModel)]="record.defaultPaymentTerms" [matAutocomplete]="termsAuto">
                    <mat-autocomplete placeholder="Payment Terms" #termsAuto="matAutocomplete"
                                      (optionSelected)="record.defaultPaymentTerms = $event.option.value || $event.option.viewValue">
                      <mat-option>Cash In Advance</mat-option>
                      <mat-option>C.O.D</mat-option>
                      <mat-option>Net 30</mat-option>
                      <mat-option>Net 45</mat-option>
                      <mat-option>Net 60</mat-option>
                      <mat-option>Credit Card</mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Account Number</label>
                    <input placeholder="Account Number" type="text" max="100" class="form-control form-control-sm"
                      appStaticEdit [editable]="editing" [(ngModel)]="record.accountNumber" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Default PO Tax Rate</label>
                    <div class="input-group input-group-sm">
                      <input placeholder="Default PO Tax Rate" type="number" min="0" step="0.1" class="form-control form-control-sm"
                        appStaticEdit [editable]="editing" [(ngModel)]="record.defaultTaxRate" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <mat-checkbox [(ngModel)]="record.shippingTaxable" [disabled]="!editing">
                    Shipping/Handling Taxable by Default
                  </mat-checkbox>
                </div>
              </div>
              <div>
                <div *ngIf="!contactsView" class="row">
                  <div class="col-9">
                    <!-- Address Management -->
                    <div class="row border rounded mb-1">
                      <div class="col-4 border-right px-0">
                        <h5 class="m-0 p-2 bg-secondary text-center">
                          Addresses
                          <div class="text-muted" style="
                          position: absolute;
                          right: 5px;
                          top: 14px;
                          font-size: .6em;
                          ">Primary</div>
                        </h5>
                        <table class="table table-sm table-striped">
                          <tr *ngIf="record.vendorAddresses?.length==0">
                            <td class="text-center text-muted" colspan="100%">
                              <em>-- No Addresses Entered --</em>
                            </td>
                          </tr>
                          <tr *ngFor="let vendorAddress of record.vendorAddresses">
                            <td class="pl-2 cursor-pointer d-flex align-items-center"
                              (click)="switchToAddress(vendorAddress)"
                              [class.bg-primary]="selectedAddress?.vendorAddressId === vendorAddress.vendorAddressId"
                              [class.text-light]="selectedAddress?.vendorAddressId === vendorAddress.vendorAddressId"
                              [class.address-selected]="selectedAddress?.vendorAddressId === vendorAddress.vendorAddressId">
                              <span *ngIf="!!vendorAddress.address.nickname">{{vendorAddress.address.nickname}}</span>
                              <span class="font-italic" *ngIf="!vendorAddress.address.nickname">--Unnamed
                                Address--</span>
                              <mat-radio-button class="primary-vendor-radio ml-auto px-2"
                                *ngIf="editing && vendorAddress.addressId !== '00000000-0000-0000-0000-000000000000'"
                                [checked]="record.primaryAddressId === vendorAddress.addressId"
                                (click)="onPrimaryAddressClick($event, vendorAddress.addressId)">
                              </mat-radio-button>
                              <div class="fas fa-check ml-auto px-2"
                                *ngIf="!editing && record.primaryAddressId === vendorAddress.addressId"></div>
                            </td>
                          </tr>
                        </table>
                        <div class="text-center" *ngIf="editing">
                          <button class="btn btn-primary btn-sm mb-2" [class.invisible]="creatingAddress"
                            (click)="newAddress()"><i class="fas fa-plus"></i> Add Address</button>
                        </div>
                      </div>
                      <div class="col-8 py-2">
                        <div *ngIf="!selectedAddress"
                          class="w-100 h-100 relative top-0 left-0 d-flex align-items-center justify-content-center text-muted">
                          -- Select an address from the left. --
                        </div>
                        <form #addressForm="ngForm" [class.invisible]="!selectedAddress"
                          (submit)="saveAddress(selectedAddress)">
                          <ng-container *ngIf="selectedAddress">
                            <div class="form-group">
                              <label>Address Nickname</label>
                              <input placeholder="Nickname" type="text" max="100" class="form-control form-control-sm"
                                appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.nickname"
                                name="nickname" required />
                            <div class="small text-muted font-italic">An identifying name for the address. Will not show up in any reports or quotes.</div>
                            </div>
                            <div class="form-group">
                              <label>Street Address</label>
                              <input placeholder="Street Address" type="text" max="100"
                                class="form-control form-control-sm" appStaticEdit [editable]="editing"
                                [(ngModel)]="selectedAddress?.address.streetAddress" name="streetAddress" required />
                            </div>
                            <div class="form-group">
                              <label>Second Line (optional)</label>
                              <input placeholder="Second Line" type="text" max="100" class="form-control form-control-sm"
                                appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.streetAddress2"
                                name="streetAddress2" />
                            </div>
                            <div class="form-group">
                              <label>City</label>
                              <input placeholder="City" type="text" max="100" class="form-control form-control-sm"
                                appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.city" name="city"
                                required />
                            </div>
                            <div class="form-group">
                              <label>State</label>
                              <input placeholder="State" type="text" max="100" class="form-control form-control-sm"
                                appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.state"
                                name="state" required />
                            </div>
                            <div class="form-group">
                              <label>Zip</label>
                              <input placeholder="Zip" type="text" max="100" class="form-control form-control-sm"
                                appStaticEdit [editable]="editing" [(ngModel)]="selectedAddress?.address.postalCode"
                                name="postalCode" required />
                            </div>
                            <div class="row">
                              <div class="col-6 form-group">
                                <label>Phone Number</label>
                                <phone-number placeholder="Phone Number" [editable]="editing" [(number)]="selectedAddress?.address.phoneNumber" name="phone"></phone-number>
                              </div>
                              <div class="col-6 form-group">
                                <label>Fax</label>
                                <phone-number placeholder="Fax" [editable]="editing" [(number)]="selectedAddress?.address.faxNumber" name="fax"></phone-number>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Contact</label>
                              <mat-select [(ngModel)]="selectedAddress?.address.contact" name="contact" *ngIf="editing"
                                (ngModelChange)="setAddressContactId($event)">
                                <mat-option [value]="null">
                                  <div class="text-muted font-italic">None</div>
                                </mat-option>
                                <mat-option *ngFor="let vContact of record.vendorContacts" [value]="vContact.contact">
                                  {{ vContact.contact.name }}
                                </mat-option>
                              </mat-select>
                              <input placeholder="None" type="text" max="100" class="form-control form-control-sm"
                                appStaticEdit [editable]="false" [value]="selectedAddress?.address.contact?.name"
                                *ngIf="!editing" />
                            </div>
                            <div class="w-100 d-flex justify-content-end" *ngIf="editing">
                              <button class="btn btn-danger" type="button" (click)="deleteCurrentAddress()">
                                <i class="fas fa-times mr-1"></i>{{creatingAddress ? 'Cancel' : 'Delete'}}</button>
                              <button class="btn btn-success ml-2" type="submit"
                                [disabled]="addressForm.pristine || addressForm.invalid">
                                <i class="fas fa-trash mr-1"></i>Save</button>
                            </div>
                          </ng-container>
                        </form>
                      </div>
                    </div>
                    <!-- /Address Management -->
                    <div class="row pb-2">
                      <div class="col-6 pl-0 pr-1 bg-light">
                        <h5 class="w-100 p-2 pl-1 bg-secondary">Notes<small class="float-right">(Public)</small></h5>
                        <div class="p-1">
                          <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm"
                            [(ngModel)]="record.publicNotes"
                            placeholder="Place general order notes for this vendor here"></textarea>
                        </div>
                      </div>
                      <div class="col-6 pr-0 bg-light">
                        <h5 class="w-100 p-2 bg-secondary">Notes<small class="float-right">(Private)</small></h5>
                        <div class="p-1">
                          <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm"
                            [(ngModel)]="record.privateNotes" placeholder="Internal only notes can go here"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 border-left border-secondary pl-0">
                    <h5 (click)="toggleContactsView()" class="cursor-pointer m-0 p-2 bg-secondary text-center">
                      <i class="fas fa-play fa-rotate-180 float-left"></i> Contacts
                    </h5>
                    <table class="table table-sm table-striped">
                      <tr *ngIf="!record.vendorContacts || record.vendorContacts.length==0">
                        <td class="text-center text-muted" colspan="100%">
                          <em>-- No Contacts Entered --</em>
                        </td>
                      </tr>
                      <tr (click)="toggleContactsView()" *ngFor="let vendorContact of record.vendorContacts">
                        <td><i class="fas fa-fw mr-1" [title]="vendorContact.useForQuoting ? 'Quoting Contact' : ''"
                            [ngClass]="{'fa-quote-right': vendorContact.useForQuoting}"></i>{{vendorContact.contact.name}}
                        </td>
                        <td class="text-muted text-right">{{vendorContact.contact.phoneNumber}}
                          {{vendorContact.contact.phoneExtension==null?'':('ext. '+vendorContact.contact.phoneExtension)}}
                        </td>
                      </tr>
                    </table>
                    <div class="text-center" *ngIf="editing">
                      <button (click)="toggleContactsView()" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i>
                        Add Contact</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactsView" class="row mr-2">
                  <h5 (click)="toggleContactsView()" class="cursor-pointer d-block w-100">
                    <i class="fas fa-play text-muted"></i> Contacts
                    <a href="javascript:void(0);" class="float-right btn btn-sm btn-secondary text-dark">Back to Vendor
                      Info</a>
                  </h5>
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Quote</th>
                        <th>Name</th>
                        <th>Title</th>
                        <th>Phone Number</th>
                        <th style="width: 85px">Extension</th>
                        <th>Fax</th>
                        <th>Cell Number</th>
                        <th>Work Email</th>
                        <th>Personal Email</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="!editing && (!record.vendorContacts || record.vendorContacts.length==0)">
                        <td class="text-center text-muted" colspan="100%">
                          <em>-- No Contacts Entered --</em>
                        </td>
                      </tr>
                      <tr *ngFor="let vendorContact of record.vendorContacts">
                        <td class="text-center">
                          <mat-checkbox [(ngModel)]="vendorContact.useForQuoting" [disabled]="!editing"></mat-checkbox>
                        </td>
                        <td>
                          <input required placeholder="Contact Name" type="text" max="100"
                            class="form-control form-control-sm" appStaticEdit [editable]="editing"
                            [(ngModel)]="vendorContact.contact.name" />
                        </td>
                        <td>
                          <input placeholder="Title" type="text" max="100" class="form-control form-control-sm"
                            appStaticEdit [editable]="editing" [(ngModel)]="vendorContact.contact.title" />
                        </td>
                        <td>
                          <phone-number [editable]="editing" [(number)]="vendorContact.contact.phoneNumber">
                          </phone-number>
                        </td>
                        <td>
                          <input placeholder="Extension" type="text" max="100" class="form-control form-control-sm"
                            appStaticEdit [editable]="editing" [(ngModel)]="vendorContact.contact.phoneExtension" />
                        </td>
                        <td>
                          <phone-number [editable]="editing" [(number)]="vendorContact.contact.faxNumber">
                          </phone-number>
                        </td>
                        <td>
                          <phone-number [editable]="editing" [(number)]="vendorContact.contact.cellNumber">
                          </phone-number>
                        </td>
                        <td>
                          <input placeholder="Work Email" type="email" max="100" class="form-control form-control-sm"
                            appStaticEdit [editable]="editing" [(ngModel)]="vendorContact.contact.email" />
                        </td>
                        <td>
                          <input placeholder="Personal Email" type="email" max="100" class="form-control form-control-sm"
                            appStaticEdit [editable]="editing" [(ngModel)]="vendorContact.contact.personalEmail" />
                        </td>
                        <td>
                          <div *ngIf="editing">
                            <button title="Delete" class="btn btn-sm btn-danger"
                              (click)="deleteContact(vendorContact)"><i class="fas fa-fw fa-times"></i></button>
                            &nbsp;
                            <button title="Save" class="btn btn-sm btn-success" (click)="saveContact(vendorContact)"><i
                                class="fas fa-fw fa-save"></i></button>
                          </div>
                          <div *ngIf="!editing">&nbsp;</div>
                        </td>
                      </tr>
                      <tr *ngIf="editing">
                        <td class="text-center">
                          <mat-checkbox [(ngModel)]="newContact.useForQuoting" [disabled]="!editing"></mat-checkbox>
                        </td>
                        <td>
                          <input required minlength="2" placeholder="Contact Name" type="text" max="100"
                            class="form-control form-control-sm" [(ngModel)]="newContact.contact.name" />
                        </td>
                        <td>
                          <input placeholder="Title" type="text" max="100" class="form-control form-control-sm"
                            [(ngModel)]="newContact.contact.title" />
                        </td>
                        <td>
                          <phone-number [editable]="editing" [(number)]="newContact.contact.phoneNumber"></phone-number>
                        </td>
                        <td>
                          <input placeholder="Extension" type="text" max="100" class="form-control form-control-sm"
                            [(ngModel)]="newContact.contact.phoneExtension" />
                        </td>
                        <td>
                          <phone-number [editable]="editing" [(number)]="newContact.contact.faxNumber"></phone-number>
                        </td>
                        <td>
                          <phone-number [editable]="editing" [(number)]="newContact.contact.cellNumber">
                          </phone-number>
                        </td>
                        <td>
                          <input placeholder="Work Email" type="email" max="100" class="form-control form-control-sm"
                            [(ngModel)]="newContact.contact.email" />
                        </td>
                        <td>
                          <input placeholder="Personal Email" type="email" max="100" class="form-control form-control-sm"
                            [(ngModel)]="newContact.contact.personalEmail" />
                        </td>
                        <td>
                          <button title="Cancel" class="btn btn-sm btn-danger" (click)="clearContact()"><i
                              class="fas fa-fw fa-minus-circle"></i></button>
                          &nbsp;
                          <button title="Add" class="btn btn-sm btn-success" (click)="saveContact(newContact)"><i
                              class="fas fa-fw fa-plus"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-step>
            <mat-step label="Certifications">
              <div class="mb-3 mt-1 mr-5 p-2 bg-light">
                <button class="btn btn-sm btn-default ml-2" matStepperPrevious>Back</button>
                <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
              </div>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-8">
                    <table class='table search-results table-striped table-borderless table-hover thead-light'>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Certificate Name</th>
                          <th>Issue Date</th>
                          <th>Expiration Date</th>
                          <th>Documentation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let cert of record.vendorCertificates" (click)="openCertificate(cert)">
                          <td>
                            <div *ngIf="editing">
                              <button title="Delete" class="btn btn-sm btn-danger" (click)="deleteCert(cert)"><i
                                  class="fas fa-fw fa-times"></i></button>
                            </div>
                          </td>
                          <td>{{cert.certificateName}}</td>
                          <td>{{cert.issueDate | date}}</td>
                          <td>
                            <span [ngClass]="getStatusColorClass(cert.expirationDate)">
                              {{(cert.expirationDate | date) || '---'}}
                            </span>
                          </td>
                          <td>
                            <span
                              *ngIf="cert.vendorCertificateDocuments.length>0">{{cert.vendorCertificateDocuments.length}}
                              Documents</span>
                            <span *ngIf="cert.vendorCertificateDocuments.length==0"
                              class="text-center text-muted font-italic">-- No Documents --</span>
                          </td>
                        </tr>
                        <tr *ngIf="!record.vendorCertificates || record.vendorCertificates.length==0">
                          <td class="text-center text-muted font-italic" colspan="100%">
                            -- No Certificates Entered --
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right" *ngIf="editing">
                      <button class="btn btn-sm btn-primary" (click)="addCertification()"><i class="fas fa-plus"></i>
                        Add Certification</button>
                    </div>
                  </div>
                  <div class="col-4">
                    <p *ngIf="!approvedSupplierList" class="text-center">
                      <i class="fas fa-2x fa-circle-notch fa-spin text-primary"></i>
                    </p>
                    <table class="table search-results table-striped table-borderless table-hover thead-light"
                      *ngIf="approvedSupplierList">
                      <thead>
                        <tr>
                          <th>Certified Supplier For</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let customer of approvedSupplierList" (click)="goToCustomer(customer)">
                          <td>{{customer.businessName}}</td>
                        </tr>
                        <tr *ngIf="approvedSupplierList.length==0">
                          <td class="text-center text-muted font-italic" colspan="100%">
                            -- No Companies Have Certified this Supplier --
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p class="font-italic text-muted text-center small" *ngIf="editing">
                      <i class="text-info fas fa-fw fa-lg fa-info-circle"></i>
                      Vendors are Added to a Customer's Approved Suppliers List
                      <span class="d-block small">
                        To add this supplier to a customer, first navigate to that customer.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </mat-step>
            <mat-step label="Supplied Products">
              <div class="mb-3 mt-1 mr-5 p-2 bg-light">
                <button class="btn btn-sm btn-default ml-2" matStepperPrevious>Back</button>
                <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
              </div>
              <div class="row">
                <div class="col-6">
                  <table class='table search-results table-striped table-borderless table-hover thead-light'>
                    <thead>
                      <tr>
                        <th>Supplied Materials</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let group of materialGroupList"
                        (click)="toggleMaterialCapability(group.materialGroupId)">
                        <td>
                          <mat-checkbox [disabled]="!editing" [ngClass]="{'enabled':editing}"
                            [checked]="hasMaterialCapability(group.materialGroupId)" (click)="$event.preventDefault()">
                            {{group.groupName}}
                          </mat-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6">
                  <table class='table search-results table-striped table-borderless table-hover thead-light'>
                    <thead>
                      <tr>
                        <th>Supplied Processes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let station of outsourcedStationsList"
                        (click)="toggleOutsourceCapability(station.stationId)">
                        <td>
                          <mat-checkbox [disabled]="!editing" [ngClass]="{'enabled':editing}"
                            [checked]="hasOutsourceCapability(station.stationId)" (click)="$event.preventDefault()">
                            {{station.name}}
                          </mat-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-step>
            <mat-step label="QC Clauses">
              <div class="mb-3 mt-1 mr-5 p-2 bg-light">
                <button class="btn btn-sm btn-default ml-2" matStepperPrevious>Back</button>
                <button class="btn btn-sm btn-default mr-2 float-right" matStepperNext>Next</button>
              </div>
              <div class="row pb-4">
                <div class="col-2 mb-4" *ngFor="let clause of qualityClauseList">
                  <mat-card class="h-100 cursor-default border mat-elevation-z0 qc-card"
                    [class.border-primary]="getQcClauseApplication(clause).override">
                    <mat-card-header>
                      <mat-card-title>
                        {{clause.number}}
                      </mat-card-title>
                      <mat-card-subtitle>
                        {{clause.title || '&nbsp;'}}
                      </mat-card-subtitle>
                      <span *ngIf="getQcClauseApplication(clause).override"
                        class="badge align-self-start rounded bg-primary text-white font-weight-bold">
                        OVERRIDEN
                      </span>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="qcText font-italic">
                        {{ clause.text }}
                      </div>
                    </mat-card-content>
                    <mat-card-footer class="px-2 py-1">
                      <div class="my-1 small text-muted d-flex align-items-center"
                        [class.text-primary]="getQcClauseApplication(clause).hardware !== clause.hardwareDefault"
                        *ngIf="vendorCanDo('purchases')">
                        <mat-icon [inline]="true" class="mr-1">
                          {{
                            getQcClauseApplication(clause).hardware ? 'done' : 'close'
                          }}
                        </mat-icon>
                        <span [class.strikethrough]="!getQcClauseApplication(clause).hardware">Hardware</span>
                      </div>
                      <div class="my-1 small text-muted d-flex align-items-center"
                        [class.text-primary]="getQcClauseApplication(clause).material !== clause.materialDefault"
                        *ngIf="vendorCanDo('materials')">
                        <mat-icon [inline]="true" class="mr-1">
                          {{
                            getQcClauseApplication(clause).material ? 'done' : 'close'
                          }}
                        </mat-icon>
                        <span [class.strikethrough]="!getQcClauseApplication(clause).material">Material</span>
                      </div>
                      <div class="my-1 small text-muted d-flex align-items-center"
                        [class.text-primary]="getQcClauseApplication(clause).process !== clause.processDefault"
                        *ngIf="vendorCanDo('outsourced')">
                        <mat-icon [inline]="true" class="mr-1">
                          {{
                            getQcClauseApplication(clause).process ? 'done' : 'close'
                          }}
                        </mat-icon>
                        <span [class.strikethrough]="!getQcClauseApplication(clause).process">Outside Processes</span>
                      </div>
                    </mat-card-footer>
                    <button *ngIf="editing" color="primary" (click)="editQcClause(clause)" mat-mini-fab style="position: absolute;
                      bottom: -20px;
                      right: 15px;">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </mat-card>
                </div>
              </div>
            </mat-step>
          </mat-stepper>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>


<ng-template #qcClauseDialogTemplate let-vendorClause>
  <div class="mat-card-title">Quality Clause: {{vendorClause.qualityClause.number}}</div>
  <div class="mat-card-subtitle">{{vendorClause.qualityClause.title}}</div>
  <mat-dialog-content>
    <p class="font-italic text-muted my-3">
      {{vendorClause.qualityClause.text}}
    </p>
    <h5>Used in PO types:</h5>
    <form #form="ngForm" class="smallks">
      <mat-checkbox class="d-block mb-2" [(ngModel)]="vendorClause.forHardware" name="forHardware">
        Hardware
      </mat-checkbox>
      <mat-checkbox class="d-block mb-2" [(ngModel)]="vendorClause.forMaterial" name="forMaterial">
        Material
      </mat-checkbox>
      <mat-checkbox class="d-block mb-2" [(ngModel)]="vendorClause.forProcess" name="forProcess">
        Outside Processing
      </mat-checkbox>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" class="mr-1" [disabled]="form.pristine"
      [matDialogClose]="{action: 'save', newData: vendorClause}">
      Save Changes
    </button>
    <button mat-flat-button color="primary" [disabled]="!getQcClauseApplication(vendorClause.qualityClause).override"
      class="mr-1" [matDialogClose]="{action: 'clear'}">
      Clear Overrides
    </button>
    <button mat-flat-button [matDialogClose]="{action: 'cancel'}">
      Cancel
    </button>
  </mat-dialog-actions>
</ng-template>
