import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanningService } from '../../services/planning.service';
import { OrderStatus } from '../../../order/resources/order';
import { NavigationService } from '../../../common/services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { ProgrammingTicket, ProgrammingType } from '../../resources/programming-ticket';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgForm } from '@angular/forms';
import { Workflow, WorkflowStep } from '../../../order/resources/workflow';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'programming-detail',
  templateUrl: './programming-detail.component.html',
  styleUrls: ['./programming-detail.component.less'],
  animations: [
    trigger('measurementAnimationTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(25%)' }),
        animate('150ms cubic-bezier(0.4, 0.0, 1, 1)', style({ opacity: 1, transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'translateX(-25%)' }))
      ])
    ]),
    trigger('noMeasurementsAnimationTrigger', [
      transition(':enter', [
        style({ opacity: 0, position: 'absolute', width: 'calc(100% - 32px)' }),
        animate('750ms 150ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 })),
      ])
    ]),
  ]
})
export class ProgrammingDetailComponent implements OnInit {
  @Input() record: ProgrammingTicket;
  private id: string;
  public editing: boolean = false;
  public saving: boolean = false;
  public showEditor: string = null;
  @ViewChild("insetnav") insetnav: MatSidenav;
  private stationList: Station[] = null;

  constructor(private route: ActivatedRoute, private router: Router, public stationService: StationService, private navService: NavigationService, private userSvc: UserService, private planningSvc: PlanningService, private utilitySvc: UtilityService, private dialog: MatDialog) {
    this.id = this.route.snapshot.paramMap.get('id');

    navService.setPageTitle('Programming');
    navService.pushBreadcrumb("Ticket Detail");
  }

  public getStatusText(disposition: number): string {
    return OrderStatus.getStatusText(disposition);
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("PlanningManager") || this.userSvc.canAccess("Developer")
    );
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }

  private async getDetail() {
    this.id = this.record ? this.record.programmingTicketId : this.id;

    this.record = await this.planningSvc.getProgrammingTicket(this.id).toPromise();
    this.navService.pushBreadcrumb(this.record.workOrder.workOrderNumber);
  }

  @ViewChild('stepper') stepper: MatStepper;

  public async saveChanges() {

    this.saving = true;

    const record = await this.planningSvc.saveProgrammingTicket(this.record).toPromise();

    this.record = record;
    this.saving = false;
    this.editing = false;
  }

  public async complete() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Are you sure you want to mark this programming ticket as complete?');
    if (!r) return;
    this.saving = true;
    await this.planningSvc.completeProgrammingTicket(this.record).toPromise();
    this.saving = false;
    this.router.navigate(["/programming"]);
  }

  public cancelEditing(): void {
    if (this.editing) {
      this.toggleEditing();
      return;
    }
      this.router.navigate(["/programming"]);
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getDetail();
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
  }

  public getStation(stationId: string) {
    return this.stationList.find(s => s.stationId == stationId);
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  // public get programStepsList() {
  //   return this.record.workOrder.product.workflow.workflowSteps
  // }

  public isHighlighted(workflowStepId: string) {
    return this.record.highlightedByPlanner.includes(workflowStepId);
  }

  public expansionControl = new SelectionModel<WorkflowStep>();

  public creatingMap: { [key: string]: boolean } = {}
  public isCreating(step: WorkflowStep, type: string) {
    return this.creatingMap[`${step.workflowStepId}-${type}`] === true;
  }

  public typeFromString(type: string): ProgrammingType {
    if (type === 'CMM') return ProgrammingType.CMMProgramming
    else if (type === 'NC') return ProgrammingType.NCProgramming
    else if (type === 'CNC') return ProgrammingType.CNCProgramming
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.getDetail();
  }

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  public openDocumentWindow(): void {
    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.sidenav.toggle();
  }

  public addDocuments(documents: VirtualDocument[]): void {
    this.saving = true;

    this.planningSvc.addProgrammingDocuments(this.record, documents).subscribe((docs) => {
      this.saving = false;
      this.record.programmingFiles = [...this.record.programmingFiles, ...docs];
    });
  }

  public selectedStep: WorkflowStep = null;

  public getSpecifications(step: WorkflowStep): string[] {
    return step.specification.split(",");
  }

  public getProgrammingTypeName(type: ProgrammingType) {
    switch (type) {
      case ProgrammingType.CMMProgramming: return 'CMM Programming';
      case ProgrammingType.CNCProgramming: return 'CNC Programming';
      case ProgrammingType.NCProgramming: return 'NC Programming';
    }
  }

  @ViewChild('rejectTemplate') rejectTemplate: TemplateRef<any>;
  public async reject() {
    const dialogRef = this.dialog.open(this.rejectTemplate, {
      disableClose: true,
      data: {
        reason: ''
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    this.saving = true;
    await this.planningSvc.rejectProgrammingTicket(this.record, result).toPromise();
    this.saving = false;
    this.router.navigate(["/programming"]);
  }

}
