<div style="margin: 15px 15px 15px; ">
  <a *ngIf="ruleBuilder && !editing" class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit Record" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
  <a *ngIf="ruleBuilder && editing" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit" (click)="toggleEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="ruleBuilder && editing" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save Record" (click)="saveChanges()"><i class="fas fa-save"></i></a>
  <a *ngIf="ruleBuilder && !editing" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Delete" (click)="deleteRecord()"><i class="fas fa-minus-circle"></i></a>

  <div class="row">
    <loading *ngIf="!ruleBuilder"></loading>

    <div *ngIf="ruleBuilder" class="col-12">
      <div class="form-group">
        <input [formControl]="ruleNameForm" minlength="3" [(ngModel)]="ruleBuilder.name" required placeholder="Rule Name" type="text" max="100" class="form-control form-control-lg" appStaticEdit [editable]="editing" />
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>&nbsp;</label>
            <mat-slide-toggle [(ngModel)]="ruleBuilder.enabled" [disabled]="!this.editing">Enabled?</mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Description</label>
            <textarea rows="3" appStaticEdit [editable]="editing" class="form-control form-control-sm" [(ngModel)]="ruleBuilder.description" placeholder="Rule Description" maxlength="1000"></textarea>
          </div>
        </div>
        <div class="col-6">
        </div>
      </div>

      <div class="row">
        <div class="col-6 only-padding-left-right">
          <div class="form-group">
            <label>Rule Action</label>
            <mat-select *ngIf="editing" class="form-control form-control-sm" [(ngModel)]="ruleBuilder.ruleAction" (selectionChange)="onSelectionChangeRuleAction($event.value)" [disabled]="!this.editing">
              <mat-option *ngFor="let ruleActionItem of lRuleActions" [value]="ruleActionItem">{{ruleActionItem}}</mat-option>
            </mat-select>
            <span *ngIf="!editing" class="d-block small">{{ruleBuilder.ruleAction}}</span>
          </div>
        </div>
        <div class="col-1 only-padding-right">
          <div class="form-group">
            <label>When ?</label>
            <input *ngIf="editing" [(ngModel)]="ruleBuilder.ruleActionWhenValue" type="number" class="form-control form-control-sm" appStaticEdit [editable]="editing" />
            <span *ngIf="!editing" class="d-block small">{{ruleBuilder.ruleActionWhenValue}}</span>
          </div>
        </div>
        <div class="col-1 only-padding-right">
          <div class="form-group">
            <label>&nbsp;</label>
            <mat-select *ngIf="editing" class="form-control form-control-sm" [(ngModel)]="ruleBuilder.ruleActionWhenUnit" (selectionChange)="onSelectionChangeRuleActionWhenUnit($event.value)" [disabled]="!this.editing">
              <mat-option *ngFor="let ruleActionWhenItem of lRuleActionsWhenUnit" [value]="ruleActionWhenItem">{{ruleActionWhenItem}}</mat-option>
            </mat-select>
            <span *ngIf="!editing" class="d-block small">{{ruleBuilder.ruleActionWhenUnit}}</span>
          </div>
        </div>
        <div class="col-1 only-padding-right">
          <div class="form-group">
            <label>&nbsp;</label>
            <mat-select *ngIf="editing" class="form-control-sm" style="visibility: hidden;">
              <mat-option>&nbsp;</mat-option>
            </mat-select>
            <span *ngIf="!editing" class="d-block small">&nbsp;</span>
          </div>
        </div>
        <div class="col-3">
        </div>
      </div>
 
      <div class="row">
        <div class="col-12">
          <div class="col-6 only-padding-right" style="display:inline-block;">
            <div class="form-group" >
              <label>Action to take</label>
              <mat-select *ngIf="editing" class="form-control form-control-sm" [(ngModel)]="ruleBuilder.actionToTake" (selectionChange)="onSelectionChangeRuleActionToTake($event.value)" [disabled]="!this.editing">
                <mat-option *ngFor="let ruleActionToTakeItem of lRuleActionsToTake" [value]="ruleActionToTakeItem">{{ruleActionToTakeItem}}</mat-option>
              </mat-select>
              <span *ngIf="!editing" class="d-block small">{{ruleBuilder.actionToTake}}</span>
            </div>
          </div>
          <div class="col-6 only-padding-right" *ngIf="showActionToTakeNumericParameters" style="display:inline-block;">
              <div class="col-2 only-padding-right" style="display:inline-block;">
                <div class="form-group">
                  <label>{{labelActionToTakeWhen}}</label>
                  <input *ngIf="editing" [(ngModel)]="ruleBuilder.actionToTakeWhenValue" type="number" min="0" step="1" pattern="\d*" class="form-control form-control-sm" appStaticEdit [editable]="editing" />
                  <span *ngIf="!editing" class="d-block small">{{ruleBuilder.actionToTakeWhenValue}}</span>
                </div>
              </div>
              <div class="col-1 only-padding-right" style="display:inline-block;">
                <div class="form-group">
                  <label>&nbsp;</label>
                  <span class="d-block small" style="text-align: center;">{{ruleBuilder_actionToTakeWhenFrequency}}</span>
                </div>
              </div>
              <div class="col-3 only-padding-right" style="display:inline-block;">
                <div class="form-group">
                  <label>&nbsp;</label>
                  <mat-select *ngIf="editing" [formControl]="actionToTakeWhenUnitFormControl" class="form-control form-control-sm" [(ngModel)]="ruleBuilder.actionToTakeWhenUnit" [disabled]="!this.editing">
                    <mat-option *ngFor="let actionToTakeWhenItem of lRuleActionsToTakeWhenUnit" [value]="actionToTakeWhenItem">{{actionToTakeWhenItem}}</mat-option>
                  </mat-select>
                  <span *ngIf="!editing" class="d-block small">{{ruleBuilder.actionToTakeWhenUnit}}</span>
                </div>
              </div> 
          </div>                         
        </div>
    </div>

  </div>
</div>
