<div style="height: 90vh; overflow-y: scroll; overflow-x: hidden">
  <div class="row p-3">
    <div class="col-6">
      <label class="font-weight-bold">Customer</label>
      <customer-search [disabled]="false" [(selectedItem)]="customer"></customer-search>
    </div>
    <div class="col-6">
      <label class="font-weight-bold">Doing Business As</label>
      <company-search [editing]="true" [(idValue)]="companyId"></company-search>
    </div>
  </div>
  <div class="w-100 p-3 bg-white" style="position: sticky; top: 0; z-index: 101;">
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message" (click)="openFileInput();">
          <mat-label>Batch Spreadsheet</mat-label>
          <input matInput type="text" [value]="file?.name" style="pointer-events: none; opacity: 0;"
            placeholder="Purcahse Order Document" #filenameinput ngModel name="filename" #fileNameModel="ngModel"
            (focus)="filenameinput.blur()">
          <div [class.invisible]="!file" style="    width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;position: absolute;
          left: 0;
  top: 14px;">
            {{ file?.name }}
          </div>
          <button mat-flat-button matSuffix class="bg-primary text-white"
            style="position: relative;bottom: 7px;">Browse</button>
        </mat-form-field>
        <input type="file" style="display: none" #fileInput (change)="onFileChange()">
      </div>
      <div class="col-6 text-danger font-weight-bold" *ngIf="fileParseError">
        Error reading file. Make sure this is a valid non-corrupted .xlsx spreadsheet file
      </div>
      <div class="col-6 d-flex align-items-center" *ngIf="!fileParseError && parsedSheets">
        <div class="font-weight-bold">File read successfully. {{ parsedSheets.length }} sheet(s) with {{ totalParsedRows }} total rows found.</div>
        <button mat-button class="bg-primary text-white button-text ml-2" (click)="parseFinal()">Parse Parts</button>
      </div>
    </div>

    <button mat-fab color="primary" class="extended-fab-button animate-fab-appear" style="    position: absolute;
    top: 96px;
    right: 15px;
" *ngIf="parsedItems.length > 0 && customer && companyId" [class.save-fab-loading]="!allLoaded" [disabled]="!allLoaded"
      (click)="confirm()">
      <mat-icon *ngIf="allLoaded">check</mat-icon>
      <i class="fas fa-circle-notch fa-spin text-current mr-3" style="color: currentColor;" *ngIf="!allLoaded"></i>
      <span class="extended-fab-button__text">Create Batch RFQ</span>
    </button>
  </div>

  <table mat-table #itemsTable="matTable" [dataSource]="parsedItems" class="w-100 table-fixed">

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;">&nbsp;</th>
      <td mat-cell *matCellDef="let item" class="user-select-none">
        <div *ngIf="item.loading">
          <i class="fas fa-circle-notch fa-spin text-primary"></i>
        </div>
        <div *ngIf="!item.loading && item.product" matTooltipPosition="after" matTooltip="Matched existing product">
          <mat-icon class="text-success">check</mat-icon>
        </div>
        <div *ngIf="!item.loading && !item.product" matTooltipPosition="after"
          matTooltip="No existing product found, will be created">
          <mat-icon class="text-warning">warning</mat-icon>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="partNumber">
      <th mat-header-cell *matHeaderCellDef style="width: 15%;">Part Number</th>
      <td mat-cell *matCellDef="let item">
        <mat-form-field appearance="outline" class="dense-field w-100 pr-2">
          <i matPrefix *ngIf="item.loading" class="fas fa-circle-notch fa-spin text-primary mr-2"
            style="position: relative; top: -6px;"></i>
          <input class="font-weight-bold" autofocus tabindex="1" matInput type="text" [(ngModel)]="item.partNumber"
            [disabled]="item.loading">
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="rev">
      <th mat-header-cell *matHeaderCellDef style="width: 90px;">Rev.</th>
      <td mat-cell *matCellDef="let item" class="font-weight-bold">
        <mat-form-field appearance="outline" class="dense-field" style="width: 70px;">
          <i matPrefix *ngIf="item.loading" class="fas fa-circle-notch fa-spin text-primary mr-2"
            style="position: relative; top: -6px;"></i>
          <input autofocus tabindex="1" matInput type="text" [(ngModel)]="item.rev" required [disabled]="item.loading">
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantities">
      <th mat-header-cell *matHeaderCellDef style="width: 30%;">Quantities</th>
      <td mat-cell *matCellDef="let item">
        <mat-form-field appearance="outline" class="dense-field w-100 mr-2">
          <mat-chip-list #chipList aria-label="Quantity selection">
            <mat-chip *ngFor="let qty of item.quantities" (removed)="removeQuantity(item, qty)">
              {{qty}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                   [matChipInputAddOnBlur]="true"
                   (matChipInputTokenEnd)="addQuantity(item, $event)">
          </mat-chip-list>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let item" class="font-weight-bold">
        <mat-form-field appearance="outline" class="dense-field w-100">
          <i matPrefix *ngIf="item.loading" class="fas fa-circle-notch fa-spin text-primary mr-2"
            style="position: relative; top: -6px;"></i>
          <input autofocus tabindex="1" matInput type="text" [(ngModel)]="item.description" [disabled]="item.loading">
        </mat-form-field>
      </td>
    </ng-container>

    <tr mat-header-row
      [ngStyle]="{position: 'sticky', top: '68px', 'z-index': '50', 'box-shadow': '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%)'}"
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>