import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Customer } from '../../../customer/resources/customer';
import { Product } from '../../resources/product';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'lead-time-verification',
  templateUrl: './lead-time-verification.component.html',
  styleUrls: ['./lead-time-verification.component.less']
})
export class LeadTimeVerificationComponent implements OnInit {

  constructor(
    private orderSvc: OrderService
  ) { }

  @ViewChild('dataTable', { static: true }) dataTable: MatTable<any>;

  ngOnInit() {
  }

  public customer: Customer = null;

  public dataArray: {
    partNumber: string,
    product: Product,
    leadTime: number,
    state: string
  }[] = [];


  public onCustomerChange() {
    this.dataArray = [];
    this.dataTable.renderRows();
  }

  public randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  public getData() {
    this.dataArray.forEach(async (d) => {
      if (d.state == 'unloaded') {
        d.state = 'loading';
        this.dataTable.renderRows();
        const result = await this.orderSvc.verifyLeadtime(this.customer, d.partNumber).toPromise();
        if (result == null) {
          d.state = 'failed'
        } else {
          d.product = result.product;
          d.leadTime = result.leadTime;
          d.state = 'done';
        }
        this.dataTable.renderRows();
      }
    })
  }

  public clearAll() {
    this.dataArray = [];
  }


  public async readClipboard() {
    // @ts-ignore
    const text = await navigator.clipboard.readText();
    const partNumbers = text.split(/\r?\n/).filter(x => x !== '');

    this.dataArray = [...this.dataArray, ...partNumbers.map(partNumber => ({
        partNumber,
        product: null,
        leadTime: null,
        state: 'unloaded'
      }))]

    this.getData();
  }

  public async copyLeadTimes() {
    const leadtimes = this.dataArray.map(d => (d && (d.leadTime != undefined && d.leadTime != null)) ? d.leadTime.toString() : '')
    const string = leadtimes.join('\r\n');
    // @ts-ignore
    await navigator.clipboard.writeText(string);
  }

  displayedColumns: string[] = ['statusIcon', 'partNumber', 'description', 'revision', 'leadTime'];


}
