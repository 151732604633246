import { OnInit, Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.less']
})
export class PhoneNumberComponent implements OnInit {
  @Output() numberChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() number: string = "";
  @Input() editable: boolean = false;
  @Input() placeholder: string = "Phone Number";
  @Input() appearance?: 'default' | 'outline' = 'default';
  @ViewChild('input') textInput: ElementRef<HTMLInputElement>;

  constructor() {  }

  formatPhone(): void {
    var val: string;
    // we want to use the input element value directly because the `this.number` ngmodel value is always 1 input behind for some reason
    // however, we're using the model as a fallback for init (since viewchildren aren't loaded) and also just in case, to avoid errors
    if (this.textInput && this.textInput.nativeElement && this.textInput.nativeElement.value) {
      val = this.textInput.nativeElement.value;
    } else {
      val = this.number;
    }
    this.number = UtilityService.formatPhone(val);
    this.notifyChange();
  }

  notifyChange(): void {
    this.numberChange.emit(this.number);
  }

  ngOnInit(): void {
    this.formatPhone();
  }

}
