import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ShippingService } from '../../services/shipping.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { ShippingTicket, ShippingTicketStatus, ShippingCarrier, ShippingClass } from '../../resources/shipping-ticket';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../../common/services/utility.service';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';
import { MatSidenav } from '@angular/material/sidenav';
import { VirtualDocument } from '../../../common/resources/virtual-document';

@Component({
  selector: 'shipping-ticket-detail',
  templateUrl: './shipping-ticket-detail.component.html',
  styleUrls: ['./shipping-ticket-detail.component.less']
})
export class ShippingTicketDetailComponent implements OnInit {
  @Input() public id: string;
  public assigningEmployee: User;
  public record: ShippingTicket;
  public saving = false;
  public editing = false;
  @Input() parentSidenav: MatSidenav = null;
  @ViewChild('sidenav') sidenav: MatSidenav;

  public showEditor: string = null;
  private fulfillmentTicketList: ShippingTicket[] = null;
  private shippingBatch: ShippingTicket[] = null;

  public isIncoming = false;
  public isOutgoing = false;

  constructor(private utilitySvc: UtilityService, private shippingService: ShippingService, private router: Router, public navService: NavigationService, route: ActivatedRoute, private userSvc: UserService) {
    if (!this.id) this.id = route.snapshot.paramMap.get('id');
    if (!this.parentSidenav) this.navService.setPageTitle("Shipping");
  }

  public getTicketNumber(ticket: ShippingTicket) {
    return ShippingService.getTicketNumber(ticket);
  }

  public get ticketsForFulfillment(): number {
    if (this.fulfillmentTicketList) return this.fulfillmentTicketList.length;

    this.shippingService.getTicketsForFulfillment(this.record.workOrderId).subscribe(result => this.fulfillmentTicketList = result);

    return 0;
  }

  public get shippingClasses(): string[] {
    const keys = Object.keys(ShippingClass).filter(k => typeof ShippingClass[k as any] === "number");
    const values = keys.map(k => ShippingClass[k as any]);

    return values;
  }

  public getShippingClassText(shippingClass: ShippingClass): string {
    return ShippingService.getShippingClassText(shippingClass);
  }

  public get ticketsinBatch(): ShippingTicket[] {
    if (this.shippingBatch || !this.record.shippingBatchId) return this.shippingBatch;

    this.shippingService.getTicketsInBatch(this.record.shippingBatchId).subscribe(result => this.shippingBatch = result);

    return null;
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getStatusText(status: ShippingTicketStatus) {
    return ShippingService.getStatusText(status);
  }

  public openTicket(ticket: ShippingTicket): void {
    window.open(`/shipping/ticket/${ticket.shippingTicketId}`, '_blank');
  }

  public get totalRequisitioned(): number {
    return this.record.shippingTicketPickLocations.reduce((total, value) => total + value.quantity, 0);
  }

  public get needsAssignment() {
    return !this.record || !this.record.employeeId;
  }

  public getRequiredDate(t: ShippingTicket) {
    if (t.requiredDate) return t.requiredDate;
    else if (t.workOrder && t.workOrder.order) return t.workOrder.order.requiredDate;
    else return null;
  }

  public openTracking(): void {
    if (this.record && this.record.shippingCarrier && this.record.trackingNumber) {
      window.open(this.record.shippingCarrier.trackingProvider + this.record.trackingNumber, "_blank");
    }
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getDetail();
  }

  public addCarrier(_name: string): void {
    this.showEditor = 'addCarrier';
    this.sidenav.open();
  }

  public saveAssignment(): void {
    this.saving = true;
    this.shippingService.assignTicket(this.record.shippingTicketId, this.assigningEmployee.userId).subscribe(_ => {
      this.saving = false;
      this.getDetail();
    })
  }

  public userIsManager(): boolean {
    //TODO: ...
    return this.userSvc.canAccess("Developer");
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.shippingService.removeDocument(this.record, document).subscribe(r => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  public openDocumentWindow(): void {
    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.sidenav.toggle();
  }

  public addDocuments(documents: VirtualDocument[]): void {
    this.saving = true;

    this.shippingService.save(this.record)
      .subscribe(detail => {
        this.record = detail;

        this.shippingService.addDocuments(this.record, documents).subscribe(
          _ => {
            this.saving = false;
            this.getDetail();
          }
        );
      });
  }

  private getDetail(): void {
    this.record = null;
    this.fulfillmentTicketList = null;

    this.shippingService.getDetail(this.id).subscribe(detail => {

      this.record = detail;
      this.assigningEmployee = detail.employee;

      if (!this.parentSidenav) this.navService.setPageTitle("Shipping");
      this.navService.pushBreadcrumb('Ticket #' + this.getTicketNumber(this.record))

      this.shippingService.$currentBuilding.subscribe(b => {
        this.isIncoming = this.record.destinationBuildingId === b.buildingId;
        this.isOutgoing = this.record.originBuildingId === b.buildingId;
      });

    });
  }

  public splitTicket(): void {
    this.saving = true;
    this.shippingService.save(this.record).subscribe(_ => {
      this.saving = false;

      this.showEditor = 'split';
      this.sidenav.open();
    });
  }

  public combineShipments(): void {
    this.saving = true;
    this.shippingService.save(this.record).subscribe(_ => {
      this.saving = false;

      this.showEditor = 'combine';
      this.sidenav.open();
    });
  }

  public editPickLocations(): void {
    this.saving = true;
    this.shippingService.save(this.record).subscribe(_ => {
      this.saving = false;

      this.showEditor = 'locations';
      this.sidenav.open();
    });
  }

  public showTicketsForWorkOrder(): void {
    this.saving = true;
    this.shippingService.save(this.record).subscribe(_ => {
      this.saving = false;

      this.showEditor = 'showSplits';
      this.sidenav.open();
    });
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
    this.getDetail();
  }

  public get shippingCarrierIcon(): string {
    if (!this.record || !this.record.shippingCarrier) return null;

    return `${(new URL(this.record.shippingCarrier.trackingProvider)).origin}/favicon.ico`;
  }

  public saveChanges(): void {
    this.saving = true;
    this.shippingService.save(this.record).subscribe(_ => {
      this.saving = false;
      this.toggleEditing();
    });
  }

  public printLabels(): void {
    this.saving = true;
    if (this.record.shippingClass === ShippingClass.OUTGOING) {
      this.shippingService.getShippingLabel(this.record, this.record.originBuilding).subscribe(doc => {
        this.saving = false;
        // TODO: print instead of opening
        // make some kind of check to see if connected to print station and just hide the print label button if not
        window.open(`/document/${doc.documentId}?inline=true`, "_blank")
      })
    } else if (this.record.shippingClass === ShippingClass.INBOUND) {
      const lotNumbers = Array.from(new Set(
        this.record.shippingTicketPlaceLocations.map(pl => ({
          lotNumber: pl.inventoryItemLocation?.internalLotNumber ?? 'No Lot Number',
          qty: pl.quantity
        }))
      ));
      lotNumbers.forEach((ln, i) => {
        this.shippingService.getInventoryLabel(this.record, ln.lotNumber, ln.qty.toString(), this.record.arrivalDate ?? new Date()).subscribe(doc => {
          this.saving = false;
          // TODO: print instead of opening
          // make some kind of check to see if connected to print station and just hide the print label button if not
          window.open(`/document/${doc.documentId}?inline=true`, "_blank")
        })
      })
    }
  }

  public getStatusColorClass(status: ShippingTicketStatus): string {
    return ShippingService.getStatusColorClass(status);
  }

  public get isEditable(): boolean {
    return this.record.status !== ShippingTicketStatus.VOID && (this.isIncoming || this.isOutgoing || this.userIsManager());
  }
  
  ngOnInit() {
    this.getDetail();
  }

}
