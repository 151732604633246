import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { MatRadioChange } from '@angular/material/radio';
import { InventoryService } from '../../../inventory/services/inventory.service';

@Component({
  selector: 'inventory-location-selection',
  templateUrl: './inventory-location-selection.component.html',
  styleUrls: ['./inventory-location-selection.component.less']
})
export class InventoryLocationSelectionComponent implements OnInit {
  @Input() locationList: InventoryLocation[];
  @Input() selectedList: InventoryLocation[];
  @Output() selectionChanged: EventEmitter<InventoryLocation> = new EventEmitter<InventoryLocation>();

  constructor(private service: InventoryService) { }

  public select(location: InventoryLocation) {
    this.selectionChanged.emit(location);
  }

  public clearSelection(): void {
    this.selectionChanged.emit(null);
  }

  ngOnInit(): void {
    
  }
}
