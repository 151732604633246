<div class="px-0" style="height: 100%;" *ngIf="product">
  <mat-stepper orientation="vertical" [linear]="false" (selectionChange)="onSelectedStep($event.selectedIndex)" style="height: auto;" ngForm #form="ngForm">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <!-- PO only for top level assembly -->
    <mat-step label="Check Purchase Order" *ngIf="!product.parentAssemblyId && !workOrder.toolingParentWorkOrderId">
      <div class="border rounded p-3" #anchor>
        <h4>
          Purchase Order
        </h4>
        <div class="d-flex flex-wrap">
          <div class="col-6">
            <div class="form-group">
              <label>Customer</label>
              <div>
                {{ workOrder.order.customer.businessName }}
              </div>
            </div>
            <div class="form-group">
              <label>Purchase Order Number</label>
              <span class="d-block small">{{workOrder.order.orderPurchaseOrder?.purchaseOrderNumber || '--'}}</span>
            </div>
            <div class="form-group">
              <label>Date Received</label>
              <date-picker [placeholder]="'Date Received'" [editable]="false" [(date)]="workOrder.order.receivedDate">
              </date-picker>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            <div class="mat-card p-3 rounded cursor-pointer d-flex flex-column align-items-center"
              *ngIf="this.workOrder.order.orderPurchaseOrder?.documentId" (click)="openPODocument()">
              <i class="fas fa-file fa-fw fa-6x text-info"></i>
              <div class="mt-2 font-weight-bold small">
                View Purchase Order Document
              </div>
            </div>
            <div class="mat-card bg-light p-3 rounded d-flex flex-column align-items-center"
              *ngIf="!this.workOrder.order.orderPurchaseOrder?.documentId">
              <i class="fas fa-file fa-fw fa-6x text-muted"></i>
              <div class="mt-2 font-weight-bold small text-muted">
                Purchase Order Document Not Available
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-card-actions align="end">
        <button mat-stroked-button matStepperNext>Next</button>
      </mat-card-actions>
    </mat-step>
    <mat-step label="Primary & Alternate Materials">
      <div class="border rounded" #anchor>
        <mat-tab-group #materialTabGroup>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon *ngIf="validatePrimaryMaterial()">warning</mat-icon>
              Primary
            </ng-template>
            <div class="p-3">
              <material-select [allMaterialData$]="materialDataSubject" [material]="product.material" [disabled]="false" [shouldPromptForDimensions]="false" #primaryMaterialSelect (change)="onPrimaryMaterialChange()"></material-select>
              <div class="small font-weight-medium text-danger" *ngIf="validatePrimaryMaterial() as errorMessage">{{ errorMessage }}</div>
            </div>
          </mat-tab>
          <mat-tab *ngFor="let altMaterialParams of this.alternateMaterialParams; let i = index">
            <div class="p-3">
              <material-select [allMaterialData$]="materialDataSubject" #altMaterialSelect [disabled]="false" [shouldPromptForDimensions]="false" #primaryMaterialSelect></material-select>
              <div class="small font-weight-medium text-danger" *ngIf="validateSecondaryMaterial(altMaterialSelect) as errorMessage">{{ errorMessage }}</div>
            </div>
            <ng-template mat-tab-label>
              <mat-icon *ngIf="validateSecondaryMaterial(altMaterialSelect)">warning</mat-icon>
              Alt #{{ i + 1}}
              <mat-button mat-icon-button (click)="deleteAltMaterial(i)"><mat-icon>delete</mat-icon></mat-button>
            </ng-template>
          </mat-tab>
          <mat-tab disabled [labelClass]="'add-alt-material-tab'">
            <ng-template mat-tab-label>
              <button mat-flat-button class="mat-square-button" (click)="addAltMaterial($event)"
                [matTooltip]="'Will create blank material. Hold CTRL to copy primary material instead'"
                [matTooltipPosition]="'above'"
              > 
                <mat-icon>add</mat-icon>
              </button>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
      <mat-card-actions align="end">
        <button mat-stroked-button matStepperNext [disabled]="!canContinueFromMaterial()">Next</button>
      </mat-card-actions>
    </mat-step>
    <mat-step label="Dimensions" [stepControl]="materialForm.control" ngModelGroup="material" #materialForm="ngModelGroup">
      <div class="border rounded p-3" #anchor *ngIf="primaryMaterialParams?.type">
        <div>
          <div class="d-flex">
            <div>
              <div class="font-weight-bold">Raw Material Dimensions</div>
              <div *ngFor="let mtd of primaryMaterialParams.type.materialTypeDimensions">
                <div class="small font-weight-bold">{{ mtd.dimensionType.label | titlecase }}</div>
                <mat-form-field appearance="outline" class="w-100 dense-field">
                  <input matInput type="number" [name]="mtd.materialTypeDimensionId + '-raw'" [minValidator]="0" [ngModel]="getDimensionValue(mtd.materialTypeDimensionId)" (ngModelChange)="setDimensionValue(mtd.materialTypeDimensionId, $event); dirty = true" />
                  <span matSuffix class="font-weight-bold small" style="position: relative; top: -7px;">{{ mtd.dimensionUnit.abbreviation }}</span>
                </mat-form-field>
              </div>
            </div>
            <div class="px-3 d-flex flex-column justify-content-around"
              style="position: relative; top: 9px; padding-top: 30px;">
              <div *ngFor="let mtd of primaryMaterialParams.type.materialTypeDimensions">
                <mat-icon>arrow_forward</mat-icon>
              </div>
            </div>
            <div>
              <div class="font-weight-bold">Blank Dimensions</div>
              <div *ngFor="let mtd of primaryMaterialParams.type.materialTypeDimensions">
                <div class="small font-weight-bold">{{ mtd.dimensionType.label | titlecase }}</div>
                <div>
                  <mat-form-field appearance="outline" class="w-100 dense-field">
                    <input matInput type="number" [name]="mtd.materialTypeDimensionId + '-blank'" [minValidator]="0" [maxValidator]="getDimensionValue(mtd.materialTypeDimensionId)" [ngModel]="getBlankDimension(mtd.materialTypeDimensionId)" (ngModelChange)="setBlankDimension(mtd.materialTypeDimensionId, $event); dirty = true" />
                    <span matSuffix class="font-weight-bold small" style="position: relative; top: -7px;">{{ mtd.dimensionUnit.abbreviation }}</span>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="px-3 d-flex flex-column justify-content-around"
              style="position: relative; top: 9px; padding-top: 30px;">
              <div *ngFor="let mtd of primaryMaterialParams.type.materialTypeDimensions">
                <mat-icon>arrow_forward</mat-icon>
              </div>
            </div>
            <div>
              <div class="font-weight-bold">Finished Dimensions</div>
              <div *ngFor="let mtd of primaryMaterialParams.type.materialTypeDimensions">
                <div class="small font-weight-bold">{{ mtd.dimensionType.label | titlecase }}</div>
                <div>
                  <mat-form-field appearance="outline" class="w-100 dense-field">
                    <input matInput type="number" [name]="mtd.materialTypeDimensionId + '-finished'" [minValidator]="0" [maxValidator]="getBlankDimension(mtd.materialTypeDimensionId)" [ngModel]="getFinishedDimension(mtd.materialTypeDimensionId)" (ngModelChange)="setFinishedDimension(mtd.materialTypeDimensionId, $event); dirty = true" />
                    <span matSuffix class="font-weight-bold small" style="position: relative; top: -7px;">{{ mtd.dimensionUnit.abbreviation }}</span>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Blanks per Unit</mat-label>
                <input class="text-right" matInput name="partsPerLot" type="number" min="1" step="1" *ngIf="product.partsPerMaterialOverride"
                       [(ngModel)]="product.partsPerMaterial">
                <input class="text-right" matInput name="partsPerLot" type="number" step="0.01" *ngIf="!product.partsPerMaterialOverride" [disabled]="true"
                       [value]="product.partsPerMaterialOverride ? product.partsPerMaterial : blanksPerMaterial" style="color: black !important;">
                <button matPrefix mat-icon-button *ngIf="!product.partsPerMaterialOverride" (click)="togglePartsPerMaterialOverride($event)" matTooltip="Override" matTooltipPosition="after">
                  <mat-icon>edit</mat-icon>
                </button>
                <button matPrefix mat-icon-button *ngIf="product.partsPerMaterialOverride" (click)="togglePartsPerMaterialOverride($event)" matTooltip="Remove Override" matTooltipPosition="after">
                  <mat-icon>calculate</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Parts Per Blank</mat-label>
                <input class="text-right" matInput name="partsPerLot" type="number" min="1" step="1" *ngIf="product.partsPerBlankOverride"
                       [(ngModel)]="product.partsPerBlank">
                <input class="text-right" matInput name="partsPerLot" type="number" step="0.01" *ngIf="!product.partsPerBlankOverride" [disabled]="true"
                       [value]="product.partsPerBlankOverride ? product.partsPerBlank : partsPerBlank" style="color: black !important;">
                <button matPrefix mat-icon-button *ngIf="!product.partsPerBlankOverride" (click)="togglePartsPerBlankOverride($event)" matTooltip="Override" matTooltipPosition="after">
                  <mat-icon>edit</mat-icon>
                </button>
                <button matPrefix mat-icon-button *ngIf="product.partsPerBlankOverride" (click)="togglePartsPerBlankOverride($event)" matTooltip="Remove Override" matTooltipPosition="after">
                  <mat-icon>calculate</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="border rounded p-3 bg-light text-muted small d-flex justify-content-center align-items-center font-italic" #anchor *ngIf="!product.materialId">
        Part has no primary material.
      </div>
      <mat-card-actions align="end">
        <button mat-stroked-button matStepperNext [disabled]="materialForm.invalid">Next</button>
      </mat-card-actions>
    </mat-step>
    <mat-step label="Check Hardware and Tooling" ngModelGroup="hardware" #hardwareForm="ngModelGroup" [stepControl]="hardwareForm">
      <div class="border rounded p-3" #anchor>
        <div *ngFor="let item of product.purchasedItems; index as i" class="row">
          <div class="col-1 align-items-center d-flex">
            <button mat-icon-button (click)="deletePurchasedItem(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div class="col-3 small d-flex align-items-center">
            <b>{{ item.purchasedItem.description }}</b>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline" class="w-100 dense-field">
              <input matInput type="number" [minValidator]="0" step="1" [name]="i + '-pi-qty'" [(ngModel)]="item.quantity" />
            </mat-form-field>
          </div>
          <div class="col-2 d-flex align-items-center">
            <mat-checkbox [(ngModel)]="item.isNonRecurring" [name]="i + '-pi-nonrecurring'" >
              Non-recurring
            </mat-checkbox>
          </div>
          <div class="col-2 d-flex align-items-center">
            <mat-checkbox [disabled]="!item.isNonRecurring" [(ngModel)]="item.isAmortized" [name]="i + '-pi-amortize'" >
              Amortize
            </mat-checkbox>
          </div>
          <div class="col-2 d-flex align-items-center">
            <mat-checkbox [(ngModel)]="item.toolingInHouse" [name]="i + '-pi-toolinginhouse'" >
              In-House
            </mat-checkbox>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <button mat-flat-button class="button-text" (click)="addPurchasedItem()">
              <mat-icon matPrefix>add</mat-icon>
              Add Item
            </button>
          </div>
        </div>
      </div>
      <mat-card-actions align="end">
        <button mat-stroked-button matStepperNext [disabled]="hardwareForm.invalid">Next</button>
      </mat-card-actions>
    </mat-step>
    <mat-step label="Check Paint" ngModelGroup="paint" #paintForm="ngModelGroup" [stepControl]="paintForm">
      <div class="border rounded p-3" #anchor>
        <div *ngFor="let paint of paintItems; index as i" class="row mb-3">
          <div class="col-1 align-items-center d-flex">
            <button mat-icon-button (click)="deletePaint(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div class="col-1 d-flex align-items-center">
            <div [style.background]="paint?.sampleColor" class="swatch"></div>
          </div>
          <div class="col-8 d-flex align-items-center">
            <b>{{ paint?.name }}</b>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <button mat-flat-button class="button-text" (click)="addPaint()">
              <mat-icon matPrefix>add</mat-icon>
              Add Paint
            </button>
          </div>
        </div>
      </div>
      <mat-card-actions align="end">
        <button mat-stroked-button matStepperNext [disabled]="paintForm.invalid">Next</button>
      </mat-card-actions>
    </mat-step>
    <mat-step label="Create Programming Tickets">
      <div class="border rounded" ngModelGroup="programming" #programmingForm="ngModelGroup">
        <div class="border-bottom p-3">
          <div class="mb-2 w-100 d-flex align-items-center">
            <mat-checkbox class="mat-checkbox-no-margin" name="createProgrammingCNC" [(ngModel)]="createProgrammingCNC">Create CNC Programming Ticket</mat-checkbox>
            <div *ngIf="cncStepNames.length > 0" class="small text-muted font-italic ml-auto">
              Found programming step along with CNC steps: <span class="font-weight-bold" *ngFor="let stepName of cncStepNames; let last = last">{{stepName}}{{last ? '' : ', '}}</span>
            </div>
          </div>
          <div [ngStyle]="{ opacity: createProgrammingCNC ? 1 : 0.6, 'pointer-events': createProgrammingCNC ? 'all' : 'none', animation: 'opacity 250ms ease' }">
            <div class="mb-2 row">
              <div class="col-8">
                <employee-search [label]="'Assigned Programmer'" name="programmerCNC" [(ngModel)]="assignedProgrammerCNC" [required]="createProgrammingCNC"></employee-search>
              </div>
            </div>
          </div>
        </div>
        <div class="border-bottom p-3">
          <div class="mb-2">
            <mat-checkbox class="mat-checkbox-no-margin" name="createProgrammingNC" [(ngModel)]="createProgrammingNC">Create NC Programming Ticket</mat-checkbox>
          </div>
          <div [ngStyle]="{ opacity: createProgrammingNC ? 1 : 0.6, 'pointer-events': createProgrammingNC ? 'all' : 'none', animation: 'opacity 250ms ease' }">
            <div class="mb-2 row">
              <div class="col-8">
                <employee-search [label]="'Assigned Programmer'" name="programmerNC" [(ngModel)]="assignedProgrammerNC" [required]="createProgrammingNC"></employee-search>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div class="mb-2 w-100 d-flex align-items-center">
            <mat-checkbox class="mat-checkbox-no-margin" name="createProgrammingCMM" [(ngModel)]="createProgrammingCMM">Create CMM Programming Ticket</mat-checkbox>
            <div *ngIf="cmmStepNames.length > 0" class="small text-muted font-italic ml-auto">
              Step{{cmmStepNames.length > 1 ? 's' : ''}} <span class="font-weight-bold" *ngFor="let stepName of cmmStepNames; let last = last">{{stepName}}{{last ? '' : ', '}}</span> marked as needing CMM inspection
            </div>
          </div>
          <div [ngStyle]="{ opacity: createProgrammingCMM ? 1 : 0.6, 'pointer-events': createProgrammingCMM ? 'all' : 'none', animation: 'opacity 250ms ease' }">
            <div class="mb-2 row">
              <div class="col-8">
                <employee-search [label]="'Assigned Programmer'" name="programmerCNC" [(ngModel)]="assignedProgrammerCMM" [required]="createProgrammingCMM"></employee-search>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-card-actions align="end">
        <button mat-stroked-button matStepperNext>Next</button>
      </mat-card-actions>
    </mat-step>
    <mat-step label="Create Planning Ticket" [stepControl]="planningForm.control">
      <div class="border rounded p-3" ngModelGroup="planning" #planningForm="ngModelGroup">
        <div>
          <div class="mb-2 row">
            <div class="col-8">
              <employee-search [label]="'Assigned Planner'" name="planner" [(ngModel)]="assignedPlanner" required></employee-search>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>

<ng-template #newPurchasedItemDialog>
  <h2 mat-dialog-title>New Purchased Item</h2>
  <purchased-item-select [(ngModel)]="newPurchasedItem"></purchased-item-select>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="false">Cancel</button>
    <button mat-button [disabled]="!newPurchasedItem" [matDialogClose]="true">Add Item</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #newPaintDialog>
  <h2 mat-dialog-title>New Paint</h2>
  <paint-select [(paint)]="newPaint" [editable]="true"></paint-select>
  <mat-dialog-actions align="end">
    <button mat-button class="btn btn-default btn-sm mr-3" [matDialogClose]="false">Cancel</button>
    <button mat-button [disabled]="!newPaint" [matDialogClose]="true">Add Paint</button>
  </mat-dialog-actions>
</ng-template>