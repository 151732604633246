<div class="d-flex flex-column" *ngIf="!inventoryLocation">
  <div class="row">
    <div class="col">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Filter Locations</mat-label>
        <input matInput autocomplete="off" type="text" [formControl]="locationFilterControl">
      </mat-form-field>
    </div>
  </div>
  <div class="row flex-grow-1 pb-2 mb-4" (mousedown)="onTouched()">
    <div class="col" #locationTreeCard>
      <div class="locations-tree-label">{{ label }}</div>
      <mat-card class="mat-elevation-z0 mat-card-outline p-0" style="height: 400px !important">
        <mat-tree *ngIf="dataSource.data?.length > 0" [dataSource]="dataSource" [treeControl]="treeControl"
          class="example-tree" style="margin-bottom: -1px">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button class="invisible mx-1" *ngFor="let _ of range(getLevel(dataSource.data, node))">
              <mat-icon class="mat-icon-rtl-mirror">
                chevron_right
              </mat-icon>
            </button>
            <ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node }">
            </ng-container>
          </mat-tree-node>
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div>
              <div class="mat-tree-node">
                <button mat-icon-button class="invisible mx-1" *ngFor="let _ of range(getLevel(dataSource.data, node))">
                  <mat-icon class="mat-icon-rtl-mirror">
                    chevron_right
                  </mat-icon>
                </button>
                <button type="button" mat-icon-button class="mx-1" (click)="toggleNode(node)" *ngIf="!node.loading"
                  [attr.aria-label]="'toggle ' + node.id">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <mat-spinner class="mx-1" color="black" diameter="24" *ngIf="node.loading"></mat-spinner>
                <ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node }">
                </ng-container>
              </div>
              <ng-container matTreeNodeOutlet *ngIf="treeControl.isExpanded(node)"></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
        <div
          class="text-muted h-100 w-100 mat-body-2 font-italic d-flex flex-column align-items-center justify-content-center"
          *ngIf="dataSource.data?.length === 0 && !searchingLocations">
          <mat-icon class="my-2">dangerous</mat-icon>
          No matching locations found.
        </div>
        <div class="searching-locations-indicator text-muted font-italic" *ngIf="searchingLocations">
          <mat-spinner class="my-1" diameter="32"></mat-spinner>
          Searching locations...
        </div>
      </mat-card>
    </div>
  </div>
</div>

<mat-form-field class="w-100" appearance="outline" *ngIf="!!inventoryLocation">
  <mat-label>{{ label }}</mat-label>
  <input matInput readonly required value=" " class="d-none">
  <div class="d-flex align-items-center">
    <ng-container *ngFor="let ancestor of locationBreadcrumbs; let last = last">
      <span>{{ancestor.name}}</span>
      <mat-icon *ngIf="!last" class="mat-icon-rtl-mirror mx-1">
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
  <button mat-icon-button matSuffix (click)="editLocation()" [attr.aria-label]="'Change check in location'">
    <mat-icon>edit</mat-icon>
  </button>
</mat-form-field>

<ng-template #nodeNameTemplate let-item>
  <button type="button" mat-icon-button color="black" class="mx-1" matTooltip="Select this location"
    matTooltipPosition="before" (click)="writeValue(item); doFilter(''); locationFilterControl.setValue('')">
    <mat-icon>
      check_circle
    </mat-icon>
  </button>
  <span [innerHTML]="item.name | highlight: (!searchingLocations ? locationFilterControl.value: '') : '<span class=\'tree-filter-match\'>$&</span>'
    ">{{ item.name }} {{item.childrenLoaded | json}}</span>
</ng-template>
