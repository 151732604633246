<div class="container-fluid">
  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" (search)="doSearch($event)" [showManagerBar]="false"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('name')">
            Name
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th (click)="sortBy('title')">
            Title
            <i [ngClass]="getSortIconFor('title')"></i>
          </th>
          <th (click)="sortBy('phone')">
            Phone
            <i [ngClass]="getSortIconFor('phone')"></i>
          </th>
          <th (click)="sortBy('supervisor')">
            Supervisor
            <i [ngClass]="getSortIconFor('supervisor')"></i>
          </th>
          <th class="text-right">
            <permission-selection [selections]="groupFilters" (selectionChanged)="setGroupFilter($event)"></permission-selection>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>
          <span class="mr-2 d-inline-block">
            <img *ngIf="row.userProfilePhotoPath; else noPhoto" class="photo border rounded-circle" [attr.src]="row.userProfilePhotoPath + '?thumbnail=true'" />
            <ng-template #noPhoto>
              <i class="no-user-icon fas fa-2x fa-user text-muted border rounded-circle"></i>
            </ng-template>
          </span>
          {{row.fullName}}
        </td>
        <td>
          {{row.title||'--'}}
        </td>
        <td>
          {{row.phone||'--'}}
        </td>
        <td colspan="2">
          {{row.directSupervisor?.fullName||'--'}}
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
