import { WorkflowStep } from "../../order/resources/workflow";
import { WorkOrder } from "./work-order";
import { Department, FloorMachine } from "../../floor/resources/building";
import { User } from "../../common/resources/user";
import { InspectionTicket } from "../../quality/resources/inspection-ticket";

export enum OperatorState {
  PRE_SETUP = 0,
  SETUP = 1,
  PRE_FIRST_ARTICLE = 2,
  FIRST_ARTICLE = 3,
  AWAIT_FIRST_INSPECTION = 4,
  PRE_PRODUCTION = 5,
  PRODUCTION = 6,
  INACTIVE = 100
}

export interface MachineAssignmentOperatorMetadata
{
    hasStartedOperation: boolean;
    state: OperatorState;
    previousTimeCounter: number;
    timerStarted: Date;
    setupFinishedTime: Date;
    partStartedMap: { [key: number]: number };
    partFinishedMap: { [key: number]: number };
    partTimeMap: { [key: number]: number };
    operatorId: string;
}
export interface MachineAssignment {
  assignmentId: string;
  departmentId: string;
  workOrderId: string;
  workflowStepId: string;
  machineId?: string;
  scheduledStart: Date;
  scheduledEnd: Date;
  actualStart: Date;
  actualEnd: Date;
  status: number;
  userId: string;
  requestedQty: number;
  readyQty: number;

  metadata?: MachineAssignmentOperatorMetadata;


  department: Department;
  workOrder: WorkOrder;
  operation: WorkflowStep;
  machine: FloorMachine;
  user: User;

  inspectionTickets: InspectionTicket[]

}
