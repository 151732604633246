import { Component, Input, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { GenericSelectComponent } from '../../../common/components/generic-select/generic-select.component';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MaterialSelectComponent } from '../../../order/components/material-select/material-select.component';
import { Material } from '../../../order/resources/material';
import { Station } from '../../../order/resources/station';
import { MaterialService } from '../../../order/services/material.service';
import { StationService } from '../../../order/services/station.service';
import { ShippingTicket, ShippingTicketStatus } from '../../../shipping/resources/shipping-ticket';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { PurchaseOrderStatus } from '../../resources/purchaseOrder';
import { Requisition, RequisitionLineItem, RequisitionOrderInfo, RequisitionStatus } from '../../resources/requisition';
import { PurchasingService } from '../../services/purchasing.service';

@Component({
  selector: 'app-requisition-info',
  templateUrl: './requisition-info.component.html',
  styleUrls: ['./requisition-info.component.less']
})
export class RequisitionInfoComponent implements OnInit {

  public record: Requisition;
  public id: string;
  public assigningEmployee: User = null;

  editing = false;
  saving = false;


  emptyGuid = UtilityService.emptyGuid;
  requisitionStatus = RequisitionStatus;

  constructor(private purchasingService: PurchasingService, private navService: NavigationService, private route: ActivatedRoute, private userSvc: UserService, private dialog: MatDialog, private router: Router, private utilitySvc: UtilityService, private materialService: MaterialService) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  public saveAssignment(): void {
    if (!this.record) return;

    this.saving = true;
    this.purchasingService
      .assignRequisition(this.record.requisitionId, this.assigningEmployee)
      .subscribe((_) => {
        this.saving = false;
        if (this.assigningEmployee.userId === this.userSvc.userData.userId) this.getDetail();
        else {
          // redirect to list if assigning to someone else
          this.router.navigate(['requisition']);
        }
      });
  }

  private getDetail(): void {
    this.record = null;
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;

      this.userSvc.user.subscribe(u => {
        if (!u || !(u.userId)) return;

        this.record = <Requisition>{
          requisitionId: UtilityService.emptyGuid,
          createdById: u.userId,
          createdBy: u,
          note: "",
          department: "",
          datePlaced: new Date(),
          requisitionLineItems: [],
        };

      });

    }
    else {
      this.purchasingService.getRequisitionDetail(this.id).subscribe(detail => {
        this.record = detail;

        this.setPageTitle();
        if (this.record.status == RequisitionStatus.APPROVED || this.record.status == RequisitionStatus.FULFILLED) {
          this.getRequisitionOrderInfo();
        }
      });
    }
  }

  public get canEditDetails(): boolean {
    return this.record &&
    this.record.createdById === this.userSvc.userData.userId &&
    (this.record.status === undefined ||
      this.record.status === null || 
      this.record.status === RequisitionStatus.AWAITING_INPUT ||
      this.record.status === RequisitionStatus.REJECTED
    )
  }

  @ViewChild('itemEditDialogTemplate', { static: true }) itemEditDialogTemplate: TemplateRef<any>;
  private itemEditDialogRef: MatDialogRef<any>;

  public editItem(item: RequisitionLineItem) {
    this.itemEditDialogRef = this.dialog.open(this.itemEditDialogTemplate, {
      data: item,
      minWidth: "50%",
      disableClose: true,
    })
  }

  public async newItem() {
    await this.saveChanges(true);
    this.newItemType = 'purchased';
    this.newMaterialType = 'new';
    const item = <RequisitionLineItem>{
      requisitionLineItemId: UtilityService.emptyGuid,
      purchasedItemId: null,
      purchasedItem: null,
      materialId: null,
      material: null,
      stationId: null,
      station: null,
      amountReceived: 0,
      amountRequired: null,
      dueDate: null,
      requisitionId: this.record.requisitionId
    }
    this.editItem(item);
  }

  public async saveNewItem(item: RequisitionLineItem, type: 'purchased' | 'material' | 'station', materialData?: MaterialSelectComponent['parameterIds']) {
    this.saving = true;
    if (type === 'material') {
      console.log(materialData);
      const material = await this.materialService.findOrCreateMaterial(materialData).toPromise();
      item.material = material;
      item.materialId = material.materialId;
    }
    this.saveItem(item);
  }

  public async saveItem(item: RequisitionLineItem) {
    this.saving = true;
    if (this.itemEditDialogRef) this.itemEditDialogRef.close();
    await this.saveChanges(true);
    const isNew = item.requisitionLineItemId === UtilityService.emptyGuid;
    const saved = await this.purchasingService.saveRequisitionLineItem(item).toPromise();
    this.saving = false;
    if (isNew) {
      this.record.requisitionLineItems.push(saved)
    } else {
      this.record.requisitionLineItems[
        this.record.requisitionLineItems.findIndex(
          x => x.requisitionLineItemId === saved.requisitionLineItemId
        )
      ] = saved;
    }
  }

  public async deleteItem(item: RequisitionLineItem) {
    this.saving = true;
    await this.purchasingService.deleteRequisitionLineItem(item.requisitionLineItemId).toPromise();
    this.record.requisitionLineItems =
      this.record.requisitionLineItems.filter(x => x.requisitionLineItemId !== item.requisitionLineItemId); 
    this.saving = false;
  }

  public daysLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getDaysRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  private setPageTitle(): void {
    this.navService.setPageTitle("Requisition Request Detail");
    this.navService.pushBreadcrumb(this.record.requisitionNumber || "New Requisition Request");
  }

  @ViewChild('readyDialogTemplate', { static: true }) readyDialogTemplate: TemplateRef<any>;
  public async saveChanges(noReload: boolean = false, skipConfirmation = false) {
    if (this.canEditDetails && !noReload && !skipConfirmation) {
      const dialogRef = this.dialog.open<any, any, {
        ready: boolean, note: string
      }>(this.readyDialogTemplate, {
        disableClose: true,
      })
      const { ready, note } = await dialogRef.afterClosed().toPromise();
      this.record.statusNote = "";
      if (!ready) {
        this.record.status = RequisitionStatus.AWAITING_INPUT;
        this.record.statusNote = note;
      } else this.record.status = RequisitionStatus.AWAITING_REVIEW;
    }
    this.saving = true;
    const detail = await this.purchasingService.saveRequisition(this.record).toPromise();
    this.record = detail;
    this.saving = false;
    if (this.id == detail.requisitionId) {
      if (!noReload) this.getDetail();
    } else {
      this.id = this.record.requisitionId;
      this.router.navigate(['/requisition', detail.requisitionId]);
      if (!noReload) this.getDetail();
    }
    if (!noReload) this.editing = false;
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("PurchasingManager") ||
      this.userSvc.canAccess("Developer")
    );
  }
  public userIsAssigned(): boolean {
    return this.userSvc.userData.userId === this.record.assignedTo;
  }
  public userIsPurchasing(): boolean {
    return (
      this.userSvc.canAccess("PurchasingManager") ||
      this.userSvc.canAccess("PurchasingUser")
    );
  }

  public getLineItemName(item: RequisitionLineItem): string {
    if (item.materialId) {
      //Material
      return Material.generatedName(item.material);
    }
    else if (item.stationId) {
      //station
      return item.station.name;
    }
    else {
      //purchased item
      return item.purchasedItem.description;
    }
  }

  public getStatusColorClass(status: number): string {
    return RequisitionStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return RequisitionStatus.getStatusText(status);
  }

  ngOnInit() {
    this.getDetail();
  }

  async approveOrder() {
    const r = await this.utilitySvc.showConfirmationPromise(
      "Approve This Order?",
      "<p>Please confirm that you want to approve this requisition in its current state.</p><p class='text-muted'>This cannot be undone.</p>",
    )
    if (!r) return;
    this.record.status = RequisitionStatus.APPROVED;
    await this.saveChanges(true);
    this.router.navigate(['/requisition'])
  }

  @ViewChild('rejectReasoningDialogTemplate', { static: true }) rejectReasoningDialogTemplate: TemplateRef<any>;
  async rejectOrder() {
    const r = await this.utilitySvc.showConfirmationPromise(
      "Reject This Requisition Request?",
      "<p>Are you sure you want to reject this requisition request?</p><p class='text-muted'>It will be sent back for additional information, clairification, etc.</p>",
    )
    if (!r) return;
    const dialogRef = this.dialog.open<any, any, {
      confirm: boolean, note: string
    }>(this.rejectReasoningDialogTemplate, {
      disableClose: true,
    })
    const { confirm, note } = await dialogRef.afterClosed().toPromise();
    if (!confirm) return;
    this.record.status = RequisitionStatus.REJECTED;
    this.record.statusNote = note;
    this.saveChanges(false, true);
  }

  @ViewChild('fulfillDialogTemplate', { static: true }) fulfillDialogTemplate: TemplateRef<any>;
  async fulfillItem(item: RequisitionLineItem) {
    const dialogRef = this.dialog.open<any, any, null | { item: RequisitionLineItem, fulfillAmount: number }>(this.fulfillDialogTemplate, {
      disableClose: true,
      data: {
        item,
        fulfillAmount: null,
      }
    })
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    const { fulfillAmount } = result;
    this.saving = true;
    await this.purchasingService.fulfillRequisitionItem(item, fulfillAmount).toPromise();
    this.saving = false;
    this.getDetail();
  }

  public orderInfo: RequisitionOrderInfo[] = null;

  async getRequisitionOrderInfo() {
    this.orderInfo = null;
    this.orderInfo = await this.purchasingService.getPOsForRequisition(this.record).toPromise();
  }

  public newItemType = 'purchased';
  public newMaterialType: 'new' | 'search' = 'new';

  getPOStatusColorClass(status: number): string {
    return PurchaseOrderStatus.getStatusColorClass(status);
  }

  getPOStatusText(status: number): string {
    return PurchaseOrderStatus.getStatusText(status);
  }

  getShippingTicketStatusColorClass(status: number): string {
    return ShippingService.getStatusColorClass(status);
  }

  getShippingTicketStatusText(status: number): string {
    return ShippingService.getStatusText(status);
  }

  getShippingTicketNumber = ShippingService.getTicketNumber;

  public openTracking(ticket: ShippingTicket): void {
    if (ticket && ticket.shippingCarrier && ticket.trackingNumber) {
      window.open(ticket.shippingCarrier.trackingProvider + ticket.trackingNumber, "_blank");
    }
  }

  public getShippingCarrierIcon(ticket: ShippingTicket): string {
    if (!ticket || !ticket.shippingCarrier || !ticket.shippingCarrier.trackingProvider) return null;

    return `${(new URL(ticket.shippingCarrier.trackingProvider)).origin}/favicon.ico`;
  }

  public openQuoting() {
    this.router.navigate(["/purchasing/quote/requisition", this.record.requisitionId])
  }


}


@Component({
  selector: 'station-select',
  templateUrl: '../../../common/components/generic-select/generic-select.component.html',
  styleUrls: ['../../../common/components/generic-select/generic-select.component.less']
})
export class StationSelectComponent extends GenericSelectComponent<Station> {

  @Input() station: Station;
  @Output() stationChange = new EventEmitter<Station>();

  constructor(private stationSvc: StationService) {
    super();
  }

  protected onSelect(s: Station): void {
    this.stationChange.emit(s);
  }

  public formatValue(item: Station): string {
    if (!item) return '';
    return item.name;
  }
  public canAdd(): boolean {
    return false;
  }

  public formatSearchResult(item: Station): string {
    return `${item.name || ""}`;
  }
  public get addItemText(): string {
    return "Add Item";
  }
  public get noItemsText(): string {
    return "-- No Items Found --";
  }
  public get placeholderText(): string {
    return "Search Station";
  }

  protected searchFunction(text: string) {
    return this.stationSvc.search(text).pipe(map(s => {
      s.results = s.results.filter(r => r.isOutsourceStep);
      return s;
    }));
  }

  public testSelection(_: any): void {
    {
      if (!this.selection) {
        this.inputTxt.nativeElement.value = "";
        return;
      }
      this.inputTxt.nativeElement.value = this.selection.name;
    }
  }
}
