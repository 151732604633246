<div class="pt-3">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4" style="height: 93vh;">

    <div class="p-0 m-0 row h-100-t">
      <div class="col-4">
        <station-list (selected)="select($event)"></station-list>
        <div class="mt-2 text-right">
          <button class="btn btn-success" (click)="addStation()">
            <i class="fas fa-fw la-lg fa-plus"></i>
            Add Station
          </button>
        </div>
      </div>
      <div class="col-8 h-100-t overflow-y-scroll">
        <div *ngIf="!selectedStation">
          <p class="text-muted text-center font-italic p-5">-- Select a Station to View its Details --</p>
        </div>
        <div *ngIf="selectedStation">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Station Name</label>
                <input #name="ngModel" minlength="3" [(ngModel)]="selectedStation.name" required placeholder="Station Name" type="text" maxlength="100" class="form-control form-control-lg" appStaticEdit [editable]="true" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Standard Cost</label>
                <div class="text-center">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        $
                      </span>
                    </div>
                    <input #cost="ngModel" min="0.0" step="0.01" [(ngModel)]="selectedStation.stdCostPerHour" required placeholder="Cost" type="number" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="text-right">
                  <mat-radio-group aria-label="Price Per" [(ngModel)]="selectedStation.perPart">
                    <mat-radio-button class="mr-2" [value]="true">Per Part</mat-radio-button>
                    <mat-radio-button [value]="false">Per Job</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-checkbox [(ngModel)]="selectedStation.isStandalone">Standalone Step</mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox [(ngModel)]="selectedStation.isOutsourceStep">Outsource Step</mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox [(ngModel)]="selectedStation.isPurchasing">Purchasing Step</mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox [(ngModel)]="selectedStation.isShipping">Shipping Step</mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox [(ngModel)]="selectedStation.isPainting">Painting Step</mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-2 setup-checkbox">
              <mat-checkbox [(ngModel)]="selectedStation.hasSetup">Needs Setup</mat-checkbox>
            </div>
            <div class="col-10">
              <div class="form-group">
                <label>Standard Setup Time</label>
                <div class="text-center">
                  <div class="input-group input-group-sm">
                    <input min="0.0" step="0.01" [(ngModel)]="selectedStation.defaultSetupTime" placeholder="Time" type="number" class="form-control form-control-sm" appStaticEdit [editable]="true" [disabled]="!selectedStation.hasSetup" />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        hrs
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <div class="form-group">
                <label>Op Notes Prefix</label>
                <div class="text-center">
                  <div class="input-group input-group-sm">
                    <input maxlength="3" [(ngModel)]="selectedStation.operationNotesPrefix" placeholder="Prefix" type="text" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="shouldShowMachineData">
            <div class="col">
              <h5 class="w-100 text-center p-2 bg-secondary">Machine Data</h5>
              <div class="p-1 small">
                <div class="row">
                  <div class="col-6 text-center font-weight-bold">
                    <label>Data Label</label>
                  </div>
                  <div class="col-6 text-center font-weight-bold">
                    <label>Data Type</label>
                  </div>
                </div>
                <div *ngIf="!hasExtendedData(selectedStation)">
                  <p class="text-muted text-center font-italic p-5">-- No Machine Data Fields Added --</p>
                </div>
                <div class="container-fluid p-0" *ngFor="let item of selectedStation.extendedFields | keyvalue">
                  <div class="row mb-1">
                    <div class="col-6">
                      <input [ngModel]="item.key" (change)="setNewKey($event, item.key)" required placeholder="Data Label" type="text" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                    </div>
                    <div class="col-4">
                      <mat-select [value]="item.value" (selectionChange)="assignType(item.key, $event)">
                        <mat-option value="text">Text Data</mat-option>
                        <mat-option value="number">Number Data</mat-option>
                      </mat-select>
                    </div>
                    <div class="col-2 text-right">
                      <button class="btn btn-sm btn-danger" (click)="removeMachineField(item.key)">
                        <i class="fas fa-fw la-lg fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-2" *ngIf="canRemove()">
                  <div class="col text-right">
                    <button class="btn btn-success" (click)="addMachineField()">
                      <i class="fas fa-fw la-lg fa-plus"></i>
                      Add Machine Field
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 mt-4 pt-4" align="end">
            <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
              <i class="fas fa-fw fa-lg fa-minus-circle"></i>
              Delete
            </button>
            <button class="btn btn-success" (click)="save()">
              <i class="fas fa-fw fa-lg fa-save"></i>
              Save
            </button>
          </div>
          <div *ngIf="selectedStation.isOutsourceStep">
            <op-specification-editor [station]="selectedStation" [(saving)]="saving"></op-specification-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
