import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { UserService } from '../../../common/services/user.service';
import { Paint } from '../../resources/paint';
import { PaintService } from '../../services/paint.service';

@Component({
  selector: 'paint-select',
  templateUrl: './paint-select.component.html',
  styleUrls: ['./paint-select.component.less']
})
export class PaintSelectComponent {
  @Input() paint: Paint;
  @Output() paintChange: EventEmitter<Paint> = new EventEmitter<Paint>();
  @Input() editable: boolean;
  @ViewChild('inputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() addPaint: EventEmitter<string> = new EventEmitter<string>();
  private request: Subscription = null;
  public searching: boolean = false;

  public paints: Paint[] = [];

  constructor(private paintSvc: PaintService, private usrService: UserService) { }

  public setValue(paint: Paint): void {
    this.paint = paint;
    this.paintChange.emit(paint);
  }

  public testSelection(_: any): void {
    if (!this.paint ) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.paint.name;
  }

  public getSheen(paint: Paint): string {
    return Paint.PaintSheen[paint.sheen];
  }

  public getSize(paint: Paint): string {
    return Paint.PaintVolumes[paint.purchaseVolume];
  }

  public toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.search(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public canAdd(): boolean {
    return this.usrService.canAccess("AdminUser");
  }

  public onAdd(): void {
    event.stopImmediatePropagation();
    this.addPaint.emit(this.inputTxt.nativeElement.value);
    this.autoCompleteTrigger.closePanel();
  }

  public search(_: any) {
    this.searching = true;
    if (this.request != null) {
      this.request.unsubscribe();
    }

    this.request = this.paintSvc.search(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        this.searching = false;
        this.paints = result.results
      });
  }
}
