<div>
  <a *ngIf="record && !editing && showEditor==null && isEditable" class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit Record" (click)="toggleEditing()"><i class="fas fa-edit"></i></a>
  <a *ngIf="record && editing && showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit" (click)="toggleEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="record && editing && showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save Record" (click)="saveChanges()"><i class="fas fa-save"></i></a>
  <a *ngIf="record && !editing && parentSidenav" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Close" (click)="navService.popBreadCrumb(); parentSidenav.close()"><i class="fas fa-times"></i></a>

  <div class="row">

    <loading *ngIf="!record"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <mat-sidenav-container *ngIf="record">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <shipping-carrier-detail *ngIf="showEditor=='addCarrier'" [id]="'new'" [sidenav]="sidenav"></shipping-carrier-detail>
        <shipping-pick-location-editor *ngIf="showEditor=='locations'" [picklLocations]="record.shippingTicketPickLocations" [requiredQuantity]="record.quantityRequired" [ticketId]="record.shippingTicketId" [inventoryItemId]="record.inventoryItemId" [sidenav]="sidenav"></shipping-pick-location-editor>
        <shipping-split-ticket *ngIf="showEditor=='split'" [ticketId]="record.shippingTicketId" [totalQuantityRequired]="record.quantityRequired" [sidenav]="sidenav"></shipping-split-ticket>
        <shipping-combine-shipments *ngIf="showEditor=='combine'" [sidenav]="sidenav" [ticketId]="record.shippingTicketId" [batchId]="record.shippingBatchId"></shipping-combine-shipments>
        <shipping-split-view *ngIf="showEditor=='showSplits'" [ticketId]="record.shippingTicketId" [workOrderId]="record.workOrderId" [sidenav]="sidenav"></shipping-split-view>
        <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav"></upload-file>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="row border border-bottom">
          <div *ngIf="userIsManager()" class="col-12 text-center rounded p-4" [ngClass]="{'bg-info text-light': !record.employeeId}">
            <div *ngIf="!record.employeeId">
              <h3>Needs Assignment</h3>
              <p>This ticket needs to be assigned to an employee before it can be edited.</p>
            </div>
            <div class="row align-items-center justify-content-center">
              <div class="col-6">
                <employee-select [editable]="true" (employeeChange)="assigningEmployee = $event" [employee]="assigningEmployee"></employee-select>
              </div>
              <div class="col-2"><button [disabled]="assigningEmployee == record.employee" class="btn btn-success" (click)="saveAssignment()"><span *ngIf="record.employeeId">Re-</span>Assign</button></div>
            </div>
          </div>
        </div>
        <div class="ml-2 pt-4 container-fluid">
          <div class="row">
            <div class="col text-center">
              <div *ngIf="record.status!=2 || editing || !isEditable" class="badge w-90 m-3 text-center" [ngClass]="getStatusColorClass(record.status)">
                <h4 *ngIf="!editing">{{getStatusText(record.status)}}</h4>

                <mat-select *ngIf="editing" [(ngModel)]="record.status" class="form-control-sm">
                  <mat-option [value]="2">In Process</mat-option>
                  <mat-option [value]="3">Packaged</mat-option>
                  <mat-option [value]="4">Shipped</mat-option>
                  <mat-option [value]="5">Received</mat-option>
                </mat-select>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-6">

              <div class="form-group">
                  <div *ngIf="record.workOrderId; else vendor">
                      <label>Customer</label>
                      <div>{{ record.workOrder?.order?.customer?.businessName }}</div>
                  </div>
                  <ng-template #vendor>
                      <label>Vendor</label>
                      <div>{{ record.purchaseOrder?.vendor?.name }}</div>
                  </ng-template>
              </div>
              <div class="form-group">
                <label>Ticket Number</label>
                <span class="d-block small">{{ getTicketNumber(record) }}</span>
              </div>
              <div class="form-group">
                <label>Date Required</label>
                <div>
                  <span *ngIf="getRequiredDate(this.record)">
                    {{ getRequiredDate(this.record) | date:'M/d/yyyy' }}
                  </span>
                  <span *ngIf="!getRequiredDate(this.record)">
                    --
                  </span>
                  <span *ngIf="getRequiredDate(this.record)" class="badge ml-3 required p-2 uk-text-large" [ngClass]="getRequiredColor(getRequiredDate(this.record))">
                    <span class="d-block small">{{weeksLeft(getRequiredDate(this.record), true)||'--'}} Week(s) {{(weeksLeft(getRequiredDate(this.record), false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group container-fluid m-0 p-0">
                <div class="row">
                  <div class="col-4">
                    <label>Class</label>
                    <mat-select *ngIf="editing" [(ngModel)]="record.shippingClass" class="form-control-sm">
                      <mat-option *ngFor="let class of shippingClasses" [value]="class">
                        {{getShippingClassText(class)}}
                      </mat-option>
                    </mat-select>
                    <div *ngIf="!editing" class="d-block small">
                      {{ getShippingClassText(record.shippingClass) }}
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="form-group">
                      <label>Shipping Method</label>
                      <input placeholder="Shipping Method" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="record.shippingMethod" />
                    </div>
                  </div>
                  <div class="col-3 text-right">
                    <button class="btn btn-info btn-sm" (click)="printLabels()">
                      <i class="fas fa-fw fa-lg fa-print"></i>
                      Print Labels
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group container-fluid m-0 p-0">
                <div class="row">
                  <div class="col">
                    <label>Carrier/Service</label>
                    <shipping-carrier-select (onAddItem)="addCarrier($event)" [editable]="editing" [(selection)]="record.shippingCarrier" (selectionChange)="record.shippingCarrierId = $event?.shippingCarrierId"></shipping-carrier-select>
                  </div>
                  <div class="col">
                    <label>Tracking Number</label>
                    <input *ngIf="editing; else trackingLink" placeholder="Tracking Number" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="record.trackingNumber" />
                    <ng-template #trackingLink>
                      <button class="btn btn-sm btn-link d-block" title="Open Tracking" (click)="openTracking()" *ngIf="record.trackingNumber; else noTracking">
                        <img [attr.src]="shippingCarrierIcon" *ngIf="record.shippingCarrier" />
                        {{record.trackingNumber}}
                        <i class="fas fa-fw fa-lg fa-external-link-alt text-muted pl-2"></i>
                      </button>
                      <ng-template #noTracking>
                        <div class="text-muted font-italic small">Tracking Number Not Set</div>
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Departure Date</label>
                <date-picker [placeholder]="'Departure Date'" [editable]="editing" [(date)]="record.departureDate"></date-picker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Item</label>
                <div>
                  {{record.inventoryItem?.name}}
                  <item-type-chip [item]="record.inventoryItem" class="ml-2"></item-type-chip>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Quantity Requisitioned / (Required)</label>
                <div [ngClass]="{'text-danger':(totalRequisitioned < record.quantityRequired), 'text-success':(totalRequisitioned == record.quantityRequired)}">
                  <span class="font-weight-bold">{{totalRequisitioned}}</span> / (<span>{{record.quantityRequired}}</span>)
                </div>
              </div>
            </div>
            <div class="col-3" *ngIf="editing">
              <div class="form-group">
                <label>Split/Combine Shipments</label>
                <div>
                  <button class="btn btn-sm btn-secondary text-secondary" (click)="splitTicket()">Split Ticket</button>
                  <button class="btn btn-sm btn-secondary ml-2 text-secondary" (click)="combineShipments()">Combine Shipments</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6">
              <div class="form-group" *ngIf="record.shippingClass == 1">
                <label>Pulling From</label>
                <div>
                  <div *ngFor="let pickLocation of record.shippingTicketPickLocations">
                    <span class="font-weight-bold">{{pickLocation.quantity}}</span>
                    <span class="text-secondary small"> from </span>
                    {{pickLocation.inventoryItemLocation?.inventoryLocation?.name}}
                  </div>
                  <div *ngIf="record.shippingTicketPickLocations?.length == 0" class="text-center text-muted font-italic small">-- No Pick Locations Set --</div>
                  <div class="float-right" *ngIf="editing">
                    <button class="btn btn-sm btn-primary" (click)="editPickLocations()">
                      <i class="fas fa-fw fa-lg fa-edit"></i>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="record.shippingClass == 0">
                <label>Placed In</label>
                <div>
                  <div *ngFor="let placeLocation of record.shippingTicketPlaceLocations">
                    <span class="font-weight-bold">{{placeLocation.quantity}}</span>
                    <span class="text-secondary small"> in </span>
                    {{placeLocation.inventoryItemLocation?.inventoryLocation?.name}} (Lot Number: <b>{{placeLocation.inventoryItemLocation?.internalLotNumber ?? 'None'}}</b>)
                  </div>
                  <div *ngIf="record.shippingTicketPlaceLocations?.length == 0" class="text-center text-muted font-italic small">-- No Place Locations Set --</div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div *ngIf="ticketsForFulfillment > 1" class="form-group">
                <label>Split Shipment</label>
                <div class="small">
                  Shipment <span class="font-weight-bold">{{record.ticketNumber+1}}</span> of <button (click)="showTicketsForWorkOrder()" class="btn-inline btn btn-link btn-sm font-weight-bold">{{ticketsForFulfillment}}</button>
                </div>
              </div>
              <div *ngIf="ticketsinBatch?.length > 1" class="form-group">
                <label>Combined Shipments</label>
                <table class="table-striped w-100 border">
                  <thead class="small">
                    <tr class="small">
                      <th>Ticket Number</th>
                      <th>Item</th>
                      <th>Item Type</th>
                      <th>Date Required</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ticket of ticketsinBatch">
                      <td>
                        <button (click)="openTicket(ticket)" class="btn btn-link btn-sm" *ngIf="ticket.shippingTicketId != record.shippingTicketId; else thisTicket">
                          <i class="fas fa-fw fa-external-link-alt text-muted pr-2"></i>
                          {{getTicketNumber(ticket)}}
                        </button>
                        <ng-template #thisTicket>
                          <button [disabled]="true" class="btn btn-link btn-sm font-weight-bold text-muted">
                            <i class="fas fa-fw fa-arrow-right text-muted pr-2"></i>
                            {{getTicketNumber(ticket)}}
                          </button>
                        </ng-template>
                      </td>
                      <td class="small">
                         {{ticket.inventoryItem?.name}}
                      </td>
                      <td class="small">
                       <item-type-chip [item]="ticket.inventoryItem" class="ml-2"></item-type-chip>
                      </td>
                      <td class="small">{{getRequiredDate(ticket) | date:'M/d/yyyy'}}</td>
                      <td class="small">{{ticket.quantityRequired}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-12 pl-0 pr-1 bg-light">
              <h5 class="w-100 p-2 pl-1 bg-secondary">Notes</h5>
              <div class="p-1">
                <textarea rows="6" appStaticEdit [editable]="editing" class="form-control form-control-sm" [(ngModel)]="record.notes" placeholder="Notes can go here"></textarea>
              </div>
            </div>
          </div>
          <div class="row pb-2 mb-4">
            <div class="col-12 p-0 bg-light mb-4">
              <h5 class="w-100 p-2 bg-secondary">Documents</h5>
              <div class="p-2">
                <file-display-grid (delete)="deleteDocument($event)" [editable]="editing" [files]="record.shippingTicketDocuments"></file-display-grid>
                <p *ngIf="record.shippingTicketDocuments.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
                <a *ngIf="editing" class="btn btn-badge bg-success has-icon float-right" title="Add Document" (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>


  </div>
</div>
