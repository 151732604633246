import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../common/resources/address';
import { WorkOrder } from '../../../planning/resources/work-order';
import { ShippingTicket, ShippingTicketStatus } from '../../../shipping/resources/shipping-ticket';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { CustomerPurchaseOrderLineItem } from '../../resources/customer-po';
import { Order, OrderStatus } from '../../resources/order';

@Component({
  selector: 'quote-accepted-pos',
  templateUrl: './quote-accepted-pos.component.html',
  styleUrls: ['./quote-accepted-pos.component.less']
})
export class QuoteAcceptedPosComponent implements OnInit {

  @Input() record: Order;

  constructor() { }

  ngOnInit() {
  }

  public printAddress(address: Address) {
    let out = `${this.record.customer.businessName}
${address.streetAddress}
${address.streetAddress2}
${address.city}, ${address.state} ${address.postalCode}`;
    if (address.phoneNumber) out += `\nTel. ${address.phoneNumber}`
    if (address.faxNumber) out += `\nFax: ${address.faxNumber}`;
    return out;
  }

  public sortItems(items: CustomerPurchaseOrderLineItem[]): CustomerPurchaseOrderLineItem[] {
    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    return items.sort((a, b) => {
      return collator.compare(a.lineItemNumber, b.lineItemNumber);
    });
  }

  public displayedColumns = [
    'lineItemNumber',
    'description',
    'quantity',
    'unitPrice',
    'extPrice',
    'dueDate'
  ]

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public getShippingTicketNumber(wo: WorkOrder, st: ShippingTicket) {
    return `${wo.workOrderNumber}//${st.ticketNumber.toString().padStart(3, '0')}`;
  }

  public getShippingStatusColorClass(status: ShippingTicketStatus): string {
    return ShippingService.getStatusColorClass(status);
  }

  public getShippingStatusText(status: ShippingTicketStatus): string {
    return ShippingService.getStatusText(status);
  }

  public printName(i: CustomerPurchaseOrderLineItem) {
    try {
      if (!i.station) return `${i.product.partNumber} Rev. ${i.product.revision}`;
      else return `${i.station.name} for ${i.product.partNumber} Rev. ${i.product.revision}`
    } catch (e) {
      return ''
    }
  }

}
