import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less']
})
export class SearchBarComponent implements OnInit {
  @Input() @Output() searchString: string;
  @Input() managerView: boolean = false;
  @Input() showManagerBar: boolean = true;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() managerViewChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute) {
    //empty
  }

  private getStorageKey() {
    const routeName = this.route.snapshot.url.toString();
    return `managerView-${routeName}`;
  }

  ngOnInit() {
    
  }

  public doSearch(): void {
    this.search.emit(this.searchString);
  }

  public toggleMyView(): void {
    this.managerView = !this.managerView;

    const key = this.getStorageKey();
    localStorage.setItem(key, JSON.stringify(this.managerView));
    this.managerViewChanged.emit(this.managerView);
  }

  public resetSearch(): void {
    this.searchString = "";
    this.doSearch();
  }
}
