<div class="order-detail-products-label">Associated Parts</div>
<button *ngIf="!editing" tabindex="-1" mat-fab class="extended-fab-button extended-fab-small button-text" color="primary" style="position: absolute; top: -1.25em; right: 2em; z-index: 10"
  [class.bg-info]="canGetBinder"
  [disabled]="!canGetBinder"
  [matTooltip]="canGetBinder ? undefined : 'No tagged drawings available.'"
  matTooltipPosition="above"
  (click)="getBinder()"
>
  <mat-icon svgIcon="folder-download"></mat-icon>
  Get Binder
</button>
<button *ngIf="editing" tabindex="-1" mat-fab class="extended-fab-button extended-fab-small button-text" color="primary" style="position: absolute; top: -1.25em; right: 2em; z-index: 10"
  (click)="addProduct()"
>
  <mat-icon svgIcon="plus-box"></mat-icon>
  Add Product
</button>
<input type="file" style="display: none" #fileInput (change)="onFileChange()">
<input type="file" multiple style="display: none" #productFileInput (change)="onProductFileChange()">
<div class="order-detail-products-border w-100 h-100-t" #container tabindex="0"
style="height: 615px !important"
(keydown.control.v)="$event.preventDefault(); onPaste()"
(keydown.control.a)="$event.preventDefault(); addProduct()"
(contextmenu)="openMenu($event, null)"
(dragenter)="onDragEnter($event)"
(dragleave)="onDragLeave($event)"
(dragover)="$event.preventDefault()"
(drop)="onDrop($event)"
*ngIf="data$ | async as data">
  <div class="drop-overlay" *ngIf="dragging">
    <mat-icon svgIcon="file-multiple" class="text-muted" style="height: 56px; width: 48px; font-size: 48px; line-height: 56px;"></mat-icon>
    <div class="text-muted font-italic mat-small-header mt-2">
      <span>Drop to import spreadsheet data or parse and auto-assign drawings.</span>
    </div>
  </div>
  <div [matMenuTriggerFor]="contextMenu" #menuTrigger="matMenuTrigger" style="position: fixed;" [style]="{
    left: menuPosition.x + 'px',
    top: menuPosition.y + 'px'
  }">
  </div>
  <mat-menu #contextMenu>
    <button mat-menu-item *ngIf="!menuItem && editing" (click)="addProduct()">
      <mat-icon class="text-muted" svgIcon="plus-box"></mat-icon>
      Add Product
    </button>
    <button mat-menu-item *ngIf="!menuItem && editing" (click)="onPaste()" [disabled]="!clipboardIsValid" [matTooltip]="clipboardIsValid ? undefined : 'Could not find valid table data in clipboard.'" matTooltipPosition="after">
      <mat-icon [class.text-muted]="clipboardIsValid" svgIcon="clipboard-multiple-outline"></mat-icon>
      Paste Spreadsheet Data
    </button>
    <button mat-menu-item *ngIf="!menuItem && editing" (click)="fileInput.click()">
      <mat-icon class="text-muted" svgIcon="file-upload"></mat-icon>
      Import Spreadsheet File
    </button>
    <button mat-menu-item *ngIf="menuItem" (click)="openProduct(menuItem)">
      <mat-icon class="text-muted" svgIcon="open-in-new"></mat-icon>
      Open <b>{{menuItem.product.partNumber}} Rev. {{menuItem.product.revision}}</b>
    </button>
    <button mat-menu-item *ngIf="menuItem && editing" (click)="duplicateProduct(menuItem.product)">
      <mat-icon class="text-muted" svgIcon="content-copy"></mat-icon>
      Duplicate <b>{{menuItem.product.partNumber}} Rev. {{menuItem.product.revision}}</b>
    </button>
    <button mat-menu-item *ngIf="menuItem && editing" (click)="deleteProduct(menuItem)">
      <mat-icon class="text-danger">delete</mat-icon>
      Delete <b>{{menuItem.product.partNumber}} Rev. {{menuItem.product.revision}}</b>
    </button>
  </mat-menu>
  <table  mat-table [trackBy]="trackByFn" #matTable cdkDropList (cdkDropListDropped)="drop($event)" [dataSource]="data" class="w-100 products-table bg-transparent">

    <ng-container matColumnDef="dragHandle">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pr-0">
        <mat-icon class="text-muted drag-handle" cdkDragHandle [class.invisible]="!editing" *ngIf="!isLoading(op)">
          drag_handle
        </mat-icon>
        <i class="fa fa-spin fa-circle-notch text-primary" *ngIf="isLoading(op)"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="review">
      <th mat-header-cell *matHeaderCellDef class="px-0 text-center" style="width: 56px">Review</th>
      <td mat-cell *matCellDef="let op" class="px-0 text-center">
        <button mat-icon-button (click)="reviewProduct(op)" [matTooltip]="op.reviewStatus === 0 ? 'Review Product' : op.reviewStatus === -1 ? 'On Hold, Click to Edit' : 'View Review Notes'" matTooltipPosition="after"
        >
          <mat-icon svgIcon="hand-front-right" class="text-primary" *ngIf="op.reviewStatus === -1"></mat-icon>
          <mat-icon svgIcon="receipt-text-edit" class="text-muted" *ngIf="op.reviewStatus === 0"></mat-icon>
          <mat-icon svgIcon="alert-box" class="text-warning" *ngIf="op.reviewStatus === 1"></mat-icon>
          <mat-icon svgIcon="checkbox-marked" class="text-success" *ngIf="op.reviewStatus === 2 || op.reviewStatus === 3"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="reviewEstimate">
      <th mat-header-cell *matHeaderCellDef class="px-0 text-center" style="width: 56px">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="px-0 text-center">
        <button mat-icon-button (click)="viewReviewData(op)" [matTooltip]="op.reviewStatus === 0 ? 'Not Reviewed' : 'View Review Notes'"
        matTooltipPosition="after"
        >
          <mat-icon svgIcon="help-box-outline" class="text-muted" *ngIf="op.reviewStatus === 0"></mat-icon>
          <mat-icon svgIcon="alert-box" class="text-warning" *ngIf="op.reviewStatus === 1"></mat-icon>
          <mat-icon svgIcon="checkbox-marked" class="text-success" *ngIf="op.reviewStatus === 2 || op.reviewStatus === 3"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="partNumber">
      <th mat-header-cell *matHeaderCellDef style="width: 20%">Part Number</th>
      <td mat-cell *matCellDef="let op, let i = index" class="pr-1" (keydown)="$event.stopPropagation()">
          <mat-form-field appearance="outline" class="w-100 dense-field mat-input-no-message">
            <input placeholder="Part Number" matInput [disabled]="isLoading(op)" [readonly]="!editing" type="text" [(ngModel)]="op.product.partNumber" (ngModelChange)="service.notifyEdited(this.product)" [name]="'orderProducts[' + i + '].partNumber'" class="font-weight-bold" required>
          </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="rev">
      <th mat-header-cell *matHeaderCellDef style="width: 5%">Rev</th>
      <td mat-cell *matCellDef="let op, let i = index" class="pr-1" (keydown)="$event.stopPropagation()">
        <mat-form-field appearance="outline" class="w-100 dense-field mat-input-no-message rev-field">
          <input placeholder="Revision" matInput [disabled]="isLoading(op)"  [readonly]="!editing" type="text" [(ngModel)]="op.product.revision" (ngModelChange)="service.notifyEdited(this.product)" [name]="'orderProducts[' + i + '].revision'" required>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantities">
      <th mat-header-cell *matHeaderCellDef >Quantities</th>
      <td mat-cell *matCellDef="let op, let i = index" class="pr-1" (keydown)="$event.stopPropagation()">
          <product-quantity-input *ngIf="!isLoading(op)"  [dense]="true" [(ngModel)]="op.product.quantitiesMap" (ngModelChange)="service.notifyEdited(op.product)" [name]="'orderProducts[' + i + '].quantitiesMap'" [readonly]="!editing" [required]="true"></product-quantity-input>
      </td>
    </ng-container>

    <ng-container matColumnDef="unitPrice">
      <th mat-header-cell *matHeaderCellDef >Unit Price</th>
      <td mat-cell *matCellDef="let op" class="px-1">
        <span *ngIf="!isLoading(op)">
          <div *ngIf="op.unitPriceObservable | async | currency as val; else loading">
            {{ val }}
          </div>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="leadTime">
      <th mat-header-cell *matHeaderCellDef >Lead Time</th>
      <td mat-cell *matCellDef="let op" class="px-1">
        <span *ngIf="!isLoading(op)">
          <ng-container *ngLet="op.leadTimeObservable | async as val">
            <div *ngIf="val !== null; else loading">
              {{ val }} days
            </div>
          </ng-container>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="cloneData">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pl-2">
        <div *ngIf="!isLoading(op) && op.type !== null" class="button-text mat-chip mat-standard-chip" [ngClass]="getCloneDataChipClass(op)">
          {{ getCloneDataText(op) }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="quoteHistory">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pl-2">
        <div *ngIf="!isLoading(op) && (op.type !== 0)" class="button-text mat-chip mat-standard-chip" [ngClass]="getQuoteHistoryChipClass(op.history ?? 0)">
          {{ getQuoteHistoryText(op.history ?? 0) }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="microtasks">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pl-2">
        <div class="rounded border d-inline-flex" *ngIf="!isLoading(op)">
          <div class="border-right microtask-indicator-column">
            <div class="font-weight-bold" matTooltip="Material">
              M
            </div>
            <div class="microtask-indicator" [ngClass]="getStatusColorClass(getSimpleTaskStatus(op, 'material'))">&nbsp;</div>
          </div>
          <div class="border-right microtask-indicator-column">
            <div class="font-weight-bold" matTooltip="Workflow">
              W
            </div>
            <div class="microtask-indicator" [ngClass]="getStatusColorClass(getSimpleTaskStatus(op, 'workflow'))">&nbsp;</div>
          </div>
          <div class="border-right microtask-indicator-column">
            <div class="font-weight-bold" matTooltip="Hardware">
              H
            </div>
            <div class="microtask-indicator" [ngClass]="getStatusColorClass(getSimpleTaskStatus(op, 'hardware'))">&nbsp;</div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="files">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pl-2">
        <button class="mat-square-button files-button" mat-flat-button *ngIf="!isLoading(op)" (click)="onClickFiles(op)"
        [matBadge]="op.product.documents.length" matBadgePosition="below after" matBadgeSize="small"
        >
          <mat-icon svgIcon="file-document-multiple"></mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="filesUpload">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pl-2">
        <button class="mat-square-button" mat-flat-button *ngIf="!isLoading(op)" (click)="onClickUpload(op)">
          <mat-icon svgIcon="file-upload"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="open">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="pl-2">
        <button class="mat-square-button" mat-flat-button color="primary" (click)="openProduct(op)" *ngIf="!isLoading(op)">
          <mat-icon svgIcon="open-in-new"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">&nbsp;</th>
      <td mat-cell *matCellDef="let op" class="px-2">
        <button class="mat-square-button" mat-flat-button color="warn" (click)="deleteProduct(op)" *ngIf="!isLoading(op)">
          <mat-icon>
            delete
          </mat-icon>
        </button>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="!editing" *matRowDef="let op, let i = index; columns: displayedColumns;" tabindex="0" (contextmenu)="$event.stopPropagation(); openMenu($event, op)"
    (keydown.delete)="deleteProduct(op)" (keydown.backspace)="deleteProduct(op)"
    >
      <div class="rounded mat-elevation-z3 px-3 bg-white" *cdkDragPreview [matchSize]="true" style="height: 48px; line-height: 48px; font-size: 14px; font-weight: 500; min-width: 300px; left: -28px;">
        {{ op.product.partNumber ?? '???' }} Rev. {{ op.product.revision ?? '-' }}
      </div>
      <div *cdkDragPlaceholder style="display: table-row">
        <div style="display: table-cell" *ngFor="let col of displayedColumns" class="bg-primary" style="display: table-cell; height: 48px">
        &nbsp;
        </div>
      </div>
    </tr>
    <tr class="mat-row" *matNoDataRow [ngStyle]="{
      height: addingPart ? '0' : auto
    }">
      <td class="mat-cell" [attr.colspan]="displayedColumns.length" class="text-muted small font-italic text-center py-3" *ngIf="!addingPart">
        <span *ngIf="!editing">This order has no products.</span>
        <span *ngIf="editing">This order has no products. Add one with the button above or by pasting or dragging in spreadsheet data.</span>
      </td>
    </tr>
  </table>
  <div class="mat-cell w-100 p-2 d-flex" style="height: 56px" *ngIf="addingPart">
    <div style="width: 47px" class="align-self-center pl-1">
      <mat-icon class="text-muted">add</mat-icon>
    </div>
    <mat-form-field appearance="outline" class="flex-grow-1 dense-field mat-input-no-message">
      <input placeholder="Search Part Number..." #partSearcher matInput [matAutocomplete]="auto" [readonly]="!editing" type="text" name="partSearcher" [formControl]="searchControl" (keydown)="$event.stopPropagation()"
      (keydown.escape)="addingPart = false"
      >
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="partDropdownSelected($event.option.value)" [displayWith]="displayWith.bind(this)">
        <mat-option *ngFor="let item of (productResults | async)" [value]="item">
          <ng-container *ngIf="item.type === 'REPEAT'">
            <mat-icon matPrefix svgIcon="content-copy"></mat-icon>
            <span [innerHTML]="displayWith(item) | highlight: searchControl.value"></span>
          </ng-container>
          <ng-container *ngIf="item.type === 'NEWREV'">
            <mat-icon matPrefix svgIcon="content-duplicate" class="text-success"></mat-icon>
            <span class="text-success">New Revision For Existing PN <span [innerHTML]="item.product.partNumber | highlight: searchControl.value"></span></span>
          </ng-container>
        </mat-option>
        <mat-option *ngIf="!!searchControl.value" [value]="searchControl.value" class="text-muted">
          <mat-icon matPrefix svgIcon="plus-box"></mat-icon>
          New Blank Part <b>"{{searchControl.value}}"</b>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-stroked-button class="button-text ml-2" style="font-size: 14px;" (click)="addingPart = false">
      <mat-icon matPrefix>close</mat-icon>
      Cancel
    </button>
  </div>
  <div class="p-2 d-flex mt-auto">
    <mat-form-field class="flex-grow-1"
      (input)="searchSubject.next($event.target.value)"
      (keydown)="$event.stopPropagation()"
    >
      <input matInput type="text" placeholder="Search parts...">
    </mat-form-field>
    <mat-paginator *ngIf="this.rawData$ | async as raw"
                    [pageSize]="this.PAGE_SIZE"
                    [length]="raw.length"
                   showFirstLastButtons
                   #paginator
                    (page)="onPage($event)"

    >
    </mat-paginator>
  </div>
</div>

<ng-template #newRevDialogTemplate let-data>
  <div class="row" *ngIf="data.availableProducts">
    <div class="col-4">
      <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
        <mat-label>New Rev Number</mat-label>
        <input matInput type="text" [(ngModel)]="data.newRev" required>
      </mat-form-field>
    </div>
    <div class="col-8">
      <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
        <mat-label>Product to Clone</mat-label>
        <mat-select [(ngModel)]="data.productToClone">
          <mat-option *ngFor="let product of data.availableProducts" [value]="product">
            {{ product.partNumber }} Rev. {{ product.revision }}
          </mat-option>
          <mat-option class="text-muted" [value]="null">
            None (Blank Product)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12" align="end">
      <button mat-stroked-button class="button-text" [matDialogClose]="null" class="mr-1">Cancel</button>
      <button mat-flat-button color="primary" class="button-text" [matDialogClose]="{ newRev: data.newRev, productToClone: data.productToClone }" [disabled]="!data.newRev">Create</button>
    </div>
  </div>
  <mat-spinner color="primary" *ngIf="!data.availableProducts"></mat-spinner>
</ng-template>

<ng-template #reviewProductDialogTemplate let-data>
  <h1 mat-dialog-title>
    Review {{ data.op.product.partNumber }} Rev. {{ data.op.product.revision }}
  </h1>
  <div mat-dialog-content ngForm #form="ngForm">
    <mat-form-field appearance="outline" class="w-100 mb-2">
      <mat-label>Review Status</mat-label>
      <mat-select [(ngModel)]="data.reviewStatus" name="reviewStatus">
        <mat-select-trigger>
          <ng-container *ngIf="data.reviewStatus === -1">
            <mat-icon svgIcon="hand-front-right" class="text-primary" matPrefix></mat-icon>
            On Hold
          </ng-container>
          <ng-container *ngIf="data.reviewStatus === 0">
            <mat-icon svgIcon="receipt-text-edit" class="text-muted" matPrefix></mat-icon>
            Not Reviewed
          </ng-container>
          <ng-container *ngIf="data.reviewStatus === 1">
            <mat-icon svgIcon="alert-box" class="text-warning" matPrefix></mat-icon>
            Incomplete
          </ng-container>
          <ng-container *ngIf="data.reviewStatus === 2">
            <mat-icon svgIcon="checkbox-marked" class="text-success" matPrefix></mat-icon>
            Complete
          </ng-container>
        </mat-select-trigger>
        <mat-option [value]="-1">
          <mat-icon svgIcon="hand-front-right" class="text-primary" matPrefix></mat-icon>
          On Hold
        </mat-option>
        <mat-option [value]="0">
          <mat-icon svgIcon="receipt-text-edit" class="text-muted" matPrefix></mat-icon>
          Not Reviewed
        </mat-option>
        <mat-option [value]="1">
          <mat-icon svgIcon="alert-box" class="text-warning" matPrefix></mat-icon>
          Incomplete
        </mat-option>
        <mat-option [value]="2">
          <mat-icon svgIcon="checkbox-marked" class="text-success" matPrefix></mat-icon>
          Complete
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100" *ngIf="data.reviewStatus === 1">
      <mat-label>
        Missing Info
      </mat-label>
      <textarea rows="4" matInput placeholder="Please describe what is missing..." required [(ngModel)]="data.reviewStatusNote" name="reviewStatusNote"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button class="button-text" mat-stroked-button [matDialogClose]="null">Cancel</button>
    <button class="button-text" mat-flat-button color="primary" [disabled]="!form.valid" [matDialogClose]="{ reviewStatus: data.reviewStatus, reviewStatusNote: data.reviewStatusNote }">Finish</button>
  </div>
</ng-template>

<ng-template #viewProductReviewTemplate let-data>
  <h1 mat-dialog-title>
    {{ data.op.product.partNumber }} Rev. {{ data.op.product.revision }}
  </h1>
  <div mat-dialog-content>
    <p>
      Reviewed as <ng-container>
        <mat-chip *ngIf="data.op.reviewStatus === 1 || data.op.reviewStatus === 3" class="bg-warning text-dark button-text" disableRipple style="pointer-events: none;">
          <mat-icon svgIcon="exclamation" matPrefix style="margin-left: -8px;"></mat-icon> Incomplete
        </mat-chip>
        <mat-chip *ngIf="data.op.reviewStatus === 2" class="bg-success text-white button-text" disableRipple style="pointer-events: none;">
          <mat-icon svgIcon="check" matPrefix style="margin-left: -7px; margin-right: 2px;"></mat-icon> Complete
        </mat-chip>
      </ng-container> by <b>{{ record.rfqReviewer.fullName }}</b> on <b>{{ record.dateRFQReviewed | date: 'shortDate' }}</b> 
      <ng-container *ngIf="data.op.reviewStatus === 3">but marked as corrected</ng-container>
    </p>
    <mat-form-field appearance="outline" class="w-100" *ngIf="data.op.reviewStatusNote">
      <mat-label>Review Note</mat-label>
      <textarea matInput readonly [ngModel]="data.op.reviewStatusNote" rows="4"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button class="button-text" mat-stroked-button matDialogClose>Close</button>
  </div>
</ng-template>

<ng-template #loading>
  <div>
    <i class="fa fa-spin fa-circle-notch text-primary"></i>
  </div>
</ng-template>