<div class="snapshot-saving" *ngIf="saving"><loading [content]="currentFileName"></loading></div>

<div>
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6 btn-webcam btn-webcam-cancel" title="Cancel" (click)="cancelEditing()"><i class="fas fa-times"></i></a>
  <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon btn-webcam btn-webcam-upload" title="Upload" *ngIf="photos.length > 0" (click)="doUploads()"><i class="fas fa-arrow-up"></i></a>

  <a class="position-fixed fixed-bottom btn btn-badge btn-badge-large bg-success has-icon btn-webcam btn-switch-camera" title="Snapshot" *ngIf="multipleWebcamsAvailable" (click)="showNextWebcam(true);">Switch<br />Camera</a>
  
  <div class="my-2 mx-3 alert alert-danger small" *ngIf="errorString">
    <h4>Webcam Error: {{errorString}}</h4>
  </div>

  <div class="webcam-snap-shot-carousel-container bg-light">
    <carousel [freeScroll]="false" [cellWidth]="200">
      <div class="carousel-cell photo-carousel-slide" *ngFor="let photo of photos">
        <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon btn-delete-photo" title="DeletePhoto" (click)="deletePhoto(photo)" ><i class="fas fa-times"></i></a>
        <img [src]="photo.imageAsDataUrl" *ngIf="photo.imageAsDataUrl"/>
      </div>
    </carousel>
  </div>
  <div class="webcam-preview-container bg-light">
    <a class="btn btn-badge btn-badge-large bg-success has-icon btn-webcam btn-take-photo btn-take-photo-left" title="Snapshot" *ngIf="showWebcam" (click)="triggerSnapshot()" [disabled]="saving"><i class="fas fa-camera"></i></a>
    <div>
      <webcam [width]="800" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        [imageQuality]="1"
        (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)">
      </webcam>
    </div>

    <a class="btn btn-badge btn-badge-large bg-success has-icon btn-webcam btn-take-photo btn-take-photo-right" title="Snapshot" *ngIf="showWebcam" (click)="triggerSnapshot()" [disabled]="saving"><i class="fas fa-camera"></i></a>
  </div>
</div>
