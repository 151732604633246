<a *ngIf="record && canEdit && !editing && !sidenav.opened"
class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit Record" (click)="edit()"><i
  class="fas fa-edit"></i></a>
<a *ngIf="((id === 'new') || !(record && editing)) && !sidenav.opened"
  class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
  routerLink="/sales/rma"><i class="fas fa-times"></i></a>
<a *ngIf="id !== 'new' && (record && editing) && !sidenav.opened"
class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
(click)="editing = false"><i class="fas fa-times"></i></a>
<a *ngIf="record && editing && !sidenav.opened" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon"
title="Save Record" (click)="save()"><i class="fas fa-save"></i></a>

<loading *ngIf="saving">Saving...</loading>
<loading *ngIf="loading"></loading>

<mat-sidenav-container>
  <mat-sidenav disableClose #sidenav mode="over" position="end" (closed)="showEditor = null">
    <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav"></upload-file>
    <webcam-snapshot *ngIf="showEditor=='photo'" [sidenav]="sidenav" [documentTags]="['RCVD PICTURES']" [saveDocuments]="true" [photoFilenamePrefix]="'WO' + record.workOrder?.workOrderNumber" (photosTaken)="addPhotos($event)"></webcam-snapshot>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card style="min-height: 90vh; margin-left: 25px;" class="w-75 mat-elevation-z6" *ngIf="record" ngForm #form="ngForm" autocomplete="off">
      <mat-card-content>
        <div class="row" [class.not-editing]="!editing">
          <div class="col-12" [class.d-none]="editing">
            <div class="rounded text-center p-2 mb-4" [ngClass]="getStatusColorClass(record.status)">
              <h4 class="text-white font-weight-bold text-uppercase">
                {{ getStatusText(record.status) }}
              </h4>
              <div class="text-white">
                <p *ngIf="record.status === 3">
                  RMA is awaiting disposition by Quality.
                </p>
                <p *ngIf="record.status === 4">
                  RMA has been dispositioned and is awaiting fulfilment.
                </p>
                <p *ngIf="record.status === 7 && !isQuality">
                  RMA request has been sent to Quality for approval.
                </p>
                <p *ngIf="record.status === 8">
                  RMA request was rejected by {{ record.rejectingUser?.fullName || '[INVALID USER]' }} on {{ record.rejectionTime | date}}
                </p>
                <p *ngIf="record.status === 8">
                  <b>Reason given:</b> {{ record.rejectionNote }}
                </p>
                <p *ngIf="record.status === 9" class="text-dark">
                  RMA request has been closed. A new request will need to be opened if followup is required.
                </p>
              </div>
              <div *ngIf="record.status==6 && !isQuality" class="font-weight-light">
                <p class="text-white">You can send this RMA request to Quality for further inspection.</p>
                <div class="pt-4 mb-2" *ngIf="!editing">
                  <button class="btn btn-warning" title="Send to Quality" (click)="sendToQuality()"><i
                      class="fas fa-arrow-right-from-bracket fa-fw fa-2x"></i> Send to Quality</button>
                </div>
              </div>
              <div *ngIf="record.status==7 && isQuality" class="font-weight-light">
                <p class="text-white">This RMA request requires approval.</p>
                <div class="pt-4 mb-2" *ngIf="!editing">
                  <button class="btn btn-success mr-4" title="Approve this ticket" (click)="approve()"><i
                      class="fas fa-check fa-fw fa-2x"></i> Approve</button>
                  <button class="btn btn-danger mr-4" title="Reject this ticket" (click)="reject()"><i
                      class="fas fa-times fa-fw fa-2x"></i> Deny</button>
                </div>
              </div>
              <div *ngIf="record.status==8 && !isQuality" class="font-weight-light">
                <p class="text-white">You can resend an updated version to Quality or close the request altogether.</p>
                <div class="pt-4 mb-2" *ngIf="!editing">
                  <button class="btn btn-secondary mr-4 text-dark" title="Reject this ticket" (click)="close()"><i
                      class="fas fa-times fa-fw fa-2x"></i> Close</button>
                      <button class="btn btn-warning" title="Send to Quality" (click)="sendToQuality()"><i
                        class="fas fa-arrow-right-from-bracket fa-fw fa-2x"></i>Resend to Quality</button>
                </div>
              </div>
              <p class="text-white" *ngIf="record.status === 0">
                Click the button below to proceed once the customer has received the RMA number and tag and confirmed shipment.
              </p>
              <div *ngIf="record.status === 0">
                <button class="btn btn-success" (click)="markAsShipped()">Confirm Customer Response</button>
              </div>
            </div>
          </div>
          <div class="col-5 d-flex align-items-start">
            <customer-search [disabled]="!editing" [(selectedItem)]="record.customer"
              (selectedItemChange)="onCustomerChange($event)"
            ></customer-search>
            <button style="margin-top: 15px;" *ngIf="id !== 'new' && !editing" mat-raised-button color="primary" class="ml-1"
            (click)="getLabel()"
            >
              <mat-icon matPrefix>receipt</mat-icon>
              Generate Customer Label
            </button>
          </div>
          <div class="col-3">
            <mat-slide-toggle *ngIf="id === 'new'" [class.mat-slide-toggle-readonly]="!editing" class="mt-3" [(ngModel)]="record.isNewDataInput" name="historicalData">Historical Data</mat-slide-toggle>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="w-100" *ngIf="!record.rmaNumber">
              <mat-label>RMA Request Number</mat-label>
              <input
                readonly
                matInput
                [value]="record.requestNumber || 'Not Yet Assigned'"
                type="text"
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100" *ngIf="record.rmaNumber">
              <mat-label>RMA Number</mat-label>
              <input
                readonly
                matInput
                [value]="record.rmaNumber || 'Not Yet Assigned'"
                type="text"
              >
            </mat-form-field>
          </div>
          <div class="col-6">
            <employee-search label="Sales Person" [readonly]="!editing" [(selectedItem)]="record.salesPerson" (selectedItemChange)="record.salesPersonId = $event.userId"></employee-search>
          </div>
          <div class="col-6">
            <employee-search label="Quality Assignee" [readonly]="!editing" [disabled]="editing && !isQuality" [(selectedItem)]="record.qualityAssignee" (selectedItemChange)="record.qualityAssigneeId = $event.userId"></employee-search>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100" appearance="outline" *ngIf="editing && !record.isNewDataInput">
              <mat-label>{{ 
                !record.customerId ? 'Select a customer first.' : 
                ((matchedPurchaseOrders && matchedPurchaseOrders.length === 0) ? 'No matching purchase orders found.' : 'Purchase Order Number') }}
              </mat-label>
              <input
                autocomplete="off"
                matInput
                type="text"
                [matAutocomplete]="poAutocomplete"
                [(ngModel)]="purchaseOrderSearch"
                [ngModelOptions]="{standalone: true}"
                [disabled]="!record.customerId || !matchedPurchaseOrders" 
              >
              <mat-autocomplete (optionSelected)="onPurchaseOrderChange($event.option.value)" #poAutocomplete="matAutocomplete"
                [displayWith]="displayWithPO">
                <mat-option *ngFor="let po of filteredPurchaseOrders" [value]="po">
                  {{ po.purchaseOrderNumber }}
                </mat-option>
              </mat-autocomplete>
              <i matSuffix [class.invisible]="matchedPurchaseOrders" class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
            </mat-form-field>
            <mat-form-field *ngIf="editing && record.isNewDataInput" class="w-100" appearance="outline">
              <mat-label>Purchase Order Number</mat-label>
              <input matInput type="text" [(ngModel)]="record.newDataPONumber" name="newDataPONumber" />
            </mat-form-field>
            <mat-form-field *ngIf="!editing" class="w-100" appearance="outline">
              <mat-label>Purchase Order Number</mat-label>
              <input
                matInput
                disabled
                [value]="record.customerPurchaseOrder?.purchaseOrderNumber || ''"
              >
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field [class.mat-select-readonly]="!editing" class="w-100" appearance="outline" *ngIf="!record.isNewDataInput && editing">
              <mat-label>{{
                !record.customerPurchaseOrder ? 'Select a purchase order first.' : 
                ((matchedWorkOrders && matchedWorkOrders.length === 0) ? 'No matching work orders found.' : 'Work Order Number' )
              }}
              <span matPrefix [class.invisible]="!matchedWorkOrders || matchedWorkOrders.length === 0" class="badge badge-primary">{{ matchedWorkOrders && matchedWorkOrders.length }}</span>
              </mat-label>
              <mat-select [disabled]="!matchedWorkOrders || matchedWorkOrders.length === 0" [(ngModel)]="record.workOrder" name="workOrder" (ngModelChange)="record.workOrderId = $event.workOrderId; setWorkOrder($event); onDebitParamChange()"
                [placeholder]="(matchedWorkOrders && matchedWorkOrders.length === 0) ? 'No matching work orders found.' : 'Work Order Number'"
              >
                <ng-container *ngIf="matchedWorkOrders">
                  <mat-option disabled *ngIf="matchedWorkOrders.length === 0">
                    No matching work orders found.
                  </mat-option>
                  <mat-option *ngFor="let wo of matchedWorkOrders" [value]="wo">
                    {{ wo.workOrderNumber }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <i matSuffix [class.invisible]="matchedWorkOrders" class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
            </mat-form-field>
            <mat-form-field *ngIf="record.isNewDataInput && editing" class="w-100" appearance="outline">
              <mat-label>Work Order Number</mat-label>
              <input matInput type="text" [(ngModel)]="record.newDataWorkOrderNumber" name="newDataWorkOrderNumber" />
            </mat-form-field>
          </div>
          <div class="col-4" *ngIf="!record.isNewDataInput">
            <mat-form-field class="w-100 mr-1" appearance="outline">
              <mat-label>Part Number</mat-label>
              <input matInput readonly type="text" [value]="record.workOrder?.product ? (record.workOrder?.product?.partNumber + ' Rev. ' + record.workOrder?.product?.revision) : null" />
            </mat-form-field>
          </div>
          <div class="col-4 d-flex" *ngIf="record.isNewDataInput">
            <mat-form-field class="flex-grow-1 mr-1" appearance="outline">
              <mat-label>Part Number</mat-label>
              <input matInput type="text" [(ngModel)]="record.newDataPartNumber" name="newDataPartNumber" />
            </mat-form-field>
              <mat-form-field style="width: 30%" appearance="outline">
                <mat-label>Rev.</mat-label>
                <input matInput type="text" [(ngModel)]="record.newDataRev" name="newDataRev" />
              </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Quantity</mat-label>
              <input
                matInput
                required
                autocomplete="off"
                [(ngModel)]="record.quantity"
                name="quantity"
                (ngModelChange)="onDebitParamChange()"
                type="number"
                min="0"
                step="1"
                placeholder="Quantity"
                [disabled]="!editing"
                class="text-right"
              >
              <span *ngIf="record.workOrder" matSuffix style="position: relative; bottom: 4px;" class="text-muted">/{{record.workOrder?.product.orderQuantity}}</span>
            </mat-form-field>
          </div>
          <div class="col-1 d-flex align-items-center justify-content-end" style="padding-bottom: 7px; padding-right: 0;">
            <mat-checkbox [(ngModel)]="record.debit" name="debit" (ngModelChange)="onDebitParamChange()" [disabled]="!editing">Debiting</mat-checkbox>
          </div>
          <div class="col-3 d-flex align-items-center">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always" *ngIf="!record.debitOverride" [matTooltip]="debitValue === null ? 'Not enough information to estimate' : null" matTooltipPosition="after">
              <mat-label>Total Debit (est.)</mat-label>
              <i matPrefix [class.invisible]="!debitValueLoading" class="fas fa-circle-notch fa-spin text-primary"></i>
              <span [class.invisible]="debitValue === null" matPrefix style="position: relative; bottom: 4px;" class="text-muted">$</span>
              <input
              [class.invisible]="debitValueLoading"
                autocomplete="off"
                matInput
                [value]="record.debit ? debitValue : null"
                name="debitValue"
                type="text"
                placeholder="--"
                readonly
                [disabled]="!record.debit"
              >
              <button (click)="overrideDebitValue()" *ngIf="editing" matSuffix mat-icon-button class="text-muted" matTooltip="Override amount" matTooltipPosition="after">
                <mat-icon>
                  edit
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline" *ngIf="record.debitOverride">
              <mat-label>Total Debit Amount</mat-label>
              <span matPrefix style="position: relative; bottom: 4px;" class="text-muted">$</span>
              <input
                autocomplete="off"
                matInput
                [(ngModel)]="record.debitOverrideAmount"
                name="debitOverrideAmount"
                type="text"
                placeholder="--"
                [readonly]="!editing"
                [disabled]="record.debit"
                autofocus
              >
              <button (click)="removeDebitOverride()" matSuffix mat-icon-button *ngIf="editing" class="text-muted" matTooltip="Remove override" matTooltipPosition="after">
                <mat-icon>
                  close
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="w-100" appearance="outline" matTooltipPosition="after">
              <mat-label>Additional Fees</mat-label>
              <span matPrefix style="position: relative; bottom: 4px;" class="text-muted">$</span>
              <input
                autocomplete="off"
                matInput
                [(ngModel)]="record.additionalRMAFee"
                name="additionalRMAFee"
                type="text"
                [readonly]="!editing"
                required
              >
            </mat-form-field>
          </div>
          <div class="col-8">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Customer Rejection Number</mat-label>
              <input
                matInput
                [(ngModel)]="record.customerRejectionNumber"
                name="customerRejectionNumber"
                type="text"
                [disabled]="!editing"
                [placeholder]="editing ? 'Customer Rejection Number' : '--'"
              >
            </mat-form-field>
          </div>
          <div class="col-4">
            <date-picker appearance="outline" [(date)]="record.createdDate" [editable]="editing" required></date-picker>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 textarea" appearance="outline">
              <mat-label>Customer Reason for Return</mat-label>
              <textarea
                matInput
                [(ngModel)]="record.returnReason"
                name="returnReason"
                type="text"
                rows="5"
                [disabled]="!editing"
                ></textarea>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 textarea" appearance="outline">
              <mat-label>Requirements Violated</mat-label>
              <textarea
                matInput
                [(ngModel)]="record.requirementsViolated"
                name="requirementsViolated"
                type="text"
                rows="5"
                [disabled]="!editing"
                ></textarea>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100 textarea" appearance="outline">
              <mat-label>Notes</mat-label>
              <textarea
                matInput
                [(ngModel)]="record.notes"
                name="notes"
                type="text"
                rows="5"
                [disabled]="!editing"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="w-100 rounded overflow-hidden bg-light" style="margin: 0 15px;">
            <h5 class="w-100 p-2 bg-secondary">Documents</h5>
            <div class="p-2">
              <file-display-grid (delete)="deleteDocument($event)" [editable]="true" [files]="record.documents"></file-display-grid>
              <p *ngIf="record.documents.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
              <a class="btn btn-badge bg-success has-icon float-right mb-4" title="Add Document" *ngIf="editing" (click)="openDocumentsWindow()"><i class="fas fa-plus mt-2"></i></a>
              <a class="btn btn-badge bg-success has-icon float-right mr-4 mb-4" title="Take Photos" *ngIf="editing" (click)="openPhotosWindow()"><i class="fas fa-camera mt-2"></i></a>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #shippingDetailsDialogTemplate let-data>
  <h2 mat-dialog-title>RMA Shipping Details</h2>
  <mat-dialog-content class="shipping-details-dialog">
    <p>
      Please input as many details about the RMA shipment as possible. Only the destination building is required.
    </p>
    <div class="row">
      <div class="col-12 form-group">
        <label>Carrier/Service</label>
        <shipping-carrier-select
          [editable]="true"
          [enableAdd]="false"
          [(selection)]="data.shippingCarrier"
          (selectionChange)="data.shippingCarrierId = $event?.shippingCarrierId"
        ></shipping-carrier-select>
      </div>
      <div class="col-6 form-group">
        <label>Tracking Number</label>
        <input class="form-control form-control-sm" type="text" [(ngModel)]="data.trackingNumber">
      </div>
      <div class="col-6 form-group">
        <label>Shipping Method</label>
        <input placeholder="Shipping Method" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="data.shippingMethod" />
      </div>
      <div class="col-6 form-group">
        <label>Departure Date</label>
        <date-picker [placeholder]="'Departure Date'" [editable]="true" [(date)]="data.departureDate"></date-picker>
      </div>
      <div class="col-6 form-group">
        <label>Arrival Date</label>
        <date-picker [placeholder]="'Arrival Date'" [editable]="true" [(date)]="data.arrivalDate"></date-picker>
      </div>
      <div class="col-12 form-group">
        <label>Destination Address (required)</label>
        <app-address-selector [filter]="['ourBuildings']" [(address)]="data.destinationAddress" (addressChange)="data.destinationAddressId = $event.addressId"></app-address-selector>
      </div>
      <div class="col-12 form-group">
        <div *ngIf="!data.destinationAddress">
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
        <div *ngIf="data.destinationAddress">
          <div>{{data.destinationAddress.streetAddress}}</div>
          <div>{{data.destinationAddress.streetAddress2}}</div>
          <div>{{ data.destinationAddress.city }}, {{ data.destinationAddress.state }} {{ data.destinationAddress.postalCode }}</div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button [matDialogClose]="null">Cancel</button>
    <button mat-flat-button color="primary" [matDialogClose]="data"
      [disabled]="!data.destinationAddress"
    >Confirm</button>
  </mat-dialog-actions>
</ng-template>


<ng-template let-data #rejectReasonTemplate>
  <h2 mat-dialog-title>Rejecting RMA Request</h2>
  <mat-dialog-content class="mat-typography">
    <p>Please explain why this RMA request was rejected.</p>
    <p>
      <textarea class="form-control form-control-sm" [(ngModel)]="data.reason" placeholder="Rejection Reason"></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-info btn-sm mr-3" [mat-dialog-close]="null">Cancel</button>
    <button class="btn btn-default btn-danger btn-sm mr-3" [disabled]="!data.reason" [mat-dialog-close]="data.reason">Reject</button>
  </mat-dialog-actions>
</ng-template>
