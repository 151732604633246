import IWidgetData from '../interface';
import { CompanyMessagesComponent } from './components';

const messagesWidgets: IWidgetData[] = [
  {
    id: 'companyMessages',
    name: 'Message Board',
    component: CompanyMessagesComponent,
    access: ["User"],
    minHeight: 300,
    minWidth: 350,
  }
]

export default messagesWidgets;
