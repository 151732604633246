import { Component, OnInit, Output, EventEmitter, } from '@angular/core';
import { MaterialSpecification } from '../../../order/resources/material';
import { MaterialService } from '../../../order/services/material.service';

@Component({
  selector: 'material-spec-list',
  templateUrl: './material-spec-list.component.html',
  styleUrls: ['./material-spec-list.component.less']
})
/** station-list component*/
export class MaterialSpecListComponent implements OnInit {
  public materialList: MaterialSpecification[] = null;
  @Output() selected: EventEmitter<MaterialSpecification> = new EventEmitter<MaterialSpecification>();

  constructor(private materialService: MaterialService) { }

  private loadMaterials(): void {
    this.materialService.getMaterialSpecifications('').subscribe(result => {
      this.materialList = result.results;
    });
  }

  public select(material: MaterialSpecification): void {
    this.selected.emit(material);
  }

  ngOnInit(): void {
    this.loadMaterials();
    this.materialService.updated.asObservable().subscribe(_ => this.loadMaterials());
  }
}
