<div>
  <div *ngIf="editable" class="input-group mb-1">
    <input #qtyInput placeholder="Add Qty" (keyup.enter)="addQty(qtyInput.value)" type="number" min="0" max="10000" class="form-control" appStaticEdit [editable]="editable" />
    <div class="input-group-append">
      <button (click)="addQty(qtyInput.value)" class="btn btn-default"><i class="fas fa-fw fa-lg fa-plus"></i></button>
    </div>
  </div>
  <span *ngFor="let q of quantityArray; let i=index" class="badge badge-secondary text-dark p-1 pl-2 pr-2 mr-1">
    {{q.value}}
    <a title="Remove Quantity" href="javascript:void(0);" *ngIf="editable" class="ml-1 text-dark" (click)="removeQty(i)"><i class="fas fa-times"></i></a>
  </span>
  <p *ngIf="quantityArray.length==0" class="small text-muted">-- No Quantities Entered --</p>
</div>
