<h2 mat-dialog-title>Quote Status</h2>
<mat-dialog-content class="mat-typography m-2 p-4">
  <p class="font-weight-bold">Is this Bid complete?</p>
  <div class="ml-2">
    <div>
      <mat-radio-group [(ngModel)]="status">
        <mat-radio-button [value]="0" class="d-block">
          No, I'm still adding/editing things
        </mat-radio-button>
        <mat-radio-button [value]="1" class="d-block">
          Yes, and I'm waiting on the supplier
        </mat-radio-button>
        <mat-radio-button [value]="2" class="d-block">
          Yes, all the data entry is done
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="ml-2 pt-4" *ngIf="status==2">
      <mat-checkbox [(ngModel)]="createPo">Create a new PO Now</mat-checkbox>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{status: status, createPo: createPo}">Okay</button>
</mat-dialog-actions>
