import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'oor-gantt-bar',
  templateUrl: './oor-gantt-bar.component.html',
  styleUrls: ['./oor-gantt-bar.component.less']
})
export class OorGanttBarComponent implements OnInit {

  @Input() colorClass: '0%';
  @Input() start: '0%';
  @Input() width: '0%';
  @Input() tooltip: string | null = null;
  @Input() colorOverride: string | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
