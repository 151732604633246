import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgModel, UntypedFormControl } from '@angular/forms';
import { VendorService } from '../../../supplier/services/vendor.service';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.less']
})
export class CertificateListComponent implements OnInit {
  public items: string[];
  @Input() name: string;
  @Output() nameChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() required: boolean = false;
  @Input() editable: boolean;
  @ViewChild('autoCompleteTrigger') autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('inputText') inputField: NgModel;
  searching: boolean = true;

  constructor(private vendorSvc: VendorService) { }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getList(this.name);
      this.autoCompleteTrigger.openPanel();
    }
  }

  untouched = true;

  onFocus() {
    if (!this.editable) return;
    console.log('onFocus', this.untouched)
    this.toggleDropdown();
    if (this.untouched) {
      this.untouched = false;
      this.getList('');
      
    }
  }

  getListEvent = new Subject<string>();

  getList(str?: string): void {
    const fieldVal = this.inputField.value || '';
    str = str === undefined ? fieldVal : str;
    this.getListEvent.next(str);
  }

  setSelection() {
    this.nameChange.emit(this.inputField.value);
  }

  public get invalid(): boolean {
    return this.required ? this.inputField.invalid : false;
  }

  public get control(): UntypedFormControl {
    if (this.required) {
      return this.inputField.control;
    }
  }

  ngOnInit() {
    this.getListEvent.pipe(
      tap(() => this.searching = true),
      debounceTime(300),
    ).subscribe(x => {
      this.searching = true;
      this.vendorSvc.getCertificateTitles(x).subscribe(results => {
        this.items = results;
        this.searching = false;
      });
    })
  }

}
