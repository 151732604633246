import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map, mergeMap, startWith } from 'rxjs/operators';
import { OutsideProcessByProduct, PurchasingService } from '../../services/purchasing.service';
import { OutsideProcessDescription } from '../../resources/outsideProcessDescription';

const enCollator = new Intl.Collator('en');

function tokenSearch(target: string, search: string) {
  const tokens = (search ?? "").split(" ");
  return tokens.some(tk => target.includes(tk));
}

@Component({
  selector: 'po-outside-process-select',
  templateUrl: './po-outside-process-select.component.html',
  styleUrls: ['./po-outside-process-select.component.less']
})
export class PoOutsideProcessSelectComponent implements OnInit {

  constructor(
    private svc: PurchasingService
  ) { }

  public searchControl = new FormControl<string>('');
  public results: Observable<OutsideProcessByProduct[]>;

  ngOnInit(): void {
    const searchedProcesses = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      mergeMap((searchText) => this.svc.searchOutsideProcessesByProduct(searchText))
    );
    this.results = combineLatest([searchedProcesses, this.searchControl.valueChanges.pipe(startWith(''))]).pipe(
      // Clientside filtering
      map(([results, string]) => {
        if (typeof string !== 'string') return results;
        return results.filter(p => tokenSearch(`${p.partNumber} Rev. ${p.revision}`.toLowerCase() + ' ' +
          p.outsideProcessDescription?.name.toLowerCase().includes(string.toLowerCase()) + ' ' +
          p.outsideProcessDescription?.outsideProcessDescriptionNumber.toLowerCase(), string.toLowerCase())
        )
      }),
      map(results => results.sort((a, b) => (a.outsideProcessDescription ? 0 : 1) - (b.outsideProcessDescription ? 0 : 1))),
      map(results => results.sort((a, b) => enCollator.compare(a.revision, b.revision))),
      map(results => results.sort((a, b) => enCollator.compare(a.partNumber, b.partNumber)))
    );
  }

  public getStationString(item: OutsideProcessDescription) {
    if (item.steps.length === 0) return "No Processes";
    return Array.from(new Set(item.steps.filter(s => !!s.station).map(s => s.station.name))).join(', ')
  }

  @Output('selected') selected = new EventEmitter<OutsideProcessByProduct | string>();
  public optionSelected(item: OutsideProcessByProduct | string) {
    this.selected.emit(item);
  }

}
