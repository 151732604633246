import { Component, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { OrderService } from '../../../order/services/order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Product } from '../../resources/product';
import { Workflow } from '../../resources/workflow';

@Component({
  selector: 'product-finder',
  templateUrl: './product-finder.component.html',
  styleUrls: ['./product-finder.component.less']
})
/** product-finder component*/
export class ProductFinderComponent {
  @Input() sidenav: MatSidenav;
  @Output() partSelected: EventEmitter<Product> = new EventEmitter<Product>();
  private newPart: Product = null;
  public parts: Product[] = null;
  private displayText: string = null;
  public searching: boolean = false;
  adding: boolean = false;
  @ViewChild('partInputText') inputTxt: NgModel;
  @ViewChild('autoCompleteTrigger') autoCompleteTrigger: MatAutocompleteTrigger;
  private request: Subscription = null;
  @ViewChild('partNumber') partNumber: NgModel;
  @ViewChild('partNumber') partNumberControl: ElementRef;
  @ViewChild('partRevision') partRevision: NgModel;

  constructor(private utilitySvc: UtilityService, private orderSvc: OrderService) { }

  public cancelEditing(): void {

    this.utilitySvc.showConfirmation("Are you Sure?",
      "<p>This product won't be added to your order.</p>",
      (r => {
        if (r == true) {
          this.sidenav.close();
        }
      }));
  }

  getPartList(_: KeyboardEvent) {
    this.searching = true;
    if (this.request != null) {
      this.request.unsubscribe();
    }

    this.request = this.orderSvc.searchProducts(this.inputTxt.value)
      .subscribe(result => {
        this.parts = result;
        this.searching = false;
      });
  }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getPartList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  setValue(event: any) {
    this.newPart = event;
    this.displayText = event.partNumber + ' Rev ' + event.revision;
  }

  switchToAdding(): void {
    this.newPart = <Product>{
      productId: UtilityService.emptyGuid,
      childAssemblies: [],
      documents: [],
      workflow: <Workflow>{
        workflowId: UtilityService.newGuid(),
        workflowSteps: []
      },
      numberTestPeices: 0,
      quantitiesMap: []
    };

    this.newPart.partNumber = this.inputTxt.value;

    this.adding = true;
  }

  addProduct(): void {
    if (this.newPart == null || this.newPart.partNumber == null || this.newPart.revision == null || this.newPart.partNumber.length < 2 || this.newPart.revision.length == 0) {

      var msgTitle = "Please Select a Part";
      var msgContent = "<p>Please select a part, or choose cancel.</p>";

      if (this.adding) {
        this.partRevision.control.markAsTouched();
        this.partNumber.control.markAsTouched();

        if (this.partNumber.invalid) {
          msgTitle = "Please Enter a Part Number";
          msgContent = "<p>Please enter a part number to continue.</p>";
        }
        else if (this.partRevision.invalid) {
          msgTitle = "Please Enter a Part Revision";
          msgContent = "<p>Please enter a part revision level to continue.</p>";
        }
      }
      else {
        this.inputTxt.control.markAsTouched();
      }

      this.utilitySvc.showAlert(msgTitle, msgContent);

      return;
    }


    if (!this.adding) {
      // get full details for the product so workflow etc aren't null
      this.orderSvc.getProduct(this.newPart.productId).subscribe(product => {
        this.partSelected.emit(product);
        this.sidenav.close();
      })
    }
    else {
      this.partSelected.emit(this.newPart);
      this.sidenav.close();
    }
  }

}
