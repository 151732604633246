<div class="container-fluid shadow">
  <mat-sidenav-container>
    <mat-sidenav disableClose #sidenav mode="over" position="end" id="locationPanel">
      <inventory-location-detail #locationDetail [locationPanel]="locationPanel"
        (locationSelected)="updateLocation($event)"></inventory-location-detail>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- <a *ngIf="item && !editing" class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit Record" (click)="toggleEditing()"><i class="fas fa-edit"></i></a> -->
      <a *ngIf="item && editing" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6"
        title="Cancel Edit" (click)="toggleEditing()"><i class="fas fa-times"></i></a>
      <a *ngIf="item && editing" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon"
        title="Save Record" (click)="saveChanges()"><i class="fas fa-save"></i></a>

      <mat-stepper #stepper *ngIf="item">
        <mat-step label="Details">
          <div class="row" *ngIf="showName">
            <div class="col">
              <div class="form-group">
                <label>Item</label>
                <div>
                  {{item.name}}
                  <mat-chip class="ml-2 text-white text-uppercase font-weight-bold"
                    [class.bg-success]="getItemTypeName(item) === 'Part/Assembly'" [class.bg-danger]="getItemTypeName(item) ===
            'Raw Material'" [class.bg-info]="getItemTypeName(item) === 'Purchased/Hardware'">
                    <i [class]="'mr-2 fas fa-' + getItemTypeIcon(item)"></i>
                    {{getItemTypeName(item)}}
                  </mat-chip>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Notes</label>
                <input [placeholder]="editing ? 'Mind the sharp edges' : '--'" type="text" max="100"
                  class="form-control form-control-sm" appStaticEdit [editable]="editing" [(ngModel)]="item.notes" />
              </div>
            </div>
          </div>
          <div class="form-group mb-1">
            <label>Labels</label>
          </div>
          <div class="row mb-4">
            <div class="col">
              <mat-chip-list #chipList aria-label="Inventory Item Labels">
                <mat-chip title="Inventory Item Label" class="text-secondary small" *ngFor="let label of item.labels"
                  [selectable]="editing" [removable]="editing" (removed)="removeLabel(item, label)">
                  {{label}}
                  <mat-icon matChipRemove *ngIf="editing" title="Remove Label"
                    class="fas text-muted fa-fw fa-2x fa-times-circle"></mat-icon>
                </mat-chip>
                <div class="form-group">
                  <input (keyup)="labelInputChanged()" (click)="ignoreOpen($event)" *ngIf="editing"
                    class="form-control form-control-sm" placeholder="New Label..." #labelInput type="text"
                    [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addNewLabel(item, $event)">
                </div>
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addLabel(item, $event)">
                <mat-option *ngFor="let label of filteredLabels">
                  {{label}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="h5">Locations</div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table class="table relative mat-card rounded overflow-none">
                <button style="position: absolute; top: -28px; right: 50px;" mat-fab color="primary"
                  (click)="addNewLocation()">
                  <mat-icon>add</mat-icon>
                </button>
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Quantity</th>
                    <th>PO #</th>
                    <th>WO #</th>
                    <th>Lot #</th>
                    <th>Heat #</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="this.itemLocations.length === 0" class="bg-light text-muted small font-italic text-center">
                    <td colspan="6">
                      No locations.
                    </td>
                  </tr>
                  <tr *ngFor="let itemLoc of this.itemLocations">
                    <td>
                      <mat-chip class="bg-dark text-white">
                        <ng-container *ngFor="let location of itemLoc.address; last as isLast">
                          <span>{{location.name}}</span>
                          <mat-icon class="mx-1" *ngIf="!isLast">chevron_right</mat-icon>
                        </ng-container>
                      </mat-chip>
                    </td>
                    <td>
                      {{itemLoc.quantity}}
                    </td>
                    <td>
                      {{itemLoc.purchaseOrder?.purchaseOrderNumber || '--'}}
                    </td>
                    <td>
                      {{itemLoc.workOrder?.workOrderNumber ?? itemLoc.legacyWorkOrderOrDeptCode ?? '--'}}
                    </td>
                    <td>
                      {{itemLoc.internalLotNumber || '--'}}
                    </td>
                    <td>
                      {{itemLoc.heatLotNumber || '--'}}
                    </td>
                    <td>
                      <button mat-icon-button color="warn" (click)="delete(itemLoc)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
