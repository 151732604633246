import { Component, OnInit, Output, EventEmitter, } from '@angular/core';
import { MaterialHardness } from '../../../order/resources/material';
import { MaterialService } from '../../../order/services/material.service';

@Component({
  selector: 'material-hardness-list',
  templateUrl: './material-hardness-list.component.html',
  styleUrls: ['./material-hardness-list.component.less']
})
/** station-list component*/
export class MaterialHardnessListComponent implements OnInit {
  public materialList: MaterialHardness[] = null;
  @Output() selected: EventEmitter<MaterialHardness> = new EventEmitter<MaterialHardness>();

  constructor(private materialService: MaterialService) { }

  private loadMaterials(): void {
    this.materialService.getMaterialHardnesses('').subscribe(result => {
      this.materialList = result.results;
    });
  }

  public select(material: MaterialHardness): void {
    this.selected.emit(material);
  }

  ngOnInit(): void {
    this.loadMaterials();
    this.materialService.updated.asObservable().subscribe(_ => this.loadMaterials());
  }
}
