import { Injectable, Inject, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, UserRole } from '../resources/user';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, filter, first, tap } from 'rxjs/operators';
import { MessageType } from '../resources/message';
import { MessageService } from './message.service';
import { ErrorHandlerService } from './errorHandler.service';
import { httpService } from './http.service';
import { SearchResult } from '../resources/search-result';
import * as Sentry from '@sentry/browser';


@Directive()
@Injectable({
  providedIn: 'root',
})
export class UserService extends httpService {
  private userObservable: BehaviorSubject<User> = new BehaviorSubject(<User>{});
  public userList = new ReplaySubject<User[]>(1);
  public userData: User;
  public userLoggedIn = this.userObservable.pipe(
    filter(x => !!x && !!x.userId),
    first()
  );
  public userReplaySubject = new ReplaySubject<User>(1);
  public user: Observable<User> = this.userObservable.asObservable();
  private apiBase: string = 'api/user';
  private apiUrl: string;

  public roles: UserRole[] = [];
  public loaded: boolean = false;
  @Output() rolesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl) {
    super(errorHandler, messages);
    this.serviceName = "User";

    this.apiUrl = this.baseUrl + this.apiBase;
    this.getRoles();
    this.getData();
  }

  private getRoles(): void {
    this.rolesLoaded.emit(this.loaded = false);
    this.http.get<any[]>(this.apiUrl + '/getCotsGroups').pipe(
      tap(groups => {
        this.roles = [];
        groups.forEach(g => {
          this.roles.push(<UserRole>{
            id: g.id,
            name: g.name.substring(4),
            niceName: g.name.substring(4).replace(/([A-Z])/g, ' $1').substring(1)
          });
          g.name = g.name.substring(4);
        });
        this.rolesLoaded.emit(this.loaded = true);
      }),
      catchError(this.handleError<any>("Get COTS Roles", null))
    ).subscribe();
  }

  private doHeartbeat() {
    this.http.get(this.apiUrl + '/heartbeat').subscribe(() => {
      console.log('heartbeat ok');
    });
  }

  private heartbeatTimer: any = null;
  private startHeartbeat() {
    if (this.heartbeatTimer != null) clearInterval(this.heartbeatTimer);
    this.heartbeatTimer = setInterval(() => {
      this.doHeartbeat();
    }, 1000 * 60)
  }

  private getData(): void {
    this.http.get<User>(this.apiUrl + '/getOwn').pipe(
      tap(usr => {
        this.userData = usr;

        try {
          console.log('sentry.setUser')
          Sentry.setUser({
            name: usr.fullName,
            email: usr.contactEmail
          });
        } catch (_) {}

        this.startHeartbeat();

        this.userObservable.next(this.userData);
        this.messages.add(`User ${usr.fullName} Logged In Successfully`, MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<any>("Get Self User Data", null))
    ).subscribe(this.userReplaySubject);
    this.http.get<User[]>(this.apiUrl + '/list').subscribe(s => this.userList.next(s));
  }

  search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string, roleFilter?: string, roleFilterMode?: boolean): Observable<SearchResult<User>> {
    return this.http.get<SearchResult<User>>(this.apiUrl + '/searchFilter', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc", roleFilter: roleFilter || "", roleFilterAllowAny: roleFilterMode ? 'true' : 'false' } }).pipe(
      catchError(this.handleError<any>("Get Users Search Results", null))
    );
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(this.apiUrl + '/' + userId).pipe(
      catchError(this.handleError<any>("Get User By ID", null))
    );
  }

  getSalesPeople(searchString?: string): Observable<SearchResult<User>> {
    return this.http.get<SearchResult<User>>(this.apiUrl + '/getSalesUsers', { params: { searchText: searchString, pageIndex: '0', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Sales People Search Results", null))
    );
  }
  
  listUserNames(): Observable<Pick<User, 'userId' | 'fullName'>[]> {
    return this.http.get<SearchResult<User>>(this.apiUrl + '/listUserNames').pipe(
      catchError(this.handleError<any>("List User Names", null))
    );
  }

  updateUserInfo(user: User): Observable<User> {
    user.directSupervisor = null;
    return this.http.post<User>(this.apiUrl, user).pipe(
      tap(u => {
        this.messages.add("User Service: User Updated Successfully", MessageType.SUCCESS, true);
        this.getData();
      }),
      catchError(this.handleError<any>("Update User", null))
    );
  }

  public canAccess(area: string, user?: User): boolean {
    if (user == null) user = this.userData;
    if (user == null) return false;

    if (user.permissionGroups != null)
      return user.permissionGroups.findIndex(a => a.name.toLowerCase() == area.toLowerCase()) >= 0;

    return false;
  }

}
