import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { User } from '../../../common/resources/user';
import { FloorOpsMetaData, MachineProficiency } from '../../resources/floor-ops-meta-data';
import { FloorService } from '../../../floor/services/floor.service';
import { FloorMachine, FloorBuilding, Department } from '../../../floor/resources/building';

@Component({
  selector: 'floor-user-setup',
  templateUrl: './floor-user-setup.component.html',
  styleUrls: ['./floor-user-setup.component.less']
})
export class FloorUserSetupComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() editable: boolean = false;
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();
  public metaData: FloorOpsMetaData;
  public lastBuildingId: string = null;

  public machines: FloorMachine[];
  public buildings: FloorBuilding[];
  public departments: Department[];


  constructor(private floorSvc: FloorService) { }

  public updateSource(): void {
    this.userChange.emit(this.user);
  }

  public getDepartment(departmentId: string): Department {
    if (!this.departments) return null;

    return this.departments.find(d => d.departmentId == departmentId);
  }

  public getBuilding(buildingId: string, increment?: boolean): FloorBuilding {
    if (!this.buildings) return null;

    if (increment || false) this.lastBuildingId = buildingId;
    return this.buildings.find(b => b.floorBuildingId == buildingId);
  }

  private ensureMetaRoot(): void {
    this.user.permissionMetaData["floorOps"] = this.user.permissionMetaData["floorOps"] ? <FloorOpsMetaData>this.user.permissionMetaData["floorOps"] : new FloorOpsMetaData();

    this.metaData = this.user.permissionMetaData["floorOps"];
  }

  public getRatingFor(m: FloorMachine): number {
    const machine = this.metaData.machineProficiencies.find(p => p.machineId == m.machineId);

    if (machine)
      return machine.level;

    return 0;
  }

  public setRating(m: FloorMachine, n: number): void {
    var machine = this.metaData.machineProficiencies.find(p => p.machineId == m.machineId);

    if (n >= 0) {
      if (machine)
        machine.level = n;
      else
        this.metaData.machineProficiencies.push({ level: n, machineId: m.machineId });
    }
    else {
      if (machine) {
        this.metaData.machineProficiencies.splice(this.metaData.machineProficiencies.findIndex(p => p.machineId == m.machineId), 1);
      }
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.ensureMetaRoot();
  }

  ngOnInit(): void {
    this.ensureMetaRoot();

    this.floorSvc.getMachineList().subscribe(m => {
      this.machines = m.results.sort((a, b) => a.floorBuildingId < b.floorBuildingId ? -1 : 1);
    });
    this.floorSvc.getAllFloorBuildings().subscribe(d => {
      this.buildings = d.results.sort((a, b) => a.departmentId < b.departmentId ? -1 : 1);

      this.floorSvc.search().subscribe(b => {
        this.departments = b.results;
      });
    });
  }
}
