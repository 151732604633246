import { Component } from '@angular/core';
import { NavigationService } from './cots/common/services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  navExpanded: boolean = true;

  constructor(private navService: NavigationService, router: Router) {
    var returnToRoute = window.sessionStorage.getItem('returnTo');
    if (returnToRoute != null) {
      window.sessionStorage.removeItem('returnTo');
      router.navigate([returnToRoute]);
    }
  }

  ngOnInit() {
    this.navService.isExpanded.subscribe(state => this.navExpanded = state);
  }
}
