import { Directive, Input, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from "@angular/forms";

@Directive({
  selector: '[minValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }
  ]
})
export class MinValidatorDirective implements Validator, OnChanges {

  @Input() minValidator: number;
 
  constructor(private el: ElementRef<Element>) {
    
  }

  private minOverride = false;
 
  ngOnChanges(changes: SimpleChanges) {
    const { nativeElement } = this.el;
    if (changes.minValidator.isFirstChange()) {
      if (nativeElement && nativeElement.tagName === 'INPUT' && (nativeElement as HTMLInputElement).type === 'number') {
        if ((nativeElement as HTMLInputElement).min) {
          this.minOverride = true;
        }
      }
    }
    if (this.minOverride) return;
    if (nativeElement && nativeElement.tagName === 'INPUT' && (nativeElement as HTMLInputElement).type === 'number') {
      (nativeElement as HTMLInputElement).min = (this.minValidator || 0).toString();
    }
  }
 
  validate(c: FormControl<number>): ValidationErrors | null {
 
    if (isNaN(this.minValidator) || c.value < this.minValidator) {
      return { min: true };
    }
    return null;
  }
}