<button mat-fab color="error" class="extended-fab-button position-fixed bg-danger text-white" style="bottom: 25px; right: 20px; z-index: 1030" (click)="abort()">
  <mat-icon>close</mat-icon>
  <span class="extended-fab-button__text">Abort</span>
</button>

<loading *ngIf="saving" content="Saving..."></loading>

<mat-stepper orientation="vertical" [linear]="true" class="checkin-stepper">
  <mat-step #step label="Review ticket details" [stepControl]="checklistForm">
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-title>Ticket #{{getTicketNumber(ticket)}}</mat-card-title>
      <mat-card-subtitle>Checklist</mat-card-subtitle>
      <div class="my-1 checklist" [formGroup]="checklistForm">
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="item">
            <div mat-line class="small font-weight-bold">
              Item
            </div>
            <div mat-line>
              <item-type-chip [iconOnly]="true" [item]="ticket.inventoryItem" class="mr-1"></item-type-chip>
              {{ ticket.inventoryItem.name }}
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="quantity">
            <div mat-line class="small font-weight-bold">
              Quantity
            </div>
            <div mat-line>
              <b>{{ticket.quantityRequired}}</b> units
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="origin">
            <div mat-line class="small font-weight-bold">
              Sending to
            </div>
            <div mat-line>
              {{sendingTo}}
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="poNumber">
            <div mat-line class="small font-weight-bold">
              Purchase Order
            </div>
            <div mat-line>
              <div *ngIf="ticket.purchaseOrder">
                {{ticket.purchaseOrder.purchaseOrderNumber}}
              </div>
              <div *ngIf="!ticket.purchaseOrder" class="text-muted">
                No Associated Purchase Order
              </div>
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="mx-2 mb-1 mt-2">
          <mat-checkbox formControlName="woNumber">
            <div mat-line class="small font-weight-bold">
              Work Order
            </div>
            <div mat-line>
              <div *ngIf="ticket.workOrder">
                {{ticket.workOrder.workOrderNumber}}
              </div>
              <div *ngIf="!ticket.workOrder" class="text-muted">
                No Associated Work Order
              </div>
            </div>
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
      </div>
      <mat-card-actions>
        <button mat-button matStepperNext [disabled]="!checklistForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
  <!-- <mat-step label="Fill in inventory info" [stepControl]="inventoryInfoForm">
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-title>Check-in details</mat-card-title>
      <mat-card-content>
        <div [formGroup]="inventoryInfoForm" class="w-100">
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Lot Number</mat-label>
                <input matInput type="text" placeholder="Lot Number" formControlName="lotNumber" autocomplete="off" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext [disabled]="!inventoryInfoForm.valid">Next</button>
      </mat-card-actions>
    </mat-card>
  </mat-step> -->
  <mat-step label="Pick from inventory" [stepControl]="locationControl">
    <div class="row">
      <div class="col-6">
        <inventory-tree-picker
          #inventoryTreePicker
          label="Location" name="location"
          [building]="building"
          [takingItem]="ticket.inventoryItem"
          [takingWorkOrderId]="ticket.workOrderId"
          [takingRMATicketId]="ticket.rmaTicketId"
          [(ngModel)]="locationData" (ngModelChange)="locationControl.updateValueAndValidity()" [max]="ticket.quantityRequired">
        </inventory-tree-picker>
      </div>
      <div class="col-6 d-flex flex-column">
        <div style="height: 63px;
        padding-top: 4px;
        margin-bottom: 26px;
        padding-left: 24px;
        " class="d-flex align-items-center"
        >
          <mat-checkbox class="mr-2 location-done-checkbox" [checked]="locationControl.valid"></mat-checkbox>
          <span><b>{{ totalLocationsPicked }}</b> out of <b>{{ticket.quantityRequired}}</b> items selected</span>
        </div>
        <div class="border rounded h-0 flex-grow-1">
          <mat-list>
            <ng-container *ngFor="let loc of locations; last as last">
              <mat-list-item>
                <mat-icon class="fa-rotate-90 mr-2" mat-list-icon>login</mat-icon>
                <div class="d-flex align-items-center w-100">
                  <div style="max-width: 28%;">
                    <div mat-line>
                      <b>{{loc.amt}}</b> units
                    </div>
                    <div class="small">
                      <div *ngIf="loc.loc.workOrder" class="mr-1 badge badge-sm badge-info small">
                        WO#: {{ loc.loc.workOrder.workOrderNumber }}
                      </div>
                      <div *ngIf="loc.loc.purchaseOrder" class="mr-1 badge badge-sm badge-success small">
                        {{ loc.loc.purchaseOrder.purchaseOrderNumber }}
                      </div>
                      <div *ngIf="loc.loc.rmaTicket" class="mr-1 badge badge-sm badge-danger small">
                        {{ loc.loc.rmaTicket.rmaNumber }}
                      </div>
                    </div>
                  </div>
                  <div class="ml-1">
                    <div mat-line>
                      from
                      <span class="badge badge-primary">
                        <ng-container *ngFor="let crumb of loc.breadcrumbs; last as last">
                          {{ crumb }}{{ !last ? ', ' : '' }}
                        </ng-container>
                      </span>
                    </div>
                  </div>
                  <mat-icon class="ml-auto mr-1 text-danger" *ngIf="getWarnings(loc.loc).length > 0">warning</mat-icon>
                  <div class="text-danger small mr-2" *ngIf="getWarnings(loc.loc).length > 0">
                    <div *ngIf="getWarnings(loc.loc).includes('workOrder')">Marked for other WO.</div>
                    <div *ngIf="getWarnings(loc.loc).includes('rma')">RMA # should match.</div>
                  </div>
                  <button mat-stroked-button class="ml-auto" (click)="clearLocation(loc.loc)">
                    <mat-icon matPrefix>close</mat-icon>
                    Clear
                  </button>
                </div>
              </mat-list-item>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
          </mat-list>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext [disabled]="!locationControl.valid">Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step label="Double-check, print label and finalize">
    <mat-card class="border rounded mat-elevation-z0 w-50">
      <mat-card-title>Checking out ticket <b>{{getTicketNumber(ticket)}}</b></mat-card-title>
      <mat-card-content>
        <p>
          Sending <b>{{ ticket.quantityRequired }}</b> units of <item-type-chip [iconOnly]="true" [item]="ticket.inventoryItem" class="mr-1"></item-type-chip><b>{{ ticket.inventoryItem.name }}</b>
          to <b>{{sendingTo}}</b>
        </p>
        <mat-divider></mat-divider>
        <!-- <div class="font-weight-bold text-muted mt-4 mb-2">Inventory information</div>
        <div class="row mb-2">
          <div class="col-6">
            <label class="font-weight-bold small">Purchase Order</label>
            <div>
              <div *ngIf="ticket.purchaseOrder">
                {{ticket.purchaseOrder.purchaseOrderNumber}}
              </div>
              <div *ngIf="!ticket.purchaseOrder" class="text-muted">
                --
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="font-weight-bold small">Work Order</label>
            <div>
              <div *ngIf="ticket.workOrder">
                {{ticket.workOrder.workOrderNumber}}
              </div>
              <div *ngIf="!ticket.workOrder" class="text-muted">
                --
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="font-weight-bold small">Lot number</label>
            <div>
              {{ inventoryInfoForm.value['lotNumber'] }}
            </div>
          </div>
        </div>
        <mat-divider></mat-divider> -->
        <div class="font-weight-bold text-muted mt-4 mb-2">Inventory sources</div>
        <ul class="pl-3">
          <li *ngFor="let loc of locations">
            <b>{{loc.amt}}</b> units from
            <span class="font-weight-bold">
              <ng-container *ngFor="let crumb of loc.breadcrumbs; last as last">
                {{ crumb }}{{ !last ? ', ' : '' }}
              </ng-container>
            </span>
          </li>
        </ul>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions class="mb-0">
        <!-- TODO -->
        <button mat-stroked-button color="secondary" (click)="printLabel()" [disabled]="printed">
          <mat-icon matPrefix>print</mat-icon>
          Print Shipping Label
        </button>
      </mat-card-actions>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button mat-flat-button color="primary" [disabled]="!printed" (click)="finalizeAndCheckOut()">
          <mat-icon matPrefix>task_alt</mat-icon>
          Finalize check-in and close ticket
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-stepper>