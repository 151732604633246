import { Component, Output, EventEmitter } from '@angular/core';
import { Paint } from '../../../order/resources/paint';
import { PaintService } from '../../../order/services/paint.service';

@Component({
  selector: 'paint-list',
  templateUrl: './paint-list.component.html',
  styleUrls: ['./paint-list.component.less']
})
export class PaintListComponent {
  public paintList: Paint[] = null;
  @Output() selected: EventEmitter<Paint> = new EventEmitter<Paint>();

  constructor(private paintSvc: PaintService) { }

  private loadData(): void {
    this.paintSvc.search('').subscribe(result => {
      this.paintList = result.results;
    });
  }

  public select(paint: Paint): void {
    this.selected.emit(paint);
  }

  public getSheen(paint: Paint): string {
    return Paint.PaintSheen[paint.sheen];
  }

  public getSize(paint: Paint): string {
    return Paint.PaintVolumes[paint.purchaseVolume];
  }

  ngOnInit(): void {
    this.loadData();
    this.paintSvc.updated.asObservable().subscribe(_ => this.loadData());
  }
}
