import { Observable, of } from 'rxjs';
import { MessageService } from '../services/message.service';
import { MessageType, Message } from '../resources/message'
import { ErrorHandlerService } from '../services/errorHandler.service'
import * as Sentry from '@sentry/browser';

export abstract class httpService {
  protected serviceName: string = "HTTP";

  constructor(private errHndlr: ErrorHandlerService, private msgs: MessageService) {
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.errHndlr.handle(error);

      var message: Message = this.msgs.add(`${this.serviceName} Service: ${operation} failed: ${error.message}`, MessageType.ERROR);
      message.messagePayload = error;

      try {
        Sentry.captureException(error, {
          data: {
            operation,
            message
          }
        })
      } catch (_) {}

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
