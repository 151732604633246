<loading *ngIf="loading" content="Loading..."></loading>

<div style="min-height: 85vh;" class="p-3">
  <div class="row" *ngIf="record">
    <div class="col-5">
      <div class="row">
        <div class="col-12">
          <mat-card-title>Ticket Details</mat-card-title>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Customer</mat-label>
            <input label="Customer" disabled matInput
              [value]="record.machineAssignment.workOrder.order?.customer?.businessName" type="text">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Work Order Number</mat-label>
            <input disabled matInput [value]="record.machineAssignment.workOrder.workOrderNumber" type="text">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Part Number</mat-label>
            <input class="font-weight-bold" disabled matInput
              [value]="record.machineAssignment.workOrder.product.partNumber + 'Rev. ' + record.machineAssignment.workOrder.product.revision"
              type="text">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Amount to Inspect</mat-label>
            <input disabled matInput [value]="inspectionQuantity" type="text">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100 chip-holder" appearance="outline">
            <mat-label>Inspection Type</mat-label>
            <input disabled matInput [value]="' '" type="text">
            <mat-chip class="text-white" [ngClass]="{
                    'bg-first-part': record.inspectionType == 0 && !record.requiresReport,
                    'bg-fair': record.inspectionType == 0 && record.requiresReport,
                    'bg-lot-inspection': record.inspectionType == 1
                    }">
              <i ngPrefix class="mr-1 fas" [ngClass]="{
                       'fa-box': record.inspectionType == 0 && !record.requiresReport,
                       'fa-box-archive': record.inspectionType == 0 && record.requiresReport,
                       'fa-boxes': record.inspectionType == 1
                       }">
              </i>
              {{ getInspectionTypeText(record) }}
            </mat-chip>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100 chip-holder" appearance="outline">
            <mat-label>Inspection Method</mat-label>
            <input disabled matInput [value]="' '" type="text">
            <mat-chip class="text-white" [ngClass]="{
                    'bg-cmm': record.isCMM,
                    'bg-non-cmm': !record.isCMM
                    }">
              <i ngPrefix class="mr-1 fas" [ngClass]="{
                       'fa-terminal': record.isCMM,
                       'fa-user-check': !record.isCMM
                       }">
              </i>
              {{ getInspectionMethodText(record) }}
            </mat-chip>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-7">
      <p class="text-center text-muted font-italic"
        *ngIf="record.machineAssignment.workOrder.product?.documents?.length==0">-- No Documents --</p>
      <file-display-grid [files]="record.machineAssignment.workOrder.product.documents" [editable]="false">
      </file-display-grid>
    </div>
    <div class="col-12 mt-2">
      <div class="border rounded">
        <mat-tab-group>
          <mat-tab label="Inspection">
            <div class="p-3">
              <div class="row p-0">
                <div class="col-4">
                  <div class="text-center p-3">
                    <b>Parts Inspected:</b> {{ inspectedCount }} / {{ inspectionQuantity }}
                  </div>
                  <div class="d-flex justify-content-end" *ngIf="estimatedRunTimeSeconds">
                    <div [ngClass]="{
                        'text-muted': !isOverMetric,
                        'text-danger': isOverMetric
                    }">
                      <div style="font-size: 0.75em; margin-bottom: -12px;" class="text-uppercase font-weight-bold">Current Run Time</div>
                      <div>
                        <div style="font-size: 2em" class="font-weight-bold text-right">{{ runTimeString }}</div>
                      </div>
                    </div>
                    <div class="ml-2" [ngClass]="{
                      'text-muted': (fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds,
                      'text-success': (fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds
                    }">
                      <div style="font-size: 0.75em; margin-bottom: -12px;" class="text-uppercase font-weight-bold">{{
                        fastestRunTimeSeconds !== null && (fastestRunTimeSeconds < estimatedRunTimeSeconds) ? 'Fastest Run Time' : 'Estimated Run Time'
                      }}</div>
                      <div>
                        <div style="font-size: 2em" class="font-weight-bold">{{ runTimeMetricString }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 position-relative mb-2" style="height: 24px;" *ngIf="estimatedRunTimeSeconds">
                    <!-- Progress bar base -->
                    <div class="d-inline-block h-100 w-100 border" [ngClass]="{
                      'border-dark': !isOverMetric && ((fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds),
                      'border-success': !isOverMetric && ((fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds)
                    }">&nbsp;</div>
                    <!-- Time spent bar -->
                    <div class="w-100 position-absolute h-100 timer-bar border" style="top: 0; left: 0; box-sizing: content-box;"
                      [ngStyle]="{
                        'clip-path': 'inset(0 ' + (100 - percentagePartTimeSpent) + '% 0 0)'
                      }"
                      [ngClass]="{
                        'bg-dark': !isOverMetric && ((fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds),
                        'border-dark': !isOverMetric && ((fastestRunTimeSeconds === null) || fastestRunTimeSeconds >= estimatedRunTimeSeconds),
                        'bg-success': !isOverMetric && ((fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds),
                        'border-success': !isOverMetric && ((fastestRunTimeSeconds !== null) && fastestRunTimeSeconds < estimatedRunTimeSeconds),
                        'bg-danger': isOverMetric,
                        'border-danger': isOverMetric
                      }"
                    >
                    </div>
                  </div>
                  <div class="form-group text-center op-buttons">
                    <button *ngIf="!complete" [disabled]="hasStartedInspection" class="btn btn-lg btn-block btn-info" (click)="beginInspection()">
                      Begin Inspection
                    </button>
                    <button *ngIf="complete" class="btn btn-lg btn-block btn-success" (click)="submitInspection()">
                      Submit Inspection
                    </button>
                    <button *ngIf="estimatedRunTimeSeconds" class="btn btn-lg btn-block text-white"
                      [ngClass]="{'bg-success': !timerRunning, 'bg-danger': timerRunning}" [disabled]="(timerRunning) || complete || !hasStartedInspection"
                      (click)="startStop()">
                      {{ timerRunning ? 'Stop Timer' : 'Start Timer' }}
                    </button>
                  </div>
                  <div class="d-flex">
                    <button class="btn btn-lg btn-block btn-success text-white mr-1"
                      [disabled]="!timerRunning"
                      (click)="approvePart()">
                      Approve Part
                    </button>
                    <button class="btn btn-lg btn-block btn-danger text-white m-0"
                      [disabled]="!timerRunning"
                      (click)="rejectPart()">
                      Reject Part
                    </button>
                  </div>
                </div>
                <div class="col-4">
                  <div class="border rounded h-100" style="height: 45vh !important; overflow-y: scroll;">
                    <mat-accordion>
                      <mat-expansion-panel *ngFor="let status of record.inspectedItems; index as i">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="font-weight-bold text-muted mr-2" style="order: -1;">
                              #{{ i + 1 }}
                            </div>
                          </mat-panel-title>
                          <mat-panel-description>
                            <div>
                              <div mat-line class="font-weight-bold overline" [class.text-success]="status.approved" [class.text-danger]="!status.approved">
                                {{ status.approved ? 'Approved' : 'Rejected' }}
                              </div>
                              <div mat-line class="small text-muted">
                                <div *ngIf="status.note">Has Note</div>
                              </div>
                            </div>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="mt-2">
                          <div *ngIf="status.note">
                            <div class="font-weight-bold small">Note</div>
                            <div class="white-space-pre">
                              {{ status.note }}
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="col-4">
                  <mat-form-field class="w-100" appearance="fill" *ngIf="record.requiresReport">
                    <mat-label>FAIR Notes</mat-label>
                    <textarea matInput placeholder="Write first article inspection report notes here" rows="2" [(ngModel)]="record.inspectionNotes" style="resize: none;" rows="6"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Operator Verification" *ngIf="record.inspectionType == 0">
            <div class="measurementsArea p-3 overflow-hidden" style="height: 45vh !important; overflow-y: scroll;" ngForm #cmmMeasurementsForm="ngForm">
              <div class="d-flex mb-2">
                <mat-card-title>Operator Verification</mat-card-title>
                <mat-form-field class="ml-auto verify-count-field" appearance="outline" style="font-size: 14px !important">
                  <div matPrefix>Verify every</div>
                  <input matInput type="number" step="1" min="1" class="text-right" [minValidator]="1" [(ngModel)]="operatorVerifyEvery" name="verifyEvery">
                  <div matSuffix> parts</div>
                </mat-form-field>
                <button mat-stroked-button class="ml-2 bg-dark border-dark text-white mat-button-text" (click)="printInspectionTable()" [disabled]="record.bubbleMeasurements.length === 0">
                  <mat-icon matPrefix>print</mat-icon>
                  Print Operator Verification Table
                </button>
                <button mat-flat-button class="ml-2 bg-primary text-white mat-button-text" (click)="addMeasurement()">
                  <mat-icon matPrefix>add</mat-icon>
                  Add Measurement
                </button>
              </div>
              <div *ngIf="record.bubbleMeasurements.length === 0" class="border rounded px-3 py-2 bg-light text-muted small">
                No measurements have been added.
              </div>
              <div *ngFor="let measurement of record.bubbleMeasurements; index as i" class="border rounded px-3 py-2 d-flex justify-content-evenly align-items-center mb-2 position-relative" style="font-size: 13px;">
                <div class="row w-100">
                  <div class="col-2" style="padding: 0 5px;">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Bubble Number</mat-label>
                      <input matInput required type="text" [disabled]="!canEdit" [(ngModel)]="measurement.bubbleNumber" [name]="'measurement-' + i + '-name'">
                    </mat-form-field>
                  </div>
                  <div class="col-6" style="padding: 0 5px;">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Tool</mat-label>
                      <input matInput required type="text" [disabled]="!canEdit" [(ngModel)]="measurement.measurementTool" [name]="'measurement-' + i + '-value'">
                    </mat-form-field>
                  </div>
                  <div class="col-3" style="padding: 0 5px;">
                    <mat-form-field appearance="outline" class="w-100" (click)="measurement.bubbleSnippetDocumentId ? openSnippet(measurement.bubbleSnippetDocumentId) : (openFileInput(fileInput) && fileNameModel.touched = true)">
                      <mat-label>Snippet Screenshot (optional)</mat-label>
                      <input matInput type="text" [value]="measurement.bubbleSnippetDocumentId ? '...' : null" style="pointer-events: none; opacity: 0;"
                        placeholder="Purcahse Order Document" #filenameinput ngModel name="filename" #fileNameModel="ngModel"
                        (focus)="filenameinput.blur()">
                      <div [class.invisible]="!measurement.bubbleSnippetDocumentId" style="    width: 100%;
                      position: absolute;
                      left: 0;
                      top: 0px;">
                        <mat-icon class="text-muted mr-2" style="position: relative; top: 6px;">image</mat-icon>
                        Click to view snippet
                      </div>
                      <button mat-flat-button matSuffix class="bg-primary text-white" *ngIf="!measurement.bubbleSnippetDocumentId"
                        style="position: relative;bottom: 7px;">Browse</button>
                      <button mat-flat-button matSuffix class="bg-danger text-white" *ngIf="!!measurement.bubbleSnippetDocumentId" (click)="clearSnippet($event, measurement)"
                        style="position: relative;bottom: 7px;">Clear</button>
                    </mat-form-field>
                    <input type="file" style="display: none" #fileInput (change)="onFileChange($event, measurement)">
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    <button mat-icon-button (click)="deleteMeasurement(i)" *ngIf="canEdit">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<ng-template #rejectReasoningDialogTemplate let-data>
  <h2 mat-dialog-title>Rejecting Part</h2>
  <mat-dialog-content class="mat-typography">
    <p>Please explain why this part was rejected.</p>
    <p>
      <textarea [(ngModel)]="data.note" class="form-control form-control-sm" #note placeholder="Rejection Note"></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="null">Cancel</button>
    <button class="btn btn-default btn-sm mr-3" [disabled]="false" [mat-dialog-close]="data" (click)="openRedTag(data.note)">Reject</button>
  </mat-dialog-actions>
</ng-template>