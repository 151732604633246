<search-list-new
  #searchList
  matSort
  [defaultSort]="{
    id: 'dueDate',
    start: 'desc'
  }"
  [showManagerToggle]="true"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['assigned', 'department', 'relatedTicketName', 'creator', 'text', 'dueDate', 'status', 'action']"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
  [fieldSearchFields]="[
    { field: 'relatedTicketName', code: 'ticket' },
    { field: 'relatedTicketName', code: 'aqn' },
    { field: 'assigned', code: 'user' },
    { field: 'assigned', code: 'assigned' },
    { field: 'assigned', code: 'assignee' },
    { field: 'creator', code: 'creator' },
    { field: 'department', code: 'department' },
    { field: 'department', code: 'dept' }
  ]"
  [fieldSearchNames]="{
    relatedTicketName: 'Related Ticket',
    assigned: 'Assigned User',
    creator: 'Ticket Creator',
    department: 'Department'
  }"
  (itemSelected)="onSelect($event)"
  localStorageId="microticket-queue"
>
  <ng-container matColumnDef="assigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned" style="flex-basis: 5%">Assignee</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 5%">
      <div class="d-flex align-items-center w-100">
        {{row.assignedUser?.fullName}}
        <mat-chip selected color="accent" class="small font-weight-bold ml-auto mr-2" *ngIf="isCCd(row)">
          <mat-icon svgIcon="account-supervisor" matPrefix></mat-icon>
          CCd
        </mat-chip>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="department">
    <mat-header-cell *matHeaderCellDef mat-sort-header="filterKey">Department</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.filterKey">
        {{row.filterKey}}
      </span>
      <span class="text-muted" *ngIf="!row.filterKey">
        —
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="relatedTicketName">
    <mat-header-cell *matHeaderCellDef mat-sort-header="relatedTicketName">Related Ticket</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.relatedTicketName}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="creator">
    <mat-header-cell *matHeaderCellDef mat-sort-header="creator">Ticket Creator</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span>
        {{row.creator?.fullName}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="text">
    <mat-header-cell *matHeaderCellDef>Text</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span>
        {{row.text}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dueDate" style="flex-basis: 5%">Due Date</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 5%">
      <ng-container *ngIf="row.dueDate">
        <span class="font-weight-medium">
          {{row.dueDate | date: 'MM/dd/yyyy' }}
        </span> 
        <mat-chip class="small ml-2 font-weight-medium" [ngClass]="daysLeftColorClass(row.dueDate)" *ngLet="daysLeft(row.dueDate, true) as dayCount">
          <ng-container *ngIf="dayCount !== 0">
            {{dayCount}} Day(s)
            {{(daysLeft(row.dueDate, false)||0) >= 0 ? 'From Today':'Overdue'}}
          </ng-container>
          <ng-container *ngIf="dayCount === 0">
            Due Today
          </ng-container>
        </mat-chip>
      </ng-container>
      <ng-container *ngIf="!row.dueDate">
        <div class="text-muted">—</div>
      </ng-container>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
        <mat-chip [ngClass]="getStatusColorClass(row.status)" class="button-text mr-2">
            {{ getStatusText(row.status) }}
        </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let row">
        <div class="d-flex align-items-center ml-auto" *ngIf="isAssignedToMe(row)" data-testid="assignedUser-buttons" (click)="$event.stopPropagation()">
            <button mat-flat-button color="accent" class="mat-button-small button-text small" (click)="markInProgress(row)"
                *ngIf="row.status === 0" data-testid="in-progress-button">
                Mark In Progress
            </button>
            <button mat-flat-button color="accent" class="bg-success mat-button-small button-text small" (click)="markComplete(row)"
                *ngIf="row.status === 1" data-testid="completed-button">
                Mark Complete
            </button>
        </div>
    </mat-cell>
  </ng-container>
</search-list-new>
