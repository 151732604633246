<ordersegment-list-new
  [displayedColumns]="['customer', 'quoteNumber', 'customerRfqnumber', 'partHistory', 'hasIncomplete', 'estimateProgress', 'assigned', 'received', 'required', 'estimatedEstimateCompletionDate', 'status']"
  orderNumberTitle="Estimate"
  [showAdd]="false"
  [showManagerToggle]="isManager"
  [isManager]="userIsManager.bind(this)"
  path="/estimating/"
  discriminator="Estimate"
  localStorageId="sales-estimate-list"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
</ordersegment-list-new>