import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderDetailService } from '../order-detail.service';
import { OrderProduct, OrderSegmentProductReviewStatus, OrderSegmentProductType } from '../../../resources/order';
import { Product, ProductQuantity, ProductStandardHistory } from '../../../resources/product';
import { UtilityService } from '../../../../common/services/utility.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, map, mergeMap, scan, startWith, switchMap, take } from 'rxjs/operators';
import { OrderService } from '../../../services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MessageService } from '../../../../common/services/message.service';
import * as XLSX from 'xlsx';
import { FilePartAssignment, OrderDetailProductsUploadComponent } from './order-detail-products-upload/order-detail-products-upload.component';
import { VirtualDocument } from '../../../../common/resources/virtual-document';
import { MessageType } from '../../../../common/resources/message';
import { Task, TaskList, TaskStatus } from '../../../../common/resources/estimatingtask';
import { EstimateProgressService } from '../../../services/estimate-progress.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

const parseMap = {
  'Name': 'partNumber',
  'Part Number': 'partNumber',
  'Part': 'partNumber',
  'PN': 'partNumber',
  'Part #': 'partNumber',
  'Description': 'description',
  'Notes': 'description',
  'Revision': 'revision',
  'Rev': 'revision'
}

const sheetTypes = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet'
]

function tableToJson(tableHtml: string): { [key: string]: string }[] {

  const div = document.createElement('div');
  div.innerHTML = tableHtml.trim();
  const table = div.querySelector('table') as HTMLTableElement;

  var data = [];

  // first row needs to be headers
  var headers = [];
  for (var i = 0; i < table.rows[0].cells.length; i++) {
    headers[i] = table.rows[0].cells[i].innerText.trim().replace(/\n/gi, ' ');
  }

  // go through cells
  for (var i = 1; i < table.rows.length; i++) {

    var tableRow = table.rows[i];
    var rowData = {};

    for (var j = 0; j < tableRow.cells.length; j++) {

      rowData[headers[j]] = tableRow.cells[j].innerText;

    }

    data.push(rowData);
  }

  return data;
}


type ClassifiedProduct = { type: 'REPEAT' | 'NEWREV', product: Product };
type ParsedSpreadsheetProduct = { 'partNumber': string, 'revision': string, quantities: number[] }


type OrderProductMod = OrderProduct & {
  unitPriceObservable: Observable<number>,
  leadTimeObservable: Observable<number>
}

@Component({
  selector: 'order-detail-products',
  templateUrl: './order-detail-products.component.html',
  styleUrls: ['./order-detail-products.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailProductsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService,
    public orderService: OrderService,
    private dialog: MatDialog,
    private utilityService: UtilityService,
    private messageService: MessageService,
    private progressService: EstimateProgressService
  ) { }

  public get record() { return this.service.order }
  public get editing() { return this.service.editing }

  public rawData$: Observable<OrderProductMod[]>;
  public data$: Observable<OrderProductMod[]>;


  private modifyProd(op: OrderProduct, fullData: Product = null): OrderProductMod {
    return {
      ...op,
      product: fullData ?? op.product,
      unitPriceObservable: this.service.getProductUnitPriceObservable(op.product),
      leadTimeObservable: this.service.getProductLeadTimeObservable(op.productProductId)
    }
  }

  private generateProductsObservable(): Observable<OrderProductMod[]> {
    const inner = this.service.productsModified.pipe(startWith(this.record.products))
      .pipe(
        switchMap((state) => {
          const ids = state.map(p => p.productProductId);
          const mapped = state.map(x => this.modifyProd(x));
          return this.service.productEdited.pipe(
            filter((input) => input && ids.includes(input.productId)),
            scan((curState, input) => {
              return curState.map(op => {
                const fullData = op.productProductId === input.productId ? input : null;
                return this.modifyProd(op, fullData);  
              })
            }, mapped),
            startWith(mapped)
          )
        })
      );
    this.rawData$ = combineLatest([inner, this.searchSubject])
        .pipe(map(([products, search]) => {
          if (search.trim()) products = products.filter(p => `${p.product.partNumber} Rev. ${p.product.revision}`.toLowerCase().includes(search.trim().toLowerCase()))
          return products;
        }));
    return combineLatest([this.rawData$, this.pageSubject.pipe(startWith({ length: 0, pageIndex: this.service.partListPageIndex, pageSize: this.PAGE_SIZE }))])
        .pipe(map(([products, pageEvent]) => {
          const { pageSize, pageIndex } = pageEvent;
          const start = pageIndex * pageSize;
          const end = start + pageSize;
          return products.slice(start, end);
        }));
  }

  public get displayedColumns() 
  {
    let displayed = [];
    if (this.editing) displayed.push('dragHandle');
    if (this.service.canApproveRFQ()) displayed.push('review');
    if (this.record.discriminator === 'Estimate' || this.record.discriminator === 'RMAEstimate' || this.record.discriminator === 'Quote') displayed.push('reviewEstimate');
    displayed = [...displayed, 'partNumber', 'rev', 'quantities', 'unitPrice', 'leadTime', 'cloneData', 'quoteHistory'];
    if (this.progressService?.shouldShowIndicators()) displayed.push('microtasks');
    displayed.push('files');
    if (this.editing) displayed.push('filesUpload');
    displayed.push('open');
    if (this.editing) displayed.push('delete');
    return displayed;
  }

  public drop(event: CdkDragDrop<OrderProduct[]>) {
    moveItemInArray(this.record.products, event.previousIndex, event.currentIndex);
    this.record.products.forEach((p, i) => p.sort = i);
    this.service.orderEdited.next(this.record);
  }

  @ViewChild('partSearcher', { static: false }) set partSearcherSetter(content: ElementRef<HTMLInputElement>) {
    if (content) {
      content.nativeElement.focus();
    }
  }
  @ViewChild('container') container: ElementRef<HTMLDivElement>; 
  @ViewChild('paginator') paginator: MatPaginator;
  public addingPart = false;
  public addProduct() {
    this.addingPart = true;
    this.searchSubject.next('');
    this.paginator.lastPage();
  }

  public openProduct(product: OrderProduct) {
    this.service.selectedProductId = product.productProductId;
  }

  public getProduct(id: string): Product {
    return this.service.allProductsFlat().find(p => p.productId === id);
  }

  public searchControl = new FormControl<string>('');
  public productResults: Observable<ClassifiedProduct[]>;
  ngOnInit(): void {
    this.data$ = this.generateProductsObservable();
    const searchedProducts = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      mergeMap((searchText) => this.orderService.searchProducts(searchText))
    );
    this.productResults = combineLatest([searchedProducts, this.searchControl.valueChanges]).pipe(
      // Clientside filtering
      map(([products, string]) => {
        if (typeof string !== 'string') return products;
        return products.filter(p => `${p.partNumber} Rev. ${p.productId}`.toLowerCase().includes(string.toLowerCase()))
      }),
      // Convert to format
      map(products => {
        let aggregated = products.reduce((acc, prod) => {
          if (!acc[prod.partNumber]) acc[prod.partNumber] = []
          acc[prod.partNumber].push({ type: 'REPEAT', product: prod })
          return acc;
        }, {} as { [key: string]: ClassifiedProduct[] })
        let preFlattened = Object.values(aggregated);
        // Add new rev option
        return preFlattened.flatMap(sa => [...sa, { type: 'NEWREV', product: sa[0].product }]);
      })
    );

    // combineLatest([this.searchSubject, this.pageSubject]).subscribe(([search, page]) => {
    //   if (page.pageIndex != 0 || !!search.trim()) this.addingPart = false;
    // })
  }

  public displayWith(item: ClassifiedProduct | string | null) {
    if (!item) return '';
    else if (typeof item === 'string') return item;
    else return `${item.product.partNumber} Rev. ${item.product.revision}`
  }
  private newBlankProduct(partNumber: string): Product {
    const prod = Product.newEmptyProduct();
    prod.partNumber = partNumber;
    prod.productId = UtilityService.newGuid();
    prod.topParentAssemblyId = prod.productId;
    prod.workflowWorkflowId = prod.productId;
    this.service.addNewId(prod.productId);
    this.service.onProductsChange([
      ...this.record.products.map(p => p.product),
      prod
    ]);
    this.progressService.syncOrCreateTopLevelProductTask(prod);
    this.service.notifyEdited(prod);
    return prod;
  }

  private notifyNewProduct(product: Product) {
    const notify = (p: Product) => {
      // Create and cache the product observable, to make sure productEdited is hooked up to emit properly, and actually subscribe and unsubscribe to force the code to actually run
      const _ = this.service.getProductObservable(p.productId).subscribe().unsubscribe();
      // Then, notify the parent as edited
      this.service.notifyEdited(p);
      // Then, run this for all child assemblies. Once these are done, the combineLatest(subassemblies) call in the parents will resolve and emit
      p.childAssemblies.forEach(ca => notify(ca));
    }

    notify(product);
  }

  private cloneProduct(product: Product, newRev: string = null) {
    // Adding the raw product from search temporarily to display it
    const cloningId = UtilityService.newGuid();
    const cloningProd: Product = JSON.parse(JSON.stringify(product));
    cloningProd.productId = cloningId;
    if (newRev) cloningProd.revision = newRev;
    this.service.onProductsChange([
      ...this.record.products.map(p => p.product),
      cloningProd
    ]);
    this.loadingMap[cloningId] = true;
    this.orderService.getProductWithTreeClone(product.productId).toPromise().then(prod => {
      if (newRev) prod.revision = newRev;
      prod.quantitiesMap = [];
      prod.parentAssemblyId = null;
      this.service.addClonedProductIds(prod);
      // In case the user autoassigned any documents while the product was loading, transfer them over
      prod.documents = [...prod.documents, ...cloningProd.documents.map(d => ({
        ...d,
        productProductId: prod.productId
      }))]
      const index = this.record.products.findIndex(p => p.productProductId === cloningId);
      const mapped = this.record.products.map(p => p.product);
      mapped.splice(index, 1, prod);
      this.service.onProductsChange(mapped);
      this.progressService.syncOrCreateTopLevelProductTask(prod);
      const op = this.record.products.find(p => p.productProductId === prod.productId);
      op.type = newRev ? OrderSegmentProductType.CLONED_NEWREV : OrderSegmentProductType.CLONED;
      op.history = prod.numberProductStandard?.history ?? ProductStandardHistory.NEW;
      this.loadingMap[cloningId] = undefined;

      this.notifyNewProduct(op.product);
    });
  }

  public duplicateProduct(originalProduct: Product) {
    let dupeProd: Product = JSON.parse(JSON.stringify(originalProduct));
    let oldIdsMap: { [key: string]: string } = {};
    dupeProd = Product.generateNewIdsRecursive([dupeProd], null, null, oldIdsMap)[0];
    this.service.addClonedProductIds(dupeProd);
    this.service.mapMaterialSearchStatus(oldIdsMap);
    this.service.onProductsChange([
      ...this.record.products.map(p => p.product),
      dupeProd
    ]);
    this.progressService.syncOrCreateTopLevelProductTask(dupeProd);
    const newOP = this.record.products.find(p => p.productProductId === dupeProd.productId);
    const oldOP = this.record.products.find(p => p.productProductId === originalProduct.productId);
    newOP.type = oldOP.type;
    newOP.history = oldOP.history;
    newOP.reviewStatus = OrderSegmentProductReviewStatus.NotReviewed;
    this.notifyNewProduct(dupeProd);
  }

  @ViewChild('newRevDialogTemplate') newRevDialogTemplate: TemplateRef<any>;
  public async partDropdownSelected(value: ClassifiedProduct | string) {
    if (typeof value === 'string') {
      const prod = this.newBlankProduct(value);
      const op = this.record.products.find(p => p.productProductId === prod.productId);
      op.type = OrderSegmentProductType.NEW;
      op.history = ProductStandardHistory.NEW;
    } else if (value.type === 'REPEAT') {
      this.cloneProduct(value.product);
    } else if (value.type === 'NEWREV') {
      const data = {
        availableProducts: null,
        newRev: '',
        productToClone: null
      }
      this.orderService.searchProducts(value.product.partNumber).subscribe(s => {
        data.availableProducts = s;
        if (data.availableProducts.length === 1) data.productToClone = data.availableProducts[0]
      });
      const dialogRef = this.dialog.open(this.newRevDialogTemplate, {
        disableClose: true,
        maxWidth: '30vw',
        data
      });
      dialogRef.afterClosed().subscribe((result: { newRev: string, productToClone: Product | null }) => {
        if (!result) return;
        if (result.productToClone) {
          this.cloneProduct(result.productToClone, result.newRev);
        } else {
          const prodRef = this.newBlankProduct(value.product.partNumber);
          prodRef.revision = result.newRev;
        }
      })
    }
    this.addingPart = false;
    this.searchControl.setValue('');
    setTimeout(() => {
      this.container.nativeElement && this.container.nativeElement.focus();
    })
  }

  public loadingMap: { [key: string]: boolean } = {};
  public isLoading(row: OrderProduct) {
    return this.loadingMap[row.productProductId] === true;
  }

  public async deleteProduct(row: OrderProduct) {
    const r = await this.utilityService.showConfirmationPromise('Delete part?', `<p>Really delete part <b>${row.product.partNumber} Rev. ${row.product.revision}</b>?</p><p class="font-weight-bold text-danger">This cannot be undone and may result in severe data loss.</p>`)
    if (!r) return;
    this.progressService.deleteTopLevelProductTask(row.product);
    this.service.onProductsChange(
      this.record.products.filter(p => p.productProductId !== row.productProductId).map(p => p.product)
    );
  }

  private parseKey(parseString: string, target: string) {
    const sanitized = target
        .replace(/[^a-zA-Z0-9\s]+/gi, '')
        .replace(/\s+/gi, ' ')
        .trim();
      const keywords = parseString.split(' ');
        // check that all keywords are in the sanitized table header name
        if (keywords.every(kw => sanitized.toUpperCase().includes(kw.toUpperCase())))
          return true;
      return false;
  }

  public async parseTable(items: { [key: string]: string }[]): Promise<ParsedSpreadsheetProduct[]> {
    let unmappedKeys = [];
    return items.flat().map(r => {
      let out = { partNumber: null, revision: null, description: null };
      let qties: number[] = [];
      for (const objectKey in r) {
        if (Object.prototype.hasOwnProperty.call(r, objectKey)) {
          if (/(qty|quantity)/gi.test(objectKey)) {
            const parsed = parseInt(r[objectKey]);
            if (!isNaN(parsed)) qties.push(parsed);
            continue;
          }
          let couldParse = false;
          for (const parseString in parseMap) {
            if (this.parseKey(parseString, objectKey)) {
              out[parseMap[parseString]] = r[objectKey];
              couldParse = true;
            }
          }
          if (!couldParse) unmappedKeys.push(objectKey);
        }
      }
      return {
        partNumber: out.partNumber,
        revision: out.revision || '-',
        description: out.description || '',
        quantities: qties,
      }
    });
  }

  private mapQuantities(qties: number[]): ProductQuantity[] {
    return qties.map(q => ({
      showOnQuote: true,
      markup: 18,
      value: q
    }))
  }

  public addParsedProducts(parsedProducts: ParsedSpreadsheetProduct[]) {
    let tempProducts = [];
    let promises: Promise<void>[] = [];
    let copiedCount = 0;
    let blankCount = 0;
    for (const parsedProduct of parsedProducts) {
      const { partNumber, revision, quantities } = parsedProduct;
      // Create blank product to show loading
      const tempId = UtilityService.newGuid();
      const tempProduct = Product.newEmptyProduct();
      tempProduct.productId = tempId;
      tempProduct.topParentAssemblyId = tempId;
      tempProduct.productId = tempId;
      tempProduct.partNumber = partNumber;
      tempProduct.revision = revision;
      tempProduct.quantitiesMap = this.mapQuantities(quantities);
      this.loadingMap[tempId] = true;
      tempProducts.push(tempProduct);
      let p = this.orderService.matchProductAndClone(partNumber, revision).toPromise().then(result => {
        this.loadingMap[tempId] = undefined;
        if (!result) {
          // Just let the temp product become the real product in this case
          this.service.addNewId(tempId);
          const op = this.record.products.find(p => p.productProductId === tempProduct.productId);
          this.progressService.syncOrCreateTopLevelProductTask(tempProduct);
          op.type = OrderSegmentProductType.NEW;
          op.history = ProductStandardHistory.NEW;
          blankCount += 1;
        } else {
          result.partNumber = partNumber;
          result.revision = revision;
          result.quantitiesMap = this.mapQuantities(quantities);
          this.service.addClonedProductIds(result);
          // In case the user autoassigned any documents while the product was loading, transfer them over
          result.documents = [...result.documents, ...tempProduct.documents.map(d => ({
            ...d,
            productProductId: result.productId
          }))]
          const index = this.record.products.findIndex(p => p.productProductId === tempId);
          const mapped = this.record.products.map(p => p.product);
          mapped.splice(index, 1, result);
          this.service.onProductsChange(mapped);
          const op = this.record.products.find(p => p.productProductId === result.productId);
          op.type = result.revision === revision ? OrderSegmentProductType.CLONED : OrderSegmentProductType.CLONED_NEWREV;
          op.history = result.numberProductStandard?.history ?? ProductStandardHistory.NEW;
          this.service.notifyEdited(op.product);
          copiedCount += 1;
        }
      });
      promises.push(p);
    }
    this.service.onProductsChange([...this.record.products.map(p => p.product), ...tempProducts]);
    Promise.all(promises).then(() => {
      this.messageService.add(`${parsedProducts.length} items loaded. ${copiedCount} items found and cloned, ${blankCount} items created as new blank parts.`, MessageType.GENERAL, true)
    })
  }

  public async parseClipboard(): Promise<{ [key: string]: string }[]> {
    // @ts-ignore
    const items = await navigator.clipboard.read();
    if (!items[0]) return;
    const item = items[0];
    if (!item) return;
    if (!item.types.includes('text/html')) return;
    const blob = await item.getType('text/html')
    const tableString = await blob.text();
    return tableToJson(tableString);
  }

  public async onPaste() {
    if (!this.editing) return;
    try {
      const rawData = await this.parseClipboard();
      const parsed = await this.parseTable(rawData);
      this.addParsedProducts(parsed);
    } catch (e) {
      console.error('Failed to parse clipboard:', e);
    }
  }

  private async parseFile(file: File): Promise<{ [key: string]: string }[]> {
    // @ts-ignore
    const buffer = await file.arrayBuffer();
    const parsed = XLSX.read(buffer);
    return Object.values(parsed.Sheets).map(s => XLSX.utils.sheet_to_json(s, { defval: null })) as any;
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  
  public async onFileChange() {
    const files = this.fileInput && this.fileInput.nativeElement.files;
    console.log(files)
    if (files.length > 0) {
      const file = files[0];
      try {
        const data = await this.parseFile(file);
        const parsed = await this.parseTable(data);
        this.addParsedProducts(parsed);
      } catch (e) {
        this.messageService.add(`Could not parse file`, 1, true);
        console.error('Failed to parse file:', e);
      }
    }
  }

  public clipboardIsValid = false;
  public checkClipboard() {
    this.parseClipboard().then(() => this.clipboardIsValid = true).catch(() => this.clipboardIsValid = false);
  }


  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  public menuPosition: { x: number; y: number } = { x: 0, y: 0 }
  public menuItem: OrderProduct | null = null;
  public openMenu(event: MouseEvent, item: OrderProduct | null) {
    this.checkClipboard();
    if (item) {
      this.menuItem = item;
    } else {
      this.menuItem = null;
    }
    this.menuPosition = {
      x: event.clientX ?? 0,
      y: event.clientY ?? 0
    }
    if (!this.menuItem) return;
    event.preventDefault();
    this.menuTrigger.openMenu();
  }

  public ngAfterViewInit() {
    this.paginator.pageIndex = this.service.partListPageIndex;
  }

  public ngOnDestroy(): void {
  }

  public dragging = false;
  private enterTarget: EventTarget | null;
  public onDragEnter(event: DragEvent) {
    if (!this.editing) return;
    this.enterTarget = event.target;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = true;
  }

  public onDragLeave(event: DragEvent) {
    if (!this.editing) return;
    if (this.enterTarget === event.target){
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
    }
  }

  private assignmentToDocument(assign: FilePartAssignment): VirtualDocument {
    return {
      documentId: UtilityService.newGuid(),
      name: assign.filename,
      mimeType: assign.file.type,
      tags: assign.tags,
      imageAsDataUrl: null,
    }
  }

  private async assignDocuments(assignments: FilePartAssignment[]) {
    const uploaded = await this.service.batchUploadDocuments(assignments.map(a => a.file));
    let i = 0;
    for (const assign of assignments) {
      const doc = uploaded[i];
      const docId = doc.documentId;
      if (assign.assignedPart === 'NONE') {
        this.record.documents = [...this.record.documents, {
          orderSegmentOrderSegmentId: this.record.orderSegmentId,
          documentDocumentId: docId,
          document: doc
        }];
      } else {
        const part = this.record.products.find(p => p.productProductId === (assign.assignedPart as OrderProduct).productProductId)?.product;
        part.documents = [...part.documents, {
          productProductId: part.productId,
          documentDocumentId: docId,
          document: doc
        }];
      }
      i++;
    }
  }

  public async onDrop(event: DragEvent) {
    if (!this.editing) return;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = false;
    const files = Array.from(event.dataTransfer.files ?? []);
    if (files.length === 0) return;
    else if (files.length === 1 && sheetTypes.includes(files[0].type)) {
      const file = files[0];
      try {
        const data = await this.parseFile(file);
        const parsed = await this.parseTable(data);
        this.addParsedProducts(parsed);
      } catch (e) {
        this.messageService.add(`Could not parse file`, 1, true);
        console.error('Failed to parse file:', e);
      }
    } else {
      const dialogRef = this.dialog.open<any, { files: File[], products: OrderProduct[] }, FilePartAssignment[] | null>(OrderDetailProductsUploadComponent, {
        data: {
          files: files,
          products: this.record.products
        },
        minWidth: '50vw',
        minHeight: '70vh',
        disableClose: true
      });
      const result = await dialogRef.afterClosed().toPromise();
      if (!result) return;
      await this.assignDocuments(result);
      this.messageService.add(`${result.length} files assigned.`, MessageType.GENERAL, true)
    }
  }

  public get canGetBinder() {
    const allDocs = this.record.products.flatMap(p => p.product.documents).filter(d => d.document.tags.includes('Drawing'));
    return allDocs.length > 0;
  }

  public getBinder() {
    window.open(`/api/orderSegment/getBinder/${this.record.orderSegmentId}`, '_blank');
  }

  public getCloneDataChipClass(product: OrderProduct) {
    switch (product.type) {
      case OrderSegmentProductType.NEW:
        return 'chip-clone-blank'
      case OrderSegmentProductType.CLONED_NEWREV:
        return 'chip-clone-newrev'
      case OrderSegmentProductType.CLONED:
        return 'chip-clone-repeat'
      default:
        return '';
    }
  }
  public getCloneDataText(product: OrderProduct) {
    switch (product.type) {
      case OrderSegmentProductType.NEW:
        return 'New Part'
      case OrderSegmentProductType.CLONED_NEWREV:
        return 'Cloned – New Rev'
      case OrderSegmentProductType.CLONED:
        return 'Cloned'
      default:
        return '';
    }
  }

  
  @ViewChild('productFileInput', { static: true }) productFileInput: ElementRef<HTMLInputElement>;
  private addingFilesProduct: OrderProduct = null;
  public onClickFiles(op: OrderProduct) {
    this.service.selectedProductId = op.productProductId;
    this.service.productTab = 1;
  }
  public onClickUpload(op: OrderProduct) {
    this.addingFilesProduct = op;
    this.productFileInput.nativeElement.click();
  }

  public async onProductFileChange() {
    const files = this.productFileInput && this.productFileInput.nativeElement.files;
    const part = this.addingFilesProduct?.product;
    if (!part) return;
    if (files.length > 0) {
      const vdocs = await this.service.batchUploadDocuments(Array.from(files));
      part.documents = [...part.documents, ...vdocs.map(vd => ({
        productProductId: part.productId,
        documentDocumentId: vd.documentId,
        document: vd
      }))];
      this.messageService.add(`${files.length} files assigned to ${part.partNumber} Rev. ${part.revision}.`, MessageType.GENERAL, true)
    }
    this.productFileInput.nativeElement.value = '';
    this.addingFilesProduct = null;
  }

  public getQuoteHistoryText(h: ProductStandardHistory) {
    switch (h) {
      case ProductStandardHistory.NEW:
        return 'New Part';
      case ProductStandardHistory.RFQ:
        return 'Previously in RFQ';
      case ProductStandardHistory.ESTIMATED:
        return 'Previously Estimated';
      case ProductStandardHistory.QUOTED:
        return 'Previously Quoted';
      case ProductStandardHistory.PURCHASED:
        return 'Previously Purchased';
      case ProductStandardHistory.GOLD:
        return 'Gold Part';
    }
  }

  public getQuoteHistoryChipClass(h: ProductStandardHistory) {
    switch (h) {
      case ProductStandardHistory.NEW:
        return 'history-chip-new';
      case ProductStandardHistory.RFQ:
        return 'history-chip-new';
      case ProductStandardHistory.ESTIMATED:
        return 'history-chip-estimated';
      case ProductStandardHistory.QUOTED:
        return 'history-chip-quoted';
      case ProductStandardHistory.PURCHASED:
        return 'history-chip-purchased';
      case ProductStandardHistory.GOLD:
        return 'history-chip-gold';
    }
  }

  @ViewChild('reviewProductDialogTemplate') reviewProductDialogTemplate: TemplateRef<any>;
  public async reviewProduct(op: OrderProduct) {
    const diagRef = this.dialog.open(this.reviewProductDialogTemplate, {
      disableClose: true,
      width: '450px',
      data: {
        op,
        reviewStatus: op.reviewStatus,
        reviewStatusNote: op.reviewStatusNote
      }
    });
    const result: Pick<OrderProduct, 'reviewStatus' | 'reviewStatusNote'> | null = await diagRef.afterClosed().toPromise();
    if (!result) return;
    this.service.loading = true;
    await this.orderService.setProductRFQReviewStatus(op, result.reviewStatus, result.reviewStatusNote).toPromise();
    this.service.loading = false;
    op.reviewStatus = result.reviewStatus;
    op.reviewStatusNote = result.reviewStatusNote;
    this.service.productsModified.pipe(take(1)).subscribe(prods => {
      const idx = prods.findIndex(pr => pr.productProductId === op.productProductId);
      prods.splice(idx, 1, op);
      this.service.productsModified.next(prods);
    });
  }
  
  @ViewChild('viewProductReviewTemplate') viewProductReviewTemplate: TemplateRef<any>;
  public viewReviewData(op: OrderProduct) {
    if (op.reviewStatus === OrderSegmentProductReviewStatus.NotReviewed) return;
    this.dialog.open(this.viewProductReviewTemplate, {
      width: '450px',
      data: {
        op,
      }
    });
  }

  private getAllTasksRecursive(taskList: Task[]): Task[] {
    return [...taskList, ...taskList.flatMap((t) => this.getAllTasksRecursive(t.subtasks))]
  }

  public getSimpleTaskStatus(op: OrderProduct, taskType: 'material' | 'workflow' | 'hardware') {
    const topTask = this.progressService.getProductTask(op.productProductId);
    let allStatuses = this.getAllTasksRecursive(topTask.subtasks).filter(t => t.microTaskId === taskType).map(t => t.status);
    if (allStatuses.some(s => s === TaskStatus.NOT_STARTED)) return TaskStatus.NOT_STARTED;
    if (allStatuses.some(s => EstimateProgressService.countsAsInProgress(s))) return TaskStatus.IN_PROGRESS;
    return TaskStatus.DONE;
  }

  public getStatusColorClass(status: TaskStatus) {
    return EstimateProgressService.getStatusColorClass(status);
  }
  
  public getStatusText(status: TaskStatus) {
    return EstimateProgressService.getStatusText(status);
  }

  public readonly PAGE_SIZE = 9 as const;
  private pageSubject = new Subject<PageEvent>();
  public onPage(event: PageEvent) {
    this.service.partListPageIndex = event.pageIndex;
    this.pageSubject.next(event);
  }
  public searchSubject = new BehaviorSubject<string>('');

  public trackByFn(index: number, item: OrderProductMod) {
    return item.productProductId;
  }

}
