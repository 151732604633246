import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderProduct } from '../../../../resources/order';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm, NgModel } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { getMimeTypeIcon } from '../../../../../common/resources/virtual-document';

export interface FilePartAssignment {
  file: File,
  filename: string,
  assignedPart: OrderProduct | 'NONE' | null,
  tags: string[],
}

@Component({
  selector: 'order-detail-products-upload',
  templateUrl: './order-detail-products-upload.component.html',
  styleUrls: ['./order-detail-products-upload.component.less']
})
export class OrderDetailProductsUploadComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      files: File[],
      products: OrderProduct[]
    },
  ) { }

  readonly separatorKeyCodes = [ENTER, COMMA, SPACE] as const;
  public displayedColumns = ['status', 'file', 'assignedPart', 'tags'] as const;
  public dataSource = new MatTableDataSource<FilePartAssignment>([]);

  removeTag(file: FilePartAssignment, index: number): void {
    file.tags.splice(index, 1);
    file.tags = [...file.tags];
  }

  addTag(file: FilePartAssignment, event: MatChipInputEvent): void {
    event.chipInput!.clear();
    if (!event.value?.trim()) return;
    file.tags = [...file.tags, event.value];
  }

  public filterOptions(model: NgModel) {
    const val = model.value;
    if (typeof val !== 'string' || !val) return this.data.products;
    const keywords = val.toLowerCase().split(' ');
    return this.data.products.filter(p =>
      keywords.some(kw => `${p.product.partNumber} Rev. ${p.product.revision}`.toLowerCase().includes(kw))
    )
  }

  public matchFile(file: File) {
    const matchedPart = this.data.products.find(prod => file.name.includes(prod.product.partNumber)) ?? null;

    return {
      file: file,
      filename: file.name,
      assignedPart: matchedPart,
      tags: (matchedPart && file.type === 'application/pdf') ? ['Drawing'] : [],
    }
  }

  public assignments: FilePartAssignment[];
  ngOnInit(): void {
    this.assignments = this.data.files.map((file) => this.matchFile(file));
    this.dataSource.data = this.assignments;
  }

  @ViewChild('form') form: NgForm;
  ngAfterViewInit(): void {
    this.form.form.markAllAsTouched();
  }

  public productDisplayFn(item: FilePartAssignment['assignedPart'] | string) {
    if (!item) return '';
    if (item === 'NONE') return 'No Part (Upload as Quote Document)';
    if (typeof item === 'string') return item;
    return `${item.product.partNumber} Rev. ${item.product.revision}`
  }

  public getIcon(file: FilePartAssignment) {
    return getMimeTypeIcon(file.file.type);
  }

}
