import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Product } from '../../../resources/product';
import { OrderDetailService } from '../order-detail.service';
import { SelectionModel } from '@angular/cdk/collections';
import { WorkflowStep } from '../../../resources/workflow';
import { Observable, combineLatest, of, pipe } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { MaterialBid } from '../../../../purchasing/resources/materialBid';
import { ProductTimingService, TimingData, TimingDataPurchasedItem, TimingDataWorkflow } from '../../../services/product-timing.service';

type SearchedQuote = MaterialBid | { error: 'UNQUOTED' | 'NOT_FOUND' }

@Component({
  selector: 'order-detail-lead-time-breakdown',
  templateUrl: './order-detail-lead-time-breakdown.component.html',
  styleUrls: ['./order-detail-lead-time-breakdown.component.less'],
})
export class OrderDetailLeadTimeBreakdownComponent implements OnInit, OnChanges {

  constructor(
    public service: OrderDetailService,
    public productTimingService: ProductTimingService,
  ) { }

  public get record() { return this.service.order }
  public get editing() { return this.service.editing }
  @Input() product: Product;

  public selectionModel = new SelectionModel<'PURCHASED' | 'OUTSOURCED' | 'LABOR' | 'CHILDREN'>(true);

  public get laborSteps() {
    return this.product.workflow.workflowSteps.filter(s => {
      const station = this.service.getStation(s);
      return !station.isOutsourceStep;
    })
  }

  public get outsourceSteps() {
    return this.product.workflow.workflowSteps.filter(s => {
      const station = this.service.getStation(s);
      return station.isOutsourceStep;
    })
  }

  public $leadTimeDetail: Observable<TimingData>
  public $subassemblyDetail: Observable<{part: string, days: number}[]>;

  public initObservables(): void {
    const $detail = this.service.getProductLeadTimeDetailObservable(this.product.productId);

    this.$leadTimeDetail = $detail.pipe(
      map(list => list.find(d => d.productId === this.product.productId))
    );

    this.$subassemblyDetail = $detail.pipe(
      map(list => {
        const subassemblyData: {part: string, days: number}[] = [];

        for (const child of this.product.childAssemblies)
          subassemblyData.push({
            part: `${child.partNumber} Rev. ${child.revision}`,
            days: list.find(d => d.productId === child.productId)?.totalDays ?? 0,
          });

        return subassemblyData;
      })
    );
  }

  getPurchasedItemData(itemId: string, data: TimingDataPurchasedItem[]) {
    return data?.find(d => d.itemId === itemId);
  }

  getWorkflowData(stepId: string, data: TimingDataWorkflow[]) {
    return data?.find(d => d.stepId === stepId);
  }

  ngOnInit(): void {
    this.initObservables();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initObservables();
  }

}
