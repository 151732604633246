import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { FloorService } from '../../services/floor.service';
import { UserService } from '../../../common/services/user.service';
import { ChartComponent } from '../../../common/components/chart/chart.component';

@Component({
  selector: 'operator-today-schedule',
  templateUrl: './operator-today-schedule.component.html',
  styleUrls: ['./operator-today-schedule.component.less']
})
export class OperatorTodayScheduleComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() date: Date = new Date();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  private chartLabels: any = null;
  public additionalData: any[] = [];
  public extendedConfiguration: any = null;
  public chartOptions: any = null;
  public chartPlugins: any[] = [];
  public chartData: any[] = null;
  public rawChartData: MachineAssignment[] = null;

  constructor(private floorSvc: FloorService, private userSvc: UserService) { }

  public bubbleClick(e: any) {
    this.onClick.emit(e);
  }

  private setupChart(): void {
    this.chartData = this.chartLabels = this.rawChartData = null;
    var od = this;

    this.chartOptions = {
      responsive: true,
      legend: { display: false },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            if (data.datasets[tooltipItem.datasetIndex].label == 'LTA')
              return 'LTA';

            return data.datasets[tooltipItem.datasetIndex].label + ': ' + Number(tooltipItem.xLabel) + ' min';
          }
        }
      },
      aspectRatio: 4,
      hover: {
        onHover: function (e, el) {
          var section = el[0];
          if (!section) {
            e.currentTarget.style.cursor = 'default';
            return;
          }
          e.currentTarget.style.cursor = (this.data.datasets[this.getElementAtEvent(e)[0]._datasetIndex].backgroundColor != 'transparent') ? 'pointer' : 'default';
        }
      },
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 1440,
            stepSize: 120,
            callback: function (v, i, vs) {
              return v / 60 + ":00";
            }
          }
        }],
        yAxes: [{ stacked: true }]
      }
    };

    this.chartPlugins = [{
      afterDatasetDraw: function (chart, options) {
        od.showNow(chart);
      },
      beforeDraw: function (chart, options) {
        var active = chart.tooltip._active || [];

        if (active.length > 0) {
          if (active[0]._model.start == 'transparent' || active[0]._model.backgroundColor == 'rgba(0, 0, 0, 0)') {
            // Opacity of 0 causes the tooltip draw method to do nothing
            chart.tooltip._model.opacity = 0;
          }
        }
      }
    }];

    this.floorSvc.getOperatorSchedule(this.userId || this.userSvc.userData.userId, this.date).subscribe(data => {
      this.rawChartData = data;

      this.getAssignmentLabels();
      this.getAssignmentData();
    });
  }

  private showNow(chart: any): void {
    const xAxisWidth = chart.scales['x-axis-0'].right - chart.scales['x-axis-0'].left;

    var drawLineAt = function (date: Date, color: string) {
      var lineLeftOffset = chart.scales['x-axis-0'].left + ((((date.getHours() * 60) + date.getMinutes()) / 1440) * xAxisWidth);

      chart.ctx.beginPath();
      chart.ctx.strokeStyle = color;
      chart.ctx.moveTo(lineLeftOffset, chart.scales['y-axis-0'].top);
      chart.ctx.lineTo(lineLeftOffset, chart.scales['y-axis-0'].bottom);
      chart.ctx.stroke();
    };

    if (this.isToday(this.date))
      drawLineAt(new Date(), '#2f9a41');

    //todo: lines at shifts??
  }

  private isToday(date: Date): boolean {
    var todayDate: Date = new Date();
    return date.toLocaleDateString() == todayDate.toLocaleDateString();
  }

  private getAssignmentLabels(): void {
    var labels = this.rawChartData.concat(this.additionalData).map(assignment => assignment.machine.name).filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    labels.push("LTA");

    this.chartLabels = labels;

    this.extendedConfiguration = {
      data: {
        labels: labels
      }
    };
  }

  private getMachineIndex(name: string): number {
    return this.chartLabels.indexOf(name);
  }

  private getDataArray(value: number, index: number): number[] {
    var values = new Array(this.chartLabels.length + 1).fill(0);

    values[index] = value;

    return values;
  }

  private mergeData(): any[] {
    var toBeAdded: any[] = JSON.parse(JSON.stringify(this.additionalData));
    if (toBeAdded && toBeAdded.length > 0) {
      var toRemove: number[] = [];
      for (var i in toBeAdded) {
        var overlap = this.rawChartData.findIndex(a => a.assignmentId == toBeAdded[i].assignmentId);
        if (overlap >= 0) {
          this.rawChartData[overlap] = toBeAdded[i];
          toRemove.push(Number(i));
        }
      }

      toRemove = toRemove.sort().reverse();
      for (var i in toRemove) {
        toBeAdded.splice(Number(i), 1);
      }

    }

    return this.rawChartData.concat(toBeAdded);
  }

  private getAssignmentData(): void {
    var data = [];
    var hasData = [];
    //1440 minutes in a day

    var allData: any[] = this.mergeData();

    for (var i in allData) {
      const assignment = allData[i];
      const chartIndex = this.getMachineIndex(assignment.machine.name);
      var midnight = new Date();
      midnight.setHours(0, 0, 0, 0);

      //transparent member first?
      if (hasData[chartIndex] == null && new Date(assignment.scheduledStart) > midnight) {
        var fill = new Date(assignment.scheduledStart).getHours() * 60 + new Date(assignment.scheduledStart).getMinutes();
        data.push({
          label: assignment.workOrder.workOrderNumber,
          backgroundColor: 'transparent',
          data: this.getDataArray(fill, chartIndex)
        });
      }

      data.push({
        label: assignment.workOrder.workOrderNumber,
        backgroundColor: assignment.backgroundColor || ChartComponent.getColor(chartIndex + parseInt(i) + 1),
        borderColor: assignment.borderColor || 'transparent',
        borderWidth: assignment.borderColor ? 1 : 0,
        borderSkipped: false,
        data: this.getDataArray(assignment.operation.runTime * (assignment.operation.runIsPerPart ? 1 : 60), chartIndex),
        assignmentId: assignment.assignmentId
      });
      hasData[chartIndex] = true;
    }

    data.push({
      label: "LTA",
      backgroundColor: '#aeaeae',
      data: this.getDataArray(1440, this.chartLabels.length - 1)
    });

    this.chartData = data;

  }

  public refresh(): void {
    this.setupChart();
  }

  ngOnInit(): void {
    this.setupChart();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupChart();
  }
}
