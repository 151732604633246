import { Injectable, Inject, Output, EventEmitter, Directive } from '@angular/core';
import { httpService } from '../../common/services/http.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { MessageService } from '../../common/services/message.service';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '../../common/resources/search-result';
import { Paint } from '../resources/paint';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UtilityService } from '../../common/services/utility.service';
import { MessageType } from '../../common/resources/message';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class PaintService extends httpService {
  private apiBase: string = 'api/paint';
  private apiUrl: string;
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Paint";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public search(searchString?: string): Observable<SearchResult<Paint>> {
    return this.http.get<SearchResult<Paint>>(this.apiUrl + '/search', { params: { searchText: searchString, pageIndex: '0', orderBy: "name", direction: "asc" } }).pipe(
      catchError(this.handleError<any>("Get Paints Search Results", null))
    );
  }

  public save(paint: Paint): Observable<Paint> {
    if (paint.paintId == UtilityService.emptyGuid) {
      return this.http.post<Paint>(this.apiUrl + '/new', paint).pipe(
        tap(_ => {
          this.messages.add("Paint Service: Paint Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<Paint>("Save New Paint", paint))
      );
    }
    else {
      return this.http.post<Paint>(this.apiUrl, paint).pipe(
        tap(_ => {
          this.messages.add("Paint Service: Paint Saved Successfully", MessageType.SUCCESS, true);
          this.updated.emit(true);
        }),
        catchError(this.handleError<Paint>("Update Paint", paint))
      );
    }
  }

  public remove(paintId: string): Observable<any> {
    return this.http.delete<Paint>(this.apiUrl + `/${paintId}`).pipe(
      tap(_ => {
        this.messages.add("Paint Service: Paint Removed Successfully", MessageType.SUCCESS, true);
        this.updated.emit(true);
      }),
      catchError(this.handleError<Paint>("Remove Paint", null))
    );
  }
}
