import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer, CustomerAddress, CustomerContact } from './../../../customer/resources/customer';
import { CustomerService } from './../../../customer/services/customer.service';
import { UtilityService } from './../../../common/services/utility.service';
import { NavigationService } from './../../../common/services/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from './../../../common/resources/user';
import { NgForm, NgModel } from '@angular/forms';
import { Vendor } from '../../../supplier/resources/vendor';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Contact } from '../../../common/resources/contact';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.less']
})
export class CustomerDetailComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() sidenav: MatSidenav;
  @Input() hint: string;
  @Output() customerChange: EventEmitter<Customer> = new EventEmitter<Customer>();
  private fromExternal: boolean = false;
  public customer: Customer;
  public approvedSupplierList: Vendor[] = null;
  public editing: boolean = false;
  public contactsView: boolean = false;
  public saving: boolean = false;
  public newContact: CustomerContact;
  public newSupplier: Vendor = null;
  public selectedTab: number = 0;
  @ViewChild('name') name: NgModel;
  public showEditor: string = null;
  @ViewChild('insetnav') insetnav: MatSidenav;

  constructor(private customerService: CustomerService, private utilitySvc: UtilityService, private route: ActivatedRoute, private navService: NavigationService, private router: Router) { }

  private getDetail(): void {
    this.customer = null;
    this.id = this.id || UtilityService.emptyGuid;
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;
      this.customer = <Customer>{
        customerId: UtilityService.emptyGuid,
        businessName: this.hint || "",
        customerAddresses: [],
      };

      this.approvedSupplierList = [];

      this.setPageTitle();
    }
    else {
      this.customerService.getDetail(this.id).subscribe(detail => {
        this.customer = detail;

        this.customerService.getApprovedSupplierList(this.customer).subscribe(result => this.approvedSupplierList = result);

        this.setPageTitle();

        if (this.customer.physicalAddressId) {
          this.selectedAddress = this.customer.customerAddresses.find(va => va.addressId === this.customer.physicalAddressId);
        } else if (this.customer.customerAddresses.length > 0) {
          this.selectedAddress = this.customer.customerAddresses[0]
        }

      });
    }
  }

  public goToVendor(vendor: Vendor) {
    this.router.navigate(['/supplier', vendor.vendorId]);
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  private setPageTitle(): void {
    if (!this.fromExternal) {
      this.navService.setPageTitle("Customer Detail");
      var customerNum = (this.customer.customerNum || 0).toString().padStart(8, "0");
      var breadcrumb = this.customer.businessName == null ? "New Customer" : `${this.customer.businessName} (C-${customerNum})`;
      this.navService.pushBreadcrumb(breadcrumb);
    }
  }

  public toggleEditing(): void {
    if (this.fromExternal) {
      this.returnToTop();
      return;
    }

    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.router.navigate(['/customers']);
    }

    this.getDetail();
    this.editing = !this.editing;
  }

  public toggleContactsView(): void {
    if (!this.contactsView && this.name && this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Customer Name is Required", "<p>Please add a customer name before proceeding.</p>");
      return;
    }

    this.contactsView = !this.contactsView;

    if (this.contactsView) {
      this.navService.pushBreadcrumb("Contacts");
    }
    else {
      this.navService.popBreadCrumb();
    }
  }

  public clearContact(): void {
    this.newContact = <CustomerContact>{ 
      customerContactId: UtilityService.emptyGuid,
      contact: {
        contactId: UtilityService.emptyGuid,
        name: "",
        title: "",
        email: "",
        note: "",
        phoneExtension: "",
        phoneNumber: "",
        faxNumber: ""
      }
    };
  }

  public returnToTop(): void {
    if (this.contactsView) this.toggleContactsView();
    this.customerChange.emit(this.customer);

    if (this.sidenav)
      this.sidenav.close();
  }

  public salesPersonChange(salesUsr: User): void {
    if (salesUsr == null) return;

    this.customer.salesPerson = salesUsr;
    this.customer.salesPersonUserId = salesUsr.userId;
  }

  public saveContact(contact: CustomerContact): void {
    if (!contact.contact.name || contact.contact.name.length < 2) {
      this.utilitySvc.showAlert("A Valid Contact Name is Required", "<p>Please add a name before proceeding.</p>");
      return;
    }

    this.saving = true;

    if (this.customer.customerId == UtilityService.emptyGuid) {

      this.customerService.save(this.customer)
        .subscribe(detail => {
          this.customer = detail;

          if (this.id != detail.customerId) {
            this.id = this.customer.customerId;
            this.router.navigate(['/customer', detail.customerId]);
          }

          this.saveContact(contact);
        });

      return;
    }

    //Note: The customer is saved if necessary from this function...
    this.customerService.saveContact(this.customer, contact).subscribe(c => {
      this.saving = false;
      this.clearContact();

      if (this.id != c.customerId) {
        this.id = this.customer.customerId;
        this.router.navigate(['/customer', c.customerId]);
      }
      this.getDetail();

    });
  }

  public deleteContact(contact: CustomerContact): void {
    this.utilitySvc.showConfirmation("Confirm Delete Contact",
      `<p>Are you sure you want to delete the contact '<em>${contact.contact.name}</em>'?</p><p class='font-weight-bold text-center'>This cannot be undone.</p>`
      , (result => {
        if (result === true) {
          this.saving = true;
          this.customerService.deleteContact(contact).subscribe(_ => {
            this.saving = false;
            this.getDetail();
          });
        }
      }));
  }

  public saveChanges(finishEditingWhenDone?: boolean): void {
    finishEditingWhenDone = finishEditingWhenDone || false;

    if (this.name && this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Name is Required", "<p>Please add a customer name before proceeding.</p>");
      return;
    }

    if (!!this.selectedAddress) {
      if (this.addressForm.invalid) {
        for(let i in this.addressForm.controls) {
          this.addressForm.controls[i].markAsTouched();
        }
        this.utilitySvc.showAlert("Invalid Address", "<p>Please correct errors in the selected address before proceeding.</p>");
        return;
      }

      if (this.addressForm.dirty) {
        this.utilitySvc.showAlert("Unsaved Address", "<p>Please save the selected address before proceeding.</p>");
        return;
      }
    }

    this.saving = true;
    this.customerService.save(this.customer)
      .subscribe(detail => {
        this.customer = detail;

        if (this.id != detail.customerId) {
          this.id = this.customer.customerId;
          if (!this.sidenav)
            this.router.navigate(['/customer', detail.customerId]);
        }

        this.saving = false;
        if (finishEditingWhenDone) {
          this.editing = false;
        }

        if (this.fromExternal) {
          this.returnToTop();
        } else {
          this.getDetail();
        }
      });
  }

  public addSupplier(): void {
    if (this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Name is Required", "<p>Please add a customer name before proceeding.</p>");
      return;
    }

    //NOTE: this makes the new supplier non-null, and thus it shows up. We validate it on save.
    this.newSupplier = <Vendor>{};
  }

  public cancelAddSupplier(): void {
    this.newSupplier = null;
  }

  public saveSupplier(): void {
    if (this.newSupplier.name == null || this.newSupplier.name.length < 3) {
      this.utilitySvc.showAlert("Invalid Supplier Selection", "<p>Please select a supplier from the supplier list before saving.</p>");
      return;
    }

    this.saving = true;

    this.customerService.addApprovedSupplier(this.newSupplier, this.customer).subscribe(v => {
      this.approvedSupplierList.push(this.newSupplier);
      this.newSupplier = null;
      this.saving = false;
    });

  }

  public createSupplier(name: string): void {
    console.log(`Creating supplier with text '${name}'`);
    this.showEditor = 'addVendor';
    this.insetnav.open();
  }

  public removeSupplier($event: any, vendor: Vendor): void {
    $event.stopImmediatePropagation();

    this.utilitySvc.showConfirmation("Confirm Remove Approved Supplier",
      `<p>Are you sure you want to remove the supplier '<em>${vendor.name}</em>'?</p><p class='font-weight-bold text-center'>This cannot be undone.</p>`
      , (result => {
        if (result === true) {
          this.saving = true;
          this.customerService.removeApprovedSupplier(vendor, this.customer).subscribe(_ => {
            this.saving = false;
            this.getDetail();
          });
        }
      }));
  }

  public setSelectedTab(e: StepperSelectionEvent): void {
    this.selectedTab = e.selectedIndex;
  }

  ngOnInit(): void {

    this.clearContact();

    if (this.id == null) {
      this.id = this.route.snapshot.paramMap.get('id');
      this.navService.setPageTitle("Customer Detail");
    }
    else {
      this.fromExternal = true;
      this.editing = true;
    }

    this.getDetail();

  }

  ngOnChanges(_: SimpleChanges): void {
    this.getDetail();
  }

  // 
  selectedAddress: CustomerAddress | null = null;

  previousAddressState: CustomerAddress | null = null;

  public get creatingAddress() {
    return this.selectedAddress && this.selectedAddress.customerAddressId === UtilityService.emptyGuid;
  }
  @ViewChild('addressForm') addressForm: NgForm;
  public async switchToAddress(address: CustomerAddress) {
    if (this.selectedAddress && address.customerAddressId === this.selectedAddress.customerAddressId) return;
    if (this.addressForm.dirty || this.creatingAddress) {
      const response = await this.utilitySvc.showConfirmationPromise(
        'Change address?', 
        'If you switch addresses, all changes made to the current address will be lost.',
        );
      if (response === false) return;
      else if (this.creatingAddress) {
        this.customer.customerAddresses = this.customer.customerAddresses.filter(x => x.customerAddressId !== UtilityService.emptyGuid);
      }
    }
    if (this.previousAddressState) Object.assign(this.selectedAddress, this.previousAddressState);
    this.selectedAddress = address;
    this.previousAddressState = JSON.parse(JSON.stringify(address));
    this.addressForm.form.markAsPristine();
    this.addressForm.form.markAsUntouched();
  }
  public async newAddress() {
    if (this.creatingAddress) return;
    if (this.addressForm.dirty) {
      const response = await this.utilitySvc.showConfirmationPromise(
        'Create new address?', 
        'If you switch addresses, all changes made to the current address will be lost.',
        );
      if (response === false) return;
    }
    this.previousAddressState = null;

    const addressNumber = this.customer.customerAddresses.length + 1;

    this.selectedAddress = {
      customerId: this.customer.customerId,
      customerAddressId: UtilityService.emptyGuid,
      addressId: UtilityService.emptyGuid,
      isPhysical: false,
      isMailing: false,
      address: {
        addressId: UtilityService.emptyGuid,
        nickname: `Address ${addressNumber}`,
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        postalCode: "",
        phoneNumber: "",
        faxNumber: "",
        contact: null,
        contactId: null,
      }
    }
    this.customer.customerAddresses.push(this.selectedAddress);
    this.addressForm.form.markAsPristine();
    this.addressForm.form.markAsUntouched();
  }

  public setAddressContactId(c: Contact) {
    this.selectedAddress.address.contactId = c && c.contactId;
  }

  public async saveAddress(a: CustomerAddress) {
    this.saving = true;
    if (this.customer.customerId == UtilityService.emptyGuid) {
      const detail = await this.customerService.save(this.customer).toPromise();
      this.customer = detail;
      this.id = detail.customerId;
    }
    const newAddress = await this.customerService.saveAddress(this.customer, a).toPromise();
    this.customer = await this.customerService.getDetail(this.id).toPromise();
    Object.assign(this.selectedAddress, newAddress);
    this.addressForm.form.markAsPristine();
    this.saving = false;
  } 

  public async deleteCurrentAddress() {
    if (this.creatingAddress) {
      this.selectedAddress = null;
      this.customer.customerAddresses = this.customer.customerAddresses.filter(x => x.customerAddressId !== UtilityService.emptyGuid);
    } else {
      const confirm = await this.utilitySvc.showConfirmationPromise('Delete address?', 'Are you sure? This cannot be undone.');
      if (!confirm) return;
      this.saving = true;
      await this.customerService.deleteAddress(this.selectedAddress).toPromise();
      this.customer.customerAddresses = this.customer.customerAddresses.filter(x => x.customerAddressId !== this.selectedAddress.customerAddressId);
      this.selectedAddress = null;
      this.saving = false;
    }
  }

  public onPhysicalAddressClick(e: MouseEvent, id: string) {
    e.preventDefault();
    e.stopPropagation();
    if (this.customer.physicalAddressId === id) this.customer.physicalAddressId = null;
    else this.customer.physicalAddressId = id;
  }

  public onMailingAddressClick(e: MouseEvent, id: string) {
    e.preventDefault();
    e.stopPropagation();
    if (this.customer.mailingAddressId === id) this.customer.mailingAddressId = null;
    else this.customer.mailingAddressId = id;
  }

  public delete(): void {
    if (this.customer && this.customer.customerId != UtilityService.emptyGuid) {
      this.utilitySvc.showConfirmation("Are you sure?", "<p>Are you sure you want to remove this customer?</p><p class='text-muted'>This WILl affect orders and other items that potentially use this customer.</p>", r => {
        if (r) {
          this.saving = true;
          this.customerService.delete(this.customer).subscribe(_ => {
            this.router.navigate(['/customer']);
            this.saving = false;
          });
        }
      })
    }
  }

}
