<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create Requisition Request"
    routerLink="/requisition/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" [showManagerBar]="hasPermission('PurchasingManager')"
      [managerView]="managerView" (managerViewChanged)="setManagerView($event)" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results">
      <loading></loading>
    </div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)"
      (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th>
            Requisition #
          </th>
          <th>
            Requisitioner
          </th>
          <th>
            Department
          </th>
          <th>
            Note
          </th>
          <th>
            Due Date
          </th>
          <th>
            Assigned To
          </th>
          <th [class.d-none]="!managerView">
            Created By
          </th>
          <th>
            Status
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.requisitionNumber}}</td>
        <td>{{row.createdBy.fullName}}</td>
        <td class="text-muted">{{row.department}}</td>
        <td>{{row.note}}</td>
        <td class="text-center">
          <span *ngIf="row.dueDate!=null" class="badge required" [ngClass]="getRequiredColor(row.dueDate)">
            {{row.dueDate | date}}
            <span class="d-block small">{{weeksLeft(row.dueDate, true)||'--'}} Week(s)
              {{(weeksLeft(row.dueDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
          </span>
        </td>
        <td [ngClass]="{'d-none':!hasPermission('SalesManager')}">
          <span
            [ngClass]="{'text-danger font-italic':!row.assignedUser}">{{row.assignedUser?.fullName || '-- Not Assigned --'}}</span>
        </td>
        <td [class.d-none]="!managerView">
          {{ row.createdBy.fullName }}
        </td>
        <td class="text-center">
          <span class="badge status text-uppercase" [ngClass]="getStatusColorClass(row.status)">
            {{getStatusText(row.status)}}
          </span>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
