import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { Order, OrderProduct, OrderSegmentProductReviewStatus, OrderStatus, QuoteLineItem } from "../../resources/order";
import { MaterialBid } from "../../../purchasing/resources/materialBid";
import { Product, ProductPurchasedItem } from "../../resources/product";
import { MaterialSelectComponent } from "../material-select/material-select.component";
import { Material, MaterialDimension } from "../../resources/material";
import { MaterialBidService } from "../../../purchasing/services/material-bid.service";
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, concat, merge, of, timer } from "rxjs";
import { OutsideProcessSpecification, Station } from "../../resources/station";
import { StationService } from "../../services/station.service";
import { WorkflowStep } from "../../resources/workflow";
import { UtilityService } from "../../../common/services/utility.service";
import { Router } from "@angular/router";
import { AsyncPipe, Location } from "@angular/common";
import { ProductHierarchySortComponent } from "../../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component";
import { debounceTime, filter, map, mergeMap, pairwise, pluck, sampleTime, scan, share, shareReplay, skip, skipUntil, startWith, switchAll, switchMap, take, tap, throttleTime, withLatestFrom } from "rxjs/operators";
import { VirtualDocument } from "../../../common/resources/virtual-document";
import { DocumentService } from "../../../common/services/document.service";
import { get, set } from 'idb-keyval';
import { UserService } from "../../../common/services/user.service";
import { OrderService } from "../../services/order.service";
import { Task, TaskStatus } from "../../../common/resources/estimatingtask";
import { EstimateProgressService } from "../../services/estimate-progress.service";
import { SelectionModel } from "@angular/cdk/collections";
import { MicroTicket } from "../../../microtickets/resources/microticket";
import { PricingBreakdown, PricingBreakdownItem, PricingBreakdownSubitem, doAltText } from "../../../../../util/pricing";
import { ProductTimingService, TimingData } from "../../services/product-timing.service";
import { MatSidenav } from "@angular/material/sidenav";

type SidenavMode = 'quoteDetail' | 'quoteHistory';
interface OrderDetailValidationError {
  error: string,
  screen: 'productList' | 'form' | 'material' | 'workflowStep' | 'purchasedItem'
  formField?: string
  productId?: string
  itemId?: string
}

type QuoteMap = { [itemId: string]: MaterialBid[] }

type QuoteStartMessage = {
  type: 'start',
  data: QuoteMap
}
type QuoteAddedMessage = {
  type: 'add',
  data: {
    itemId: string,
    quote: MaterialBid
  }
}
type QuoteEditedMessage = {
  type: 'edit',
  data: {
    itemId: string,
    quote: MaterialBid
  }
}
type QuoteBatchAddedMessage = {
  type: 'batch',
  data: QuoteMap
}

export async function fetchLatest<T>(obs: Observable<T>): Promise<T> {
  const result = await obs.pipe(take(1)).toPromise();
  return result;
}

@Injectable()
export class OrderDetailService {

  private _order: Order;

  public get order() { return this._order }
  public set order(val: Order) {
    this.progressService.order = val;
    this._order = val;
  }

  public productHierarchyExpansionModel = new SelectionModel<string>(true);


  public _selectedProductId: string;
  private selectedProductIdSubject = new BehaviorSubject<string | null>(null);
  public productChange = new Subject<Product>();
  public suppressProductUpdate = false;
  public get selectedProductId() { return this._selectedProductId }
  public set selectedProductId(val: string) {
    this.suppressProductUpdate = true;
    this.selectedProductIdSubject.next(val);
    if (!val) this.productHierarchyExpansionModel.clear();
    if (val !== null && this.mainTabBodyElement) {
      this.lastMainTabScroll = this.mainTabBodyElement?.scrollTop ?? 0;
    }
    this._selectedProductId = val;
    if (val === null) this.productTab = 0;
    if (val === null && this.mainTabBodyElement) {
      const observer = new ResizeObserver(() => {
        this.mainTabBodyElement.scrollTop = this.lastMainTabScroll ?? 0
        observer.disconnect();
      });
      observer.observe(this.mainTabBodyElement);
    }
    this.productChange.next(this.selectedProduct);
    setTimeout(() => this.suppressProductUpdate = false);
  }
  public get selectedProduct(): Product {
    if (this._selectedProductId === null) return null;
    return this.allProductsFlat().find(p => p.productId === this._selectedProductId);
  }
  public get selectedTopLevelProduct() {
    if (!this.selectedProduct) return null;
    else return this.order.products.find(tlp => tlp.productProductId === this.selectedProduct.topParentAssemblyId)?.product;
  }
  public editing = false;

  public loading$ = new BehaviorSubject(false);
  public set loading(value: boolean) {
    this.loading$.next(value);
  }

  public hierarchySort: ProductHierarchySortComponent;

  public oldData: Order;

  public setOldData() {
    this.oldData = JSON.parse(JSON.stringify(this.order));
  }
  
  public async startEditing() {
    this.setOldData();
    // if (this.order.discriminator === 'Estimate' || this.order.discriminator === 'RMAEstimate') {
    //   const r = await this.utilSvc.showConfirmationPromise("Edit estimate?", "It will have to be reapproved.");
    //   if (!r) {
    //     this.oldData = null;
    //     return;
    //   };
    //   this.order.status = 0;
    // }
    this.editing = true;
  }

  public dirty = false;
  public async stopEditing() {
    if (this.selectedProductId && !this.solo) {
      this.selectedProductId = null;
      return;
    }
    if (this.dirty) {
      const r = await this.utilSvc.showConfirmationPromise('Are you sure?', 'If you cancel editing, all your changes will be lost. This cannot be undone.');
      if (!r) return;
    }
    if (this.solo) {
      this.soloClose.emit();
      return;
    } else if (this.order.orderSegmentId === UtilityService.emptyGuid) {
      this.location.back();
    } else {
      if (this.dirty) this.order = this.oldData;
      this.dirty = false;
      this.selectedProductId = null;
      this.resetMetadata();
      this.onProductsChange(this.order.products.map(p => p.product));
      if (this.hierarchySort) this.hierarchySort.getDetail();
      this.oldData = null;
      this.editing = false;
    }
  }

  public onTopLevelProductChange(product: Product) {
    let current = this.order.products?.map(p => p.product) ?? [];
    let index = current.findIndex(p => p.productId === product.productId);
    if (index === -1) return;
    current.splice(index, 1, product);
    this.progressService.syncOrCreateTopLevelProductTask(product);
    this.onProductsChange(current);
  }

  public onProductsChange(newProducts: Product[]) {
    const oldProducts: OrderProduct[] = JSON.parse(JSON.stringify(this.order.products));
    const toDelete = oldProducts.filter(op => newProducts.findIndex(np => np.productId === op.productProductId) === -1);
    const toUpdate = oldProducts.filter(op => newProducts.findIndex(np => np.productId === op.productProductId) !== -1);
    const toAdd = newProducts.filter(np => oldProducts.findIndex(op => op.productProductId === np.productId) === -1);
    let output: OrderProduct[] = JSON.parse(JSON.stringify(this.order.products));
    for (const item of toDelete) {
      output = output.filter(o => o.productProductId !== item.productProductId)
    }
    for (const item of toUpdate) {
      const index = output.findIndex(o => o.productProductId === item.productProductId);
      const newItem = newProducts.find(p => p.productId === item.productProductId);
      output[index].product = newItem;
    }
    for (const item of toAdd) {
      output = [...output, <OrderProduct>{
        orderSegmentOrderSegmentId: this.order.orderSegmentId,
        productProductId: item.productId,
        product: item
      }]
    }
    this.order.products = output;
    this.order.products.forEach((p, i) => p.sort = i);
    this.productsModified.next(this.order.products);
  }

  private allChildrenFlatRecursive(product: Product): Product[] {
    return product.childAssemblies.flatMap(p => this.allProductsFlatRecursive(p))
  }

  private allProductsFlatRecursive(product: Product): Product[] {
    return [product, ...this.allChildrenFlatRecursive(product)]
  }

  public allProductsFlat(): Product[] {
    return this.order.products.flatMap(p => this.allProductsFlatRecursive(p.product));
  }

  // Any IDs for items that need to be saved later go here
  public newIds: string[] = [];
  public addNewId(id: string) {
    this.newIds.push(id);
  }

  public addNewIds(ids: string[]) {
    this.newIds.push(...ids);
  }

  public addClonedProductIds(product: Product, documents = true) {
    const allProducts = this.allProductsFlatRecursive(product);
    const ids = [];
    for (const product of allProducts) {
      ids.push(product.productId);
      ids.push(product.workflow.workflowId);
      product.workflow.workflowSteps.forEach(s => {
        ids.push(s.workflowStepId);
        s.workflowStepInventoryItems?.forEach(wsii => ids.push(wsii.workflowStepInventoryItemId));
      });
      product.purchasedItems.forEach(p => ids.push(p.productPurchasedItemId));
      // if (documents) product.documents.forEach(p => ids.push(p.documentDocumentId));
    }
    this.newIds.push(...ids);
  }

  public mapMaterialSearchStatus(map: { [oldId: string]: string }) {
    for (const oldId in map) {
      if (Object.prototype.hasOwnProperty.call(map, oldId)) {
        const newId = map[oldId];
        const oldInputMaterial = this.productsWithInputMaterial[oldId];
        if (oldInputMaterial) this.productsWithInputMaterial[newId] = JSON.parse(JSON.stringify(oldInputMaterial));
        const oldInputParameters = this.productMaterialParameters[oldId];
        if (oldInputParameters) this.productMaterialParameters[newId] = JSON.parse(JSON.stringify(oldInputParameters));
      }
    }
  }
  // Storing products that have a newly built material and will require extra logic in the backend.
  public productsWithInputMaterial: { [key: string]: (MaterialSelectComponent['parameterIds']) } = {}
  public productMaterialParameters: { [key: string]: { params: (MaterialSelectComponent['materialParameters']) , dimensions: MaterialDimension[], state: MaterialSelectComponent['state'], hasFinishedSpecs: boolean, density: number } } = {}
  
  public getStation(item: WorkflowStep): Station {
    if (this.stationService.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationService.stationList.find(r => r.stationId == item.stationId);
  }

  public quotesByItemObservableMap: { [itemId: string]: Observable<MaterialBid[]> } = {};
  public getItemQuotesObservable(itemId: string, isNewMaterial = false): Observable<MaterialBid[]> {
    if (this.quotesByItemObservableMap[itemId]) return this.quotesByItemObservableMap[itemId];
    const newObservable = this.allQuotes.pipe(
        map(all => {
          return all[itemId]
        }),
        filter(i => !!i),
        shareReplay(1)
      );
    this.quotesByItemObservableMap[itemId] = newObservable;

    fetchLatest(this.allQuotes).then(all => {
      // If we haven't seen this item before, go fetch it from the API
      if (!all[itemId]) {
        this.quoteService.searchItem(itemId).subscribe(results => {
          this.quotesFetched.next({
            [itemId]: results.results
          })
        });
      }
    });

    return newObservable;
  }

  public quoteAdded = new Subject<{
    itemId: string,
    quote: MaterialBid,
  }>();

  public addQuote(itemId: string, quote: MaterialBid, isNewMaterial = false) {
    // if (isNewMaterial) {
    //   if (!this.searchedMaterialQuotesMap[productId]) this.searchedMaterialQuotesMap[productId] = [];
    //   this.searchedMaterialQuotesMap[productId] = [...this.searchedMaterialQuotesMap[productId], quote];
    // } else {
    //   if (!this.quotesMap[productId]) this.quotesMap[productId] = {};
    //   if (!this.quotesMap[productId][itemId]) this.quotesMap[productId][itemId] = [];
    //   this.quotesMap[productId][itemId] = [...this.quotesMap[productId][itemId], quote];
    // }
    this.addNewId(quote.materialBidId);
    this.quoteAdded.next({ itemId, quote });
  }
  
  public quoteEdited = new Subject<{
    itemId: string,
    quote: MaterialBid,
  }>();
  public editQuote(itemId: string, quote: MaterialBid, isNewMaterial = false) {
    this.quoteEdited.next({ itemId, quote });
  }

  // Have to save new outside process specs so that they will display properly before saving
  public newOPSpecs: OutsideProcessSpecification[] = [];
  
  public getFirstQuantity(product: Product): number {
    return Product.getFirstQuantity(product);
  }

  async quoteSearchCallback(itemId: string, quoteId: string) {
    return (
      await fetchLatest(this.getItemQuotesObservable(itemId))
    ).find(q => q.materialBidId === quoteId);
  }

  public async resetProductLeadTime(product: Product): Promise<void> {
    // If this is a child, don't update it. The parent should be updating at the same time,
    // which will update the child along with it.
    if (product.parentAssemblyId)
      return;

    // For top-level products, remove existing data and calculate times for the whole tree again
    await this.productTimingService.updateDataForProduct(product, this.getFirstQuantity(product));
  }

  public getProductLeadTime(product: Product): number {
    let qty = null;

    // Top-level product, get the quantity ourselves
    // Otherwise, send null to get previously calculated amount for children
    if (!product.parentAssemblyId)
      qty = this.getFirstQuantity(product);

    const timingData = this.productTimingService.getDataForProduct(product, qty);

    return timingData?.totalDays ?? 0;
  }

  public getProductLeadTimeDetail(product: Product): TimingData[] {
    return this.productTimingService.getAllDataForProduct(product);
  }

  public getProductMaterial(product: Product) {
    if (product.material) return product.material;
    else if (this.productsWithInputMaterial[product.productId]) {
      const { params, dimensions, density, state, hasFinishedSpecs } = this.productMaterialParameters[product.productId];
      if (state !== 'DONE' || !hasFinishedSpecs) return null;
      else return <Material>{
        materialType: params.type,
        materialDimensions: dimensions,
        density
      }
    } else return null;
  }

  private getProductPrice(product: Product, qty: number, parentQty: number = null): number {
    // do this to make sure we have all quotes. should be instant if we do since we store them clientside
    // Should always exist after the previous method is run
    if (parentQty) qty = qty * parentQty;
    let totalPrice = 0;
    let material = this.getProductMaterial(product);
    if (material && product.selectedMaterialQuote) {
      const materialCost = Product.getMaterialCost(product, qty, material);
      totalPrice += materialCost;
    }
    const stepCostList = product.workflow.workflowSteps.filter(s => !s.isStandalone).sort((a,b) => a.stepOrder - b.stepOrder).map(s => WorkflowStep.calculateCostForQty(s, qty));
    const stepCost = stepCostList.reduce((acc, x) => acc + x, 0);
    totalPrice += stepCost;
    let purchasedItemPrice = 0;
    for (const item of product.purchasedItems ?? []) {
      let itemPrice = item.costPer ?? 0;
      itemPrice = itemPrice * item.quantity;
      if (!item.isNonRecurring) itemPrice = itemPrice * qty;
      purchasedItemPrice += itemPrice * (1 + ((item.markupPercent ?? 0.0) / 100.0));
    }
    totalPrice += purchasedItemPrice;
    // children
    const childCosts = (product.childAssemblies ?? []).map(c => this.getProductPrice(c, c.quantityAsChild, qty));
    totalPrice += childCosts.reduce((acc, x) => acc + x, 0);
    return totalPrice;
  }

  public async getProductUnitPrice(product: Product, parentQty: number = null) {
    let qty = this.getFirstQuantity(product);
    const total = this.getProductPrice(product, qty, parentQty);
    return parseFloat((total / qty).toFixed(2));
  }

  public productEdited = new Subject<Product>();
  public orderEdited = new Subject<Order>();
  public productsModified = new BehaviorSubject<OrderProduct[]>([]);
  public notifyEdited(product: Product = null) {
    if (this.editing && !this.suppressProductUpdate) {
      this.productEdited.next(product ?? this.selectedProduct);
    }
  }
  
  // Some navigation bindings so we can easily jump to certain parts of the app using the service
  public mainTab = 0;
  public productTab = 0;
  public selectedWorkflowStepId: string;
  public selectedPurchasedItemId: string;

  public async validate(product: Product): Promise<OrderDetailValidationError> {
    // await this.getAllQuotesForProduct(product);
    // Quantities
    if (!product.parentAssemblyId && product.quantitiesMap.length === 0) {
      const screen = this.order.discriminator === 'RFQ' ? 'productList' : 'form';
      const i = this.order.products.findIndex(op => op.productProductId === product.productId);
      const formField = this.order.discriminator === 'RFQ' ? `orderProducts[${i}].quantitiesMap` : null;
      return { error: `Part ${product.partNumber} Rev. ${product.revision} needs at least 1 quantity`, screen, formField, productId: product.productId }
    }
    if (this.order.discriminator !== 'RFQ' && product.parentAssemblyId && (!product.quantityAsChild || product.quantityAsChild < 0)) {
      return { error: 'Subassembly needs a valid quantity', screen: 'form', productId: product.productId }
    }
    // Workflow steps
    // for (const step of product.workflow.workflowSteps) {
    //   if (this.getStation(step).isOutsourceStep) {
    //     // ...
    //   } else {

    //   }
    // }
    // Purchased items
    for (const item of product.purchasedItems) {
      if (!item.purchasedItem) return { error: 'Please select a purchased item before saving', screen: 'purchasedItem', productId: product.productId, itemId: item.productPurchasedItemId }
    }
  }

  public async validateAll(): Promise<OrderDetailValidationError> {
    if (!this.order.customer) return { error: 'Please select a customer before saving.', screen: 'form' }
    const all = this.allProductsFlat();
    for (const product of all) {
      const error = await this.validate(product);
      if (error) return error;
    }
  }

  public async uploadDocument(file: File): Promise<VirtualDocument> {
    this.loading = true;
    const doc = this.documentService.upload(file as any).toPromise();
    this.addNewId((await doc).documentId);
    this.loading = false;
    return doc;
  }

  public async batchUploadDocuments(files: File[]): Promise<VirtualDocument[]> {
    this.loading = true;
    const docs = await Promise.all(files.map(f => this.documentService.upload(f as any).toPromise()));
    docs.forEach(d =>  this.addNewId(d.documentId))
    this.loading = false;
    return docs;
  }

  resetMetadata() {
    this.newIds = [];
    this.productsWithInputMaterial = {};
    this.productMaterialParameters = {};
    this.newOPSpecs = [];
    this.movedProductIds = new Set();
  }

  public get products() {
    return this.order.products?.map(p => p.product);
  }

  public async initLeadTimes() {
    this.productTimingService.resetData();
    this.productTimingService.setCallback(this.quoteSearchCallback.bind(this));
    for (const product of this.products) {
      await this.productTimingService.updateDataForProduct(product, this.getFirstQuantity(product));
    }
  }

  public initStartingQuotes() {
    this.quoteService.getAllQuotesForOrder(this.order).subscribe(x => this.startingQuotes.next(x));
  }

  public startingQuotes = new ReplaySubject<{ [key: string]: MaterialBid[]}>(1);
  private observableMap: { [key: string]: Observable<Product> } = {};
  // public allProducts: Observable<{ [key: string]: Product }>
  public productsReset = new ReplaySubject<Product[]>(1);




  public allQuotes: Observable<{ [productId: string]: MaterialBid[] }>;

  public allQuotesStatic: { [productId: string]: MaterialBid[] };

  public selectedProduct$: Observable<Product>;
  
  public quotesFetched = new Subject<QuoteMap>();
  constructor(
    private documentService: DocumentService,
    private quoteService: MaterialBidService,
    public stationService: StationService,
    private utilSvc: UtilityService,
    private location: Location,
    private asyncPipe: AsyncPipe,
    private orderService: OrderService,
    private userSvc: UserService,
    private progressService: EstimateProgressService,
    private productTimingService: ProductTimingService,
    ) {
    this.allQuotes = concat(
      this.startingQuotes.pipe(take(1)), 
      merge(
        this.startingQuotes.pipe(map(data => ({
          type: 'start',
          data
        }))),
        this.quoteAdded.pipe(map(data => ({
          type: 'add',
          data
        }))),
        this.quotesFetched.pipe(map(data => ({
          type: 'batch',
          data
        }))),
        this.quoteEdited.pipe(map(data => ({
          type: 'edit',
          data
        }))),
      )
    ).pipe(
      scan<any, QuoteMap>((acc: QuoteMap, val: QuoteMap | QuoteStartMessage | QuoteAddedMessage | QuoteBatchAddedMessage | QuoteEditedMessage) => {
        if (!val.type) return val as QuoteMap;
        if (val.type === 'start') acc = val.data;
        else if (val.type === 'batch') acc = { ...acc, ...val.data };
        else if (val.type === 'add') {
          const { itemId, quote } = val.data;
          if (!acc[itemId]) acc[itemId] = [];
          // For some reason, in some instances every new quote triggers the subject twice
          // I don't have time to figure out why on earth that happens, so I'm adding a bandaid fix here
          if (acc[itemId].findIndex(x => x.materialBidId === val.data.quote.materialBidId) === -1) acc[itemId].push(quote);
        }
        else if (val.type === 'edit') {
          const { itemId, quote } = val.data;
          if (!acc[itemId]) acc[itemId] = [];
          // For some reason, in some instances every new quote triggers the subject twice
          // I don't have time to figure out why on earth that happens, so I'm adding a bandaid fix here
          const index = acc[itemId].findIndex(x => x.materialBidId === val.data.quote.materialBidId);
          if (index === -1) {
            console.error(`Attempted to edit nonexistent quote with id ${val.data.quote.materialBidId}`);
          } else {
            acc[itemId].splice(index, 1, val.data.quote);
          }
        }
        return acc;
      }),
      shareReplay(1)
    );

    merge(this.orderEdited, this.productsModified, this.productEdited).subscribe(() => this.dirty = true);

    this.productEdited.subscribe(() => console.log('PRODUCTEDITED'));

    this.selectedProduct$ = this.selectedProductIdSubject.pipe(
      filter(id => !!id),
      switchMap(id => this.getProductObservable(id)),
      shareReplay(1)
    );
  }

  private mapSubassemblies(product: Product): string[] {
    return [product.productId, ...product.childAssemblies.flatMap(ca => this.mapSubassemblies(ca))];
  }

  private findSubassembly


  public productObservables: { [key: string]: Observable<Product> } = {}
  public productSubjects: { [key: string]: BehaviorSubject<Product> } = {}

  private createProductObservableAndSubject(productId: string) {
    const obs = merge(
      this.productsReset.pipe(
        map(l => l.flatMap(p => this.allProductsFlatRecursive(p))),
        map(l => l.find(p => p.productId === productId)),
        filter(l => !!l)
      ),
      this.productEdited
    ).pipe(
      scan((state, p) => {
        if (!p) {
          state.emit = false;
        } else if (p?.productId === productId) {
          state.subassemblyMap = this.mapSubassemblies(p);
          state.product = p;
          state.emit = true;
        } else if (state.subassemblyMap.includes(p?.productId)) {
          const position = state.subassemblyMap.findIndex(id => id === p.productId);
          if (position !== -1) state.subassemblyMap.splice(position, 1, ...this.mapSubassemblies(p));
          // We have to replace the product in the subassemblies with the one we just got
          state.emit = true;
        } else {
          state.emit = false;
        }
        return state;
      }, {
        product: null,
        subassemblyMap: [],
        emit: false
      }),
      filter(state => state.emit),
      switchMap(state => {
        const subassemblies = (state.product as Product).childAssemblies.map(x => this.getProductObservable(x.productId).pipe(take(1)));
        if (subassemblies.length === 0) return combineLatest([of(state.product as Product), of([])]);
        else return combineLatest([of(state.product as Product), combineLatest(subassemblies)]);
      }),
      map(([product, subassemblies]) => {
        product.childAssemblies = subassemblies;
        return product;
      }),
      shareReplay(1)
    );
    const flat = this.allProductsFlat();
    const base = flat.find(p => p.productId === productId);
    const sbj = new BehaviorSubject<Product>(base);
    obs.subscribe(sbj);
    this.productObservables[productId] = obs;
    this.productSubjects[productId] = sbj;
  }

  public getProductObservable(productId: string) {
    if (!this.productObservables[productId]) {
      this.createProductObservableAndSubject(productId);
    }
    return this.productObservables[productId];
  }
  public getProductSubject(productId: string) {
    if (!this.productObservables[productId]) {
      this.createProductObservableAndSubject(productId);
    }
    return this.productSubjects[productId];
  }

  public async getProductsLatestValues(): Promise<OrderProduct[]> {
    const products = this.order.products.map((op) => {

      const sbj = this.getProductSubject(op.productProductId);
      return {
        ...op,
        product: sbj.getValue()
      }
    });
    return products;
  }

  public leadTimeObservables: { [key: string]: Observable<number> } = {}
  public getProductLeadTimeObservable(productId: string): Observable<number> {
    if (!this.leadTimeObservables[productId])
      this.leadTimeObservables[productId] = this.getProductObservable(productId)
        .pipe(
          switchMap(async pr => {
            await this.resetProductLeadTime(pr);
            return this.getProductLeadTime(pr);
          }),
          shareReplay(1)
        );
    return this.leadTimeObservables[productId];
  }

  public leadTimeDetailObservables: { [key: string]: Observable<TimingData[]> } = {}
  public getProductLeadTimeDetailObservable(productId: string): Observable<TimingData[]> {
    if (!this.leadTimeDetailObservables[productId])
      this.leadTimeDetailObservables[productId] = this.getProductObservable(productId)
        .pipe(
          switchMap(async pr => {
            await this.resetProductLeadTime(pr);
            return this.getProductLeadTimeDetail(pr);
          }),
          shareReplay(1)
        );
    return this.leadTimeDetailObservables[productId];
  }

  public productPriceObservables: { [key: string]: { [key: number]: Observable<number> } } = {}
  public getProductPriceObservable(product: Product, qty: number): Observable<number> {
    let qtyMap = this.productPriceObservables[product.productId];
    if (!qtyMap) this.productPriceObservables[product.productId] = {};
    qtyMap = this.productPriceObservables[product.productId];
    if (!qtyMap[product.productId]) qtyMap[qty] = this.getProductObservable(product.productId).pipe(
      map(p => {
        if (!p) return 0;
        return this.getProductPrice(p, qty);
      }),
      shareReplay(1)
    );
    return qtyMap[qty];
  }

  public productUnitPriceObservables: { [key: string]: { [key: number]: Observable<number> } } = {}
  public getProductUnitPriceObservable(product: Product, qty: number = null): Observable<number> {
    let qtyMap = this.productUnitPriceObservables[product.productId];
    if (!qtyMap) this.productUnitPriceObservables[product.productId] = {};
    qtyMap = this.productUnitPriceObservables[product.productId];
    if (!qtyMap[product.productId]) qtyMap[qty ?? 0] = this.getProductObservable(product.productId).pipe(
      switchMap(p => {
        if (!qty) qty = this.getFirstQuantity(p);
        const markup = p.quantitiesMap.find(q => q.value === qty)?.markup ?? 0;
        return this.getProductPriceObservable(p, qty).pipe(
          take(1),
          map(o => {
          const markedUp = o * (1 + markup / 100);
          return parseFloat((markedUp / qty).toFixed(2));
        }));
      }),
      shareReplay(1)
    );
    return qtyMap[qty ?? 0];
  }

  public generateProgrammingPrice(product: Product) {
    return product.workflow.workflowSteps.filter(s => s.hasProgramming).map(s => WorkflowStep.getProgrammingCost(s)).reduce((acc, x) => acc + x, 0);
  }

  public async generateLineItems(): Promise<QuoteLineItem[]> {
    if (!this.stationService.loaded) {
      await this.stationService.stationsLoaded.toPromise();
    }
    const output = await Promise.all(this.order.products.map(osp => osp.product).map(async (product, i) => {
      // All quantities
      const leadTimeDays = await this.getProductLeadTime(product);
      let output: QuoteLineItem[] = [];
      const quantityItems = await Promise.all(product.quantitiesMap.map(async (qty) => {
        let cost = await this.getProductPrice(product, qty.value);
        const price = cost * ( 1 + qty.markup / 100);
        const unitPrice = parseFloat((price / qty.value).toFixed(2));
        let extPrice = unitPrice * qty.value;
        return <QuoteLineItem>{
          lineItemNumber: (i + 1).toString(),
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: null,
          station: null,
          quantity: qty.value,
          unitPrice,
          extPrice,
          leadTimeDays,
        };
      }));
      output = output.concat(quantityItems);
      const standaloneStepItems = product.workflow.workflowSteps.filter((s) => s.isStandalone).map(step => {
        const qty = this.getFirstQuantity(product);
        let stepPrice = WorkflowStep.calculateCostForQty(step, qty);
        return <QuoteLineItem>{
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: step.stationId,
          station: this.getStation(step),
          quantity: step.runIsPerPart ? qty : 1,
          unitPrice: step.runIsPerPart ? (stepPrice / qty) : stepPrice,
          extPrice: stepPrice,
          leadTimeDays,
        };
      });
      output = output.concat(standaloneStepItems);
      const programmingCost = this.generateProgrammingPrice(product);
      if (programmingCost > 0) {
        const progStation = this.stationService.stationList.find((s) => s.name === 'Programming');
        output.push({
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: progStation.stationId,
          station: progStation,
          quantity: 1,
          unitPrice: programmingCost,
          extPrice: programmingCost,
          leadTimeDays,
        })
      }
      return output;
    }));
    return output.flat();
  }

  // Code for live updating estimating quote view
  public quotePreviewWindow: Window | null;
  public quotePreviewLoading = false;
  private quotePreviewSubscription: Subscription;
  private quotePreviewWindowInterval: any = null;

  public async updateQuotePreview() {
    this.quotePreviewLoading = true;
    const lineItems = await this.generateLineItems();
    const res = await fetch(`/api/orderSegment/getQuoteReportClientside/${this.order.orderSegmentId}`, {
      method: 'POST',
      body: JSON.stringify(lineItems),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const buffer = await res.arrayBuffer();
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const w = this.quotePreviewWindow;
    this.quotePreviewLoading = false;
    w.open(fileURL, '_self');
    setTimeout(() => w.document.title = `Quote Preview - ${this.order.orderSegmentId}`, 50);
  }

  private onPreviewWindowUnload() {
    if (this.quotePreviewWindow.closed) {
      this.quotePreviewWindow = null;
      this.quotePreviewLoading = false;
      clearInterval(this.quotePreviewWindowInterval);
      this.quotePreviewWindowInterval = null;
      if (this.quotePreviewSubscription) this.quotePreviewSubscription.unsubscribe();
    }
  }

  public closeQuotePreviewWindow() {
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.close();
    }
  }

  public startQuotePreview() {
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.focus();
      return;
    };
    this.quotePreviewWindow = window.open('about:blank', '_blank', 'menubar=no,status=no,toolbar=no');
    // only reliable way to check if window was closed seems to be constant interval
    this.quotePreviewWindowInterval = setInterval(() => {
      if (this.quotePreviewWindow.closed) this.onPreviewWindowUnload()
    }, 500)
    // this.quotePreviewWindow.addEventListener('beforeunload', this.onPreviewWindowUnload.bind(this));
    this.updateQuotePreview();
    this.quotePreviewSubscription = combineLatest([this.productsModified, this.productEdited.pipe(startWith(() => null))])
    .pipe(
      debounceTime(1000)
    )
    .subscribe(() => {
      this.updateQuotePreview();
    });
    window.addEventListener('beforeunload', this.closeQuotePreviewWindow.bind(this));
    return this.quotePreviewWindow;
  }

  public userIsManager(): boolean {
    return this.userSvc.canAccess('QuoteManager');
  }

  public canApproveRFQ() {
    return this.order && this.order.discriminator === 'RFQ' && this.order.status === OrderStatus.AWAITING_REVIEW && this.userSvc.canAccess("RFQReviewer");
  }

  public getIncompleteAssemblyInfo(product: Product) {
    const top = this.order.products.find(p => p.productProductId === product.topParentAssemblyId);
    if (!top) return null;
    if (top.reviewStatus !== 1 && top.reviewStatus !== 3) return null;
    else return { note: top.reviewStatusNote, status: top.reviewStatus };
  }

  public async markAsCorrected(product: Product) {
    this.loading = true;
    const top = this.order?.products?.find(p => p.productProductId === product.topParentAssemblyId);
    try {
      await this.orderService.markProductAsCorrected(this.order.orderSegmentId, top.productProductId).toPromise();
      top.reviewStatus = OrderSegmentProductReviewStatus.Corrected;
    } catch (_) { } finally {
      this.loading = false;
    }
  }

  public getTotalQuantity(product: Product, quantity: number) {
    let currentParent = product;
    let totalQuantity = quantity;
    let allProducts = this.allProductsFlat();

    while (currentParent.parentAssemblyId !== null) {
      totalQuantity *= currentParent.quantityAsChild ?? 0;
      currentParent = allProducts.find(p => p.productId === currentParent.parentAssemblyId);
    }

    return totalQuantity;
  }

  public getPriceBreakdown(product: Product, quantity: number) {

    const totalQuantity = this.getTotalQuantity(product, quantity);

    const material = Product.getMaterialBreakdown(product, totalQuantity, this.getProductMaterial(product));

    let purchasedItems: PricingBreakdownItem[] = null;
    if (product.purchasedItems?.length > 0) {
      purchasedItems = [];
      for (const item of product.purchasedItems) {
        purchasedItems.push(ProductPurchasedItem.getBreakdown(item, totalQuantity));
      }
    }
 
    const outsourcingSteps = product.workflow.workflowSteps.filter(s => !s.isStandalone && s.outsourceMarkup).sort((a,b) => a.stepOrder - b.stepOrder);
    let process: PricingBreakdownItem[] = null;
    if (outsourcingSteps.length > 0) {
      process = [];
      for (const step of outsourcingSteps) {
        const name = this.getStation(step).name;
        process.push(WorkflowStep.getOutsideProcessBreakdown(step, totalQuantity, name));
      }
    }
    
    const laborSteps = product.workflow.workflowSteps.filter(s => !s.isStandalone && !s.outsourceMarkup).sort((a,b) => a.stepOrder - b.stepOrder);
    let labor: PricingBreakdownItem[] = null;
    if (laborSteps.length > 0) {
      labor = [];
      for (const step of laborSteps) {
        const name = this.getStation(step).name;
        if (step.paint)
          labor.push(WorkflowStep.getPaintStepBreakdown(step, totalQuantity, name));
        else
          labor.push(...WorkflowStep.getLaborStepBreakdown(step, totalQuantity, name));
      }
    }

    return <PricingBreakdown>{
      material,
      purchasedItems,
      process,
      labor,
    }
  }

  public movedProductIds = new Set<string>();
  public addMovedProduct(productId: string) {
    this.movedProductIds.add(productId);
  }
  public removeMovedProduct(productId: string) {
    this.movedProductIds.delete(productId);
  }

  public generateSubItemNavigationId(productId: string, type: 'product' | 'material' | 'workflow' | 'hardware', subItemId?: string) {
    if (type === 'product') {
      return `${productId}`;
    } else if (type === 'material') {
      return `${productId}--material`;
    } else if (type === 'workflow') {
      if (!subItemId) return `${productId}--workflow`;
      return `${productId}--workflow--${subItemId}`;
    } else if (type === 'hardware') {
      if (!subItemId) return `${productId}--hardware`;
      return `${productId}--hardware--${subItemId}`;
    }
  }

  public focusedMicroticketId: string = null;
  public focusedPurchasedItemId: string = null;
  public navigateToSubItem(navigationString: string) {
    const [left, right, subItemId] = navigationString.split('--');
    if (left === 'microticket') {
      const microticketId = right;
      if (!microticketId) console.warn('Attempted to navigate to microticket with invalid ID')
      else {
        // Microtickets tab
        this.focusedMicroticketId = microticketId;
        this.mainTab = 5;
        this.selectedProductId = null;
      }
      return;
    }
    const productId = left;
    if (!productId) {
      console.warn(`Invalid navigation string ${navigationString}`);
      return;
    }
    this.mainTab = 0;
    this.selectedProductId = productId;
    if (!right) this.productTab = 0;
    else if (right === 'material') this.productTab = 2;
    else if (right === 'workflow') {
      this.selectedWorkflowStepId = subItemId ?? null;
      this.productTab = 3;
    } else if (right === 'hardware') {
      this.selectedPurchasedItemId = subItemId ?? null;
      this.productTab = 4;
    }
  }
  
  public get nameForMicrotickets(): string {
    if (!this.order) return '';
    else if (this.order.discriminator === 'RFQ' || this.order.discriminator === 'Quote') return this.order.orderNumber;
    else return this.order.quoteNumber ?? this.order.orderNumber;
  }

  public get microticketFilterKey(): string {
    if (this.order.discriminator === 'RFQ' && this.order.status === 1) return 'Estimating';
    if (this.order.discriminator === 'RFQ' || this.order.discriminator === 'Quote') return 'Sales';
    if (this.order.discriminator === 'Estimate' || this.order.discriminator === 'RMAEstimate') return 'Estimating';
    else return 'Other';
  }

  public sharedMicroticketSubject = new ReplaySubject<MicroTicket[]>(1);
  public mainTabBodyElement: HTMLDivElement;
  private lastMainTabScroll: number;

  public partListPageIndex = 0;

  //
  private soloClose: EventEmitter<void>;
  public initSolo(product: Product, order: Order, soloClose: EventEmitter<void>) {
    this.solo = true;
    this.soloClose = soloClose;
    order = JSON.parse(JSON.stringify(order));
    order.products = [{
      orderSegmentOrderSegmentId: order.orderSegmentId,
      productProductId: product.productId,
      reviewStatus: OrderSegmentProductReviewStatus.Complete,
      reviewStatusNote: '',
      product,
    }]
    this.order = order;
    this.selectedProductId = product.productId;
    this.editing = true;
    this.initStartingQuotes();
  }
  public solo: boolean = false;

  //
  public contractReviewAnswers = {};

  public canApproveEstimate() {
    return this.userSvc.canAccess('EstimateManager');
  }

  public estimateIsApprovable() {
    const allProductIds = this.allProductsFlat().map(p => p.productId);
    return allProductIds.every(id => {
      const answers = this.contractReviewAnswers[id]?.['dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e'];
      if (!answers) return false;
      const answerValues = Object.values(answers);
      return answerValues.length === 7 && answerValues.every(v => v !== undefined && v !== null);
    });
  }

  public sidenav: MatSidenav;
  public sidenavMode = new BehaviorSubject<SidenavMode | null>(null);
  public sidenavData = new BehaviorSubject<any | null>(null);
  public openSidenav(mode: SidenavMode, data: any | null) {
    this.sidenavData.next(data);
    this.sidenavMode.next(mode);
    this.sidenav.open();
  }

  public editedQuotes = new Set<string>();

  public async resetSidenav() {
    const [mode, data] = await fetchLatest(combineLatest([this.sidenavMode, this.sidenavData]));
    if (mode === 'quoteDetail' && this.editing) {
      const { itemId, quote }: { itemId: string, quote: MaterialBid } = data;
      this.editQuote(itemId, JSON.parse(JSON.stringify(quote)));
      if (!this.newIds.includes(quote.materialBidId)) this.editedQuotes.add(quote.materialBidId);
    }
    this.sidenavMode.next(null);
    this.sidenavData.next(null);
  }

}