import { Component, OnInit, Output, EventEmitter, Input, } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { Station } from '../../../order/resources/station';
import { StationService } from '../../../order/services/station.service';
import { Observable, combineLatest } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.less']
})
/** station-list component*/
export class StationListComponent implements OnInit {
  public stationList: Station[] = null;
  @Input() addButton: boolean = false;
  @Input() editing: boolean = false;
  @Output() addNew: EventEmitter<void> = new EventEmitter<void>();
  @Output() staged: EventEmitter<Station> = new EventEmitter<Station>();
  @Output() selected: EventEmitter<Station> = new EventEmitter<Station>();

  searchCtrl = new FormControl('');
  filteredStations: Observable<Station[]>;
  constructor(private stationService: StationService, private userService: UserService) {
    this.filteredStations =  combineLatest([
      this.stationService.search('').pipe(map(sl => sl.results.filter(s => !s.isDeleted))),
    this.searchCtrl.valueChanges.pipe(startWith(''))]).pipe(
      map(([list, search]) => 
        (search ?
          (list.filter(s => s.name.toLowerCase().includes(search.toLowerCase())))  
          : list.slice())
      ),
    );
  }

  getStations(): void {
    this.stationService.stationsLoaded.asObservable().subscribe(r => {
      if (r) this.stationList = this.stationService.stationList;
    });
    if (this.stationService.loaded) { this.stationList = this.stationService.stationList; }
  }

  public async reloadStations() {
    this.stationService.getStationList();
    this.getStations();
    if (!this.stationService.loaded) {
      await this.stationService.stationsLoaded.toPromise();
    } 
  }

  public send(station: Station): void {
    this.staged.emit(station);
  }

  public select(station: Station): void {
    this.selected.emit(station);
  }

  public onDragStart($event: any, station: Station, index: number) {
    sessionStorage.setItem('station-list-drag-data', JSON.stringify(station));
  }

  public get canAdd() {
    return this.userService.canAccess('AdminUser')
  }

  ngOnInit(): void {
    this.getStations();
  }
}
