
<ng-template #newMicroticketDialogTemplate>
  <new-microticket
      #microTicketForm
      [relatedTicketId]="relatedTicketId"
      [relatedTicketName]="relatedTicketName"
      [filterKey]="filterKey"
      (save)="onSave($event)"
      (cancel)="onCancel()"
      [isNew]="true"
      [fixedButtons]="true"
  ></new-microticket>
</ng-template>

<mat-accordion class="position-relative" *ngIf="filteredMicroTickets$ | async as tickets">
  <button mat-fab class="extended-fab-button extended-fab-small button-text" color="primary" (click)="create()"
    style="position: absolute;
    top: -1.25em;
    right: 1em;
    z-index: 1;"
  >
      <mat-icon svgIcon="note-plus"></mat-icon>
      Create Microticket
  </button>
  <mat-expansion-panel disabled *ngIf="tickets.length === 0" class="microticket-panel">
    <mat-expansion-panel-header>
      <div class="w-100 text-center text-muted font-italic">No microtickets found for this item.</div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel class="microticket-panel" *ngFor="let ticket of tickets"
    data-testid="microticket-panel"
    [attr.data-test-subitem-id]="ticket.relatedSubItem"
    [expanded]="expansionModel.isSelected(ticket.microTicketId)"
    (opened)="expansionModel.select(ticket.microTicketId)"
    (closed)="expansionModel.deselect(ticket.microTicketId)"
  >
    <mat-expansion-panel-header>
      <div class="w-100 d-flex align-items-center">
        <mat-chip [ngClass]="getStatusColorClass(ticket.status)" class="button-text mr-2">
            {{ getStatusText(ticket.status) }}
        </mat-chip>
        <div class="text-muted mr-2">Assigned to <b data-testid="assignedUserName">{{getUserName(ticket.assignedUser)}}</b> by <b data-testid="creatorName">{{ getUserName(ticket.creator) ?? 'Unknown User' }}</b></div>
        <div class="text-muted ml-auto mr-2" *ngIf="ticket.dueDate">
          <b>Due on <b>{{ ticket.dueDate | date }}</b></b>
        </div>
      </div>
    </mat-expansion-panel-header>
    <p>{{ ticket.text }}</p>
    <div class="d-flex align-items-center justify-content-end">
        <button mat-flat-button (click)="close(ticket)" *ngIf="isOwn(ticket) && ticket.status !== 2 && ticket.status !== 3">
            <mat-icon matPrefix svgIcon="close-octagon"></mat-icon>
            Close
        </button>
        <button mat-flat-button color="accent" class="button-text ml-2" (click)="markInProgress(ticket)" *ngIf="isAssignedToMe(ticket) && ticket.status === 0">
            Mark as In Progress
        </button>
        <button mat-flat-button color="accent" class="bg-success button-text ml-2" (click)="markComplete(ticket)" *ngIf="isAssignedToMe(ticket) && ticket.status === 1">
            Mark as Completed
        </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>