import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FloorService } from '../../../floor/services/floor.service';
import { Department } from '../../../floor/resources/building';

@Component({
  selector: 'department-dropdown',
  templateUrl: './department-dropdown.component.html',
  styleUrls: ['./department-dropdown.component.less']
})
export class DepartmentDropdownComponent implements OnInit, OnChanges {
  @Input() selectedDepartment: Department;
  @Input() editable: boolean = false;
  public selection: Department;
  @Output() selectedDepartmentChange: EventEmitter<Department> = new EventEmitter<Department>();
  public departments: Department[] = null;

  constructor(private floorSvc: FloorService) {
    this.selection = this.selectedDepartment;
  }

  public selectDepartment(dpt: Department): void {
    event.preventDefault();
    this.selection = dpt;
    this.selectedDepartmentChange.emit(dpt);
  }

  ngOnInit(): void {
    this.floorSvc.search('').subscribe(result => {
      this.departments = result.results;
    });
  }

  ngOnChanges(_: SimpleChanges): void {
    this.selectDepartment(this.selectedDepartment);
  }
}
