import { Directive, OnInit } from "@angular/core";
import { NG_VALIDATORS, Validator, UntypedFormControl, ValidationErrors } from "@angular/forms";

@Directive({
  selector: '[notNullValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NotNullValidatorDirective, multi: true }
  ]
})
export class NotNullValidatorDirective implements Validator, OnInit {

  ngOnInit() {}
 
  validate(c: UntypedFormControl): ValidationErrors | null {
 
    if (c.value === null || c.value === undefined) {
      return { required: true };
    }
    return null;
  }
}