<loading *ngIf="!user; else loaded"></loading>

<ng-template #loaded>

  <div *ngIf="selectedMachine && selectedDepartment && selectedBuilding; else stationSelect">
    <ng-container *ngTemplateOutlet="resetMachineBtn"></ng-container>
    <operator-detail [machineId]="selectedMachine.machineId" (badMachine)="onBadMachine()"></operator-detail>
  </div>

  <ng-template #stationSelect>
    <div class="text-center border border-secondary p-4 m-2 mb-4">
      <h2 class="text-primary text-uppercase">Welcome {{user.fullName}}</h2>
      <p class="text-muted">Please select your Department, Building and Machine</p>
      <ng-container *ngTemplateOutlet="resetMachineBtn"></ng-container>
    </div>
    <div class="m-2">
      <div *ngIf="!selectedBuilding">
        <div *ngIf="buildingList; else loading" class="select-box">
          <div class="select-frame" *ngFor="let building of buildingList" (click)="setBuilding(building)">{{building.name}}</div>
          <div *ngIf="buildingList.length==0"><ng-container *ngTemplateOutlet="empty"></ng-container></div>
        </div>

      </div>
      <div *ngIf="!selectedDepartment && selectedBuilding">
        <div *ngIf="departmentList; else loading" class="select-box">
          <div class="select-frame" *ngFor="let department of departmentList" (click)="setDepartment(department)">{{department.name}}</div>
          <div *ngIf="departmentList.length==0"><ng-container *ngTemplateOutlet="empty"></ng-container></div>
        </div>
      </div>
      <div *ngIf="!selectedMachine && selectedDepartment && selectedBuilding">
        <div *ngIf="machineList; else loading" class="select-box">
          <div class="select-frame" *ngFor="let machine of machineList" (click)="setMachine(machine)">{{machine.name}}</div>
          <div *ngIf="machineList.length==0"><ng-container *ngTemplateOutlet="empty"></ng-container></div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #loading>
    <loading></loading>
  </ng-template>
  <ng-template #empty>
    <div class="m-4 p-4 text-center text-muted font-italic font-weight-bold">
      <h2>-- Nothing to Show --</h2>
      <p>Please see a management team member.</p>
    </div>
  </ng-template>
  <ng-template #resetMachineBtn>
    <div class="d-block p-1 pb-2 mb-4" *ngIf="selectedBuilding">
      <div class="float-right">
        <button class="btn btn-sm btn-secondary text-muted" (click)="resetMachine()">
          <i class="fas fa-fw fa-lg fa-exchange-alt"></i>
          Switch Station
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>
