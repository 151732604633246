<loading *ngIf="loading" content="Loading..."></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<button mat-fab color="warn" class="position-fixed" style="bottom: 15px; right: 10px; z-index: 1030" (click)="close()">
  <mat-icon>close</mat-icon>
</button>

<div class="container-fluid py-2 d-flex flex-column">
  <div class="row h-100">
    <div class="col-4 h-100 d-flex flex-column">
      <mat-form-field appearance="outline" dense class="w-100 dense-field">
        <mat-label>Filter Items</mat-label>
        <input matInput type="text" [(ngModel)]="filter" #filterModel="ngModel">
      </mat-form-field>
      <ul (scroll)="onListScroll($event)"
        class="list-group list-group-hover list-group-striped overflow-y-scroll relative" style="flex-grow: 1">
        <div *ngIf="searching" class="searching-indicator">
          <i class="fa fa-lg fa-spin fa-circle-notch text-primary"></i>
          <div class="text-muted font-italic mt-1">Searching...</div>
        </div>
        <li (click)="setSelectedItem(item)" class="list-group-item mat-caption"
          [class.item-selected]="selectedItem && selectedItem.qualityClauseId === item.qualityClauseId"
          *ngFor="let item of items; index as i">
          <span [innerHTML]="item.number + (item.title ? ' (' + item.title + ')' : '') | highlight: filter"></span>
          <span class="float-right">
            <span [class.invisible]="!item.hardwareDefault">H</span>
            &nbsp;
            <span [class.invisible]="!item.materialDefault">M</span>
            &nbsp;
            <span [class.invisible]="!item.processDefault">O</span>
            </span>
        </li>
      </ul>
      <button [disabled]="isCreating" mat-flat-button color="primary" [class.bg-success]="!isCreating"
        class="mt-2 new-item-button" (click)="createNewItem()">
        New Item
      </button>
    </div>
    <div class="col-8 h-100">
      <mat-card class="h-100 mat-elevation-z0 border border-secondary" [class.p-0]="!selectedItem">
        <div *ngIf="!selectedItem"
          class="bg-light p-3 w-100 h-100 d-flex align-items-center justify-content-center text-muted font-italic">
          Choose or
          create a QC clause from the left
          panel.</div>
        <ng-container *ngIf="!!selectedItem">
          <mat-card-header>
            <mat-card-title>
              {{ isCreating ? 'Creating' : 'Editing' }}
              QC Clause</mat-card-title>
            <mat-card-subtitle>{{selectedItem?.number || '&nbsp;'}}
              <span *ngIf="selectedItem?.title">-- {{selectedItem.title}}</span>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <form #itemForm="ngForm">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Clause #</mat-label>
                    <input matInput autocomplete="off" type="text" [(ngModel)]="selectedItem.number" name="number"
                      required>
                    <mat-error *ngIf="itemForm.controls['name']?.errors?.required">
                      Clause number is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Clause Name</mat-label>
                    <input matInput autocomplete="off" type="text" [(ngModel)]="selectedItem.title" name="title">
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Clause Text</mat-label>
                <textarea style="min-height: 4em;" matInput rows="6" autocomplete="off" [(ngModel)]="selectedItem.text"
                  name="text"></textarea>
              </mat-form-field>
              <h5>Default Clause For</h5>
              <div class="text-muted small d-flex align-items-center my-1">
                <mat-icon class="mr-1">info</mat-icon>
                This clause will be applied by default to all purchase orders of the selected types unless it is
                overriden per vendor.
              </div>
              <div>
                <mat-checkbox class="d-block mb-2" [(ngModel)]="selectedItem.hardwareDefault" name="hardwareDefault">
                  Hardware
                </mat-checkbox>
                <mat-checkbox class="d-block mb-2" [(ngModel)]="selectedItem.materialDefault" name="materialDefault">
                  Material
                </mat-checkbox>
                <mat-checkbox class="d-block mb-2" [(ngModel)]="selectedItem.processDefault" name="processDefault">
                  Outside Processing
                </mat-checkbox>
              </div>
            </form>
          </mat-card-content>
          <mat-card-actions align="end">
            <button type="button" mat-flat-button color="warn" class="mr-2" *ngIf="!isCreating"
              (click)="deleteSelected()">
              Delete
            </button>
            <button type="submit" mat-flat-button color="primary" class="mr-5"
              [disabled]="itemForm.invalid || itemForm.pristine" (click)="saveSelected()">
              Save
            </button>
          </mat-card-actions>
        </ng-container>
      </mat-card>
    </div>
  </div>
</div>
