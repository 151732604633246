import { Station } from "../../order/resources/station";
import { User } from "../../common/resources/user";
import { Address } from "../../common/resources/address";
import { InventoryLocation } from "../../inventory/resources/inventory-location";

export interface Department {
  departmentId: string;
  name: string;
  alertCount: number;
  addressId: string;
  
  floorBuildings: FloorBuilding[];
}

export interface FloorBuilding {
  floorBuildingId: string;
  departmentId: string;
  buildingId: string;
  readonly name: string;
  width: number;
  height: number;
  posX: number;
  posY: number;
  alertCount: number;
  managerId: string;

  manager: User;
  machines: FloorMachine[];
}

export interface Building {
  buildingId: string;
  name: string;
  addressId: string;
  rootInventoryLocationId?: string;
  
  address: Address;
  rootInventoryLocation?: InventoryLocation;
}

export class FloorMachine {
  machineId: string;
  floorBuildingId: string;
  name: string;
  width: number;
  height: number;
  posX: number;
  posY: number;
  operatingLength: number;
  operatingHeight: number;
  operatingThickness: number;
  lastMaintenance?: Date;
  stationId: string;
  status: number;

  extendedData: any;
  operationType: Station;

  public static statuses: any[] = [
    { statusValue: 0, statusText: "OPERATIONAL" },
    { statusValue: 1, statusText: "SCHEDULED MAINTENANCE" },
    { statusValue: 2, statusText: "OFFLINE" },
    { statusValue: 3, statusText: "ALERT" },
  ];

  public static getStatusText(status: number): string {
    var statusText = "--";

    const entry = this.statuses.find(s => s.statusValue == status);
    if (entry) {
      statusText = entry.statusText;
    }

    return statusText;
  }
}

