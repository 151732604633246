import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { httpService } from '../../common/services/http.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { AccountReceivable, AccountReceivableStatus } from '../resources/account-receivable';
import { SearchResult } from '../../common/resources/search-result';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountingService extends httpService {
  private apiBase: string = 'api/';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Accounting";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  searchAccountsReceivable(searchString?: string, page?: number, pageSize?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<AccountReceivable>> {
    return this.http.get<SearchResult<AccountReceivable>>(this.apiUrl + 'AccountReceivable/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), pageSize: pageSize.toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Accounts Receivable Search Results", null))
    );
  }

  getDetail(id: string): Observable<AccountReceivable> {
    return this.http.get<AccountReceivable>(this.apiUrl + 'AccountReceivable/' + id).pipe(
      catchError(this.handleError<any>("Get Account Receivable Detail", null))
    );
  }

  save(_item: AccountReceivable): Observable<AccountReceivable> {
    const item: AccountReceivable = JSON.parse(JSON.stringify(_item));
    item.customerPurchaseOrderLineItem = null;
    item.shippingTicket = null;
    return this.http.post<AccountReceivable>(this.apiUrl + 'AccountReceivable/', item).pipe(
      catchError(this.handleError<any>("Save Account Receivable Detail", null))
    );
  }

  public static getStatusColorClass(status: AccountReceivableStatus) {
    switch (status) {
      case AccountReceivableStatus.AWAIT_WORK:
        return 'bg-secondary text-dark';
      case AccountReceivableStatus.READY_INVOICE:
        return 'bg-danger text-white';
      case AccountReceivableStatus.AWAIT_PAYMENT:
        return 'bg-warning text-dark';
      case AccountReceivableStatus.PAID:
        return 'bg-success text-white';
      case AccountReceivableStatus.CLOSED:
        return 'bg-danger text-dark';
      default:
        return ''
    }
  }

  public static getStatusText(status: AccountReceivableStatus) {
    switch (status) {
      case AccountReceivableStatus.AWAIT_WORK:
        return 'Awaiting WO';
      case AccountReceivableStatus.READY_INVOICE:
        return 'Ready to Invoice';
      case AccountReceivableStatus.AWAIT_PAYMENT:
        return 'Invoiced';
      case AccountReceivableStatus.PAID:
        return 'Paid';
      case AccountReceivableStatus.CLOSED:
        return 'Closed';
      default:
        return ''
    }
  }

}
