import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { OutsideProcessDescription } from '../../../purchasing/resources/outsideProcessDescription';
import { PurchasingService } from '../../../purchasing/services/purchasing.service';
import { NgForm, NgModel } from '@angular/forms';
import { debounceTime, filter, map, mergeMap, startWith } from 'rxjs/operators';
import { UtilityService } from '../../../common/services/utility.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

function tokenSearch(target: string, search: string) {
  const tokens = (search ?? "").split(" ");
  return tokens.some(tk => target.includes(tk));
}
@Component({
  selector: 'outside-process-editor',
  templateUrl: './outside-process-editor.component.html',
  styleUrls: ['./outside-process-editor.component.less']
})
export class OutsideProcessEditorComponent implements AfterViewInit {

  constructor(
    private service: PurchasingService,
    private utilitySvc: UtilityService,
    private router: Router
  ) { }

  @ViewChild('searchModel') searchModel: NgModel;
  public items$: Observable<OutsideProcessDescription[]>;
  public saving = false;

  private saved$ = new Subject<void>();

  ngAfterViewInit(): void {
    const searchedItems = combineLatest([
      this.searchModel.valueChanges.pipe(
        startWith(''),
        debounceTime(300)
      ),
      this.saved$.pipe(startWith(null))
    ]).pipe(
      mergeMap(([search, _]) => {
        if (!search || typeof search !== 'string') search = '';
        return this.service.searchOutsideProcesses(search);
      })
    )
    this.items$ = combineLatest([
      searchedItems,
      this.searchModel.valueChanges.pipe(
        startWith('')
      )
    ]).pipe(
      map(([items, filter]) => {
        if (!filter || typeof filter !== 'string') filter = '';
        if (!items) return items;
       return items.filter(i => !filter || tokenSearch(i.outsideProcessDescriptionNumber?.toLowerCase() + ' ' + i.name.toLowerCase(), filter.toLowerCase()))
      })
    )
  }

  public selectedItem: OutsideProcessDescription | null;
  @ViewChild('form') form: NgForm;
  public markAsDirty() {
    this.form.control.markAsDirty();
  }

  private async dirtyCheck() {
    if (this.form?.dirty) return this.utilitySvc.showConfirmationPromise('Are you sure?', 'Unsaved changes on the current item will be lost.');
    else return true;
  }

  public async select(item: OutsideProcessDescription) {
    const r = await this.dirtyCheck();
    if (!r) return;
    this.selectedItem = JSON.parse(JSON.stringify(item));
    setTimeout(() => 
      this.form.control.markAsPristine()
    );
  }

  public getStationString(item: OutsideProcessDescription) {
    return Array.from(new Set(item.steps.filter(s => !!s.station).map(s => s.station.name))).join(', ')
  }

  public async save() {
    this.saving = true;
    const item = await this.service.saveOutsideProcessDescription(this.selectedItem).toPromise();
    this.selectedItem = item;
    this.saved$.next();
    this.saving = false;
  }

  public async delete() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'This cannot be undone. It will not affect existing POs, but you will have to recreate the process for future ones.');
    if (!r) return;
    this.saving = true;
    await this.service.deleteOutsideProcessDescription(this.selectedItem).toPromise();
    this.selectedItem = null;
    this.saved$.next();
    this.saving = false;
  }

  public async create() {
    const r = await this.dirtyCheck();
    if (!r) return;
    this.selectedItem = {
      outsideProcessDescriptionId: UtilityService.emptyGuid,
      product: null,
      productId: null,
      steps: []
    }
  }

  @Input() sidenav: MatSidenav;
  public async close() {
    const r = await this.dirtyCheck();
    if (!r) return;
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public get isNew() {
    return this.selectedItem?.outsideProcessDescriptionId === UtilityService.emptyGuid;
  }

  public async onClone(item: OutsideProcessDescription) {
    const r = await this.dirtyCheck();
    if (!r) return;
    this.selectedItem = item;
  }

}
