

export class auxString{


  public static padLeft(num: string, size: number): string {
    while (num.length < size)
      num = "0" + num;
    return num;
  }

}
