import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap, concatMap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message';
import { httpService } from '../../common/services/http.service';
import { UtilityService } from '../../common/services/utility.service';
import { UserService } from '../../common/services/user.service';
import { CounterService } from '../../common/services/counter.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { SearchResult } from '../../common/resources/search-result';
import { InventoryItem } from '../../inventory/resources/inventory-item';
import { InventoryLocation } from '../../inventory/resources/inventory-location';
import { InventoryItemLocation } from '../../inventory/resources/inventory-item-location';

@Injectable({
  providedIn: 'root',
})
export class InventoryService extends httpService {
  private apiBase: string = 'api/InventoryItem';
  private apiBaseLocation: string = 'api/InventoryLocation';
  private apiBaseItemLocation: string = 'api/InventoryItemLocation';
  private apiBaseFacade: string = 'api/Inventory';
  private apiUrl: string;
  private apiLocationUrl: string;
  private apiItemLocationUrl: string;
  private apiFacadeUrl: string;

  constructor(private counterService: CounterService, private usrService: UserService, errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Inventory";

    this.apiUrl = this.baseUrl + this.apiBase;
    this.apiLocationUrl = this.baseUrl + this.apiBaseLocation;
    this.apiItemLocationUrl = this.baseUrl + this.apiBaseItemLocation;
    this.apiFacadeUrl = this.baseUrl + this.apiBaseFacade;
  }

  search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string, locationId?: string): Observable<SearchResult<InventoryItem>> {
    let params: Object = {
      params: {
        searchText: searchString,
        pageIndex: (page || 0).toString(),
        pageSize: 50,
        orderBy: sortBy || "name",
        direction: sortDirection || "asc",
      }
    };
    if (typeof(locationId) === "string" && locationId.length > 0) {
      params['params']['locationId'] = locationId;
    }
    return this.http.get<SearchResult<InventoryItem>>(this.apiFacadeUrl + '/search/', params).pipe(
      catchError(this.handleError<any>("Get Inventory Search Results", null))
    );
  }

  save(item: InventoryItem): Observable<InventoryItem> {
    if (item.inventoryItemId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<InventoryItem>(this.apiUrl + '/new', item).pipe(
        tap(_ => this.messages.add("Inventory Service: Inventory Item Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<InventoryItem>("Save New Inventory Item", item))
      );
    }
    else {
      //Existing Item
      return this.http.post<InventoryItem>(this.apiUrl, item).pipe(
        tap(_ => this.messages.add("Inventory Service: Inventory Item Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<InventoryItem>("Update Inventory Item", item))
      );
    }
  }

  saveLocation(location: InventoryLocation): Observable<any> {
    if (location.inventoryLocationId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<InventoryLocation>(this.apiLocationUrl + '/new', location).pipe(
        tap(_ => this.messages.add("Inventory Service: Inventory Location Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<InventoryLocation>("Save New Inventory Location", location))
      );
    }
    else {
      //Existing Item
      return this.http.post<InventoryLocation>(this.apiLocationUrl, location).pipe(
        tap(_ => this.messages.add("Inventory Service: Inventory Location Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<InventoryLocation>("Update Inventory Location", location))
      );
    }
  }

  deleteLocation(location: InventoryLocation): Observable<any> {
    return this.http.delete<InventoryLocation>(this.apiLocationUrl + '/' + location.inventoryLocationId).pipe(
      tap(_ => this.messages.add("Inventory Service: LocationRemoved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<InventoryLocation>("Remove Existing Location", location))
    );
  }

  getDetail(id: string): Observable<InventoryItem> {
    return this.http.get<InventoryItem>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Inventory Item Detail", null))
    );
  }

  getLocationChildren(loc: InventoryLocation | null): Observable<InventoryLocation[]> {
    let url: string = loc ? loc.inventoryLocationId : '';
    return this.http.get<InventoryLocation[]>(this.apiFacadeUrl + '/getChildren/' + url).pipe(
      catchError(this.handleError<any>("Get Inventory Location Children", null))
    );
  }

  getLocationDetail(id: string): Observable<InventoryLocation> {
    return this.http.get<InventoryLocation>(this.apiLocationUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Inventory Location Detail", null))
    );
  }

  getLocationParents(id: string): Observable<InventoryLocation[]> {
    return this.http.get<InventoryLocation[]>(this.apiFacadeUrl + '/getParents/' + id).pipe(
      catchError(this.handleError<any>("Get Inventory Location Parents", null))
    );
  }

  getAddresses(itemLocIds: string[]): Observable<any> {
    return this.http.post<InventoryLocation[][]>(this.apiFacadeUrl + '/getAddresses', itemLocIds).pipe(
      catchError(this.handleError<any>("Get Inventory Location Addresses", null))
    );
  }

  deleteItemLocation(itemLocId: string): Observable<any> {
    return this.http.delete<InventoryItemLocation>(this.apiBaseItemLocation + '/' + itemLocId).pipe(
      catchError(this.handleError<any>("Delete Inventory Location", null))
    );
  }

  saveItemLocation(itemLocation: InventoryItemLocation): Observable<any> {
    if (itemLocation.inventoryItemLocationId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<InventoryItemLocation>(this.apiItemLocationUrl + '/new', itemLocation).pipe(
        tap(_ => this.messages.add("Inventory Service: Inventory Item Location Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<InventoryItemLocation>("Save New Inventory Item Location", itemLocation))
      );
    }
    else {
      //Existing Item
      return this.http.post<InventoryItemLocation>(this.apiItemLocationUrl, itemLocation).pipe(
        tap(_ => this.messages.add("Inventory Service: Inventory Item Location Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<InventoryItemLocation>("Update Inventory Item Location", itemLocation))
      );
    }
  }

  getItemLocations(id: string): Observable<InventoryItemLocation[]> {
    return this.http.get<InventoryItemLocation[]>(this.apiFacadeUrl + '/getItemLocations/' + id).pipe(
      catchError(this.handleError<any>("Get Inventory Item Locations", null))
    );
  }

  saveItemLocations(itemLocs: InventoryItemLocation[]): Observable<any> {
    return this.http.post<InventoryItemLocation[]>(this.apiFacadeUrl + '/saveItemLocations', itemLocs).pipe(
      tap(_ => this.messages.add("Inventory Service: Inventory Item Locations Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<InventoryItemLocation[]>("Save Inventory Item Locations", itemLocs))
    );
  }

  locationTreeFilter(filterText: string, rootLocationIds: string[] = [], filterItem?: InventoryItem, noMachines: boolean = false): Observable<InventoryLocation[]> {
    let baseLocations = '';
    for (const locationId of rootLocationIds) {
      baseLocations += `&baseLocations=${locationId}`;
    }
    return this.http.get<InventoryLocation[]>(this.apiFacadeUrl + `/locationTreeFilter?filterText=${encodeURIComponent(filterText)}&filterItemId=${(filterItem && filterItem.inventoryItemId) || ''}&noMachines=${noMachines.toString()}` + baseLocations).pipe(
      catchError(this.handleError<InventoryLocation[]>("Filter Inventory Locations"))
    );
  }

}
