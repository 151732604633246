import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { httpService } from '../../common/services/http.service';
import { EmployeeShiftAssignment } from '../resources/employee-shift-assignment';
import { MbscCalendarEvent, MbscEventcalendarOptions} from '@mobiscroll/angular';
import { Shift } from '../resources/shift';
import { ResourceEmployee } from '../resources/resource-employee';
import { User } from '../../common/resources/user';
import { auxString } from '../../../../util/auxString';


@Injectable({
  providedIn: 'root',
})
export class EmployeeShiftAssignmentService extends httpService {
  private apiBase: string = 'api/EmployeeShiftAssignment';
  private apiUrl: string;

  private arrAvailableShifts: Shift[];
  private valueIndex_Shift: number = 0;
  private valueIndex_ShiftAssginmentRecurrenceId: number = 1;
  private valueIndex_ShiftAssginmentRecurernceEndOption: number = 2;


  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "EmployeeShiftAssignment";

    this.apiUrl = this.baseUrl + this.apiBase;

    this.getAvailableShifts().subscribe(data => {
      this.arrAvailableShifts = data;
      //console.log("constructor() - getAvailableShifts() - data.length= " + data.length);
    });

  }


  getEmployeeShiftAssignments(): Observable<EmployeeShiftAssignment[]> {
    return this.http.get<EmployeeShiftAssignment[]>(this.apiUrl)
      .pipe(
        //tap(data => console.log('getEmployeeShiftAssignments() - data.length= ' + data.length)),
        //tap(data => console.log('getEmployeeShiftAssignments() - data= ' + JSON.stringify(data))),
        catchError(this.handleError('getEmployeeShiftAssignments', []))
      );
  }

  public map_ArrEmployeeShiftAssignments_To_ArrMbscCalendarEvent(arrEmployeeShiftAssignment: EmployeeShiftAssignment[]) {
    var arrAssignedShifts: MbscCalendarEvent[] = [];

    arrEmployeeShiftAssignment.forEach(z => {
      var mbscCalendarEvent: MbscCalendarEvent = {};
       
      mbscCalendarEvent.id = z.employeeShiftAssignmentId;
      mbscCalendarEvent.start = new Date(z.startDate.toString() + 'Z'); // concatenate to indicate datetime comes in UTC
      mbscCalendarEvent.end = new Date(z.endDate.toString() + 'Z');
      mbscCalendarEvent.title = z.title;
      mbscCalendarEvent.resource = z.resourceId.toLocaleUpperCase();
      mbscCalendarEvent.color = (z.shift != null && z.shift.color!= null )? z.shift.color : '';
      mbscCalendarEvent.notes = z.notes;
      mbscCalendarEvent.x = [z.shift];

      //Recurrence
      if (z.shiftAssignmentRecurrence != null) {
        mbscCalendarEvent.recurring = {};
        mbscCalendarEvent.recurring.repeat = z.shiftAssignmentRecurrence.repeat;
        mbscCalendarEvent.recurring.interval = z.shiftAssignmentRecurrence.repeatInterval;
        mbscCalendarEvent.recurring.weekDays = z.shiftAssignmentRecurrence.weekDays;
        mbscCalendarEvent.recurring.count = z.shiftAssignmentRecurrence.countMax;
        mbscCalendarEvent.recurring.from = z.shiftAssignmentRecurrence.startDate;
        mbscCalendarEvent.recurring.until = z.shiftAssignmentRecurrence.untilDate;

        mbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurrenceId] = [z.shiftAssignmentRecurrence.shiftAssignmentRecurrenceId];
        mbscCalendarEvent.x[this.valueIndex_ShiftAssginmentRecurernceEndOption] = z.shiftAssignmentRecurrence.recurrenceEndOption;
      }

      arrAssignedShifts.push(mbscCalendarEvent);
    });
    return arrAssignedShifts;
  }

  public buildDateTimeObject(paramStartDate: any, paramStartTime: string, paramEndDate: any, paramEndTime: string): [Date, Date] {
    var arrDateTime: [Date, Date];

    //Set start and end time:
    if (paramStartDate != undefined) {
      var myStartDate: Date = new Date(paramStartDate.toString());
      var monthNumber = myStartDate.getMonth() + 1;
      var myStartTime: Date = new Date(myStartDate.getFullYear() + "-" + auxString.padLeft(monthNumber.toString(), 2) + "-" + auxString.padLeft(myStartDate.getDate().toString(), 2) + "T" + paramStartTime.toString());
      var myStartDateTime = new Date(Number(myStartDate.getFullYear()), Number(myStartDate.getMonth()), Number(myStartDate.getDate()), myStartTime.getHours(), myStartTime.getMinutes(), 0);
      //console.log("onSelectionChangeShift() - myStartDateTime = " + myStartDateTime.toString());
    }
    
    var adjustDateToFollowingDay: number = 0;
    if (paramStartTime > paramEndTime) {
      adjustDateToFollowingDay = 1;
      console.log("buildDateTimeObject() - paramStartTime > paramEndTime ");
    }

    if (paramEndDate != undefined) {
      var myEndDate: Date = new Date(paramEndDate.toString());
      myEndDate.setDate(myEndDate.getDate() + adjustDateToFollowingDay);
      var monthNumber = myEndDate.getMonth() + 1;
      var myEndTime: Date = new Date(myEndDate.getFullYear() + "-" + auxString.padLeft(monthNumber.toString(), 2) + "-" + auxString.padLeft(myEndDate.getDate().toString(), 2) + "T" + paramEndTime.toString());
      var myEndDateTime = new Date(Number(myEndDate.getFullYear()), Number(myEndDate.getMonth()), Number(myEndDate.getDate()), myEndTime.getHours(), myEndTime.getMinutes(), 0);
      //console.log("onSelectionChangeShift() - myEndDateTime = " + myEndDateTime.toString());
    }
    
    arrDateTime = [myStartDateTime, myEndDateTime];
    console.log("buildDateTimeObject() - arrDateTime= " + JSON.stringify(arrDateTime));

    return arrDateTime;
  }


   

  getAvailableShifts(): Observable<Shift[]> {
    return this.http.get<Shift[]>(this.baseUrl + 'api/Shift')
      .pipe(
        //tap(data => console.log('getAvailableShifts() - data.length= ' + data.length)),
        //tap(data => console.log('getAvailableShifts() - data= ' + JSON.stringify(data))),
        catchError(this.handleError('getAvailableShifts', []))
      );
  }


  public map_ArrUser_To_ArryResourcesEmployee(arrUser: User[]) {
    var myResourcesEmployee: ResourceEmployee[] = [];

    arrUser.forEach(x => {
      var resourceEmployee = new ResourceEmployee();
      resourceEmployee.id = x.userId.toLocaleUpperCase();
      resourceEmployee.name = x.fullName;
      resourceEmployee.color = '';
      resourceEmployee.title = x.title;
      resourceEmployee.img = x.userProfilePhotoPath != null ? x.userProfilePhotoPath : '';
      resourceEmployee.scheduledHours = '0';  //TODO !!!! 
      resourceEmployee.maxHours = '40'; //TODO !!!! 

      myResourcesEmployee.push(resourceEmployee);
    });

    return myResourcesEmployee;
  }


  getCalendarBlockedOutDates() {

    var myInvalids = [{
      start: '2021-08-17T00:00',
      end: '2021-08-17T23:59',
      resource: 4,
      title: 'invalid title test 1'
    }, {
      start: '2021-08-15T00:00',
      end: '2021-08-15T23:59',
      resource: 2
    }, {
      recurring: {
        repeat: 'weekly',
        weekDays: 'SA,SU',
        title: 'no valid working hours test 2'
      }
    }];

    return myInvalids;
  }

   
  addEmployeeShiftAssignment(employeeShiftAssignment: EmployeeShiftAssignment): Observable<EmployeeShiftAssignment> {

    const url = `${this.apiUrl}/new`;

    return this.http.post<EmployeeShiftAssignment>(url, employeeShiftAssignment).pipe(
      //tap(data => console.log(`addEmployeeShiftAssignment() - Title=${employeeShiftAssignment.title}`)),
      catchError(this.handleError<EmployeeShiftAssignment>(`addEmployeeShiftAssignment() - Name=${employeeShiftAssignment.title}`))
    );
  }

  updateEmployeeShiftAssignment(employeeShiftAssignment: EmployeeShiftAssignment): Observable<EmployeeShiftAssignment> {

    if (employeeShiftAssignment.employeeShiftAssignmentId != null && employeeShiftAssignment.employeeShiftAssignmentId > 0) {
      const url = `${this.apiUrl}/${employeeShiftAssignment.employeeShiftAssignmentId}`;

      return this.http.put<EmployeeShiftAssignment>(url, employeeShiftAssignment).pipe(
        //tap(data => console.log(`updateEmployeeShiftAssignment() - id=${employeeShiftAssignment.employeeShiftAssignmentId}`)),
        catchError(this.handleError<EmployeeShiftAssignment>(`updateEmployeeShiftAssignment() - id=${employeeShiftAssignment.employeeShiftAssignmentId}`))
      )
    }
  }

  deleteEmployeeShiftAssignment(id: string): Observable<EmployeeShiftAssignment> {

    if (id != null && id != '') {
      const url = `${this.apiUrl}/${id}`;

      return this.http.delete<EmployeeShiftAssignment>(url).pipe(
        //tap(data => console.log(`deleteEmployeeShiftAssignment() - id=${id}`)),
        catchError(this.handleError<EmployeeShiftAssignment>(`deleteEmployeeShiftAssignment() - id=${id}`))
      )
    }
  }



}
