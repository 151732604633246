export interface PricingBreakdownSubitem {
  name: string,
  id?: string,
  calculation?: string,
  value?: number,
  displayValue?: string
}

export interface PricingBreakdownItem {
  name: string,
  price: number,
  subitems: PricingBreakdownSubitem[],
  itemId: string
}

export interface PricingBreakdown {
  material: PricingBreakdownItem,
  labor: PricingBreakdownItem[],
  process: PricingBreakdownItem[],
  purchasedItems: PricingBreakdownItem[],
}

export function doAltText(text: string, alt: string) {
  return `<abbr title="${alt}">${text}</abbr>`
}
