import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FloorService } from '../../services/floor.service';
import { ChartComponent } from '../../../common/components/chart/chart.component';

@Component({
  selector: 'department-future-schedule',
  templateUrl: './department-future-schedule.component.html',
  styleUrls: ['./department-future-schedule.component.less']
})
export class DepartmentFutureScheduleComponent implements OnInit, OnChanges {
  @Input() floorBuildingId: string = null;
  @Input() days: number = 30;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() shiftHours: number = 8;
  @Input() shiftCount: number = 1;
  @Input() machineCount: number = 1;

  public saving: boolean = false;

  private futureRawData: any[];
  public futureData: any[];
  public futureLabels: string[];
  public chartOptions: any;
  public plugins: any[];

  private dragData: any = {};

  constructor(private floorSvc: FloorService) { }

  public bubbleClick(e: any) {
    this.onClick.emit(e);
  }

  public reload(): void {
    this.setupChart();
  }

  private setupChart(): void {
    var og = this;

    this.chartOptions = {
      responsive: true,
      legend: {
        display: false
      },
      aspectRatio: 4,
      scales: {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }]
      },
      hover: {
        onHover: function (e, el) {
          var section = el[0];

          if (og.dragData.mouseData) {
            e.currentTarget.style.cursor = 'grab';
            return;
          }

          e.currentTarget.style.cursor = (section && section._model.backgroundColor != 'transparent') ? 'pointer' : 'default';
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + Number(tooltipItem.yLabel).toFixed(2);
          }
        }
      },
      plugins: {
        labels: {
          render: () => { }
        }
      }
    };

    this.plugins = [{
      afterDatasetDraw: function (chart, options) {
        og.drawValues(chart);

        og.drawShiftLines(chart);

        og.showExternalDragData(chart);
      },
      afterInit: function (chart, _options) {
        chart.canvas.onmousedown = function (e: MouseEvent): void { ; }; //We won't support startting drag from this chart
        chart.canvas.onmouseup = function (e: MouseEvent): void { og.mouseUp(e, chart); };
        chart.canvas.onmousemove = function (e: MouseEvent): void { og.mouseMove(e, chart); };
        chart.canvas.onmouseleave = function (e: MouseEvent): void { og.mouseUp(e, chart); }; //We won't support dragging data OUT OF this chart
        chart.canvas.onmouseenter = function (e: MouseEvent): void { og.mouseEnter(e, chart) };
      }
    }];

    this.floorSvc.getFloorBuildingFutureData(this.floorBuildingId).subscribe(result => {
      this.futureRawData = result;

      this.getFutureLabels();
      this.getFutureData();
    });
  }

  private showExternalDragData(chart: any) {
    if (this.dragData.fromExternal) {
      chart.ctx.beginPath();
      chart.ctx.fillStyle = this.dragData.dataPoint.backgroundColor;
      chart.ctx.strokeStyle = this.dragData.dataPoint.borderColor;
      chart.ctx.fillRect(this.dragData.boxElement._view.x - (this.dragData.boxElement._view.width / 2), chart.scales["y-axis-0"].bottom, this.dragData.boxElement._view.width, (chart.scales["y-axis-0"].bottom - chart.scales["y-axis-0"].top) * -0.9);
      chart.ctx.strokeRect(this.dragData.boxElement._view.x - (this.dragData.boxElement._view.width / 2), chart.scales["y-axis-0"].bottom, this.dragData.boxElement._view.width, (chart.scales["y-axis-0"].bottom - chart.scales["y-axis-0"].top) * -0.9);
    }
  }

  private drawShiftLines(chart): void {
    var max = chart.scales['y-axis-0'].end;

    var drawLineAt = function (value: number, color: string) {
      var valueRatio = value / max;
      var valueOffset = chart.scales['y-axis-0'].bottom - (valueRatio * chart.scales['y-axis-0'].height);

      chart.ctx.beginPath();
      chart.ctx.strokeStyle = color;
      chart.ctx.moveTo(chart.scales['x-axis-0'].left, valueOffset);
      chart.ctx.lineTo(chart.scales['x-axis-0'].right, valueOffset);
      chart.ctx.stroke();
    };

    var totalShiftHours: number = this.shiftHours * this.shiftCount * this.machineCount;
    drawLineAt(totalShiftHours, '#ff9900');
  }

  private drawValues(chart): void {
    var canvas = chart.ctx;
    canvas.font = 'Arial Bold';
    canvas.textAlign = 'center';
    canvas.textBaseline = 'bottom';

    chart.data.datasets.forEach(function (dataset, i) {
      var meta = chart.controller.getDatasetMeta(i);
      meta.data.forEach(function (bar, index) {
        var data = dataset.data[index];
        if (!data || data == 0) return;
        var position = bar._model.y + 25;
        canvas.fillStyle = "#fff";
        if (bar._model.base - 25 < position) {
          position = bar._model.base - 15;
          canvas.fillStyle = "#999";
          canvas.strokeStyle = "#eee";
          canvas.strokeText(Math.round(data * 10) / 10, bar._model.x, position);
        }

        canvas.fillText(Math.round(data * 10) / 10, bar._model.x, position);
      });
    });
  }

  private getFutureLabels(): void {
    var labels = [];

    var date = new Date();
    var days = this.days;

    while (days-- >= 0) {
      labels.push(date.toLocaleDateString());
      date.setDate(date.getDate() + 1);
    }

    this.futureLabels = labels;
  }

  private getFutureData(): void {

    this.futureData = [];
    var totalShiftHours: number = this.shiftHours * this.shiftCount * this.machineCount;

    var data = {
      overdueAssignedHours: [],
      overdueUnassignedHours: [],
      regularAssignedHours: [],
      regularUnassignedHours: [],
      overtimeAssignedHours: [],
      overtimeUnassignedHours: [],
    };

    for (var i = 0; i < this.futureLabels.length; i++) {
      var overdueAssignedHours = i > 0 ? 0 : this.futureRawData.filter(d => new Date(d.day).valueOf() <= new Date().setHours(0, 0, 0, 0) && d.hasValue == true).reduce((total, value, index) => { return total + value.hours }, 0);
      var overdueUnassignedHours = i > 0 ? 0 : this.futureRawData.filter(d => new Date(d.day).valueOf() < new Date().setHours(0, 0, 0, 0) && d.hasValue == false).reduce((total, value, index) => { return total + value.hours }, 0);
      var regularAssignedHours = this.futureRawData.filter(d => new Date(d.day).valueOf() == new Date(this.futureLabels[i]).valueOf() && d.hasValue == true).reduce((total, value, index) => { return total + value.hours }, 0);
      var regularUnassignedHours = this.futureRawData.filter(d => new Date(d.day).valueOf() == new Date(this.futureLabels[i]).valueOf() && d.hasValue == false).reduce((total, value, index) => { return total + value.hours }, 0);
      var overtimeAssignedHours = null;
      var overtimeUnassignedHours = null;

      if ((overdueAssignedHours) + (regularAssignedHours) > totalShiftHours) {
        overtimeAssignedHours = ((overdueAssignedHours) + (regularAssignedHours)) - totalShiftHours;
        if (overdueAssignedHours >= totalShiftHours) {
          overdueAssignedHours -= overtimeAssignedHours
        }
        else {
          var tmp = overdueAssignedHours;
          overdueAssignedHours = 0;
          regularAssignedHours -= (overtimeAssignedHours - tmp);
        }
      }
      if ((overdueUnassignedHours) + (regularUnassignedHours) > totalShiftHours) {
        overtimeUnassignedHours = ((overdueUnassignedHours) + (regularUnassignedHours)) - totalShiftHours;
        if (overdueUnassignedHours >= totalShiftHours) {
          overdueUnassignedHours -= overtimeUnassignedHours
        }
        else {
          var tmp = overdueUnassignedHours;
          overdueUnassignedHours = 0;
          regularUnassignedHours -= (overtimeUnassignedHours - tmp);
        }
      }
      if ((overdueUnassignedHours) + (regularAssignedHours) > totalShiftHours) {
        overtimeUnassignedHours = ((overdueUnassignedHours) + (regularAssignedHours)) - totalShiftHours;
        if (overdueUnassignedHours >= totalShiftHours) {
          overdueUnassignedHours -= overtimeUnassignedHours
        }
        else {
          var tmp = overdueUnassignedHours;
          overdueUnassignedHours = 0;
          regularAssignedHours -= (overtimeUnassignedHours - tmp);
        }
      }

      data.overdueUnassignedHours.push(overdueUnassignedHours);
      data.overdueAssignedHours.push(overdueAssignedHours);
      data.regularUnassignedHours.push(regularUnassignedHours);
      data.regularAssignedHours.push(regularAssignedHours);
      data.overtimeUnassignedHours.push(overtimeUnassignedHours);
      data.overtimeAssignedHours.push(overtimeAssignedHours);
    }

    this.futureData.push({
      label: "Overdue",
      backgroundColor: ChartComponent.getColor(1),
      borderColor: ChartComponent.getColor(0),
      borderWidth: 1,
      data: data.overdueAssignedHours
    });

    this.futureData.push({
      label: "Overdue, Unassigned",
      backgroundColor: ChartComponent.getColor(1) + "99",
      borderColor: ChartComponent.getColor(0),
      borderWidth: 1,
      data: data.overdueUnassignedHours
    });

    this.futureData.push({
      label: "Regular Hours",
      backgroundColor: ChartComponent.getColor(1),
      data: data.regularAssignedHours
    });

    this.futureData.push({
      label: "Regular Hours, Unassigned",
      backgroundColor: ChartComponent.getColor(1) + "99",
      data: data.regularUnassignedHours
    });

    this.futureData.push({
      label: "Overtime Hours",
      backgroundColor: ChartComponent.getColor(0),
      data: data.overtimeAssignedHours
    });

    this.futureData.push({
      label: "Overtime Hours, Unassigned",
      backgroundColor: ChartComponent.getColor(0) + "99",
      data: data.overtimeUnassignedHours
    });
  }

  private mouseEnter(e: MouseEvent, chart: any): void {
    const dataKey: string = 'IntraChartData';
    if (sessionStorage.getItem(dataKey)) {
      var data = JSON.parse(sessionStorage.getItem(dataKey));
      sessionStorage.removeItem(dataKey);

      const acceptedSources: string[] = ["DepartmentTodayScheduleComponent"];

      if (acceptedSources.findIndex(s => s == data.source) < 0) {
        //only accept data from the above charts...
        return;
      }

      this.dragData.fromExternal = true;

      this.dragData.dataPoint = {
        workOrderId: data.data.workOrderId,
        machineId: data.data.machineId,
        day: data.scheduledStart,
        backgroundColor: data.backgroundColor,
        borderWidth: data.borderWidth,
        borderColor: data.borderColor,
        data: data.data
      };

      this.dragData.mouseData = {
        x: e.x, y: e.y
      };

      var width: number = 0.75 * (chart.scales["x-axis-0"]._gridLineItems[1].x1 - chart.scales["x-axis-0"]._gridLineItems[0].x1);
      this.dragData.dataPoint.originalBackgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement = {};
      this.dragData.boxElement._view = {
        x: this.dragData.mouseData.x,
        y: this.dragData.mouseData.y,
        width: width
      };
      this.dragData.boxElement._model = this.dragData.boxElement._view;
      this.dragData.boxElement.originalX = e.offsetX;
      this.dragData.boxElement.originalY = e.offsetY;
    }
  }

  private mouseUp(e: MouseEvent, chart: any): void {
    if (!this.futureData) return;

    if (this.dragData.dataPoint) {
      this.dragData.dataPoint.backgroundColor = this.dragData.dataPoint.originalBackgroundColor;

      if (this.dragData.fromExternal) {
        if (e.offsetY < 0) {
          this.dragData = {};

          chart.update();
          return;
        }

        this.dragData.dataPoint.day = new Date(this.dragData.dropOnDay);
        const droppedData = JSON.parse(JSON.stringify(this.dragData.dataPoint));

        if (droppedData.machineId == null) {
          //TODO: bring up the window!
          debugger;
        }
        else {
          this.saving = true;
          const oldDay = new Date(this.dragData.dataPoint.day);

          this.floorSvc.updateWorkOrderSchedule(droppedData.machineId, droppedData.workOrderId, oldDay, new Date(this.dragData.dropOnDay)).subscribe(_ => {

            this.floorSvc.getFloorBuildingFutureData(this.floorBuildingId).subscribe(result => {
              this.futureRawData = result;

              this.getFutureLabels();
              this.getFutureData();

              this.onClick.emit({ event: e, chart: chart, clickedElement: [droppedData] });
              this.saving = false;
            });
          });
        }

      }
      else if (this.dragData.dropOnDay) {
        //Not supported...
      }
    }

    this.dragData = {};

    chart.update();
  }

  private mouseMove(e: MouseEvent, chart: any): void {
    if (!this.futureData) return;
    var self = this;
    var getNearest = function (x: number, c: any): number {
      const dayWidth = Math.floor(c.scales['x-axis-0'].width / self.days) - 1;
      var day = Math.floor(x / dayWidth) - 1;
      const offset = (self.dragData.boxElement._view.width / 2) + ((dayWidth - self.dragData.boxElement._view.width) / 2);

      if (!c.scales['x-axis-0']._labelItems[day]) day = 0;

      self.dragData.dropOnDay = c.scales['x-axis-0']._labelItems[day].label;

      return c.scales['x-axis-0']._gridLineItems[day].x1 + offset;
    }

    if (this.dragData.mouseData) {
      this.dragData.dataPoint.backgroundColor = ChartComponent.shade(0.4, this.dragData.dataPoint.originalBackgroundColor, "#FFFFFF", true) + "66";

      this.dragData.boxElement._view.backgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement._view.x = getNearest(this.dragData.boxElement.originalX + (e.x - this.dragData.mouseData.x), chart);
      this.dragData.boxElement._view.borderSkipped = "none";

      this.dragData.boxElement._model.backgroundColor = this.dragData.boxElement._view.backgroundColor;
      this.dragData.boxElement._model.x = this.dragData.boxElement._view.x;
      this.dragData.boxElement._model.borderSkipped = "none";

      chart.render({
        duration: 0,
        lazy: false,
        easing: 'easeOutBounce'
      });
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupChart();
  }

  ngOnInit(): void {
    this.setupChart();
  }
}
