<div *ngIf="!results" class="center-spinner">
  <mat-spinner [color]="'warn'"></mat-spinner>
</div>
<search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)" emptySetMessage="-- No Alerts --">
  <thead header-template>
    <tr class="small text-uppercase text-muted">
      <th>
        Message
      </th>
      <th>
        Machine
      </th>
      <th>
        Date
      </th>
    </tr>
  </thead>
  <ng-template let-row>
    <td class="small">
      <i class="fas fa-fw fa-lg" [ngClass]="{'text-danger fa-exclamation-triangle': (row.priority==1), 'text-info fa-long-arrow-alt-down': (row.priority==3)}"></i>
      {{row.message}}
    </td>
    <td class="small">{{row.machine?.name || '--'}}</td>
    <td class="small">{{row.date | date: 'MM-dd HH:mm'}}</td>
  </ng-template>
</search-result>
