<div class="pt-3">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">

    <div class="p-0 m-0 row">
      <div class="col-4">
        <material-spec-list (selected)="select($event)"></material-spec-list>
        <div class="mt-2">
          <div class="text-right">
            <button class="btn btn-success" (click)="add()">
              <i class="fas fa-fw la-lg fa-plus"></i>
              Add Material Specification
            </button>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div *ngIf="!selected">
          <p class="text-muted text-center font-italic p-5">-- Select a Material Specification to View its Details --</p>
        </div>
        <div *ngIf="selected">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Specification Name</label>
                  <input [(ngModel)]="selected.name" class="form-control" placeholder="Specification Name" />
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-4 pt-4">
            <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
              <i class="fas fa-fw fa-lg fa-minus-circle"></i>
              Delete
            </button>
            <button class="btn btn-success" [disabled]="!selected.name" (click)="save()">
              <i class="fas fa-fw fa-lg fa-save"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
