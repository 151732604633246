import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';
import { FloorBuilding, Department, FloorMachine } from '../../resources/building';
import { NavigationService } from '../../../common/services/navigation.service';
import { FloorService } from '../../services/floor.service';

@Component({
  selector: 'operator-view',
  templateUrl: './operator-view.component.html',
  styleUrls: ['./operator-view.component.less']
})
export class OperatorViewComponent implements OnInit {
  public user: User;
  public selectedDepartment: FloorBuilding = null;
  public selectedBuilding: Department = null;
  public selectedMachine: FloorMachine = null;
  public buildingList: Department[] = null;
  public departmentList: FloorBuilding[] = null;
  public machineList: FloorMachine[] = null;
  private storageKey: string = "OpertorMachineSelection";
  
  constructor(private usrSvc: UserService, private navService: NavigationService, private floorSvc: FloorService) {
    navService.setPageTitle("Operator");

    this.getStoredMachine();
  }

  private getStoredMachine(): void {
    var stored = localStorage.getItem(this.storageKey);

    if(stored != null){
      var set = JSON.parse(stored);
      if(set.Building) this.setBuilding(set.Building);
      if(set.Department) this.setDepartment(set.Department);
      if(set.Machine) this.setMachine(set.Machine);
    }
  }

  private storeMachineSelection(): void {
    var stored = {Building: this.selectedBuilding, Department: this.selectedDepartment, Machine: this.selectedMachine};

    localStorage.setItem(this.storageKey, JSON.stringify(stored));
  }

  public resetMachine(): void {
    this.selectedDepartment = null;
    this.selectedBuilding = null;
    this.selectedMachine = null;

    this.departmentList = null;
    this.machineList = null;

    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb(`Welcome ${this.user.fullName}`);

    this.storeMachineSelection();
  }

  public setDepartment(d: FloorBuilding): void {
    this.navService.popBreadCrumb();
    this.navService.pushBreadcrumb(d.name);
    this.navService.pushBreadcrumb("Select Machine");

    this.selectedDepartment = d;

    this.floorSvc.getFloorBuilding(d.floorBuildingId).subscribe(r => {
      this.machineList = r.machines;
    });

    this.storeMachineSelection();
  }

  public setBuilding(b: Department): void {
    this.navService.popBreadCrumb();
    //this.navService.pushBreadcrumb(this.user.fullName);
    this.navService.pushBreadcrumb(b.name);
    this.navService.pushBreadcrumb("Select Department");

    this.selectedBuilding = b;

    this.floorSvc.get(b.departmentId).subscribe(r => {
      this.departmentList = r.floorBuildings;
    });

    this.storeMachineSelection();
  }

  public setMachine(m: FloorMachine): void {
    this.navService.popBreadCrumb();

    this.selectedMachine = m;

    this.storeMachineSelection();
  }

  public getUserData(): void {
    this.usrSvc.user.subscribe(u => {
      //this.selectedBuilding = null;
      //this.selectedDepartment = null;
      //this.selectedMachine = null;

      this.user = u;

      //this.resetMachine();
    });
  }

  public onBadMachine() {
    this.selectedBuilding = null;
    this.selectedDepartment = null;
    this.selectedMachine = null;
  }

  ngOnInit(): void {
    this.getUserData();
    this.floorSvc.search().subscribe(r => {
      if (r.successful) {
        this.buildingList = r.results;
      }
    });
  }
}
