<search-list-new
  matSort
  [defaultSort]="{
    id: 'purchaseOrderNumber',
    start: 'desc'
  }"
  [showManagerToggle]="hasPermission('PurchasingManager')"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['poNumber', 'vendor', 'assigned', 'status', 'datePlaced', 'dueDate', 'items', 'wos']"
  (itemSelected)="onSelect($event)"
  [fieldSearchFields]="[
    { field: 'vendor', code: 'supplier' },
    { field: 'vendor', code: 'vendor' },
    { field: 'purchaseOrderNumber', code: 'po' },
    { field: 'purchaseOrderNumber', code: 'number' },
    { field: 'workOrderNumber', code: 'wo' }
  ]"
  [fieldSearchNames]="{
    vendor: 'Supplier',
    purchaseOrderNumber: 'PO #',
    workOrderNumber: 'Work Order Number'
  }"
  localStorageId="purchasing-queue"
  [isManager]="userIsManager.bind(this)"
  [fieldsToPersist]="['forAllUsers']"
>
  <button mat-fab color="primary" routerLink="/purchasing/order/new" floatingButton><mat-icon>add</mat-icon></button>
  <ng-container matColumnDef="poNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="purchaseOrderNumber">PO #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.purchaseOrderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="vendor">
    <mat-header-cell *matHeaderCellDef mat-sort-header="vendor">Supplier</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.vendorName}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="items">
    <mat-header-cell *matHeaderCellDef>Items</mat-header-cell>
    <mat-cell *matCellDef="let row">
        <item-type-chip class="mx-1" [iconOnly]="true" [override]="'MATERIAL'" *ngIf="row.hasMaterial"></item-type-chip>
        <item-type-chip class="mx-1" [iconOnly]="true" [override]="'PROCESS'" *ngIf="row.hasProcess"></item-type-chip>
        <item-type-chip class="mx-1" [iconOnly]="true" [override]="'PURCHASED'" *ngIf="row.hasPurchased"></item-type-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned">Assigned To</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.assignedUserName" class="d-flex align-items-center text-muted font-weight-bold">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.assignedUserName }}</span>
      </span>
      <span *ngIf="!row.assignedUserName" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="datePlaced">
    <mat-header-cell *matHeaderCellDef mat-sort-header="datePlaced" style="flex-grow: 0; flex-basis: 7.5%;">Date Placed</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%;">
      {{row.datePlaced | date }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dateRequired" style="flex-grow: 0; flex-basis: 7.5%;">Date Required</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 7.5%;">
      {{row.dateRequired | date }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="wos">
    <mat-header-cell *matHeaderCellDef>WO #s</mat-header-cell>
    <mat-cell *matCellDef="let row">
        <mat-chip-list *ngIf="row.workOrderNumbers.length">
            <mat-chip *ngFor="let woNumber of row.workOrderNumbers" [selectable]="true" class="small">
                {{woNumber}}
            </mat-chip>
        </mat-chip-list>
        <span *ngIf="row.workOrderNumbers.length === 0" class="text-muted small font-italic">
            None
        </span>
    </mat-cell>
  </ng-container>
</search-list-new>