import { Vendor } from "../../supplier/resources/vendor";
import { MaterialBid } from "./materialBid";
import { InventoryItem } from "../../inventory/resources/inventory-item";
import { Customer, CustomerAddress } from "../../customer/resources/customer";
import { Building } from "../../floor/resources/building";
import { User } from "../../common/resources/user";
import { ShippingCarrier, ShippingTicket } from "../../shipping/resources/shipping-ticket";
import { Address } from "../../common/resources/address";
import { Contact } from "../../common/resources/contact";
import { Station } from "../../order/resources/station";
import { Material } from "../../order/resources/material";
import { Product } from "../../order/resources/product";
import { PurchasedItem, PurchasedItemPartNumber } from "../../order/resources/purchased-item";
import { Company } from "../../common/resources/company";
import { WorkOrder } from "../../planning/resources/work-order";
import { OutsideProcessDescription } from "./outsideProcessDescription";

export class PurchaseOrderStatus {
  static COMPLETE: number = 0;
  static RECEIVED: number = 1;
  static SHIPPED: number = 2;
  static IN_PROCESS: number = 3;
  static AWAITING_REVIEW: number = 4;
  static REJECTED: number = 5;
  static PARTIALLY_RECEIVED: number = 6;


  static getStatusColorClass(status: number): string {
    switch (status) {
      case PurchaseOrderStatus.COMPLETE: return 'badge-success';
      case PurchaseOrderStatus.RECEIVED: return 'badge-success';
      case PurchaseOrderStatus.SHIPPED: return 'badge-warning';
      case PurchaseOrderStatus.IN_PROCESS: return 'badge-secondary';
      case PurchaseOrderStatus.AWAITING_REVIEW: return 'badge-info';
      case PurchaseOrderStatus.PARTIALLY_RECEIVED: return 'badge-warning';
      default: return null;
    }
  }

  static getStatusBackgroundColorClass(status: number): string {
    switch (status) {
      case PurchaseOrderStatus.REJECTED: return 'bg-danger text-white';
      case PurchaseOrderStatus.COMPLETE: return 'bg-success text-white';
      case PurchaseOrderStatus.RECEIVED: return 'bg-success text-white';
      case PurchaseOrderStatus.SHIPPED: return 'bg-warning';
      case PurchaseOrderStatus.IN_PROCESS: return 'bg-secondary';
      case PurchaseOrderStatus.AWAITING_REVIEW: return 'bg-info text-white';
      case PurchaseOrderStatus.PARTIALLY_RECEIVED: return 'bg-warning';
      default: return null;
    }
  }

  static getStatusText(status: number): string {
    switch (status) {
      case PurchaseOrderStatus.COMPLETE: return 'Fulfilled';
      case PurchaseOrderStatus.RECEIVED: return 'Received';
      case PurchaseOrderStatus.SHIPPED: return 'Awaiting Shipment';
      case PurchaseOrderStatus.IN_PROCESS: return 'Work in Progress';
      case PurchaseOrderStatus.AWAITING_REVIEW: return 'Awaiting Review';
      case PurchaseOrderStatus.REJECTED: return 'Rejected';
      case PurchaseOrderStatus.PARTIALLY_RECEIVED: return 'Partially Received';
      default: return null;
    }
  }

  static isEditable(status: number): boolean {
    return status != PurchaseOrderStatus.COMPLETE &&
      status != PurchaseOrderStatus.RECEIVED
      ;
  }
}

export class PurchaseOrderDestination {
  static CustomAddress = 0;
  static OurBuilding = 1;
  static Customer = 2;

  static getDestinationText(status: number): string {
    switch (status) {
      case PurchaseOrderDestination.CustomAddress: return "Custom Address";
      case PurchaseOrderDestination.OurBuilding: return "One of our buildings";
      case PurchaseOrderDestination.Customer: return "A customer's address";
      default: return null;
    }
  }

}

export interface PurchaseOrder {
  purchaseOrderId: string;
  purchaseOrderNumber: string;

  vendorOrderNumber: string;

  datePlaced: Date; // "order date"
  dateExpected?: Date; // "promise date"
  dateRequired?: Date;
  shipDate?: Date;

  paymentTerms: string;
  fobTerms: string;

  process: number;
  status: number;
  statusNote: string;
  vendorId: string;
  void: boolean;
  note: string;
  privateNote: string;
  voidedByUserId: string;
  voidNote: string;
  assignedTo?: string;
  approvedBy?: string;
  approvedOn?: Date;
  requisitioner: string;
  companyId: string;

  destinationType: number;

  destinationBuildingId?: string;
  destinationBuilding: Building;

  destinationCustomerId?: string;
  destinationCustomerAddressId?: string;
  destinationCustomer: Customer;
  destinationCustomerAddress: CustomerAddress;

  // custom address
  
  shippingAttention: string;
  shippingAddr1: string;
  shippingAddr2: string;
  shippingCity: string;
  shippingState: string;
  shippingPostalCode: string;

  shippingCarrierId: string;
  shippingCarrier: ShippingCarrier;
  vendorShippingAccountNumber: string;

  vendorAddressId?: string;
  vendorAddress?: Address;

  shippingTaxable: boolean;
  taxRate: number;

  contactId?: string;
  contact?: Contact;

  shippingMethod: string;
  shippingHandlingFees: number;
  shippingAccountNumber: string;

  assignedUser?: User;
  vendor: Vendor;
  company: Company;

  purchaseOrderMaterialBids?: PurchaseOrderMaterialBid[];
  purchaseOrderLineItems?: PurchaseOrderLineItem[];
  shippingTickets?: ShippingTicket[];

  readonly latestDocumentId?: string;
}

export interface PurchaseOrderMaterialBid {
  purchaseOrderMaterialBidId: string;
  purchaseOrderId: string;
  materialBidId: string;

  materialBid?: MaterialBid;
}

export interface PurchaseOrderLineItem {


  purchaseOrderLineItemId: string;
  purchaseOrderId: string;

  productId?: string;
  materialId?: string;
  stationId?: string;
  purchasedItemId?: string;
  purchasedItemPartNumberId?: string;
  outsideProcessDescriptionId?: string;
  workOrderId?: string;
  department?: string

  isMisc: boolean;
  miscDescription: string;

  materialBidId?: string;

  dueDate?: Date;
  quantity: number;
  lineNumber: number;
  mfgrUnitsQty?: number;

  taxable: boolean;
  unitPriceOverride?: number;


  purchaseOrder?: PurchaseOrder;

  station?: Station;
  material?: Material;
  product?: Product;
  purchasedItem?: PurchasedItem;
  purchasedItemPartNumber?: PurchasedItemPartNumber;
  workOrder?: WorkOrder;

  materialBid?: MaterialBid;
  outsideProcessDescription?: OutsideProcessDescription;

  shippingTickets?: ShippingTicket[];


  readonly name?: string;
}

export interface PseudoPurchasedItemPartNumber {
   purchasedItemId: string;
   description: string;
   purchasedItemPartNumberId?: string;
   partNumber: string;
   vendorId?: string;
   vendor: Vendor;
}