import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'purchasing-home',
  templateUrl: './purchasing-home.component.html',
  styleUrls: ['./purchasing-home.component.less']
})
export class PurchasingHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
