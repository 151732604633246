<loading *ngIf="!records"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<div class="container-fluid" *ngIf="records">
  <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Continue" (click)="done()"><i class="fas fa-check pt-2"></i></a>

  <div class="row mt-4">
    <div class="col">
      <h3 class="text-primary">Split Ticket Viewer</h3>
      <div class="form-group mt-4">
        <table class="table table-striped border small">
          <thead>
            <tr>
              <th>Ticket Number</th>
              <th>Qty</th>
              <th>Required</th>
              <th>Departed</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ticket of records">
              <td>
                <button (click)="openTicket(ticket)" class="btn btn-link btn-sm" *ngIf="ticket.shippingTicketId != ticketId; else thisTicket">
                  <i class="fas fa-fw fa-external-link-alt text-muted pr-2"></i>
                  {{getTicketNumber(ticket)}}
                </button>
                <ng-template #thisTicket>
                  <span title="Current Shipment" class="text-muted pl-4 ml-2">
                    {{getTicketNumber(ticket)}}
                  </span>
                </ng-template>
              </td>
              <td class="font-weight-bold">{{ticket.quantityRequired}}</td>
              <td>
                <span *ngIf="ticket.workOrder?.order?.requiredDate" class="badge required" [ngClass]="getRequiredColor(ticket.workOrder.order.requiredDate)">
                  {{ticket.workOrder.order.requiredDate | date}}
                  <span class="d-block small">{{weeksLeft(ticket.workOrder.order.requiredDate, true)||'--'}} Week(s) {{(weeksLeft(ticket.workOrder.order.requiredDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                </span>
              </td>
              <td>{{(ticket.departureDate | date) || '--'}}</td>
              <td>
                <span class="badge status text-uppercase p-2" [ngClass]="getStatusColorClass(ticket.status)">
                  {{getStatusText(ticket.status)}}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-right text-muted">Total</td>
              <td class="font-weight-bold" colspan="4">{{totalQuantityForWorkOrder}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
