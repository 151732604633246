import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../common/resources/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message';
import { MessageService } from '../../common/services/message.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { httpService } from '../../common/services/http.service';
import { SearchResult } from '../../common/resources/search-result';
import { Channel } from '../../notification/resources/notificationChannel'
import { NotificationConfiguration } from '../../notification/resources/notificationConfiguration'
import { NotificationRecipientGroup } from '../../notification/resources/notificationRecipientGroup'
import { environment } from '../../../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    "Access-Control-Allow-Headers": "Content-Type",
    'Content-Type': 'application/json',
    //'Access-Control-Allow-Origin': 'http://localhost:54296',
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
    "Access-Control-Allow-Credentials": "true"
  })
};


@Injectable({
  providedIn: 'root',
})

export class NotificationPreferredChannelService extends httpService {
  myAppUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl) {
    super(errorHandler, messages);
    this.serviceName = "NotificationPreferredChannel";

    this.myAppUrl = environment.notificationApiUrl;
  }

  getRecipientGroup(recipientId: string, notificationTypeId: string): Observable<NotificationRecipientGroup> {
    var result: any;

    if (recipientId != null && notificationTypeId != null) {
      return this.http.get<NotificationRecipientGroup>(this.myAppUrl + "/api/NotificationRecipientGroup/getRecipientGroup/" + recipientId + "/" + notificationTypeId)
        .pipe(
          //tap(data => console.log('getRecipientGroup() - fetched recipientGroups. recipientGroups.length= ' + data.length)),
          catchError(this.handleError<NotificationRecipientGroup>('getRecipientGroup', null))
        );
    }
  }

  getNotificationConfigurationsAlreadySetUp() {
    return this.http.get<NotificationConfiguration[]>(this.myAppUrl + "/api/NotificationConfiguration/getNotificationConfigurationsAlreadySetUp")
      .pipe(
        catchError(this.handleError('getNotificationConfigurationsAlreadySetUp', []))
      );
  }

  getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedTo(recipientId: string) {
    return this.http.get<NotificationConfiguration[]>(this.myAppUrl + "/api/NotificationConfiguration/getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedTo/" + recipientId)
      .pipe(
        catchError(this.handleError('getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedTo', []))
      );
  }


  getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedToWithPreferredChannels(recipientId: string) {
    return this.http.get<NotificationConfiguration[]>(this.myAppUrl + "/api/NotificationConfiguration/getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedToWithPreferredChannels/" + recipientId)
      .pipe(
        //tap(data => console.log('getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedToWithPreferredChannels() - data= ' + JSON.stringify(data) )),
        catchError(this.handleError('getNotificationConfigurationsAlreadySetUpAndRecipientIsSubscribedToWithPreferredChannels', []))
      );
  }

  public getAllNotificationChannels(): Observable<Channel[]> {

    return this.http.get<Channel[]>(this.myAppUrl + '/api/channel/', { headers: httpOptions.headers })
      .pipe(
        catchError(this.handleError<Channel[]>('getAllNotificationChannels', []))
      );
  }
   
  public getRecipientMySmsNumber(recipientId: string) {
    return this.http.get<string>(this.myAppUrl + '/api/recipient/GetRecipientMySmsNumber/' + recipientId, { headers: httpOptions.headers })
      .pipe(
        catchError(this.handleError<string>('getRecipientMySmsNumber', null))
      );
  }

  public updateRecipientMySmsNumber(recipientId: string, mySmsNumber: string) {
    return this.http.put(this.myAppUrl + "/api/recipient/UpdateRecipientMySmsNumber/" + recipientId + "/" + mySmsNumber, null)
      .pipe(
        catchError(this.handleError('updateRecipientMySmsNumber', null))
      );
  }
    
  public updateNotificationConfigurationPreferredChannels(recipientId, lNotificationConfiguration): Observable<any> {

    return this.http.put(this.myAppUrl + "/api/NotificationRecipientGroup/updateNotificationConfigurationPreferredChannels/" + recipientId, lNotificationConfiguration)
      .pipe(
        catchError(this.handleError('UpdateNotificationConfigurationPreferredChannels', []))
      );
  }



}
