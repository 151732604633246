import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderDetailService } from '../../order-detail.service';
import { Product } from '../../../../resources/product';

@Component({
  selector: 'product-estimating-review',
  templateUrl: './product-estimating-review.component.html',
  styleUrls: ['./product-estimating-review.component.less'],
})
export class ProductEstimatingReviewComponent implements OnInit, OnChanges {

  constructor(public service: OrderDetailService) { }

  private initIds() {
    if (!this.service.contractReviewAnswers) this.service.contractReviewAnswers = {};
    if (!this.service.contractReviewAnswers[this.product.productId]) this.service.contractReviewAnswers[this.product.productId] = {};
    if (!this.service.contractReviewAnswers[this.product.productId]['dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e']) this.service.contractReviewAnswers[this.product.productId]['dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e'] = {};
  }

  ngOnInit(): void {
    this.initIds();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) this.initIds();
  }

  @Input() product: Product;

  public answers = ['Yes', 'No', 'N / A'];

  public questions = [
  { contractReviewQuestionId: 'd8975684-aac9-4158-a117-ccdb3a2f6c0a', text: 'First Article Inspection Report', contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e'},
  { contractReviewQuestionId: '974b8b1b-0aa3-4d9d-8499-36eec9befa9e', text: 'PPAP Required', contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e' },
  { contractReviewQuestionId: 'a9bc3d37-25d3-4fa4-8d00-0479beecb5e3', text: 'MPS Plan', examples: 'UTAS/HDI with heat treat at 180KSI or above', contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e' },
  { contractReviewQuestionId: 'f2dbea6e-fb2f-4b06-b61b-d79d805ee688', text: 'Special Inspection Tooling Required', examples: 'Spline Gage, Thread Gage pre and post finish, etc.', contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e' },
  { contractReviewQuestionId: 'f82ddf92-0a11-40ed-a5b8-61cc1cc037a3', text: 'Long Lead Time Material', examples: 'Forgings, Castings, Etc.', contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e' },
  { contractReviewQuestionId: '509b36ea-78aa-4d1c-b4ca-fd745d8ba750', text: 'Long Lead Time Hardware', examples: 'Captive Screws, Card Guides, etc.',  contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e' },
  { contractReviewQuestionId: 'dc476bd5-c872-4026-bf38-9f3596134230', text: 'Non Standard Processes Quoted', examples: 'Welding, Special Heat Treat, Special Finishing, etc.', contractReviewCategoryId: 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e' },
  ]

}
