<div *ngIf="saving"><loading [content]="currentFileName"></loading></div>

<div>
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel" (click)="cancelEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="files.length>0 && emptyFiles.length === 0" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Upload" (click)="doUploads()"><i class="fas fa-arrow-up"></i></a>

  <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="addFile($event)">
    <p class="text-center mt-5 pt-5"><i class="fas fa-cloud-upload-alt text-muted fa-6x"></i></p>
    <p class="text-center">Drag and Drop files here, or <span class="font-weight-bold">click</span> to choose files.</p>
    <input hidden type="file" multiple #fileInput (change)="addFile($event.target.files)">
  </div>
  <div class="my-2 mx-3 alert alert-danger small" *ngIf="emptyFiles.length > 0">
    <p><b>One or more of the files you uploaded are empty. These files cannot be uploaded.</b></p>
    <p>This could be caused by a corrupted file. Please make sure you can open this file as expected.</p>
    <p>Alternatively, it could be caused by dragging files from a Windows Explorer .zip pseudo-folder. Please extract the files first.</p>
    <p><b>Empty files:</b></p>
    <ul>
      <li *ngFor="let f of emptyFiles">{{f.document.name}}</li>
    </ul>
  </div>
  <div class="m-3 p-4 bg-light">
    <file-display-grid (delete)="removeFile($event)" [editable]="true" [files]="files"></file-display-grid>
    <div *ngIf="files.length==0">
      <p class="font-italic text-muted text-center">-- No Uploaded Documents --</p>
      <p class="small text-muted text-center"><i class="text-info fas fa-fw fa-lg fa-info-circle"></i>Drag files onto the area above, or click to open a file-select dialog box.</p>
    </div>
  </div>
</div>
