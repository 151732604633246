import { 
  Directive,
  ElementRef, Renderer2, Inject,
  Input, Output, 
  EventEmitter, HostListener
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

interface Props {
  minWidth: number,
  minHeight: number,
}

const defaultValues: Props = {
  minWidth: 320,
  minHeight: 280,
}

interface Dimensions {
  width?: number,
  height?: number
}

interface ResizeEvent {
  newDimensions: Dimensions,
  target: HTMLElement
}

@Directive({
  selector: '[appResizable]'
})
export class ResizableDirective {

  // directive props, passed like [appResizable]="{ minWidth: ... }"
  @Input() appResizable: Partial<Props> = {}
  // compute final props by writing the provided props over the defaults
  private get props(): Props {
    return { ...defaultValues, ...this.appResizable }
  }

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
  ) {}
  // convenience getter since this is all we want from the ElementRef
  get nativeElement(): HTMLElement { return this._el.nativeElement }

  // Set up handle element, give it class for CSS & pointerdown handler
  private _handleElement: HTMLElement
  ngAfterViewInit() {
    this._handleElement = this._document.createElement('div');
    this._handleElement.classList.add('resizableHandle');
    this._renderer.appendChild(this.nativeElement, this._handleElement)
    this._handleElement.addEventListener('pointerdown', this.resizeOnStart.bind(this))
  }

  // 
  private width?: number
  private height?: number

  // Event handlers
  private _isResizing = false;
  
  @Output() resizeStarted = new EventEmitter<void>();

  resizeOnStart() {
    this._isResizing = true;
    this.nativeElement.classList.add('grid-resizing')
    this.resizeStarted.emit();
  }

  private get parentMaxWidth() {
    const parentEl = this.nativeElement.parentElement
    const pxToNumber = (x: string) => parseFloat(x.replace(/px$/, ''))
    const padding = pxToNumber(window.getComputedStyle(parentEl, null).getPropertyValue('padding-left'))
     + pxToNumber(window.getComputedStyle(parentEl, null).getPropertyValue('padding-right'))
    console.log(padding)
    return parentEl.offsetWidth - padding
  }

  @Output() resizeChanged = new EventEmitter<ResizeEvent>();
  @HostListener('window:pointermove', ['$event']) resizeOnMove({ movementX, movementY }: PointerEvent) {
    if (!this._isResizing) return;

    let newWidth = Math.max(this.nativeElement.offsetWidth + movementX, this.props.minWidth);

    newWidth = Math.min(this.parentMaxWidth, newWidth)

    this.width = newWidth;
    this.nativeElement.style.width = `${newWidth}px`;

    const newHeight = Math.max(this.nativeElement.offsetHeight + movementY, this.props.minHeight);
    this.height = newHeight;
    this.nativeElement.style.height = `${newHeight}px`;
    this.resizeChanged.emit({
      newDimensions: { width: this.width, height: this.height },
      target: this.nativeElement
    })
  }

  @Output() resizeDone = new EventEmitter<ResizeEvent>();
  @HostListener('window:pointerup') resizeOnDone() {
    if (this._isResizing) {
      this._isResizing = false;
      this.nativeElement.classList.remove('grid-resizing')
      this.resizeDone.emit({
        newDimensions: { width: this.width, height: this.height },
        target: this.nativeElement
      });
    }
  }

}
