import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ProductQuantity } from '../../../../../resources/product';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'product-quantity-input',
  templateUrl: './product-quantity-input.component.html',
  styleUrls: ['./product-quantity-input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductQuantityInputComponent),
      multi: true
    }
  ]
})
export class ProductQuantityInputComponent implements OnInit, ControlValueAccessor {

  @Input() readonly = false;
  @Input() dense = false;
  @Input() required = true;
  @Input() name: string;

  readonly separatorKeyCodes = [ENTER, COMMA, SPACE] as const;

  constructor() { }

  ngOnInit(): void {
  }

  public value: ProductQuantity[];
  writeValue(item: ProductQuantity[]): void {
    this.value = item ? item : [];
  }

  private onChange: (value: ProductQuantity[]) => void;
  private onTouched: () => void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  remove(index: number): void {
    this.value.splice(index, 1);
    this.onChange(this.value);
  }

  add(event: MatChipInputEvent): void {
    event.chipInput!.clear();
    const value = (event.value || '').trim();
    const quantity = parseInt(value);
    if (isNaN(quantity)) return;
    if (quantity < 1) return;

    this.value.push({
      value: quantity,
      markup: 18,
      showOnQuote: true
    });

    this.value.sort((a, b) => a.value - b.value);

    this.onChange(this.value);
  }

}
