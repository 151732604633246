import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Customer, CustomerAddress, CustomerContact } from '../resources/customer';
import { UtilityService } from '../../common/services/utility.service';
import { MessageService } from '../../common/services/message.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message'
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { Vendor, ApprovedVendor } from '../../supplier/resources/vendor';
import { Contact } from '../../common/resources/contact';
import { Address } from '../../common/resources/address';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends httpService {
  private apiBase: string = 'api/customer';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Customer";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<Customer>> {
    return this.http.get<SearchResult<Customer>>(this.apiUrl + '/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Customer Search Results", null))
    );
  }

  getDetail(id: string): Observable<Customer> {
    return this.http.get<Customer>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Customer Detail", null))
    );
  }

  deleteContact(contact: CustomerContact): Observable<any> {
    return this.http.delete<CustomerContact>(this.baseUrl + 'api/contact/' + contact.contactId).pipe(
      tap(_ => this.messages.add("Customer Service: Contact Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<CustomerContact>("Remove Existing Contact", contact))
    );
  }

  saveContact(customer: Customer, contact: CustomerContact): Observable<CustomerContact> {
    contact.customerId = customer.customerId;
    if (contact.customerContactId == UtilityService.emptyGuid) {
      //New Item
      // The new Contact will be saved automatically thanks to EFCore
      contact.contact.contactId = UtilityService.newGuid();
      return this.http.post<CustomerContact>(this.apiUrl + 'contact/new', contact).pipe(
        tap(_ => this.messages.add("Customer Service: Contact Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<CustomerContact>("Save New Contact", contact))
      );
    }
    else {
      //Existing Item
      // Have to save the contained contact manually when updating
      return this.http.post<Contact>(this.baseUrl + 'api/contact', contact.contact).pipe(
        mergeMap(_ => {
          return this.http.post<CustomerContact>(this.apiUrl + 'contact', contact)
        }),
        tap(_ => this.messages.add("Customer Service: Contact Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<CustomerContact>("Update Contact", contact))
      );
    }
  }

  save(_item: Customer): Observable<Customer> {

    // clone item 
    const item: Customer = JSON.parse(JSON.stringify(_item));
    // null related entities
    item.salesPerson = null;
    item.customerAddresses = null;

    if (item.customerId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<Customer>(this.apiUrl + '/new', item).pipe(
        tap(_ => this.messages.add("Customer Service: Customer Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Customer>("Save New Customer", item))
      );
    }
    else {
      //Existing Item
      return this.http.post<Customer>(this.apiUrl, item).pipe(
        tap(_ => this.messages.add("Customer Service: Customer Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Customer>("Update Customer", item))
      );
    }
  }

  getApprovedSupplierList(customer: Customer): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(this.baseUrl + 'api/vendor/getApprovedSupplierList/' + customer.customerId).pipe(
      tap(_ => this.messages.add("Customer Service: Fetched Customer ASL Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Fetch Customer ASL List", null))
    );
  }

  addApprovedSupplier(vendor: Vendor, customer: Customer): Observable<ApprovedVendor> {
    //Note: the vendor here should ALWAYS be new...
    return this.http.post<ApprovedVendor>(this.baseUrl + 'api/vendor/addApprovedSupplier/' + customer.customerId, vendor).pipe(
      tap(_ => this.messages.add("Customer Service: Approved Supplier Added Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ApprovedVendor>("Add New Approved Supplier", null))
    );
  }

  removeApprovedSupplier(vendor: Vendor, customer: Customer): Observable<any> {
    return this.http.delete<ApprovedVendor>(this.baseUrl + 'api/vendor/removeApprovedSupplier/', { params: { customerId: customer.customerId, vendorId: vendor.vendorId } }).pipe(
      tap(_ => this.messages.add("Customer Service: Approved Supplier Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<ApprovedVendor>("Remove Approved Supplier", null))
    );
  }

  saveAddress(customer: Customer, customerAddress: CustomerAddress): Observable<CustomerAddress> {
    customerAddress.customerId = customer.customerId;
    customerAddress.address.contactId = customerAddress.address.contact && customerAddress.address.contact.contactId;
    customerAddress.address.contact = null;
    if (customerAddress.customerAddressId == UtilityService.emptyGuid) {
      //New Item
      // The new Address will be saved automatically thanks to EFCore
      customerAddress.address.addressId = UtilityService.newGuid();
      return this.http.post<CustomerAddress>(this.apiUrl + 'address/new', customerAddress).pipe(
        tap(_ => this.messages.add("Vendor Service: Address Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<CustomerAddress>("Save New Address", customerAddress))
      );
    }
    else {
      //Existing Item
      // Have to save the contained address manually when updating
      return this.http.post<Address>(this.baseUrl + 'api/address', customerAddress.address).pipe(
        mergeMap(_ => {
          return this.http.post<CustomerAddress>(this.apiUrl + 'address', customerAddress)
        }),
        tap(_ => this.messages.add("Vendor Service: Address Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<CustomerAddress>("Update Address", customerAddress))
      )
    }
  }

  deleteAddress(item: CustomerAddress): Observable<CustomerAddress> {
    // just deleting the address will automatically delete the vendoraddress via cascade
    return this.http.delete<CustomerAddress>(this.baseUrl + 'api/address/' + item.addressId).pipe(
      tap(_ => this.messages.add("Vendor Service: Address Removed Successfully", MessageType.SUCCESS, true)),
      map(_ => item),
      catchError(this.handleError<CustomerAddress>("Remove Vendor Address", item))
    );
  }

  delete(customer: Customer): Observable<any> {
    return this.http.delete<Customer>(this.apiUrl + '/' + customer.customerId).pipe(
      tap(_ => this.messages.add("Customer Service: Customer Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<Customer>("Remove Existing Customer", customer))
    );
  }

}
