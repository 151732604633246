import { Component, OnInit } from '@angular/core';
import { WorkOrderService } from '../../../planning/services/work-order.service';
import { WorkOrder } from '../../../planning/resources/work-order';
import { Router } from '@angular/router';
import { SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { OrderService } from '../../../order/services/order.service';
import { ProductStandard } from '../../../order/resources/product';
import { OrderProduct } from '../../../order/resources/order';
import { map } from 'rxjs/operators';
import { SearchResult } from '../../../common/resources/search-result';

@Component({
  selector: 'upcoming-work-list',
  templateUrl: './upcoming-work-list.component.html',
  styleUrls: ['./upcoming-work-list.component.less']
})
export class UpcomingWorkListComponent implements OnInit {

  constructor(private service: OrderService, private router: Router) { }

  ngOnInit(): void {
  }

  public onSelect(item: OrderProduct) {
    this.router.navigate(['floor/upcoming', item.productProductId]);
  }

  public search({ searchText, page, sort }: SearchData) {
    return this.service.searchOrderProducts(searchText, page.pageIndex, sort.active, sort.direction, 20).pipe(map(x => <SearchResult<OrderProduct>>{ results: x, pageIndex: 0, pageSize: 50, resultCount: 50, successful: true }));
  }


}
