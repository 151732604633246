<loading *ngIf="!record"></loading>
<div class="rounded mat-elevation-z2 py-2" *ngIf="record">
<div class="mat-dialog-title mb-2 px-3">
  Upcoming Work Graph for {{ record.partNumber }} Rev. {{ record.revision }}
</div>
<mat-divider class="mb-4"></mat-divider>
<div class="w-100 d-flex p-3 mb-3 position-relative">
  <div class="gantt-y-grid-holder">
    <div class="gantt-y-line" *ngFor="let i of rows">&nbsp;</div>
  </div>
  <div class="gantt-left mr-2">
    <div class="gantt-header">&nbsp;</div>
    <ng-container *ngFor="let step of workflow">
      <div class="gantt-header">{{ getName(step) }}</div>
      <div class="gantt-header" *ngIf="workflowStepHasInspection(step)">Inspection</div>
    </ng-container>
    <div class="gantt-header">&nbsp;</div>
  </div>
  <div class="flex-grow-1 position-relative">
    <div class="gantt-grid">
      <div class="gantt-grid-x-interval" *ngFor="let i of gridxIntervalArray" [ngStyle]="{
          left: (gridxIntervalPercent * i) + '%'
        }">
        <div class="grid-tick-text">Day {{ i + 1 }}</div>
      </div>
    </div>
    <div class="gantt-bar">&nbsp;</div>
    <ng-container *ngFor="let step of workflow">
      <oor-gantt-bar colorClass="production"
        [colorOverride]="materialColor(step)"
        [start]="getWorkflowStepStartOffset(step)"
        [width]="getWorkflowStepBarWidth(step)"
        [tooltip]="getStepTooltipText(step)"
      >&nbsp;</oor-gantt-bar>
      <oor-gantt-bar colorClass="qa"
        [start]="getWorkflowStepStartOffset(step)"
        [width]="getWorkflowStepBarWidth(step)"
       *ngIf="workflowStepHasInspection(step)">&nbsp;</oor-gantt-bar>
    </ng-container>
    <div class="gantt-bar">&nbsp;</div>
    <div class="gantt-bar">&nbsp;</div>
  </div>
</div>
</div>
