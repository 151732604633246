<loading *ngIf="!record || saving"></loading>

<div class="rounded mat-elevation-z4 position-relative d-flex flex-column" style="height: 90vh; overflow: hidden;" *ngIf="record">
  <div class="bg-dark text-white d-flex align-items-center p-3 small justify-content-end" style="min-height: 1em;">
    <div *ngIf="record.status === 4">
      This purchase order is awaiting review.
    </div>&nbsp;
  </div>
  <div class="d-flex" style="position: absolute; top: 4.25em; right: 1em; z-index: 15;">
    <button mat-fab class="extended-fab-button bg-danger text-white mr-2"
      *ngIf="!editing && isManager && (record.status == 0 || record.status == 1 || record.status == 6)" (click)="unreceive()">
      <mat-icon svgIcon="arrow-u-left-top"></mat-icon>
      <span class="extended-fab-button__text">Unreceive PO</span>
    </button>
    <button mat-fab class="extended-fab-button bg-primary text-white mr-2"
      *ngIf="!editing" (click)="openReport()">
      <mat-icon>print</mat-icon>
      <span class="extended-fab-button__text">Print PO</span>
    </button>
    <button mat-fab class="extended-fab-button bg-success text-white mr-2"
      *ngIf="record.status === 4 && isManager" (click)="approve()">
      <mat-icon>check</mat-icon>
      <span class="extended-fab-button__text">Approve</span>
    </button>
    <button mat-fab class="extended-fab-button bg-danger text-white"
      *ngIf="record.status === 4 && isManager" (click)="reject()">
      <mat-icon>close</mat-icon>
      <span class="extended-fab-button__text">Reject</span>
    </button>
    <button mat-fab class="bg-success text-white"
      *ngIf="!editing && record.status !== 4" (click)="edit()">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-fab class="extended-fab-button text-white mr-2" [ngClass]="{ 'bg-light': form.invalid || !form.dirty, 'bg-success': form.valid && form.dirty }"
      *ngIf="editing" [disabled]="form.invalid || !form.dirty" (click)="save()">
      <mat-icon>save</mat-icon>
      <span class="extended-fab-button__text">Save Changes</span>
    </button>
    <button mat-fab class="bg-danger text-white" *ngIf="editing" (click)="stopEditing()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-tab-group class="mat-tab-group-colgrow mat-tab-group-headerstyle flex-grow-1" ngForm #form="ngForm">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-2">description</mat-icon>
        Purchase Order Information
      </ng-template>
      <div class="row py-3 px-2 mx-0 mt-4">
        <div class="col-3 mb-2">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Purchase Order Number</mat-label>
            <input matInput readonly type="text" [ngModel]="record?.purchaseOrderNumber ?? ''" [ngModelOptions]="{ standalone: true }"
              placeholder="-- Not Yet Assigned --" class="font-weight-bold">
          </mat-form-field>
        </div>
        <div class="col-3">
          <company-search [editing]="editing" [(ngModel)]="record.company" (ngModelChange)="onCompanyChange($event)" name="company"></company-search>
        </div>
        <div class="col-2">
          <mat-form-field appearance="outline" class="w-100 form-field-chip-size">
            <mat-label>Status</mat-label>
            <mat-chip-list>
              <mat-chip matPrefix style="pointer-events: none;" class="mat-standard-chip button-text"
                [ngClass]="getStatusColorClass(record.status)">{{ getStatusText(record.status) }}</mat-chip>
              <input matInput [matChipInputFor]="cl" type="text" readonly>
            </mat-chip-list>
            <button mat-icon-button matSuffix class="text-muted" *ngIf="record.status === 5" matTooltip="View Rejection Note" (click)="viewRejectionNote()">
              <mat-icon>info</mat-icon>
            </button> 
          </mat-form-field>
        </div>
        <div class="col-2">
          <employee-search [readonly]="!editing" label="Assigned User" [(ngModel)]="record.assignedUser" name="assignedUser"
            placeholder="Unassigned" floatLabel="always"
            (selectedItemChange)="record.assignedTo = $event?.userId"></employee-search>
        </div>
        <div class="col-2">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Requisitioned By</mat-label>
            <input matInput [readonly]="!editing" type="text" [(ngModel)]="record.requisitioner" name="requisitioner" placeholder="--">
          </mat-form-field>
        </div>
        <div class="col-3">
          <vendor-search [readonly]="!editing" label="Vendor (required)" [(ngModel)]="record.vendor" [(selectedItem)]="record.vendor" required notNullValidator
            (ngModelChange)="onVendorChange($event)" name="vendor"></vendor-search>
        </div>
        <div class="col-3">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Vendor Order Number</mat-label>
            <input matInput [readonly]="!editing" type="text" [(ngModel)]="record.vendorOrderNumber" name="vendorOrderNumber" placeholder="--">
          </mat-form-field>
        </div>
        <div class="col-6 mb-2">
          <mat-form-field [class.mat-select-readonly]="!editing" appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Vendor Representative</mat-label>
            <mat-select [panelClass]="!editing ? 'd-none' : ''" [placeholder]="'--'" [(ngModel)]="record.contact" (ngModelChange)="record.contactId = $event?.contactId" name="contact"
            [compareWith]="compareContacts"
            >
              <mat-option [value]="null">
                <div class="text-muted font-italic">None</div>
              </mat-option>
              <mat-option *ngFor="let vendorContact of record.vendor?.vendorContacts"
                [value]="vendorContact.contact">
                {{ vendorContact.contact.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <date-picker appearance="outline" class="w-100" placeholder="Date Placed" [(ngModel)]="record.datePlaced" name="datePlaced"
            [editable]="true" [readonly]="!editing"></date-picker>
        </div>
        <div class="col-4 mb-2">
          <date-picker appearance="outline" class="w-100" placeholder="Date Required" [(ngModel)]="record.dateRequired" name="dateRequired"
            [editable]="true" [readonly]="!editing"></date-picker>
        </div>
        <div class="col-4">
          <date-picker appearance="outline" class="w-100" placeholder="Promise Date" [(ngModel)]="record.dateExpected" name="dateExpected"
            [editable]="true" [readonly]="!editing"></date-picker>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>PO Notes</mat-label>
            <textarea [readonly]="!editing" matInput [(ngModel)]="record.note" name="note" rows="4"></textarea>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Private/Internal Notes</mat-label>
            <textarea [readonly]="!editing" matInput [(ngModel)]="record.privateNote" name="privateNote" rows="4"></textarea>
          </mat-form-field>
        </div>
        <div class="col-12 my-3">
          <mat-divider></mat-divider>
        </div>
        <div class="col-3 mb-2">
          <clientside-search
            [readonly]="!editing" 
            label="Shipping Carrier"
            [(ngModel)]="record.shippingCarrier"
            (ngModelChange)="record.shippingCarrierId = $event?.shippingCarrierId"
            name="shippingCarrier"
            [items]="shippingCarriers$"
            [canAdd]="true"
            [getSearchField]="this.getShippingCarrierName.bind(this)"
            [addConverter]="this.generateShippingCarrier.bind(this)"
            [nullOption]="'N/A'"
            [reqSel]="true"
          >
          </clientside-search>
        </div>
        <div class="col-3">
          <clientside-search
            [readonly]="!editing" 
            label="Shipping Method"
            [(ngModel)]="record.shippingMethod"
            [items]="shippingMethods$"
            [canAdd]="true"
            [nullOption]="'N/A'"
            [reqSel]="true"
            name="shippingMethod"
          >
          </clientside-search>
        </div>
        <div class="col-4">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Shipping Account Number</mat-label>
            <input matInput [readonly]="!editing" type="text" [(ngModel)]="record.vendorShippingAccountNumber" name="vendorShippingAccountNumber"
              placeholder="--">
          </mat-form-field>
        </div>
        <div class="col-2">
          <date-picker appearance="outline" class="w-100" placeholder="Ship Date" [(ngModel)]="record.shipDate" name="shipDate"
            [editable]="true" [readonly]="!editing"></date-picker>
        </div>
        <div class="col-3">
          <clientside-search
          [readonly]="!editing"
          label="Freight Terms"
          [(ngModel)]="record.fobTerms"
          [items]="freightTerms$"
          [canAdd]="true"
          [nullOption]="'N/A'"
          [reqSel]="true"
          name="fobTerms"
          >
          </clientside-search>
        </div>
        <div class="col-3">
          <clientside-search
          [readonly]="!editing"
          label="Payment Terms"
          [(ngModel)]="record.paymentTerms"
          [items]="paymentTerms$"
          [canAdd]="true"
          [nullOption]="'N/A'"
          [reqSel]="true"
          name="paymentTerms"
          >
          </clientside-search>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Shipping/Handling Fees</mat-label>
            <span matPrefix class="text-muted mr-1">$</span>
            <input matInput [readonly]="!editing" type="number" [(ngModel)]="record.shippingHandlingFees" name="shippingHandlingFees" required notNullValidator
              [placeholder]="editing ? '' : '--'">
          </mat-form-field>
        </div>
        <div class="col-12 my-3">
          <mat-divider></mat-divider>
        </div>
        <div class="col-6 mb-2">
          <mat-form-field [class.mat-select-readonly]="!editing" appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Vendor Address</mat-label>
            <mat-select [panelClass]="!editing ? 'd-none' : ''" [disabled]="!record.vendor"
              [placeholder]="!record.vendor ? 'Please select a vendor first.' : vendorAddresses?.length === 0 ? 'Vendor has no addresses defined.' : undefined"
              [(ngModel)]="record.vendorAddressId" (ngModelChange)="onVendorAddressChange($event)" name="vendorAddressId">
              <mat-option *ngFor="let address of vendorAddresses" [value]="address.addressId">
                {{ address.nickname }}
              </mat-option>
              <mat-option *ngIf="vendorAddresses?.length === 0" [value]="undefined" class="text-muted">
                Vendor has no addresses defined.
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [class.col-2]="record.destinationType !== 0" [class.col-6]="record.destinationType === 0">
          <mat-form-field [class.mat-select-readonly]="!editing" appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Deliver To</mat-label>
            <mat-select [panelClass]="!editing ? 'd-none' : ''" [(ngModel)]="record.destinationType" name="destinationType">
              <mat-option [value]="1">One of our buildings</mat-option>
              <mat-option [value]="0">Custom address</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4" *ngIf="record.destinationType === 1">
          <building-select [readonly]="!editing"
            [(selectedItem)]="record.destinationBuilding"
            (selectedItemChange)="record.destinationBuildingId = $event.buildingId">
          </building-select>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100 textarea-address">
            <textarea [readonly]="true" matInput [ngModel]="getAddressString(record.vendorAddress, record.vendor?.name)" rows="6" placeholder="No Address Selected" [ngModelOptions]="{standalone: true}"></textarea>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100 textarea-address" *ngIf="record.destinationType !== 0">
            <textarea [readonly]="true" matInput [ngModel]="getAddressString(activeAddress, activeAddressAttn)" rows="6" placeholder="No Address Selected" [ngModelOptions]="{standalone: true}"></textarea>
          </mat-form-field>
          <div class="row" *ngIf="record.destinationType === 0">
            <div class="col-12 mb-1">
              <mat-form-field appearance="outline" class="w-100 dense-field">
                <input matInput placeholder="Address Line 1" [(ngModel)]="record.shippingAddr1" name="shippingAddr1" />
              </mat-form-field>
            </div>
            <div class="col-12 mb-1">
              <mat-form-field appearance="outline" class="w-100 dense-field">
                <input matInput placeholder="Address Line 2" [(ngModel)]="record.shippingAddr2" name="shippingAddr2" />
              </mat-form-field>
            </div>
            <div class="col-6 mb-1">
              <mat-form-field appearance="outline" class="w-100 dense-field">
                <input matInput placeholder="City" [(ngModel)]="record.shippingCity" name="shippingCity" />
              </mat-form-field>
            </div>
            <div class="col-6 mb-1">
              <mat-form-field appearance="outline" class="w-100 dense-field">
                <input matInput placeholder="State" [(ngModel)]="record.shippingState" name="shippingState" />
              </mat-form-field>
            </div>
            <div class="col-12 mb-1">
              <mat-form-field appearance="outline" class="w-100 dense-field">
                <input matInput placeholder="Postal Code" [(ngModel)]="record.shippingPostalCode" name="shippingPostalCode" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-2">receipt_long</mat-icon>
        Line Items
      </ng-template>
      <div class="p-3 mt-3 h-100">
        <div class="border rounded h-100" style="overflow: hidden">
          <po-line-items [editing]="editing" [(records)]="record.purchaseOrderLineItems" (recordsChange)="form.control.markAsDirty()" [(removedLineItems)]="removedLineItems" [purchaseOrder]="record" [purchaseOrderId]="record.purchaseOrderId"></po-line-items>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #statusConfirmationDialogTemplate>
  <h2 mat-dialog-title>Are You Finished?</h2>
  <mat-dialog-content class="mat-typography">
    <p>Is this purchase order ready<span *ngIf="!isManager"> for review</span>, or is there still more to be done?</p>
    <div class="m-3">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button [disabled]="!addressInput" value="1" #ready >It's Ready<span *ngIf="!isManager"> for Review</span></mat-radio-button>
        <mat-radio-button value="2" #needswork class="ml-3">Still Needs More Work</mat-radio-button>
      </mat-radio-group>
      <div class="text-muted small font-italic font-weight-bold" *ngIf="!addressInput">
        A PO can't be marked as ready with no address set.
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="!(needswork.checked || ready.checked)" [mat-dialog-close]="{ready: ready.checked}">Okay</button>
    <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #rejectionNoteDialogTemplate>
  <h2 mat-dialog-title>Rejection</h2>
  <mat-dialog-content class="mat-typography">
    <blockquote *ngIf="record.statusNote" class="border-left pl-2">
      {{ record.statusNote }}
    </blockquote>
    <div *ngIf="!record.statusNote">
      No rejection note was provided.
    </div>
    <div *ngIf="approvedByUser" class="text-danger font-italic align-right">
      Rejected by {{ approvedByUser.fullName }} on {{ record.approvedOn | date }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="null">Close</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #rejectDialogTemplate let-data>
  <h2 mat-dialog-title>Reject Purchase Order</h2>
  <mat-dialog-content class="mat-typography">
    <p>Please explain why this purchase order was rejected, and what needs to be done to fix it.</p>
      <mat-form-field appearance="outline" class="w-100">
        <textarea matInput [(ngModel)]="data.note" placeholder="Please tell us what needs to be done for this purchase order to proceed..."></textarea>
      </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="!data.note" [mat-dialog-close]="{ note: data.note }">Okay</button>
    <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</ng-template>

