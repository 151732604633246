import { Component, OnInit } from "@angular/core";
import {  DashboardCount } from "../orderStatusInfo/service"


// estimateManager
@Component({
  selector: 'cccccc',
  template: `
    <div class="small">
      <alert-list></alert-list>
    </div>
  `,
  styleUrls: ['./styles.less']
})
export class FloorAlertsWidgetComponent implements OnInit {

  public data: DashboardCount[] = null;

  constructor() { }

  ngOnInit(): void {
    ;
  }

}
