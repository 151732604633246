import { Component } from '@angular/core';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';
import { FilterParam } from '../../../common/components/search-list-new/search-list-new.component';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../services/order.service';
import { getQuoteHistoryChipClass, getQuoteHistoryText } from '../../resources/product';

@Component({
  selector: 'rfq-approval-list',
  templateUrl: './rfq-approval-list.component.html',
  styleUrls: ['./rfq-approval-list.component.less']
})
export class RfqApprovalListComponent {
  constructor(private userSvc: UserService, private dialog: MatDialog, private service: OrderService) {
  }

  public userIsManager(user: User): boolean {
    return this.userSvc.canAccess('QuoteManager', user);
  }

  public get isManager() { return this.userIsManager(this.userSvc.userData) }

  public readonly availableStatuses = [OrderStatus.AWAITING_REVIEW] as const;
  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    })),
  }, 
  // {
  //   categoryName: 'partHistory',
  //   categoryTitle: 'Part Priority',
  //   options: [0, 1, 2, 3, 4, 100].map(s => ({
  //     class: getQuoteHistoryChipClass(s),
  //     title: getQuoteHistoryText(s),
  //     value: s
  //   })),
  // }
  ]
  public readonly defaultStatuses = [OrderStatus.AWAITING_REVIEW] as const;
  public readonly defaultFilters = { status: this.defaultStatuses, 
    // partHistory: [] 
  }

  public loading = false;

}
