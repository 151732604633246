import { Component, Inject, Injector, Input, forwardRef } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { map } from 'rxjs/operators';
import { GenericSearchComponent } from '../../../common/components/generic-search/generic-search.component';
import { ShippingService } from '../../services/shipping.service';
import { ShippingCarrier } from '../../resources/shipping-ticket';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
  selector: 'shipping-carrier-select',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../common/components/generic-search/generic-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShippingCarrierSelectComponent),
      multi: true
    }
  ]
})
export class ShippingCarrierSelectComponent extends GenericSearchComponent<ShippingCarrier> {

  @Input() placeholder = 'Shipping Carrier';

  constructor(private shippingService: ShippingService, private userSvc: UserService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.shippingService.getShippingCarriers(searchText).pipe(
      map((v) => v.results)
    )
  }

  public canAdd(): boolean {
    return this.userSvc.canAccess('PurchasingUser')
  }

  public get addItemText(): string {
     return 'Add Carrier';
  }

  public get noItemsText(): string {
    return this.canAdd() ? 'Still not seeing your carrier?' : 'No vendors found.';
  }

  public getSearchField(v: ShippingCarrier): string {
    return v && v.name;
  }

}
