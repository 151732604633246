import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ShippingCarrier } from '../../resources/shipping-ticket';
import { UtilityService } from '../../../common/services/utility.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { ShippingService } from '../../services/shipping.service';

@Component({
  selector: 'shipping-carrier-detail',
  templateUrl: './shipping-carrier-detail.component.html',
  styleUrls: ['./shipping-carrier-detail.component.less']
})
export class ShippingCarrierDetailComponent implements OnInit {
  @Input() id: string = null;
  @Input() nameHint: string = null;
  @Input() sidenav: MatSidenav;

  public record: ShippingCarrier;
  public saving: boolean = false;

  constructor(private shippingSvc: ShippingService, private navService: NavigationService) { }

  public cancelEditing(): void {
    if (this.sidenav) {
      this.sidenav.close();
    }
    else {
      this.getDetail();
    }
  }

  public saveChanges(): void {
    this.saving = true;
    this.shippingSvc.saveShippingCarrier(this.record).subscribe(_ => {
      this.saving = false;
      this.cancelEditing();
    }
    );
  }

  private getDetail(): void {
    this.record = null;

    if (this.id == 'new' || this.id == UtilityService.emptyGuid) {
      if (this.sidenav) {
        this.navService.popBreadCrumb();
        this.navService.pushBreadcrumb("Add Shipping Carrier");
      }
      this.record = <ShippingCarrier>{
        shippingCarrierId: UtilityService.emptyGuid,
        name: this.nameHint || 'New Carrier',
        trackingProvider: ''
      }
    }
    else {
      this.shippingSvc.getShippingCarrierDetail(this.id).subscribe(detail => {
        this.record = detail;
      });
    }
  }

  public testTrackingProvider(): void {
    window.open(this.record.trackingProvider + '1234567890', 'providerLink', '');
  }

  ngOnInit(): void {
    if (this.sidenav) {
      this.navService.pushBreadcrumb("Edit Shipping Carrier");
    }

    this.getDetail();
  }
}
