<div class="d-flex flex-column h-100-t">
  <mat-list class="pt-0 flex-grow-1 position-relative" style="height: 0; overflow-y: scroll;" cdkDropList [cdkDropListData]="product.workflow.workflowSteps" id="workflowWorkflowListCdkDrop" [cdkDropListConnectedTo]="'workflowStationListCdkDrop'" (cdkDropListDropped)="drop($event)">
    <mat-list-item cdkDrag [cdkDragDisabled]="!editing" *ngFor="let step of product.workflow.workflowSteps" class="border-bottom bg-white list-item"
    [class.bg-primary]="selectedStepId === step.workflowStepId"
    [class.text-white]="selectedStepId === step.workflowStepId"
    (click)="selectedStepIdChange.emit(step.workflowStepId)"
    >
      <div class="w-100 d-flex align-items-center text-ellipsis">
        <mat-icon svgIcon="format-list-group" class="text-muted flex-shrink-0" [class.invisible]="!step.isStandalone"></mat-icon>
        <mat-icon svgIcon="cog" class="text-muted flex-shrink-0" [class.invisible]="step.runIsPerPart"></mat-icon>
        <mat-icon svgIcon="logout-variant" class="text-muted flex-shrink-0" [class.invisible]="!getStation(step).isOutsourceStep"></mat-icon>
        <span class="ml-2 text-ellipsis" style="width: 50%">{{ getStation(step).name }}</span>
        <span class="ml-auto small" [class.text-muted]="selectedStepId !== step.workflowStepId" *ngIf="!step.isStandalone">
          ({{calculateCost(step) | currency}} / {{calculatePercentage(step) | percent:'0.1-2'}})
        </span>
        <span class="ml-auto small font-weight-bold" [class.text-muted]="selectedStepId !== step.workflowStepId" *ngIf="step.isStandalone" matTooltip="Standalone Step" matTooltipPosition="after">
          <i class="fas fa-bars-staggered mr-1"></i>
          {{ ( (step.runPrice) * (step.runTime + (step.hasSetup ? step.setupTime : 0)) ) | currency }}
        </span>
      </div>
    </mat-list-item>
    <div *cdkDragPreview style="cursor: grabbing">
      <div class="rounded bg-white mat-elevation-z5 px-3 list-item" style="width: 300px; height: 48px; line-height: 48px; position: relative; left: -2em; top: -1em; cursor: grabbing">
        {{ getName(step) }}
      </div>
    </div>
    <div *ngIf="product.workflow.workflowSteps.length === 0" class="w-100 text-muted text-center position-absolute small p-4 no-workflow" style="pointer-events: none;">
      This product has no workflow steps. You can add them from the list on the right.
    </div>
  </mat-list>
</div>