<div>
  <a class="position-fixed fixed-bottom btn btn-badge has-icon simalar-part" title="Similar Part Recomendations" (click)="toggleWindow()"><i class="fa-solid fa-clone"></i></a>

  <div class="popout mat-app-background" *ngIf="isOpen">
    <div class="header">
      Similar Parts
      <i matTooltip="Cick to Preview a Part, Select to Apply Changes to Working Part" class="fa-solid fa-circle-info cursor-help text-muted cursor-"></i>
      <a title="Refresh" class="float-right cursor-pointer" (click)="refresh()"><i class="fa-solid fa-arrows-rotate"></i></a>
      <a title="Minimize" class="float-right cursor-pointer pr-2" (click)="toggleWindow()"><i class="fa-solid fa-window-minimize"></i></a>
    </div>
    <div class="content">
      <div *ngIf="results; else waiting">
        <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
          <thead header-template>
            <tr class="text-uppercase text-muted">
              <th>
                &nbsp;
              </th>
              <th>
                Customer
              </th>
              <th>
                Part # / Rev
              </th>
              <th>
                Score
              </th>
            </tr>
          </thead>
          <ng-template let-row>
            <td><button class="btn btn-primary btn-sm" (click)="assignPart($event, row.productId)">Select</button></td>
            <td>{{row.businessName}}</td>
            <td>{{row.partNumber}} / {{row.revision}}</td>
            <td>{{row.score | number:'1.1-1'}}</td>
          </ng-template>
        </search-result>
      </div>
      <ng-template #waiting>
        <div class="center waiting">
          <mat-spinner [color]="'warn'"></mat-spinner>
          <span class="text-muted font-italic">Finding Similar Parts...</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
