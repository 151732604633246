<div class="position-relative">
  <input (blur)="onTouched()" (input)="searchInputChange.next()" #searchInput [(ngModel)]="searchString"
    (focus)="onFocus()" #searchStringModel="ngModel" #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1"
    matInput placeholder="Select a Purchased Item" class="form-control form-control-sm" type="text"
    [matAutocomplete]="itemsAutocomplete" />
  <mat-autocomplete (optionSelected)="optionSelected($event.option.value)" #itemsAutocomplete="matAutocomplete"
    [displayWith]="getOptionText">
    <mat-option *ngFor="let item of items" [value]="item">
      {{ item.description }}
    </mat-option>
    <mat-option *ngIf="!searching && items.length === 0" disabled>
      <div class="d-flex justify-content-center align-items-center p-1">
        <span class="body-2 text-muted font-italic">No matching items found.</span>
        <button mat-flat-button color="primary" class="text-uppercase mx-3" (click)="createNewPurchasedItem()">Create
          new</button>
      </div>
    </mat-option>
    <mat-option [class.searching-indicator-disabled]="!searching" disabled class="searching-indicator">
      <div class="d-flex align-items-center justify-content-center w-100">
        <mat-spinner diameter="20" class="mr-2"></mat-spinner> Searching...
      </div>
    </mat-option>
  </mat-autocomplete>
</div>

<ng-template #newPurchasedItemDialog let-data>
  <ng-container *ngIf="!savingNew">
    <h2 matDialogTitle>New Purchased Item</h2>
    <mat-dialog-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100" appearance="standard">
              <mat-label>Purchased Item Name</mat-label>
              <input matInput type="text" autocomplete="off" [(ngModel)]="data.description">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="newItemCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="newItemSave(data)" [disabled]="!data.description">Create
        Purchased Item</button>
    </mat-dialog-actions>
  </ng-container>
  <div *ngIf="savingNew" class="d-flex flex-column align-items-center py-2">
    <div>
      <mat-spinner [color]="'warn'"></mat-spinner>
    </div>
    <div class="text-muted font-italic mt-4">
      Saving...
    </div>
  </div>
</ng-template>
