<shipping-wrapper>
  <div class="row">
    <div class="col-5 py-2 px-4">
      <div class="border border-secondary rounded h-100">
        <h5 class="w-100 text-center p-2 bg-secondary">Today</h5>
        <div class="chartContainer">
          <canvas #todayChart>
            <p>This is a chart. If you can see this, your browser doesn't support the Canvas tag.</p>
          </canvas>
        </div>  
      </div>    
    </div>
    <div class="col-7 py-2 px-4">
      <div class="border border-secondary rounded h-100">
        <h5 class="w-100 text-center p-2 bg-secondary">Future Schedule</h5>
        <div class="p-1">
          <div class="chartContainer">
            <canvas #futureChart>
              <p>This is a chart. If you can see this, your browser doesn't support the Canvas tag.</p>
            </canvas>
          </div>   
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="p-1">
        <widget-area name="Dashboard" id="shipping-dashboard"></widget-area>
      </div>
    </div>
  </div>
</shipping-wrapper>
