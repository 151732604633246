import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { UtilityService } from '../../../common/services/utility.service';
import { ProductMaterialDimension } from '../../../order/resources/product';
import { BubbleMeasurement, InspectionTicket, InspectionType } from '../../resources/inspection-ticket';
import { QualityService } from '../../service/quality.service';
import { DocumentService } from '../../../common/services/document.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'inspection-ticket',
  templateUrl: './inspection-ticket.component.html',
  styleUrls: ['./inspection-ticket.component.less', '../inspection-queue/inspection-queue.component.less'],
  animations: [
    trigger('measurementAnimationTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(25%)' }),
        animate('150ms cubic-bezier(0.4, 0.0, 1, 1)', style({ opacity: 1, transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'translateX(-25%)' }))
      ])
    ]),
    trigger('noMeasurementsAnimationTrigger', [
      transition(':enter', [
        style({ opacity: 0, position: 'absolute', width: 'calc(100% - 32px)' }),
        animate('750ms 150ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 })),
      ])
    ]),
  ]
})
export class InspectionTicketComponent implements OnInit {

  private id: string;
  public record: InspectionTicket | null = null;

  public hasStartedInspection = false;
  public complete = false;
  public inspectedCount = 0;
  public partTimes: number[] = [];

  public loading = false;

  constructor(
    private qualityService: QualityService,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private documentService: DocumentService,
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
  }

  async ngOnInit() {
    this.loading = true;
    this.record = await this.qualityService.getInspectionTicket(this.id).toPromise();
    this.loading = false;
  }

  public get canEdit() {
    return this.record.status !== 3 && this.record.status !== 4;
  }

  public getInspectionTypeText(ticket: InspectionTicket) {
    if (ticket.inspectionType == 0 && !ticket.requiresReport) return 'First Part Inspection';
    if (ticket.inspectionType == 0 && ticket.requiresReport) return 'FAIR';
    if (ticket.inspectionType == 1) return 'Lot Inspection';
  }

  public getInspectionMethodText(ticket: InspectionTicket) {
    if (ticket.isCMM) return 'CMM Inspection';
    else return 'Manual Inspection';
  }

  public get inspectionQuantity(): number {
    if (this.record.inspectionType === InspectionType.FIRST_PART) return 1;
    else return (this.record.machineAssignment.requestedQty);
  }

  private counterInterval: any;
  public timerCounter: number = 0;

  private pauseTimer(): void {
    clearInterval(this.counterInterval);
    this.counterInterval = null;
  }

  private startTimer(): void {
    this.counterInterval = setInterval(_ => {
      this.timerCounter += 1;
    }, 1000);
  }

  private resetTimer() {
    if (this.counterInterval) clearInterval(this.counterInterval);
    this.timerCounter = 0;
    this.counterInterval = setInterval(_ => {
      this.timerCounter += 1;
    }, 1000);
  }

  public get timerRunning(): boolean {
    return !!this.counterInterval;
  }

  public startStop(): void {
    if (this.timerRunning)
      this.pauseTimer();
    else
      this.startTimer();
  }

  public checkDimensions(dimensions: {
    name: string, value: number, unit: string
  }[]) {
    return dimensions.length === 0 || dimensions.every(d => typeof d.value === 'number' && d.value > 0)
  }

  public async beginInspection() {
    
    this.hasStartedInspection = true;
    this.record.startedTime = new Date();
    this.record.inspectedItems = [];
    this.startTimer();
  }

  public async approvePart() {
    this.pauseTimer();
    const r = await this.utilityService.showConfirmationPromise('Are you sure?', 'This will pass inspection for this part and cannot be undone.');
    this.startTimer();
    if (!r) return;
    this.partTimes = [...this.partTimes, this.timerCounter];
    this.record.inspectedItems = [...this.record.inspectedItems, { approved: true }];
    this.inspectedCount += 1;
    this.resetTimer();
    if (this.inspectedCount === this.inspectionQuantity) this.finishInspection();
  }

  @ViewChild('rejectReasoningDialogTemplate', { static: true }) rejectReasoningDialogTemplate: TemplateRef<any>;
  public async rejectPart() {
    this.pauseTimer();
    const dialogRef = this.dialog.open<any, any, { note: string, recordedDimensions: { name: string, value: number, unit: string }[] }>(this.rejectReasoningDialogTemplate, {
      data: { note: "" },
      disableClose: true
    })
    const result = await dialogRef.afterClosed().toPromise();
    this.startTimer();
    if (!result) return;
    const { note, recordedDimensions } = result;
    this.partTimes = [...this.partTimes, this.timerCounter];
    this.record.inspectedItems = [...this.record.inspectedItems, { approved: false, note }];
    this.inspectedCount += 1;
    this.resetTimer();
    if (this.inspectedCount === this.inspectionQuantity) this.finishInspection();
  }

  public finishInspection() {
    this.complete = true;
    this.record.finishedTime = new Date();
    this.pauseTimer();
  }

  public get estimatedRunTimeSeconds() {
    // todo
    if (!this.record.machineAssignment.operation.hasInspection || this.record.inspectionType === InspectionType.FIRST_PART) return null;
    return this.record.machineAssignment.operation.inspectionTime * 60;
  }

  public get fastestRunTimeSeconds() {
    if (this.partTimes.length === 0) return null;
    return Math.min(...this.partTimes);
  }

  public get runTimeMetricSeconds() {
    return (this.fastestRunTimeSeconds !== null && this.fastestRunTimeSeconds < this.estimatedRunTimeSeconds) ? this.fastestRunTimeSeconds : this.estimatedRunTimeSeconds;
  }

  public get runTimeMetricString() {
    
    const minutes: number = Math.floor((this.runTimeMetricSeconds) / (60)) % 60;
    const seconds = ((this.runTimeMetricSeconds) % 60).toFixed(0);

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  public get runTimeString(): string {
    const minutes: number = Math.floor(this.timerCounter / (60));
    const seconds: number = Math.floor(this.timerCounter % 60);

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  public get isOverMetric() {
    if (this.timerCounter > this.runTimeMetricSeconds) return true;
    else return false;
  }

  public get percentagePartTimeSpent() {
    if (this.timerCounter > this.runTimeMetricSeconds) return 100;
    return ((this.timerCounter) / this.runTimeMetricSeconds) * 100;
  }

  public async submitInspection() {
    
    if (this.record.inspectionType == InspectionType.FIRST_PART && this.cmmMeasurementsForm && this.cmmMeasurementsForm.invalid) {
      this.utilityService.showAlert('Invalid measurements', 'Please correct bubble measurements before submitting inspection.');
      return;
    }

    const r = await this.utilityService.showConfirmationPromise('Are you sure?', 'The inspection ticket will be closed. Floor work will be affected based on inspection results.');
    if (!r) return;

    this.loading = true;
    await this.qualityService.submitInspection(this.record).toPromise();
    this.loading = false;
    this.router.navigate(['/quality/inspection'])
  }

  public displayDimensions(dimensions: ProductMaterialDimension[]) {
    const { materialType } = this.record.machineAssignment.workOrder.product.material;
    if (!materialType || !dimensions || !materialType.displayTemplate) return null;

    if (materialType.materialTypeDimensions == null || dimensions.some(md => md.value === null)) return '[MISSING DIMENSIONS]';

    return materialType.displayTemplate.replace(/{(\w+?)}/g, (_match, group) => {
      const matchingDimension = materialType.materialTypeDimensions.find(mtd =>
        mtd.dimensionType.label.toLowerCase().trim() === group.toLowerCase().trim()
      );
      if (!matchingDimension) return '--';

      const dimensionValue = dimensions.find(
        md => md.materialTypeDimensionId === matchingDimension.materialTypeDimensionId
      );

      const out = dimensionValue ? dimensionValue.value.toFixed(3) : 'null';

      return out;
    });
  }

  @ViewChild('cmmMeasurementsForm') cmmMeasurementsForm: NgForm;

  public addMeasurement() {
    this.record.bubbleMeasurements = [...this.record.bubbleMeasurements, {
      bubbleNumber: '',
      measurementTool: '',
      bubbleSnippetDocumentId: null
    }]
  }

  public deleteMeasurement(index: number) {
    this.record.bubbleMeasurements = this.record.bubbleMeasurements.filter((_, i) => i !== index);
  }

  public openFileInput(fileInput: HTMLInputElement) {
    fileInput && fileInput.click();
  }

  public async onFileChange(event: any, measurement: BubbleMeasurement) {
    const files = (event.target as HTMLInputElement).files;
    console.log(files)
    if (files.length > 0) {
      this.loading = true;
      const doc = await this.documentService.upload(files[0] as any).toPromise();
      this.loading = false;
      measurement.bubbleSnippetDocumentId = doc.documentId;
    }
  }

  public openSnippet(documentId: string) {
    window.open(`/document/${documentId}/?inline=true`, "_blank")
  }
  
  clearSnippet(event: MouseEvent, measurement: BubbleMeasurement) {
    event.stopPropagation();
    measurement.bubbleSnippetDocumentId = null;
  }

  public printInspectionTable() {
    if (this.cmmMeasurementsForm.invalid) {
      (<any>Object).values(this.cmmMeasurementsForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    this.loading = true;
    this.qualityService.getSelfInspectionTable(this.record, this.operatorVerifyEvery).subscribe(doc => {
      this.loading = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  public operatorVerifyEvery = 1;

  public openRedTag(note: string) {
    let url = `/api/qualityTicket/generateRedTag?productId=${this.record.machineAssignment.workOrder.product.productId}&rejectionNote=${note}`;
    window.open(url, '_blank');
  }

}
