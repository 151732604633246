<nav class=" searchPager mt-3">
  <ul class="pagination justify-content-center">
    <li class="page-item">
      <a class="page-link pill" *ngIf="canDoFirst" (click)="clickPage(0)">&lt;&lt; 1</a>
    </li>
    <li class="page-item">
      <a class="page-link pill" *ngIf="canDoPrev" (click)="clickPage(page-1)">&lt; Back</a>
    </li>
    <li class="page-item" *ngFor="let p of pages">
      <a class="page-link" [ngClass]="{'active': (p==page)}" (click)="clickPage(p)">{{p+1}}</a>
    </li>
    <li class="page-item">
      <a class="page-link pill" *ngIf="canDoNext" (click)="clickPage(page+1)">Next &gt;</a>
    </li>
    <li class="page-item">
      <a class="page-link pill" *ngIf="canDoNext" (click)="clickPage(lastPage-1)">{{lastPage}} &gt;&gt;</a>
    </li>
  </ul>
</nav>
