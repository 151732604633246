import { OperatorFunction, SchedulerLike, concat } from "rxjs";
import { debounceTime, publish, take } from "rxjs/operators";

export function debounceTimeAfter<T>(
  amount: number,
  dueTime: number,
): OperatorFunction<any, any> {
  return publish(value =>
    concat(
      value.pipe(take(amount)),
      value.pipe(debounceTime(dueTime))
      ),
    );
}

export function debounceTimeAfterFirst(
  dueTime: number,
): OperatorFunction<any, any> {
  return debounceTimeAfter(1, dueTime);
}
