<div class="sortable-container">
  <div>
    <h5 class="bg-secondary text-center p-2">Current Paints</h5>
  </div>
  <ul class="sortable-list">
    <li *ngIf="!paintList">
      <p class="text-center">
        <i class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
      </p>
    </li>
    <li *ngFor="let paint of paintList" (click)="select(paint)">
      <div>
        <div class="d-inline-block mr-3 swatch" [style.background]="paint.sampleColor"></div>
        <span>{{paint.name}} <span class="small text-muted">({{getSize(paint)}})</span></span>
        <span class="text-muted float-right">{{getSheen(paint)}}</span>
      </div>
    </li>
  </ul>
</div>
