<ng-container *ngLet="filteredItems$ | async as items">
  <mat-form-field class="w-100" [ngClass]="fieldClass" [class.dense-field]="dense" appearance="outline" [floatLabel]="readonly ? 'always' : undefined" #formField>
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input
      #searchField
      matInput
      [(ngModel)]="searchText"
      #searchModel="ngModel"
      [matAutocomplete]="auto"
      #autoTrigger="matAutocompleteTrigger"
      [matAutocompleteNoDisplay]="autoTrigger"
      type="text"
      [placeholder]="readonly ? '--' : placeholder"
      [disabled]="disabled"
      [readonly]="readonly"
      [required]="required"
      (blur)="onBlur($event, auto)"
    >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <ng-container *ngIf="items">
            <mat-option [value]="{ type: 'existing', value: null }" *ngIf="nullOption && !searchText" class="text-muted">{{ nullOption === true ? '—' : nullOption }}</mat-option>
            <mat-option *ngFor="let item of items" [value]="{ type: 'existing', value: item}">
              <span [innerHTML]="getHighlitName(searchText, getSearchField(item))"></span>
          </mat-option>
        </ng-container>
        <mat-option *ngIf="canAdd && (addValid | async)" class="text-muted clientside-search-add-option" [value]="{ type: 'new', value: addConverter(searchText) }">
          <mat-icon matPrefix>add</mat-icon>
          Add <b>"{{ searchText }}"</b>
        </mat-option>
        <mat-option *ngIf="!loading && !nullOption && !canAdd && items && items?.length===0">
          <!-- <button *ngIf="canAdd()" class="float-right btn btn-primary btn-sm mt-4" (click)="onAdd($event)">
            <i class="fas fa-fw fa-lg fa-plus"></i>
            {{addItemText}}
          </button> -->
          <p class="text-muted small text-center mt-3">
            <em>
              {{noItemsText || 'No items found.'}}
            </em>
          </p>
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
</ng-container>