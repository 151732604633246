export interface TaskList {
    tasks: Task[]
}

export interface Task {
    microTaskId: string;
    status: TaskStatus,
    subtasks: Task[]
}

export enum TaskStatus {
    NOT_STARTED = 0,
    IN_PROGRESS = 1,
    DONE = 2,
    NOT_APPLICABLE = 3,
    NEEDS_QUOTE = 4
}