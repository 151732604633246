import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ProductPurchasedItem } from '../../../order/resources/product';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'purchased-item-list',
  templateUrl: './purchased-item-list.component.html',
  styleUrls: ['./purchased-item-list.component.less']
})
export class PurchasedItemListComponent {
  @Input() purchasedItems: ProductPurchasedItem[];
  @Input() editable: boolean = false;
  @Output() purchasedItemsChange: EventEmitter<ProductPurchasedItem[]> = new EventEmitter<ProductPurchasedItem[]>();
  @Output() selectionChanged: EventEmitter<ProductPurchasedItem> = new EventEmitter<ProductPurchasedItem>();
  @Output() deleted: EventEmitter<ProductPurchasedItem> = new EventEmitter<ProductPurchasedItem>();
  @Output() added: EventEmitter<ProductPurchasedItem> = new EventEmitter<ProductPurchasedItem>();
  @Input() selectedItem: ProductPurchasedItem | null = null;
  @Output() selectedItemChange: EventEmitter<ProductPurchasedItem> = new EventEmitter<ProductPurchasedItem>();

  constructor(private utilitySvc: UtilityService) { }

  public select(item: ProductPurchasedItem | null): void {
    if (item === this.selectedItem || !item) return;
    this.selectionChanged.emit(item);
    this.selectedItem = item;
  }

  public hasComparisonData(obj: any): boolean {
    var keys: string[] = Object.keys(obj);

    return keys.filter(k => k.startsWith("compareTo_")).length > 0;
  }

  public delete(item: ProductPurchasedItem): void {

    if (item.productPurchasedItemId === UtilityService.emptyGuid) {
      this.purchasedItems = this.purchasedItems.filter(i => i.productPurchasedItemId != item.productPurchasedItemId);
      this.deleted.emit(item);
      this.purchasedItemsChange.emit(this.purchasedItems);
      return;
    }

    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this purchased item?</p><p class='text-muted'>You will need to re-add this item if you change your mind.</p>", r => {
      if (r) {
        this.select(null);

        this.purchasedItems = this.purchasedItems.filter(i => i.productPurchasedItemId != item.productPurchasedItemId);

        this.purchasedItemsChange.emit(this.purchasedItems);
        this.deleted.emit(item);
      }
    });

  }

  public addItem(): void {
    var newItem = <ProductPurchasedItem>{
      productPurchasedItemId: UtilityService.emptyGuid
    };

    this.purchasedItems.push(newItem);
    this.select(newItem);

    this.purchasedItemsChange.emit(this.purchasedItems);
    this.added.emit(newItem);
  }
}
