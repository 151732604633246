import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { WorkOrder } from '../../../planning/resources/work-order';
import { WorkOrderService } from '../../../planning/services/work-order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'traveler-list',
  templateUrl: './traveler-list.component.html',
  styleUrls: ['./traveler-list.component.less']
})
export class TravelerListComponent extends SearchList<WorkOrder> {

  public loading = false;

  constructor(navService: NavigationService, private service: WorkOrderService, private router: Router, private userSvc: UserService) {
    super('TravelerListComponent');
    navService.setPageTitle("Travelers");
  }
  
  search(): void {
    this.results = null;
    this.service.search(this.managerView, this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  onSelect(record: WorkOrder): void {
    this.loading = true;
    this.service.getTraveler(record).subscribe(doc => {
      this.loading = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

}
