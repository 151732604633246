import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MaterialService } from '../../../order/services/material.service';
import { DimensionType, DimensionUnit, MaterialTypeDimension } from '../../../order/resources/material';
import { UtilityService } from '../../../common/services/utility.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'material-dimension-type-unit-select',
  templateUrl: './material-dimension-type-unit-select.component.html',
  styleUrls: ['./material-dimension-type-unit-select.component.less']
})
export class MaterialDimensionTypeUnitSelectComponent implements OnInit, OnChanges {
  public dimensionTypes: DimensionType[];

  public searching: boolean = false
  public saving: boolean = false
  @Input() materialTypeDimension: MaterialTypeDimension
  @Output() createType = new EventEmitter<string>()
  @Output() createUnit = new EventEmitter<string>()
  @Output() updated = new EventEmitter()
  @Output() deleted = new EventEmitter<void>()

  constructor() { }

  @Input() allTypes: DimensionType[]
  public typesFilter = new FormControl<string>("")
  public filteredTypes: DimensionType[] = []

  @Input() allUnits: DimensionUnit[]
  public unitsFilter = new FormControl<string>("")
  public filteredUnits: DimensionUnit[] = []

  public filterTypes() {
    this.filteredTypes = this.allTypes.filter(item => item.label.toLowerCase().indexOf(this.typesFilter.value) > -1)
  }

  public filterUnits() {
    this.filteredUnits = this.allUnits.filter(item => item.label.toLowerCase().indexOf(this.unitsFilter.value) > -1)
  }

  ngOnInit(): void {
    this.typesFilter.valueChanges.subscribe(() => this.filterTypes())
    this.unitsFilter.valueChanges.subscribe(() => this.filterUnits())
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allTypes?.previousValue !== changes.allTypes?.currentValue) this.filterTypes()
    if (changes.allUnits?.previousValue !== changes.allUnits?.currentValue) this.filterUnits()
    if (changes.materialTypeDimension && changes.materialTypeDimension.firstChange) {
      // this.setType(this.materialTypeDimension.dimensionTypeId)
      // this.setUnit(this.materialTypeDimension.dimensionUnitId)
    }
  }

  // setType(typeId: string) {
  //   this.materialTypeDimension.dimensionType = this.allTypes.find(x => x.dimensionTypeId === typeId)
  //   if (this.typeInvalid) this.validate()
  //   this.updated.emit()
  // }

  // setUnit(unitId: string) {
  //   this.materialTypeDimension.dimensionUnit = this.allUnits.find(x => x.dimensionUnitId === unitId)
  //   if (this.unitInvalid) this.validate()
  //   this.updated.emit()
  // }

  public typeInvalid = false;
  public unitInvalid = false;
  validate() {
    let valid = true;
    if (!this.materialTypeDimension.dimensionType) { this.typeInvalid = true; valid = false }
    else this.typeInvalid = false
    if (!this.materialTypeDimension.dimensionUnit) { this.unitInvalid = true; valid = false }
    else this.unitInvalid = false
    return valid
  }

  public compareUnit(u1: DimensionUnit, u2: DimensionUnit) {
    return u1?.dimensionUnitId === u2?.dimensionUnitId;
  }

  public compareType(u1: DimensionType, u2: DimensionType) {
    return u1?.dimensionTypeId === u2?.dimensionTypeId;
  }

}
