<div class="d-flex flex-column">
  <div class="row">
    <div class="col">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Filter Locations</mat-label>
        <input matInput autocomplete="off" type="text" [formControl]="locationFilterControl">
      </mat-form-field>
    </div>
  </div>
  <div class="row flex-grow-1" (mousedown)="onTouched()">
    <div class="col" #locationTreeCard>
      <div class="locations-tree-label">{{ label }} *</div>
      <mat-card class="mat-elevation-z0 mat-card-outline p-0" style="height: 400px !important">
        <mat-tree *ngIf="dataSource.data?.length > 0" [dataSource]="dataSource" [treeControl]="treeControl"
          class="example-tree" style="margin-bottom: -1px">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [class.item-location-node]="isItemLocation(node)">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button class="invisible mx-1" *ngFor="let _ of range(getLevel(dataSource.data, node))" disabled>
              <mat-icon class="mat-icon-rtl-mirror">
                chevron_right
              </mat-icon>
            </button>
            <button mat-icon-button class="mx-1" *ngIf="node.children?.length === 0" disabled
            matTooltip="No child locations"
            >
              <mat-icon class="text-muted mat-icon-rtl-mirror invisible">
                my_location
              </mat-icon>
            </button>
            <ng-container *ngIf="!isItemLocation(node)">
              <ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node }"></ng-container>
            </ng-container>
            <ng-container *ngIf="isItemLocation(node)">
              <item-type-chip [item]="takingItem" [iconOnly]="true" class="mx-1"></item-type-chip>
              <div class="item-location-info">
                <div class="font-weight-bold">
                  {{ takingItem.name }}
                </div>
                <div *ngIf="node.workOrder" class="mr-1 badge badge-sm badge-info small">
                  WO#: {{ node.workOrder.workOrderNumber }}
                </div>
                <div *ngIf="node.purchaseOrder" class="mr-1 badge badge-sm badge-success small">
                  {{ node.purchaseOrder.purchaseOrderNumber }}
                </div>
                <div *ngIf="node.rmaTicket" class="mr-1 badge badge-sm badge-danger small">
                  {{ node.rmaTicket.rmaNumber }}
                </div>
              </div>
              <mat-icon class="ml-auto mr-1 text-danger" *ngIf="getWarnings(node).length > 0" [matTooltip]="getWarningTooltip(node)" matTooltipPosition="after">warning</mat-icon>
              <ng-container *ngTemplateOutlet="pickerTemplate; context: { $implicit: node }"></ng-container>
            </ng-container>
          </mat-tree-node>
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div>
              <div class="mat-tree-node">
                <button mat-icon-button class="invisible mx-1" *ngFor="let _ of range(getLevel(dataSource.data, node))">
                  <mat-icon class="mat-icon-rtl-mirror">
                    chevron_right
                  </mat-icon>
                </button>
                <button type="button" mat-icon-button class="mx-1" (click)="toggleNode(node)" *ngIf="!node.loading"
                  [attr.aria-label]="'toggle ' + node.id">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <button mat-icon-button disabled class="mx-1" *ngIf="node.loading">
                  <mat-spinner class="mx-1" color="black" diameter="24"></mat-spinner>
                </button>
                <ng-container *ngTemplateOutlet="nodeNameTemplate; context: { $implicit: node }">
                </ng-container>
              </div>
              <ng-container matTreeNodeOutlet *ngIf="treeControl.isExpanded(node)"></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
        <div
          class="text-muted h-100 w-100 mat-body-2 font-italic d-flex flex-column align-items-center justify-content-center"
          *ngIf="dataSource.data?.length === 0 && !searchingLocations">
          <mat-icon class="my-2">dangerous</mat-icon>
          No matching locations found.
        </div>
        <div class="searching-locations-indicator text-muted font-italic" *ngIf="searchingLocations">
          <mat-spinner class="my-1" diameter="32"></mat-spinner>
          Searching locations...
        </div>
      </mat-card>
    </div>
  </div>
</div>


<ng-template #nodeNameTemplate let-item>
  <span [innerHTML]="item.name | highlight: (!searchingLocations ? locationFilterControl.value: '') : '<span class=\'tree-filter-match\'>$&</span>'
    ">{{ item.name }} {{item.childrenLoaded | json}}</span>
</ng-template>

<ng-template #pickerTemplate let-item>
  <div class="pick-buttons ml-auto" *ngIf="item.quantity > 0">
    <button type="button" [disabled]="(this.locationsPicked[item.inventoryItemLocationId]?.amt || 0) === 0" mat-icon-button color="black" class="mx-1" (click)="clearLocation(item)">
      <mat-icon>
        close
      </mat-icon>
    </button>
    <button type="button" [disabled]="(this.locationsPicked[item.inventoryItemLocationId]?.amt || 0) === 0" mat-icon-button color="black" class="mx-1" (click)="decreaseLocation(item)">
      <mat-icon>
        remove
      </mat-icon>
    </button>
    <input type="text" class="amount-input" [value]="this.locationsPicked[item.inventoryItemLocationId]?.amt || 0" (input)="onAmountInputChange($event, item)">
    <span class="d-inline-flex ml-1" *ngIf="takingItem">
      of {{ item.quantity }}
    </span>
    <button type="button" [disabled]="this.max && (this.availableMax === 0)" mat-icon-button color="black" class="mx-1" (click)="increaseLocation(item)">
      <mat-icon>
        add
      </mat-icon>
    </button>
    <button type="button" [disabled]="this.max && (this.availableMax === 0)" mat-icon-button color="black" class="mx-1" (click)="maxOutLocation(item)" *ngIf="this.max">
      <mat-icon>
        select_all
      </mat-icon>
    </button>
  </div>
  <div *ngIf="takingItem && item.quantity === 0" class="ml-auto small text-uppercase mx-4 font-italic text-muted">
    -- None available --
  </div>
</ng-template>


