import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { WorkOrder } from '../../resources/work-order';
import { WorkOrderService } from '../../services/work-order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';
import { PlanningTicket } from '../../resources/planning-ticket';
import { PlanningService } from '../../services/planning.service';
import { FilterParam, SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { User } from '../../../common/resources/user';
import { ProgrammingTicket } from '../../resources/programming-ticket';

@Component({
  selector: 'planning-list',
  templateUrl: './planning-list.component.html',
  styleUrls: ['./planning-list.component.less']
})
export class PlanningListComponent {

  constructor(navService: NavigationService, private service: PlanningService, private router: Router, private userSvc: UserService) {
    navService.setPageTitle("Planning");
  }
  
  public search({ searchText, page, sort, forAllUsers, filters, fieldSearches }: SearchData) {
    return this.service.search(forAllUsers, searchText, page.pageIndex, sort.active, sort.direction, filters, fieldSearches);
  }

  onSelect(record: PlanningTicket): void {
    this.router.navigate(['/planning', record.planningTicketId]);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

  public userIsManager(user: User): boolean {
    return this.userSvc.canAccess('PlanningManager', user);
  }

  public readonly availableStatuses = [OrderStatus.QC_HOLD, OrderStatus.UNASSIGNED, OrderStatus.IN_PROCESS, OrderStatus.FULFILLED] as const;

  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    }))
  }];

  public readonly defaultStatuses = [OrderStatus.UNASSIGNED, OrderStatus.IN_PROCESS] as const;

  public readonly defaultFilters = { status: this.defaultStatuses }

  public getDaysLate(ticket: PlanningTicket) {
    const till = new Date();
    const since = (ticket.planningPromiseDate && new Date(ticket.planningPromiseDate) < new Date(ticket.requiredDate)) ? ticket.planningPromiseDate : ticket.requiredDate;
    if (!since) return null;
    return Math.max(0, Math.floor((+new Date(till) - +new Date(since))/(24*60*60*1000)));
  }
  
  public getHighlightClass(due: string, now: any) {
    if (!due) {
      return '';
    }

    const target = new Date(due);
    if (!now) now = new Date();
    else now = new Date(now);
    const daysBetween = (+target - +now) / (24*60*60*1000);

    if (daysBetween < 1) {
      return 'bg-danger text-white';
    } else if (daysBetween < 3) {
      return 'bg-warning';
    }

    return '';
  }}
