<div class="border rounded d-flex flex-column h-100-t" #rootEl *ngLet="data$ | async as data">
  <mat-tab-group class="tab-group-hide-header" style="min-height: 500px; height: 100%;" [(selectedIndex)]="selectedTab">
    <mat-tab label="select">
      <div class="p-3 border-bottom mb-2 d-flex w-100 align-items-center">
        <div style="font-size: 1.25rem" class="font-weight-normal">Quotes</div>
        <button mat-stroked-button class="button-text ml-auto" (click)="openQuoteHistory()">History</button>
      </div>
    <div class="p-3 border-bottom" *ngIf="shouldShowHistory">
      <price-history-chart [materialId]="materialId" [stationId]="stationId" [purchasedItemId]="purchasedItemId" [orderId]="order.orderSegmentId"></price-history-chart>
    </div>
    <div *ngIf="!data || loading" style="min-height: 10em;" >
      <mat-progress-bar class="w-100 progress-bar" mode="indeterminate"></mat-progress-bar>
  
    </div>
    <div *ngIf="data" class="position-relative">
      <button *ngIf="editable" mat-fab class="extended-fab-button extended-fab-small button-text" color="primary" style="position: absolute; top: -3em; right: 2em; z-index: 10"
        (click)="createNew()"
      >
        <mat-icon svgIcon="file-document-plus-outline"></mat-icon>
        Quick Quote
      </button>
      <mat-list [class.mt-4]="editable">
        <div [matMenuTriggerFor]="contextMenu" #menuTrigger="matMenuTrigger" style="position: fixed;" [style]="{
          left: menuPosition.x + 'px',
          top: menuPosition.y + 'px'
        }">
        </div>
        <mat-menu #contextMenu>
          <button mat-menu-item *ngIf="menuQuote" (click)="viewQuote(menuQuote)">
            <!-- <mat-icon matPrefix class="text-muted">edit</mat-icon> -->
            {{ editable ? 'View/Edit Quote' : 'View Quote' }}
          </button>
        </mat-menu>
      <mat-list-item *ngFor="let quote of data" #option [class.selected]="option.selected" class="border-bottom" (click)="selectQuote(quote.materialBidId)" [matRipple]="editable"
          (contextmenu)="openMenu($event, quote)"
        >
          <div class="w-100 d-flex quote-item" [ngStyle]="{
            cursor: editable ? 'pointer' : 'default'
          }">
            <mat-radio-button [name]="'selectedQuote-' + filterId" class="mr-2 align-self-center" [checked]="value === quote.materialBidId" [ngStyle]="{
              'pointer-events': editable ? 'all' : 'none'
            }"></mat-radio-button>
            <div>
              <div>
                <b>{{ quote.vendor?.name ?? 'Unknown Vendor' }}</b>
              </div>
              <div class="lead-time">
                Lead time: <b>{{quote.leadTimeDays}}</b> days
              </div>
            </div>
            <div class="ml-auto">
              <b>{{((quote.perItemBid || (quote.totalBid/quote.qty)) | currency) || '--'}}</b>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="data.length === 0 && !isNewMaterial && !(showExpired | async)" class="text-muted text-center">
          <div>
            <div class="small">No unexpired quotes are available for this item.</div>
            <button mat-button class="mt-1 button-text" color="primary" (click)="showExpiredQuotes()">Show Expired</button>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="data.length === 0 && (isNewMaterial || (showExpired | async))" class="text-muted text-center">
          <div class="small w-100 text-center">No existing quotes are available for this item.</div>
        </mat-list-item>
      </mat-list>
    </div>
    </mat-tab>
    <mat-tab label="create">
      <product-quoting-new-quote *ngIf="newQuote" [record]="newQuote" [order]="order" (done)="quoteDone($event)" (cancel)="quoteCancel()"></product-quoting-new-quote>
    </mat-tab>
  </mat-tab-group>
</div>