<div class="row">
  <div class="col-7">
    <div>
      <h6 class="text-center m-3">
        {{getStation(editingItem?.stationId)?.name}}
      </h6>
      <div class="form-group">
        <div>
          <div class="col">
            <mat-checkbox [(ngModel)]="editingItem.isStandalone">Standalone
              Step</mat-checkbox>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div>
          <mat-radio-group
            [disabled]="editingItem.isStandalone"
            [(ngModel)]="editingItem.runIsPerPart" aria-label="Select an option">
            <mat-radio-button [value]="true">Per Part</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-2">Per Job</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="form-group">
        <label>Outsource Markup</label>
        <div class="input-group input-group-sm">
          <input type="number" appStaticEdit [editable]="true"
            [(ngModel)]="editingItem.outsourceMarkup"
            class="form-control form-control-sm form-inline text-right">
          <div class="input-group-append">
            <span class="input-group-text">
              %
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <ng-container *ngIf="!getStation(editingItem?.stationId)?.isPainting">
          <label>
            Rate
          </label>
          <div class="font-bold">
            ${{editingItem.runPrice}}
          </div>
        </ng-container>
        <div class="pt-2" *ngIf="getStation(editingItem?.stationId)?.isPainting">
          <div class="form-group">
            <label>Paint Selection</label>
            <div class="container-fluid row p-0 m-0">
              <div class="col-2">
                <span class="d-inline-block paintSwatch"
                  [style.background]="editingItem.paint?.sampleColor"></span>
              </div>
              <div class="col-10 p-0">
                <paint-select [editable]="true" (addPaint)="addPaint($event)"
                  [paint]="editingItem.paint" (paintChange)="assignPaint($event)"
                  ></paint-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Painted Area</label>
            <div class="input-group input-group-sm">
              <input type="number" appStaticEdit [editable]="true"
                [(ngModel)]="editingItem.paintedArea"
                class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text">
                  in<sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Number of Coats</label>
            <input type="number" appStaticEdit [editable]="true"
              [(ngModel)]="editingItem.paintCoats"
              class="form-control form-control-sm form-inline text-right">
          </div>
          <div class="form-group">
            <label>Labor Cost</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  $
                </span>
              </div>
              <input type="number" appStaticEdit [editable]="true"
                [(ngModel)]="editingItem.runPrice"
                class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text">
                  / in<sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Paint Cost</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  $
                </span>
              </div>
              <input type="number" appStaticEdit [editable]="true"
                [(ngModel)]="editingItem.paintCost"
                class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text">
                  / in<sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Minimum Charge</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  $
                </span>
              </div>
              <input type="number" appStaticEdit [editable]="true"
                [(ngModel)]="editingItem.paintMinPrice"
                class="form-control form-control-sm form-inline text-right">
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light">
        <div class="w-100 p-1 bg-secondary text-center text-secondary">Notes</div>
        <div class="p-1">
          <textarea rows="6" appStaticEdit [editable]="true"
            class="form-control form-control-sm" [(ngModel)]="editingItem.description"
            placeholder="Station/Process Notes"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="col-5">
    <div class="sortable-container" (drop)="onDrop($event, data.steps.length)" (dragover)="allowDrop($event,data.steps.length)">
      <div>
        <div class="float-right mt-1 mr-1">
          <button (click)="moveUp()" class="btn btn-light btn-sm ml-1 mr-1" title="Move Up"><i class="fas fa-fw fa-lg fa-arrow-circle-up text-info"></i></button>
          <button (click)="moveDown()" class="btn btn-light btn-sm" title="Move Down"><i class="fas fa-fw fa-lg fa-arrow-circle-down text-info"></i></button>
        </div>
        <h5 class="bg-secondary text-center p-2">Workflow</h5>
      </div>
      <ul class="sortable-list">
        <li draggable="true" *ngFor="let item of data.steps; let i = index;"
        [class.new-workflow-step]="item === editingItem"
            (drop)="onDrop($event, i)" (dragover)="allowDrop($event,i)" (dragstart)="onDragStart($event, item, i)">
          <div class="drop-zone text-white text-center font-italic" *ngIf="onDragOverIndex == i">
            -- Drop Here to Add/Reorder --
          </div>
          <div
            
          >
            <i [title]="!item?.runIsPerPart?'Per Job':''" [ngClass]="{'fa-cogs':!item?.runIsPerPart}" class="fas fa-fw fa-lg text-muted"></i>
            <i [title]="getStation(item)?.isOutsourceStep?'Outsourced':''" [ngClass]="{'fa-sign-out-alt':getStation(item)?.isOutsourceStep}" class="fas fa-fw fa-lg text-muted"></i>
            <i [title]="getStation(item)?.isPainting?'Painting':''" [ngClass]="{'fa-fill-drip':getStation(item)?.isPainting}" class="fas fa-fw fa-lg text-muted"></i>
            <i [title]="item.outsourceIsFinal?'Tentative':''" [ngClass]="{'fa-question':(getStation(item)?.isOutsourceStep && !item.outsourceIsFinal)}" class="fas fa-fw fa-lg text-muted"></i>
            {{getStation(item)?.name}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<mat-card-actions align="end" class="mt-4 mb-0">
  <button mat-flat-button [matDialogClose]="null">Cancel</button>
  <button mat-flat-button color="primary" [matDialogClose]="data.steps">Accept</button>
</mat-card-actions>