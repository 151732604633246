import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderOverview } from '../../../../planning/resources/work-order';
import { InspectionTicket } from '../../../../quality/resources/inspection-ticket';
import { WorkflowStep } from '../../../../order/resources/workflow';
import { Station } from '../../../../order/resources/station';
import { StationService } from '../../../../order/services/station.service';

interface InspectionTicketData {
  workflowStep: WorkflowStep;
  ticketData: InspectionTicket;
}

@Component({
  selector: 'oor-inspection',
  templateUrl: './oor-inspection.component.html',
  styleUrls: ['./oor-inspection.component.less']
})
export class OorInspectionComponent implements OnInit {

  @Input() record: WorkOrderOverview;

  public get workflow() {
    return (this.record.workflowSteps ?? []).sort((a, b) => a.stepOrder - b.stepOrder);
  }

  public get inspectionTickets(): InspectionTicketData[] {
    return this.workflow.flatMap(s => (s.machineAssignments ?? [])
      .flatMap(a => a.inspectionTickets.flatMap(it => ({
        workflowStep: s,
        ticketData: it
      }) ?? []))
    );
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public getName(step: WorkflowStep) {
    return this.getStation(step)?.name ?? 'Unknown Station';
  }

  public isDone(ticket: InspectionTicket) {
    return !!ticket.finishedTime;
  }

  constructor(private stationService: StationService) { }

  public stationList: Station[]
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

  public contractReviewIsDone() {
    return this.record.contractReviewTicket?.status === 4;
  }

}
