import { Component, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { NavigationService } from '../../../common/services/navigation.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent {

  constructor(
    navService: NavigationService,
  ) {
    navService.setPageTitle("Dashboard");
  }


}
