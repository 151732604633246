<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h5 class="mt-2">Notifications Subscribed To</h5>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <p *ngIf="!lNotificationConfiguration"><em>Loading...</em></p>
  <div *ngIf="lNotificationConfiguration">

    <mat-label>Filter</mat-label>
    <mat-form-field fxFlex="40%" class="filterForm">
      <input matInput #inputFilter type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" *ngIf="lNotificationConfiguration" class="mat-elevation-z8" style="width:100%;" >
      <ng-container matColumnDef="TypeName">
        <th mat-header-cell *matHeaderCellDef>Type Name</th>
        <td mat-cell *matCellDef="let notificationConfiguration">{{notificationConfiguration.notificationTypeName}}</td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let notificationConfiguration">{{notificationConfiguration.action}}</td>
      </ng-container>

      <ng-container matColumnDef="NotificationPreferredChannels">
        <th mat-header-cell *matHeaderCellDef>Notification Channel/s</th>
        <td mat-cell *matCellDef="let notificationConfiguration">
          <mat-form-field appearance="standard">
            <mat-select [compareWith]="compareFnChannels" [(ngModel)]="notificationConfiguration.notificationRecipientGroup_PreferredChannelsIds" multiple (selectionChange)="onSelectionChangeChannels(notificationConfiguration, $event.value)" [disabled]="!this.editable">
              <mat-option [disabled]="notificationConfiguration.recipientCanChangeDefaultChannel==false" [ngClass]="{'disabled-class': notificationConfiguration.recipientCanChangeDefaultChannel==false}" *ngFor="let channel of lChannels" [value]="channel">{{channel.channelName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" [ngClass]="{'highlightrow-TypeNeedsToBeSetUp': myRowData.typeNeedsToBeSetUp == true, 'mat-row-hover': this.editable}"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{inputFilter.value}}"</td>
      </tr>
    </table>
    <br />
    <mat-paginator #paginator [pageSizeOptions]="[3, 5, 10, 20, 50]" [pageSize]="5" showFirstLastButtons></mat-paginator>
  </div>

</mat-expansion-panel>

