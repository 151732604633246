<mat-sidenav-container>
  <div class="position-relative order-detail-root" [class.solo-mode]="service.solo" ngForm #form="ngForm" *ngLet="(service.loading$ | async) as loading">
      <div class="d-flex button-holder" style="
        position: absolute; z-index: 15;"
      *ngIf="!loading && record"
    >
      <button mat-fab tabindex="-1" class="extended-fab-button mr-2" color="warn"
        *ngIf="!editing && canBeClosed" (click)="closeOrder()">
        <mat-icon svgIcon="archive-remove"></mat-icon>
        <span class="extended-fab-button__text">Close</span>
      </button>
      <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-primary mr-2"
        *ngIf="record.discriminator === 'Estimate' && !service.quotePreviewWindow" (click)="viewPdf()">
        <mat-icon svgIcon="file-table"></mat-icon>
        <span class="extended-fab-button__text">Preview Quote</span>
      </button>
      <button mat-fab tabindex="-1" class="extended-fab-button mr-2 bg-white text-dark"
        *ngIf="record.discriminator === 'Estimate' && !!service.quotePreviewWindow" (click)="viewPdf()"
        matTooltipPosition="above"
        matTooltip="Click to focus preview. Close preview window to stop."
        >
        <ng-container *ngIf="!service.quotePreviewLoading">
          <ng-container *ngTemplateOutlet="waitingDots"></ng-container>
        </ng-container>
        <mat-spinner diameter="20" color="black" class="preview-spinner" *ngIf="service.quotePreviewLoading"></mat-spinner>
        <span class="extended-fab-button__text">Live Previewing Quote...</span>
      </button>
      <!-- RFQ Approval -->
      <ng-container *ngIf="service.canApproveRFQ()">
        <button mat-fab tabindex="-1" class="extended-fab-button text-white mr-2"
          (click)="approveRFQ()" [disabled]="!allProductsReviewed()" [class.bg-success]="allProductsReviewed()"
          [matTooltip]="allProductsReviewed() ? undefined : 'Please review all parts below before approving.'"
          >
          <mat-icon>check</mat-icon>
          <span class="extended-fab-button__text">Approve</span>
        </button>
        <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-danger mr-2"
          (click)="rejectRFQ()">
          <mat-icon>close</mat-icon>
          <span class="extended-fab-button__text">Reject</span>
        </button>
      </ng-container>
      <!-- /RFQ Approval -->
      <button mat-fab tabindex="-1" class="extended-fab-button text-white mr-2"
        [class.bg-success]="service.estimateIsApprovable()"
        [disabled]="!service.estimateIsApprovable()"
        *ngIf="record.discriminator === 'Estimate' && record.status === 1 && canApproveEstimate()" (click)="approveEstimate()">
        <mat-icon>check</mat-icon>
        <span class="extended-fab-button__text">Approve</span>
      </button>
      <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-danger mr-2"
        *ngIf="record.discriminator === 'Estimate' && record.status === 1 && canApproveEstimate()" (click)="rejectOrder()">
        <mat-icon>close</mat-icon>
        <span class="extended-fab-button__text">Reject</span>
      </button>
      <button mat-fab tabindex="-1" class="extended-fab-button mr-2" color="primary"
        *ngIf="!editing && record.discriminator === 'RFQ' && (record.status === 11 || record.status === 19)" (click)="cloneRFQ()">
        <mat-icon svgIcon="content-duplicate"></mat-icon>
        <span class="extended-fab-button__text">Clone RFQ</span>
      </button>
      <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-success mr-2"
        *ngIf="!editing && (record.discriminator === 'Estimate' || record.discriminator === 'RMAEstimate') && (record.status === 0 || record.status === 3)" (click)="sendEstimateToApproval()">
        <mat-icon>send-variant</mat-icon>
        <span class="extended-fab-button__text">Send for Approval</span>
      </button>
      <button mat-fab tabindex="-1" class="bg-success text-white"
        *ngIf="!editing && isEditable(record.status)" (click)="service.startEditing()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-success"
        *ngIf="editing" (click)="save()">
        <mat-icon>save</mat-icon>
        <span class="extended-fab-button__text">Save Changes</span>
      </button>
      <button mat-fab tabindex="-1" class="bg-danger text-white ml-2" *ngIf="editing || selectedProductId" (click)="service.stopEditing()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div [class.rounded]="!service.solo" class="mat-elevation-z4 position-relative d-flex flex-column h-100-t" style="overflow: hidden;">
      <mat-progress-bar class="w-100 progress-bar" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <div class="bg-dark text-white d-flex align-items-center p-3 small" [class.main-disabled]="loading" *ngIf="!service.solo" style="min-height: 68px;">
        &nbsp;
        <button mat-stroked-button class="text-white border-white mr-3" (click)="goToOldUI()" *ngIf="!editing || id === 'new'">
          Switch to Old UI
        </button>
        <div *ngIf="record?.status === 1 && record?.discriminator === 'Estimate'">
          This estimate is currently awaiting approval.
        </div>
      </div>
      <div class="bg-dark" style="height: 8px;" *ngIf="service.solo">&nbsp;</div>
      <mat-tab-group [animationDuration]="'0ms'" *ngIf="record" [class.main-disabled]="loading" #tabGroup class="main-tab-group mat-tab-group-colgrow mat-tab-group-headerstyle flex-grow-1" [class.order-tab-group-inactive]="!!selectedProductId"  [(selectedIndex)]="selectedTab" (click)="onTabGroupClick($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">description</mat-icon>
            Order Information
          </ng-template>
            <div class="row h-100 w-100 m-0">
              <div class="pt-4" [class.d-none]="selectedProductId">
                <order-detail-form [editing]="editing"></order-detail-form>
              </div>
              <div [class.d-none]="!selectedProductId" class="w-100">
                <product-detail-new [(selectedTab)]="productSelectedTab"></product-detail-new>
              </div>
            </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon svgIcon="file-document-multiple" class="mr-2"></mat-icon>
            Order Documents
          </ng-template>
          <order-detail-documents [documents]="convertDocuments(record.documents)" (documentsChange)="updateDocuments($event); notifyEdited()" [editing]="editing" [uploadFunction]="this.service.batchUploadDocuments.bind(service)"></order-detail-documents>
        </mat-tab>
        <mat-tab *ngIf="record.rmaTicket">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">receipt_long</mat-icon>
            RMA Information
          </ng-template>
          <div class="p-3 mt-3 h-100">
            WIP
          </div>
        </mat-tab>
        <mat-tab *ngIf="record.discriminator==='Estimate'">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">receipt_long</mat-icon>
            Pricing and Lead Times
          </ng-template>
          <div class="h-100-t">
            <order-detail-estimating-screen></order-detail-estimating-screen>
          </div>
        </mat-tab>
        <mat-tab *ngIf="record.discriminator==='Estimate'">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" svgIcon="calculator-variant"></mat-icon>
            Pricing Calculation Breakdown
          </ng-template>
          <div class="h-100-t">
          <ng-template matTabContent>
            <order-detail-pricing-breakdown></order-detail-pricing-breakdown>
          </ng-template>
          </div>
        </mat-tab>
        <mat-tab *ngIf="record.discriminator=='Quote'">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">receipt_long</mat-icon>
            Formal Quoting and Acceptance
          </ng-template>
          <div class="p-3 mt-3 h-100">
            WIP
          </div>
        </mat-tab>
        <mat-tab *ngIf="record.discriminator=='Quote'">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">receipt_long</mat-icon>
            Accepted Purchase Orders
          </ng-template>
          <div class="p-3 mt-3 h-100">
            WIP
          </div>
        </mat-tab>
        <mat-tab *ngIf="!!record.salesProcessId">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" svgIcon="message-bulleted"></mat-icon>
            Sales Notes
          </ng-template>
          <sales-notes [processId]="record.salesProcessId"></sales-notes>
        </mat-tab>
        <mat-tab label="Microtickets" *ngIf="!!record.salesProcessId">
            <order-detail-microticket-screen>
            
            </order-detail-microticket-screen>
        </mat-tab>
      </mat-tab-group>
    </div>  
  </div>
  <mat-sidenav *ngLet="service.sidenavMode | async as sidenavMode" mode="over" position="end" style="width: 82%; overflow: visible"
  (closedStart)="service.resetSidenav()"
  >
  <div style="position: absolute; top: 3.5em; left: -1.7em; z-index: 9;">
    <button mat-fab tabindex="-1" class="bg-danger text-white" (click)="service.sidenav.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngLet="service.sidenavData | async as sidenavData">
    <product-quote-detail-new *ngIf="sidenavMode === 'quoteDetail'" [editing]="service.editing" [quote]="sidenavData.quote" (newDocs)="service.addNewIds($event)"></product-quote-detail-new>
    <item-quote-history *ngIf="sidenavMode === 'quoteHistory'" [filterId]="sidenavData"></item-quote-history>
  </ng-container>
  </mat-sidenav>
</mat-sidenav-container>

<ng-template #waitingDots>
  <div class="waiting-dots-container mat-icon">
    <mat-icon svgIcon="dots-horizontal" class="waiting-dot waiting-dots-1"></mat-icon>
    <mat-icon svgIcon="dots-horizontal" class="waiting-dot waiting-dots-2"></mat-icon>
    <mat-icon svgIcon="dots-horizontal" class="waiting-dot waiting-dots-3"></mat-icon>
  </div>
</ng-template>

<ng-template #closeOrderDialogTemplate let-data>
  <h2 mat-dialog-title>Closing This {{ discriminator }}</h2>
  <mat-dialog-content class="mat-typography">
    <p>Are you sure you want to close this {{ discriminator | lowercase }}? This cannot be undone.</p>
    <p>If so, please provide a reasoning below.</p>
    <p class="text-muted">
      The {{ discriminator | lowercase }} and all its associated parts will remain in the database to be used in the future. If this data needs to be
      scrubbed from the database, please contact a developer or sysadmin.
    </p>
    <p>
      <mat-form-field appearance="outline" class="w-100">
        <textarea matInput [(ngModel)]="data.reason"
          placeholder="Provide a reason for closing..."></textarea>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="button-text mr-2" [mat-dialog-close]="null">Cancel</button>
    <button mat-flat-button color="warn" class="button-text" [disabled]="!data.reason" [mat-dialog-close]="data">Close Order</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #rejectRfqTemplate let-data>
  <h2 mat-dialog-title>Reject RFQ?</h2>
  <mat-dialog-content class="mat-typography">
    <p>Are you sure you want to reject this RFQ? If so, explain why.</p>
    <mat-form-field class="w-100" appearance="outline">
      <textarea matInput [(ngModel)]="data.note" rows="3"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="button-text" [mat-dialog-close]="null">Cancel</button>
    <button mat-flat-button color="warn" class="button-text" [disabled]="!data.note" [mat-dialog-close]="data.note">Reject</button>
</mat-dialog-actions>
</ng-template>

<ng-template #backupTemplate let-data>
  <h2 mat-dialog-title>Error Saving Data</h2>
  <mat-dialog-content class="mat-typography">
    <p>A server error occurred while trying to save your data.</p>
    <p>You can download a backup of the data you attempted to save using the button below. It can be sent to the developers to aid them in debugging and recovering your data. The file can be used by them to restore your data even if you leave this screen.</p>
    <p>
    The data you attempted to save will also be preserved in the UI, so you can try saving again in case the error was due to a temporary outage.
    </p>
    <a class="mat-card my-2 p-3 border rounded mat-elevation-z0 d-flex align-items-center" [attr.download]="data.filename" [attr.href]="data.encodedUri">
      <mat-icon svgIcon="file-code" style="width: 48px; height: 48px;"></mat-icon>
      <div>
        <div class="mat-line font-weight-medium">{{ data.filename }}</div>
        <div class="mat-line text-muted">{{ data.fileSize }}</div>
      </div>
      <div class="ml-auto text-muted font-weight-medium">Click to Download</div>
      <mat-icon svgIcon="download" class="text-muted" style="width: 48px; height: 48px;"></mat-icon>
    </a>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="button-text" [mat-dialog-close]>Return to UI</button>
  </mat-dialog-actions>
</ng-template>