import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderOverview } from '../../../../planning/resources/work-order';
import { ShippingTicket } from '../../../../shipping/resources/shipping-ticket';

@Component({
  selector: 'oor-shipping',
  templateUrl: './oor-shipping.component.html',
  styleUrls: ['./oor-shipping.component.less']
})
export class OorShippingComponent implements OnInit {

  @Input() record: WorkOrderOverview;

  public get shippingTickets() {
    return this.record.shippingTickets.filter(st => !!st.inventoryItem.productId);
  }

  public getTicketNumber(ticket: ShippingTicket) {
    return `${this.record.workOrderNumber}//${ticket.ticketNumber.toString().padStart(3, '0')}`;
  }

  public isShipped(ticket: ShippingTicket) {
    return !!ticket.departureDate;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
