<loading *ngIf="loading" content="Loading..."></loading>
<ordersegment-list-new
  [displayedColumns]="['customer', 'orderNumber', 'estimateNumber', 'customerRfqnumber', 'partHistory', 'assigned', 'received', 'required', 'estimatedEstimateCompletionDate', 'status']"
  orderNumberTitle="RFQ"
  [showAdd]="true"
  [showManagerToggle]="isManager"
  [isManager]="userIsManager.bind(this)"
  path="/rfqs/"
  discriminator="RFQ"
  localStorageId="sales-rfq-list"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
  <button preSearchContent mat-flat-button color="primary" class="button-text mr-2" style="width: 280px;" (click)="generateReport()">RFQ Status Report</button>
</ordersegment-list-new>