import { Component } from '@angular/core';
import { NavigationService } from '../../../common/services/navigation.service';
import { Router } from '@angular/router';
import { UserService } from '../../../common/services/user.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { PurchaseOrder, PurchaseOrderStatus } from '../../resources/purchaseOrder';
import { PurchasingService } from '../../services/purchasing.service';
import { UtilityService } from '../../../common/services/utility.service';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { Subscription } from 'rxjs';
import { User } from '../../../common/resources/user';

@Component({
  selector: 'app-purchasing-queue',
  templateUrl: './purchasing-queue.component.html',
  styleUrls: ['./purchasing-queue.component.less']
})
export class PurchasingQueueComponent {

  constructor(navService: NavigationService, private service: PurchasingService, private router: Router, private userSvc: UserService) {
    navService.setPageTitle("Purchasing Orders");
  }

  public search({ searchText, page, sort, forAllUsers, fieldSearches }: SearchData) {
    return this.service.searchPurchaseOrders(forAllUsers ?? false, searchText, page.pageIndex, page.pageSize, sort.active, sort.direction, fieldSearches);
  }

  onSelect(record: PurchaseOrder ): void {
    this.router.navigate(['/purchasing/order', record.purchaseOrderId]);
  }

  daysLeft(date: string, abs: boolean): number | null {
    if (date == null) return null;

    return UtilityService.getDaysRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getStatusColorClass(status: number): string {
    return PurchaseOrderStatus.getStatusBackgroundColorClass(status);
  }

  getStatusText(status: number): string {
    return PurchaseOrderStatus.getStatusText(status);
  }

  public getProcessText(process: number): string {
    return ShippingService.getShippingClassText(process);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }
  
  public userIsManager(user: User): boolean {
    return this.userSvc.canAccess('PurchasingManager', user);
  }
}
