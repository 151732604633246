<div class="p-3 pb-4 position-relative">
    <mat-card-title>
        {{ isNew ? 'New Note' : 'Editing Note' }}
    </mat-card-title>
    <mat-form-field class="w-100 mb-2" appearance="outline">
        <mat-label>Note Content</mat-label>
        <textarea matInput [(ngModel)]="note.content">
    </textarea>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100 dense-field" floatLabel="always"
        *ngLet="(filteredTags$ | async) as tags">
        <mat-label>Tags</mat-label>
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let tag of note.tags; index as i" [removable]="true" (removed)="removeTag(i)">
                {{tag}}
                <button matChipRemove>
                    <mat-icon>close</mat-icon>
                </button>
            </mat-chip>
            <input [matChipInputFor]="chipList" ngModel #inputModel="ngModel" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
           [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event.value, $event.chipInput)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag($event.option.value)">
            <mat-option *ngFor="let tag of tags" [value]="tag">
                {{ tag }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div style="position: absolute; bottom: -1em; right: 1em; z-index: 3" class="d-flex align-items-center">
        <button mat-fab class="extended-fab-button extended-fab-small button-text" color="warn"
          (click)="cancel()">
          <mat-icon>close</mat-icon>
          Cancel
        </button>
        <button mat-fab class="extended-fab-button extended-fab-small button-text ml-2" color="primary"
            [disabled]="!note?.content"
            [class.bg-success]="!!note.content"
          (click)="doSave()">
          <mat-icon svgIcon="content-save"></mat-icon>
          Save
        </button>
    </div>
</div>