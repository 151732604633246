import { Component, OnInit } from "@angular/core";
import {  DashboardCount } from "../orderStatusInfo/service"


// estimateManager
@Component({
  selector: 'cccccc',
  template: `
    <div class="text-center small">
      <h2 class='p-2 pt-4 text-info'>Today Is</h2><p>Today is Daniel Stanton's Birthday!</p><p>Join us in celebrating in the lunch room at 1pm.</p>
    </div>
  `,
  styleUrls: ['./styles.less']
})
export class CompanyMessagesComponent implements OnInit {

  public data: DashboardCount[] = null;

  constructor() { }

  ngOnInit(): void {
    ;
  }

}
