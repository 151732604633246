import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageType } from '../../../common/resources/message';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { MessageService } from '../../../common/services/message.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { MachineAssignment, OperatorState } from '../../../planning/resources/machine-assignment';
import { FloorService } from '../../services/floor.service';

@Component({
  selector: 'machine-operator-controls',
  templateUrl: './machine-operator-controls.component.html',
  styleUrls: ['./machine-operator-controls.component.less']
})
export class MachineOperatorControlsComponent implements OnInit, OnChanges {
  @Input() machineId: string;
  @Input() assignmentId: string;
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();
  public assignment: MachineAssignment = null;
  public shopDocuments: VirtualDocument[] = null;
  public saving: boolean = false;

  public hasStartedOperation = false;

  public complete: boolean = false;
  public currentCount: number = 0;
  public assignmentTotal: number = 0;
  public state: OperatorState = OperatorState.INACTIVE;
  public processSteps: string[] = ["Start Setup", "Complete Setup", "First Piece Run", "Send First Article", "Start Production Run", "Complete Run", "Finalize Assignment"];


  private counterInterval: any;
  public previousTimeCounter: number = 0;
  public timerStarted: Date | null = null;
  public timerCounter: number = 0;

  constructor(private floorSvc: FloorService,
    private utilitySvc: UtilityService,
    public userService: UserService,
    private messages: MessageService
  ) { }

  public setAssignmentId(id: string): void {
    this.assignmentId = id;
    this.getAssignment();
  }

  public getRuntime(): string {
    const hours: number = Math.floor(this.timerCounter / (60 * 60));
    const minutes: number = Math.floor(this.timerCounter / (60)) % 60;
    const seconds: number = Math.floor(this.timerCounter % 60);

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  public startStop(): void {
    if (this.timerRunning)
      this.pause();
    else
      this.start();
  }

  public startOperation() {
    this.hasStartedOperation = true;
    if (!this.assignment.actualStart) this.assignment.actualStart = new Date();
    this.start();
    this.advanceState();
  }

  private pause(): void {
    this.timerStarted = null;
    this.previousTimeCounter = this.timerCounter;
    clearInterval(this.counterInterval);
    this.counterInterval = null;
  }

  private timerUpdateFunction() {
    this.timerCounter = this.previousTimeCounter + ((Date.now() - this.timerStarted.getTime()) / 1000);
  }

  private start(): void {
    this.timerStarted = new Date();
    this.counterInterval = setInterval(_ => {
      this.timerUpdateFunction();
    }, 1000);
  }

  private resumeTimer() {
    this.timerUpdateFunction();
    this.counterInterval = setInterval(_ => {
      this.timerUpdateFunction();
    }, 1000);
  }

  public get timerRunning(): boolean {
    return !!this.counterInterval;
  }

  public getAdvanceButtonText(state: OperatorState) {
    switch (state) {
      case OperatorState.PRE_SETUP:
        return 'Start Setup';
      case OperatorState.SETUP:
        return 'Complete Setup';
      case OperatorState.PRE_FIRST_ARTICLE:
        return "First Piece Run";
      case OperatorState.FIRST_ARTICLE:
        return "Send First Article";
      case OperatorState.AWAIT_FIRST_INSPECTION:
        return "Awaiting First Article Inspection...";
      case OperatorState.PRE_PRODUCTION:
        return "Start Production Run";
      case OperatorState.PRODUCTION:
        return "Complete Run";
      case OperatorState.INACTIVE:
      default:
        return "-";
    }
  }

  public async awaitFirstArticle() {
    const { status, note } = await this.floorSvc.checkFirstArticle(this.assignment).toPromise();
    if (status !== 'waiting') {
      if (status === 'approved') this.onFirstArticleApproved();
      else if (status === 'rejected') this.onFirstArticleRejected(note);
    } else {
      setTimeout(() => this.awaitFirstArticle(), 2500);
    }
  }

  public onFirstArticleApproved() {
    this.hasStartedOperation = false;
    this.state = OperatorState.PRE_PRODUCTION;
    this.saveWork();
    this.utilitySvc.showConfirmationPromise('First Article Rejected',
    `
      <p class="text-success font-weight-bold">The first article sent has passed inspection.</p>
      <p>Press "Okay" or "Cancel" to continue.</p>
    `)
  }

  public async onFirstArticleRejected(note: string) {
    this.hasStartedOperation = false;
    this.state = OperatorState.PRE_FIRST_ARTICLE;
    this.saveWork();
    const noteString = note ? `<p>Inspection left the following note:</p><p class="font-italic">${note}</p>` : `<p>Inspection left no notes.</p>`;
    const r = await this.utilitySvc.showConfirmationPromise('First Article Rejected',
    `
      <p class="text-danger font-weight-bold">The first article sent has failed inspection.</p>
      ${noteString}
      <p>The failed inspection will be reviewed.</p>
      <p>Hit 'Okay' to load your next assignment, or 'Cancel' to select an assignment from your chart.</p>
    `);
    this.assignmentId = null;
    if (r) this.getAssignment();
  }

  public async advanceState() {
    if (this.state === OperatorState.PRE_SETUP) {
      this.state = OperatorState.SETUP;
      this.saveWork();
      return;
    }
    if (this.state === OperatorState.SETUP) {
      this.setupFinishedTime = this.timerCounter;
      if (this.assignment.operation.hasFirstPartInspection) {
        this.state = OperatorState.PRE_FIRST_ARTICLE;
      } else {
        this.state = OperatorState.PRE_PRODUCTION;
      }
      this.saveWork();
      return;
    }
    if (this.state === OperatorState.PRE_FIRST_ARTICLE) {
      this.partStartedMap[0] = this.timerCounter;
      this.state = OperatorState.FIRST_ARTICLE;
      this.saveWork();
      return;
    }
    if (this.state === OperatorState.FIRST_ARTICLE) {
      this.partFinishedMap[0] = this.timerCounter;
      this.partTimeMap[0] = this.partFinishedMap[0] - this.partStartedMap[0];
      this.saving = true;
      this.pause();
      this.state = OperatorState.AWAIT_FIRST_INSPECTION;
      this.saving = false;
      this.saveWork();
      await this.floorSvc.sendFirstArticle(this.assignment, new Date()).toPromise();
      this.awaitFirstArticle();
      return;
    }
    if (this.state === OperatorState.AWAIT_FIRST_INSPECTION) {
      this.state = OperatorState.PRE_PRODUCTION;
      this.saving = false;
      this.saveWork();
      return;
    }
    if (this.state === OperatorState.PRE_PRODUCTION) {
      this.partStartedMap[1] = this.timerCounter;
      this.state = OperatorState.PRODUCTION;
      this.saveWork();
      return;
    }
    if (this.state === OperatorState.PRODUCTION) {
      if (this.currentCount < this.assignmentTotal) {
        this.currentCount++;
        this.partFinishedMap[this.currentCount] = this.timerCounter;
        this.partTimeMap[this.currentCount] = this.partFinishedMap[this.currentCount] - this.partStartedMap[this.currentCount];
        this.partStartedMap[this.currentCount + 1] = this.timerCounter;
        this.saving = true;
        await this.completeRun();
        this.saveWork();
      }
      else {
        this.complete = true;
        this.assignment.actualEnd = new Date();
        await this.saveWork();
        this.completeAssignment();
        this.utilitySvc.showConfirmation("Job Complete", "<p>Hit 'Okay' to load your next assignment, or 'Cancel' to select an assignment from your chart.</p>", r => {
          if (r) {
            this.assignmentId = null;
            this.getAssignment();
          }
        });
      }
      return;
    }
  }

  private async saveWork() {
    this.saving = true;
    this.assignment.metadata = {
      hasStartedOperation: this.hasStartedOperation,
      state: this.state,
      previousTimeCounter: this.previousTimeCounter,
      timerStarted: this.timerStarted,
      setupFinishedTime: this.setupFinishedTime,
      partStartedMap: this.partStartedMap,
      partFinishedMap: this.partFinishedMap,
      partTimeMap: this.partTimeMap,
      operatorId: this.userService.userData.userId
    }
    this.assignment.status = this.complete ? 11 : this.assignment.status;

    const a = await this.floorSvc.saveAssignment(this.assignment).toPromise();
    this.assignment = a;
    this.saving = false;
  }

  private async completeRun() {
    this.saving = true;

    const a = await this.floorSvc.completeRun(this.assignment).toPromise();
    this.assignment = a;
    this.saving = false;
    if (this.currentCount == this.assignmentTotal) {
      this.advanceState();
    }
  }

  private completeAssignment(): void {
    this.saving = true;
    clearInterval(this.counterInterval);
    this.counterInterval = null;
    this.timerCounter = 0;
    this.partStartedMap = {};
    this.partFinishedMap = {};
    this.partTimeMap = {};
    this.setupFinishedTime = null;

    this.floorSvc.completeAssignment(this.assignment).subscribe((a) => {
      this.assignment = a;
      this.saving = false;
    });
  }

  private getAssignment(): void {
    this.assignment = null;

    this.hasStartedOperation = false;
    this.complete = false;
    this.state = OperatorState.INACTIVE;
    this.currentCount = 0;
    this.assignmentTotal = 0;

    if (this.assignmentId == null) {
      this.floorSvc.getNextAssignment(this.machineId).subscribe(a => {
        // test data
        // a = <MachineAssignment>{
        //     machineId: this.machineId,
        //     scheduledStart: new Date(this.now.getTime() + (0.5)*60000),
        //     scheduledEnd: new Date(this.now.getTime() + 9*60000),
        //     readyQty: 0,
        //     requestedQty: 50,
        //       operation: {
        //       hasSetup: false,
        //       setupTime: 1 / 60,
        //       runTime: 1,
        //       runIsPerPart: true
        //     },
        //     workOrder: {
        //       workOrderNumber: '123TESTDELETE',
        //       product: {
        //         partNumber: 'test',
        //         revision: '123',
        //         documents: [],
        //         orderQuantity: 50
        //       }
        //     }
        //   };
        if (a == null) {
          this.assignment = <MachineAssignment>{ machineId: this.machineId };

          this.utilitySvc.showAlert("No Assignments", "<p>We couldn't find any assignments here!</p><p class='text-muted'>Try switching to a different machine.</p>");
        }
        else {
          this.assignment = a;
          const { metadata } = this.assignment;
          if (metadata) {
            this.hasStartedOperation = metadata.hasStartedOperation;
            this.state = metadata.state;
            this.previousTimeCounter = metadata.previousTimeCounter;
            this.setupFinishedTime = metadata.setupFinishedTime;
            this.partStartedMap = metadata.partStartedMap;
            this.partFinishedMap = metadata.partFinishedMap;
            this.partTimeMap = metadata.partTimeMap;
            if (metadata.timerStarted) {
              this.timerStarted = new Date(metadata.timerStarted);
              if (this.timerStarted) this.resumeTimer();
            } else {
              this.timerCounter = metadata.previousTimeCounter;
            }

            if (this.state === OperatorState.AWAIT_FIRST_INSPECTION) this.awaitFirstArticle();
          }
          this.currentCount = this.assignment.readyQty;
          this.assignmentTotal = this.assignment.requestedQty;
          this.getShopDocuments();
        }
        // Setup state for assignments that haven't been started yet
        if (!this.assignment.actualStart) {
          if (this.assignment.operation) {
            if (this.assignment.operation.hasSetup) {
              this.state = OperatorState.PRE_SETUP;
            } else if (this.assignment.operation.hasFirstPartInspection) {
              this.state = OperatorState.PRE_FIRST_ARTICLE;
            } else {
              this.state = OperatorState.PRE_PRODUCTION;
            }
          } else {
            this.state = OperatorState.INACTIVE;
          }
        }
      });
    }
    else {
      this.floorSvc.getAssignment(this.assignmentId).subscribe(a => {
        this.assignment = a;
        this.currentCount = this.assignment.readyQty;
        this.assignmentTotal = this.assignment.requestedQty;
        this.getShopDocuments();
      });
    }
  }

  private getShopDocuments(): void {
    this.shopDocuments = this.assignment.workOrder.product.documents.filter(d => d.document.tags.findIndex(t => t == 'Shop Aids') >= 0).map(p => p.document);
  }

  ngOnInit(): void {
    this.getAssignment();
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  ngOnChanges(_: SimpleChanges): void {
    this.getAssignment();
  }

  public now = new Date();

  public get startTimeDifference() {
    // do now unless we have started the timer
    const startTime = this.assignment.actualStart ? new Date(this.assignment.actualStart) : this.now;
    return (new Date(this.assignment.scheduledStart)).getTime() - startTime.getTime();
  }

  public get startTimeDifferenceString() {
    const startTimeDifferenceSeconds = Math.abs(this.startTimeDifference) / 1000;
    let time = startTimeDifferenceSeconds;

    const day = Math.floor(time / (24 * 3600))
    time = time % (24 * 3600)
    const hours = Math.floor(time / 3600)
    time = time % 3600
    const minutes = Math.floor(time / 60)
    time = time % 60
    const seconds = time.toFixed(0);


    if (day > 0) return `${day} DAYS`;


    const hourString = hours > 0 ? `${hours.toString()}h` : '';
    const minString = minutes > 0 ? `${minutes.toString()}m` : '';

    return `${hourString}${minString}${seconds.toString().padStart(2, "0")}s`;
  }

  public get setupTime() {
    if (!this.assignment || !this.assignment.operation) return 0;
    else if (!this.assignment.operation.hasSetup) return 0;
    // setup time is in hours, so convert it to seconds
    else return (this.assignment.operation.setupTime * 60) * 60;
  }

  public get runTime() {
    if (!this.assignment || !this.assignment.operation) return 0;
    // if in hours, convert to seconds
    if (!this.assignment.operation.runIsPerPart) return this.assignment.operation.runTime * 60 * 60;
    else {
      return this.assignment.operation.runTime * this.assignment.requestedQty * 60;
    }
  }

  public setupFinishedTime = null;
  public partStartedMap: { [key: number]: number } = {};
  public partFinishedMap: { [key: number]: number } = {};
  public partTimeMap: { [key: number]: number } = {};
  
  public get totalTime() {
    return this.setupTime + this.runTime;
  }

  public get totalTimeString() {
    const hours: number = Math.floor((this.totalTime) / (60 * 60));
    const minutes: number = Math.floor((this.totalTime) / (60)) % 60;
    const seconds = ((this.totalTime) % 60).toFixed(0);

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  public get setupPercent() {
    return ((this.setupTime / this.totalTime) * 100);
  }

  public get runPercent() {
    return ((this.runTime / this.totalTime) * 100);
  }

  public get percentageTimeSpent() {
    return ((this.timerCounter) / this.totalTime) * 100;
  }

  public get setupUnderage() {
    if (this.setupFinishedTime === null) return null;
    else return this.setupTime - this.setupFinishedTime;
  }

  public get estimatedRunTimeSeconds() {
    return this.assignment.operation && this.assignment.operation.runTime * (this.assignment.operation.runIsPerPart ? 60 : (60 * 60));
  }

  public get fastestRunTimeSeconds() {
    if (Object.values(this.partTimeMap).length === 0) return null;
    return Math.min(...Object.values(this.partTimeMap));
  }

  public get runTimeMetricSeconds() {
    return (this.fastestRunTimeSeconds !== null && this.fastestRunTimeSeconds < this.estimatedRunTimeSeconds) ? this.fastestRunTimeSeconds : this.estimatedRunTimeSeconds;
  }

  public get runTimeMetricString() {
    
    const minutes: number = Math.floor((this.runTimeMetricSeconds) / (60)) % 60;
    const seconds = ((this.runTimeMetricSeconds) % 60).toFixed(0);

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  public get currentRunTimeSeconds() {
    if (this.state !== OperatorState.FIRST_ARTICLE && this.state !== OperatorState.PRODUCTION) return 0;
    const startedTime = (this.state === OperatorState.FIRST_ARTICLE) ? this.partStartedMap[0] : this.partStartedMap[this.currentCount + 1];
    if (startedTime === undefined) return 0;
    return this.timerCounter - startedTime;
  }

  public get currentRunTimeString() {
    if (this.state !== OperatorState.FIRST_ARTICLE && this.state !== OperatorState.PRODUCTION) return '--'
    const startedTime = (this.state === OperatorState.FIRST_ARTICLE) ? this.partStartedMap[0] : this.partStartedMap[this.currentCount + 1];
    if (startedTime === undefined) return '--';

    const minutes: number = Math.floor(this.currentRunTimeSeconds / (60)) % 60;
    const seconds: string = (this.currentRunTimeSeconds % 60).toFixed(0);

    const minString = minutes > 0 ? `${minutes.toString()}m` : '';

    return `${minString}${seconds.toString().padStart(2, "0")}s`;
  }

  public get isOverMetric() {
    if (this.currentRunTimeSeconds > this.runTimeMetricSeconds) return true;
    else return false;
  }

  public get percentagePartTimeSpent() {
    if (this.currentRunTimeSeconds > this.runTimeMetricSeconds) return 100;
    return ((this.currentRunTimeSeconds) / this.runTimeMetricSeconds) * 100;
  }

}
