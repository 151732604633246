<loading *ngIf="loading" content="Loading..."></loading>
<ordersegment-list-new
  [displayedColumns]="['customer', 'orderNumber', 'estimateNumber', 'customerRfqnumber', 'partHistory', 'hasOnHold', 'assigned', 'received', 'required']"
  orderNumberTitle="RFQ"
  [showAdd]="false"
  [alwaysManager]="true"
  [isManager]="userIsManager.bind(this)"
  path="/rfqs/"
  discriminator="RFQ"
  localStorageId="sales-rfq-approval-list"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
</ordersegment-list-new>