import { Injectable } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../common/components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../../common/components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private dialog: MatDialog) { }

  private static s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public static array_move(arr: any[], old_index: number, new_index: number): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing

  };

  public static distinct(ary: any[]): any[] {
    const d = (v, i, s) => { return s.indexOf(v) === i; };

    return ary.filter(d);
  };

  public static newGuid(): string {
    //Use cryptographic generation if we can, Math.random as a backup
    if (typeof crypto === "undefined") {
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
        this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    return (1e7.toString() + -1e3.toString() + -4e3.toString() + -8e3.toString() + -1e11.toString())
      .replace(/[018]/g, c => (Number.parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number.parseInt(c) / 4).toString(16));
  }

  public static get emptyGuid(): string {
    return "00000000-0000-0000-0000-000000000000";
  }

  static toInitialCase(input: string): string {
    const re = /(\b[a-z](?!\s))/g;
    return input.replace(re, function (x) { return x.toUpperCase(); });
  }

  static formatPhone(phoneNumber: string): string {
    return new AsYouType('US').input(phoneNumber);
  }

  static copyText(val: string): void {
    var selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async showAlert(dialogTitle: string, messageContent: string): Promise<void> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: {
        title: dialogTitle, detail: messageContent
      }
    });
    return ref.afterClosed().toPromise();
  }

  showConfirmation(dialogTitle: string, messageContent: string, callback: (result: boolean) => any, timer?: number): MatDialogRef<ConfirmDialogComponent, any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: dialogTitle, detail: messageContent, timer
      }, disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      callback(result);
    });

    return dialogRef;
  }

  public showConfirmationPromise(dialogTitle: string, messageContent: string, timer?: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.showConfirmation(dialogTitle, messageContent, (r) => resolve(r), timer);
    });
  }

  public showMessage(dialogTitle: string, messageContent: string, disableClose: boolean = true): Promise<void> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: dialogTitle, detail: messageContent
        }, disableClose
      });
  
      dialogRef.afterClosed().subscribe(() => {
        resolve();
      });
    });
  }

  public get dialogService(): MatDialog {
    return this.dialog;
  }

  public static get defaultMarkup(): number {
    return 18.0;
  }

  static getWeeksRemaining(date: Date, useAbs?: boolean): number {
    useAbs = useAbs === undefined ? true : useAbs;

    var now = new Date();

    var weeks = Math.round((date.valueOf() - now.valueOf()) / (604800 * 1000));

    if (isNaN(weeks))
      return NaN;

    if (useAbs)
      return Math.abs(weeks);

    return weeks;
  };

  static getDaysRemaining(date: Date, useAbs?: boolean): number {
    useAbs = useAbs === undefined ? true : useAbs;

    var now = new Date();

    var days = Math.ceil((date.valueOf() - now.valueOf()) / (1000*60*60*24));

    if (isNaN(days))
      return NaN;

    if (useAbs)
      return Math.abs(days);

    return days;
  };

  static getDateRequiredColor(date: string): string {
    if (date == null)
      return "badge-secondary";

    var weeksLeft: number = UtilityService.getWeeksRemaining(new Date(date), false);

    if (weeksLeft > 4) {
      return "badge-success";
    }
    else if (weeksLeft > 2) {
      return "badge-warning";
    }

    return "badge-danger";
  }

}
