<loading *ngIf="saving"></loading>

<div class="position-fixed" style="bottom: 15px; right: 10px; z-index: 1030">
    <button mat-fab color="warn" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-fab class="bg-success ml-2 text-white" *ngIf="!isNew" (click)="create()">
        <mat-icon>add</mat-icon>
    </button>        
</div>

<div class="row m-0 h-100-t">
    <div class="col-4 p-0 h-100-t overflow-y-scroll border-right">
        <mat-form-field appearance="outline" class="w-100 p-2">
            <mat-label>Search Outside Processes</mat-label>
            <input type="text" matInput ngModel #searchModel="ngModel">
        </mat-form-field>
        <mat-list *ngIf="items$ | async as items">
            <mat-list-item class="border-bottom py-2 cursor-pointer mat-selectable" [class.selected]="selectedItem?.outsideProcessDescriptionId === item.outsideProcessDescriptionId" (click)="select(item)" style="height: auto" *ngFor="let item of items">
                <div class="w-100 d-flex align-items-center">
                    <div>{{ item.product.partNumber }} Rev. {{ item.product.revision }} - {{ getStationString(item) }}</div>
                    <div class="ml-auto small text-muted">{{ item.outsideProcessDescriptionNumber }}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="col-8 py-2 h-100" ngForm #form="ngForm">
        <div class="mt-3 mb-4 d-flex align-items-center" *ngIf="selectedItem">
            <h1 mat-dialog-title class="m-0">
                <ng-container *ngIf="!isNew">Outside Process for {{selectedItem?.product?.partNumber}} Rev. {{selectedItem?.product.revision}}</ng-container>
                <ng-container *ngIf="isNew">New Outside Process</ng-container>
            </h1>
            <div class="ml-auto"></div>
            <button mat-stroked-button class="button-text text-danger" (click)="delete()" *ngIf="!isNew">
                <mat-icon matPrefix>delete</mat-icon>
                Delete Process
            </button>
            <button mat-flat-button class="ml-2 button-text" [class.text-success]="form.dirty && form.valid" [disabled]="!(form.dirty && form.valid)" (click)="save()">
                <mat-icon matPrefix>save</mat-icon>
                 Save Changes 
            </button>
        </div>
        <mat-tab-group *ngIf="selectedItem" >
            <mat-tab label="Process Details">
                <div class="p-1">
                    <po-outside-process-editor [(ngModel)]="selectedItem" (stepsChanged)="markAsDirty()" name="editor" (clone)="onClone($event)"></po-outside-process-editor>
                </div>
            </mat-tab>
            <mat-tab label="Pricing History">
                <purchasing-line-item-history [itemId]="this.selectedItem.outsideProcessDescriptionId"></purchasing-line-item-history>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>