<div *ngIf="detail && !sidenav.opened && !saving && !loading" class="position-fixed d-flex" style="bottom: 50px; right: 50px; z-index: 1030">
  <button *ngIf="!editing && detail?.status === 3 && lotsAreValid && this.dispositionedAmount === this.detail.receivedQuantity" mat-fab color="primary" class="extended-fab-button bg-danger text-white mr-1" 
  (click)="disposition()"
  >
    <mat-icon>logout</mat-icon>
    <span class="extended-fab-button__text">Finish Disposition</span>
  </button>
  <button *ngIf="!editing && detail?.status === 3" mat-fab class="bg-success text-white" (click)="editing = true;">
    <mat-icon>edit</mat-icon>
  </button>
  <button *ngIf="editing" mat-fab class="bg-success text-white mr-1" (click)="save()">
    <mat-icon>save</mat-icon>
  </button>
  <button *ngIf="editing" mat-fab class="bg-danger text-white" (click)="editing = false">
    <mat-icon>close</mat-icon>
  </button>
</div>


<loading *ngIf="saving">Saving...</loading>
<loading *ngIf="loading"></loading>

<mat-sidenav-container>
  <mat-sidenav disableClose #sidenav mode="over" position="end">
    <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav"></upload-file>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card style="min-height: 92vh;" *ngIf="detail && qualityTicket">
      <mat-card-content>
        <div class="d-flex flex-column" style="min-height: calc(92vh - 32px);">
          <div style="margin-bottom: 16px; padding: 0 16px;">
            <quality-assign
              *ngIf="detail.status < 4"
              class="my-1"
              [record]="qualityTicket"
              (startLoading)="loading = true;"
              (finishLoading)="getDetail()"
            ></quality-assign>
          </div>
          <div style="margin-bottom: 16px">
            <mat-card class="border mat-elevation-z0">
              <mat-card-content>
                <div class="row">
                  <div class="col-5">
                    <div class="row">
                      <div class="col-12">
                        <mat-card-title>Ticket Details</mat-card-title>
                      </div>
                      <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Customer</mat-label>
                          <input
                            disabled
                            matInput
                            [value]="detail.customer.businessName"
                            type="text"
                          >
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>RMA Number</mat-label>
                          <input
                            disabled
                            matInput
                            [value]="detail.rmaNumber || 'Not Yet Assigned'"
                            type="text"
                          >
                        </mat-form-field>
                      </div>
                      <div class="col-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Returned Part Number</mat-label>
                          <input
                            class="font-weight-bold"
                            disabled
                            matInput
                            [value]="detail.product.partNumber"
                            type="text"
                          >
                        </mat-form-field>
                      </div>
                      <div class="col-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Returned Item Location</mat-label>
                          <div *ngIf="location">
                            <b *ngIf="!locationParents[location.inventoryLocationId]">
                              {{ location.inventoryLocation?.name }}
                              <i class="fa fa-spin fa-circle-notch text-primary mx-1"></i>
                            </b>
                            <input matInput disabled type="text" class="d-none">
                            <div class="d-flex align-items-center ml-1" *ngIf="locationParents[location.inventoryLocationId]">
                              <ng-container *ngFor="let ploc of locationParents[location.inventoryLocationId]; last as last">
                                <div>{{ ploc.name }}</div>
                                <mat-icon class="mat-icon-rtl-mirror mx-1" *ngIf="!last">
                                  chevron_right
                                </mat-icon>
                              </ng-container>
                            </div>
                          </div>
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Customer Rejection Number</mat-label>
                          <input
                            disabled
                            matInput
                            [(ngModel)]="detail.customerRejectionNumber"
                            type="text"
                            placeholder="--"
                          >
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <date-picker appearance="outline" [(date)]="detail.createdDate" [editable]="false"></date-picker>
                      </div>
                      <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Customer PO Number</mat-label>
                          <input
                            disabled
                            matInput
                            [value]="detail.customerPurchaseOrder.purchaseOrderNumber"
                            type="text"
                          >
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Work Order Number</mat-label>
                          <input
                            matInput
                            disabled
                            [value]="detail.workOrder?.workOrderNumber || ''"
                          >
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-7">
                    <mat-card-title>Documents/Photos</mat-card-title>
                    <p class="text-center text-muted font-italic" *ngIf="detail?.documents?.length==0">-- No Documents --</p>
                    <file-display-grid
                      [files]="detail.documents"
                      [editable]="false"
                    ></file-display-grid>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <mat-form-field class="w-100 textarea" appearance="outline">
                      <mat-label>Reason for Return</mat-label>
                      <textarea
                        disabled
                        matInput
                        [value]="detail.returnReason"
                        style="resize: none;"
                        rows="6"
                        ></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-7">
                    <mat-form-field class="w-100 textarea" appearance="outline">
                      <mat-label>Notes</mat-label>
                      <textarea
                        disabled
                        matInput
                        [value]="detail.notes"
                        type="text"
                        style="resize: none;"
                        rows="6"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="row h-100">
                      <div class="col-12 pb-4">
                        <mat-card-title class="d-flex align-items-center" *ngIf="detail.status <= 3">
                          Disposition
                          <span class="text-muted ml-auto" style="font-size: .9rem;">
                            {{ dispositionedAmount }}/{{ detail.receivedQuantity }} items dispositioned
                          </span>
                          <button mat-stroked-button class="ml-3" color="primary" *ngIf="editing" (click)="addNewLot()">
                            <mat-icon matPrefix>call_split</mat-icon>
                            Add New Lot
                          </button>
                        </mat-card-title>
                        <mat-card-title *ngIf="detail.status > 3">
                          Dispositioned Lots
                        </mat-card-title>
                        <mat-accordion>
                          <!-- expansion panel for each of locations -->
                          <!-- invside expansion panel: title, then two radio groups: our fault/customer fault, repair/remake -->
                          <mat-expansion-panel *ngFor="let lot of detail.dispositionLots; let i = index">
                            <mat-expansion-panel-header class="expansion-panel-reset">
                              <mat-panel-title class="d-flex align-items-center">
                                <b>Lot #{{ i + 1 }}</b>
                                <ng-container *ngIf="lot.dispositionType !== null && lot.repairType !== null">
                                  <mat-icon class="text-primary mx-1">check</mat-icon>
                                  <span class="text-primary">
                                    {{ lot.dispositionType === 0 ? 'Internal Responsibility' : 'Customer Responsibility' }},
                                    {{ lot.repairType === 0 ? 'Repair' : 'Remake' }}
                                  </span>
                                </ng-container>
                              </mat-panel-title>
                              <mat-panel-description *ngIf="detail.status <= 3" style="flex-grow: 0" class="justify-content-end align-items-center" (click)="$event.stopPropagation()">
                                <mat-form-field dense appearance="fill">
                                  <input matInput class="text-right" type="number" min="0" [disabled]="!editing" [(ngModel)]="lot.quantity" [minValidator]="1" [maxValidator]="detail.receivedQuantity - (totalLotAmount - lot.quantity)" [max]="detail.receivedQuantity - (totalLotAmount - lot.quantity)" >
                                  <span matSuffix>/{{detail.receivedQuantity}} items</span>
                                </mat-form-field>
                                <button mat-stroked-button class="ml-2" *ngIf="editing" [class.text-danger]="detail.dispositionLots.length > 1" [disabled]="detail.dispositionLots.length === 1" (click)="deleteLot(i)">
                                  <mat-icon matPrefix>delete</mat-icon>
                                  Delete
                                </button>
                              </mat-panel-description>
                              <mat-panel-description class="d-flex align-items-center" *ngIf="detail.status > 3">
                                <span class="ml-auto"><b>{{lot.quantity}}</b> items</span>
                                <div class="ml-2">
                                </div>
                                <a (click)="$event.stopPropagation()" [href]="'/order/' + lotOrders[lot.rmaDispositionLotId].orderSegmentId" class="d-block text-dark" *ngIf="lotOrders[lot.rmaDispositionLotId] && lotOrders[lot.rmaDispositionLotId] !== 'loading'">
                                  <b>{{lotOrders[lot.rmaDispositionLotId].orderNumber}}</b>
                                  <span class="badge status text-uppercase" [ngClass]="getStatusColorClass(lotOrders[lot.rmaDispositionLotId].status)">
                                    {{getStatusText(lotOrders[lot.rmaDispositionLotId].status)}}
                                  </span>
                                  <i class="fas fa-fw fa-lg fa-external-link-alt text-underline pl-2"></i>
                                </a>
                                <div *ngIf="lotOrders[lot.rmaDispositionLotId] === 'loading'">
                                  <i class="fa fa-lg fa-spin fa-spinner text-primary"></i>
                                </div>
                                <div *ngIf="!lotOrders[lot.rmaDispositionLotId]">
                                  <span class="text-muted small-font-italic">No orders found for this lot.</span>
                                </div>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div *ngIf="lot">
                              <mat-divider class="lot-disposition-divider"></mat-divider>
                              <div class="row">
                                <div class="col-6 small">
                                  <p>
                                    <b>Who is responsible for the defect?</b>
                                  </p>
                                  <mat-radio-group class="mb-2" [(ngModel)]="lot.dispositionType" [disabled]="!editing">
                                    <mat-radio-button class="mr-2" [value]="0">Internal Responsibility</mat-radio-button>
                                    <mat-radio-button [value]="1">Customer Responsibility</mat-radio-button>
                                    <div class="text-muted small">
                                      <ng-template *ngIf="lot.dispositionType && lot.repairType !== 3 && lot.repairType !== 4; else disptextempty">
                                        <span *ngIf="lot.dispositionType === 0">An expedited RMA estimate for the part will be created, skipping the quoting process.</span>
                                        <span *ngIf="lot.dispositionType === 1">A regular quoting process will be initiated for the returned parts.</span>
                                      </ng-template>
                                      <span #disptextempty>&nbsp;</span>
                                    </div>
                                  </mat-radio-group>
                                  <p><b>What should be done with the part?</b></p>
                                  <mat-radio-group [(ngModel)]="lot.repairType" [disabled]="!editing">
                                    <mat-radio-button class="mr-2" [value]="0">Repair</mat-radio-button>
                                    <mat-radio-button [value]="2">Rework</mat-radio-button>
                                    <mat-radio-button [value]="3">Scrap</mat-radio-button>
                                    <mat-radio-button [value]="4">Use As Is</mat-radio-button>
                                    <mat-radio-button [value]="1">Scrap/Remake</mat-radio-button>
                                  </mat-radio-group>
                                </div>
                                <div class="col-6">
                                  <mat-form-field class="w-100 textarea" appearance="outline">
                                    <mat-label>Lot Defect/Repair Notes</mat-label>
                                    <textarea
                                      matInput
                                      [disabled]="!editing"
                                      [(ngModel)]="lot.notes"
                                      style="resize: none;"
                                      rows="6"
                                      ></textarea>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12">
                                  <h5>Lot Documents (repair plans, etc)</h5>
                                  <p class="text-center text-muted font-italic" *ngIf="detail?.documents?.length==0">-- No Documents --</p>
                                  <div class="p-2 bg-secondary rounded">
                                    <file-display-grid (delete)="deleteLotDocument(lot, $event)" [editable]="editing" [files]="lot.documents"></file-display-grid>
                                    <p *ngIf="lot.documents.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
                                    <a class="btn btn-badge bg-success has-icon float-right" *ngIf="editing" title="Add Document" (click)="openDocumentsWindow(lot)"><i class="fas fa-plus mt-2"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>