<h2 mat-dialog-title>Are You Finished?</h2>
<mat-dialog-content class="mat-typography">
  <p>Is this RFQ ready to be reviewed, or is there still more to be done?</p>
  <p class="m-3">
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button value="1" #ready >It's Ready for Review</mat-radio-button>
      <mat-radio-button value="2" #needswork class="ml-3">Still Needs More Work</mat-radio-button>
    </mat-radio-group>
  </p>
  <p [hidden]="!needswork.checked">
    <textarea class="form-control form-control-sm" #note placeholder="Please tell us what still needs to be done..."></textarea>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="needswork.checked || ready.checked">
  <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{ready: ready.checked, note: note.value}">Okay</button>
  <button class="btn btn-primary btn-sm" [mat-dialog-close]="null">Cancel</button>
</mat-dialog-actions>
