import IWidgetData from '../interface';
import { FloorAlertsWidgetComponent } from './components';

const floorWidgets: IWidgetData[] = [
  {
    id: 'floorAlerts',
    name: 'Floor Alerts',
    component: FloorAlertsWidgetComponent,
    access: ["FloorManager"],
    minHeight: 400,
    minWidth: 500,
  }
]

export default floorWidgets;
