import { Component, OnInit, Input, ViewChild, EventEmitter, Output, AfterViewInit, Optional } from '@angular/core';
import { WorkflowStep, WorkflowStepType } from '../../../../../resources/workflow';
import { OutsideProcessSpecification, Station } from '../../../../../resources/station';
import { StationService } from '../../../../../services/station.service';
import { OrderDetailService } from '../../../order-detail.service';
import { MaterialBid } from '../../../../../../purchasing/resources/materialBid';
import { UtilityService } from '../../../../../../common/services/utility.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Product } from '../../../../../resources/product';
import { Order } from '../../../../../resources/order';

@Component({
  selector: 'product-workflow-step-form',
  templateUrl: './product-workflow-step-form.component.html',
  styleUrls: ['./product-workflow-step-form.component.less']
})
export class ProductWorkflowStepFormComponent implements OnInit, AfterViewInit {

  @Input() record: Order;
  @Input() product: Product;
  @Input() step: WorkflowStep;
  @Input() editing: boolean = false;
  @Output() newOPSpec = new EventEmitter<OutsideProcessSpecification>();

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  constructor(private stationService: StationService) { }

  @ViewChild('form') form: NgForm;
  @Output() edited = new EventEmitter<WorkflowStep>();
  private formSubscription: Subscription;
  ngAfterViewInit() {
    this.form?.valueChanges.subscribe(() => {
      this.edited.emit(this.step);
    });
  }

  ngOnDestroy(): void {
    this.formSubscription && this.formSubscription.unsubscribe();
  }

  public get canEditWorkflowStep() {
    if (!this.editing) return false;
    else if (!this.product.productRepairPlan) return true;
    else return this.step.stepType !== WorkflowStepType.Standard
  }

  public get firstQuantity(): number {
    return Product.getFirstQuantity(this.product);
  }

  public get firstPartInspectionCost() {
    if (!this.step.firstPartInspectionTime || !this.step.firstPartInspectionRate) return 0;
    if (!this.step.hasFirstPartInspection) return 0;
    return ((this.step.firstPartInspectionTime / 60) * this.step.firstPartInspectionRate);
  }

  public get fullInspectionCost() {
    if (!this.step.inspectionTime || !this.step.inspectionRate) return 0;
    if (!this.step.hasInspection) return 0;
    let inspectionTimes: number;
    if (!this.step.inspectionIsBatched || isNaN(this.step.inspectionBatchSize) || this.step.inspectionBatchSize < 1) inspectionTimes = this.firstQuantity;
    else inspectionTimes = Math.ceil(this.firstQuantity / this.step.inspectionBatchSize);
    return ((this.step.inspectionTime / 60) * this.step.inspectionRate) * inspectionTimes;
  }

  public setOutsourcedStepPrice(quote: MaterialBid) {
    if (this.step.runIsPerPart)
    {
        this.step.runPrice = quote.totalBid ? (quote.totalBid / quote.qty) : quote.perItemBid;
    }
    else
    {
        this.step.runPrice = quote.totalBid ? (quote.totalBid) : (quote.perItemBid * quote.qty);
    }
  }

  public async onCreateSpec(specName: string) {
    console.log(specName);
    const newSpec = <OutsideProcessSpecification>{
      outsideProcessSpecificationId: UtilityService.newGuid(),
      name: specName,
      stationId: this.step.stationId
    };
    this.newOPSpec.emit(newSpec);
    this.step.outsideProcessSpecifications = [...this.step.outsideProcessSpecifications, newSpec.outsideProcessSpecificationId];
  }


  public stationList: Station[]
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

  @Output() deleteStep = new EventEmitter<void>();
  @Output() addQuote = new EventEmitter<MaterialBid>();

}
