import { SelectionModel } from '@angular/cdk/collections';
import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ContentChild, TemplateRef } from '@angular/core';
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource, MatTreeNode } from '@angular/material/tree';
import { OrderStatus } from '../../../order/resources/order';
import { Product } from '../../../order/resources/product';
import { OrderService } from '../../../order/services/order.service';
import { WorkOrder } from '../../resources/work-order';
import { MatMenuTrigger } from '@angular/material/menu';
import { UtilityService } from '../../../common/services/utility.service';
import { WorkOrderService } from '../../services/work-order.service';
import { PlanningTicket } from '../../resources/planning-ticket';


type PreTransform = Product;
interface NodeData {
  name: string;
  itemId: string;
  parentId: string;
  productData: Product;
  workOrder: WorkOrder;
  isTooling: boolean,
  children: NodeData[],
  loading?: boolean,
  isNew?: boolean
}

type TreeNode = NodeData & {
  expandable: boolean,
  level: number
}

export interface SortedProductHierarchy {
    productId: string;
    parentId: string;
    newOrderQuantity: number;
}[]


@Component({
  selector: 'product-hierarchy-sort',
  templateUrl: './product-hierarchy-sort.component.html',
  styleUrls: ['./product-hierarchy-sort.component.less']
})
export class ProductHierarchySortComponent implements OnInit {

  @Input() planning: boolean = true;
  @Input() single: boolean = false;
  @Input() products: Product[];
  @Input() editing: boolean = true;
  @Input() planningTicket: PlanningTicket;
  @Input() workOrders: WorkOrder[];
  @Output() workOrdersChange = new EventEmitter<WorkOrder[]>();

  @ContentChild("postName") postNameTemplate: TemplateRef<any>;

  @Input() expansionModel = new SelectionModel<string>(true);
  treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren)
  treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  public sorting = false;

  constructor(private orderSvc: OrderService, private utilitySvc: UtilityService, private woService: WorkOrderService) {
  }

  dataTransformer(item: PreTransform): NodeData {
    let wo = this.workOrders?.find(w => w.productId == item.productId);
    let toolingParentProductId: string;
    const isTooling = !!wo?.toolingParentWorkOrderId;
    if (isTooling) {
      const toolingWoParentWo = this.workOrders?.find(iwo => !!iwo.toolingChildWorkOrders && (iwo.toolingChildWorkOrders.map(x => x.productId).includes(item.productId)));
      toolingParentProductId = toolingWoParentWo?.productId;
    }
    const toolingChildren = wo?.toolingChildWorkOrders?.map(tcwo => this.workOrders.find(iwo => iwo.workOrderId === tcwo.workOrderId)).map(iwo => iwo.product) ?? [];
    const children = [...item.childAssemblies, ...toolingChildren];
    const transformedChildren = children.map(x => this.dataTransformer(x));
    return {
      name: `${item.partNumber} Rev. ${item.revision}`,
      itemId: item.productId,
      parentId: isTooling ? toolingParentProductId : item.parentAssemblyId,
      isTooling,
      productData: item,
      workOrder: wo,
      children: transformedChildren
    }
  }
  transformer(node: NodeData, level: number): TreeNode {
    return {
      ...node,
      level: level,
      expandable: node.children.length > 0
    };
  }

  findNode(id: string, data: NodeData[]) {
    for (const datum of data) {
      const r = this.findNodeRecursive(id, datum);
      if (r) return r;
    }
    return null;
  }

  findNodeRecursive(id: string, currentNode: NodeData): NodeData {
    var i: number,
        currentChild: NodeData,
        result: NodeData;

    if (id == currentNode.itemId) {
        return currentNode;
    } else {

        // Use a for loop instead of forEach to avoid nested functions
        // Otherwise "return" will not work properly
        for (i = 0; i < currentNode.children.length; i += 1) {
            currentChild = currentNode.children[i];

            // Search in the current child
            result = this.findNodeRecursive(id, currentChild);

            // Return the result if the node has been found
            if (result !== null) {
                return result;
            }
        }

        // The node has not been found and we have no more options
        return null;
    }
}

  getChildren(node: NodeData) {
    return node.children
  }

  getLevel(node: TreeNode) {
    return node.level;
  }

  isExpandable(node: TreeNode) {
    return node.expandable;
  };

  getAncestors(array: NodeData[], id: string): NodeData[] {
    for (let item of array) {
      if (item.itemId === id) {
        return [item];
      }
      const a = this.getAncestors(item.children, id);
      if (a !== null) {
        a.unshift(item);
        return a;
      } else {
        continue;
      }
    }
    return null;
  }

  hasChild(index: number, node: TreeNode){
    return node.expandable;
  }

  public workOrdersMap: { [key: string]: WorkOrder } = {};

  public mapWorkOrders() {
    if (!this.workOrders) return;
    this.allProductsFlat().forEach((pr) => {
      const wo = this.workOrders.find(wo => wo.productId === pr.productId);
      if (wo) {
        this.workOrdersMap[pr.productId] = wo;
      }
    });
    this.workOrdersMap = { ...this.workOrdersMap };
  }

  public forceExpand() {
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.itemId === id);
      this.treeControl.expand(node);
    });
  }
  
  public expandTo(id: string) {
    const toExpand = this.getAncestors(this.dataSource.data, id);
    this.expansionModel.select(...toExpand.map(x => x.itemId));
    this.forceExpand();
  }

  public loading = false;
  public async getDetail() {
    if (this.planning) {
      this.loading = true;
      await Promise.all(this.products.map(async (p) => {
        p.childAssemblies = await this.orderSvc.getAllChildProducts(p.productId).toPromise();
      }))
      this.mapWorkOrders();
    }
    this.dataSource.data = this.products.map(p => this.dataTransformer(p));
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.itemId === id);
      this.treeControl.expand(node);
    });
    this.loading = false;
  }

  ngOnInit() {
    console.log('ngOnInit')
    this.getDetail();
  }

  visibleNodes(): NodeData[] {
    const result: NodeData[] = [];

    const addExpandedChildren = (node: NodeData, expanded: string[]) => {
      result.push(node);
      if (expanded.includes(node.itemId)) {
        node.children.map((child) => addExpandedChildren(child, expanded));
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected);
    });
    return result.filter(p => !this.hiddenChildren.includes(p.itemId));
  }

  findNodeSiblings(arr: NodeData[], id: string): NodeData[] {
    let result: NodeData[]
    let subResult: NodeData[];
    arr.forEach((item, i) => {
      if (item.itemId === id) {
        result = arr;
      } else if (item.children) {
        subResult = this.findNodeSiblings(item.children, id);
        if (subResult) result = subResult;
      }
    });
    return result;

  }

  rebuildTreeForData(data: NodeData[]) {
    // Need to grab all product refs to preserve them every time we do this
    const products = this.allProductsFlat();
    this.dataSource.data = data.map(d => {
      if (d.isNew) return d;
      d.productData = products.find(p => p.productId === d.itemId);
      return d;
    });
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.itemId === id);
      this.treeControl.expand(node);
    });
  }

  drop(event: CdkDragDrop<NodeData, TreeNode, TreeNode>) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) return;

    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();

    // deep clone the data source so we can mutate it
    const changedData: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));

    const previousParent = this.findNode(event.item.data.parentId, this.dataSource.data)?.productData;
    if (!previousParent) return;
    const oldProductRef = this.findNode(event.item.data.itemId, this.dataSource.data)?.productData;

    // skip all the below and just add to the end of the list and return if we're creating a new level
    if (this.isCreatingNewLevel && this.possibleParent) {
      const parentId = this.possibleParent.itemId;

      const newParentOldRef = this.findNode(this.possibleParent.itemId, this.dataSource.data)?.productData;

      const newSiblings = this.findNodeSiblings(changedData, event.item.data.itemId)
      const removeIndex = newSiblings.findIndex(s => s.itemId === event.item.data.itemId)

      const parent = this.findNode(parentId, changedData);
      newSiblings.splice(removeIndex, 1);
      previousParent.childAssemblies.splice(removeIndex, 1);
      const data = {
        ...event.item.data,
        productData: {
          ...event.item.data.productData,
          parentAssemblyId: parent.itemId
        },
        parentId: parent.itemId
      }
      parent.children = [...parent.children, data];
      oldProductRef.parentAssemblyId = parent.itemId;
      newParentOldRef.childAssemblies = [...newParentOldRef.childAssemblies, oldProductRef];
      this.productMoved.emit({
        productId: oldProductRef.productId,
        fromId: previousParent.productId,
        toId: newParentOldRef.productId
      });
      this.expansionModel.select(parentId);
      this.rebuildTreeForData(changedData);
      this.isCreatingNewLevel = false;
      this.possibleParent = null;
      this.productsChange.emit(this.products);
      return;
    }
    this.isCreatingNewLevel = false;
    this.possibleParent = null;

    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex];
    const level = this.treeControl.dataNodes.find(node => node.itemId === nodeAtDest.itemId).level;

    // Disallow moving anything to level 0
    if (level === 0 && this.single) return;

    const newSiblings = this.findNodeSiblings(changedData, nodeAtDest.itemId);
    if (!newSiblings) return;
    const insertIndex = newSiblings.findIndex(s => s.itemId === nodeAtDest.itemId);

    // remove the node from its old place
    const node: TreeNode = event.item.data;
    const siblings = this.findNodeSiblings(changedData, node.itemId);
    const siblingIndex = siblings.findIndex(n => n.itemId === node.itemId);
    const nodeToInsert = siblings.splice(siblingIndex, 1)[0];
    previousParent.childAssemblies.splice(siblingIndex, 1);
    if (nodeAtDest.itemId === nodeToInsert.itemId) return;
    if (newSiblings.map(x => x.parentId).some(x => x === event.item.data.itemId)) return;

    // insert node 
    const parentId = newSiblings[0].parentId;
    nodeToInsert.parentId = parentId;
    newSiblings.splice(insertIndex, 0, nodeToInsert);
    const newParentOldRef = this.findNode(parentId, this.dataSource.data)?.productData;
    oldProductRef.parentAssemblyId = newParentOldRef.productId;
    newParentOldRef.childAssemblies = [...newParentOldRef.childAssemblies, oldProductRef];
    this.productMoved.emit({
      productId: oldProductRef.productId,
      fromId: previousParent.productId,
      toId: newParentOldRef.productId
    });
    
    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData);
    this.productsChange.emit(this.products);
  }

  intentLevel: number = 0;

  public possibleParent: NodeData | null;
  public checkParent(itemId: string, insertIndex: number, siblings: NodeData[]) {
    siblings = siblings.filter(s => s.itemId !== itemId)
    if (insertIndex > 0) {
      const priorItem = siblings[insertIndex - 1];
      if (!priorItem.isTooling && priorItem.children.length === 0 || !this.expansionModel.isSelected(priorItem.itemId)) {
        this.possibleParent = priorItem;
      } else {
        this.possibleParent = null;
      }
    } else {
      this.possibleParent = null;
    }
  }

  dragSorted(event: CdkDragDrop<TreeNode, TreeNode, TreeNode>) {
    const visibleNodes = this.visibleNodes();
    const nodeAtDest = visibleNodes[event.currentIndex];
    const level = this.treeControl.dataNodes.find(node => node.itemId === nodeAtDest.itemId).level;
    const newSiblings = this.findNodeSiblings(this.dataSource.data, nodeAtDest.itemId);
    const insertIndex = newSiblings.findIndex(s => s.itemId === nodeAtDest.itemId);

    this.checkParent(event.item.data.itemId, insertIndex, newSiblings);
    

    if (level === 0 || (newSiblings.map(x => x.parentId).some(x => x === event.item.data.itemId))) {
      if (level === 0) {
        console.log('LEVEL === 0')
      } else if (newSiblings.map(x => x.parentId).some(x => x === event.item.data.itemId)) {
        console.log('SIBLING', newSiblings.find(x => x.parentId === event.item.data.itemId).name)
      }
      const x: HTMLElement = event.container.element.nativeElement.querySelector('.cdk-drag-placeholder');
      x.style.display = 'none';
    } else {
      const x: HTMLElement = event.container.element.nativeElement.querySelector('.cdk-drag-placeholder');
      x.style.display = 'block';
      x.style.paddingLeft = `${(level * 40)}px`;
    }
    this.productsChange.emit(this.products);
  }

  getTransform(x: number, y: number): string {
    // Round the transforms since some browsers will
    // blur the elements for sub-pixel transforms.
    return `translate3d(${Math.round(x)}px, ${Math.round(y)}px, 0)`;
  }

  hiddenChildren: string[] = [];


  dragStartedWithChildren(event: CdkDragStart<TreeNode>) {
    this.expansionModel.deselect(event.source.data.itemId);

    const siblings = this.findNodeSiblings(this.dataSource.data, event.source.data.itemId);
    const index = siblings.findIndex(x => x.itemId === event.source.data.itemId);

    this.checkParent(event.source.data.itemId, index, siblings);

    const originalElement: HTMLElement = event.source.element.nativeElement;
    const rect = originalElement.getBoundingClientRect();
    const dragRef: DragRef = event.source._dragRef
    // @ts-ignore
    dragRef._pickupPositionOnPage = { x: rect.left + (event.source.data.level + 1) * 40, y: rect.top }
    // Hide children
    this.hiddenChildren = this.treeControl.getDescendants(event.source.data).map(x => x.itemId);
    setTimeout(() => {
      const previewElement: HTMLElement = document.querySelector('.cdk-drag-preview');
      previewElement.style.width = `${rect.width}px`;
      previewElement.style.transform = this.getTransform(rect.left, rect.top);
    });
  }

  dragEnd() {
    this.hiddenChildren = [];
  }

  isCreatingNewLevel = false;
  dragMoved(event: CdkDragMove<TreeNode>) {
    const previewElement: HTMLElement = document.querySelector('.cdk-drag-preview');
    if (!previewElement) return;
    const placeholder: HTMLElement = event.source.dropContainer.element.nativeElement.querySelector('.cdk-drag-placeholder').querySelector('.mat-tree-node');
    if (this.possibleParent) {
      const level = this.treeControl.dataNodes.find(node => node.itemId === this.possibleParent.itemId).level;
      const threshold = (level + 1) * 40;
      // @ts-ignore
      const absoluteThreshold = (event.source.dropContainer.element.nativeElement.getBoundingClientRect().x) + threshold;
      // @ts-ignore
      if (previewElement.getBoundingClientRect().x > absoluteThreshold) {
        this.isCreatingNewLevel = true;
        placeholder.style.cssText = 'background-color: green !important;';
      } else {
        this.isCreatingNewLevel = false;
        placeholder.style.cssText = '';
      }
    } else {
      this.isCreatingNewLevel = false;
      placeholder.style.cssText = '';
    }
  }

  public isNewParent(itemId: string) {
    return this.isCreatingNewLevel && this.possibleParent && this.possibleParent.itemId === itemId;
  }

  public calculateTotalQuantity(product: Product) {
    if (!this.treeControl.dataNodes) return 0;
    let qty = 1;
    let currentProduct: Product = product;
    while (!!currentProduct.parentAssemblyId) {
      qty = qty * currentProduct.quantityAsChild;
      currentProduct = this.treeControl.dataNodes.find(p => p.itemId === currentProduct.parentAssemblyId).productData;
    }
    qty = qty * currentProduct.orderQuantity;
    return qty;
  }

  private allProductsFlatRecursive(product: Product): Product[] {
    return [product, ...product.childAssemblies.flatMap(p => this.allProductsFlatRecursive(p))]
  }

  public allProductsFlat(): Product[] {
    return this.products.flatMap(p => this.allProductsFlatRecursive(p));
  }

  public getHierarchy(): SortedProductHierarchy[] {
    const flat = this.treeControl.dataNodes;
    return flat.filter(x => !x.isTooling).map(p => {
      return {
        productId: p.itemId,
        parentId: p.parentId,
        newOrderQuantity: this.calculateTotalQuantity(p.productData!)
      }
    })
  }

  @Output() sorted = new EventEmitter<SortedProductHierarchy[]>();
  public doneSorting() {
    this.sorting = false;
    this.sorted.emit(this.getHierarchy());
    this.productsChange.emit(this.products);
  }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  @Input() selectedItemId: string;
  @Output() selectedItemIdChange = new EventEmitter<string>();
  public selectItem(id: string) {
    this.selectedItemId = id;
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  public menuPosition: { x: number; y: number } = { x: 0, y: 0 }
  public menuItem: TreeNode | null;
  public openMenu(event: MouseEvent, item: TreeNode | null) {
    if (!this.editing) return;
    event.preventDefault();
    if (item) {
      if (item.isTooling) { return; }
      event.stopPropagation();
      this.menuItem = item;
    } else {
      this.menuItem = null;
    }
    this.menuPosition = {
      x: event.clientX ?? 0,
      y: event.clientY ?? 0
    }
    this.menuTrigger.openMenu();
  }

  public isAdding(_: number, node: TreeNode) {
    return node.isNew || node.loading;
  }

  private getTopParent(productId: string) {
    const allProductsFlat = this.allProductsFlat();
    let topParent: Product = null;
    do {
      let currentParent = allProductsFlat.find(p => p.productId === productId);
      productId = currentParent.parentAssemblyId;
      if (currentParent.productId === currentParent.topParentAssemblyId) topParent = currentParent;
    } while (!topParent);
    return topParent;
  }

  @ViewChild('tree') tree: MatTree<any>;
  public addingItem: NodeData | null;
  public menuAddChild(parentNode: TreeNode | null) {
    if (!parentNode) parentNode = this.treeControl.dataNodes[0];
    parentNode.expandable = true;
    const r = parentNode.productData.childAssemblies.map(sa => 
      /-(\d+)$/.exec(sa.partNumber)
    );
    console.log(r);
    const highestExisting = r.filter(r => !!r).map(r => r[1])
      .filter(r => !!r)
      .map(r => parseFloat(r))
      .sort((a, b) => b - a)
      .at(0)
      ;
      
    const subAssemblyNumber = (highestExisting ?? 0) + 1;
    const oldParentRef = parentNode.productData;
    const changedData: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));
    const parent = this.findNode(parentNode.itemId, changedData);
    const prod = Product.newEmptyProduct();
    prod.workflow.workflowId = UtilityService.newGuid();
    prod.partNumber = `${parentNode.productData.partNumber}-${subAssemblyNumber}`;
    prod.revision = '-',
    prod.parentAssemblyId = parentNode.itemId;
    const topParent = this.getTopParent(parentNode.itemId);
    prod.topParentAssemblyId = topParent.productId;
    prod.quantityAsChild =  1;
    this.addingItem = this.dataTransformer(prod);
    this.addingItem.isNew = true;
    parent.productData = oldParentRef;
    parent.children = [...parentNode.children, this.addingItem];
    this.expansionModel.select(parentNode.itemId);
    this.rebuildTreeForData(changedData);
    const addInput = document.getElementById('adding-text-field');
    addInput.focus();
  }



  @Output() cloned = new EventEmitter<{
    cloned: Product,
    parent: Product,
    oldIdsMap: { [oldId: string]: string }
  }>();
  public menuDuplicateChild(origItem: TreeNode) {
    const oldParentRef = this.findNode(origItem.productData.parentAssemblyId, this.dataSource.data)?.productData;
    const changedData: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));
    const parent = this.findNode(origItem.parentId, changedData);
    let dupeProd: Product = JSON.parse(JSON.stringify(origItem.productData));
    let oldIdsMap: { [key: string]: string } = {};
    dupeProd = Product.generateNewIdsRecursive([dupeProd], dupeProd.parentAssemblyId, dupeProd.topParentAssemblyId, oldIdsMap)[0];
    const adding = this.dataTransformer(dupeProd);
    parent.productData = oldParentRef;
    const originalIndex = parent.children.findIndex(c => c.itemId === origItem.itemId);
    parent.children.splice(originalIndex + 1, 0, adding);
    parent.children = [...parent.children];
    this.rebuildTreeForData(changedData);
    const newParentRef = this.findNode(origItem.parentId, this.dataSource.data)?.productData;
    newParentRef.childAssemblies = [...newParentRef.childAssemblies, dupeProd];
    this.expansionModel.select(parent.itemId);
    this.productsChange.emit(this.products);
    this.cloned.emit({ cloned: adding.productData, parent: newParentRef, oldIdsMap });
  }

  public menuAddRoot() {
    const prod = Product.newEmptyProduct();
    this.addingItem = this.dataTransformer(prod);
    this.addingItem.isNew = true;
    this.dataSource.data = [...this.dataSource.data, this.addingItem];
    this.rebuildTreeForData(this.dataSource.data);
    const addInput = document.getElementById('adding-text-field');
    addInput.focus();
  }

  public cancelAdding(event: FocusEvent, node: TreeNode) {
    if (node.loading) return;
    // only cancel if newly focused event is not inside target
    const tgt = event.target as HTMLElement;
    const newTgt = event.relatedTarget as HTMLElement;
    if (
      newTgt &&
      (
        tgt === newTgt
        ||
        newTgt.contains(tgt)
        ||
        document.querySelector('#adding-text-field-container').contains(newTgt)
      )
    ) return;
    let changedData: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));
    const addingNode = this.findNode(UtilityService.emptyGuid, changedData);
    if (addingNode.parentId) {
      const parent = this.findNode(addingNode.parentId, changedData);
      parent.children = parent.children.filter(c => c.itemId !== UtilityService.emptyGuid);
    } else {
      changedData = changedData.filter(c => c.itemId !== UtilityService.emptyGuid);
    }
    this.rebuildTreeForData(changedData);
  }

  @Output() productsChange = new EventEmitter<Product[]>();
  @Output() created = new EventEmitter<[Product, Product]>();

  async saveAdding(node: TreeNode) {
    if (!this.addingItem.productData.partNumber) return;
    let oldParentRef: Product;
    if (node.parentId) {
      const parent = this.findNode(node.parentId, this.dataSource.data);
      oldParentRef = parent.productData;
    }
    const changedData: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));
    const nodeData = this.findNode(node.itemId, changedData);
    const prod = nodeData.productData;
    console.log(prod);
    const fakeNewId = UtilityService.newGuid();
    nodeData.itemId = fakeNewId;
    nodeData.isNew = false;
    console.log(changedData);
    // using original product data
    if (this.workOrders) {
      node.loading = true;
      this.rebuildTreeForData(changedData);
      const { product: savedProd, workOrder } = await this.woService.newSubassemblyPlanning(this.planningTicket, prod).toPromise();
      this.workOrdersChange.emit([...this.workOrders, workOrder]);
      const changedData2: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));
      const placeholderNode = this.findNode(fakeNewId, changedData2);
      node.loading = false;
      const newNode = this.dataTransformer(savedProd);
      newNode.workOrder = workOrder;
      Object.assign(placeholderNode, newNode);
      this.rebuildTreeForData(changedData2);
    } else {
      prod.productId = fakeNewId;
      const newNode = this.dataTransformer(prod);
      Object.assign(nodeData, newNode);
      if (node.parentId) {
        oldParentRef.childAssemblies = [...oldParentRef.childAssemblies, prod]
        const newParentNode = this.findNode(node.parentId, changedData);
        newParentNode.productData = oldParentRef;
      } else {
        this.products = [...this.products, prod];
        prod.topParentAssemblyId = fakeNewId;
        this.productsChange.emit(this.products);
      }
      this.rebuildTreeForData(changedData);
    }
    console.log(this.products)
    this.created.emit([prod, oldParentRef]);
  }

  @Output() productDeleted = new EventEmitter<{
    productId: string,
    parentId: string
  }>();
  async menuDelete(item: TreeNode) {
    const r = await this.utilitySvc.showConfirmationPromise('Delete subassembly?', `<p>Really delete subassembly <b>${item.productData.partNumber} Rev. ${item.productData.revision}</b>?</p><p class="font-weight-bold text-danger">This cannot be undone and may result in severe data loss.</p>`, this.workOrders ? null : 3)
    if (!r) return;
    if (item.itemId === this.selectedItemId) {
      this.selectItem(item.parentId ?? null);
    }
    if (this.workOrders) {
      this.loading = true;
      await this.orderSvc.removeSubComponentId(item.productData.parentAssemblyId, item.itemId).toPromise();
    }
    let changedData: NodeData[] = JSON.parse(JSON.stringify(this.dataSource.data));
    if (item.productData.parentAssemblyId) {
      const oldParentRef = this.findNode(item.productData.parentAssemblyId, this.dataSource.data)?.productData;
      const parent = this.findNode(item.productData.parentAssemblyId, changedData);
      parent.children = parent.children.filter(c => c.itemId !== item.itemId);
      oldParentRef.childAssemblies = oldParentRef.childAssemblies.filter(c => c.productId !== item.itemId);
    } else {
      changedData = changedData.filter(c => c.itemId !== item.itemId);
      this.products = this.products.filter(c => c.productId !== item.itemId);
    }
    this.rebuildTreeForData(changedData);
    this.productsChange.emit(this.products);
    this.productDeleted.emit({
      productId: item.itemId,
      parentId: item.parentId
    });
    this.loading = false;
  }

  getMainQuantity(product: Product) {
    if (product.orderQuantity) return product.orderQuantity;
    else return product.quantitiesMap[0]?.value ?? undefined;
  }

  @Output() productMoved = new EventEmitter<{
    productId: string,
    fromId: string,
    toId: string
  }>();

}
