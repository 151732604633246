import { Injectable, Inject, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../../common/services/message.service';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { httpService } from '../../common/services/http.service';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class AdminService extends httpService {
  
  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl) {
    super(errorHandler, messages);
  }

  public listGlobalConstants(): Observable<{ id: string, title: string, value: any, category: string }[]> {
    return this.http.get<any>(this.baseUrl + 'api/GlobalConstant/list').pipe(
      catchError(this.handleError<any>("List Global Constants"))
    );
  }

  public saveGlobalConstants(data: { id: string, title: string, value: any, category: string }[]): Observable<{ id: string, title: string, value: any, category: string }[]> {
    return this.http.post<any>(this.baseUrl + 'api/Admin/saveGlobalConstants', data).pipe(
      catchError(this.handleError<any>("List Global Constants"))
    );
  }


}
