<div class="row m-0 h-100-t" ngForm #form="ngForm">
  <div class="col-12 d-flex py-2 border-bottom" *ngIf="progressService?.shouldShowIndicators()">
    <div class="ml-auto">
      <estimating-progress-status label="Hardware Status" [status]="getHardwareTaskStatus()" (statusChange)="setHardwareTaskStatus($event)" [disabled]="!editing"></estimating-progress-status>
    </div>
  </div>
  <div class="col-4 h-100-t p-0 d-flex flex-column mat-elevation-z6">
    <div style="font-size: 1.25rem" class="font-weight-normal p-3 border-bottom position-relative">
      Purchased Items
      <button mat-fab *ngIf="editing" class="bg-success text-white" style="position: absolute; bottom: -2em; right: 2em; z-index: 10"
        (click)="createNew()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <mat-list class="pt-0 pt-4 flex-grow-1 position-relative" style="height: 0; overflow-y: scroll;">
      <mat-list-item *ngFor="let item of product.purchasedItems"
        class="border-bottom bg-white list-item cursor-pointer"
        [class.bg-primary]="selectedItemId === item.productPurchasedItemId"
        [class.text-white]="selectedItemId === item.productPurchasedItemId" 
        (click)="selectedItemId = item.productPurchasedItemId">
        <div class="w-100 text-ellipsis d-flex align-items-center">
          <span class="badge mr-2 bg-dark text-white">{{ item.quantity }}<span *ngIf="!item.isNonRecurring">/part</span></span>
          <span *ngIf="item.purchasedItem" class="small text-ellipsis">{{ item.purchasedItem?.description }}</span>
          <span *ngIf="!item.purchasedItem" class="small font-italic text-ellipsis">(No Item Selected)</span>
          <span class="ml-auto small text-muted">
            ({{ getCost(item) | currency }}/p)
          </span>
          <button mat-icon-button (click)="deleteItem(item)" class="ml-1">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <div *ngIf="product.purchasedItems.length === 0"
        class="w-100 text-muted text-center position-absolute small p-4" style="pointer-events: none;">
        This product has no purchased items.
      </div>
    </mat-list>
  </div>
  <div class="col-8 p-3 pt-5">
    <div class="row m-0 align-items-start" *ngIf="selectedItem">
      <div class="col-8 row m-0">
        <div class="col-8">
          <purchased-item-search [readonly]="!editing" name="purchasedItem" [(ngModel)]="selectedItem.purchasedItem" (ngModelChange)="selectedItem.purchasedItemId = $event.purchasedItemId"
            (onAddItem)="addItem($event)"         
          ></purchased-item-search>
        </div>
        <div class="col-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Quantity</mat-label>
            <input type="number" matInput name="quantity" [(ngModel)]="selectedItem.quantity" [readonly]="!editing" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-button-toggle-group [disabled]="!editing" aria-label="Font Style" name="isNonRecurring" [(ngModel)]="selectedItem.isNonRecurring">
            <mat-button-toggle value="everytime" [value]="false">Every Time</mat-button-toggle>
            <mat-button-toggle value="amortized" [value]="true">Non-recurring</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col-6">
          <mat-button-toggle-group [disabled]="!editing" aria-label="Font Style" name="isAmortized" [(ngModel)]="selectedItem.isAmortized" *ngIf="selectedItem.isNonRecurring">
            <mat-button-toggle value="amortized" [value]="true">Amortized</mat-button-toggle>
            <mat-button-toggle value="everytime" [value]="false">Non-amortized</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col-12">
          <div class="mat-dialog-title">Microtickets for Purchased Item</div>
        </div>
        <div class="col-12 mt-3">
          <microticket-subitem-viewer
            [relatedTicketId]="record.salesProcessId"
            [relatedTicketName]="service.nameForMicrotickets"
            [relatedTicketType]="'SalesProcess'"
            [filterKey]="service.microticketFilterKey"
            [subItem]="service.generateSubItemNavigationId(product.productId, 'hardware', selectedItem.productPurchasedItemId)"
            (loadingChange)="service.loading = $event"
            [microTickets]="service.sharedMicroticketSubject"
          ></microticket-subitem-viewer>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-2" *ngIf="selectedItem.purchasedItem">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Outsource Markup</mat-label>
            <input type="number" matInput name="markupPercent" [(ngModel)]="selectedItem.markupPercent" [readonly]="!editing" />
            <span matSuffix>
              %
            </span>
          </mat-form-field>
        </div>
        <product-quoting *ngIf="selectedItem.purchasedItem"
        [order]="record" [purchasedItemId]="selectedItem.purchasedItem.purchasedItemId" name="selectedQuote" [(ngModel)]="selectedItem.selectedQuote" (quoteSelected)="setItemPrice(selectedItem, $event)" [editable]="editing"
        ></product-quoting>
      </div>
    </div>
  </div>
</div>

<ng-template #newItemDialog let-data>
  <h2 matDialogTitle>New Item</h2>
  <mat-dialog-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" autocomplete="off" [(ngModel)]="data.name">
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!data.name" [matDialogClose]="data.name">Create Item</button>
  </mat-dialog-actions>
</ng-template>
