import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from './../../../common/services/utility.service';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { InventoryItemLocation } from '../../resources/inventory-item-location';
import { InventoryLocationDetailComponent } from '../inventory-location-detail/inventory-location-detail.component';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.less']
})
export class InventoryDetailComponent implements OnInit {
  public keys = Object.keys;
  @Input() id: string | null;
  @Input() openedFrom: MatSidenav | null = null;
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itemSaved: EventEmitter<InventoryItem> = new EventEmitter<InventoryItem>();
  public editing: boolean = false;
  public adding: boolean = false;
  public contactsView: boolean = false;
  public saving: boolean = false;
  public showName: boolean = true;
  public showNumber: boolean = true;
  public item: InventoryItem | null;
  public quantities: number[];
  public itemLocations: InventoryItemLocation[];
  public addresses: Object;
  public filteredLabels: string[];
  private fromExternal: boolean = false;
  private static standardLabels: string[] = ['Fastener', 'Material', 'Assembly', 'Custom', 'Standard', 'Packing'];

  @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('sidenav', { static: true }) locationPanel: MatSidenav;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(InventoryLocationDetailComponent, { static: true }) private locationDetail: InventoryLocationDetailComponent;

  constructor(private navService: NavigationService, private service: InventoryService, private route: ActivatedRoute, private router: Router) { }

  getDetail(): void {
    this.item = null;
    this.id = this.id || UtilityService.emptyGuid;

    // If we're adding a new item, make a blank slate
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;
      this.adding = true;
      this.item = <InventoryItem>{
        inventoryItemId: UtilityService.emptyGuid,
      };
      this.item.labels = this.item.labels || [];
      this.itemLocations = [];
    }
    // If this is an existing item, grab the details for it
    else {
      this.service.getDetail(this.id).subscribe(detail => {
        if (detail) {
          this.item = detail;
          this.item.labels = this.item.labels || [];
          this.getItemLocations();
        }
      });
    }
  }

  saveChanges(): void {
    if (!this.item) return;
    this.saving = true;
    // Save the item first
    this.service.save(this.item).subscribe(detail => {
      this.item = detail;
      if (this.id != this.item.inventoryItemId) this.itemSaved.emit(this.item);

      // Set the retrieved item id on the itemlocations
      this.itemLocations.forEach((itemLoc) => {
        if (this.item) itemLoc.inventoryItemId = this.item.inventoryItemId;
      });
      // Save the item locations
      this.service.saveItemLocations(this.itemLocations).subscribe(_ => {
        this.saving = false;
        this.editing = false;

        if (this.openedFrom) {
          this.updated.emit(true);
          this.openedFrom.close();
        }
      });
    });
  }

  getItemLocations(): void {
    if (!this.item) return;
    this.itemLocations = [];
    // Get the list of ItemLocations matching this id
    this.service.getItemLocations(this.item.inventoryItemId).subscribe(itemLocs => {
      let idList: string[] = [];
      this.addresses = {};

      // Generate the list of location ids
      itemLocs.forEach((itemLoc) => {
        idList.push(itemLoc.inventoryLocationId);
      });

      // Get the addresses for the listed ids and put them in this.addresses
      this.service.getAddresses(idList).subscribe(addressList => {
        addressList.forEach((address) => {
          let thisChild = address[address.length - 1].inventoryLocationId;
          this.addresses[thisChild] = address;
        });

        // Iterate over itemlocs again, storing the address alongside each itemlocation
        itemLocs.forEach((itemLoc, index) => {
          this.itemLocations[index] = itemLoc;
          this.itemLocations[index].address = this.addresses[itemLoc.inventoryLocationId];
        });
      });
    });
  }

  addNewLocation(): void {
    this.locationPanel.open();
  }

  editLocation(itemLoc: InventoryItemLocation): void {
    // Clicks on a location while we're not in edit mode should not pop up the editor
    if (!this.editing) {
      return;
    }

    // Set up existing itemlocaiton in the panel and open it
    this.locationDetail.select(itemLoc.address.slice(-1)[0]);
    this.locationDetail.setValues(itemLoc);
    this.locationPanel.open();
  }

  updateLocation(itemLoc: InventoryItemLocation): void {
    // This was just saved from location-detail. For now, add it to the list,
    // we'll save them all in bulk when we save the item
    this.itemLocations.forEach((existingItemLoc, index, list) => {
      if (existingItemLoc.inventoryItemLocationId == itemLoc.inventoryItemLocationId) {
        list.splice(index, 1);
      }
    });
    this.itemLocations.push(itemLoc);
    this.locationDetail.reset();
    this.saveChanges();
  }

  delete(itemLoc: InventoryItemLocation): void {
    this.service.deleteItemLocation(itemLoc.inventoryItemLocationId).subscribe(_ => {
      let index = this.itemLocations.indexOf(itemLoc);
      this.itemLocations.splice(index, 1);
    });
  }

  addNewLabel(item: InventoryItem, event: MatChipInputEvent): void {
    // Add label only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our label
      if ((value || '').trim()) {
        item.labels = item.labels || [];
        item.labels.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.labelInput.nativeElement.value = '';
      this.labelInputChanged();
    }
  }

  removeLabel(item: InventoryItem, t: string): void {
    const index = item.labels.indexOf(t);

    if (index >= 0) {
      item.labels.splice(index, 1);
    }
  }

  addLabel(item: InventoryItem, event: MatAutocompleteSelectedEvent): void {
    if (item.labels.indexOf(event.option.viewValue) < 0) {
      item.labels.push(event.option.viewValue);
    }
    this.labelInput.nativeElement.value = '';
    this.labelInputChanged();
  }

  labelInputChanged(): void {
    var searchFor = this.labelInput.nativeElement.value.toLowerCase();
    this.filteredLabels = InventoryDetailComponent.standardLabels.filter((v, i, a) => v.toLowerCase().indexOf(searchFor) == 0);
  }

  toggleEditing(): void {
    if (this.openedFrom) {
      this.openedFrom.close();
      return;
    }

    if (this.fromExternal) {
      return;
    }

    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.router.navigate(['/inventory']);
    }

    this.getDetail();
    this.editing = !this.editing;
  }

  ignoreOpen(): void {
    event && event.stopImmediatePropagation();
    this.labelInputChanged();
  }

  ngOnInit(): void {
    if (this.id == null) {
      this.id = this.route.snapshot.paramMap.get('id');
      this.navService.setPageTitle("Inventory Detail");
    }
    else {
      this.fromExternal = true;
      this.editing = true;
    }

    this.getDetail();

  }

  getItemTypeIcon(i: InventoryItem): string {
    if (i.productId) return 'cog';
    else if (i.materialId) return 'cube';
    else if (i.purchasedItemId) return 'box-open';
    else return '???';
  }

  getItemTypeName(i: InventoryItem): string {
    if (i.productId) return 'Part/Assembly';
    else if (i.materialId) return 'Raw Material';
    else if (i.purchasedItemId) return 'Purchased/Hardware';
    else return '???';
  }

}
