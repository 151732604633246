  <a *ngIf="record && !editing && canEditDetails" class="position-fixed fixed-bottom btn btn-badge has-icon"
    title="Edit Record" (click)="editing = true"><i class="fas fa-edit"></i></a>
  <a *ngIf="(record && editing && this.id != 'new')"
    class="mr-6 position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Cancel Edit"
    (click)="editing = false; getDetail()"><i class="fas fa-times"></i></a>
  <a *ngIf="record && editing" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save Record"
    (click)="saveChanges()"><i class="fas fa-save"></i></a>

  <loading *ngIf="!record"></loading>
  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="mat-drawer-container pb-4" *ngIf="record">
    <div class="row">
      <div class="col-12 pt-4 pb-4">
        <div class="row border border-bottom" *ngIf="userIsManager() && record.status == 1">
          <div class="col-12 text-center rounded p-4" [ngClass]="{'bg-info text-light': !record.assignedTo}">
            <div *ngIf="!record.assignedTo">
              <h3>Needs Assignment</h3>
              <p>This requisition needs to be assigned to an employee before it can be fulfilled.</p>
            </div>
            <div class="row align-items-center justify-content-center">
              <div class="col-2 text-right" *ngIf="record.assignedTo">
                <span>Assigned To:</span>
              </div>
              <div class="col-6">
                <employee-select [editable]="true" (employeeChange)="assigningEmployee = $event"
                  [employee]="record.assignedUser"></employee-select>
              </div>
              <div class="col-2"><button [disabled]="!assigningEmployee" class="btn btn-success"
                  (click)="saveAssignment()"><span *ngIf="record.assignedTo">Re-</span>Assign</button></div>
            </div>
          </div>
        </div>

        <div class="badge w-90 m-3 text-center" [ngClass]="getStatusColorClass(record.status)">
          <h4>{{getStatusText(record.status)}}</h4>
          <p *ngIf="record.statusNote?.length>0">Note: {{record.statusNote}}</p>
          <div *ngIf="record.status==1" class="font-weight-light">
            <p>This requisition is awaiting review by purchasing.</p>
            <div class="pt-4 mb-2" *ngIf="(userIsManager() || userIsAssigned()) && !editing">
              <button class="btn btn-success mr-4" title="Approve this order" (click)="approveOrder()"><i
                  class="fas fa-check fa-fw fa-2x"></i> Approve</button>
              <button class="btn btn-danger" title="Reject this order" (click)="rejectOrder()"><i
                  class="fas fa-times fa-fw fa-2x"></i> Reject</button>
            </div>
          </div>
          <div class="mx-auto my-2 ng-star-inserted w-25" *ngIf="userIsPurchasing() && (record.status == 3 || record.status == 4)">
            <button class="btn btn-block btn-light" [disabled]="saving" (click)="openQuoting()">
              <h5>Go to Quoting</h5>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col">
            <h5>Requisition Information</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Requisition Number</label>
              <span class="d-block small"
                [ngClass]="{'text-muted':record.requisitionNumber==null}">{{record.requisitionNumber || '-- Not Yet Assigned  --'}}</span>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Requisitioner</label>
              <span class="d-block small"
                [ngClass]="{'text-muted':record.createdBy?.fullName==null}">{{record.createdBy?.fullName || '--'}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Department</label>
              <mat-select [(ngModel)]="record.department" *ngIf="editing">
                <mat-option value="Estimating">Estimating</mat-option>
                <mat-option value="Sales">Sales</mat-option>
                <mat-option value="Purchasing">Purchasing</mat-option>
                <mat-option value="Customer Service">Customer Service</mat-option>
                <mat-option value="Production">Production</mat-option>
                <mat-option value="Planning">Planning</mat-option>
                <mat-option value="Programming">Programming</mat-option>
                <mat-option value="Quality">Quality</mat-option>
                <mat-option value="Shipping">Shipping</mat-option>
              </mat-select>
              <span class="d-block small" *ngIf="!editing">{{record.department}}</span>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>
                Due Date
                <span *ngIf="!!record.dueDate" class="p-1 pl-2 pr-2 badge required"
                  [ngClass]="getRequiredColor(record.dueDate)">
                  <span class="fa-lg">
                    {{daysLeft(record.dueDate, true)||'--'}} Day(s)
                    {{(daysLeft(record.dueDate, false)||0) >= 0 ? 'From Today':'Overdue'}}
                  </span>
                </span>
              </label>
              <date-picker [placeholder]="editing ? 'Due Date' : '--'" [editable]="editing" [(date)]="record.dueDate">
              </date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Note/Description</label>
              <input [(ngModel)]="record.note" required type="text" max="100" class="form-control" appStaticEdit
                [editable]="editing" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col">
            <h5>Requisition Items</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="border rounded">
              <table class="table mb-0 table-striped">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Item</th>
                    <th>
                      Amount Required
                    </th>
                    <th>Due Date</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let reqItem of record.requisitionLineItems">
                    <td>
                      <item-type-chip [item]="reqItem"></item-type-chip>
                    </td>
                    <td>
                      {{getLineItemName(reqItem)}}
                    </td>
                    <td>
                      {{reqItem.amountRequired}}
                    </td>
                    <td>
                      <span *ngIf="!!reqItem.dueDate">
                        {{ reqItem.dueDate | date }}
                      </span>
                      <span *ngIf="!reqItem.dueDate" class="text-muted">
                        --
                      </span>
                    </td>
                    <td>
                      <button *ngIf="editing && canEditDetails" (click)="editItem(reqItem)"
                        class="mx-1 btn btn-sm btn-primary" title="Edit Line Item">
                        <i class="fas fa-fw fa-lg fa-edit"></i>
                      </button>
                      <button *ngIf="editing && canEditDetails" class=" mx-1 btn btn-sm btn-danger"
                        title="Remove Line Item" (click)="deleteItem(reqItem)">
                        <i class="fas fa-fw fa-lg fa-minus-circle"></i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="record.requisitionLineItems?.length==0">
                    <td class="text-center text-muted font-italic" colspan="100%">-- No Items --</td>
                  </tr>
                  <tr *ngIf="editing && canEditDetails" (click)="newItem()">
                    <td colspan="100%" class="text-right">
                      <button class="btn btn-sm btn-primary"><i class="fas fa-fw fa-lg fa-plus"></i>
                        Add Line Item</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="record.status == 3 || record.status == 4">
      <div class="row">
        <div class="col">
          <h5>Ordering & Shipping Information</h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel disabled [expanded]="true"
              class="d-flex align-items-center justify-content-center py-5" *ngIf="orderInfo === null">
              <mat-spinner [color]="'warn'"></mat-spinner>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="orderInfo.length === 0" disabled>
              <mat-expansion-panel-header>
                -- No purchase orders found relating to this requisition. --
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <ng-container *ngIf="orderInfo">
              <mat-expansion-panel *ngFor="let purchaseData of orderInfo" [expanded]="orderInfo.length === 1">
                <mat-expansion-panel-header>
                  <mat-panel-title style="font-size: 1.2em;">
                    <span class="font-weight-bold">{{ purchaseData.po.purchaseOrderNumber }}</span>
                    <span class="small ml-3" *ngIf="purchaseData.po.vendor">
                      Vendor: {{ purchaseData.po.vendor.name }}
                    </span>
                    <span class="badge text-uppercase font-weight-bold ml-3"
                      [ngClass]="getPOStatusColorClass(purchaseData.po.status)">{{getPOStatusText(purchaseData.po.status)}}</span>
                  </mat-panel-title>
                  <mat-panel-description>
                    {{ purchaseData.items.length }} relevant items
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <h5 class="mx-3 mt-3">Line Items</h5>
                <mat-list>
                  <mat-list-item class="border-bottom" *ngFor="let lineItem of purchaseData.items; first as first"
                    [class.border-top]="first">
                    <mat-icon class="text-muted mr-2">archive</mat-icon>
                    {{lineItem.name}}
                    <span class="text-muted"> — </span>
                    <b>{{lineItem.quantity}}</b>x
                  </mat-list-item>
                </mat-list>
                <ng-container
                  *ngIf="purchaseData.po.status == 0 || purchaseData.po.status == 1 || purchaseData.po.status == 2">
                  <h5 class="mx-3 mt-3">Shipping Tickets</h5>
                  <mat-list>
                    <mat-list-item class="border-bottom"
                      *ngFor="let ticket of purchaseData.shippingTickets; first as first" [class.border-top]="first">
                      <mat-icon class="text-muted mr-2">local_shipping</mat-icon>
                      <b class="mr-3">{{getShippingTicketNumber(ticket)}}</b>
                      <span class="badge text-uppercase font-weight-bold mr-2"
                        [ngClass]="getShippingTicketStatusColorClass(ticket.status)">{{getShippingTicketStatusText(ticket.status)}}</span>
                      <span class="ml-2 smallks">
                        <b>{{ticket.quantityRequired}}x</b> {{ ticket.inventoryItem.name }}
                      </span>
                      <span *ngIf="ticket.shippingCarrier && ticket.trackingNumber" class="ml-3">
                        <button class="btn btn-sm btn-link" (click)="openTracking(ticket)">
                          <img [attr.src]="getShippingCarrierIcon(ticket)" />
                          Track
                          <i class="fas fa-fw fa-lg fa-external-link-alt text-muted pl-2"></i>
                        </button>
                      </span>
                    </mat-list-item>
                  </mat-list>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
    </ng-container>
  </div>


  <ng-template #itemEditDialogTemplate let-data>
    <div matDialogTitle class="d-flex">
      <span>{{ data.requisitionLineItemId === emptyGuid ? 'New Line Item' : 'Editing Line Item' }}</span>
      <mat-button-toggle-group [disabled]="data.requisitionLineItemId !== emptyGuid" class="ml-auto align-self-center small" [(ngModel)]="newItemType" [value]="'purchased'" (change)="
        data.purchasedItemId = null;  
        data.purchasedItem = null;
        data.materialId = null;
        data.material = null;
        data.stationId = null;
        data.station = null;
        materialSelect.resetMaterialParameters();
      ">
        <mat-button-toggle value="purchased">Purchased Item</mat-button-toggle>
        <mat-button-toggle value="material">Raw Material</mat-button-toggle>
        <mat-button-toggle value="station">Outside Process</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-dialog-content>
      <form #itemEditForm="ngForm" class="d-block container-fluid">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <ng-container *ngIf="newItemType === 'purchased'">
              <label>Item</label>
                <purchased-item-select [disabled]="data.requisitionLineItemId !== emptyGuid" [class.d-none]="!canEditDetails" name="inventoryItem" required notNullValidator
                  [(ngModel)]="data.purchasedItem">
                </purchased-item-select>
                <div *ngIf="!canEditDetails">{{data.purchasedItem?.description}}</div>
              </ng-container>
              <mat-radio-group class="mb-3" *ngIf="canEditDetails" [(ngModel)]="newMaterialType" [ngModelOptions]="{standalone: true}" [class.d-none]="newItemType !== 'material'"
              (change)="
                materialSelect.resetMaterialParameters();
                data.material = null;
                data.materialId = null;
              "
              >
                <mat-radio-button class="mr-1" value="new">Build Material</mat-radio-button>
                <mat-radio-button value="search">Search Existing Materials</mat-radio-button>
              </mat-radio-group>
              <material-select [class.d-none]="newItemType !== 'material' || newMaterialType !== 'new'" #materialSelect [material]="data.material" [disabled]="data.requisitionLineItemId !== emptyGuid || !canEditDetails" name="material" required notNullValidator>
              </material-select>
              <material-simple-search [class.d-none]="newItemType !== 'material' || newMaterialType !== 'search'" [(ngModel)]="data.material" name="materialSearch" [disabled]="data.requisitionLineItemId !== emptyGuid"></material-simple-search>
              <station-select [editable]="true" *ngIf="newItemType === 'station'" [(station)]="data.station"></station-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Required Quantity</label>
              <div style="margin-top: 5px;">
                <input name="amountRequired" type="number" [(ngModel)]="data.amountRequired" required [minValidator]="1"
                  min="1" step="1" class="form-control form-control-sm" appStaticEdit [editable]="canEditDetails" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Due Date <small class="text-muted">(optional)</small></label>
              <date-picker [(date)]="data.dueDate" [placeholder]="canEditDetails ? 'Due Date' : '--'" [editable]="canEditDetails">
              </date-picker>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button *ngIf="data.requisitionLineItemId === emptyGuid" mat-button [disabled]="
      (newItemType === 'material' &&
        (
          (this.newMaterialType === 'new' && !materialSelect.materialFullyInput) ||
          (this.newMaterialType === 'search' && !data.material)
        )
      ) 
      || itemEditForm.invalid || itemEditForm.pristine" color="success"
        (click)="saveNewItem(data, newItemType, materialSelect?.parameterIds)">Save</button>
      <button *ngIf="data.requisitionLineItemId !== emptyGuid" mat-button [disabled]="itemEditForm.invalid || itemEditForm.pristine" color="success"
        (click)="saveItem(data)">Save</button>
      <button mat-button matDialogClose color="danger">Cancel</button>
    </mat-dialog-actions>
  </ng-template>


  <ng-template #readyDialogTemplate>
    <h2 mat-dialog-title>Are You Finished?</h2>
    <mat-dialog-content class="mat-typography">
      <p>Is this requisition ready for review by purchasing, or is there still more to be done?</p>
      <p>Once it is approved, you won't be able to edit it further.</p>
      <p class="m-3">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" #ready>It's Ready for Review</mat-radio-button>
          <mat-radio-button value="2" #needswork class="ml-3">Still Needs More Work</mat-radio-button>
        </mat-radio-group>
      </p>
      <p [hidden]="!needswork.checked">
        <textarea class="form-control form-control-sm" #note
          placeholder="Please tell us what still needs to be done..."></textarea>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="needswork.checked || ready.checked">
      <button class="btn btn-default btn-sm mr-3"
        [mat-dialog-close]="{ready: ready.checked, note: note.value}">Save</button>
      <button class="btn btn-primary btn-sm" [mat-dialog-close]="null">Cancel</button>
    </mat-dialog-actions>

  </ng-template>


  <ng-template #rejectReasoningDialogTemplate>
    <h2 mat-dialog-title>Rejecting Requisition Request</h2>
    <mat-dialog-content class="mat-typography">
      <p>Please explain why this requisition request was rejected, and what needs to be done to fix it.</p>
      <p>
        <textarea class="form-control form-control-sm" #note
          placeholder="Please tell us what needs to be done for this requisition to proceed..."></textarea>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button class="btn btn-default btn-sm mr-3"
        [mat-dialog-close]="{confirm: false, note: note.value}">Cancel</button>
      <button class="btn btn-default btn-sm mr-3" [mat-dialog-close]="{confirm: true, note: note.value}">Okay</button>
    </mat-dialog-actions>

  </ng-template>


  <ng-template #fulfillDialogTemplate let-data>
    <h2 mat-dialog-title>Fulfill Item</h2>
    <mat-dialog-content class="mat-typography">
      <p>How much of the item was received?</p>
      <div class="col flex-grow-1 input-group input-group-sm">
        <input min="1" step="1" [max]="data.item.amountRequired - data.item.amountReceived"
          [(ngModel)]="data.fulfillAmount" required type="number" class="form-control form-control-sm" appStaticEdit
          [editable]="true" />
        <div class="input-group-append">
          <span class="input-group-text">
            / {{ data.item.amountRequired - data.item.amountReceived }}
          </span>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
      <button [disabled]="!(data.fulfillAmount && data.fulfillAmount > 0 && data.fulfillAmount <= (
          data.item.amountRequired - data.item.amountReceived
        ))" [matDialogClose]="data" class="btn btn-default btn-sm mr-3">Okay</button>
    </mat-dialog-actions>

  </ng-template>
