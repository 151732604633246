import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Order } from '../../resources/order';
import { Product } from '../../resources/product';
import { OrderService } from '../../services/order.service';
import { map, startWith } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'copy-part-dialog',
  templateUrl: './copy-part-dialog.component.html',
  styleUrls: ['./copy-part-dialog.component.less']
})
export class CopyPartDialogComponent implements OnInit {

  public searchText = '';
  searchControl = new FormControl<string>('');
  public parts: Product[];
  filteredOptions: Observable<Product[]>;
  public selectedPart: Product;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { orderId: string }, private orderSvc: OrderService) {}

  ngOnInit() {
    this.orderSvc.getAllProductsForOrder(this.data.orderId).subscribe((p) => {
      this.parts = p;
    });
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this.filterOptions(item) : (this.parts != null ? this.parts.slice() : null)));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    event.option.deselect();
    this.selectedPart = event.option.value;
    this.searchInput.nativeElement.value = '';
    this.searchControl.setValue(event.option.viewValue);
  }

  private filterOptions(value: any): Product[] {

    if (!this.parts) return [];

    var nameValue = (typeof value === 'string') ? value : ((value instanceof Product) ? `${value.partNumber} Rev. ${value.revision}` : null);

    if (nameValue === null) return [];

    const filterValue = nameValue.toLowerCase();
    return this.parts.filter(item => `${item.partNumber} Rev. ${item.revision}`.toLowerCase().includes(filterValue));
  }

}
