import { User } from "../../common/resources/user";
import { VirtualDocument } from "../../common/resources/virtual-document";
import { Order, OrderStatus } from "../../order/resources/order";
import { RMATicket } from "../../rma/resources/rma-ticket";
import { ShippingTicket, ShippingTicketPlaceLocation } from "../../shipping/resources/shipping-ticket";
import { Vendor, VendorCertificate } from "../../supplier/resources/vendor";

export enum QualityTicketType {
    ContractReview = 0,
    ReceivedShippingTicket = 1,
    SupplierCertExpiration = 2,
    RMAEvaluation = 3,
    RMAApproval = 4,
}

export interface QualityTicket {
    
    qualityTicketId: string;
    ticketType: QualityTicketType;

    qualityTicketNumber: string;

    status: number;

    orderSegmentId?: string;
    orderSegment?: Order;
    shippingTicketPlaceLocationId?: string;
    shippingTicketPlaceLocation?: ShippingTicketPlaceLocation;
    vendorId?: string;
    vendor?: Vendor;
    vendorCertificateId?: string;
    vendorCertificate?: VendorCertificate;
    rmaTicketID?: string;
    rmaTicket?: RMATicket;


    assignedUserId?: string;
    assignedUser?: User;

    contractReviewAnswers: {
        [productId: string]: {
            [categoryId: string]: {
                [questionId: string]: string
            }
        }
    };
    topLevelPOReviewAnswers : {
        [productId: string]: {
            [categoryId: string]: {
                [questionId: string]: string
            }
        }
    };

    dateCompleted?: Date;


    qualityTicketDocuments: QualityTicketDocument[];
}

export interface QualityTicketDocument {
    qualityTicketQualityTicketId: string;
    documentDocumentId: string;
  
    document?: VirtualDocument;
  }