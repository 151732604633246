import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OrderDetailService } from '../../../order-detail.service';
import { CdkDragDrop, CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { Station } from '../../../../../resources/station';
import { WorkflowStep } from '../../../../../resources/workflow';
import { UtilityService } from '../../../../../../common/services/utility.service';
import { StationService } from '../../../../../services/station.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'product-workflow-workflow-list',
  templateUrl: './product-workflow-workflow-list.component.html',
  styleUrls: ['./product-workflow-workflow-list.component.less']
})
export class ProductWorkflowWorkflowListComponent implements OnInit, OnDestroy {

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public getName(step: WorkflowStep) {
    return this.getStation(step)?.name ?? 'Unknown Station';
  }

  @Input() selectedStepId: string;
  @Output() selectedStepIdChange = new EventEmitter<string>();

  constructor(private service: OrderDetailService, private stationService: StationService) { }

  public get record() { return this.service.order }
  public get product() { return this.service.selectedProduct }
  public get editing() { return this.service.editing }

  private doSort() {
    if (this.product) this.product.workflow.workflowSteps.sort((a, b) => a.stepOrder - b.stepOrder);
  }

  public stationList: Station[];
  private productSubscription: Subscription;
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
    this.doSort();
    this.productSubscription = this.service.productChange.subscribe(() => {
      this.doSort();
    });
  }

  ngOnDestroy(): void {
    if (this.productSubscription) this.productSubscription.unsubscribe();
  }

  @Output() stationDraggedIn = new EventEmitter<{ index: number, station: Station }>();

  drop(e: CdkDragDrop<any, any>) {
    console.log(e);
    // coming in from the station list
    if (e.previousContainer.id === "workflowStationListCdkDrop") {
      const station = e.item.data as Station;
      this.stationDraggedIn.emit({
        index: e.currentIndex,
        station
      });
    } else {
      moveItemInArray(this.product.workflow.workflowSteps, e.previousIndex, e.currentIndex);
      this.product.workflow.workflowSteps.forEach((v, i) => v.stepOrder = i);
    }
  }

  public get firstQuantity(): number {
    return this.service.getFirstQuantity(this.product);
  }

  public calculateCost(item: WorkflowStep): number {
    return WorkflowStep.calculateCostForQty(item, this.firstQuantity) / this.firstQuantity;
  }

  public calculatePercentage(item: WorkflowStep): number {
    let out = this.calculateCost(item) / this.product.workflow.workflowSteps.reduce((p, c) => { return p += this.calculateCost(c); }, 0);
    if (!out) return 0;
    else return out;
  }

}
