<mat-sidenav-container style="
padding-top: 15px;
margin-right: 0 !important;
margin-left: 0 !important;"
>
  <mat-sidenav disableClose #sidenav mode="over" position="end" (closed)="sidenavMode = null">
    <div style="padding: 50px" *ngIf="sidenavMode === 'ticket'">
      <shipping-ticket-detail [id]="selectedTicket?.shippingTicketId" [parentSidenav]="sidenav"></shipping-ticket-detail>
    </div>
    <shipping-check-in 
    *ngIf="sidenavMode === 'checkIn'" 
    [ticket]="selectedTicket" 
    [building]="currentBuilding | async" 
    [sidenav]="sidenav"
    (done)="sidenav.close(); loadInfo()"
    ></shipping-check-in>
    <shipping-check-out 
    *ngIf="sidenavMode === 'checkOut'" 
    [ticket]="selectedTicket" 
    [building]="currentBuilding | async" 
    [sidenav]="sidenav"
    (done)="sidenav.close(); loadInfo()"
    ></shipping-check-out>
    </mat-sidenav>
  <mat-sidenav-content class="border rounded">
    <shipping-wrapper>
      <mat-tab-group [(selectedIndex)]="currentTab" color="primary" backgroundColor="primary" class="h-100 queue-tab-group">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>arrow_back</mat-icon>
            <mat-icon class="fa-flip-horizontal mr-1">local_shipping</mat-icon>
            <span class="tab-title">Incoming</span>
            <span class="badge bg-white text-primary mx-1" *ngIf="countUrgentTickets(incomingTickets) > 0">
              {{countUrgentTickets(incomingTickets)}}
            </span>
            <i class="ml-1 fas fa-circle-notch fa-spin" [class.invisible]="incomingTickets"></i>
          </ng-template>
          <div class="bg-white">
            <shipping-queue-list
            (openTicketInfo)="openTicket($event)" 
            (finalize)="checkIn($event)" 
            (update)="loadInfo()" 
            [tickets]="incomingTickets" direction="incoming"></shipping-queue-list>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>local_shipping</mat-icon>
            <mat-icon class="mr-1">arrow_forward</mat-icon>
            <span class="tab-title">Outgoing</span>
            <span class="badge bg-white text-primary mx-1" *ngIf="countUrgentTickets(outgoingTickets) > 0">
              {{countUrgentTickets(outgoingTickets)}}
            </span>
            <i class="ml-1 fas fa-circle-notch fa-spin" [class.invisible]="outgoingTickets"></i>
          </ng-template>
          <div class="bg-white">
            <shipping-queue-list (openTicketInfo)="openTicket($event)" (finalize)="checkOut($event)" (update)="loadInfo()" [tickets]="outgoingTickets" direction="outgoing"></shipping-queue-list>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>history</mat-icon>
            <span class="tab-title">History</span>
            <i class="ml-1 fas fa-circle-notch fa-spin" [class.invisible]="historyTickets"></i>
          </ng-template>
          <div class="bg-white">
            <mat-selection-list>
              <ng-container *ngFor="let ticket of historyTickets">
                <mat-list-option matRipple matRippleColor="rgba(0,0,0,.04)" #option class="ticket-line"
                    (click)="openTicket(ticket)">
                    <div class="row">
                        <div class="col-5 col-xl-3">
                            <div mat-line>
                                <b>{{ getTicketNumber(ticket) }}</b>
                            </div>
                            <div mat-line class="text-muted" *ngIf="ticket.destinationBuildingId === (currentBuilding | async).buildingId">
                                <span *ngIf="ticket.originBuilding">
                                    Internal shipment from <b>{{ticket.originBuilding.name}}</b>
                                </span>
                                <span *ngIf="ticket.purchaseOrder && ticket.purchaseOrder.vendor">
                                    Vendor shipment from <b>{{ticket.purchaseOrder.vendor.name}}</b>
                                </span>
                            </div>
                            <div mat-line class="text-muted" *ngIf="ticket.originBuildingId === (currentBuilding | async).buildingId">
                                <span *ngIf="ticket.destinationBuilding">
                                    Internal shipment to <b>{{ticket.destinationBuilding.name}}</b>
                                </span>
                                <span *ngIf="ticket.purchaseOrder && ticket.purchaseOrder.vendor && ticket.inventoryItem.productId">
                                    Shipping part to vendor <b>{{ticket.purchaseOrder.vendor.name}}</b> for processing
                                </span>
                                <span *ngIf="ticket.workOrder && ticket.workOrder.order.customer && ticket.inventoryItem.productId">
                                    Shipping final product to customer <b>{{ticket.workOrder.order.customer.businessName}}</b>
                                </span>
                            </div>
                            <div mat-line>
                                <item-type-chip [iconOnly]="true" [item]="ticket.inventoryItem"></item-type-chip>
                                {{ ticket.inventoryItem.name }}
                            </div>
                        </div>
                        <div class="col-4 col-xl-2">
                            <ng-container *ngIf="ticket.receivedOrSentBy">
                                <div mat-line class="font-weight-bold text-uppercase unassigned-indicator d-flex align-items-center">
                                    <mat-icon class="mr-1 text-muted">assignment_ind</mat-icon>
                                    {{ ticket.destinationBuildingId === (currentBuilding | async).buildingId ? 'Received by' : 'Sent out by' }}
                                </div>
                                <div mat-line class="text-muted small d-flex align-items-center" *ngIf="ticket.receivedOrSentBy">
                                    {{ ticket.receivedOrSentBy.fullName }}
                                </div>
                                <div mat-line class="text-muted small d-flex align-items-center" *ngIf="ticket.destinationBuildingId === (currentBuilding | async).buildingId">
                                  On {{ ticket.arrivalDate | date: 'shortDate' }}
                                </div>
                                <div mat-line class="text-muted small d-flex align-items-center" *ngIf="ticket.originBuildingId === (currentBuilding | async).buildingId">
                                  On {{ ticket.departureDate | date: 'shortDate' }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mat-list-option>
                <mat-divider></mat-divider>
            </ng-container>
            </mat-selection-list>
          </div>
        </mat-tab>
        <mat-tab disabled [labelClass]="'search-tab'">
          <ng-template mat-tab-label>
            <mat-form-field class="w-100 mat-input-no-message dense-field">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput type="text" [formControl]="searchText">
            </mat-form-field>
          </ng-template>
        </mat-tab>
        <mat-tab disabled [labelClass]="userIsManager() ? 'manager-tab' : 'invisible'">
          <ng-template mat-tab-label>
            <span class="manager-toggle-label">
              Just Mine
            </span>
            <mat-slide-toggle class="mx-2" [formControl]="managerView">
            </mat-slide-toggle>
            <span class="manager-toggle-label">
              Manager View
            </span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="paginator border-top px-3 mat-elevation-z3 d-flex justify-content-end">
        <mat-paginator [pageSize]="25" [length]="currentCount" [pageIndex]="pageIndex" (page)="onPage($event)"></mat-paginator>
      </div>
    </shipping-wrapper>
  </mat-sidenav-content>
</mat-sidenav-container>