<mat-form-field appearance="outline" class="w-100 dense-field mat-input-no-message">
    <input placeholder="Search Part Number..." matInput [matAutocomplete]="auto" type="text"
        name="partSearcher" [formControl]="searchControl" (keydown)="$event.stopPropagation()"
        >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
        <mat-option *ngFor="let item of (results | async)" [value]="item">
            <ng-container *ngIf="item.outsideProcessDescription">
                <span class="small muted mr-2">
                    {{item.outsideProcessDescription.outsideProcessDescriptionNumber}}
                </span>
                {{ item.partNumber }} Rev. {{ item.revision }}: {{ getStationString(item.outsideProcessDescription) }}
            </ng-container>
            <ng-container *ngIf="!item.outsideProcessDescription">
                <mat-icon matPrefix svgIcon="plus" class="text-muted"></mat-icon>
                <span class="text-muted"><span
                        [innerHTML]="item.partNumber + ' Rev. ' + item.revision | highlight: searchControl.value"></span>: New Process</span>
            </ng-container>
        </mat-option>
        <mat-option *ngIf="!!searchControl.value" [value]="searchControl.value" class="text-muted">
            <mat-icon matPrefix svgIcon="plus-box"></mat-icon>
            New Part Number <b>"{{searchControl.value}}"</b>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>