import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MessageType } from '../../../common/resources/message';
import { MessageService } from '../../../common/services/message.service';
import { ProductQuantity } from '../../../order/resources/product';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'multiple-quantity-input',
  templateUrl: './multiple-quantity-input.component.html',
  styleUrls: ['./multiple-quantity-input.component.less']
})
export class MultipleQuantityInputComponent implements OnInit, OnChanges {
  @Input() quantities: ProductQuantity[];
  @Output() quantitiesChange: EventEmitter<ProductQuantity[]> = new EventEmitter<ProductQuantity[]>();
  @Input() editable: boolean = false;
  public quantityArray: ProductQuantity[] = [];
  @ViewChild('qtyInput') qtyInput: ElementRef;

  constructor(private messageSvc: MessageService) { }

  addQty(qty: number): void {
    if (!isNaN(qty) && qty != null && <any>qty != "") {
      var newVal = Math.round(qty);

      if (this.quantityArray.find(x => x.value === newVal)) {
        this.messageSvc.add(`Already have quantity '${newVal}'`, MessageType.INFO, true);
        return;
      }

      this.quantityArray.push({
        value: Math.round(newVal),
        markup: UtilityService.defaultMarkup,
        showOnQuote: true,
      });
      this.quantityArray = this.quantityArray.sort((a, b) => a.value - b.value)
      this.qtyInput.nativeElement.value = null;
      this.notifyChanged();
    }
    else {
      this.qtyInput.nativeElement.value = null;
      this.messageSvc.add("Quantity Input Invalid!", MessageType.INFO, true);
    }
  }

  notifyChanged(): void {
    this.quantitiesChange.emit(this.quantityArray);
  }

  removeQty(index: number): void {
    this.quantityArray.splice(index, 1);
    this.notifyChanged();
  }

  ngOnInit(): void {
    if (this.quantities == null) return;
    this.quantityArray = JSON.parse(JSON.stringify(this.quantities));
  }

  ngOnChanges(_: SimpleChanges): void {
    this.ngOnInit();
  }
}
