import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FloorService } from '../../services/floor.service';
import { ChartComponent } from '../../../common/components/chart/chart.component';
import { StationService } from '../../../order/services/station.service';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'machine-today-schedule',
  templateUrl: './machine-today-schedule.component.html',
  styleUrls: ['./machine-today-schedule.component.less']
})
export class MachineTodayScheduleComponent implements OnInit, OnChanges {
  @Input() machineId: string = null;
  @Input() date: Date = new Date();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDragComplete: EventEmitter<Date> = new EventEmitter<Date>();

  public saving: boolean = false;
  private skipClick: boolean = false;

  public additionalData: any[] = [];
  public todayOptions: any;
  public todayPlugins: any[];
  public todayRawData: any[];
  public todayData: any[];
  public todayLabels: string[];

  private dragData: any = {};

  constructor(private floorSvc: FloorService, private stationSvc: StationService, private utilSvc: UtilityService) { }

  public bubbleClick(e: any) {
    if (this.skipClick || this.dragData.mouseData)
      return;

    this.onClick.emit(e);
  }

  public reload(): void {
    this.setupChart();
  }

  private showNow(chart: any): void {
    const xAxisWidth = chart.scales['x-axis-0'].right - chart.scales['x-axis-0'].left;

    var drawLineAt = function (date: Date, color: string) {
      var lineLeftOffset = chart.scales['x-axis-0'].left + ((((date.getHours() * 60) + date.getMinutes()) / 1440) * xAxisWidth);

      chart.ctx.beginPath();
      chart.ctx.strokeStyle = color;
      chart.ctx.moveTo(lineLeftOffset, chart.scales['y-axis-0'].top);
      chart.ctx.lineTo(lineLeftOffset, chart.scales['y-axis-0'].bottom);
      chart.ctx.stroke();
    };

    if (this.isToday(this.date))
      drawLineAt(new Date(), '#2f9a41');

    //todo: lines at shifts??
  }

  private isToday(date: Date): boolean {
    var todayDate: Date = new Date();
    return date.toLocaleDateString() == todayDate.toLocaleDateString();
  }

  private getTodayLabels(): void {
    var labels = (this.todayRawData || []).concat(this.additionalData).map(assignment => assignment.workOrder ? assignment.workOrder.workOrderNumber : "--Unkown--").filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    labels.push("LTA");

    this.todayLabels = labels;
  }

  private getAssignmentIndex(name: string): number {
    return this.todayLabels.indexOf(name);
  }

  private getDataArray(value: number, index: number): number[] {
    var values = new Array(this.todayLabels.length + 1).fill(0);

    values[index] = value;

    return values;
  }

  private mergeData(): any[] {
    var toBeAdded: any[] = JSON.parse(JSON.stringify(this.additionalData));
    if (toBeAdded && toBeAdded.length > 0) {
      var toRemove: number[] = [];
      for (var i in toBeAdded) {
        var overlap = this.todayRawData.findIndex(a => a.assignmentId == toBeAdded[i].assignmentId);
        if (overlap >= 0) {
          this.todayRawData[overlap] = toBeAdded[i];
          toRemove.push(Number(i));
        }
      }

      toRemove = toRemove.sort().reverse();
      for (var i in toRemove) {
        toBeAdded.splice(Number(i), 1);
      }

    }

    return (this.todayRawData || []).concat(toBeAdded);
  }

  private getTodayData(): void {
    var data = [];
    var hasData = [];
    //1440 minutes in a day

    var allData = this.mergeData();

    for (var i in allData) {
      const assignment = allData[i];
      const chartIndex = this.getAssignmentIndex(assignment.workOrder.workOrderNumber);
      var midnight = new Date();
      midnight.setHours(0, 0, 0, 0);

      //transparent member first?
      if (hasData[chartIndex] == null && new Date(assignment.scheduledStart) > midnight) {
        var fill = new Date(assignment.scheduledStart).getHours() * 60 + new Date(assignment.scheduledStart).getMinutes();
        data.push({
          label: '',
          backgroundColor: 'transparent',
          data: this.getDataArray(fill, chartIndex)
        });
      }

      if (!this.stationSvc.stationList) break;

      data.push({
        label: this.stationSvc.stationList.find(s => s.stationId == assignment.operation.stationId).name,
        backgroundColor: assignment.backgroundColor || ChartComponent.getColor(chartIndex + 1),
        borderColor: assignment.borderColor || "#0000002c",
        borderWidth: 1,
        borderSkipped: false,
        data: this.getDataArray(assignment.operation.runTime * (assignment.operation.runIsPerPart ? 1 : 60), chartIndex),
        assignmentId: assignment.assignmentId
      });
      hasData[chartIndex] = true;
    }

    data.push({
      label: "LTA",
      backgroundColor: '#aeaeae',
      data: this.getDataArray(1440, this.todayLabels.length - 1)
    });

    this.todayData = data;
  }

  public refresh(): void {
    this.setupChart();
  }

  private setupChart(): void {
    var self = this;
    this.todayOptions = {
      responsive: true,
      legend: { display: false },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            if (data.datasets[tooltipItem.datasetIndex].label == 'LTA')
              return 'LTA';

            return data.datasets[tooltipItem.datasetIndex].label + ': ' + Number(tooltipItem.xLabel) + ' min';
          }
        }

      },
      hover: {
        onHover: function (e, el) {
          var section = el[0];

          if (self.dragData.mouseData) {
            //we're dragging
            e.currentTarget.style.cursor = 'grab';
            return;
          }

          if (!section) {
            e.currentTarget.style.cursor = 'default';
            return;
          }
          e.currentTarget.style.cursor = (this.data.datasets[this.getElementAtEvent(e)[0]._datasetIndex].backgroundColor != 'transparent') ? 'pointer' : 'default';
        }
      },
      aspectRatio: 4,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 1440,
            stepSize: 120,
            callback: function (v, i, vs) {
              return v / 60 + ":00";
            }
          }
        }],
        yAxes: [{ stacked: true }]
      }
    };

    this.todayPlugins = [{
      afterInit: function (chart, _options) {
        chart.canvas.onmousedown = function (e: MouseEvent): void { self.mouseDown(e, chart); };
        chart.canvas.onmouseup = function (e: MouseEvent): void { self.mouseUp(e, chart); };
        chart.canvas.onmousemove = function (e: MouseEvent): void { self.mouseMove(e, chart); };
        chart.canvas.onmouseleave = function (e: MouseEvent): void { self.mouseLeave(e, chart); };
        chart.canvas.onmouseenter = function (e: MouseEvent): void { ; }; //We won't support dragging data IN TO this chart
      },
      afterDatasetDraw: function (chart, _options) {
        self.showNow(chart);
      },
      beforeDraw: function (chart, _options) {
        var active = chart.tooltip._active || [];

        if (active.length > 0) {
          if (active[0]._model.start == 'transparent' || active[0]._model.backgroundColor == 'rgba(0, 0, 0, 0)') {
            // Opacity of 0 causes the tooltip draw method to do nothing
            chart.tooltip._model.opacity = 0;
          }
        }
      }
    }];

    this.fetchData();
  }

  private fetchData(): void {
    this.floorSvc.getMachineSchedule(this.machineId, this.date).subscribe(data => {
      this.todayRawData = data;

      this.getTodayLabels();
      this.getTodayData();
    });
  }

  private mouseLeave(e: MouseEvent, chart: any): void {
    if (this.dragData.dataPoint) {
      var exportData = {
        source: "MachineTodayScheduleComponent",
        assignmentId: this.dragData.dataPoint.assignmentId,
        backgroundColor: this.dragData.dataPoint.originalBackgroundColor,
        borderColor: this.dragData.dataPoint.borderColor,
        borderWidth: this.dragData.dataPoint.borderWidth,
        borderSkipped: this.dragData.dataPoint.borderSkipped,
        data: this.todayRawData.find(m => m.assignmentId == this.dragData.dataPoint.assignmentId)
      };

      sessionStorage.setItem("IntraChartData", JSON.stringify(exportData));

      this.dragData.dataPoint.backgroundColor = this.dragData.dataPoint.originalBackgroundColor;

      this.dragData = {};
      chart.update();
    }
  }

  private mouseDown(e: MouseEvent, chart: any): void {
    var element = chart.getElementAtEvent(e);
    this.dragData = {};

    if (element.length > 0) {
      this.dragData.dataPoint = this.todayData[element[0]._datasetIndex];
      this.dragData.boxElement = element[0];
      this.dragData.mouseData = {
        x: e.x, y: e.y
      };

      this.dragData.dataPoint.originalBackgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement.originalX = this.dragData.boxElement._view.x;
      this.dragData.boxElement.originalY = this.dragData.boxElement._view.y;
      this.dragData.clickedOn = new Date().valueOf();
    }
  }

  private mouseUp(e: MouseEvent, chart: any): void {
    if (!this.todayData) return;

    if (this.dragData.clickedOn && (new Date().valueOf() - this.dragData.clickedOn) < 100) {
      this.skipClick = false;
    }
    else if (this.dragData.dataPoint) {
      this.dragData.dataPoint.backgroundColor = this.dragData.dataPoint.originalBackgroundColor;

      var minutes: number = ((this.dragData.boxElement._view.x - chart.scales["x-axis-0"].left - (this.dragData.boxElement._view.width / 2)) / chart.scales["x-axis-0"].width) * chart.scales["x-axis-0"].max;
      var newStartDate: Date = new Date(new Date(this.date.toLocaleDateString()).setMinutes(minutes));

      if (newStartDate) {

        if (newStartDate > new Date()) {
          this.onDragComplete.emit(newStartDate);
          this.saving = true;
          this.floorSvc.updateSchedule(this.dragData.dataPoint.assignmentId, newStartDate).subscribe(_ => {
            this.fetchData();
            this.saving = false;
          });
        }
        else {
          this.utilSvc.showAlert("Invalid Time Slot", "<p>Sorry, but you can't schedule an assignment in the past.</p><p class='text-muted'>Please try again.</p>");
        }

      }
    }

    this.dragData = {};

    chart.update();

    setTimeout(_ => this.skipClick = false, 10);
  }

  private mouseMove(e: MouseEvent, chart: any): void {
    if (!this.todayData) return;
    var self = this;
    this.skipClick = true;

    var getNearest = function (x: number, c: any): number {
      //const dayWidth = Math.floor(c.scales['x-axis-0'].width / self.days) - 1;
      //var day = Math.floor(x / dayWidth) - 1;
      //const offset = (self.dragData.boxElement._view.width / 2) + ((dayWidth - self.dragData.boxElement._view.width) / 2);

      //if (!c.scales['x-axis-0']._labelItems[day]) day = 0;

      //self.dragData.dropOnDay = c.scales['x-axis-0']._labelItems[day].label;

      //return c.scales['x-axis-0']._gridLineItems[day].x1 + offset;

      return x;
    }

    if (this.dragData.mouseData) {
      this.dragData.dataPoint.backgroundColor = ChartComponent.shade(0.4, this.dragData.dataPoint.originalBackgroundColor, "#FFFFFF", true) + "66";

      this.dragData.boxElement._view.backgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement._view.x = getNearest(this.dragData.boxElement.originalX + (e.x - this.dragData.mouseData.x), chart);
      this.dragData.boxElement._view.width = Math.abs(this.dragData.boxElement._view.base - this.dragData.boxElement.originalX);
      this.dragData.boxElement._view.borderSkipped = "none";

      this.dragData.boxElement._model.backgroundColor = this.dragData.boxElement._view.backgroundColor;
      this.dragData.boxElement._model.x = this.dragData.boxElement._view.x;
      this.dragData.boxElement._model.width = this.dragData.boxElement._view.width;
      this.dragData.boxElement._model.borderSkipped = "none";

      chart.render({
        duration: 0,
        lazy: false,
        easing: 'easeOutBounce'
      });
    }
  }

  ngOnInit(): void {
    this.setupChart();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupChart();
  }
}
