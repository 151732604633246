<div *ngIf="!results" class="center-spinner">
  <mat-spinner [color]="'warn'"></mat-spinner>
</div>
<search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)" emptySetMessage="-- No Jobs Scheduled --">
  <thead header-template>
    <tr class="text-uppercase text-muted">
      <th>
        Work #
      </th>
      <th>
        Part #
      </th>
      <th>
        Start Date
      </th>
      <th>
        Customer
      </th>
      <th>
        Building
      </th>
      <th>
        Department
      </th>
      <th>
        Machine
      </th>
      <th class="text-center">
        Status
      </th>
      <th>
        Finish Date
      </th>
    </tr>
  </thead>
  <ng-template let-row>
    <td [ngClass]="{'text-danger':!(row.userId)}">{{row.workOrder.workOrderNumber}}</td>
    <td [ngClass]="{'text-danger':!(row.userId)}">{{row.workOrder.product.partNumber}}/{{row.workOrder.product.revision}}</td>
    <td [ngClass]="{'text-danger':!(row.userId)}">{{row.scheduledStart | date: 'MM/dd HH:mm'}}</td>
    <td [ngClass]="{'text-danger':!(row.userId)}">{{row.workOrder.order.customer.businessName}}</td>
    <td [ngClass]="{'text-danger':!(row.userId)}">{{row.department?.name || '(Unassigned)'}}</td>
    <td [ngClass]="{'text-danger':!(row.userId)}">{{getBuildingById(row.machine?.floorBuildingId)?.name || '--'}}</td>
    <td [ngClass]="{'text-danger':!(row.userId)}">
      <span *ngIf="row.machineId; else operationName">{{row.machine?.name}}</span>
      <ng-template #operationName>
        <span>({{getStation(row.operation?.stationId)?.name}})</span>
      </ng-template>
    </td>
    <td class="text-center">
      <span class="status-circ" [title]="getStatusText(row.status)" [ngClass]="getStatusColorClass(row.status)"></span>
    </td>
    <td [ngClass]="{'text-danger':!(row.machineId)}">{{row.scheduledEnd | date: 'MM/dd HH:mm'}}</td>
  </ng-template>
</search-result>
