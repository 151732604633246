import { Component, Input, OnInit } from '@angular/core';
import { ShippingTicket, ShippingTicketStatus } from '../../resources/shipping-ticket';
import { ShippingService } from '../../services/shipping.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilityService } from '../../../common/services/utility.service';
import { NavigationService } from '../../../common/services/navigation.service';

@Component({
  selector: 'shipping-combine-shipments',
  templateUrl: './shipping-combine-shipments.component.html',
  styleUrls: ['./shipping-combine-shipments.component.less']
})
export class ShippingCombineShipmentsComponent implements OnInit {
  @Input() ticketId: string;
  @Input() batchId: string;
  @Input() sidenav: MatSidenav;
  public suggestions: ShippingTicket[] = null;
  public saving: boolean = false;

  constructor(private utilitySvc: UtilityService, private shippingService: ShippingService, navService: NavigationService) {
    navService.pushBreadcrumb("Combine Shipments");
  }

  private getData(): void {
    this.suggestions = null;
    this.shippingService.getBatchSuggestions(this.ticketId).subscribe(results => {
      this.suggestions = results;
      if (!this.batchId) {
        this.batchId = UtilityService.newGuid();
        this.suggestions.find(t => t.shippingTicketId == this.ticketId).shippingBatchId = this.batchId;
      }
    });
  }

  public cancel(): void {

    this.utilitySvc.showConfirmation("Are you Sure?",
      "<p>If you cancel now, no changes will be saved.</p>",
      (r => {
        if (r == true) {
          this.sidenav.close();
        }
      }));
  }

  public selectionChanged(changeEvent: MatCheckboxChange): void {
    var changedItem = this.suggestions.find(t => t.shippingTicketId == changeEvent.source.value);

    if (changeEvent.checked) {
      changedItem.shippingBatchId = this.batchId;
    }
    else {
      changedItem.shippingBatchId = null;
    }
  }

  public save(): void {
    this.saving = true;
    var selections = this.suggestions.filter(t => t.shippingBatchId != null);
    if (selections.length == 1) {
      this.suggestions.find(t => t.shippingTicketId == this.ticketId).shippingBatchId = null;
      selections = [];
    }

    this.shippingService.saveBatch(this.batchId, selections.map(t => t.shippingTicketId)).subscribe(_ => {
      this.saving = false;

      this.sidenav.close();
    });
  }

  public getTicketNumber(ticket: ShippingTicket) {
    return ShippingService.getTicketNumber(ticket);
  }

  public getStatusColorClass(status: ShippingTicketStatus): string {
    return ShippingService.getStatusColorClass(status)
  }

  public getStatusText(status: ShippingTicketStatus) {
    return ShippingService.getStatusText(status)
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  ngOnInit() {
    this.getData();
  }
}
