<div class="header-area">
  <button _ngcontent-c6 disabled class="btn btn-outline-light btn-sm savingBadge" *ngIf="saving$ | async" [@fade]="'in'">
    <i class="fas fa-sync mr-1"></i>
    Saving...
  </button>
  <h5 class="bg-primary text-center m-0 p-2">
    {{ name }}
    {{ adding ? ' - Add Widget' : '' }}
  </h5>
  <button class="btn btn-sm btn-light bg-transparent" title="Add Widget" type="button" id="addWidgetbutton"
  (click)="toggleAdding()"
  >
    <i class="fas fa-plus fa-fw text-light" *ngIf="!adding; else stopAddingIcon"></i>
    <ng-template #stopAddingIcon><i class="fas fa-times fa-fw text-light"></i></ng-template>
  </button>
</div>
<div class="main-area">
  <div *ngIf="loading | async" class="loading-spinner">
    <mat-spinner [@fade]="'in'" [color]="'warn'"></mat-spinner>
  </div>
  <div class="row widget-area p-2" #widgetArea>
    <widget-item
      *ngFor="let widgetData of widgetItems"
      [data]="widgetData"
      class="border-secondary border"
      [appResizable]="{
        minWidth: widgetById(widgetData.widget).minWidth,
        minHeight: widgetById(widgetData.widget).minHeight
      }"
      (resizeDone)="onResize($event.target, $event.newDimensions, widgetData)"
      (remove)="remove(widgetData.widget, $event)"
      #widgetItem
    >
    </widget-item>
  </div>
  <div class="no-widgets text-muted" *ngIf="widgetItems.length === 0">
    No widgets selected; add them with the <i class="fas fa-plus fa-fw"></i> button above.
  </div>
  <div class="widget-selection-area row mx-0 flex-wrap align-items-start p-2" *ngIf="adding" [@fade]="'in'">
    <div class="widget-selection__outer col-sm-12 col-md-6 col-lg-3 col-xl-2" *ngFor="let widget of availableWidgets | async">
      <a href="javascript:void(0)" (click)="addWidget(widget)" matRipple matRippleColor="rgba(255,255,255,0.15)" class="widget-selection bg-primary text-light text-center" style="height: 150px;">
        <i class="fas fa-plus fa-fw"></i> {{ widget.name }}
      </a>
    </div>
    <div class="col text-center text-muted font-italic pt-4 no-more-widgets">
      No more widgets to add!
    </div>
  </div>
</div>
