import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MicroTicket, MicroTicketStatus } from '../../resources/microticket';
import { MicroTicketService } from '../../services/microticket.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { User } from '@sentry/angular-ivy';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'microticket-subitem-viewer',
  templateUrl: './microticket-subitem-viewer.component.html',
  styleUrls: ['./microticket-subitem-viewer.component.less']
})
export class MicroticketSubitemViewerComponent implements OnInit, OnChanges {
  @Input() relatedTicketId: string;
  @Input() relatedTicketName: string;
  @Input() relatedTicketType: string;
  @Input() filterKey: string;
  @Input() subItem: string;

  @Input() microTickets: Subject<MicroTicket[]>;
  public filteredMicroTickets$: Observable<MicroTicket[]>;
  
  @Output() loadingChange = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog, private service: MicroTicketService, private userService: UserService, private utilityService: UtilityService) { }

  private setUpObservable() {
    this.filteredMicroTickets$ = this.microTickets.pipe(
      map(mts => mts
        .filter(t => t.relatedSubItem === this.subItem)
        .sort((a, b) => {
          const aSortValue = a.status === MicroTicketStatus.CLOSED || a.status === MicroTicketStatus.COMPLETE ? Infinity : (new Date(a.dueDate).getDate());
          const bSortValue = b.status === MicroTicketStatus.CLOSED || b.status === MicroTicketStatus.COMPLETE ? Infinity : (new Date(b.dueDate).getDate());
          return aSortValue - bSortValue;
        })
      )
    )
  }

  ngOnInit(): void {
    // this.setUpObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subItem) this.setUpObservable();
  }

  newDialogRef: MatDialogRef<any, any>;
  @ViewChild('newMicroticketDialogTemplate') newMicroticketDialogTemplate: TemplateRef<any>;
  public create() {
    this.newDialogRef = this.dialog.open(this.newMicroticketDialogTemplate, {
      disableClose: true,
      minWidth: '50vw'
    })
  }

  public async onSave(data: MicroTicket) {
    this.newDialogRef.close();
    this.loadingChange.emit(true);
    await this.service.createTicket({
      microTicketId: UtilityService.emptyGuid,
      relatedTicketId: this.relatedTicketId,
      relatedTicketName: this.relatedTicketName,
      relatedTicketType: this.relatedTicketType,
      filterKey: this.filterKey,
      status: MicroTicketStatus.OPEN,
      creatorId: this.userService.userData.userId,
      createdDate: new Date(),
      ...data,
      relatedSubItem: this.subItem
    }).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }

  public onCancel() {
    this.newDialogRef.close();
  }

  public getStatusText(status: MicroTicketStatus) {
    return MicroTicketService.getStatusText(status);
  }

  public getStatusColorClass(status: MicroTicketStatus) {
    return MicroTicketService.getStatusColorClass(status);
  }
  
  public isOwn(ticket: MicroTicket) {
    return this.userService.userData?.userId === ticket?.creatorId;
  }

  public isAssignedToMe(ticket: MicroTicket) {
    return this.userService.userData?.userId === ticket?.assignedUserId;
  }

  getUserName(user: User) {
    if (!user) return 'Unknown User';
    if (this.userService.userData?.userId === user.userId) return 'you';
    else return user.fullName;
  }

  private updateFromServer() {
    this.service.getTickets(this.relatedTicketId, this.filterKey ?? null).pipe(take(1)).subscribe(n => this.microTickets.next(n));
  }

  public async close(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "Closing a ticket cannot be undone.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.closeTicket(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }
  public async markInProgress(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "The ticket will be marked as in progress.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.markTicketInProgress(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }
  public async markComplete(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "The ticket will be marked as completed.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.markTicketComplete(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }

  public expansionModel = new SelectionModel<string>(false);
  


}
