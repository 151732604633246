import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap, concatMap, map } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service';
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { MaterialBid, BulkBid } from '../../purchasing/resources/materialBid';
import { Order } from '../../order/resources/order';
import { MessageType } from '../../common/resources/message';
import { Vendor } from '../../supplier/resources/vendor';
import { PseudoPurchasedItemPartNumber, PurchaseOrder, PurchaseOrderLineItem } from '../resources/purchaseOrder';
import { CounterService } from '../../common/services/counter.service';
import { UtilityService } from '../../common/services/utility.service';
import { User } from '../../common/resources/user';
import { GlobalReq } from '../resources/globalReqsReoprt';
import { WorkOrder } from '../../planning/resources/work-order';
import { Requisition, RequisitionLineItem, RequisitionOrderInfo } from '../resources/requisition';
import { ShippingTicket } from '../../shipping/resources/shipping-ticket';
import { SupplierData } from '../resources/supplierData';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { PurchasingSheetItem } from '../resources/purchasingSheet';
import { ItemType } from '../../inventory/components/item-type-chip/item-type-chip.component';
import { OutsideProcessDescription } from '../resources/outsideProcessDescription';
import { PurchasedItem } from '../../order/resources/purchased-item';
import { Product } from '../../order/resources/product';


export enum DueTime {
  UNKNOWN = "unknown",
  OVERDUE = "overdue",
  THREE_DAYS = "3_days",
  SEVEN_DAYS = "7_days",
  FOURTEEN_DAYS = "14_days",
  LATER = "later",
}
export const dueTimeOrder: DueTime[] = [DueTime.OVERDUE, DueTime.THREE_DAYS, DueTime.SEVEN_DAYS, DueTime.FOURTEEN_DAYS, DueTime.UNKNOWN, DueTime.LATER]

export interface OutsideProcessByProduct {
  productId: string,
  product: Product,
  partNumber: string,
  revision: string,
  outsideProcessDescriptionId?: string,
  outsideProcessDescription?: OutsideProcessDescription
}

@Injectable({
  providedIn: 'root',
})
export class PurchasingService extends httpService {
  private apiBase: string = 'api/ordersegment';
  private apiControllerPath = 'api/Purchasing';
  private apiUrl: string;
  private controllerUrl: string;

  constructor(private counterService: CounterService, errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Suppliers";

    this.apiUrl = this.baseUrl + this.apiBase;
    this.controllerUrl = this.baseUrl + this.apiControllerPath;
  }

  public getUnassignedBidsForVendor(vendorId: string, purchaseOrderId: string): Observable<MaterialBid[]> {
    return this.http.get<MaterialBid[]>(this.apiControllerPath + '/getUnassignedBidsForVendor/' + vendorId + '?poId=' + purchaseOrderId).pipe(
      tap(_ => this.messages.add("Purchasing Service: Get Open Bids", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Open Bids", null))
    );
  }

  public getLineItemData(poId: string): Observable<PurchaseOrderLineItem[]> {
    return this.http.get<PurchaseOrderLineItem[]>(this.baseUrl + 'api/purchasing/getLineItems/' + poId).pipe(
      tap(_ => this.messages.add("Purchasing Service: Get PO Line Item Detail", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get PO Line Item Detail", null))
    );
  }

  public updateLineItems(items: PurchaseOrderLineItem[], purchaseOrder: PurchaseOrder): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/Purchasing/updateLineItems/', {
      purchaseOrder,
      items
    }).pipe(
      tap(_ => this.messages.add("Purchasing Service: Line Items Updated Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Update Line Items", items))
    );
  }

  public addLineItem(item: PurchaseOrderLineItem): Observable<PurchaseOrderLineItem> {
    item.product = null;
    item.station = null;
    item.purchasedItem = null;
    item.material = null;

    if (item.purchaseOrderLineItemId == UtilityService.emptyGuid) {
      return this.http.post<PurchaseOrderLineItem>(this.baseUrl + 'api/PurchaseOrderLineItem/new', item).pipe(
        tap(_ => this.messages.add("Purchasing Service: PO Line Item Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<PurchaseOrderLineItem>("Save New PO Line Item", item))
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<PurchaseOrderLineItem>(this.baseUrl + 'api/PurchaseOrderLineItem', item).pipe(
        tap(_ => this.messages.add("Purchasing Service: PO Line Item Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<PurchaseOrderLineItem>("Update PO Line Item", item))
      );
    }
  }

  public get(vendorId: string): Observable<Vendor> {
    return this.http.get<Vendor>(this.baseUrl + 'api/Vendor/' + vendorId).pipe(
      tap(_ => this.messages.add("Purchasing Service: Get Detail", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Vendor Detail", null))
    );
  }

  public promoteBulkBidToPO(bulkBid: BulkBid): Observable<PurchaseOrder> {
    return this.http.get<Vendor>(this.baseUrl + 'api/Purchasing/promoteBulkBidToPO/' + bulkBid.bulkBidId).pipe(
      tap(_ => this.messages.add("Purchasing Service: Create PO From Bulk Bid", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Vendor Detail", null))
    );
  }

  public search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<Vendor>> {
    return this.http.get<SearchResult<Vendor>>(this.baseUrl + 'api/Vendor/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Vendor Search Results", null))
    );
  }

  public searchBulkBids(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<BulkBid>> {
    return this.http.get<SearchResult<BulkBid>>(this.baseUrl + 'api/BulkBid/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Bulk Bid Search Results", null))
    );
  }

  public searchOrdersAndReqs(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<SupplierData>> {
    return this.http.get<SearchResult<SupplierData>>(this.apiUrl + '/suppliersearch', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Vendor Search Results", null))
    );
  }

  public searchPurchaseOrders(managerView?: boolean, searchString?: string, page?: number, pageSize?: number, sortBy?: string, sortDirection?: string, fieldSearches?: {
    field: string,
    string: string
  }[]): Observable<SearchResult<PurchaseOrder>> {
    const params = new URLSearchParams({ forAllUsers: (!!managerView).toString(), searchText: searchString, pageIndex: (page || 0).toString(), pageSize: (pageSize ?? 10).toString(), orderBy: sortBy || "purchaseOrderNumber", direction: sortDirection || "desc" });
    if (fieldSearches) {
      for (const fs of fieldSearches) {
        params.append(`fieldSearches[${fs.field}]`, fs.string)
      }
    }
    return this.http.get<SearchResult<PurchaseOrder>>(this.baseUrl + 'api/Purchasing/search?' + params.toString()).pipe(
      catchError(this.handleError<any>("Get Purchase Order Search Results", null))
    );
  }

  public getPurchaseOrderDetail(id: string): Observable<PurchaseOrder> {
    return this.http.get<PurchaseOrder>(this.baseUrl + 'api/PurchaseOrder/' + id).pipe(
      catchError(this.handleError<any>("Get Purchase Order Detail", null))
    );
  }

  public addBidsToPurchaseOrder(purchaseOrderId: string, bidIds: string[]): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/Purchasing/addBids/' + purchaseOrderId, bidIds).pipe(
      tap(_ => this.messages.add("Purchasing Service: Bids Attached Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Bids to PO", bidIds))
    );
  }

  public addBidsToPurchaseOrderWithoutLineItems(purchaseOrderId: string, bidIds: string[]): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/Purchasing/addBidsWithoutLineItems/' + purchaseOrderId, bidIds).pipe(
      tap(_ => this.messages.add("Purchasing Service: Bids Attached Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Bids to PO", bidIds))
    );
  }

  public removeBidsFromPurchaseOrder(purchaseOrderId: string, bidIds: string[]): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/Purchasing/removeBids/' + purchaseOrderId, bidIds).pipe(
      tap(_ => this.messages.add("Purchasing Service: Bids Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Bids from PO", bidIds))
    );
  }

  public removePurchaseOrderLineItem(id: string): Observable<any> {
    return this.http.delete<PurchaseOrderLineItem>(this.baseUrl + 'api/PurchaseOrderLineItem/' + id).pipe(
      tap(_ => this.messages.add("Purchasing Service: Line Item Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchaseOrderLineItem>("Remove Line Item", null))
    );
  }

  public savePurchaseOrder(item: PurchaseOrder, deletedLineItems: PurchaseOrderLineItem[], isReady = false): Observable<PurchaseOrder> {
    return this.http.post<PurchaseOrder>(this.baseUrl + 'api/purchasing/savePurchaseOrder', {
      purchaseOrder: item,
      deletedLineItems,
      isReady
    }).pipe(
      tap(_ => this.messages.add("Purchasing Service: PO Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchaseOrder>("Update PO", item))
    );
  }

  public voidPurchaseOrder(id: string, reason: string): Observable<any> {
    return this.http.post<any>(this.controllerUrl + '/void/' + id, JSON.stringify(reason), { headers: { 'Content-Type': 'application/json' } }).pipe(
      catchError(this.handleError<any>("Void Purchase Order", null))
    );
  }

  public getQuotesForOrder(orderOrReqId: string): Observable<MaterialBid[]> {
    return this.http.get<MaterialBid[]>(this.baseUrl + 'api/MaterialBid/getQuotesForOrder/' + orderOrReqId).pipe(
      tap(_ => this.messages.add("Vendor Service: Fetched Quotes", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Vendor Quotes for Order", null))
    );
  }

  public getPurchaseOrdersForOrder(orderOrReqId: string): Observable<PurchaseOrder[]> {
    return this.http.get<PurchaseOrder[]>(this.baseUrl + 'api/MaterialBid/getPOsForOrder/' + orderOrReqId).pipe(
      tap(_ => this.messages.add("Vendor Service: Fetched Quotes", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Vendor Quotes for Order", null))
    );
  }

  public getApprovedQuotesByVendor(orderOrReqId: string): Observable<{
    vendor: Vendor,
    materialBids: MaterialBid[]
  }[]> {
    return this.http.get<any>(this.baseUrl + 'api/MaterialBid/getApprovedQuotesForOrder/' + orderOrReqId).pipe(
      tap(_ => this.messages.add("Vendor Service: Fetched Quotes", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Get Approved Quotes for Order", null))
    );
  }

  public createVendorPOFromBids(vendor: Vendor, bidIds: string[]) {
    return this.http.post<PurchaseOrder>(this.baseUrl + 'api/purchasing/createVendorPOFromBids/' + vendor.vendorId, bidIds).pipe(
      tap(_ => this.messages.add("Purchasing Service: Purchase Order Created", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchaseOrder>("Create Purchase Order", null))
    );
  }

  public approvePO(item: PurchaseOrder): Observable<PurchaseOrder> {
    return this.http.get<PurchaseOrder>(this.baseUrl + 'api/purchasing/approve/' + item.purchaseOrderId).pipe(
      tap(_ => this.messages.add("Purchasing Service: Purchase Order Approved", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchaseOrder>("Approve Purchase Order", item))
    );
  }

  public rejectPO(item: PurchaseOrder, note: string): Observable<PurchaseOrder> {
    return this.http.post<PurchaseOrder>(this.baseUrl + 'api/purchasing/reject/' + item.purchaseOrderId, note).pipe(
      tap(_ => this.messages.add("Purchasing Service: Purchase Order Rejected", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchaseOrder>("Reject Purchase Order", item))
    );
  }

  public unreceivePO(item: PurchaseOrder): Observable<PurchaseOrder> {
    return this.http.post<PurchaseOrder>(this.baseUrl + 'api/purchasing/unreceivePurchaseOrder/' + item.purchaseOrderId, null).pipe(
      tap(_ => this.messages.add("Purchasing Service: Purchase Order Unreceived", MessageType.SUCCESS, true)),
      catchError(this.handleError<PurchaseOrder>("Unreceive Purchase Order", item))
    );
  }

  public assign(poId: string, toUser: User): Observable<void> {
    return this.http.get<void>(this.baseUrl + `api/purchasing/assign/${poId}?userId=${toUser.userId}`).pipe(
      tap(_ => this.messages.add("Purchasing Service: Assign Purchase Order", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Assign Purchase Order", null))
    );
  }

  public globalReqs(): Observable<GlobalReq[]> {
    return this.http.get<GlobalReq[]>(this.baseUrl + `api/purchasing/requisitions`).pipe(
      tap(_ => this.messages.add("Purchasing Service: Get Global Reqs", MessageType.SUCCESS, true)),
      catchError(this.handleError<GlobalReq[]>("Get Global Reqs", null))
    );
  }

  public searchUnassignedPurchaseOrders(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<PurchaseOrder>> {
    return this.http.get<SearchResult<PurchaseOrder>>(this.baseUrl + 'api/Purchasing/unassociated', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Unassigned Purchase Order Search Results", null))
    );
  }

  public getAssignableWOsforPO(poId: string): Observable<{
    bid: MaterialBid,
    availableQty: number,
    assignableWOs: {
      wo: WorkOrder,
      unfulfilled: number
    }[]
  }[]> {
    return this.http.get<any>(this.baseUrl + 'api/Purchasing/getAssignableWOs/' + poId).pipe(
      catchError(this.handleError<any>("Get Unassigned Purchase Order Detail", null))
    );
  }

  public assignWOsToPO(poId: string, data: {
    materialBidId: string,
    workOrders: {
      workOrderId: string,
      assignedAmount: number
    }[]
  }[]): Observable<void> {
    return this.http.post<void>(this.baseUrl + 'api/Purchasing/assignWOsToLineItems/' + poId, data).pipe(
      catchError(this.handleError<any>("Assign Work Orders to Purchase Order", null))
    );
  }

  public getTotalProductQty(productId: string): Observable<number> {
    return this.http.get<number>(this.baseUrl + 'api/Purchasing/getTotalProductQty/' + productId).pipe(
      catchError(this.handleError<any>("Get Total Product Quantity", null))
    );
  }
  public getRequisitionDetail(id: string): Observable<Requisition> {
    return this.http.get<Requisition>(this.baseUrl + 'api/Requisition/' + id).pipe(
      catchError(this.handleError<any>("Get Requisition Detail", null))
    );
  }

  public saveRequisition(item: Requisition) {
    item.createdBy = null;
    item.assignedUser = null;
    item.requisitionLineItems = null;

    if (item.requisitionId == UtilityService.emptyGuid) {
      //New Item
      //Needs a number
      return this.counterService.next('REQ').pipe(
        concatMap(nextPrefix => {
          item.requisitionNumber = nextPrefix;

          return this.http.post<Requisition>(this.baseUrl + 'api/Requisition/new', item).pipe(
            tap(_ => this.messages.add("Requisition Service: Requisition Saved Successfully", MessageType.SUCCESS, true)),
            catchError(this.handleError<Requisition>("Save New Requisition", item))
          );
        })
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<Requisition>(this.baseUrl + 'api/Requisition', item).pipe(
        tap(_ => this.messages.add("Requisition Service: Requisition Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Requisition>("Update Requisition", item))
      );
    }
  }

  public saveRequisitionLineItem(item: RequisitionLineItem) {
    item.purchasedItemId = item.purchasedItem && item.purchasedItem.purchasedItemId;
    item.purchasedItem = null;
    item.materialId = item.material && item.material.materialId;
    item.material = null;
    item.stationId = item.station && item.station.stationId;
    item.station = null;

    if (item.requisitionLineItemId == UtilityService.emptyGuid) {
      return this.http.post<RequisitionLineItem>(this.baseUrl + 'api/RequisitionLineItem/new', item).pipe(
        catchError(this.handleError<RequisitionLineItem>("Save New Requisition Item", item))
      );
    }
    else {
      //Existing Item - we can just do a simple update here
      return this.http.post<RequisitionLineItem>(this.baseUrl + 'api/RequisitionLineItem', item).pipe(
        catchError(this.handleError<RequisitionLineItem>("Update Requisition Item", item))
      );
    }
  }

  public deleteRequisitionLineItem(id: string) {
    return this.http.delete<RequisitionLineItem>(this.baseUrl + 'api/RequisitionLineItem/' + id).pipe(
      tap(_ => this.messages.add("Requisition Service: Item Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<RequisitionLineItem>("Remove Line Item", null))
    );
  }

  public assignRequisition(reqId: string, toUser: User): Observable<void> {
    return this.http.get<void>(this.baseUrl + `api/purchasing/assignRequisition/${reqId}?userId=${toUser.userId}`).pipe(
      tap(_ => this.messages.add("Purchasing Service: Assign Requisition", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Assign Requisition", null))
    );
  }

  public searchRequisitions(managerView: boolean, searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<Requisition>> {
    return this.http.get<SearchResult<Requisition>>(this.baseUrl + 'api/purchasing/requisitionSearch', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc", managerView: (managerView || false).toString() } }).pipe(
      catchError(this.handleError<any>("Get Requisition Search Results", null))
    );
  }

  public fulfillRequisitionItem(item: RequisitionLineItem, amount: number): Observable<void> {
    return this.http.post<void>(this.baseUrl + `api/purchasing/fulfillRequisitionItem/${item.requisitionLineItemId}`, amount).pipe(
      tap(_ => this.messages.add("Purchasing Service: Fulfill Requisition Item", MessageType.SUCCESS, true)),
      catchError(this.handleError<void>("Fulfill Requisition Item", null))
    );
  }

  public getPOsForRequisition(requisition: Requisition): Observable<RequisitionOrderInfo[]> {
    return this.http.get<RequisitionOrderInfo[]>(this.baseUrl + 'api/purchasing/getPOsForRequisition/' + requisition.requisitionId).pipe(
      catchError(this.handleError<any>("Get Requisition Search Results", null))
    );
  }

  public getPOShippingTickets(po: PurchaseOrder): Observable<ShippingTicket[]> {
    return this.http.get<ShippingTicket[]>(this.baseUrl + 'api/purchasing/getPOShippingTickets/' + po.purchaseOrderId).pipe(
      catchError(this.handleError<any>("Get PO Shipping Tickets", null))
    );
  }

  public getPOReport(item: PurchaseOrder): Observable<VirtualDocument> {
    return this.http.get<Document>(this.baseUrl + 'api/purchasing/report/' + item.purchaseOrderId).pipe(
      catchError(this.handleError<any>("Get Purchase Order Report", null))
    );
  }

  public getOSPLabel(item: PurchaseOrderLineItem): Observable<VirtualDocument> {
    return this.http.get<Document>(this.baseUrl + 'api/purchasing/ospLabel/' + item.purchaseOrderLineItemId).pipe(
      catchError(this.handleError<any>("Get OSP Label", null))
    );
  }

  public searchPurchasedItemsByPartNumber(searchText: string, pageIndex: number, pageSize: number): Observable<SearchResult<PseudoPurchasedItemPartNumber>> {
    return this.http.get<any>(this.baseUrl + 'api/purchasing/searchPurchasedItemsByPartNumber/', { params: { searchText, pageIndex: pageIndex.toString(), pageSize: pageSize.toString() } }).pipe(
      catchError(this.handleError<any>("Get Purchase Order Report", null))
    );
  }

  public searchUniquePurchasedItemsByPartNumber(searchText: string, pageIndex: number, pageSize: number): Observable<SearchResult<PurchasedItem>> {
    return this.http.get<any>(this.baseUrl + 'api/purchasing/searchUniquePurchasedItemsByPartNumber/', { params: { searchText, pageIndex: pageIndex.toString(), pageSize: pageSize.toString() } }).pipe(
      catchError(this.handleError<any>("Get Purchase Order Report", null))
    );
  }

  public quickAddPurchasedItem(data: { name: string, partNumber: string }, vendor: Vendor): Observable<PseudoPurchasedItemPartNumber> {
    return this.http.post<PseudoPurchasedItemPartNumber>(this.baseUrl + `api/purchasing/quickAddPurchasedItem`, { ...data, vendorId: vendor.vendorId }).pipe(
      tap(_ => this.messages.add("Purchasing Service: Create Purchased Item", MessageType.SUCCESS, true)),
      catchError(this.handleError<PseudoPurchasedItemPartNumber>("Create Purchased Item", null))
    );
  }

  public listPurchasingSheetItems(groupBy: string, searchText: string = '', itemFilters: ItemType[] = [], validityFilters: number[] = []): Observable<{ dueTime?: DueTime, workOrder?: WorkOrder, mainItem?: PurchasingSheetItem, mainItemId?: string, quantity: number, items: PurchasingSheetItem[] }[]> {
    const params = new URLSearchParams({ groupBy, searchText });
    for (const type of itemFilters) {
      params.append('itemTypes', type);
    }
    for (const validity of validityFilters) {
      params.append('validities', validity.toString());
    }
    return this.http.get<any>(this.baseUrl + 'api/purchasing/listPurchasingSheetItems?' + params.toString()).pipe(
      catchError(this.handleError<any>("Get Purchasing Prerequisite Report", null))
    );
  }

  public getPurchasedItemCategories() {
    return this.http.get<any>(this.baseUrl + 'api/PurchasedItemCategory/list/').pipe(
      catchError(this.handleError<any>("Get Purchased Item Categories", null))
    );
  }

  public getPaymentTerms(): Observable<string[]> {
    return this.http.get<any>(this.baseUrl + 'api/Purchasing/getPaymentTerms/').pipe(
      catchError(this.handleError<any>("Get Payment Terms", null))
    );
  }

  public getFreightTerms(): Observable<string[]> {
    return this.http.get<any>(this.baseUrl + 'api/Purchasing/getFreightTerms/').pipe(
      catchError(this.handleError<any>("Get Freight Terms", null))
    );
  }

  public getShippingMethods(): Observable<string[]> {
    return this.http.get<any>(this.baseUrl + 'api/Purchasing/getShippingMethods/').pipe(
      catchError(this.handleError<any>("Get Shipping Methods", null))
    );
  }

  public searchOutsideProcesses(searchText: string): Observable<OutsideProcessDescription[]> {
    return this.http.get<any>(this.baseUrl + 'api/purchasing/searchOutsideProcesses/', { params: { searchText } }).pipe(
          catchError(this.handleError<any>("Search Outside Processes", null))
    );
  }

  public searchOutsideProcessesByProduct(searchText: string): Observable<OutsideProcessByProduct[]> {
    return this.http.get<any>(this.baseUrl + 'api/purchasing/searchOutsideProcessesByProduct/', { params: { searchText } }).pipe(
          catchError(this.handleError<any>("Search Outside Processes", null))
    );
  }

  public saveOutsideProcessDescription(item: OutsideProcessDescription): Observable<OutsideProcessDescription> {
    return this.http.post<OutsideProcessDescription>(this.baseUrl + 'api/purchasing/saveOutsideProcessDescription', item).pipe(
      tap(_ => this.messages.add("Purchasing Service: OP Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<OutsideProcessDescription>("Update OP", item))
    );
  }

  public deleteOutsideProcessDescription(item: OutsideProcessDescription): Observable<void> {
    return this.http.delete<any>(this.baseUrl + 'api/OutsideProcessDescription/' + item.outsideProcessDescriptionId).pipe(
      tap(_ => this.messages.add("Purchasing Service: OP Saved Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<OutsideProcessDescription>("Update OP", item))
    );
  }

  public getPricingDataForItem(itemId: string): Observable<PurchaseOrderLineItem[]> {
    return this.http.get<any>(this.baseUrl + 'api/purchasing/getPricingDataForItem/' + itemId).pipe(
          catchError(this.handleError<any>("Search Pricing History", null))
    );
  }
}
