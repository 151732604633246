<mat-card class="mat-elevation-z3 pt-2">
    <div class="d-flex w-100 align-items-center mb-2">
        <mat-icon svgIcon="note" class="mr-2"></mat-icon>
        <div class="top-line">Written by <b>{{ note.author?.fullName ?? 'Unknown User' }}</b> on <b>{{ note.date | date }}</b> at <b>{{ note.date | date: "hh:mm a" }}</b></div>
        <div class="d-flex align-items-center ml-2" *ngIf="isOwn()">
            <button mat-icon-button (click)="delete.emit()">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="edit.emit()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="tags ml-auto">
            <mat-chip class="ml-1" *ngFor="let tag of note.tags">{{ tag }}</mat-chip>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="note-content mt-4" [innerHTML]="note.content"></div>
</mat-card>