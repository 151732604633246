import { EmployeeShiftAssignment } from "./employee-shift-assignment";
import { Time } from "@angular/common";



export class Shift {
  shiftId: number;
  name: string;
  color: string;
  startTime?: Time;
  endTime?: Time;

  employeeShiftAssignments: EmployeeShiftAssignment[];

}
