<div>
    <a class="mr-6 position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Cancel" (click)="cancelEditing()"><i class="fas fa-times"></i></a>
    <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save" (click)="saveChanges()"><i class="fas fa-save"></i></a>

    <loading *ngIf="!record"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <div class="p-4 container-fluid" *ngIf="record">
        <div class="pb-4 row">
            <div class="col">
                <h2 class="text-primary">Carrier Details</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Carrier Name</label>
                    <input [(ngModel)]="record.name" placeholder="Name" required min="3" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>
                        Tracking URL
                        <i class="pl-4 cursor-pointer fas fa-lg fa-question-circle text-info" matTooltipClass="allow-cr" matTooltip="You can set the URL the system uses for tracking here.&#13;&#13;This is generally found by looking up a tracking number from the web, and copy/pasting the URL, and leaving out the actual tracking number you looked for."></i>
                    </label>
                    <input [(ngModel)]="record.trackingProvider" placeholder="Tracking URL" type="text" max="100" class="form-control form-control-sm" appStaticEdit [editable]="true" />
                    <div *ngIf="record.trackingProvider?.length > 0" class="text-muted small font-italic">
                        <span>eg. '{{record.trackingProvider}}[Tracking Number]'</span>
                        -
                        <button class="btn btn-link btn-sm" (click)="testTrackingProvider()">Click Here to Test</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
                <label>Account Number</label>
                <input [(ngModel)]="record.accountNumber" placeholder="Account Number" type="text" class="form-control form-control-sm" appStaticEdit [editable]="true" />
            </div>
          </div>
        </div>
    </div>
</div>
