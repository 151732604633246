import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FloorMachine } from '../../resources/building';
import { NavigationService } from '../../../common/services/navigation.service';
import { FloorService } from '../../services/floor.service';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { MachineTodayScheduleComponent } from '../../charts/machine-today-schedule/machine-today-schedule.component';
import { MachineFutureScheduleComponent } from '../../charts/machine-future-schedule/machine-future-schedule.component';

@Component({
  selector: 'machine-detail',
  templateUrl: './machine-detail.component.html',
  styleUrls: ['./machine-detail.component.less']
})
export class MachineDetailComponent implements OnInit {
  @Input() machineId: string = null;
  @Input() sidenav: MatSidenav;
  @Input() date: Date = new Date();
  @ViewChild('insetNav') insetNav: MatSidenav;
  @ViewChild('todayChart') todayChart: MachineTodayScheduleComponent;
  @ViewChild('futureChart') futureChart: MachineFutureScheduleComponent;

  private firstLoad: boolean = true;
  private originalStatus: number = null;
  public record: FloorMachine = null;
  public editing: boolean = false;
  public loading: boolean = false;
  public saving: boolean = false;
  public selectedAssignment: MachineAssignment;
  public showEditor: string = null;

  constructor(private navService: NavigationService, private floorSvc: FloorService) { }

  public close(): void {
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  public getData(): void {
    this.record = null;
    this.floorSvc.getMachine(this.machineId).subscribe(m => {
      this.record = m;
      this.originalStatus = m.status;
      if (this.firstLoad) this.navService.pushBreadcrumb(this.record.name + ' (Detail)');
      this.firstLoad = false;
    });
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
  }

  public cancelEditing(): void {
    //TODO: checks here?
    this.toggleEditing();
    this.getData();
  }

  public saveSetup(): void {
    this.saving = true;
    if (this.originalStatus != this.record.status) {
      this.floorSvc.sendMachineStatusUpdateNotifications(this.record).subscribe(_ => { /*empty*/ });
    }
    this.floorSvc.saveMachine(this.record).subscribe(r => {
      this.machineId = r.machineId;
      this.saving = false;
      this.getData();
    });
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getData();

    this.todayChart.reload();
    this.futureChart.reload();
  }

  public setAssignment(event: any): void {
    if (event.clickedElement && event.clickedElement.length > 0) {

      if (this.todayChart.todayData[event.clickedElement[0]._datasetIndex].label == 'LTA') return;

      this.loading = true;
      this.floorSvc.getAssignment(this.todayChart.todayData[event.clickedElement[0]._datasetIndex].assignmentId).subscribe(assignment => {
        this.selectedAssignment = assignment;
        this.showEditor = 'assignment';
        this.insetNav.open();
        this.loading = false;
      });

    }
  }

  public switchDate(event: any): void {
    if (event.clickedElement && event.clickedElement.length > 0) {
      if (event.clickedElement[0].day)
        this.date = new Date(event.clickedElement[0].day);
      else
        this.date = new Date(this.futureChart.futureLabels[event.clickedElement[0]._index]);
    }
  }

  public getDate(): Date {
    return new Date();
  }

  public returnToToday(): void {
    this.date = this.getDate();
  }

  public isToday(date: Date): boolean {
    return date.toLocaleDateString() == this.getDate().toLocaleDateString();
  }

  public getStatusText(): string {
    return FloorMachine.getStatusText(this.record.status);
  }

  ngOnInit(): void {
    this.getData();
  }
}
