import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderDetailService } from '../../order-detail.service';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, startWith } from 'rxjs/operators';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'product-detail-form',
  templateUrl: './product-detail-form.component.html',
  styleUrls: ['./product-detail-form.component.less']
})
export class ProductDetailFormComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService,
    private orderService: OrderService
  ) { }

  public get record() { return this.service.order }
  public get product() { return this.service.selectedProduct }
  public get editing() { return this.service.editing }

  public leadTime$: Observable<number>;

  ngOnInit(): void {
    this.leadTime$ = this.service.productChange.pipe(
      startWith(this.product),
      filter(prod => !!prod),
      mergeMap((prod) => this.service.getProductLeadTimeObservable(prod.productId)),
    );
  }
  
  @ViewChild('form') form: NgForm;
  private formSubscription: Subscription;
  ngAfterViewInit() {
    this.formSubscription = this.form?.valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.service.notifyEdited();
    });
  }

  ngOnDestroy(): void {
    this.formSubscription && this.formSubscription.unsubscribe();
  }

  public async makePartCanonical() {
    if (this.editing) return;
    this.service.loading = true;
    this.product.productStandard = await this.orderService.makeProductCanonical(this.product).toPromise();
    this.service.loading = false;
  }


}
