import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrderDetailService } from '../order-detail.service';
import { Product } from '../../../resources/product';

@Component({
  selector: 'order-detail-estimating-screen',
  templateUrl: './order-detail-estimating-screen.component.html',
  styleUrls: ['./order-detail-estimating-screen.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailEstimatingScreenComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
  ) { }

  public get record() { return this.service.order }
  public get editing() { return this.service.editing }


  ngOnInit() {
    if (this.record.products && this.record.products.length > 0)
      this.selectedProductId = this.record.products[0].productProductId;
  }

  public selectedProductId = null;
  public get selectedProduct() {
    const osp = this.record.products.find(p => p.productProductId === this.selectedProductId);
    return osp?.product;
  }

  public openProduct(product: Product) {
    this.service.selectedProductId = product.productId;
    this.service.mainTab = 0;
  }

}
