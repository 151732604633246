<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/purchasing/bulkquote/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [showManagerBar]="false" [(searchString)]="searchString" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('name')">
            Vendor Name
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th (click)="sortBy('number')">
            Quote #
            <i [ngClass]="getSortIconFor('number')"></i>
          </th>
          <th (click)="sortBy('required')">
            Sent
            <i [ngClass]="getSortIconFor('required')"></i>
          </th>
          <th>
            Recieved
          </th>
          <th>
            Status
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.vendor.name}}</td>
        <td class="text-muted">{{row.bidNumber}}</td>
        <td>
          {{(row.bids[0]?.asked | date) || '--'}}
        </td>
        <td>
          {{(row.bids[0]?.answered | date) || '--'}}
        </td>
        <td>
          <span class="badge status text-uppercase" [ngClass]="getStatusColorClass(row.status)">
            {{getStatusText(row.status)}}
          </span>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
