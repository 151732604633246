import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { PurchasingService } from '../../services/purchasing.service';
import { UtilityService } from '../../../common/services/utility.service';
import { UserService } from '../../../common/services/user.service';
import { Material } from '../../../order/resources/material';
import { WorkOrder } from '../../../planning/resources/work-order';
import { GlobalReq } from '../../resources/globalReqsReoprt';
import { MaterialBid } from '../../resources/materialBid';
import { Router } from '@angular/router';
import { Requisition } from '../../resources/requisition';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { Station } from '../../../order/resources/station';
import { GlobalReqsBase } from './global-reqs-base';

type ReqItem = Material | PurchasedItem | Station;


@Component({
  selector: 'global-reqs',
  templateUrl: './global-reqs.component.html',
  styleUrls: ['./global-reqs.component.less']
})
export class GlobalRequisitionsComponent extends GlobalReqsBase implements OnInit {
  @ViewChild('insetnav') insetnav: MatSidenav;

  constructor(navService: NavigationService, private router: Router, purchasingSvc: PurchasingService) {
    super(purchasingSvc);
    navService.setPageTitle("Global Requisitions");
  }
  
  public getWorkOrders(item: ReqItem): WorkOrder[] {
    let materialId = null;
    let stationId = null;
    let purchasedItemId = null;
    if (this.isMaterial(item)) {
      materialId = item.materialId;
    } else if (this.isStation(item)) {
      stationId = item.stationId;
    } else if (this.isPurchasedItem(item)) {
      purchasedItemId = item.purchasedItemId;
    } 

    return this.data && this.data
      .filter(r => r.workOrderId !== null && r.materialId === materialId && r.stationId === stationId && r.purchasedItemId === stationId)
      .map(r => r.workOrder);
  }

  public getRequisitions(item: ReqItem): Requisition[] {
    let materialId = null;
    let stationId = null;
    let purchasedItemId = null;
    if (this.isMaterial(item)) {
      materialId = item.materialId;
    } else if (this.isStation(item)) {
      stationId = item.stationId;
    } else if (this.isPurchasedItem(item)) {
      purchasedItemId = item.purchasedItemId;
    } 

    return this.data && this.data
      .filter(r => r.requisitionId !== null && r.materialId === materialId && r.stationId === stationId && r.purchasedItemId === stationId)
      .map(r => r.requisition);
  }

  public getFirstOrder(item: ReqItem): WorkOrder | Requisition {
    var wos = this.getWorkOrders(item);
    var reqs = this.getRequisitions(item);
    var all = [...wos, ...reqs]
    return all && all.length > 0 && all[0];
  }

  public getFirstOrderNumber(item: ReqItem): string {
    var o = this.getFirstOrder(item);
    if (this.isWorkOrder(o)) return o.workOrderNumber;
    else if (this.isRequisition(o)) return o.requisitionNumber;
  }

  public openFirstOrder(item: ReqItem) {
    var o = this.getFirstOrder(item);
    if (this.isWorkOrder(o)) this.router.navigate(['/purchasing/quote', o.order.orderSegmentId]);
    else if (this.isRequisition(o)) this.router.navigate(['/requisition', o.requisitionId]);
  }

  public getWorkOrdersCount(item: ReqItem): number {
    var wos = this.getWorkOrders(item);
    var reqs = this.getRequisitions(item);
    return wos.length + reqs.length;
  }

  public getSoonestRequired(item: ReqItem): Date {
    var wos = this.getWorkOrders(item);
    var reqs = this.getRequisitions(item);

    var dates = [
      ...wos.filter(wo => wo.order.requiredDate).map(wo => new Date(wo.order.requiredDate)),
      ...reqs.filter(req => req.dueDate).map(req => new Date(req.dueDate)),
    ].sort((a, b) => a.getTime() - b.getTime());
    if (dates.length == 0) return null;

    return dates[0];
  }

  private getPerItemAmount(bid: MaterialBid): number | null {
    if (!bid.perItemBid && (!bid.totalBid && !bid.qty)) return null;
    else if (bid.perItemBid) return bid.perItemBid
    else return bid.totalBid / bid.qty;
  }

  public getLowestBid(item: ReqItem): MaterialBid {
    var wos = this.getWorkOrders(item);
    var reqs = this.getRequisitions(item);

    var bids = [
      ...wos.filter(wo => wo.materialBids && wo.materialBids.length > 0).flatMap(wo => wo.materialBids),
      ...reqs.filter(req => req.materialBids && req.materialBids.length > 0).flatMap(req => req.materialBids)
    ].filter(b => this.getPerItemAmount(b) !== null).sort((a, b) =>
      this.getPerItemAmount(a) - this.getPerItemAmount(b)
    );
    if (bids.length == 0) return null;

    return bids[0];
  }

  public getBidLeadTime(item: ReqItem, bid: MaterialBid): number | string {
    if (!bid) return '--'; 
    const set = bid.leadTimeDays;
    if (!set) {
      if (this.isMaterial(item)) return item.defaultLeadTimeDays;
      else return '--';
    } else return set;
  }

  public getBidCost(bid: MaterialBid): number {
    if (!bid) return null;
    return bid.totalBid || (bid.perItemBid * bid.qty);
  }

  public getReqBids(item: GlobalReq): MaterialBid[] {
    let bids: MaterialBid[] = [];
    if (item.workOrder && item.workOrder.materialBids) bids = item.workOrder.materialBids;
    if (item.requisition && item.requisition.materialBids) bids = item.requisition.materialBids;
    return bids;
  }

  public getLowestWOBid(item: GlobalReq): MaterialBid {
    const bids = this.getReqBids(item);

    if (bids.length == 0) return null;

    const lowest = Math.min.apply(null, bids.filter(b => b != null).map(b => this.getBidCost(b)));
    return bids.find(b => this.getBidCost(b) == lowest);
  }

  public openRelevantOrder(item: GlobalReq) {
    if (item.workOrder !== null) this.router.navigate(['/purchasing/quote', item.workOrder.order.orderSegmentId]);
    else if (item.requisition !== null) this.router.navigate(['/requisition', item.requisition.requisitionId]);
  }

  public getReqNumber(item: GlobalReq): string {
    if (item.workOrder !== null) return item.workOrder.workOrderNumber;
    else if (item.requisition !== null) return item.requisition.requisitionNumber;
  }

  public getReqRequiredDate(item: GlobalReq): Date {
    if (item.workOrder !== null) return item.workOrder.order.requiredDate;
    else if (item.requisition !== null) return item.requisition.dueDate;
  }

  public openWorkOrder(wo: WorkOrder): void {
    this.router.navigate(['/purchasing/quote', wo.order.orderSegmentId]);
  }

  public openRequisition(req: Requisition): void {
    this.router.navigate(['/requisition', req.requisitionId]);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public makeDate(dateStr: string): Date {
    if (dateStr == null || dateStr.length == 0) return null;
    return new Date(dateStr);
  }

  ngOnInit() {
    this.getDetail();
  }
}
