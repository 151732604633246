import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { MessageType } from '../resources/message'
import { ErrorHandlerService } from '../services/errorHandler.service'
import { httpService } from './http.service';
import { VirtualDocument } from '../resources/virtual-document';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends httpService {

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Document";
  }

  public setTags(file: VirtualDocument): Observable<VirtualDocument> {
    return this.http.post<VirtualDocument>(this.baseUrl + `api/upload/settags?documentid=${file.documentId}`, file.tags || [] ).pipe(
      tap(_ => this.messages.add(`Document Service: Tags Updated`, MessageType.SUCCESS, true)),
      catchError(this.handleError<VirtualDocument>("Set Document Tags", null))
    );
  }

  public upload(file: VirtualDocument): Observable<VirtualDocument> {
    const formData = new FormData();
    formData.append('file', <any>file);

    return this.http.post<VirtualDocument>(this.baseUrl + 'api/upload/file', formData, { params: { tags: file.tags || [] }}).pipe(
      tap(_ => this.messages.add(`Document Service: Document '${file.name}' Uploaded Successfully`, MessageType.SUCCESS, true)),
      catchError(this.handleError<VirtualDocument>("Upload New Document", null))
    );
  }
}
