<div class="mat-small-header mb-2 px-3">Finished Part Shipments</div>
<mat-list class="pt-0 border-top">
  <mat-list-item *ngFor="let shippingTicket of shippingTickets" class="border-bottom">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center p-2" style="font-size: 0.9em;">
        <mat-checkbox class="mat-checkbox-display mat-checkbox-no-margin mr-3" [checked]="!!shippingTicket.departureDate"></mat-checkbox>
        <b>{{ getTicketNumber(shippingTicket) }} </b>
        <div class="small ml-3">
          <b>{{ shippingTicket.quantityRequired }}</b> item{{ shippingTicket.quantityRequired === 1 ? '' : 's' }} due on <b>{{ (shippingTicket.requiredDate | date: 'MM/dd/yyyy') ?? 'Unknown Date' }}</b>
        </div>
        <div class="ml-auto"></div>
        <div class="small" *ngIf="!!shippingTicket.departureDate">
          Shipped by <b>{{ shippingTicket.receivedOrSentBy_Name ?? 'Unknown User' }}</b> on <b>{{ (shippingTicket.departureDate | date: 'MM/dd/yyyy') ?? 'Unknown Date' }}</b>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>