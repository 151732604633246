import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FloorService } from '../../services/floor.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { SearchResult } from '../../../common/resources/search-result';
import { Station } from '../../../order/resources/station';
import { StationService } from '../../../order/services/station.service';

@Component({
  selector: 'machine-assignment-list',
  templateUrl: './machine-assignment-list.component.html',
  styleUrls: ['./machine-assignment-list.component.less']
})
export class MachineAssignmentListComponent extends SearchList<MachineAssignment> implements OnChanges {
  @Input() machineId: string;
  @Output() selected: EventEmitter<MachineAssignment> = new EventEmitter<MachineAssignment>();

  constructor(private service: FloorService, private stationSvc: StationService) {
    super('machineassignment');
  }

  public get resultData(): MachineAssignment[] {
    return this.results;
  }

  protected search(): void {
    this.results = null;
    //NOTE: this isn't a true search service, so....
    this.service.getMachineSchedule(this.machineId).subscribe(data => {
      this.searchResults = <SearchResult<MachineAssignment>>{
        pageIndex: 0,
        pageSize: 1000,
        resultCount: data.length,
        successful: true,
        message: "",
        results: data
      };
      this.results = data;
    });
  }

  public getStation(stationId: string): Station {
    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  public onSelect(record: MachineAssignment): void {
    this.selected.emit(record);
  }

  public onDragStart(_event: any, record: MachineAssignment) {
    sessionStorage.setItem('machine-assignment-list-drag-data', JSON.stringify(record));
  }

  ngOnChanges(_: SimpleChanges): void {
    this.search();
  }
}
