import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { ShippingTicket } from '../../../shipping/resources/shipping-ticket';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { Vendor, VendorCertificate } from '../../../supplier/resources/vendor';
import { VendorService } from '../../../supplier/services/vendor.service';
import { QualityTicket } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';

@Component({
  selector: 'cert-expiration',
  templateUrl: './cert-expiration.component.html',
  styleUrls: ['./cert-expiration.component.less']
})
export class CertExpirationComponent implements OnInit {

  public loading = false;
  public saving = false;
  public id: string;
  public ticket: QualityTicket;
  public vendor: Vendor;
  public certificate: VendorCertificate;
  public showEditor: string = null;
  @ViewChild("sidenav", { static: true }) sidenav: MatSidenav;
  @ViewChild("finalizeDialog", { static: true }) finalizeDialog: TemplateRef<any>;

  constructor(
    public router: Router,
    private qualityService: QualityService,
    private vendorService: VendorService,
    private navService: NavigationService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    route: ActivatedRoute
  ) {
    navService.setPageTitle("Quality");
    navService.pushBreadcrumb("Supplier Certificate Expiration");
    this.id = route.snapshot.paramMap.get('id');
  }


  ngOnInit() {
    this.getDetail();
  }


  public async getDetail() {
    this.loading = true;
    this.ticket = await this.qualityService.getDetail(this.id).toPromise();
    this.vendor = await this.vendorService.getDetail(this.ticket.vendorId).toPromise();
    this.certificate = this.vendor.vendorCertificates.find(c => c.vendorCertificateId === this.ticket.vendorCertificateId);
    this.navService.pushBreadcrumb(`${this.vendor.name} - ${this.certificate.certificateName}`);
    this.loading = false;
  }

  public openDocumentWindow() {
    this.showEditor = "document";
    this.sidenav.toggle();
  }

  public addDocuments(documents: VirtualDocument[]): void {
    this.saving = true;

    this.qualityService.save(this.ticket).subscribe((detail) => {
      this.ticket = detail;

      this.qualityService.addDocuments(this.ticket, documents).subscribe((_) => {
        this.saving = false;
        this.getDetail();this.getDetail();
      });
    });
  }

  public deleteDocument(document: VirtualDocument) {
    this.qualityService.removeDocument(this.ticket, document);
  }

  public async updateContract() {
    if (this.ticket.qualityTicketDocuments.length === 0) {
      this.utilityService.showAlert('No documents uploaded',
        `<p>Please upload at least one new certificate document to replace the old one${this.certificate.vendorCertificateDocuments.length === 1 ? '' : 's'}</p>`
      );
      return;
    }
    const dialogRef = this.dialog.open<null, Date>(this.finalizeDialog, {
      disableClose: true,
    });
    const newDate = await dialogRef.afterClosed().toPromise();
    if (newDate === null) {
      return;
    } else {
      this.saving = true;
      await this.qualityService.renewCertificate(this.ticket, newDate).toPromise();
      this.saving = false;
      this.router.navigate(['/quality']);
    }
  }

  weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

}
