<div>
  <loading *ngIf="!data"></loading>

  <div class="row m-0">
    <mat-sidenav-container *ngIf="data">
      <mat-sidenav disableClose #insetnav mode="over" position="end">
      </mat-sidenav>
      <mat-sidenav-content>

        <div class="col-12">
          <h5 class="mt-2 w-100 text-center p-2 bg-secondary">
            <button class="heading-menu" [matMenuTriggerFor]="types">Show Types<i class="fas fa-fw fa-caret-right"></i></button>
            <mat-menu #types="matMenu" class="p-3">
              <div>
                <mat-checkbox [checked]="isSelected('materials')" (change)="setSelection($event, 'materials')">Materials</mat-checkbox>
              </div>
              <div>
                <mat-checkbox [checked]="isSelected('outsourced')" (change)="setSelection($event, 'outsourced')">Outsourced Items</mat-checkbox>
              </div>
              <div>
                <mat-checkbox [checked]="isSelected('purchases')" (change)="setSelection($event, 'purchases')">Purchased Items</mat-checkbox>
              </div>
            </mat-menu>

            Purchasing - All Items
          </h5>
          <div class="table table-striped">
            <div class="row text-secondary small font-weight-bold border-top border-bottom p-2">
              <div class="col text-center">Component Name</div>
              <div class="col text-center">Work Order/Requisition #</div>
              <div class="col text-center">Required By</div>
              <div class="col">Lowest Quote</div>
              <div class="col">Lead Time</div>
              <div class="col">Vendor</div>
            </div>
            <div *ngFor="let material of filteredData" class="small stripe">
              <div class="row p-1 cursor-pointer" [ngClass]="{'bg-primary text-white': material.isOpen}" (click)="material.isOpen=!material.isOpen">
                <div class="col">
                  <i *ngIf="getWorkOrdersCount(material.item) > 1; else emptySpace" class="text-muted fas fa-fw" [ngClass]="{'fa-plus': !material.isOpen, 'fa-minus': material.isOpen}"></i>
                  <ng-template #emptySpace><i class="fas fa-fw"></i></ng-template>
                  {{getItemName(material.item)}}
                </div>
                <div class="col">
                  <a [ngClass]="{'text-white underline': material.isOpen}" href="javascript:void(0)" *ngIf="getFirstOrder(material.item); else noData" 
                  (click)='openFirstOrder(material.item)'>{{getFirstOrderNumber(material.item)}}</a>
                  <span class="badge badge-secondary ml-2" *ngIf="getWorkOrdersCount(material.item) > 1">+{{getWorkOrdersCount(material.item)}}</span>
                </div>
                <div class="col text-center">
                  <span *ngIf="getSoonestRequired(material.item)!=null" class="badge required" [ngClass]="getRequiredColor(getSoonestRequired(material.item))">
                    {{getSoonestRequired(material.item) | date}}
                    <span class="d-block">{{weeksLeft(getSoonestRequired(material.item), true)||'--'}} Week(s) {{(weeksLeft(getSoonestRequired(material.item), false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                  </span>
                </div>
                <div class="col">{{(getBidCost(getLowestBid(material.item)) | currency) || '--' }}</div>
                <div class="col">{{getBidLeadTime(material.item, getLowestBid(material.item))}} Days</div>
                <div class="col">{{getLowestBid(material.item)?.vendor.name || '--'}}</div>
              </div>
              <div *ngIf="material.isOpen">
                <div *ngFor="let greq of material.data; let i=index;">
                  <div class="row p-1 stripe cursor-pointer" [ngClass]="{'bg-muted-primary text-white': greq.isOpen}" (click)="greq.isOpen=!greq.isOpen">
                    <div class="col text-right">
                      <i class="fas fa-fw text-muted" [ngClass]="{'fa-minus': greq.isOpen,'fa-plus': !greq.isOpen}"></i>
                    </div>
                    <div class="col">
                      <a [ngClass]="{'text-white underline': greq.isOpen}" href="javascript:void(0)" (click)='openRelevantOrder(greq)'>
                        {{getReqNumber(greq)}}
                      </a>
                      <span *ngIf="getReqBids(greq).length>0" class="badge badge-secondary ml-2">{{getReqBids(greq).length}} Quotes</span>
                    </div>
                    <div class="col text-center">
                      <span class="badge required" [ngClass]="getRequiredColor(getReqRequiredDate(greq))">
                        {{makeDate(getReqRequiredDate(greq)) | date}}
                        <span class="d-block">{{weeksLeft(getReqRequiredDate(greq), true)||'--'}} Week(s) {{(weeksLeft(getReqRequiredDate(greq), false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                      </span>
                    </div>
                    <div class="col">{{(getBidCost(getLowestWOBid(greq)) | currency) || '--' }}</div>
                    <div class="col">{{getBidLeadTime(material.item, getLowestWOBid(greq))}} Days</div>
                    <div class="col">{{getLowestWOBid(greq)?.vendor.name || '--'}}</div>
                  </div>
                  <div *ngIf="greq.isOpen">
                    <div *ngFor="let bid of getReqBids(greq); let bi = index;">
                      <div *ngIf="bi>0" class="row p-1 stripe cursor-pointer">
                        <div class="col"><!--empty--></div>
                        <div class="col"><!--empty--></div>
                        <div class="col"><!--empty--></div>
                        <div class="col">{{(getBidCost(bid) | currency) || '--' }}</div>
                        <div class="col">{{getBidLeadTime(bid, material.item)}} Days</div>
                        <div class="col">{{bid.vendor.name || '--'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<ng-template #noData><span class="text-muted font-italic">--</span></ng-template>
