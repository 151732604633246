import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { User, UserRole } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';
import { DocumentService } from '../../../common/services/document.service';
import { UtilityService } from '../../../common/services/utility.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationPreferredChannelService } from '../../../../cots/notification/services/notification-preferred-channel.service'

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.less'],
  encapsulation: ViewEncapsulation.None,
})
/** user-detail component*/
export class UserDetailComponent implements OnInit {
  public id: string = null;
  public user: User;
  public availableGroups: UserRole[] = [];
  public editing: boolean = false;
  public saving: boolean = false;
  public uploadFileName = null;

  eventsSubjectEmitter: Subject<void> = new Subject<void>();
  public mySmsNumber: string;


  constructor(private navService: NavigationService, private userService: UserService, private documentService: DocumentService,
    private utilitySvc: UtilityService, private route: ActivatedRoute, private router: Router,
    private notificationPreferredChannelService: NotificationPreferredChannelService) {
    this.id = this.route.snapshot.paramMap.get('id');
    navService.setPageTitle(this.id == null ? "User Details" : "Employees");
  }

  emitEventToChild() {
    this.eventsSubjectEmitter.next();
  }

  public get canEdit(): boolean {
    //TODO... HR manager??
    return this.isMe() || this.canAccess('Developer');
  }

  private isMe(): boolean {
    return this.user && this.userService.userData && this.user.userId == this.userService.userData.userId;
  }

  public canAccess(area: string): boolean {
    if (this.userService.userData != null && this.userService.userData.userId != null)
      return this.userService.canAccess(area);

    return false;
  }

  public isMemberOf(group: string): boolean {
    return this.userService.canAccess(group, this.user);
  }

  public toggleEditing(): void {
    this.editing = !this.editing;
  }

  public cancelEditing(): void {
    if (!this.editing && this.id != null)
      this.router.navigate(['/employee']);
    else {
      this.toggleEditing();
      this.getData();
    }
  }

  public saveChanges(closeOnComeplete?: boolean): void {
    this.saving = true;

    if (this.user.userId == this.user.supervisorId) {
      this.user.supervisorId = null;
    }

    this.userService.updateUserInfo(this.user).subscribe(_ => {
      this.saving = false;
      if (closeOnComeplete || false) this.toggleEditing();
    });

    this.emitEventToChild();

    this.notificationPreferredChannelService.updateRecipientMySmsNumber(this.user.userId, this.mySmsNumber).subscribe(data => {
      console.log("updateRecipientMySmsNumber() - this.mySmsNumber= " + this.mySmsNumber);
    });
  }

  public getFile(event): void {
    if (event.length > 0) {
      var document = event[0];
      this.uploadFileName = `Uploading '${document.name}'`;

      this.documentService.upload(document)
        .subscribe(doc => {
          this.user.userProfilePhotoPath = `/document/${doc.documentId}`;
          this.uploadFileName = null;
          this.saveChanges();
        });
    }
    else {
      this.utilitySvc.showAlert("No File Found!", "<p>Something went wrong trying to upload your file.</p><p class='text-muted'>Please try again.</p>");
    }
  }

  private getRecipientMySmsNumber(recipientId: string) {
    if (recipientId) {
      this.notificationPreferredChannelService.getRecipientMySmsNumber(recipientId).subscribe(data => {
        this.mySmsNumber = data == "" ? data : "Not Set";
        console.log("getData() - this.mySmsNumber=" + this.mySmsNumber);
      });
    }
  }

  private getData(): void {
    this.user = null;
    if (this.id != null) {
      //Get the specidied
      this.userService.getUser(this.id).subscribe(u => {
        this.user = u;
        this.navService.pushBreadcrumb(u.fullName);
      });
    }
    else {
      //Get your own then
      this.userService.user.subscribe(u => {
        this.user = u;
      });
    }
    this.availableGroups = this.userService.roles;

    this.getSMS();
  }

  private getSMS() {
    if (this.user != null)
      this.getRecipientMySmsNumber(this.user.userId);
    else {
      setTimeout(() => this.getSMS(), 100);
    }
  }


  ngOnInit() {
    this.getData();
  }
}
