import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Building } from '../../../floor/resources/building';
import { ShippingService } from '../../services/shipping.service';

@Component({
  selector: 'shipping-wrapper',
  templateUrl: './shipping-wrapper.component.html',
  styleUrls: ['./shipping-wrapper.component.less']
})
export class ShippingWrapperComponent implements OnInit {

  $buildings: Observable<Building[]>
  $currentBuilding: Observable<Building>

  constructor(
    private shippingService: ShippingService
  ) {
    this.$buildings = shippingService.$buildings;
    this.$currentBuilding = shippingService.$currentBuilding;
  }

  public selectBuilding(b: Building) {
    this.shippingService.setCurrentBuilding(b);
  }

  ngOnInit() {
  }

}
