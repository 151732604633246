import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SalesNote } from '../../../resources/sales-note';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { NgModel } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatChipInput } from '@angular/material/chips';
import { UtilityService } from '../../../../common/services/utility.service';

@Component({
  selector: 'sales-note-creator',
  templateUrl: './sales-note-creator.component.html',
  styleUrls: ['./sales-note-creator.component.less']
})
export class SalesNoteCreatorComponent implements OnInit, AfterViewInit {

  readonly separatorKeyCodes = [ENTER] as const;
  @Input() note: SalesNote;
  @Input() existingTags$: Observable<string[]>;
 
  constructor() { }

  public selectedTags = new BehaviorSubject<string[]>([]);
  ngOnInit(): void {
    this.selectedTags.next(this.note?.tags ?? []);
  }

  public filteredTags$: Observable<string[]>;
  ngAfterViewInit(): void {
    this.filteredTags$ = combineLatest([
      this.existingTags$,
      this.selectedTags,
      this.inputModel.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([existing, selected, filter]) => {
        let out = existing;
        if (selected && selected.length > 0) out = out.filter(t => !selected.includes(t));
        if (filter && typeof filter === 'string' && filter.trim()) out = out.filter(t => t.toLowerCase().includes(filter.trim().toLowerCase()))
        return out;
      })
    )
    
  }

  @ViewChild('inputModel') inputModel: NgModel;
  addTag(tag: string, input?: MatChipInput) {
    if (!tag?.trim()) return;
    this.note.tags = [...this.note.tags, tag];
    this.selectedTags.next(this.note.tags);
    this.inputModel.control.setValue('');
    if (input) input.clear();
  }

  removeTag(index: number) {
    this.note.tags = this.note.tags.filter((_, i) => i !== index);
    this.selectedTags.next(this.note.tags);
  }

  @Output() save = new EventEmitter<void>();
  doSave() {
    this.save.emit();
  }

  @Output() close = new EventEmitter<void>();
  public cancel() {
    this.close.emit();
  }

  public get isNew() {
    return this.note?.salesNoteId === UtilityService.emptyGuid;
  }

}
