import { User } from "../../common/resources/user";
import { VirtualDocument } from "../../common/resources/virtual-document";
import { WorkflowStep } from "../../order/resources/workflow";
import { WorkOrder } from "./work-order";


export enum ProgrammingType {
  CNCProgramming = 0,
  NCProgramming = 1,
  CMMProgramming = 2
}
export interface ProgrammingTicket {
  programmingTicketId: string;
  status: number;
  workOrderId: string;

  dueDate?: Date;

  dateAssigned?: Date;
  promiseDate?: Date;
  completedDate?: Date;

  assignedUserId: string;
  workflowNotes: string;
  programmingNotes: string;
  highlightedByPlanner: string[];
  workOrder: WorkOrder;
  assignedUser: User;
  programmingFiles: ProgrammingTicketDocument[];

  rejectedById?: string;
  rejectedDate?: Date;
  rejectionReason: string;
  rejectedBy: User;

  type: ProgrammingType;
}

export interface ProgrammingTicketDocument {
  programmingTicketId: string;
  documentId: string;

  document?: VirtualDocument;
}

