<search-list-new
  matSort
  [defaultSort]="{
    id: 'dueDate',
    start: 'asc'
  }"
  [showManagerToggle]="true"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['ticketNumber', 'workOrderNumber', 'dueDate', 'dateAssigned', 'promiseDate', 'late', 'user', 'customer', 'poNumber', 'status']"
  (itemSelected)="onSelect($event)"
  [fieldSearchFields]="[
    { field: 'customer', code: 'customer' },
    { field: 'poNumber', code: 'po' },
    { field: 'poNumber', code: 'number' },
    { field: 'woNumber', code: 'wo' },
    { field: 'partNumber', code: 'pn' },
    { field: 'partNumber', code: 'part' },
    { field: 'assignedUser', code: 'user' },
    { field: 'assignedUser', code: 'assigned' },
    { field: 'ticket', code: '#' }
  ]"
  [fieldSearchNames]="{
    vendor: 'Supplier',
    poNumber: 'PO #',
    woNumber: 'Work Order Number',
    ticket: 'Ticket #',
    partNumber: 'PN',
    assignedUser: 'Assigned User'
  }"
  localStorageId="planning-queue"
  [isManager]="userIsManager.bind(this)"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
  <ng-container matColumnDef="ticketNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="number">Ticket #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.planningTicketNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="workOrderNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="rootWO" style="flex-basis: 12%">WO #</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 12%">
      <span class="font-weight-bold mat-light-text">
        {{row.rootWorkOrderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="required">Due Date</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.requiredDate" [ngClass]="getHighlightClass(row.requiredDate)" class="p-1 rounded">
        {{row.requiredDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dateAssigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dateAssigned">Assigned On</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.dateAssigned">
        {{row.dateAssigned | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="promiseDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="promiseDate">Promise Date</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.planningPromiseDate" [ngClass]="getHighlightClass(row.planningPromiseDate)" class="p-1 rounded">
        {{row.planningPromiseDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="late">
    <mat-header-cell *matHeaderCellDef>Late</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngLet="getDaysLate(row) as daysLate">
        {{daysLate !== null ? daysLate : ''}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="user">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned">User</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.fullName" class="d-flex align-items-center text-muted font-weight-bold">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.fullName }}</span>
      </span>
      <span *ngIf="!row.fullName" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef mat-sort-header="customer">Customer</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span>
        {{ row.businessName }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="poNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="poNumber">PO #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.purchaseOrderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
</search-list-new>