import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ChartComponent } from '../../../common/components/chart/chart.component';
import { FloorService } from '../../services/floor.service';
import { FloorMachine } from '../../resources/building';
import { UtilityService } from '../../../common/services/utility.service';
import { WorkOrderService } from '../../../planning/services/work-order.service';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { WorkOrder } from '../../../planning/resources/work-order';

@Component({
  selector: 'department-today-schedule',
  templateUrl: './department-today-schedule.component.html',
  styleUrls: ['./department-today-schedule.component.less']
})
export class DepartmentTodayScheduleComponent implements OnInit, OnChanges {
  @Input() floorBuildingId: string = null;
  @Input() date: Date = new Date();
  @Input() machines: FloorMachine[] = [];
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDragComplete: EventEmitter<string> = new EventEmitter<string>();
  @Output() onNewAssignment: EventEmitter<MachineAssignment> = new EventEmitter<MachineAssignment>();

  public saving: boolean = false;
  private skipClick: boolean = false;

  private todayRawData: any[];
  public todayData: any[];
  public todayLabels: string[];
  public todayChartOptions: any;
  public chartPlugins: any[];

  private dragData: any = {};

  constructor(private floorSvc: FloorService, private utilitySvc: UtilityService, private woService: WorkOrderService) { }

  public bubbleClick(e: any) {
    if (this.skipClick || this.dragData.mouseData)
      return;

    this.onClick.emit(e);
  }

  public reload(): void {
    this.todayRawData = [];
    this.setupChart();
  }

  public get rawData(): any[] {
    return this.todayRawData;
  }

  private setupChart(): void {
    var self = this;

    this.todayChartOptions = {
      responsive: true,
      legend: {
        display: false
      },
      hover: {
        onHover: function (e, el) {
          var section = el[0];

          if (self.dragData.mouseData) {
            e.currentTarget.style.cursor = self.dragData.canDrop ? 'grab' : 'no-drop';
            return;
          }

          e.currentTarget.style.cursor = (section && section._model.backgroundColor != 'transparent') ? 'pointer' : 'default';
        }
      },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          //beforeTitle: function(){return "beforeTitle"},
          //title: function(){return "title"},
          //afterTitle: function(){return "afterTitle"},
          //beforeBody: function(){return "beforeBody"},
          //beforeLabel: function(){return "beforeLabel"},
          //afterBody: function(){return "afterBody"},
          //beforeFooter: function(){return "beforeFooter"},
          //footer: function(){return "footer"},
          //afterFooter: function(){return "afterFooter"},
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label;
          },
          afterLabel: function (tooltipItem, data) {
            return Number(tooltipItem.xLabel).toFixed(2) + ' hrs';
          }
          , footer: function (tooltipItem, data) {
            return self.getPrerequisiteInfo(self, tooltipItem, data);
          }
        }

      },
      aspectRatio: 4,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            suggestedMin: 0,
            stepSize: 1,
            callback: function (v, i, vs) {
              return `${v}:00`;
            }
          }
        }],
        yAxes: [{ stacked: true }]
      }
    };

    this.chartPlugins = [{
      afterInit: function (chart, _options) {
        chart.canvas.onmousedown = function (e: MouseEvent): void { self.mouseDown(e, chart); };
        chart.canvas.onmouseup = function (e: MouseEvent): void { self.mouseUp(e, chart); };
        chart.canvas.onmousemove = function (e: MouseEvent): void { self.mouseMove(e, chart); };
        chart.canvas.onmouseleave = function (e: MouseEvent): void { self.mouseLeave(e, chart); };
        chart.canvas.onmouseenter = function (e: MouseEvent): void { ; }; //We won't support dragging data IN TO this chart
      },
      beforeDatasetsDraw: function (chart, _options) {
        self.shadeDropArea(chart);
      },
      afterDatasetDraw: function (chart, _options) {
        if (self.dragData.mouseData && self.dragData.canDrop != undefined && !self.dragData.canDrop) {
          var canvas = chart.ctx;
          canvas.font = '24px Arial';
          canvas.textAlign = 'center';
          canvas.textBaseline = 'center';
          canvas.fillStyle = "#99999999";

          canvas.fillText("Incompatible Station", chart.width / 2, chart.height / 2);
        }
      }
    }];

    this.fetchData();
  }

  private getPrerequisiteInfo(context: any, tooltipItem: any, data: any): string[] {
    if (context.rawData[tooltipItem[0].datasetIndex].needed == 0 && context.rawData[tooltipItem[0].datasetIndex].operator != null) return null;

    if (context.rawData[tooltipItem[0].datasetIndex].ready == context.rawData[tooltipItem[0].datasetIndex].needed) {
      if (context.rawData[tooltipItem[0].datasetIndex].operator != null)
        return ["All Prerequisites Complete"];
      else
        return ["No Operator Assigned"];
    }
    
    return [context.rawData[tooltipItem[0].datasetIndex].ready + " of " + context.rawData[tooltipItem[0].datasetIndex].needed + " Prerequisites Complete", (context.rawData[tooltipItem[0].datasetIndex].operator != null ? "Operator Assigned" : "NO Operator Assigned")];
  }

  private fetchData(): void {
    this.floorSvc.getFloorBuildingTodayData(this.floorBuildingId, this.date).subscribe(result => {
      this.todayRawData = result;

      this.getTodayLabels();
      this.getTodayData();
    });
  }

  private shadeDropArea(chart: any): void {
    if (this.dragData.mouseData) {
      chart.ctx.beginPath();
      chart.ctx.fillStyle = this.dragData.canDrop ? "#00ff0022" : "#ff000022";

      chart.ctx.fillRect(chart.scales["y-axis-0"].width, chart.scales["y-axis-0"].top, chart.scales["x-axis-0"].width, chart.scales["y-axis-0"].height);
    }
  }

  private getTodayLabels(): void {
    this.todayLabels = Array.from(new Set(this.todayRawData.map(data => { return data.machineName || 'Unassigned'; })));
  }

  private getTodayData(): void {
    this.todayData = [];

    var color: number = 0;
    for (var i = 0; i < this.todayLabels.length; i++) {
      var WOs = this.todayRawData.filter(data => (data.machineName || 'Unassigned') == this.todayLabels[i]);

      for (var j = 0; j < WOs.length; j++) {
        var data = Array(this.todayLabels.length).fill(0);
        data[i] = WOs[j].hours;

        color++;

        this.todayData.push({
          label: WOs[j].workOrderNumber,
          backgroundColor: this.getRepeatColor(WOs[j].workOrderNumber, ChartComponent.getColor(color)),
          borderColor: this.getBorderColor(WOs[j]),
          borderWidth: 1,
          borderSkipped: false,
          machineId: WOs[j].machineId,
          workOrderId: WOs[j].workOrderId,
          data: data
        });
      }

    }
  }

  private getBorderColor(data: any): string {
    if (data.needed == 0) return "#0000002c";
    return (data.needed > data.ready || data.operator == null) ? "#e97272" : "#559955";
  }

  private getRepeatColor(label: string, fallbackColor: string): string {
    var existing = this.todayData.find(data => data.label == label);
    if (existing)
      return existing.backgroundColor;

    return fallbackColor;
  }

  private mouseLeave(e: MouseEvent, chart: any): void {
    if (this.dragData.dataPoint) {
      var exportData = {
        source: "DepartmentTodayScheduleComponent",
        backgroundColor: this.dragData.dataPoint.originalBackgroundColor,
        borderColor: this.dragData.dataPoint.borderColor,
        borderWidth: this.dragData.dataPoint.borderWidth,
        borderSkipped: this.dragData.dataPoint.borderSkipped,
        scheduledStart: this.date,
        data: this.todayRawData.find(m => m.machineId == this.dragData.dataPoint.machineId && m.workOrderId == this.dragData.dataPoint.workOrderId)
      };

      sessionStorage.setItem("IntraChartData", JSON.stringify(exportData));

      this.dragData.dataPoint.backgroundColor = this.dragData.dataPoint.originalBackgroundColor;

      this.dragData = {};
      chart.update();
    }
  }

  private mouseDown(e: MouseEvent, chart: any): void {
    var element = chart.getElementAtEvent(e);
    this.dragData = { clickedOn: new Date().valueOf() };

    if (element.length > 0) {
      this.dragData.dataPoint = this.todayData[element[0]._datasetIndex];
      this.dragData.stationId = this.todayRawData[element[0]._datasetIndex].stationId;

      if (this.dragData.dataPoint.machineId == null) {
        this.dragData.unassigned = true;
      }

      this.dragData.boxElement = element[0];
      this.dragData.mouseData = {
        x: e.x, y: e.y
      };

      this.dragData.dataPoint.originalBackgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement.originalX = this.dragData.boxElement._view.x;
      this.dragData.boxElement.originalY = this.dragData.boxElement._view.y;
    }
  }

  private mouseUp(_: MouseEvent, chart: any): void {
    if (!this.todayData) return;

    if (this.dragData.clickedOn && (new Date().valueOf() - this.dragData.clickedOn) < 750) {
      this.skipClick = false;
    }
    else if (this.dragData.dataPoint) {
      this.dragData.dataPoint.backgroundColor = this.dragData.dataPoint.originalBackgroundColor;

      if (this.dragData.canDrop) {
        var newMachineId = this.rawData.find(m => m.machineName == chart.scales["y-axis-0"]._labelItems[Math.floor(((this.dragData.boxElement._view.y - chart.scales["y-axis-0"].top - (this.dragData.boxElement._view.height / 2)) / chart.scales["y-axis-0"].height) * chart.scales["y-axis-0"]._labelItems.length)].label).machineId;

        if (this.dragData.dataPoint.machineId != newMachineId) { //Only fire this if the machine actually changed

          var oldMachineId = this.dragData.dataPoint.machineId;
          var workOrder: WorkOrder = <WorkOrder>this.dragData.dataPoint;
          var selectedDate: Date = new Date(this.date); //for scope
          var unassigned = this.dragData.unassigned || false;

          this.saving = true;
          this.onDragComplete.emit(newMachineId);

          this.woService.getAssignmentsForWorkOrder(workOrder).subscribe(list => {
            if (!list) {
              this.saving = false;
              //Maybe show an alert? Somewthing went wrong
            }
            else {

              if (unassigned) {
                var assignment = list.find(wo => wo.workOrderId == workOrder.workOrderId && wo.machine == null);

                assignment.machine = this.machines.find(m => m.machineId == newMachineId);
                assignment.machineId = newMachineId;
                assignment.scheduledStart = this.date;

                this.onNewAssignment.emit(assignment);
                this.saving = false;

                return;
              }
              var filtered: MachineAssignment[] = list.filter((a) => { return a.machineId == oldMachineId && a.scheduledStart != null && new Date(a.scheduledStart).getDate() == selectedDate.getDate() && new Date(a.scheduledStart).getMonth() == selectedDate.getMonth() && new Date(a.scheduledStart).getFullYear() == selectedDate.getFullYear() });

              if (filtered.length > 1) {
                this.utilitySvc.showConfirmation("Move Multiple Assignments?", "The Work Order you moved will affect multiple assignments on the machine you indicated.", (r) => {
                  if (r) {
                    filtered.forEach((assignment) => {
                      var counter = 0;

                      this.moveAssignment(assignment.assignmentId, newMachineId, (_) => {
                        counter++;
                        if (counter >= filtered.length) {
                          this.reload();
                          this.saving = false;
                        }
                      });
                    });
                  }
                });
              }
              else {
                this.moveAssignment(filtered[0].assignmentId, newMachineId, (_) => {
                  this.reload();
                  this.saving = false;
                });
              }
            }

          });

        }
      }
      else {
        this.utilitySvc.showAlert("Incompatiable Station", "<p>Sorry, but the station you selected isn't compatible with the station you're moving the workorder from.</p><p classs='text-muted'>Please choose another station. Compatible stations will show up with a green background.</p>");
      }
    }

    this.dragData = {};

    chart.update();

    setTimeout(_ => this.skipClick = false, 10);
  }

  private moveAssignment(assignmentId: string, newMachineId: string, onComplete = null): void {
    this.floorSvc.getAssignment(assignmentId).subscribe(data => {
      data.machineId = newMachineId;

      this.floorSvc.saveAssignment(data).subscribe(result => { if (onComplete) onComplete(result) });
    });
  }

  private getNearestMachineIndex(y: number, c: any): number {
    const machineHeight = Math.floor(c.scales['y-axis-0'].height / c.scales['y-axis-0'].ticks.length) - 1;
    var machineIndex = Math.floor(y / machineHeight) - 1;

    return machineIndex;
  }

  private mouseMove(e: MouseEvent, chart: any): void {
    if (!this.todayData) return;
    var self = this;
    this.skipClick = true;

    var getYOffset = function (y: number, c: any): number {
      const machineHeight = Math.floor(c.scales['y-axis-0'].height / c.scales['y-axis-0'].ticks.length) - 1;
      var machine = self.getNearestMachineIndex(y, c);

      const offset = (self.dragData.boxElement._view.height / 2) + ((machineHeight - self.dragData.boxElement._view.height) / 2);

      if (!c.scales['y-axis-0']._labelItems[machine]) machine = 0;

      self.dragData.dropOnDay = c.scales['y-axis-0']._labelItems[machine].label;

      return c.scales['y-axis-0']._gridLineItems[machine].y1 + offset;
    }

    var canDrop = function (y: number, c: any): boolean {
      var machine: number = self.getNearestMachineIndex(y, c);

      if (!c.scales['y-axis-0']._labelItems[machine]) machine = 0;

      var machineName = c.scales['y-axis-0']._labelItems[machine].label;

      if (machineName == 'Unassigned') return false;

      return self.dragData.stationId == self.machines.find(m => m.machineId == self.todayRawData.find(d => d.machineName == machineName).machineId).stationId;
    }

    if (this.dragData.mouseData) {
      this.dragData.dataPoint.backgroundColor = ChartComponent.shade(0.4, this.dragData.dataPoint.originalBackgroundColor, "#FFFFFF", true) + "66";
      this.dragData.canDrop = canDrop(this.dragData.boxElement.originalY + (e.y - this.dragData.mouseData.y), chart);

      this.dragData.boxElement._view.backgroundColor = this.dragData.dataPoint.backgroundColor;
      this.dragData.boxElement._view.y = getYOffset(this.dragData.boxElement.originalY + (e.y - this.dragData.mouseData.y), chart);
      this.dragData.boxElement._view.borderSkipped = "none";

      this.dragData.boxElement._model.backgroundColor = this.dragData.boxElement._view.backgroundColor;
      this.dragData.boxElement._model.y = this.dragData.boxElement._view.y;
      this.dragData.boxElement._model.borderSkipped = "none";

      chart.render({
        duration: 0,
        lazy: false,
        easing: 'easeOutBounce'
      });
    }
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setupChart();
  }

  ngOnInit(): void {
    this.setupChart();
  }
}
