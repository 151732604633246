import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Product } from '../../resources/product';
import * as Highcharts from 'highcharts/highstock';
import 'highcharts/modules/no-data-to-display';
import { debounceTime, withLatestFrom } from 'rxjs/operators';
import { StationService } from '../../services/station.service';
import { debounceTimeAfterFirst } from '../../../../../util/debounceTimeAfter';

@Component({
  selector: 'workflow-graph',
  templateUrl: './workflow-graph.component.html',
  styleUrls: ['./workflow-graph.component.less']
})
export class WorkflowGraphComponent implements OnDestroy {

  @Input() data$: Observable<Product>;
  @Input() quantityGetter: (p: Product) => number;
  
  private _selectedStepId: string = null;
  get selectedStepId(): string | null {
    return this._selectedStepId
  }
  @Input() set selectedStepId(value: string | null) {
    this._selectedStepId = value;
    if (value !== null && this.chart) {
      const point = this.chart.get(value) as Highcharts.Point;
      if (point) point.select(true, false);
    }
  };
  @Output() selectedStepIdChange = new EventEmitter<string>();

  public Highcharts = Highcharts;
  public chart: Highcharts.Chart;

  public chartOptions = {
    // colors: ["#673ab7","#ff9800","#ffeb3b","#9c27b0","#8bc34a","#009688","#607d8b","#607d8b","#9e9e9e","#e91e63","#795548","#2196f3","#ff5722","#ffc107","#3f51b5","#4caf50","#03a9f4","#cddc39","#00bcd4","#f44336"],
    style: {
      fontFamily: 'Roboto',
    },
    chart: {
      type: 'pie'
    },
    title: {
      text: null
    },
    credits: {
      text: ''
    },
    tooltip: {
      // I can't figure out what the right format string for the point name is. {point.name} and {key} don't work. Using default until that's resolved
      // headerFormat: '<span style="color:{point.color}">\u25CF</span> <b>{point.name}abc</b><br>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> ${point.y:.2f} <b>({point.percentage:.2f}%)</b>'
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
      events: {
        itemClick: (event: any) => { 
          if (!event.browserEvent.ctrlKey) { this.selectedStepIdChange.emit(event.legendItem.id); return false }
        }
      }
    },
    plotOptions: {
      series: {
        animation: false,
        // allowPointSelect: true,
        point: {
          events: {
            click: (event: any) => { this.selectedStepIdChange.emit(event.point.id); return false }
          }
        },
        cursor: 'pointer',
        dataLabels: [{
          enabled: true,
          distance: 20
        }, {
          enabled: true,
          distance: -40,
          format: '{point.percentage:.2f}%',
          style: {
            fontSize: '0.8em',
            textOutline: 'none',
            opacity: 0.7,
            fontWeight: '400',
          },
          allowOverlap: false,
          // filter: {
          //   operator: '>',
          //   property: 'percentage',
          //   value: 10
          // }
        }],
        states: {
          normal: {
            opacity: 0.8
          },
          select: {
            opacity: 1,
            // borderWidth: 1,
            // borderColor: '#1d1e1e'
          }
        }
      },
      pie: {
        showInLegend: true
      }
    },
    series: [
      {
        id: 'workflow',
        name: 'Cost',
        colorByPoint: true,
        data: []
      }
    ]
  }
  constructor(private stationService: StationService) { }

  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  private dataSubscription: Subscription;
  onChartReady(chart: Highcharts.Chart) {
    this.chart = chart;
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    this.dataSubscription = this.data$.pipe(debounceTimeAfterFirst(200)).subscribe((product) => {
      const qty = this.quantityGetter(product);
      const breakdown = Product.getBasePricingBreakdown(
        product, 0,
        (step) => this.stationService.stationList.find(st => st.stationId === step.stationId)?.name ?? 'Unknown Station',
        null,
        qty,
        true,
        { inspectionOutput: 'separate' }
      );
      const items = [...(breakdown.process ?? []), ...(breakdown.labor ?? [])];

      const series = chart.get('workflow') as Highcharts.Series;
      const nameCounter: { [key: string]: number } = {};
      series.setData(items.map(i => {
        const { name, price, itemId } = i;
        if (!nameCounter[name]) nameCounter[name] = 0;
        nameCounter[name] += 1;
        return {
          name: nameCounter[name] > 1 ? `${name} #2` : name,
          y: price,
          id: itemId,
          selected: this.selectedStepId === itemId
        }
      }));

      if (this.selectedStepId) {
        //@ts-ignore
        const point = series.data.find(p => p.id === this.selectedStepId);
        if (point) point.select(true, false);
      }

    });
  }

}
