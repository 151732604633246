import { Component, OnInit, Input } from '@angular/core';
import { Product, ProductHistoryEntry } from '../../resources/product';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.less']
})
export class ProductHistoryComponent implements OnInit {

  @Input() product: Product;

  constructor(private orderService: OrderService) { }

  public loading = false;
  public entries: ProductHistoryEntry[] = [];

  ngOnInit() {
    this.loading = true;
    this.orderService.getProductHistory(this.product).subscribe(r => {
      this.entries = r;
      this.loading = false;
    })
  }

}
