import { User } from "../../common/resources/user";
import { VirtualDocument } from "../../common/resources/virtual-document";

export enum MicroTicketStatus {
    OPEN = 0,
    IN_PROGRESS = 1,
    COMPLETE = 2,
    CLOSED = 3,
}

export interface MicroTicketComment {
    microTicketCommentId: string;

    microTicketId: string;

    creatorId: string;
    creator: User;

    createdDate: Date;

    text: string;
}

export interface MicroTicketDocument {
    microTicketId: string;
    documentId: string;
    document: VirtualDocument[];
}

export interface MicroTicket {

    microTicketId: string;

    assignedUserId: string;
    assignedUser: User;

    creatorId: string;
    creator: User;

    createdDate: Date;

    relatedTicketId: string;
    relatedTicketType: string;
    relatedTicketName: string;

    relatedSubItem: string;

    filterKey: string;

    dueDate: Date;
    text: string;

    status: MicroTicketStatus;
    completedDate: Date;

    comments: MicroTicketComment[];

    carbonCopiesList: string[];

    documents: VirtualDocument[];
}