
<a
class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
(click)="close()"><i class="fas fa-times"></i></a>
<a [class.invisible]="totalLocationsPicked !== ticket.quantityRequired" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon"
title="Save Record" (click)="fulfill()"><i class="fas fa-save"></i></a>

<loading *ngIf="saving" content="Saving..."></loading>

<div class="row px-3 py-4">
  <div class="col-12">
    <mat-card-title>Tool Crib Ticket</mat-card-title>
    <mat-card-subtitle>
      <b>{{ ticket.quantityRequired }}x</b> of <item-type-chip [item]="ticket.inventoryItem" [iconOnly]="true" class="mr-2"></item-type-chip> <b>{{ ticket.inventoryItem.name }}</b> needed at machine <b>{{ ticket.machineAssignment.machine.name }}</b> for work order <b>#{{ ticket.machineAssignment.workOrder.workOrderNumber }}</b>
    </mat-card-subtitle>
  </div>
  <div class="col-6">
    <inventory-tree-picker
      #inventoryTreeSearch
      label="Location" name="location"
      [building]="building"
      [takingItem]="ticket.inventoryItem"
      [takingWorkOrderId]="ticket.machineAssignment.workOrderId"
      [takingRMATicketId]="ticket.machineAssignment.workOrder.order.rmaTicketId"
      [(ngModel)]="locationData" (ngModelChange)="locationControl.updateValueAndValidity()" [max]="ticket.quantityRequired">
    </inventory-tree-picker>
  </div>
  <div class="col-6 d-flex flex-column">
    <div style="height: 63px;
    padding-top: 4px;
    margin-bottom: 26px;
    padding-left: 24px;
    " class="d-flex align-items-center"
    >
      <mat-checkbox class="mr-2 location-done-checkbox" [checked]="totalLocationsPicked === ticket.quantityRequired"></mat-checkbox>
      <span><b>{{ totalLocationsPicked }}</b> out of <b>{{ticket.quantityRequired}}</b> units selected</span>
    </div>
    <div class="border rounded h-0 flex-grow-1">
      <mat-list>
        <ng-container *ngFor="let loc of locations; last as last">
          <mat-list-item>
            <mat-icon class="fa-rotate-90 mr-2" mat-list-icon>login</mat-icon>
            <div class="d-flex align-items-center w-100">
              <div style="max-width: 28%;">
                <div mat-line>
                  <b>{{loc.amt}}</b> units
                </div>
                <div class="small">
                  <div *ngIf="loc.loc.workOrder" class="mr-1 badge badge-sm badge-info small">
                    WO#: {{ loc.loc.workOrder.workOrderNumber }}
                  </div>
                  <div *ngIf="loc.loc.purchaseOrder" class="mr-1 badge badge-sm badge-success small">
                    {{ loc.loc.purchaseOrder.purchaseOrderNumber }}
                  </div>
                  <div *ngIf="loc.loc.rmaTicket" class="mr-1 badge badge-sm badge-danger small">
                    {{ loc.loc.rmaTicket.rmaNumber }}
                  </div>
                </div>
              </div>
              <div class="ml-1">
                <div mat-line>
                  from
                  <span class="badge badge-primary">
                    <ng-container *ngFor="let crumb of loc.breadcrumbs; last as last">
                      {{ crumb }}{{ !last ? ', ' : '' }}
                    </ng-container>
                  </span>
                </div>
              </div>
              <mat-icon class="ml-auto mr-1 text-danger" *ngIf="getWarnings(loc.loc).length > 0">warning</mat-icon>
              <div class="text-danger small mr-2" *ngIf="getWarnings(loc.loc).length > 0">
                <div *ngIf="getWarnings(loc.loc).includes('workOrder')">Marked for other WO.</div>
                <div *ngIf="getWarnings(loc.loc).includes('rma')">RMA # should match.</div>
              </div>
              <button mat-stroked-button class="ml-auto" (click)="clearLocation(loc.loc)">
                <mat-icon matPrefix>close</mat-icon>
                Clear
              </button>
            </div>
          </mat-list-item>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-list>
    </div>
  </div>
</div>