<div class="d-flex position-relative w-100 h-100-t" *ngIf="data$ | async as data">
  <!-- <button [matMenuTriggerFor]="filterMenu" mat-icon-button class="text-muted mr-2">
    <mat-icon svgIcon="format-list-group"></mat-icon>
  </button> -->
  <mat-menu #filterMenu >
    <div class="font-weight-medium mb-2 px-3 pt-1">Filter</div>
    <button mat-menu-item *ngLet="purchasedItemsToggled | async as pi" (click)="purchasedItemsToggled.next(!pi)">
      <mat-checkbox class="mat-checkbox-readonly mat-checkbox-no-margin mr-2" [checked]="pi"></mat-checkbox>
      <span>Show All Purchased Items</span>
    </button>
    <button mat-menu-item *ngLet="subassembliesToggled | async as pi" (click)="subassembliesToggled.next(!pi)">
      <mat-checkbox class="mat-checkbox-readonly mat-checkbox-no-margin mr-2" [checked]="pi"></mat-checkbox>
      <span>Show All Subassemblies</span>
    </button>
    <button mat-menu-item *ngLet="workflowToggled | async as pi" (click)="workflowToggled.next(!pi)">
      <mat-checkbox class="mat-checkbox-readonly mat-checkbox-no-margin mr-2" [checked]="pi"></mat-checkbox>
      <span>Show All Workflow Steps</span>
    </button>
  </mat-menu>
  <ng-container *ngLet="dataByItem$ | async as items">
    <highcharts-chart [runOutsideAngular]="true" [Highcharts]="Highcharts" [constructorType]="'ganttChart'" [options]="chartOptions$ | async" style="width: 100%; height: 100%;" (chartInstance)="chart = $event"></highcharts-chart>
  </ng-container>
</div>