import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange, MatSlideToggle } from '@angular/material/slide-toggle';
import { NavigationService } from '../../../common/services/navigation.service';
import { PurchasingService } from '../../services/purchasing.service';
import { UtilityService } from '../../../common/services/utility.service';
import { UserService } from '../../../common/services/user.service';
import { Material } from '../../../order/resources/material';
import { WorkOrder } from '../../../planning/resources/work-order';
import { GlobalReq } from '../../resources/globalReqsReoprt';
import { MaterialBid, BulkBid, BulkBidStatus } from '../../resources/materialBid';
import { Router, ActivatedRoute } from '@angular/router';
import { Vendor } from '../../../supplier/resources/vendor';
import { Product } from '../../../order/resources/product';
import { MaterialBidService } from '../../services/material-bid.service';
import { DatePickerComponent } from '../../../common/components/date-picker/date-picker.component';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { Requisition } from '../../resources/requisition';
import { Station } from '../../../order/resources/station';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { GlobalReqsBase, ReqItem } from '../global-reqs/global-reqs-base';


type Omit<T, K extends string | number | symbol> = { [P in Exclude<keyof T, K>]: T[P]; };

@Component({
  selector: 'bulk-quotes',
  templateUrl: './bulk-quotes.component.html',
  styleUrls: ['./bulk-quotes.component.less']
})
export class BulkQuotesComponent extends GlobalReqsBase implements OnInit {
  @Input() id: string;
  public saving: boolean = false;
  public showEditor: string = null;
  public record: BulkBid = null;
  public originalRecord: BulkBid = null;
  public editing: boolean = false;
  public showVendorOnly: boolean = true;
  public originalData: GlobalReq[] = null;
  private allFilters: string[] = ['materials', 'outsourced', 'purchases'];
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private navService: NavigationService, private route: ActivatedRoute, private materialBidSvc: MaterialBidService, private purchasingSvc: PurchasingService, private utilitySvc: UtilityService, private usrService: UserService, private router: Router) {
    super(purchasingSvc);
    navService.setPageTitle("Bulk Quote Detail");
    this.id = this.route.snapshot.paramMap.get('id');
  }

  public closeSideNav(): void {
    if (this.showEditor === 'workOrder') {
      // refresh data without resetting progress
      this.purchasingSvc.globalReqs().subscribe(d => {
        this.originalData = d;
      });
    } else {
      this.getDetail();
    }
    this.showEditor = null;
    this.sidenav.close();
    this.navService.popBreadCrumb();
  }

  public async getDetail() {
    super.getDetail();
    this.navService.clearBreadCrumbs();

    if (this.id == 'new') {
      this.editing = true;
      this.record = <BulkBid>{
        bulkBidId: UtilityService.emptyGuid,
        bids: [],
        status: 0
      }

      this.navService.pushBreadcrumb("New Bulk Quote");
    }
    else {
      this.record = await this.materialBidSvc.getBulkBid(this.id).toPromise();
      this.originalRecord = JSON.parse(JSON.stringify(this.record));
      this.navService.pushBreadcrumb(this.record.bidNumber);
    }

  }

  public toggleEditing(): void {
    if (this.editing && this.id == 'new') {
      this.router.navigate(['/purchasing/bulkquotes']);
      return;
    }
    this.editing = !this.editing;
  }
  
  public openDocumentWindow(): void {
    if (this.record.vendor == null) {

      this.utilitySvc.showAlert("A Vendor is Required", "<p>Please make sure you select a vendor before continuing.</p>");
      return;
    }
    if (this.record.bids.length == 0) {

      this.utilitySvc.showAlert("Work Orders Required", "<p>Please make sure you select at least one Work Order for this bid before continuing.</p>");
      return;
    }

    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.sidenav.toggle();
  }

  public async addDocuments(documents: VirtualDocument[]) {
    this.saving = true;
    await this.doSave();
    this.materialBidSvc.addBulkBidDocuments(this.record, documents).subscribe(_ => {
      this.saving = false;
      this.getDetail();
    });
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.materialBidSvc.removeBulkQuoteDocument(this.record, document).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  public toggleReq(e: MatCheckboxChange, q: HTMLInputElement, req: GlobalReq): void {
    q.value = e.checked ? req.qty.toString() : '0';

    const { workOrder, requisition } = req;

    var bid = this.getBidFor(req);
    if (bid == null) {
      //Add it
      this.record.bids.push(<MaterialBid>{
        materialBidId: UtilityService.emptyGuid,
        workOrderId: workOrder && workOrder.workOrderId,
        orderId: workOrder && workOrder.order && workOrder.order.orderSegmentId,
        productId: workOrder && workOrder.productId,
        requisitionId: requisition && requisition.requisitionId,
        vendorId: this.record.vendorId,
        qty: 0, //will be set below...
        asked: new Date(),
        stationId: req.stationId,
        materialId: req.materialId,
        purchasedItemId: req.purchasedItemId,
        bulkBidId: this.record.bulkBidId,
        note: '',
        isVerbal: false
      });
    }
    else {
      //Remove it

      this.removeBid(req);
    }

    this.setQty(q, req);
  }

  private removeBid(req: GlobalReq): void {
    var bid = this.getBidFor(req);
    if (bid == null) return;

    this.record.bids.splice(this.record.bids.indexOf(bid), 1);
  }

  public getBidFor(req: GlobalReq): MaterialBid {
    return this.record.bids.find(b => b.workOrderId == req.workOrderId && b.requisitionId == req.requisitionId && b.stationId == req.stationId && b.materialId == req.materialId && b.purchasedItemId == req.purchasedItemId);
  }

  public setQty(q: HTMLInputElement, req: GlobalReq): void {
    var bid = this.getBidFor(req);
    if (bid == null) return;
    bid.qty = parseInt(q.value);
  }

  public setQuote(q: HTMLInputElement, req: GlobalReq): void {
    var bid = this.getBidFor(req);
    if (bid == null) return;
    bid.totalBid = parseFloat(q.value);
  }

  public setLeadTime(q: HTMLInputElement, req: GlobalReq): void {
    var bid = this.getBidFor(req);
    if (bid == null) return;
    bid.leadTimeDays = parseInt(q.value);
  }

  public setRequiredDate(q: DatePickerComponent, req: GlobalReq): void {
    var bid = this.getBidFor(req);
    if (bid == null) return;
    if (bid.requiredBy != q.date)
      bid.requiredBy = q.date;
  }

  public getSelectedWOCount(item: ReqItem): number {
    return this.record.bids.filter(b => (this.isMaterial(item) && item.materialId === b.materialId) ||
    (this.isStation(item) && item.stationId === b.stationId) ||
    (this.isPurchasedItem(item) && item.purchasedItemId === b.purchasedItemId)).length;
  }

  public getSelectedQty(item: ReqItem): number {
    var quantities = this.record.bids
      .filter(b => (this.isMaterial(item) && item.materialId === b.materialId) ||
      (this.isStation(item) && item.stationId === b.stationId) ||
      (this.isPurchasedItem(item) && item.purchasedItemId === b.purchasedItemId))
      .map(b => b.qty);

    if (quantities.length == 0) return 0;

    return quantities.reduce((total, curent) => total += curent);
  }

  public getTotalQty(reqs: GlobalReq[]): number {
    return reqs.reduce((total, req) => total += req.qty, 0);
  }

  public vendorCanDo(typ: string): boolean {
    switch (typ) {
      case 'materials':
        return this.record.vendor.vendorMaterialGroups && this.record.vendor.vendorMaterialGroups.filter(g => g.materialGroup.groupName != 'Hardware').length > 0;
      case 'purchases':
        return this.record.vendor.vendorMaterialGroups && this.record.vendor.vendorMaterialGroups.find(g => g.materialGroup.groupName == 'Hardware') != null;
      case 'outsourced':
        return this.record.vendor.vendorServiceOfferings && this.record.vendor.vendorServiceOfferings.length > 0;
      default:
        return false;
    }
  }

  
  public vendorCanDoMaterial(materialGroupId: string): boolean {
    return this.record.vendor.vendorMaterialGroups && this.record.vendor.vendorMaterialGroups.find(g => g.materialGroup.materialGroupId == materialGroupId) != null;
  }

  public vendorCanDoItem(item: ReqItem) {
    if (this.isMaterial(item)) {
      return this.record.vendor.vendorMaterialGroups && this.record.vendor.vendorMaterialGroups.find(g => g.materialGroup.materialGroupId == item.materialGroupId) != null;
    } else
    {
      // todo for when we have stricter vendor capability detection
      return true;
    }
  }

  public get filteredData() {
    return this.processedData.filter(i => 
      this.isSelected(i.type) && (!this.showVendorOnly || this.vendorCanDo(i.type)) && (this.record.status === BulkBidStatus.IN_PROCESS || this.getSelectedWOCount(i.item))
    ) || [];
  }

  public get materials() {
    return this.filteredData.filter(x => x.type === 'materials');
  }

  public get purchases() {
    return this.filteredData.filter(x => x.type === 'purchases');
  }

  public get outsourced() {
    return this.filteredData.filter(x => x.type === 'outsourced');
  }

  public async saveChanges() {

    const dialogRef = this.utilitySvc.dialogService.open(BulkQuoteFinishingDialog, { disableClose: true });

    dialogRef.afterClosed().subscribe(async (result) => {

      this.record.status = result.createPo ? BulkBidStatus.PO_CREATED : result.status;      

      this.saving = true;
      //Save BulkBid record
      await this.doSave();
      this.saving = false;

      if (result.createPo) {
        this.saving = true;
        this.purchasingSvc.promoteBulkBidToPO(this.record).subscribe(r => {
          this.saving = false;
          if (r != null) {
            this.utilitySvc.showConfirmation("PO Created Successfully", "<p>Click 'Okay' to go to the new PO, or click 'Cancel' to continue in the bid.", res => {
              if (res) {
                this.router.navigate(['/purchasing/order', r.purchaseOrderId]);
              }
            });
          }
          else {
            this.utilitySvc.showAlert("PO Creation Error","<p>Sorry, but there seems to have been an error in creating your PO.</p>");
          }
        });
      }

      this.toggleEditing();
      //Load BulkBid object
      this.getDetail();
    });

  }

  private async doSave() {
    var result = await this.materialBidSvc.saveBulkBid(this.record).toPromise();

    var removedBids: MaterialBid[] = [];

    if (this.originalRecord) {
      removedBids = this.originalRecord.bids.filter(b => this.record.bids.map(r => r.materialBidId).find(i => i == b.materialBidId) == null);
    }

    if (this.record.bulkBidId != result.bulkBidId) {
      this.id = result.bulkBidId;
      this.record.bulkBidId = result.bulkBidId;

      this.record.bids.forEach(b => { b.bulkBidId = this.id; });

      this.router.navigate(['/purchasing/bulkquote', this.id]);
    }

    removedBids.forEach(b => { b.bulkBidId = null; });
    this.record.bids.concat(removedBids);

    await this.materialBidSvc.saveAllBids(this.record.bids);
  }

  public setVendor(v: Vendor): void {
    this.record.vendorId = v.vendorId;
    this.record.vendor = v;

    if (this.showVendorOnly) this.setVendorCapableFilters();
  }

  public addVendor(): void {
    this.showEditor = "addVendor";
    this.navService.pushBreadcrumb("Add Vendor");
    this.sidenav.toggle();
  }

  public toggleView(e: boolean) {
    this.showVendorOnly = e;

    if (!e) {
      this.filters = JSON.parse(JSON.stringify(this.allFilters));
    }
    else {
      this.setVendorCapableFilters();
    }
  }

  private setVendorCapableFilters(): void {
    this.filters = [];
    for (var typ of this.allFilters) {
      if (this.vendorCanDo(typ)) {
        this.filters.push(typ);
      }
    }

  }

  public setNote(note: any): void {
    this.record.bids.forEach(b => b.note = note.value);
  }

  public isSelected(typ: string): boolean {
    return this.id != 'new' 
    || this.filters.indexOf(typ) >= 0;
  }

  public setSelection(event: MatCheckboxChange, typ: string): void {
    if (event.checked) {
      if (!this.filters.includes(typ))
        this.filters.push(typ);
    }
    else {
      if (this.filters.includes(typ)) {
        this.filters.splice(this.filters.indexOf(typ), 1);
      }
    }

  }

  public getMaterialName(m: Material): string {
    return Material.generatedName(m);
  }

  public selectedOrderOrReqId: string = null;
  public selectedQuoteType: "order" | "requisition";

  public openRelevantOrder(item: GlobalReq) {
    if (item.workOrder !== null) this.router.navigate(['/purchasing/quote', item.workOrder.order.orderSegmentId]);
    else if (item.requisition !== null) this.router.navigate(['/requisition', item.requisition.requisitionId]);
  }

  public openRequisition(req: Requisition): void {
    this.selectedOrderOrReqId = req.requisitionId;
    this.selectedQuoteType = "requisition";
    this.showEditor = 'workOrder';
    this.sidenav.toggle();
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public makeDate(dateStr: any): Date {
    if (dateStr == null || dateStr.length == 0) return null;
    if (!!dateStr.getDay || dateStr == new Date(dateStr)) return dateStr;
    return new Date(dateStr);
  }
  
  ngOnInit() {
    this.getDetail();
  }
}

@Component({
  selector: 'bulk-quotes-finishing',
  templateUrl: 'bulk-quotes-finishing.dialog.html',
})
export class BulkQuoteFinishingDialog {
  public status: number = 0;
  public createPo: boolean = false;
}
