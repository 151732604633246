import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Department } from '../../../floor/resources/building';
import { FloorService } from '../../../floor/services/floor.service';

@Component({
  selector: 'department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.less']
})
export class DepartmentListComponent implements OnInit {
  public departmentList: Department[] = null;
  @Output() selected: EventEmitter<Department> = new EventEmitter<Department>();

  constructor(private floorSvc: FloorService) { }

  private async loadData() {
    const depts = await this.floorSvc.search('').toPromise();
    this.departmentList = depts.results;
  }

  public select(bld: Department): void {
    this.selected.emit(bld);
  }

  ngOnInit(): void {
    this.loadData();
    this.floorSvc.departmentsUpdated.asObservable().subscribe(_ => this.loadData());
  }
}
