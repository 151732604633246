import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, forwardRef } from '@angular/core';
import { Customer } from '../../../customer/resources/customer';
import { CustomerService } from '../../../customer/services/customer.service';
import { Building } from '../../../floor/resources/building';
import { FloorService } from '../../../floor/services/floor.service';
import { PurchaseOrder } from '../../resources/purchaseOrder';
import { GenericSearchComponent } from '../../../common/components/generic-search/generic-search.component';
import { map } from 'rxjs/operators'
import { UserService } from '../../../common/services/user.service';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
  selector: 'po-address-selector',
  templateUrl: './po-address-selector.component.html',
  styleUrls: ['./po-address-selector.component.less']
})
export class PoAddressSelectorComponent implements OnInit {

  @Input() purchaseOrder: PurchaseOrder;
  @Input() editing: boolean;

  public customerLoading = false;


  constructor(private customerService: CustomerService) {

  }

  public async customerChange(c: Customer) {

    this.customerLoading = true;
    try {
      const customer = await this.customerService.getDetail(c.customerId).toPromise();
      this.purchaseOrder.destinationCustomerId = customer.customerId;
      this.purchaseOrder.destinationCustomer = customer;
      this.purchaseOrder.destinationCustomerAddressId = null;
      this.purchaseOrder.destinationCustomerAddress = null;

      if (customer.customerAddresses.length === 1) {
        this.purchaseOrder.destinationCustomerAddressId = customer.customerAddresses[0].customerAddressId;
        this.purchaseOrder.destinationCustomerAddress = customer.customerAddresses[0];
      }

    } catch (e) {
    } finally {
      this.customerLoading = false;
    }

    
  }

  public setCustomerAddress(id: string) {
    this.purchaseOrder.destinationCustomerAddress = this.purchaseOrder.destinationCustomer &&
    this.purchaseOrder.destinationCustomer.customerAddresses.find(ca => ca.customerAddressId === id);
  }

  get activeAddressAttn(): string {
    if (this.purchaseOrder.destinationType === 0)
      return null;
    else if (this.purchaseOrder.destinationType === 1)
      return this.purchaseOrder.destinationBuilding && "Anaheim Precision Manufacturing";
    else if (this.purchaseOrder.destinationType === 2)
      return this.purchaseOrder.destinationCustomer && this.purchaseOrder.destinationCustomer.businessName;
  }

  get activeAddress() {
    if (this.purchaseOrder.destinationType === 0) 
      return null;
    else if (this.purchaseOrder.destinationType === 1) 
      return this.purchaseOrder.destinationBuilding && this.purchaseOrder.destinationBuilding.address;
    else if (this.purchaseOrder.destinationType === 2) 
      return this.purchaseOrder.destinationCustomerAddress && this.purchaseOrder.destinationCustomerAddress.address;
  }

  ngOnInit() {
  }

}


@Component({
  selector: 'building-select',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../common/components/generic-search/generic-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingSelectComponent),
      multi: true
    }
  ]
})
export class BuildingSelectComponent extends GenericSearchComponent<Building> {

  @Input() placeholder = 'Building';

  constructor(private floorService: FloorService, private userSvc: UserService, @Inject(Injector) injector: Injector) {
    super(injector)
  }

  doSearch(searchText: string) {
    return this.floorService.searchBuildings(searchText).pipe(
      map((v) => v.results)
    )
  }

  public canAdd(): boolean {
    return this.userSvc.canAccess('ShippingUser')
  }

  public get addItemText(): string {
     return 'Add Vendor';
  }

  public get noItemsText(): string {
    return this.canAdd() ? 'Still not seeing your vendor?' : 'No vendors found.';
  }

  public getSearchField(v: Building): string {
    return v && v.name;
  }
}