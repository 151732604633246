<a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

<div class="d-flex flex-column p-3" style="min-height: calc(92vh - 32px);">
  <div style="margin-bottom: 16px">
    <mat-card class="border mat-elevation-z0">
      <div class="row">
        <div class="col-4">
          <mat-card-title>Customer RMA details</mat-card-title>
          <mat-card-content>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>RMA Number</label>
                  <div class="ticket-info">{{ ticket.rmaNumber }}</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Customer</label>
                  <div class="ticket-info">{{ ticket.customer.businessName }}</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Work Order Number</label>
                  <div class="ticket-info">{{ ticket.workOrder?.workOrderNumber }}</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Customer PO Number</label>
                  <div class="ticket-info">{{ ticket.customerPurchaseOrder.purchaseOrderNumber }}</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Rejection Number</label>
                  <div class="ticket-info">{{ ticket.customerRejectionNumber }}</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Date Submitted</label>
                  <div class="ticket-info">{{ ticket.createdDate | date: 'MM/dd/yyyy' }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Return Reason</label>
                  <div class="ticket-info">{{ ticket.returnReason }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Documents and Photos</label>
                  <div class="ticket-info">
                    <file-display-grid
                      [files]="ticket.documents"
                      [editable]="false"
                    ></file-display-grid>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
        <div class="col-4" *ngIf="lot">
          <mat-card-title>Lot-specific details</mat-card-title>
          <div class="mat-card-content">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Lot Notes</label>
                  <div class="ticket-info">{{ lot.notes }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Lot Documents</label>
                  <div class="ticket-info">
                    <file-display-grid
                      [files]="lot?.documents"
                      [editable]="false"
                    ></file-display-grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <mat-card-title>Other RMA Lots</mat-card-title>
          <mat-action-list class="border rounded p-0">
            <mat-list-item disabled class="text-center text-muted" *ngIf="otherLots.length === 0">
              No other lots.
            </mat-list-item>
            <mat-list-item (click)="goToOrder(lotOrders[lot.rmaDispositionLotId].orderSegmentId)"  *ngFor="let lot of otherLots">
              <span class="mr-auto"><b>{{lot.quantity}}</b> items</span>
              <a class="d-block text-dark" *ngIf="lotOrders[lot.rmaDispositionLotId] && lotOrders[lot.rmaDispositionLotId] !== 'loading'">
                <b>{{lotOrders[lot.rmaDispositionLotId].orderNumber}}</b>
                <span class="ml-1 badge status text-uppercase" [ngClass]="getStatusColorClass(lotOrders[lot.rmaDispositionLotId].status)">
                  {{getStatusText(lotOrders[lot.rmaDispositionLotId].status)}}
                </span>
                <i class="fas fa-fw fa-lg fa-external-link-alt text-underline pl-2"></i>
              </a>
              <div *ngIf="lotOrders[lot.rmaDispositionLotId] === 'loading'">
                <i class="fa fa-lg fa-spin fa-spinner text-primary"></i>
              </div>
              <div *ngIf="!lotOrders[lot.rmaDispositionLotId]">
                <span class="text-muted small-font-italic">No orders found for this lot.</span>
              </div>
            </mat-list-item>
          </mat-action-list>
        </div>
      </div>
    </mat-card>
  </div>
</div>