import { OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Material } from "../../../order/resources/material";
import { PurchasedItem } from "../../../order/resources/purchased-item";
import { Station } from "../../../order/resources/station";
import { WorkOrder } from "../../../planning/resources/work-order";
import { GlobalReq } from "../../resources/globalReqsReoprt";
import { Requisition } from "../../resources/requisition";
import { PurchasingService } from "../../services/purchasing.service";

export type ReqItem = Material | PurchasedItem | Station;


export abstract class GlobalReqsBase {
  public data: GlobalReq[] = null;
  public filters: string[] = ['materials', 'outsourced', 'purchases'];

  public isWorkOrder(o: WorkOrder | Requisition): o is WorkOrder {
    return o.hasOwnProperty('workOrderId');
  }
  
  public isRequisition(o: WorkOrder | Requisition): o is Requisition {
    return o.hasOwnProperty('requisitionId');
  }
  
  public isMaterial(i: ReqItem): i is Material {
    return i.hasOwnProperty('materialId');
  }
  public isPurchasedItem(i: ReqItem): i is PurchasedItem {
    return i.hasOwnProperty('purchasedItemId');
  }
  
  public isStation(i: ReqItem): i is Station {
    return i.hasOwnProperty('stationId');
  }

  public processedData: {
    item: ReqItem;
    type: 'materials' | 'outsourced' | 'purchases';
    isOpen: boolean;
    data: (GlobalReq & { isOpen?: boolean })[];
  }[] = []

  constructor(private psvc: PurchasingService) {
  }


  public getDetail(): void {
    this.data = null;

    this.psvc.globalReqs().subscribe(d => {
      this.data = d;
      if (this.data) {
        this.processedData = this.data
        .reduce((acc, i) => {
          var existing = acc.find(e => 
            (this.isMaterial(e.item) && e.item.materialId === i.materialId) ||
            (this.isStation(e.item) && e.item.stationId === i.stationId) ||
            (this.isPurchasedItem(e.item) && e.item.purchasedItemId === i.purchasedItemId)
          );
          if (existing) existing.data = [...existing.data, i];
          else {
            let item: ReqItem;
            let type: 'materials' | 'outsourced' | 'purchases';
            if (i.material !== null) {
              item = i.material;
              type = 'materials';
            }
            if (i.station !== null) {
              item = i.station;
              type = 'outsourced';
            }
            if (i.purchasedItem !== null) {
              item = i.purchasedItem;
              type = 'purchases'
            }
            acc.push({ 
              item, type,
              isOpen: false, 
              data: [i] }
            );
          }
          return acc;
        }, [] as GlobalReqsBase['processedData'])

      }
    });
  }

  public get filteredData() {
    return this.processedData.filter(i => 
      this.isSelected(i.type)
    );
  }

  public isSelected(typ: string): boolean {
    return this.filters.indexOf(typ) >= 0;
  }

  public setSelection(event: MatCheckboxChange, typ: string): void {
    if (event.checked) {
      if (!this.filters.includes(typ))
        this.filters.push(typ);
    }
    else {
      if (this.filters.includes(typ)) {
        this.filters.splice(this.filters.indexOf(typ), 1);
      }
    }
  }

  public getMaterialName(m: Material): string {
    return Material.generatedName(m);
  }


  public getItemName(item: ReqItem): string {
    if (this.isMaterial(item)) {
      return this.getMaterialName(item)
    } else if (this.isStation(item)) {
      return item.name;
    } else if (this.isPurchasedItem(item)) {
      return item.description;
    } 
  }

  public getReqNumber(item: GlobalReq): string {
    if (item.workOrder !== null) return item.workOrder.workOrderNumber;
    else if (item.requisition !== null) return item.requisition.requisitionNumber;
  }

}