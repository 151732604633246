import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MaterialService } from '../../../order/services/material.service';
import { MaterialSpecification, MaterialMaterialSpecification } from '../../../order/resources/material';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'material-specification-select',
  templateUrl: './material-specification-select.component.html',
  styleUrls: ['./material-specification-select.component.less']
})
export class MaterialSpecificationSelectComponent implements OnInit {
  public allMaterialSpecifications: MaterialSpecification[];
  @Input() selectedMaterialMaterialSpecifications: MaterialMaterialSpecification[];
  @Input() editable: boolean;
  @ViewChild('materialInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() materialSpecificationChange: EventEmitter<MaterialMaterialSpecification[]> = new EventEmitter<MaterialMaterialSpecification[]>();
  @Output() newSpec: EventEmitter<string> = new EventEmitter<string>();
  public separatorKeyCodes: number[] = [ENTER, COMMA];

  constructor(private materialService: MaterialService) { }

  public getMaterialSpecList(_event: KeyboardEvent) {
    this.materialService.getMaterialSpecifications(this.inputTxt.nativeElement.value)
      .subscribe(result => {
        let allResults = result.results;
        let filteredResults = allResults.filter((resSpec) => {
          // all results are valid if none are selected
          if (!this.selectedMaterialMaterialSpecifications) {
            return true;
          }

          // only return results not already selected
          return !this.selectedMaterialMaterialSpecifications.some(selSpec => selSpec.materialSpecification.materialSpecificationId === resSpec.materialSpecificationId)
        }, this);
        this.allMaterialSpecifications = filteredResults;
      });
  }

  public toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getMaterialSpecList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public onAdd(name: string): void {
    event.stopImmediatePropagation();
    this.newSpec.emit(name);
    this.autoCompleteTrigger.closePanel();
  }

  public addSpec(m: MaterialSpecification) {
    if (m == null)
      return;

    if (typeof (this.selectedMaterialMaterialSpecifications) === 'undefined') {
      this.selectedMaterialMaterialSpecifications = [];
    }

    // Create a new MMS. Material is filled in on the parent component while saving
    let materialMaterialSpec: MaterialMaterialSpecification = {
      materialMaterialSpecificationId: UtilityService.emptyGuid,
      material: null,
      materialId: null,
      materialSpecification: m,
      materialSpecificationId: m.materialSpecificationId,
    }

    this.selectedMaterialMaterialSpecifications.push(materialMaterialSpec);
    this.materialSpecificationChange.emit(this.selectedMaterialMaterialSpecifications);
    this.inputTxt.nativeElement.value = "";
  }

  public removeSpec(m: MaterialMaterialSpecification) {
    let index = this.selectedMaterialMaterialSpecifications.findIndex(spec => spec.materialSpecificationId === m.materialSpecificationId);
    this.selectedMaterialMaterialSpecifications.splice(index, 1);
  }

  public testSelection(_: any): void {
    // Check if this field is valid when it loses focus.
    let checkName = this.inputTxt.nativeElement.value;
    let match = [];

    if (this.allMaterialSpecifications && this.allMaterialSpecifications.length > 0) {
      match = this.allMaterialSpecifications.filter(spec => spec.name === checkName);
    }

    if (match.length === 0) {
      this.inputTxt.nativeElement.value = "";
      return;
    }

    this.addSpec(match[0]);
  }

  ngOnInit(): void {
  }
}
