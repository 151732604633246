import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { tap, debounceTime } from 'rxjs/operators';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { QualityClause } from '../../../supplier/resources/quality-clause';
import { VendorService } from '../../../supplier/services/vendor.service';

@Component({
  selector: 'qc-clause-editor',
  templateUrl: './qc-clause-editor.component.html',
  styleUrls: ['./qc-clause-editor.component.less']
})
export class QcClauseEditorComponent implements OnInit {

  public loading = false;
  public saving = false;

  public selectedItem: QualityClause | null;
  public items: QualityClause[] = [];

  public searching = false;
  canScroll = true;
  currentPageIndex = 0;

  public filter = '';

  private oldItemBackup: QualityClause | null;

  @ViewChild('itemForm') itemForm: NgForm;
  @Input() sidenav: MatSidenav;

  constructor(public service: VendorService, public util: UtilityService, public navService: NavigationService, private router: Router) { }

  public async close() {
    if ((this.itemForm && this.itemForm.dirty) || (this.selectedItem && this.selectedItem.qualityClauseId) === UtilityService.emptyGuid) {
      const confirm = await this.util.showConfirmationPromise('Exit?', 'Are you sure you want to exit the editor? You will lose any unsaved changes.');
      if (!confirm) return;
    }
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public async onSearch() {
    this.searching = true;
    const { results, resultCount } = await this.service.searchQualityClauses(this.filter, 0, 30).toPromise();
    this.searching = false;
    this.items = results;
    this.currentPageIndex = 0;

    this.canScroll = this.items.length < resultCount;
  }

  public async onListScroll(e: Event) {

    const panel = e.target as HTMLUListElement;
    const maxScrollPosition = panel.scrollHeight - panel.clientHeight;
    const scrollThreshold = maxScrollPosition - 80;
    if (panel.scrollTop > scrollThreshold && !this.searching && this.canScroll) {
      this.currentPageIndex += 1;
      this.searching = true;
      const { results, resultCount } = await this.service.searchQualityClauses(this.filter, this.currentPageIndex, 30).toPromise();
      this.searching = false;

      this.items = this.items.concat(results);

      this.canScroll = this.items.length < resultCount;
    }
  }

  public resetForm() {
    if (this.itemForm && this.itemForm.form) {
      this.itemForm.form.markAsPristine();
      this.itemForm.form.markAsUntouched();
    }
  }

  public async setSelectedItem(item: QualityClause) {
    if ((this.itemForm && this.itemForm.dirty) || (this.selectedItem && this.selectedItem.qualityClauseId) === UtilityService.emptyGuid) {
      const confirm = await this.util.showConfirmationPromise('Exit?', 'Are you sure you want to exit the current item? You will lose any unsaved changes.');
      if (!confirm) {
        return;
      } else {
        this.items[
          this.items.findIndex(x => x.qualityClauseId == this.selectedItem.qualityClauseId)
        ] = this.oldItemBackup;
      };
    }
    this.loading = true;
    this.selectedItem = item;
    this.oldItemBackup = JSON.parse(JSON.stringify(item));
    this.resetForm();
    this.navService.popBreadCrumb();
    this.navService.pushBreadcrumb(item.number);
    this.loading = false;
  };

  public async saveSelected() {
    if (!this.selectedItem) return;
    this.saving = true;
    const isNew = this.selectedItem.qualityClauseId === UtilityService.emptyGuid;
    const item = await this.service.saveQualityClause(this.selectedItem).toPromise();
    if (isNew) {
      this.filter = '';
      await this.onSearch();
    }
    this.saving = false;
    this.items[
      this.items.findIndex(x => x.qualityClauseId == item.qualityClauseId)
    ] = item;
    this.selectedItem = item;
    this.resetForm();
  }

  public async deleteSelected() {
    if (!this.selectedItem) return;
    const deletedId = this.selectedItem.qualityClauseId;

    const confirm = await this.util.showConfirmationPromise('Delete Clause?', 'Are you sure? This cannot be undone.');

    if (!confirm) return;

    this.saving = true;

    await this.service.removeQualityClause(deletedId).toPromise();
    this.saving = false;
    this.items = this.items.filter(x => x.qualityClauseId !== deletedId);
    this.selectedItem = null;
  }

  public createNewItem() {
    const item = {
      qualityClauseId: UtilityService.emptyGuid,
      number: '',
      title: '',
      text: '',
      hardwareDefault: false,
      materialDefault: false,
      processDefault: false,
    };
    this.setSelectedItem(item);
  }

  public get isCreating() {
    return this.selectedItem && this.selectedItem.qualityClauseId === UtilityService.emptyGuid;
  }

  public get canSave() {
    return this.selectedItem && this.selectedItem.number;
  }

  ngOnInit() {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Quality Clause Editor");
    this.onSearch();
  }

  @ViewChild('filterModel', { static: true }) filterModel: NgModel;
  ngAfterViewInit() {
    if (!this.filterModel || !this.filterModel.valueChanges) return;
    this.filterModel.valueChanges.pipe(
      tap(() => this.searching = true),
      debounceTime(250)
    ).subscribe(() => this.onSearch());
  }

}
