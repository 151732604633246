import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Order, QuoteLineItem } from '../../resources/order';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'quote-items-table',
  templateUrl: './quote-items-table.component.html',
  styleUrls: ['./quote-items-table.component.less']
})
export class QuoteItemsTableComponent implements OnInit {

  @Input() record: Order;
  @ViewChild('dataTable') dataTable: MatTable<any>;

  public isLoading = false;

  constructor(private orderService: OrderService) { }

  public get lineItems() {
    return this.record.lineItems
  }

  async ngOnInit() {
    // if no line items something is probably wrong/data is old, so make a call to refresh it
    // also do this if any ext price does not match unit price
    if (!this.record.lineItems || this.record.lineItems.length === 0) {
      const items = await this.orderService.resetQuoteLineItems(this.record.orderSegmentId).toPromise();
      this.record.lineItems = items;
    }
    // sort
    this.record.lineItems = this.record.lineItems.sort((a, b) => {
      return (a.station ? 1 : 0) - (b.station ? 1 : 0)
    }).sort((a, b) => { return a.product.partNumber.localeCompare(b.product.partNumber) });
    this.dataTable.renderRows();
  }

  public displayedColumns = [
    "description",
    "notes",
    "leadTime",
    "quantity",
    "unitPrice",
    "extPrice",
  ];

  public toWeeks(days: number) {
    return Math.ceil(days / 5)
  }

}
