import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SuppliedProduct } from '../../../purchasing/components/supplier-detail/supplier-detail.component';
import { Vendor, VendorContact } from '../../../supplier/resources/vendor';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { UtilityService } from '../../../common/services/utility.service';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { Order } from '../../../order/resources/order';

@Component({
  selector: 'vendor-contact-screen',
  templateUrl: './vendor-contact-screen.component.html',
  styleUrls: ['./vendor-contact-screen.component.less']
})
export class VendorContactScreenComponent {
  @Input() sidenav: MatSidenav;
  @Input() vendors: Vendor[];
  @Input() typeId: string;
  @Input() order: Order;
  @Input() orderId: string;
  @Input() requisitionId: string;
  @Input() product: SuppliedProduct<any>;
  @ViewChild('insetnav') insetnav: MatSidenav;

  public bidDetailId: string = null;

  public sentTo: string[] = [];
  public sent: MaterialBid[] = [];
  public sending: string[] = [];

  constructor(private quoteService: MaterialBidService, private navService: NavigationService) { }

  public sendToVendor(vendor: Vendor): void {
    this.sending.push(vendor.vendorId);

    var quote = <MaterialBid>{
      materialBidId: UtilityService.emptyGuid,
      orderId: this.orderId,
      requisitionId: this.requisitionId,
      productId: this.product.productId,
      vendorId: vendor.vendorId,
      asked: new Date(),
      purchasedItemId: this.product.item.purchasedItemId,
      materialId: this.product.item.materialId,
      stationId: this.product.item.stationId,
      isVerbal: false
    };

    this.quoteService.save(quote).subscribe(bid => {
      this.sending.splice(this.sending.findIndex(i => i == vendor.vendorId), 1);
      this.sentTo.push(vendor.vendorId);
      this.sent.push(bid);
    });
  }

  public canSend(vendorId: string): boolean {
    return !this.isSending(vendorId) && !this.isSent(vendorId);
  }

  public isSending(vendorId: string): boolean {
    return this.sending.indexOf(vendorId) >= 0;
  }

  public isSent(vendorId: string): boolean {
    return this.sentTo.indexOf(vendorId) >= 0;
  }

  public goToQuote(vendor: Vendor): void {
    this.bidDetailId = this.sent[this.sentTo.indexOf(vendor.vendorId)].materialBidId;

    this.navService.pushBreadcrumb("Quote Detail");
    this.insetnav.toggle();
  }

  public isQuoteContact(contact: VendorContact): boolean {
    return contact.useForQuoting;
  }

  public exit(): void {
    this.sidenav.close();
  }

  public closeSideNav(): void {
    this.bidDetailId = null;
    this.navService.popBreadCrumb();
  }
}
