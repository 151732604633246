<div class="mat-small-header mb-2 px-3">QA Tickets</div>
<mat-list class="pt-0 border-top mb-2">
  <mat-list-item class="border-bottom">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center p-2" style="font-size: 0.9em;">
        <mat-checkbox class="mat-checkbox-display mat-checkbox-no-margin mr-3" [checked]="contractReviewIsDone()"></mat-checkbox>
        <b> Contract Review </b>
        <div class="ml-auto small" *ngIf="contractReviewIsDone()">
          Completed by <b>{{ record.contractReviewTicket.assignedUser_Name ?? 'Unknown User' }}</b> on <b>{{ (record.contractReviewTicket.dateCompleted | date: 'MM/dd/yyyy') ?? 'Unknown Date' }}</b>
        </div>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item class="border-bottom" *ngFor="let ticket of record.receivingTickets">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center p-2" style="font-size: 0.9em;">
        <mat-checkbox class="mat-checkbox-display mat-checkbox-no-margin mr-3" [checked]="ticket.status === 4"></mat-checkbox>
        <b> Receiving Inspection for {{ ticket.inventoryItem_Name }} </b>
        <div class="ml-auto small" *ngIf="ticket.status === 4">
          Completed by <b>{{ ticket.assignedUser_Name ?? 'Unknown User' }}</b> on <b>{{ (ticket.dateCompleted | date: 'MM/dd/yyyy') ?? 'Unknown Date' }}</b>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>

<div class="mat-small-header mb-2 px-3">Step Inspection</div>
<mat-list class="pt-0 border-top">
  <mat-list-item *ngFor="let ticket of inspectionTickets" class="border-bottom">
    <div class="w-100">
      <div class="w-100 d-flex align-items-center p-2" style="font-size: 0.9em;">
        <mat-checkbox class="mat-checkbox-display mat-checkbox-no-margin mr-3" [checked]="isDone(ticket.ticketData)"></mat-checkbox>
        <b>{{ getName(ticket.workflowStep) }} </b>
        <div class="ml-auto small" *ngIf="isDone(ticket.ticketData)">
          Inspected by <b>{{ ticket.ticketData.inspectorName ?? 'Unknown User' }}</b> on <b>{{ (ticket.ticketData.finishedTime | date: 'MM/dd/yyyy') ?? 'Unknown Date' }}</b>
        </div>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="workflow.length === 0" class="prereq-item text-center text-muted">
    This work order has no workflow steps.
  </mat-list-item>
</mat-list>