import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskStatus } from '../../../../common/resources/estimatingtask';
import { EstimateProgressService } from '../../../services/estimate-progress.service';

@Component({
  selector: 'estimating-progress-status',
  templateUrl: './estimating-progress-status.component.html',
  styleUrls: ['./estimating-progress-status.component.less']
})
export class EstimatingProgressStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public getStatusColorClass(status: TaskStatus) {
    return EstimateProgressService.getStatusColorClass(status);
  }

  public getStatusIcon(status: number) {
    return EstimateProgressService.getStatusIcon(status);
  }

  public getStatusText(status: number) {
    return EstimateProgressService.getStatusText(status);
  }

  @Input() label: string;
  @Input() disabled = false;

  @Input() status: TaskStatus;
  @Output() statusChange = new EventEmitter<TaskStatus>();

  public possibleValues = Object.values(TaskStatus).filter(x => typeof x === 'number');

}
