<div>
  <div class="row">

    <loading *ngIf="!department"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <mat-sidenav-container *ngIf="department">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <building-detail [(building)]="selectedBuilding" *ngIf="showEditor=='departmentDetail'" [sidenav]="sidenav"></building-detail>
        <machine-detail *ngIf="showEditor=='machine'" [machineId]="selectedMachineId" [sidenav]="sidenav"></machine-detail>
        <machine-assignment-detail [record]="selectedAssignment" *ngIf="showEditor=='assignment'" [sidenav]="sidenav"></machine-assignment-detail>
        <failed-inspection-review *ngIf="showEditor=='failed-inspection'" [inspectionTicketId]="selectedInspectionTicketId" [sidenav]="sidenav"></failed-inspection-review>
      </mat-sidenav>
      <mat-sidenav-content>

        <div class="row">
          <div class="col-6 bg-secondary pt-2 pl-4">
            <div *ngIf="canEdit()">
              <button class="btn btn-sm btn-light" (click)="toggleEdit()" *ngIf="!editing">
                <i class="fas fa-edit fa-fw"></i>
                Edit
              </button>
              <button class="btn btn-sm btn-success mr-2" (click)="saveChanges()" *ngIf="editing">
                <i class="fas fa-edit fa-fw"></i>
                Save
              </button>
              <button class="btn btn-sm btn-danger" (click)="cancelEdit()" *ngIf="editing">
                <i class="fas fa-times fa-fw"></i>
                Cancel
              </button>
            </div>
          </div>
          <div class="col-6 text-right bg-secondary">
            <div class="btn-group m-2">
              <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{department?.name || "--"}}
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="#" (click)="goOverview()">Overview</a>
                <div class="dropdown-divider"></div>
                <p class="text-center text-muted" *ngIf="!departments">
                  <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
                  Loading...
                </p>
                <p class="text-center text-muted font-italic" *ngIf="departments && departments.length==0">
                  <i class="fas fa-exclamation-triangle fa-fw text-danger"></i>
                  <small>No Buildings Found!</small>
                </p>
                <a *ngFor="let b of departments" [ngClass]="{'active':(department && department.departmentId==b.departmentId)}" href="#" class="dropdown-item" (click)="showBuilding(b)">{{b.name}}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group mt-2 ml-2 mr-2 mb-0">
          <input #name="ngModel" minlength="3" [(ngModel)]="department.name" required placeholder="Building Name" type="text" max="100" class="form-control form-control-lg text-primary" appStaticEdit [editable]="editing" />
        </div>

        <div class="container-fluid mt-3">
          <div class="row">
            <div class="col-9">
              <div class="floor-layout">
                <h1 class="no-ui text-muted display-3" *ngIf="department.floorBuildings.length==0">- No Buildings -</h1>
                <floor-layout-block (click)="selectDepartment(fb)" [block]="fb" [editable]="editing" (onBlockChanged)="setDepartmentUIData(department, $event)" *ngFor="let fb of department.floorBuildings"></floor-layout-block>
              </div>
            </div>
            <div class="col-3">
              <h5 class="w-100 text-center p-2 bg-secondary">Unassigned Jobs</h5>
              <div class="p-1 small mb-4 pb-4">
                <unassigned-jobs-list #unassignedJobsList [department]="department" (assignment)="showAssignmentDetails($event)"></unassigned-jobs-list>
              </div>
              <h5 class="w-100 text-center p-2 bg-secondary">Alerts</h5>
              <div class="p-1 small">
                <filtered-alert-list [departmentId]="department?.departmentId" (selected)="getAlertDetail($event)"></filtered-alert-list>
              </div>
            </div>
          </div>
        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
