<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create New Record" routerLink="/rule-builder-detail/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" (search)="doSearch($event)" [showManagerBar]="false"></search-bar>

    <div *ngIf="!results"><loading></loading></div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('name')">
            Name
            <i [ngClass]="getSortIconFor('name')"></i>
          </th>
          <th (click)="sortBy('description')">
            Description
            <i [ngClass]="getSortIconFor('description')"></i>
          </th>
          <th (click)="sortBy('ruleAction')">
            Rule Action
            <i [ngClass]="getSortIconFor('ruleAction')"></i>
          </th>
          <th>When
          </th>
          <th (click)="sortBy('actionToTake')">
            Action to Take
            <i [ngClass]="getSortIconFor('actionToTake')"></i>
          </th>
          <th>
            
          </th>
          <th (click)="sortBy('isRuleEnabled')">
            Enabled?
            <i [ngClass]="getSortIconFor('isRuleEnabled')"></i>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.name}}</td>
        <td>{{row.description}}</td>
        <td>{{row.ruleAction}}</td>
        <td>{{row.ruleActionWhenValue}} {{row.ruleActionWhenUnit}}</td>
        <td>{{row.actionToTake}}</td>
        <td>{{row.actionToTakeWhenValue}} {{row.actionToTakeWhenUnit!=null ? (row.actionToTake.toUpperCase().includes('SCHEDULE') ? '' : 'per') : '' }} {{row.actionToTakeWhenUnit}}</td>
        <td class="mat-column-enabled"><mat-slide-toggle [checked]="row.enabled==null? false : row.enabled" [disabled]="true"></mat-slide-toggle></td>
      </ng-template>
    </search-result>
  </div>

</div>

