import { User } from "../../common/resources/user"
import { VirtualDocument } from "../../common/resources/virtual-document"
import { Customer } from "../../customer/resources/customer"
import { InventoryItemLocation } from "../../inventory/resources/inventory-item-location"
import { CustomerPurchaseOrder } from "../../order/resources/customer-po"
import { Order } from "../../order/resources/order"
import { Product } from "../../order/resources/product"
import { WorkOrder } from "../../planning/resources/work-order"
import { ShippingTicket } from "../../shipping/resources/shipping-ticket"

export class RMATicket {
    rmaTicketId: string
    status: number
    rejectionNote: string;
    rejectingUserId?: string;
    rejectionTime?: Date;
    customerId: string
    requestNumber: string
    rmaNumber: string
    productId: string
    quantity: number
    receivedQuantity?: number
    customerPurchaseOrderId: string
    workOrderId?: string
    returnReason: string
    requirementsViolated: string
    customerRejectionNumber: string
    createdDate: Date
    notes: string

    debit: boolean;
    debitOverride: boolean;
    debitOverrideAmount?: number;
    additionalRMAFee: number;


    salesPersonId?: string;
    salesPerson: User;
    qualityAssigneeId?: string;
    qualityAssignee: User;

    customer: Customer
    product: Product
    customerPurchaseOrder: CustomerPurchaseOrder;
    workOrder: WorkOrder
    documents: RMATicketDocument[]
    shippingTicket: ShippingTicket;
    inventoryItemLocations: InventoryItemLocation[];
    dispositionLots: RMADispositionLot[];

    rejectingUser?: User;

    isNewDataInput: boolean;
    newDataPONumber: string;
    newDataPartNumber: string;
    newDataWorkOrderNumber: string;

    public static canEdit(status: number): boolean {
        return true;
    }
    
    public static getStatusText(status: number): string {
        switch (status) {
            // await customer
            case 0: return 'Awaiting Customer';
            // in transit
            case 1: return 'In Transit';
            // unassigned
            case 2: return 'Unassigned';
            // await evaluation
            case 3: return 'Awaiting Disposition';
            // await fulfillment
            case 4: return 'Awaiting Fulfilment';
            // complete
            case 5: return 'Completed';
            // await sales review
            case 6: return 'Awaiting Review';
            // await quality review
            case 7: return 'Awaiting Quality Review';
            // rejected
            case 8: return 'Rejected';
            // closed
            case 9: return 'Closed';
            default: return null;
        }
    }

    public static getStatusColorClass(status: number): string {
        switch (status) {
            // await customer
            case 0: return 'badge-info';
            // in transit
            case 1: return 'badge-secondary';
            // unassigned
            case 2: return 'badge-danger';
            // await evaluation
            case 3: return 'badge-warning';
            // await fulfilment
            case 4: return 'badge-info';
            // complete
            case 5: return 'badge-success';
            // await sales review
            case 6: return 'badge-info';
            // await quality review
            case 7: return 'badge-info';
            // rejected
            case 8: return 'badge-danger';
            // closed
            case 9: return 'badge-secondary';
            default: return null;
        }
    }

    public static getStatusBackgroundColorClass(status: number): string {
        switch (status) {
            // await customer
            case 0: return 'bg-info';
            // in transit
            case 1: return 'bg-secondary';
            // unassigned
            case 2: return 'bg-danger';
            // await evaluation
            case 3: return 'bg-warning';
            // await fulfilment
            case 4: return 'bg-info';
            // complete
            case 5: return 'bg-success';
            // await sales review
            case 6: return 'bg-info';
            // await quality review
            case 7: return 'bg-info';
            // rejected
            case 8: return 'bg-danger';
            // closed
            case 9: return 'bg-secondary';
            default: return null;
        }
    }

}

export interface RMATicketDocument {
    rmaTicketId: string;
    documentDocumentId: string;
  
    document?: VirtualDocument;
}

export enum RMADispositionType
{
    OurFault = 0,
    CustomerFault = 1
}
export enum RMARepairType
{
    Repair = 0,
    Rework = 2,
    Scrap = 3,
    UseAsIs = 4,
    ScrapRemake = 1,
}
export interface RMADispositionLot {
    rmaDispositionLotId: string;
    rmaTicketId: string;
    
    quantity: number;

    dispositionType: RMADispositionType;
    repairType: RMARepairType;

    notes: string;

    documents: RMADispositionLotDocument[];
}

export interface RMADispositionLotDocument {
    rmaDispositionLotId: string;
    documentDocumentId: string;
  
    document?: VirtualDocument;
}