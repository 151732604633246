<mat-card style="cursor: unset; height: 59vh" class="border rounded mat-elevation-z0 p-0 quote-accepted-pos">
  <mat-tab-group class="vertical-tabs w-100" *ngIf="record.quotePurchaseOrders.length > 0" style="height: 100%;">
    <mat-tab *ngFor="let po of record.quotePurchaseOrders">
      <ng-template mat-tab-label>
        <mat-icon matPrefix>description</mat-icon>
        <b>PO #{{ po.purchaseOrderNumber }}</b>
        <span class="badge bg-dark text-white ml-1">
          {{ po.lineItems.length }} items
        </span>
      </ng-template>
      <div style="height: 100%;" class="d-flex">
        <div class="col-6" style="overflow-y: scroll; overflow-x: hidden; height: 100%;">
          <div class="py-2 d-flex align-items-center w-100">
            <mat-card-title>PO #{{ po.purchaseOrderNumber }}</mat-card-title>
            <div class="ml-auto badge" style="font-size: 1.2em;" [ngClass]="getStatusColorClass(po.order?.status)">
              {{ getStatusText(po.order?.status) }}
            </div>
          </div>
          <div class="row p-0" ngForm>
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Received On</mat-label>
                <input matInput type="text" [disabled]="true" [value]="po.order?.receivedDate | date: 'shortDate'">
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="w-100 textarea" appearance="outline">
                <mat-label>Ship To</mat-label>
                <textarea
                  matInput
                  [value]="po.shipToAddress ? printAddress(po.shipToAddress) : ''"
                  name="shipToAddress"
                  type="text"
                  rows="6"
                  disabled
                  style="resize: none"
                  ></textarea>
              </mat-form-field>
            </div>
          </div>
          <mat-card-title>
            Quoted Items
          </mat-card-title>
          <table mat-table [dataSource]="sortItems(po.lineItems)" #kl="matTable" style="margin-left: -15px;
          width: calc(100% + 30px) !important;">

            <ng-container matColumnDef="lineItemNumber">
              <th mat-header-cell *matHeaderCellDef>No.</th>
              <td mat-cell *matCellDef="let item" class="font-weight-bold">
                {{
                  item.lineItemNumber
                }}
              </td>
            </ng-container>
        
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> Item </th>
              <td mat-cell *matCellDef="let item" class="font-weight-bold">
                {{
                  printName(item)
                }}
              </td>
            </ng-container>
          
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef>Quantity</th>
              <td mat-cell *matCellDef="let item"> {{item.quantity}} </td>
            </ng-container>
          
            <ng-container matColumnDef="unitPrice">
              <th mat-header-cell *matHeaderCellDef>Unit Price</th>
              <td mat-cell *matCellDef="let item"> {{item.unitPrice | currency }} </td>
            </ng-container>
          
            <ng-container matColumnDef="extPrice">
              <th mat-header-cell *matHeaderCellDef>Ext. Price</th>
              <td mat-cell *matCellDef="let item"> {{item.extPrice | currency }} </td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef>Due Date</th>
              <td mat-cell *matCellDef="let item"> {{ item.dueDate ? (item.dueDate | date: 'shortDate') : '' }} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="col-6 d-flex flex-column" style="overflow-y: scroll; overflow-x: hidden; height: 100%;">
          <mat-card-title class="py-2">Work Orders</mat-card-title>
          <mat-list class="flex-grow" style="margin-left: -15px; width: calc(100% + 30px) !important;">
            <ng-container *ngFor="let wo of po.order?.workOrders">
              <mat-list-item  class="border-bottom">
                <div class="d-flex align-items-center w-100">
                  <mat-icon matPrefix class="mr-1">
                    settings
                  </mat-icon>
                  <b>WO #{{ wo.workOrderNumber }}</b>
                  <div class="ml-auto badge small" [ngClass]="getStatusColorClass(wo.status)">
                    {{ getStatusText(wo.status) }}
                  </div>
                </div>
              </mat-list-item>
              <!-- <mat-list-item class="small" style="height: 32px;" *ngFor="let st of wo.shippingTickets">
                <div class="d-flex align-items-center small w-100">
                  <mat-icon matPrefix class="mr-1">
                    local_shipping
                  </mat-icon>
                  <b>{{ getShippingTicketNumber(wo, st) }}</b>
                  <div class="ml-3">
                    <b>{{st.quantityRequired}}</b> items
                  </div>
                  <div class="ml-3" *ngIf="st.requiredDate">
                    Due <b>{{ st.requiredDate | date: 'shortDate' }}</b>
                  </div>
                  <div class="ml-3" *ngIf="!st.requiredDate">
                    Due date unknown
                  </div>
                  <div class="ml-auto badge small" [ngClass]="getShippingStatusColorClass(st.status)">
                    {{ getShippingStatusText(st.status) }}
                  </div>
                </div>
              </mat-list-item> -->
            </ng-container>
        </mat-list>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <h1 class="text-muted m-0 small">
      No purchase orders have been accepted against this quote yet.
    </h1>
  </div>
</mat-card>
