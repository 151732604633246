<div class="w-100 rounded mat-elevation-z3" style="font-size: 0.8em">
    <mat-progress-bar class="w-100 progress-bar" mode="indeterminate" [class.invisible]="!loading"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="dateOrdered">
      <th mat-header-cell *matHeaderCellDef>
        Date Ordered
      </th>
      <td mat-cell *matCellDef="let item" class="font-weight-medium">
        <ng-container *ngLet="item.purchaseOrder.approvedOn as dateOrdered">
            <div *ngIf="dateOrdered">{{ dateOrdered | date: 'shortDate' }}</div>
            <div *ngIf="!dateOrdered" class="text-muted">Not Yet Approved</div>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="vendor">
      <th mat-header-cell *matHeaderCellDef>
        Vendor
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.purchaseOrder.vendor.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="poNumber">
      <th mat-header-cell *matHeaderCellDef>
        PO #
      </th>
      <td mat-cell *matCellDef="let item" class="font-weight-medium">
        <a class="text-dark" target="_blank" [attr.href]="'/purchasing/order/' + item.purchaseOrder.purchaseOrderId">{{ item.purchaseOrder.purchaseOrderNumber }}<mat-icon class="ml-1 mat-icon-small">open_in_new</mat-icon></a>
      </td>
    </ng-container>
    <ng-container matColumnDef="unitCost">
      <th mat-header-cell *matHeaderCellDef>
        Unit Cost
      </th>
      <td mat-cell *matCellDef="let item">
        {{ getLineItemUnitPrice(item) | currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="qty">
      <th mat-header-cell *matHeaderCellDef>
        Qty
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.mfgrUnitsQty ? item.mfgrUnitsQty : item.quantity }}
      </td>
    </ng-container>
    <ng-container matColumnDef="totalCost">
      <th mat-header-cell *matHeaderCellDef>
        Total Cost
      </th>
      <td mat-cell *matCellDef="let item">
        {{ getLineItemExtPrice(item) | currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="shipDate">
      <th mat-header-cell *matHeaderCellDef>
        Ship Date
      </th>
      <td mat-cell *matCellDef="let item" class="font-weight-medium">
        <ng-container *ngLet="getLineItemShipDate(item) as shipDate">
            <div *ngIf="shipDate">{{ shipDate | date: 'shortDate' }}</div>
            <div *ngIf="!shipDate" class="text-muted">--</div>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="dateReceived">
      <th mat-header-cell *matHeaderCellDef>
        Date Received
      </th>
      <td mat-cell *matCellDef="let item" class="font-weight-medium">
        <ng-container *ngLet="getLineItemDateReceived(item) as dateReceived">
            <div *ngIf="dateReceived">{{ dateReceived | date: 'shortDate' }}</div>
            <div *ngIf="!dateReceived" class="text-muted">Not Yet Received</div>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length" class="text-muted small font-italic text-center"
        *ngIf="!loading">
        This item does not appear in any purchase orders.
      </td>
    </tr>
</table>
    <mat-paginator [pageSizeOptions]="[10]"
                     showFirstLastButtons
    >
    </mat-paginator>
</div>