<div class="mt-4">
  <a *ngIf="record && sidenav" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="!record"></loading>

  <div class="container-fluid p-0" *ngIf="record">

    <div class="p-0 m-0 row">
      <div class="col-3 pl-0">
        <h5 class="w-100 text-center p-2 bg-secondary">Current Machine</h5>
        <div class="container-fluid row">
          <div class="col-5">
            Name:
          </div>
          <div class="col-6">
            {{record.name}}
          </div>
        </div>
        <div class="container-fluid row">
          <div class="col-5">
            Status:
          </div>
          <div class="col-6">
          </div>
        </div>
        <div class="container-fluid row">
          <div class="col-5">
            Max L:
          </div>
          <div class="col-6">
            {{record.operatingLength}}&quot;
          </div>
        </div>
        <div class="container-fluid row">
          <div class="col-5">
            Max H:
          </div>
          <div class="col-6">
            {{record.operatingHeight}}&quot;
          </div>
        </div>
        <div class="container-fluid row">
          <div class="col-5">
            Max D:
          </div>
          <div class="col-6">
            {{record.operatingThickness}}&quot;
          </div>
        </div>
        <div class="container-fluid row">
          <div class="col-5">
            Last Maint:
          </div>
          <div class="col-6">
            {{record.lastMaintenance ? (record.lastMaintenance | date:'shortDate') : '--'}}
          </div>
        </div>
        <div class="container-fluid row">
          <div class="col-5">
            Specifications:
          </div>
          <div class="col-6">
            <div class="container-fluid p-0" *ngFor="let item of record.extendedData | keyvalue">
              <div class="row">
                <div class="col-4">{{item.key}}:</div>
                <div class="col-8">{{item.value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 pr-0">
        <h5 class="w-100 text-center p-2 bg-secondary">Today ({{getDate() | date:'shortDate'}})</h5>
        <operator-today-schedule #operatorSchedule (onClick)="selectAssignment($event)"></operator-today-schedule>
      </div>
    </div>
    <div class="row ml-0 mr-0 mt-3">
      <div class="col-12">
        <machine-operator-controls #operatorControls [machineId]="machineId"></machine-operator-controls>
      </div>
    </div>
  </div>
</div>
