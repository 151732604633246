import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { MaterialType } from '../../../order/resources/material';
import evalInScope from '../../../../../util/evalInScope'

@Component({
  selector: 'volume-formula',
  templateUrl: './volume-formula.component.html',
  styleUrls: ['./volume-formula.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VolumeFormulaComponent implements OnInit, OnChanges {
  @Input() materialType: MaterialType;
  @Input() editing = true;

  constructor() { }

  get formula() {
    return this.materialType.volumeFormula;
  }
  set formula(val) {
    this.materialType.volumeFormula = val
    this.testFormula()
  }

  public operators = [
    { display: "+", formula: "+"},
    { display: "-", formula: "-"},
    { display: "×", formula: "*"},
    { display: "÷", formula: "/"},
    { display: "²", formula: "^2"},
    { display: "π", formula: "π"}
  ]

  @ViewChild('formulaInput') formulaInput: ElementRef<HTMLInputElement>;
  public onInsertSymbol(e: MouseEvent, symbol: string) {
    const inputElement = this.formulaInput.nativeElement
    const formulaIsFocused = inputElement === document.activeElement
    const position = formulaIsFocused ? inputElement.selectionStart : this.formula.length
    const stringAry = (this.formula || "").split('')
    stringAry.splice(position, 0, symbol)
    this.formula = stringAry.join('')
    if (formulaIsFocused) {
      e.preventDefault();
      inputElement.setSelectionRange(position + symbol.length, position + symbol.length)
    }
  }

  get availableSymbols() {
    if (!this.materialType || !this.materialType.materialTypeDimensions) return [];
    return this.materialType.materialTypeDimensions.filter(mtd => mtd.dimensionType && mtd.dimensionUnit).map(mtd => mtd.dimensionType.label)
  }

  get cleanFormula() {
    if (!this.formula) return null
    return this.formula.replace(/\^/g, '**')
  }

  public error: string;
  public testFormula() {
    this.error = null;
    if (!this.cleanFormula) return

    // create scope, setting all symbols to 1 since we're just testing for validity
    const scope = (this.materialType.materialTypeDimensions.filter(mtd => !!mtd.dimensionType && !!mtd.dimensionUnit).map(mtd => [mtd.dimensionType.label, 1]))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    let result: number;
    try {
      result = evalInScope(this.cleanFormula, scope)
    } catch (e) {
      this.error = e.message;
      return;
    }
    if (!isFinite(result)) {
      this.error = "Formula resolves to Infinity. Are you dividing by zero?"
      return;
    };
  }

  ngOnInit() {
    if (!this.formula) this.formula = ""
    this.testFormula()
  }
  
  ngOnChanges(c: SimpleChanges) {
    if (!c.materialType.previousValue || !c.materialType.currentValue) return
    if (
      JSON.stringify(c.materialType.previousValue.materialTypeDimensions) !== JSON.stringify(c.materialType.currentValue.materialTypeDimensions)
    ) this.testFormula()
  }

}
