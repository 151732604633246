<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge" title="Create RMA Ticket"
    routerLink="/sales/rma/new">+</a>

  <div class="row border">
    <search-bar class="w-100" [(searchString)]="searchString" [showManagerBar]="true" [managerView]="managerView" (managerViewChanged)="setManagerView($event)" (search)="doSearch($event)"></search-bar>

    <div *ngIf="!results">
      <loading></loading>
    </div>

    <search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)"
      (selected)="onSelect($event)">
      <thead header-template>
        <tr class="text-uppercase text-muted">
          <th (click)="sortBy('customer')">
            Customer
            <i [ngClass]="getSortIconFor('customer')"></i>
          </th>
          <th (click)="sortBy('rma_number')">
            RMA #
            <i [ngClass]="getSortIconFor('rma_number')"></i>
          </th>
          <th (click)="sortBy('po_number')">
            Customer PO #
            <i [ngClass]="getSortIconFor('po_number')"></i>
          </th>
          <th (click)="sortBy('work_order')">
            WO #
            <i [ngClass]="getSortIconFor('work_order')"></i>
          </th>
          <th (click)="sortBy('product')">
            Part #
            <i [ngClass]="getSortIconFor('product')"></i>
          </th>
          <th (click)="sortBy('rejection_number')">
            Customer Rejection #
            <i [ngClass]="getSortIconFor('rejection_number')"></i>
          </th>
          <th (click)="sortBy('status')" style="min-width: 300px">
            Status
            <i [ngClass]="getSortIconFor('sattus')"></i>
          </th>
        </tr>
      </thead>
      <ng-template let-row>
        <td>{{row.customer.businessName}}</td>
        <td>{{row.rmaNumber ?? row.requestNumber}}</td>
        <td>{{row.customerPurchaseOrder.purchaseOrderNumber}}</td>
        <td>
          <span *ngIf="row.workOrder">
            {{row.workOrder?.workOrderNumber}}
          </span>
          <span *ngIf="!row.workOrder" class="text-muted">
            --
          </span>
        </td>
        <td>
          <span *ngIf="row.product">
            {{row.product?.partNumber}} Rev. {{row.product?.revision}}
          </span>
          <span *ngIf="!row.product" class="text-muted">
            --
          </span>
        </td>
        <td>
          <span *ngIf="row.customerRejectionNumber">
            {{row.customerRejectionNumber}}
          </span>
          <span *ngIf="!row.customerRejectionNumber" class="text-muted">
            --
          </span>
        </td>
        <td class="p-0" style="height: 40.56px">
          <div class="w-100 h-100 d-flex position-relative">
            <div *ngFor="let i of statusProgressRange"
              class="flex-grow-1 position-relative"
              [ngClass]="i <= getStatusProgressNumber(row.status) ? getStatusBackgroundColorClass(row.status) : 'bg-dark'"
            >
              &nbsp;
              <div class="rma-bar-notch"></div>
            </div>
            <!-- complete -->
            <div class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
              [ngStyle]="{
                'clip-path': 'inset(0 0 0 calc('+ (100 -getStatusProgressClipPercent(row.status)) +'% - 1px))'
              }"
            >
              <span class="badge status text-uppercase badge-dark" style="background-color: transparent !important">
                {{ getStatusText(row.status)  }}
              </span>
            </div>
            <!-- incomplete -->
            <div class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
              [ngStyle]="{
                'clip-path': 'inset(0 calc('+ (getStatusProgressClipPercent(row.status)) +'% + 1px) 0 0)'
              }"
            >
              <span class="badge status text-uppercase" [ngClass]="getStatusColorClass(row.status)" style="background-color: transparent !important">
                {{ getStatusText(row.status)  }}
              </span>
            </div>
          </div>
        </td>
      </ng-template>
    </search-result>
  </div>

</div>
