import { Component, Input, ViewChild } from '@angular/core';
import { Product } from '../../resources/product';
import { UtilityService } from '../../../common/services/utility.service';
import { ProductWaterfallComponent } from '../../charts/product-waterfall/product-waterfall.component';

@Component({
  selector: 'product-build-status',
  templateUrl: './product-build-status.component.html',
  styleUrls: ['./product-build-status.component.less']
})
export class ProductBuildStatusComponent {
  @Input() product: Product;
  @Input() orderId: string;
  @ViewChild('waterfallChart', { static: true }) waterfallChart: ProductWaterfallComponent;
  public opened: boolean = false;

  constructor() { }

  public get expectedDate(): Date {
    if (!this.waterfallChart)
      return new Date();

    return this.waterfallChart.maxDate;
  }

  private getTextStatus(): string {
    return 'On Floor'
  }

  public get trackStatus(): string {
    var textColor: string = 'text-success';
    var iconClass: string = 'check-circle'
    var iconTitle: string = 'On Track/On Time';

    var weeksUntilRequired: number = UtilityService.getWeeksRemaining(new Date(this.product.dateRequired || new Date().valueOf()), false);
    var weeksUntilExpected: number = UtilityService.getWeeksRemaining(new Date(this.expectedDate), false);

    //TODO: we need to check if everything is shipped/complete, etc.
    if ((weeksUntilRequired - weeksUntilExpected) < 2) {
      textColor = 'text-danger';
      iconClass = 'exclamation-triangle';
      iconTitle = 'High Risk/Late';
    }
    else if ((weeksUntilRequired - weeksUntilExpected) < 4) {
      textColor = 'text-warning';
      iconClass = 'exclamation';
      iconTitle = 'At Risk';
    }

    return `<span class='${textColor}'>${this.getTextStatus()} <i title='${iconTitle}' class='fas fa-lg fa-fw fa-${iconClass}'></i></span>`;
  }
}
