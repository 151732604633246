import { Material } from "./material";
import { Workflow, WorkflowStep, WorkflowStepBreakdownOptions, WorkflowStepType } from "./workflow";
import { VirtualDocument } from "../../common/resources/virtual-document";
import evalInScope from '../../../../util/evalInScope'
import { PurchasedItem } from "./purchased-item";
import { User } from "../../common/resources/user";
import { UtilityService } from "../../common/services/utility.service";
import { MaterialNeed } from "../../purchasing/resources/purchasingSheet";
import { PricingBreakdown, PricingBreakdownItem, PricingBreakdownSubitem, doAltText } from "../../../../util/pricing";

export interface ProductMaterialDimension
{
    materialTypeDimensionId: string
    value: number
}


export class ProductRepairPlan {
    productId: string
    productRepairPlanId: string
    workflowId: string

    workflow: Workflow;
    purchasedItems: ProductPurchasedItem[];
}

export enum ProductStandardHistory {
  NEW = 0,
  RFQ = 1,
  ESTIMATED = 2,
  QUOTED = 3,
  PURCHASED = 4,
  GOLD = 100
}

export interface ProductStandard {
  productStandardId: string;
  productId: string;
  partNumber: string;
  revision: string;
  isFinal: boolean;
  history: ProductStandardHistory;
};

export class Product {
  productId: string;
  partNumber: string;
  revision: string;
  description: string;
  note: string;
  privateNote: string;
  materialId?: string;
  materialCostIsLotBased: boolean;
  materialLotCost?: number;
  useMetric: boolean;
  partsPerMaterialOverride: boolean;
  partsPerMaterial: number;
  partsPerBlankOverride: boolean;
  partsPerBlank: number;
  materialMarkup?: number;
  finishedWeight?: number;
  numberTestPeices?: number;
  leadTimeWeeks?: number;
  dateRequired?: Date;
  quantitiesMap: ProductQuantity[];
  customerQuoteLineNo: string;
  legacyId: string;
  workflowWorkflowId?: string;
  parentAssemblyId?: string;
  quantityAsChild?: number;
  pricePerUnit?: number;
  orderQuantity?: number;
  quotedUnitPrice?: number;
  selectedMaterialQuote?: string;
  leadTimeBuffer: number;
  selectedSimilarPart?: string;
  blankDimensions: ProductMaterialDimension[];
  finishedDimensions: ProductMaterialDimension[];

  topParentAssemblyId: string;

  readonly useableQuantity: number;

  parentAssembly: Product;
  childAssemblies: Product[];
  documents: ProductDocument[];

  material: Material;
  workflow: Workflow;
  purchasedItems: ProductPurchasedItem[];

  productRepairPlan?: ProductRepairPlan;

  complexity: number | null = null;
  productStandard?: ProductStandard;
  numberProductStandard?: ProductStandard;

  materialNeed?: MaterialNeed;

  // static methods for calculations
  // using static methods so we don't have to bother with having serialization/deserialization for the DB

  static getVolume(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.replace(/\^/g, '**') : null
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label
      const dimension = (material.materialDimensions || []).find(
        md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
      )
      if (!dimension) return [label, 0]
      const { siEquivalent } = dimension.materialTypeDimension.dimensionUnit
      const value = dimension.value// * (siEquivalent !== null ? siEquivalent : 1)
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    return evalInScope(cleanFormula, scope)// * (product.useMetric ? 1 : 1550)

  }

  static getBlankVolume(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.replace(/\^/g, '**') : null
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label
      const blankDimension = (product.blankDimensions || []).find(bd => bd.materialTypeDimensionId === mtd.materialTypeDimensionId)
      const value = blankDimension ? blankDimension.value : 0;
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    return evalInScope(cleanFormula, scope)// * (product.useMetric ? 1 : 1550)

  }

  static getFinishedVolume(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.replace(/\^/g, '**') : null
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label
      const finishedDimension = (product.finishedDimensions || []).find(bd => bd.materialTypeDimensionId === mtd.materialTypeDimensionId)
      const value = finishedDimension ? finishedDimension.value : 0;
      return [label, value]
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})

    return evalInScope(cleanFormula, scope)// * (product.useMetric ? 1 : 1550)

  }

  static getRawWeight(product: Product, material?: Material): number {

    material = material ?? product.material;

    if (
      !product || !material ||
      !material.materialType ||
      !material.materialType.materialTypeDimensions ||
      !material.materialType.volumeFormula
    ) return 0;

    else return Product.getVolume(product, material) * material.density

  }

  static getBlankWeight(product: Product, material?: Material): number {
    material = material ?? product.material;
    return Product.getBlankVolume(product, material) * material.density;
  }

  static getFinishedWeight(product: Product, material?: Material): number {
    material = material ?? product.material;
    return Product.getFinishedVolume(product, material) * material.density;
  }

  static getMaterialRemoved(product: Product, material?: Material): number | null {
    var rawWeight = Product.getBlankWeight(product, material);
    var finishedWeight = Product.getFinishedWeight(product, material);
    if (rawWeight == null || finishedWeight == null) return null;

    return (1 - (finishedWeight) / rawWeight) * 100; //As a percentage
  }

  static getPiecesFromLot(product: Product, material?: Material): number {
    material = material ?? product.material;
    if (product.partsPerMaterialOverride) return product.partsPerMaterial;
    
    if (Product.getBlankVolume(product, material) === 0) return 0;
    return Math.floor(Product.getVolume(product, material) / Product.getBlankVolume(product, material))
  }

  static getMaterialCost(product: Product, qty: number, material?: Material) {
    material = material ?? product.material;
    var lotCost = product.materialLotCost || 0;
    var ppm = Product.getPiecesFromLot(product, material);
    let cost: number;
    if (product.materialCostIsLotBased) {
      var lotsNeeded = Math.ceil(qty / ppm);
      cost = lotCost * lotsNeeded;
    } else {
      cost = (lotCost / ppm) * qty;
    }
    if (isNaN(cost) || !isFinite(cost)) {
      return 0;
    }
    return cost * (1 + ((product.materialMarkup || 0.0) / 100.0));
  }

  static getComplexity(product: Product, material?: Material): number {
    material = material ?? product.material;
    if (product.complexity) return product.complexity
    return Math.round((Product.getMaterialRemoved(product, material) || 0) / 10);
  }
  static overrideComplexity(product: Product, val: number) {
    product.complexity = val;
  }
  static resetComplexity(product: Product) {
    product.complexity = null;
  }

  static newEmptyProduct(): Product {
    return {
      productId: UtilityService.emptyGuid,
      partNumber: '',
      revision: '-',
      quantitiesMap: [],
      childAssemblies: [],
      parentAssemblyId: null,
      quantityAsChild: 1,
      complexity: null,
      customerQuoteLineNo: '',
      description: '',
      documents: [],
      leadTimeBuffer: 0,
      legacyId: '',
      material: null,
      materialCostIsLotBased: false,
      note: '',
      privateNote: '',
      parentAssembly: null,
      partsPerBlank: 0,
      partsPerBlankOverride: false,
      partsPerMaterial: 0,
      partsPerMaterialOverride: false,
      purchasedItems: [],
      useMetric: false,
      topParentAssemblyId: null,
      workflow: {
        workflowId: UtilityService.emptyGuid,
        name: '',
        workflowSteps: []
      },
      blankDimensions: [],
      finishedDimensions: [],
      useableQuantity: 0
    }
  }

  public static getMaterialBreakdown(product: Product, quantity: number, material?: Material): PricingBreakdownItem {
    // Material
    material = material ?? product.material;
    if (!material) return null;
    const subitems: PricingBreakdownSubitem[] = [];
    let blanksPerMaterialLot: number;
    if (product.partsPerMaterialOverride) {
      blanksPerMaterialLot = product.partsPerMaterial;
      subitems.push({
        name: 'Blanks Per Material Lot <b>(Overriden)</b>',
        value: blanksPerMaterialLot,
      });
    }
    else {
      // Lot volume
      const materialLotVolume = Product.getVolume(product, material);
      // We're trusting that the user-input formula returns a cubed unit here
      const volumeUnit = `<b>${material?.materialType?.materialTypeDimensions?.[0]?.dimensionUnit?.abbreviation + '³'}</b>`;

      let preppedFormula = '';
      if (material.materialType.volumeFormula) {
        preppedFormula = material.materialType.volumeFormula
          .replace(/\^2/g, '²')
          .replace(/\*/g, '×')
          .replace(/\//g, '÷')
          .replace(/([^\s]+?)([×÷\+])/g, '$1 $2')
          .replace(/([×÷\+])([^\s]+?)/g, '$1 $2')
          .replace(/([×÷²\+])/g, '<b>$1</b>')
          ;
      }

      let materialLotVolumeFormula = preppedFormula;
      for (const mtd of material?.materialType?.materialTypeDimensions) {
        const matDimension = (material.materialDimensions || []).find(
          md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
        );
        const value = matDimension?.value ?? 0;
        materialLotVolumeFormula = materialLotVolumeFormula.replace(new RegExp(mtd.dimensionType.label, 'i'),
          doAltText(value.toFixed(3), `Raw Material ${mtd.dimensionType.label}`)
        )
      }
      subitems.push({
        name: 'Material Lot Volume',
        calculation: materialLotVolumeFormula,
        value: materialLotVolume,
        displayValue: `${materialLotVolume.toFixed(3)}${volumeUnit}`
      });
      // Blank volume
      const blankVolume = Product.getBlankVolume(product, material);
      let blankVolumeFormula = preppedFormula;
      for (const mtd of material?.materialType?.materialTypeDimensions) {
        const blankDimension = (product.blankDimensions || []).find(
          md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
        );
        blankVolumeFormula = blankVolumeFormula.replace(
          new RegExp(mtd.dimensionType.label, 'i'),
          doAltText(blankDimension?.value.toFixed(3) ?? '0.000', `Blank ${mtd.dimensionType.label}`)
        )
      }
      subitems.push({
        name: 'Blank Volume',
        calculation: blankVolumeFormula,
        value: blankVolume,
        displayValue: `${blankVolume.toFixed(3)}${volumeUnit}`
      });
      // Blanks per lot
      blanksPerMaterialLot = Math.floor(materialLotVolume / blankVolume);
      const isInfinity = !isFinite(blanksPerMaterialLot)
      if (isInfinity) blanksPerMaterialLot = 0;
      subitems.push({
        name: 'Blanks Per Material Lot',
        calculation: `<b>⌋</b>${doAltText(`${materialLotVolume.toFixed(3)}${volumeUnit}`, 'Material Lot Volume')} <b>÷</b> ${doAltText(`${blankVolume.toFixed(3)}${volumeUnit}`, 'Blank Volume')}<b>⌊</b> <b>=</b> <b>⌋</b>${(materialLotVolume / blankVolume).toFixed(3)}<b>⌊</b>`,
        value: blanksPerMaterialLot,
        displayValue: isInfinity ? 'Division by zero (treated as 0)' : blanksPerMaterialLot.toString()
      });
    }
    subitems.push({
      name: 'Material cost calculation type',
      displayValue: product.materialCostIsLotBased ? 'Lot-based (full price of all lots used)' : 'Amortized (proportional to usage)'
    });
    const lotCost = product.materialLotCost ?? 0;
    const lotCostDisplay = `$${lotCost.toFixed(2)}`;
    subitems.push({
      name: 'Material Lot Cost',
      value: lotCost,
      displayValue: lotCostDisplay
    });
    let preMarkupMaterialCost: number;
    if (product.materialCostIsLotBased) {
      const lotsNeeded = Math.ceil(quantity / blanksPerMaterialLot);
      subitems.push({
        name: `Lots needed for quantity of <b>${quantity}</b>`,
        calculation: `<b>⌈</b>${doAltText(quantity.toString(), 'Total Part Quantity')} <b>÷</b> ${doAltText(blanksPerMaterialLot.toString(), 'Blanks Per Material Lot')}<b>⌉</b> <b>=</b> <b>⌈</b>${(quantity / blanksPerMaterialLot).toFixed(3)}<b>⌉</b>`,
        value: lotsNeeded,
      });
      preMarkupMaterialCost = lotsNeeded * lotCost;
      subitems.push({
        id: 'PRE_MARKUP',
        name: `Base Material Cost`,
        calculation: `${doAltText(lotsNeeded.toString(), 'Lots Needed')} <b>×</b> ${doAltText(lotCostDisplay, 'Material Lot Cost')}`,
        value: preMarkupMaterialCost,
        displayValue: `$${preMarkupMaterialCost.toFixed(2)}`
      });
    } else {
      let matPricePerPart = lotCost / blanksPerMaterialLot;
      const isInfinity = !isFinite(matPricePerPart)
      if (isInfinity) matPricePerPart = 0;
      subitems.push({
        // name: product.partsPerMaterialOverride ? 'Blanks Per Material Lot <b>(Overriden)</b>' : 'Blanks Per Material Lot',
        name: 'Material Price Per Part',
        calculation: `${doAltText(lotCostDisplay, 'Material Lot Cost')} <b>÷</b> ${doAltText(blanksPerMaterialLot.toString(), 'Blanks Per Material Lot')}`,
        value: matPricePerPart,
        displayValue: isInfinity ? 'Division by zero (treated as $0)' : `$${matPricePerPart.toFixed(2)}`
      });
      preMarkupMaterialCost = matPricePerPart * quantity;
      subitems.push({
        id: 'PRE_MARKUP',
        name: `Base Material Cost`,
        calculation: `${doAltText('$' + matPricePerPart.toFixed(2), 'Material Price Per Part')} <b>×</b> ${doAltText(quantity.toString(), 'Total Quantity')}`,
        value: preMarkupMaterialCost,
        displayValue: `$${preMarkupMaterialCost.toFixed(2)}`
      });
    }
    // Final markup stuff
    subitems.push({
      id: 'MARKUP_PCT',
      name: 'Material Markup Rate',
      displayValue: `${product.materialMarkup ?? 0}%`
    });
    const additionalMarkup = preMarkupMaterialCost * (((product.materialMarkup || 0.0) / 100.0));
    subitems.push({
      id: 'MARKUP_AMT',
      name: 'Markup',
      calculation: `${doAltText('$' + preMarkupMaterialCost.toFixed(2), 'Base Material Cost')} <b>×</b> ${doAltText(`${product.materialMarkup ?? 0}%`, 'Material Markup Rate')}`,
      value: additionalMarkup,
      displayValue: `$${additionalMarkup.toFixed(2)}`
    });
    const totalMaterialPrice = preMarkupMaterialCost * (1 + ((product.materialMarkup || 0.0) / 100.0));
    subitems.push({
      name: `Total Material Price`,
      calculation: `${doAltText('$' + preMarkupMaterialCost.toFixed(2), 'Base Material Cost')} <b>+</b> ${doAltText(`$${additionalMarkup.toFixed(2)}`, 'Markup')}`,
      value: totalMaterialPrice,
      displayValue: `$${totalMaterialPrice.toFixed(2)}`
    });
    return {
      name: material.materialName,
      price: totalMaterialPrice,
      subitems,
      itemId: material.materialId
    }
  }

  public static getBasePricingBreakdown(product: Product,
    parentQty: number = 1,
    stationNameGetter: (s: WorkflowStep) => string = () => '',
    materialOverride?: Material,
    qtyOverride: number = null,
    includeStandalone = false,
    stepOptions: WorkflowStepBreakdownOptions = null
  ): PricingBreakdown {
    const materialData = materialOverride ?? product.material;
    let totalQuantity: number;
    if (qtyOverride != null) {
      totalQuantity = qtyOverride;
    } else {
      const qty = !!product.parentAssemblyId ? product.quantityAsChild : ((product.quantitiesMap ?? [])[0]?.value ?? 1);
      totalQuantity = qty * parentQty;
    }

    const material = Product.getMaterialBreakdown(product, totalQuantity, materialData);

    let purchasedItems: PricingBreakdownItem[] = null;
    if (product.purchasedItems?.length > 0) {
      purchasedItems = [];
      for (const item of product.purchasedItems) {
        purchasedItems.push(ProductPurchasedItem.getBreakdown(item, totalQuantity));
      }
    }

    const outsourcingSteps = product.workflow.workflowSteps.filter(s => (includeStandalone || !s.isStandalone) && s.outsourceMarkup).sort((a,b) => a.stepOrder - b.stepOrder);
    let process: PricingBreakdownItem[] = null;
    if (outsourcingSteps.length > 0) {
      process = [];
      for (const step of outsourcingSteps) {
        const name = stationNameGetter(step);
        process.push(WorkflowStep.getOutsideProcessBreakdown(step, totalQuantity, name));
      }
    }

    const laborSteps = product.workflow.workflowSteps.filter(s => (includeStandalone || !s.isStandalone) && !s.outsourceMarkup).sort((a,b) => a.stepOrder - b.stepOrder);
    let labor: PricingBreakdownItem[] = null;
    if (laborSteps.length > 0) {
      labor = [];
      for (const step of laborSteps) {
        const name = stationNameGetter(step);
        if (step.paint)
          labor.push(WorkflowStep.getPaintStepBreakdown(step, totalQuantity, name));
        else
          labor.push(...WorkflowStep.getLaborStepBreakdown(step, totalQuantity, name, stepOptions));
      }
    }

    return <PricingBreakdown>{
      material,
      purchasedItems,
      process,
      labor,
    }
  }
  
  static generateNewIdsRecursive(products: Product[], parentId: string | null, topParentId: string | null, oldIdsMap: { [oldId: string]: string } = {}): Product[] {
    return products.map(p => {
      const newId = UtilityService.newGuid();
      topParentId = topParentId ?? newId;
      oldIdsMap[p.productId] = newId;
      return {
        ...p,
        parentAssemblyId: parentId,
        topParentAssemblyId: topParentId,
        productId: newId,
        childAssemblies: this.generateNewIdsRecursive(p.childAssemblies, newId, topParentId, oldIdsMap),
        documents: p.documents.map(d => ({
          ...d,
          productProductId: newId
        })),
        workflowWorkflowId: newId,
        workflow: {
          ...p.workflow,
          workflowId: newId,
          workflowSteps: p.workflow.workflowSteps.map(ws => ({
            ...ws,
            workflowStepId: UtilityService.newGuid(),
            workflowId: newId,
          }))
        },
        purchasedItems: p.purchasedItems.map(i => ({
          ...i,
          productPurchasedItemId: UtilityService.newGuid(),
          productId: newId,
        }))
      };
    })
  }

  public static getFirstQuantity(product: Product): number {
    if (product.parentAssemblyId && product.quantityAsChild) return product.quantityAsChild;
    if (product.quantitiesMap == null) return 1;

    var quantities = product.quantitiesMap;

    if (quantities.length == 0) return 1;

    return quantities[0].value;
  }
  
}

export interface ProductDocument {
  productProductId: string;
  documentDocumentId: string;

  document?: VirtualDocument
}

export class ProductPurchasedItem {
  productPurchasedItemId: string;
  purchasedItemId: string;
  productId?: string;
  productRepairPlanId?: string;
  costPer: number;
  isAmortized: boolean;
  isNonRecurring: boolean;
  markupPercent: number;
  quantity: number;
  selectedQuote?: string;
  isFromCompare?: boolean;

  itemRepairType: WorkflowStepType;

  purchasedItem?: PurchasedItem | null;

  public static getBreakdown(item: ProductPurchasedItem, quantity: number): PricingBreakdownItem {
    const name = item.purchasedItem?.description ?? 'No Description'
    let calculation = '';
    const subitems: PricingBreakdownSubitem[] = [];

    // Define step names
    const base_price_id = 'BASE_PRICE';
    const item_quantity_mult_id = 'ITEM_QUANTITY_MULT';
    const recurring_id = 'RECURRING';
    const parent_quantity_mult_id = 'PARENT_QUANTITY_MULT';
    const markup_pct_id = 'MARKUP_PCT';
    const markup_calc_id = 'CALC_MARKUP';
    const apply_markup_id = 'APPLY_MARKUP';

    // Shorter step getter
    const getStep = (id: string) => subitems.find(i => i.id === id);

    // 1. Base price
    const costPer = item.costPer ?? 0; // actual calc

    subitems.push({
      id: base_price_id,
      name: 'Base quoted price',
      value: costPer,
      displayValue: `$${costPer.toFixed(2)}`,
    });

    // 2. Multiply by item quantity
    const itemQuantity = item.quantity ?? 0; // actual calc
    const perParentTotal = getStep(base_price_id).value * itemQuantity;

    calculation = doAltText(getStep(base_price_id).displayValue, 'Base quoted price');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${itemQuantity}`, 'Item quantity');

    subitems.push({
      id: item_quantity_mult_id,
      name: 'Multiply by item quantity',
      value: perParentTotal,
      displayValue: `$${perParentTotal.toFixed(2)}`,
      calculation,
    });

    // 3. Recurring check
    const recurring = item.isNonRecurring ? 0 : 1;

    subitems.push({
      id: recurring_id,
      name: 'Item is recurring?',
      value: recurring,
      displayValue: recurring === 1 ? 'Yes' : 'No',
    });

    // 4. Multiply by quantity
    const quantityMultiplier = getStep(recurring_id).value === 1 ? quantity : 1;
    const preMarkupTotal = getStep(item_quantity_mult_id).value * quantityMultiplier; // actual calc

    calculation = doAltText(getStep(base_price_id).displayValue, 'Base quoted price');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${quantityMultiplier}`, getStep(recurring_id).value === 1 ? 'Recurring, total quantity' : 'Non-recurring single quantity');

    subitems.push({
      id: parent_quantity_mult_id,
      name: 'Cost for all parts together',
      value: preMarkupTotal,
      displayValue: `$${preMarkupTotal.toFixed(2)}`,
      calculation,
    });

    // 5. Calculate markup
    const markupPercent = item.markupPercent ?? 0.0;
    const markupDollars = getStep(parent_quantity_mult_id).value * (markupPercent / 100.0); // actual calc

    calculation = doAltText(getStep(parent_quantity_mult_id).displayValue, 'Pre-markup total');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${markupPercent}%`, `Total markup`);

    subitems.push({
      id: markup_pct_id,
      name: 'Markup Rate',
      value: markupPercent,
      displayValue: `${markupPercent}%`,
      calculation,
    });

    subitems.push({
      id: markup_calc_id,
      name: 'Markup',
      value: markupDollars,
      displayValue: `$${markupDollars.toFixed(2)}`,
      calculation,
    });

    // 6. Add markup
    const totalPrice = getStep(parent_quantity_mult_id).value + getStep(markup_calc_id).value; // actual calc

    calculation = doAltText(getStep(parent_quantity_mult_id).displayValue, 'Pre-markup total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(getStep(markup_calc_id).displayValue, 'Total markup');

    subitems.push({
      id: apply_markup_id,
      name: 'Apply markup',
      value: totalPrice,
      displayValue: `$${totalPrice.toFixed(2)}`,
      calculation,
    });

    // Finalize price
    const price = getStep(apply_markup_id).value;

    const itemId = item.productPurchasedItemId;

    return { name, price, subitems, itemId };
  }
  
  public static getPriceFromBreakdown(item: ProductPurchasedItem, quantity: number): number {
    const { price } = ProductPurchasedItem.getBreakdown(item, quantity);
    return price;
  }

}


export interface ProductQuantity {
  value: number;
  markup: number;
  showOnQuote: boolean;
  priceOverride?: number;
  overrideNote?: string;
}

export interface SimilarPartScore {
  productId: string;
  partNumber: string;
  revision: string;
  customerNum: number;
  businessName: string;
  score: number;
}


export interface ProductHistoryEntry {
  productHistoryEntryId: string;
  partNumber: string;
  userId: string;
  user: User;
  timestamp: Date;
  note: string;
  department: string;
}

export function getQuoteHistoryText(h: ProductStandardHistory) {
  switch (h) {
    case ProductStandardHistory.NEW:
      return 'New Part';
    case ProductStandardHistory.RFQ:
      return 'Prev. In RFQ';
    case ProductStandardHistory.ESTIMATED:
      return 'Prev. Estimated';
    case ProductStandardHistory.QUOTED:
      return 'Prev. Quoted';
    case ProductStandardHistory.PURCHASED:
      return 'Prev. Purchased';
    case ProductStandardHistory.GOLD:
      return 'Gold Part';
  }
}

export function getQuoteHistoryChipClass(h: ProductStandardHistory) {
  switch (h) {
    case ProductStandardHistory.NEW:
      return 'history-chip-new';
    case ProductStandardHistory.RFQ:
      return 'history-chip-new';
    case ProductStandardHistory.ESTIMATED:
      return 'history-chip-estimated';
    case ProductStandardHistory.QUOTED:
      return 'history-chip-quoted';
    case ProductStandardHistory.PURCHASED:
      return 'history-chip-purchased';
    case ProductStandardHistory.GOLD:
      return 'history-chip-gold';
  }
}