<loading *ngIf="loading" content="Loading..."></loading>

<div *ngIf="!record.estimateNumber; else pricingTemplate" class="text-center p-4 mt-4">
  <h2 class="text-secondary">Sorry, but we couldn't find an estimate.</h2>
  <p class="text-muted p-2">
    <i class="fas fa-fw fa-lg text-info fa-info-circle"></i>
    You'll need to finish your quote and send it on to estimating before you can adjust pricing.
  </p>
</div>
<ng-template #pricingTemplate>
  <div class="row">
    <div class="col form-group">
      <label>Estimate</label>
      <h4 class="text-secondary">
        {{record.estimateNumber}}
        <span *ngIf="relatedEstimate" class="badge status text-uppercase p-1 pl-2 pr-2" [ngClass]="getStatusColorClass(relatedEstimate.status)">
          <i *ngIf="isEstimateReady(relatedEstimate.status); else notReady" class="fa fa-fw fa-check"></i>
          <ng-template #notReady>
            <i class="fa fa-fw fa-exclamation-triangle"></i>
          </ng-template>
          {{getStatusText(relatedEstimate.status)}}
        </span>
        <span *ngIf="relatedEstimate" class="small pl-4">
          <a title="Open Estimate" class="small text-muted" target="_blank" [routerLink]="'/order/' + relatedEstimate.orderSegmentId"><i class="fa fas fa-fw fa-external-link-alt"></i>Open Estimate</a>
        </span>
      </h4>
    </div>
    <div class="col-2" *ngIf="!editing">
      <button class="btn btn-primary" (click)="getDownloadableQuote()">
        <i class="fas fa-fw fa-lg fa-file-download"></i>
        Download Quote
      </button>
    </div>
    <div class="col-4" *ngIf="!editing && (record.status == 8 || record.status == 99)">
      <button class="btn btn-info" (click)="beginAcceptance()" [disabled]="!allQuantitiesSelected">
        <i class="fas fa-fw fa-arrow-right-from-bracket"></i>
        Accept PO Against Quote
      </button>
    </div>
  </div>
  <div *ngIf="!relatedEstimate; else estimateLoaded" class="text-center">
    <i class="fas fa-fw fa-4x fa-spin fa-circle-notch text-primary"></i>
  </div>
  <ng-template #estimateLoaded>
    <div *ngIf="isEstimateReady(relatedEstimate.status); else estimateIncomplete">
      <quote-items-table [record]="record"></quote-items-table>
    </div>
    <ng-template #estimateIncomplete>
      <div class="text-center p-4 mt-4">
        <h2 class="text-secondary">Waiting on Estimate</h2>
        <p class="text-muted p-2">
          <i class="fas fa-fw fa-lg text-info fa-info-circle"></i>
          The estimate needs to be completed and approved before quoting.
        </p>
      </div>
    </ng-template>
  </ng-template>
</ng-template>
