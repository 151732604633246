import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { OrderService } from '../../../order/services/order.service';
import { Company } from '../../resources/company';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'company-search',
  templateUrl: 'company-search.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySearchComponent),
      multi: true
    }
  ]
})
export class CompanySearchComponent implements OnInit, ControlValueAccessor {

  @Input() idValue: string | null = null;
  @Input() editing = true;
  @Output() idValueChange = new EventEmitter<string>();
  loading = false;
  companies: Company[] = [];


  constructor(private orderSvc: OrderService) { }

  ngOnInit(): void {
    this.loading = true;
    this.orderSvc.searchCompanies('').pipe(
      map((r) => r.results)
    ).subscribe((r) => {
      this.companies = r;
      this.loading = false;
      const def = this.companies.find(o => o.name.includes('APM'));
      if (!this.value && def) {
        this.value = def;
        this.idValue = def.companyId;
        this.idValueChange.emit(def.companyId);
        this.onChange(def);
      }
    })
  }

  public get companyName(): string {
    const company = this.companies.find(c => c.companyId === this.idValue);
    if (!company) return 'Loading...';
    else return company.name;
  }

  public value: Company;
  writeValue(value: Company) {
    this.value = value;
    this.idValue = value?.companyId;
  }

  public onChange: (value: Company) => void = () => {};
  private onTouched: () => void;
  public registerOnChange(fn: (value: Company) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public compare(option: Company, value: Company | null) {
    return option.companyId === value?.companyId
  }

}
