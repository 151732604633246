<div class="position-relative">
  <span class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i
      class="fas text-muted fa-chevron-down"></i></span>
  <input required #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1" (focusout)="testSelection()" #inputText
    matInput placeholder="Search for a building or customer..." (change)="setValue($event.value)"
    class="form-control form-control-sm" (keyup)="doSearch($event)" type="text" max="100"
    [matAutocomplete]="customerList" />
  <mat-autocomplete (opened)="doSearch($event)" autoActiveFirstOption #customerList="matAutocomplete"
    (optionSelected)="setValue($event.option.value)">
    <mat-option *ngIf="searching">
      <p class="mt-3 text-center text-muted small">
        <i class="fas fa-spin fas-circle-notch color-primary d-inline-flex mr-2"></i>
        <em>Searching...</em>
      </p>
    </mat-option>
    <ng-container *ngIf="!searching">
      <mat-option *ngFor="let r of results" [value]="r">
        <mat-chip *ngIf="!filter || filter.length > 1" [disableRipple]="true" color="primary" (click)="setValue(r); autoCompleteTrigger.closePanel();" class="mr-2 pointer-events-none" [style.backgroundColor]="getChipColor(r)">{{getChipName(r)}}</mat-chip>
        {{r.address.nickname}}&nbsp;
      </mat-option>
    </ng-container>
    <mat-option *ngIf="results.length==0 && !searching">
      <p class="mt-3 text-center text-muted small">
        <em>-- No Matches Found --</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</div>
