<div class="row m-0 h-100-t">
  <div class="col-12 d-flex justify-content-end mt-2">
    <button mat-raised-button color="primary" class="button-text" (click)="exportCsv()"><mat-icon svgIcon="table-arrow-down" matPrefix></mat-icon>Export Current View to Spreadsheet</button>
  </div>
  <div class="col-12 p-3 h-100-t">
    <div class="rounded mat-elevation-z4 breakdown" style="overflow-y: scroll;">
      <mat-list class="pt-0">

        <!-- For each top level product -->
        <ng-container *ngFor="let productId of topLevelIds">
          <ng-container *ngLet="(topLevelProducts[productId] | async) as product;">
            <ng-container *ngLet="(topLevelQtys[productId] | async) as topQty">

              <!-- expansion toggle -->
              <mat-list-item>
                <ng-container
                  [ngTemplateOutlet]="expandArrow"
                  [ngTemplateOutletContext]="{id: productId}"
                >
                </ng-container>

                <!-- product name -->
                <span class="mr-2">
                  {{ product.partNumber }} Rev. {{ product.revision }}
                </span>

                <!-- quantity grouping -->
                <mat-button-toggle-group
                  [ngModel]="topQty"
                  (ngModelChange)="topLevelQtys[productId].next($event)"
                >
                  <mat-button-toggle
                    class="button-text small font-weight-medium"
                    *ngFor="let qty of product.quantitiesMap"
                    [value]="qty.value"
                  >
                    {{ qty.value }}
                  </mat-button-toggle>
                </mat-button-toggle-group>

                <!-- overall price -->
                <div class="ml-auto font-weight-medium">
                  {{ getProductTotal(product, topQty) | async | currency }}
                </div>

              <!-- end the top-level line item -->
              </mat-list-item>

              <!-- Create list items when expanded -->
              <ng-container
                *ngIf="checkToggle(productId) | async"
                [ngTemplateOutlet]="productContent"
                [ngTemplateOutletContext]="{
                  product: product,
                  indent: 1,
                  qty: topLevelQtys[productId]
                }"
              >
              </ng-container>

            <!-- end for loop -->
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-list>
    </div>
  </div>
</div>

<ng-template
  #productContent
  let-product="product"
  let-indent="indent"
  let-qty="qty"
>
  <!-- Product Content for {{product.productId}} -->
  <ng-container
    [ngTemplateOutlet]="breakdowns"
    [ngTemplateOutletContext]="{
      product: product,
      indent: indent,
      qty: qty
    }"
  >
  </ng-container>

  <ng-container
    *ngIf="product.childAssemblies?.length"
    [ngTemplateOutlet]="categoryTitle"
    [ngTemplateOutletContext]="{
      id: product.productId,
      title: 'Subassemblies (' + product.childAssemblies.length + ')',
      section: 'sub',
      indent: indent,
      icon: 'list-box',
      price: getChildrenTotal(product, qty | async)
    }"
  >
  </ng-container>

  <ng-container *ngIf="checkToggle(product.productId, 'sub') | async">
    <ng-container
      *ngFor="let child of product.childAssemblies"
      [ngTemplateOutlet]="subassemblies"
      [ngTemplateOutletContext]="{
        product: child,
        indent: indent+1,
        qty: qty
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #categoryTitle
  let-id="id"
  let-title="title"
  let-section="section"
  let-indent="indent"
  let-icon="icon"
  let-price="price"
>
  <mat-list-item>
    <div class="list-item-inner item--{{section}}" [style.padding-left]="getIndent(indent)">
      <ng-container
        [ngTemplateOutlet]="expandArrow"
        [ngTemplateOutletContext]="{id: id, section: section}"
      >
      </ng-container>
      <mat-icon svgIcon="{{icon}}" class="mr-1"></mat-icon>
      <div>
        <span class="font-weight-medium">{{title}}</span>
      </div>
      <div class="ml-auto font-weight-medium">
        {{ price | currency }}
      </div>
    </div>
  </mat-list-item>
</ng-template>

<ng-template
  #breakdowns
  let-product="product"
  let-indent="indent"
  let-qty="qty"
>
  <ng-container *ngLet="(getBreakdown(product, qty | async) | async) as breakdown">
    <ng-container
      *ngIf="breakdown.material"
      [ngTemplateOutlet]="breakdownParent"
      [ngTemplateOutletContext]="{
        breakdown: breakdown.material,
        name: 'Material (' + breakdown.material.name + ')',
        section: 'material',
        id: product.productId,
        indent: indent,
        icon: 'cube'
      }"
    ></ng-container>

    <ng-container
      *ngIf="breakdown.purchasedItems"
      [ngTemplateOutlet]="breakdownCategory"
      [ngTemplateOutletContext]="{
        breakdown: breakdown.purchasedItems,
        name: 'Purchased Items',
        section: 'purchased',
        id: product.productId,
        indent: indent,
        icon: 'package-variant'
      }"
    ></ng-container>

    <ng-container
      *ngIf="breakdown.process"
      [ngTemplateOutlet]="breakdownCategory"
      [ngTemplateOutletContext]="{
        breakdown: breakdown.process,
        name: 'Outside Process',
        section: 'process',
        id: product.productId,
        indent: indent,
        icon: 'logout-variant'
      }"
    ></ng-container>

    <ng-container
      *ngIf="breakdown.labor"
      [ngTemplateOutlet]="breakdownCategory"
      [ngTemplateOutletContext]="{
        breakdown: breakdown.labor,
        name: 'Labor',
        section: 'labor',
        id: product.productId,
        indent: indent,
        icon: 'account-hard-hat'
      }"
    ></ng-container>

  </ng-container>
</ng-template>

<ng-template
  #subassemblies
  let-product="product"
  let-indent="indent"
  let-qty="qty"
>
  <ng-container
    [ngTemplateOutlet]="categoryTitle"
    [ngTemplateOutletContext]="{
      id: product.productId,
      title: product.partNumber + ' Rev. ' + product.revision + ' (x' + product.quantityAsChild + ')',
      indent: indent,
      icon: 'list-box',
      price: getProductTotal(product, qty | async) | async
    }"
  >
  </ng-container>

  <!-- recurse -->
  <ng-container
    *ngIf="checkToggle(product.productId) | async"
    [ngTemplateOutlet]="productContent"
    [ngTemplateOutletContext]="{
      product: product,
      indent: indent+1,
      qty: qty
    }"
  ></ng-container>

</ng-template>

<ng-template
  #breakdownCategory
  let-breakdown="breakdown"
  let-name="name"
  let-section="section"
  let-id="id"
  let-indent="indent"
  let-icon="icon"
  let-index="index"
>
  <ng-container
    [ngTemplateOutlet]="categoryTitle"
    [ngTemplateOutletContext]="{
      id: id,
      title: name + ' (' + breakdown.length + ')',
      section: section,
      indent: indent,
      icon: icon,
      price: getCategoryPrice(breakdown)
    }"
  >
  </ng-container>

  <ng-container *ngIf="checkToggle(id, section) | async">
    <ng-container
      *ngFor="let item of breakdown; index as i"
      [ngTemplateOutlet]="breakdownParent"
      [ngTemplateOutletContext]="{
        breakdown: item,
        name: item.name,
        section: section,
        id: id,
        indent: indent+1,
        icon: icon,
        index: i
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #breakdownParent
  let-breakdown="breakdown"
  let-name="name"
  let-section="section"
  let-id="id"
  let-indent="indent"
  let-icon="icon"
  let-index="index"
>
  <mat-list-item>
    <div class="list-item-inner item--{{section}}" [style.padding-left]="getIndent(indent)">
      <ng-container
        [ngTemplateOutlet]="expandArrow"
        [ngTemplateOutletContext]="{id: id, section: section, index: index}"
      >
      </ng-container>
      <mat-icon svgIcon="{{icon}}" class="mr-1"></mat-icon>
      <div>
        <span class="font-weight-medium">{{name}}</span>
      </div>
      <div class="ml-auto font-weight-medium">
        {{ breakdown.price | currency }}
      </div>
    </div>
  </mat-list-item>

  <ng-container *ngIf="checkToggle(id, section, index) | async">
    <ng-container
      *ngFor="let subitem of breakdown.subitems"
      [ngTemplateOutlet]="breakdownItem"
      [ngTemplateOutletContext]="{
        subitem: subitem,
        name: name,
        section: section,
        indent: indent+1
      }"
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #breakdownItem
  let-subitem="subitem"
  let-name="name"
  let-section="section"
  let-indent="indent"
>
  <mat-list-item>
    <div class="list-item-inner item--{{section}} padding-subitem" [style.padding-left]="getIndent(indent)">
      <ng-template [ngTemplateOutlet]="expandArrowInvis"></ng-template>
      <div class="padding-noexpand">
        <span [innerHTML]="subitem.name"></span>
      </div>
      <div class="ml-auto"></div>
      <div *ngIf="subitem.calculation" [innerHTML]="subitem.calculation + '&nbsp;<b>=</b>&nbsp;'"></div>
      <div class="font-weight-bold ml-1" [innerHTML]="subitem.displayValue ?? subitem.value ?? 'No Value'"></div>
    </div>
  </mat-list-item>
</ng-template>

<ng-template
  #expandArrow
  let-id="id"
  let-section="section"
  let-index="index"
>
  <button
    mat-icon-button
    class="mr-1"
    (click)="swapToggle(id, section, index)"
  >
    <ng-container
      *ngIf="checkToggle(id, section, index) | async; then toggledOn; else toggledOff"
    ></ng-container>
  </button>
</ng-template>

<ng-template #toggledOn>
  <mat-icon>expand_more</mat-icon>
</ng-template>

<ng-template #toggledOff>
  <mat-icon>chevron_right</mat-icon>
</ng-template>

<ng-template
  #expandArrowInvis
>
  <button mat-icon-button class="mr-1 invisible">
    <ng-template [ngTemplateOutlet]="toggledOff"></ng-template>
  </button>
</ng-template>
