import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FloorAlert } from '../../resources/floor-alert';
import { SearchList } from '../../../common/components/search-list/search-list';
import { FloorAlertService } from '../../services/floor-alert.service';

@Component({
  selector: 'filtered-alert-list',
  templateUrl: './filtered-alert-list.component.html',
  styleUrls: ['./filtered-alert-list.component.less']
})
export class FilteredAlertListComponent extends SearchList<FloorAlert> {

  @Input() buildingId: string;
  @Input() departmentId: string = null;
  @Input() machineId: string = null;
  @Output() selected: EventEmitter<FloorAlert> = new EventEmitter<FloorAlert>();

  constructor(private service: FloorAlertService) {
    super('flooralert');
  }

  protected search(): void {
    this.results = null;
    this.service.filter(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection, this.buildingId, this.departmentId, this.machineId).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public onSelect(record: FloorAlert): void {
    this.selected.emit(record);
  }
}
