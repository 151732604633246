import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '../../common/services/utility.service';
import { MessageService } from '../../common/services/message.service';
import { catchError, concatMap, first, map, mergeMap, tap } from 'rxjs/operators';
import { MessageType } from '../../common/resources/message'
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { Vendor, VendorCertificate, VendorContact, VendorCertificateDocument, VendorAddress, VendorQualityClause } from '../resources/vendor';
import { VirtualDocument } from '../../common/resources/virtual-document';
import { Customer } from '../../customer/resources/customer';
import { Address } from '../../common/resources/address';
import { Contact } from '../../common/resources/contact';
import { QualityClause } from '../resources/quality-clause';

@Injectable({
  providedIn: 'root',
})
export class VendorService extends httpService {
  private apiBase: string = 'api/vendor';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Vendor";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<Vendor>> {
    return this.http.get<SearchResult<Vendor>>(this.apiUrl + '/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Vendor Search Results", null))
    );
  }

  getCapableVendors(type: string): Observable<Vendor[]> {
    return this.http.get<Vendor>(this.apiUrl + '/getvendorsfortype/' + type).pipe(
      catchError(this.handleError<any>("Get Capable Vendors List", null))
    );
  }

  getDetail(id: string): Observable<Vendor> {
    return this.http.get<Vendor>(this.apiUrl + '/' + id).pipe(
      catchError(this.handleError<any>("Get Vendor Detail", null))
    );
  }

  getCert(certificateId: string): Observable<VendorCertificate> {
    return this.http.get<Vendor>(this.apiUrl + 'certificate/' + certificateId).pipe(
      catchError(this.handleError<any>("Get Vendor Certificate Detail", null))
    );
  }

  getCertificateTitles(searchString: string): Observable<string[]> {
    return this.http.get<SearchResult<string[]>>(this.apiUrl + '/certificatesearch', { params: { searchText: searchString } }).pipe(
      catchError(this.handleError<any>("Get Certificate Name Search Results", null))
    );
  }

  addCapability(vendor: Vendor, groupId: string): Observable<any> {
    if (vendor.vendorId == UtilityService.emptyGuid) {
      //save the vendor first
      this.save(vendor).subscribe(
        d => {
          vendor = d;
          return this.addCapability(vendor, groupId);
        }
      );
    }

    return this.http.get<VendorContact>(this.apiUrl + '/addcapability/', { params: { vendorId: vendor.vendorId, materialGroupId: groupId } }).pipe(
      tap(_ => this.messages.add("Vendor Service: Capapibility Added Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Add Capability", null))
    );
  }

  removeCapability(vendor: Vendor, groupId: string): Observable<any> {
    return this.http.get<VendorContact>(this.apiUrl + '/removecapability/', { params: { vendorId: vendor.vendorId, materialGroupId: groupId } }).pipe(
      tap(_ => this.messages.add("Vendor Service: Capapibility Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Remove Capability", null))
    );
  }

  markVendorForItem(vendor: Vendor, typeId?: string, customerId?: string): Observable<any> {
    return this.http.get<null>(this.apiUrl + '/markForItem/' + vendor.vendorId, { params: { type: typeId, customerId } }).pipe(
      tap(_ => this.messages.add("Vendor Service: Capabilites Updated Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Add Capability", null))
    );
  }

  addServiceOffering(vendor: Vendor, stationId: string): Observable<any> {
    if (vendor.vendorId == UtilityService.emptyGuid) {
      //save the vendor first
      this.save(vendor).subscribe(
        d => {
          vendor = d;
          return this.addServiceOffering(vendor, stationId);
        }
      );
    }

    return this.http.get<VendorContact>(this.apiUrl + '/addserviceoffering/', { params: { vendorId: vendor.vendorId, stationId: stationId } }).pipe(
      tap(_ => this.messages.add("Vendor Service: Service Offering Added Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Add Service Offering", null))
    );
  }

  removeServiceOffering(vendor: Vendor, stationId: string): Observable<any> {
    return this.http.get<VendorContact>(this.apiUrl + '/removeserviceoffering/', { params: { vendorId: vendor.vendorId, stationId: stationId } }).pipe(
      tap(_ => this.messages.add("Vendor Service: Service Offering Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Remove Service Offering", null))
    );
  }

  deleteContact(contact: VendorContact): Observable<any> {
    // just deleting the contact will automatically delete the vendorcontact via cascade
    return this.http.delete<VendorContact>(this.baseUrl + 'api/contact/' + contact.contactId).pipe(
      tap(_ => this.messages.add("Vendor Service: Contact Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Remove Existing Contact", contact))
    );
  }

  addDocumentsToCertificate(certificate: VendorCertificate, documents: VirtualDocument[]): Observable<VendorCertificateDocument[]> {
    return this.http.post<VendorCertificateDocument[]>(this.apiUrl + '/addcertificatedocuments?vendorCertificateId=' + certificate.vendorCertificateId, documents.map(d => d.documentId)).pipe(
      tap(_ => this.messages.add("Vendor Service: Documents Updated", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Add Documents to Certificate", null))
    );
  }

  removeDocumentFromCertificate(certificate: VendorCertificate, document: VirtualDocument): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/removecertificatedocument?vendorCertificateId=' + certificate.vendorCertificateId + '&documentId=' + document.documentId).pipe(
      tap(_ => this.messages.add("Vendor Service: Document Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Remove Document from Certificate", null))
    );
  }

  removeCertificate(certificate: VendorCertificate): Observable<any> {
    return this.http.delete<VendorCertificate>(this.apiUrl + 'certificate/' + certificate.vendorCertificateId).pipe(
      tap(_ => this.messages.add("Vendor Service: Certificate Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorCertificate>("Remove Existing Certificate", certificate))
    );
  }

  delete(vendor: Vendor): Observable<any> {
    return this.http.delete<Vendor>(this.apiUrl + '/' + vendor.vendorId).pipe(
      tap(_ => this.messages.add("Vendor Service: Vendor Removed Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<Vendor>("Remove Existing Vendor", vendor))
    );
  }

  saveCertificate(vendor: Vendor, certificate: VendorCertificate): Observable<VendorCertificate> {
    certificate.vendorId = vendor.vendorId;
    if (certificate.vendorCertificateId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<VendorCertificate>(this.apiUrl + 'certificate/new', certificate).pipe(
        tap(_ => this.messages.add("Vendor Service: Certificate Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<VendorCertificate>("Save New Certificate", certificate))
      );
    }
    else {
      //Existing Item
      return this.http.post<VendorCertificate>(this.apiUrl + 'certificate', certificate).pipe(
        tap(_ => this.messages.add("Vendor Service: Certificate Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<VendorCertificate>("Update Certificate", certificate))
      );
    }
  }

  saveContact(vendor: Vendor, contact: VendorContact): Observable<VendorContact> {
    contact.vendorId = vendor.vendorId;
    if (contact.vendorContactId == UtilityService.emptyGuid) {
      //New Item
      // The new Contact will be saved automatically thanks to EFCore
      contact.contact.contactId = UtilityService.newGuid();
      return this.http.post<VendorContact>(this.apiUrl + 'contact/new', contact).pipe(
        tap(_ => this.messages.add("Vendor Service: Contact Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<VendorContact>("Save New Contact", contact))
      );
    }
    else {
      //Existing Item
      // Have to save the contained contact manually when updating
      return this.http.post<Contact>(this.baseUrl + 'api/contact', contact.contact).pipe(
        mergeMap(_ => {
          return this.http.post<VendorContact>(this.apiUrl + 'contact', contact)
        }),
        tap(_ => this.messages.add("Vendor Service: Contact Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<VendorContact>("Update Contact", contact))
      );
    }
  }

  searchContacts(vendor: Vendor, searchString?: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.apiUrl + '/searchContacts/' + vendor.vendorId, { params: { searchText: searchString } }).pipe(
      catchError(this.handleError<any>("Get Vendor Contact Search Results", null))
    );
  }

  save(_item: Vendor): Observable<Vendor> {

    // clone item 
    const item: Vendor = JSON.parse(JSON.stringify(_item));
    // null related entities
    item.vendorAddresses = null;
    item.vendorContacts = null;
    item.vendorMaterialGroups = null;
    item.vendorServiceOfferings = null;
    item.vendorQualityClauses = null;
    item.approvedSupplierList = null;

    if (item.vendorId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<Vendor>(this.apiUrl + '/new', item).pipe(
        tap(_ => this.messages.add("Vendor Service: Vendor Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Vendor>("Save New Vendor", item))
      );
    }
    else {
      //Existing Item
      return this.http.post<Vendor>(this.apiUrl, item).pipe(
        tap(_ => this.messages.add("Vendor Service: Vendor Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<Vendor>("Update Vendor", item))
      );
    }
  }

  getApprovedVendorCustomers(vendor: Vendor): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.apiUrl + '/getApprovedVendorCustomers/' + vendor.vendorId).pipe(
      tap(_ => this.messages.add("Vendor Service: Fetched Customer ASL Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<any>("Fetch Customer ASL List", null))
    );
  }
  
  public searchQualityClauses(searchFor: string, pageIndex: number = 0, pageSize: number = 20): Observable<SearchResult<QualityClause>> {
    return this.http.get<QualityClause[]>(this.baseUrl + 'api/QualityClause/search', { params: { 
      searchText: searchFor,
      pageIndex: pageIndex.toString(),
      pageSize: pageSize.toString(),
      orderBy: 'name'
    } }).pipe(
      catchError(this.handleError<any>("Get Quality Clauses Search Results", null))
    );
  }

  public saveQualityClause(item: QualityClause): Observable<QualityClause> {
    if (item.qualityClauseId == UtilityService.emptyGuid) {
      //New Item
      return this.http.post<QualityClause>(this.baseUrl + 'api/qualityclause/new', item).pipe(
        tap(_ => this.messages.add("Order Service: QualityClause Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<QualityClause>("Save New QualityClause", item))
      );
    }
    else {
      //Existing Item
      return this.http.post<QualityClause>(this.baseUrl + 'api/qualityclause/', item).pipe(
        tap(_ => this.messages.add("Order Service: QualityClause Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<QualityClause>("Update QualityClause", item))
      );
    }
  }

  public removeQualityClause(qualityClauseId: string): Observable<any> {
    return this.http.delete<QualityClause>(this.baseUrl + `api/qualityclause/${qualityClauseId}`).pipe(
      tap(_ => {
        this.messages.add("Order Service: QualityClause Removed Successfully", MessageType.SUCCESS, true);
      }),
      catchError(this.handleError<QualityClause>("Remove QualityClause", null))
    );
  }

  updateVendorClause(vendor: Vendor, clause: QualityClause, vendorClause: VendorQualityClause): Observable<VendorQualityClause> {
    if (vendor.vendorId == UtilityService.emptyGuid) {
      //save the vendor first
      this.save(vendor).subscribe(
        d => {
          vendor = d;
          vendorClause.vendorVendorId = vendor.vendorId;
          return this.updateVendorClause(vendor, clause, vendorClause);
        }
      );
    }

    return this.http.post<VendorQualityClause>(this.apiUrl + `/updatevendorclause/${vendor.vendorId}/${clause.qualityClauseId}`, vendorClause).pipe(
      tap(_ => this.messages.add("Vendor Service: QC Clause Overrides Edited Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorQualityClause>("Add QC Clause Overrides", null))
    );
  }

  resetVendorClause(vendor: Vendor, clauseId: string): Observable<any> {
    return this.http.delete<VendorContact>(this.apiUrl + `/resetvendorclause/${vendor.vendorId}/${clauseId}`).pipe(
      tap(_ => this.messages.add("Vendor Service: QC Clause Overrides Cleared Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorContact>("Remove QC Clause Overrides", null))
    );
  }

  getVendorAddresses(vendorId: string): Observable<VendorAddress[]> {
    return this.http.get<VendorAddress[]>(this.apiUrl + '/getAddresses/' + vendorId).pipe(
      tap(_ => this.messages.add("Vendor Service: Fetched Vendor Addresses Successfully", MessageType.SUCCESS, true)),
      catchError(this.handleError<VendorAddress[]>("Fetch Customer ASL List", null))
    );
  }

  static getStatusColorClass(date: Date): string {
    if (date == null)
      return "text-secondary";

    var weeksLeft: number = UtilityService.getWeeksRemaining(new Date(date), false);

    if (weeksLeft > 52) {
      return "text-success";
    }
    else if (weeksLeft > 26) {
      return "text-warning"
    }

    return "text-danger";
  }

  static getScoreStyles(score: number, fill?: boolean): any {
    fill = fill || false;
    var colorCode = Math.ceil(score / 25);
    var color = "#ccc";

    switch (colorCode) {
      case 0://0
        color = "#DD1818";
        return fill ? { "background": color } : { "border-color": color };
      case 1://1-24
        color = "#DD1818";
        return fill ? { "background": color } : { "border-right-color": color };
      case 2://24-49
        color = "#FFA700";
        return fill ? { "background": color } : { "border-right-color": color, "border-bottom-color": color };
      case 3://50-74
        color = "#EDC900";
        return fill ? { "background": color } : { "border-right-color": color, "border-bottom-color": color, "border-left-color": color };
      case 4://75-100
        color = "#08AD4B";
        return fill ? { "background": color } : { "border-color": color };
    }
  };

  saveAddress(vendor: Vendor, vendorAddress: VendorAddress): Observable<VendorAddress> {
    vendorAddress.vendorId = vendor.vendorId;
    if (vendorAddress.vendorAddressId == UtilityService.emptyGuid) {
      //New Item
      // The new Address will be saved automatically thanks to EFCore
      vendorAddress.address.addressId = UtilityService.newGuid();
      return this.http.post<VendorAddress>(this.apiUrl + 'address/new', vendorAddress).pipe(
        tap(_ => this.messages.add("Vendor Service: Address Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<VendorAddress>("Save New Address", vendorAddress))
      );
    }
    else {
      //Existing Item
      // Have to save the contained address manually when updating
      return this.http.post<Address>(this.baseUrl + 'api/address', vendorAddress.address).pipe(
        mergeMap(_ => {
          return this.http.post<VendorAddress>(this.apiUrl + 'address', vendorAddress)
        }),
        tap(_ => this.messages.add("Vendor Service: Address Saved Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<VendorAddress>("Update Address", vendorAddress))
      )
    }
  }

  deleteAddress(item: VendorAddress): Observable<VendorAddress> {
    // just deleting the address will automatically delete the vendoraddress via cascade
    return this.http.delete<VendorAddress>(this.baseUrl + 'api/address/' + item.addressId).pipe(
      tap(_ => this.messages.add("Vendor Service: Address Removed Successfully", MessageType.SUCCESS, true)),
      map(_ => item),
      catchError(this.handleError<VendorAddress>("Remove Vendor Address", item))
    );
  }

}
