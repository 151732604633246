<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="text-muted fas fa-chevron-down"></i></span>
  <input #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1" (focusout)="testSelection($event)" #materialInputText matInput placeholder="Select Type" (change)="setSelection($event.value)" appStaticEdit [editable]="editable" [ngModel]="value?.name" class="form-control" (keyup)="getValueList($event)" type="text" max="100" [matAutocomplete]="materialList" />
  <mat-autocomplete (opened)="getValueList($event)" autoActiveFirstOption #materialList="matAutocomplete" (optionSelected)="setSelection($event.option.value)">
    <mat-option *ngFor="let item of values" [value]="item">
      <span>{{item.name}}</span>
    </mat-option>
    <mat-option *ngIf="values?.length==0">
      <button class="float-right btn btn-primary btn-sm mt-4" (click)="onAdd()">
        <i class="fas fa-fw fa-lg fa-plus"></i> Add New Material Form
      </button>
      <p class="text-muted small text-center mt-3">
        <em>-- No Matching Forms Found --</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</div>
