<div class="text-center widgetDragHandle">
  <h5 class="bg-secondary m-0 p-2">{{widget.name}}</h5>
  <div class="widgetDropdown">
    <button class="btn btn-sm btn-dark text-dark bg-transparent dropdown-toggle" title="Show" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-ellipsis-v fa-fw text-dark"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="javascript:void(0);" (click)="emitRemove()">
        Remove
      </a>
    </div>
  </div>
</div>
<div class="widget__content" *ngIf="widget">
  <ng-container *ngComponentOutlet="widget.component"></ng-container>
</div>