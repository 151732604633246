<h1 mat-dialog-title>
  Upload & Sort Drawings
</h1>
<div mat-dialog-content class="px-0" ngForm #form="ngForm" style="height: 60vh;">
  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef style="width: 32px;">&nbsp;</th>
      <td mat-cell *matCellDef="let file" class="px-2 text-center">
        <mat-icon class="icon-offset" [svgIcon]="getIcon(file)" [class.text-danger]="!file.assignedPart" [class.text-success]="file.assignedPart"></mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef>Filename</th>
      <td mat-cell *matCellDef="let file; let i = index" class="pr-2">
        <mat-form-field appearance="outline" class="w-100 dense-field mat-input-no-message">
          <input readonly placeholder="Filename" matInput type="text" [(ngModel)]="file.filename" [name]="'files[' + i + '].filename'" class="font-weight-bold" required>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="assignedPart">
      <th mat-header-cell *matHeaderCellDef>Assigned Part</th>
      <td mat-cell *matCellDef="let file; let i = index" class="pr-2">
        <mat-form-field appearance="outline" class="w-100 dense-field mat-input-no-message">
          <input placeholder="Unmatched" matInput type="text" [(ngModel)]="file.assignedPart" #model="ngModel" [name]="'files[' + i + '].assignedPart'"
          [matAutocomplete]="auto"
          [requireSelection]="auto"
          [notNullValidator]
          >
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="productDisplayFn">
            <mat-option [value]="'NONE'" class="text-muted">
              No Part (Upload as Quote Document)
            </mat-option>
            <mat-option *ngFor="let product of filterOptions(model)" [value]="product">
              {{productDisplayFn(product)}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef style="width: 56px;">Tags</th>
      <td mat-cell *matCellDef="let file; let i = index" class="pr-2">
        <mat-form-field appearance="outline" class="w-100 dense-field" floatLabel="always">
          <mat-chip-list class="chip-list-nowrap" #chipList>
            <mat-chip
              *ngFor="let tag of file.tags; index as i"
              (removed)="removeTag(file, i)"
              selected
              >
              {{tag}}
              <button matChipRemove>
                <mat-icon>close</mat-icon>
              </button>
            </mat-chip>
            <input
              [placeholder]="file.tags.length === 0 ? 'Untagged' : undefined"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addTag(file, $event)"
              >
          </mat-chip-list>
        </mat-form-field>
      </td>
    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let op, let i = index; columns: displayedColumns;"
    >
    </tr>
  </table>
</div>
<div mat-dialog-actions class="justify-content-end mat-elevation-z5" style="
  margin-left: -24px;
  margin-right: -24px;
  padding: 0 24px;
">
  <button class="button-text" mat-stroked-button [matDialogClose]="null">Cancel</button>
  <button class="button-text" mat-flat-button color="primary" [disabled]="!form.valid" [matDialogClose]="assignments">Confirm</button>
</div>