<div class="rounded mat-elevation-z8 w-100" style="overflow: hidden;">
  <mat-progress-bar mode="indeterminate" [ngStyle]="{opacity: loading ? 1 : 0}"></mat-progress-bar>
  <div class="w-100 d-flex align-items-center pt-2 px-3" [class.loading]="loading">
    <mat-form-field appearance="outline" class="flex-grow-1 dense-field" (keydown.enter)="this.doSearch.next()">
      <mat-icon matPrefix class="text-muted">search</mat-icon>
      <input type="text" matInput [(ngModel)]="searchInput">
    </mat-form-field>
    <button mat-raised-button class="bg-primary text-white button-text mx-2" (click)="this.doSearch.next()">Search</button>
    <mat-form-field appearance="outline" class="ml-auto dense-field">
      <mat-label>Group By</mat-label>
      <mat-select [(ngModel)]="sortType" (ngModelChange)="this.doSearch.next()">
        <mat-option value="workOrder">Work Order</mat-option>
        <mat-option value="dueDate">Due Date</mat-option>
        <mat-option value="item">Required Item</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button [matMenuTriggerFor]="filterMenu" class="ml-2 text-muted">
      <mat-icon>filter_alt</mat-icon>
    </button>
  </div>
  <table #dataTable mat-table [dataSource]="dataSource" class="w-100" [class.loading]="loading">
    <ng-container matColumnDef="keyHeader">
      <td [attr.colspan]="displayedColumns.length" mat-cell *matCellDef="let item" 
      [ngClass]="{ 'alert-danger': getKey(item) === 'overdue', 'text-danger': getKey(item) === 'overdue', 'bg-light': getKey(item) !== 'overdue' }" 
      [ngStyle]="{ 'border-bottom-color': getKey(item) === 'overdue' ? '#dc3545' : '#d1d1d1' }">
        <div class="d-flex align-items-center">
          <button type="button" mat-icon-button class="mx-1" (click)="toggleKey(getKey(item))">
            <mat-icon class="mat-icon-rtl-mirror">
            {{expansionModel.isSelected(getKey(item)) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <item-type-chip *ngIf="item.mainItem" [item]="item.mainItem" class="mr-2"></item-type-chip>
          <span class="group-header">
            {{ getKeyName(item) }}
          </span>
          <span class="ml-2 small" [ngClass]="{ 'text-muted': getKey(item) !== 'overdue', 'text-danger': getKey(item) === 'overdue' }" *ngIf="sortedType !== 'item'">
            {{ item.count }} item{{item.count === 1 ? '' : 's'}}
          </span>
          <span class="ml-2 small" [ngClass]="{ 'text-muted': getKey(item) !== 'overdue', 'text-danger': getKey(item) === 'overdue' }" *ngIf="sortedType === 'item'">
            {{ item.mainItem.materialNeedId ? item.quantity.toFixed(3) : item.quantity.toFixed(0) }} item{{item.quantity === 1 ? '' : 's'}} required
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Item</th>
      <td mat-cell *matCellDef="let item">
        <div class="d-flex align-items-center">
          <item-type-chip *ngIf="sortedType !== 'item'" [item]="item" class="mr-2"></item-type-chip> <b>{{ item.name }} </b>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="qty">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <!-- todo calculate quantity -->
      <td mat-cell *matCellDef="let item"> {{ item.materialNeed ? (calculateMaterialAmount(item).toFixed(3)) : item.qty }} </td>
    </ng-container>
    <ng-container matColumnDef="workOrder">
      <th mat-header-cell *matHeaderCellDef>Work Order</th>
      <!-- todo calculate quantity -->
      <td mat-cell *matCellDef="let item"> <span [class.font-weight-bold]="sortedType === 'item'">{{ item.purchasingSheet.workOrder.workOrderNumber }}</span> </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef>Due Date</th>
      <!-- todo calculate quantity -->
      <td mat-cell *matCellDef="let item">
        <span [ngClass]="{
          'text-danger': isOverdue(item.dueDate),
          'font-weight-bold': isOverdue(item.dueDate),
          'text-muted': !item.dueDate
        }">
          {{ item.dueDate ? (item.dueDate | date) : '—' }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="validity">
      <th mat-header-cell *matHeaderCellDef>Certainty</th>
      <!-- todo calculate quantity -->
      <td mat-cell *matCellDef="let item">
        <mat-chip disableRipple class="text-uppercase font-weight-bold pr-0" [ngClass]="getValidityClass(item.purchasingSheet.validity)">
          {{ getValidityName(item.purchasingSheet.validity) }}
          <mat-icon matPrefix class="mx-1" [matTooltip]="getValidityTooltip(item.purchasingSheet.validity)" matTooltipPosition="after">info</mat-icon>
        </mat-chip>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: ['keyHeader']; when: rowIsKey"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length" class="text-muted small font-italic text-center" *ngIf="!loading">
        No prerequisites matching the parameters were found.
      </td>
    </tr>
  </table>
</div>

<mat-menu #filterMenu>
  <div class="p-3" style="margin: -8px 0;" (click)="$event.stopPropagation()">
    <div class="font-weight-bold mb-2">Filter</div>
    <div class="font-weight-bold small mb-1">Item Type</div>
    <div style="line-height: 2em;">
      <item-type-chip *ngFor="let type of possibleItemFilters" [override]="type" class="cursor-pointer mr-2" [chipClasses]="itemFilterEnabled(type) ? 'mat-elevation-z4' : ''" [disabled]="!itemFilterEnabled(type)" (click)="toggleItemFilter(type)"></item-type-chip>
    </div>
    <div class="font-weight-bold small mt-3 mb-1">Certainty</div>
    <div style="line-height: 2em;">
      <mat-chip *ngFor="let validity of possibleValidityFilters" disableRipple class="text-uppercase font-weight-bold mr-2" [ngClass]="" [ngClass]="(validityFilterEnabled(validity) ? 'mat-elevation-z4' : '') + ' ' + getValidityClass(validity)" [disabled]="!validityFilterEnabled(validity)" (click)="toggleValidityFilter(validity)" style="cursor: pointer;">{{ getValidityName(validity) }}</mat-chip>
    </div>
  </div>
</mat-menu>