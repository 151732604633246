import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WorkOrder } from '../../resources/work-order';
import { PlanningTicket } from '../../resources/planning-ticket';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'planning-ticket-info',
  templateUrl: './planning-ticket-info.component.html',
  styleUrls: ['./planning-ticket-info.component.less']
})
export class PlanningTicketInfoComponent implements OnInit {

  @Input() record: PlanningTicket;
  @Input() editing = true;
  @ViewChild('form') form: NgForm;

  constructor() { }

  ngOnInit(): void {
  }

}
