import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Product, ProductDocument } from '../../../resources/product';
import { Order, OrderProduct } from '../../../resources/order';
import { OrderDetailService } from '../order-detail.service';
import { VirtualDocument } from '../../../../common/resources/virtual-document';
import { EstimateProgressService } from '../../../services/estimate-progress.service';
import { TaskStatus } from '../../../../common/resources/estimatingtask';
import { ProductHierarchySortComponent } from '../../../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component';

@Component({
  selector: 'product-detail-new',
  templateUrl: './product-detail-new.component.html',
  styleUrls: ['./product-detail-new.component.less']
})
export class ProductDetailNewComponent implements AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService,
    private progressService: EstimateProgressService
  ) { }

  public get record() { return this.service.order }
  public get product() { return this.service.selectedProduct }
  public get editing() { return this.service.editing }

  private generateFragment(tab: number = null) {
    if (!this.product) return '';
    switch (tab ?? this.selectedTab) {
      case 0:
      case 1:
        return this.service.generateSubItemNavigationId(this.product.productId, "product");
      case 2:
        return this.service.generateSubItemNavigationId(this.product.productId, "material");
      case 3:
        return this.service.generateSubItemNavigationId(this.product.productId, "workflow", this.service.selectedWorkflowStepId);
      case 4:
        return this.service.generateSubItemNavigationId(this.product.productId, "hardware", this.service.selectedPurchasedItemId);
    }
  }

  @ViewChild('hierarchySort') hierarchySort: ProductHierarchySortComponent;
  ngAfterViewInit(): void {
    this.service.hierarchySort = this.hierarchySort;
    console.log(this.hierarchySort);
    // Update the fragment
    window.location.hash = this.generateFragment();
  }

  ngOnDestroy(): void {
    window.location.hash = '';
  }

  @Input() selectedTab = 0;
  @Output() selectedTabChange = new EventEmitter<number>();
  public onSelectedTabChange(tab: number) {
    window.location.hash = this.generateFragment(tab);
    this.selectedTabChange.emit(tab);
  }

  public convertDocuments(docs: ProductDocument[]): VirtualDocument[] {
    return docs.map(d => d.document);
  }

  public updateDocuments(docs: VirtualDocument[]) {
    this.product.documents = docs.map(d => ({
      documentDocumentId: d.documentId,
      productProductId: this.product.productId,
      document: d
    }));
  }

  public onCreated([created, parent]: [Product, Product]) {
    if (parent) this.service.notifyEdited(parent);
    this.service.selectedProductId = created.productId;
    this.service.addNewId(created.productId);
    this.service.addNewId(created.workflow.workflowId);
    const topProduct = this.service.order.products.find(tlp => tlp.productProductId === created.topParentAssemblyId)?.product;
    if (!topProduct) throw new Error('failed to get top level product for some reason!');
    this.progressService.syncOrCreateTopLevelProductTask(topProduct);
    this.selectedTab = 0;
  }

  public onCloned(params: {
    cloned: Product,
    parent: Product,
    oldIdsMap: { [oldId: string]: string }
  }) {
    const { cloned, parent, oldIdsMap } = params;
    if (parent) this.service.notifyEdited(parent);
    this.service.selectedProductId = cloned.productId;
    this.service.addClonedProductIds(cloned, false);
    this.service.mapMaterialSearchStatus(oldIdsMap);
    const topProduct = this.service.order.products.find(tlp => tlp.productProductId === cloned.topParentAssemblyId)?.product;
    if (!topProduct) throw new Error('failed to get top level product for some reason!');
    this.progressService.syncOrCreateTopLevelProductTask(topProduct);
    this.selectedTab = 0;

  }
  public onHierarchyChange($event: Product[]) {
    const prod = $event[0];
    if (!prod) return;
    this.service.onTopLevelProductChange(prod);
  }


  public getSimpleTaskStatus(prod: Product, taskType: 'material' | 'workflow' | 'hardware') {
    return this.progressService.getSimpleTaskStatus(prod.productId, taskType, this.service.selectedTopLevelProduct.productId)
  }

  public getSubassembliesStatus(prod: Product) {
    const topLevelTask = this.progressService.getProductTask(this.service.selectedTopLevelProduct.productId);
    const productTask = topLevelTask.microTaskId === prod.productId ? topLevelTask : this.progressService.getProductTask(prod.productId, topLevelTask?.subtasks);
    if (!productTask) return TaskStatus.NOT_APPLICABLE;
    const subassemblyTasks = productTask.subtasks.filter(s => !EstimateProgressService.basicEstimatingTasks.includes(s.microTaskId));
    if (subassemblyTasks.every(t => t.status === TaskStatus.DONE)) return TaskStatus.DONE;
    else if (subassemblyTasks.some(t => t.status !== TaskStatus.NOT_STARTED)) return TaskStatus.IN_PROGRESS;
    else return TaskStatus.NOT_STARTED;
  }

  
  public getMicroTaskStatusClass(status: TaskStatus) {
    return EstimateProgressService.getStatusColorClass(status);
  }

  public onProductMoved(params: {
    productId: string,
    fromId: string,
    toId: string
  }) {
    this.service.addMovedProduct(params.productId);
    this.progressService.moveTask(params.productId, params.fromId, params.toId);
  }

  public onProductDeleted(params: {
    productId: string,
    parentId: string
  }) {
    this.service.removeMovedProduct(params.productId);
    this.progressService.deleteTask(params.productId, params.parentId);
  }
}
