import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, first, map, mergeMap, pairwise, shareReplay, startWith, tap } from 'rxjs/operators';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { ShippingTicket } from '../../resources/shipping-ticket';
import { ShippingService } from '../../services/shipping.service';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { PageEvent } from '@angular/material/paginator';

interface TicketsByDate {
  day: string | null,
  overdue: boolean,
  tickets: ShippingTicket[]
}

function isToday(date: string) {
  const d = new Date(date);
  const today = new Date();
  return d.getDate() == today.getDate() &&
    d.getMonth() == today.getMonth() &&
    d.getFullYear() == today.getFullYear();
}


@Component({
  selector: 'shipping-queue',
  templateUrl: './shipping-queue.component.html',
  styleUrls: ['./shipping-queue.component.less']
})
export class ShippingQueueComponent implements OnInit {

  public incomingTickets: TicketsByDate[]
  public outgoingTickets: TicketsByDate[]
  public historyTickets: ShippingTicket[]

  public currentTab = 0;
  public incomingCount = 0;
  public outgoingCount = 0;
  public historyCount = 0;

  public get currentCount() {
    if (this.currentTab == 0) return this.incomingCount;
    else if (this.currentTab == 1) return this.outgoingCount;
    if (this.currentTab == 2) return this.historyCount;
  }

  public pageIndex = 0;

  public managerView = new FormControl<boolean>(false);
  public searchText = new FormControl<string>('');

  public selectedTicket: ShippingTicket;
  public sidenavMode: string;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    private userService: UserService,
    private navService: NavigationService,
    private shippingService: ShippingService
  ) {
    navService.setPageTitle("Shipping");
  }


  public countUrgentTickets(t: TicketsByDate[]) {
    if (!t) return 0;
    else return t.reduce((acc, grouping) => {
      if (grouping.overdue || (grouping.day && this.isToday(grouping.day))) acc += grouping.tickets.length;
      return acc;
    }, 0)
  }

  public openTicket(ticket: ShippingTicket) {
    this.sidenavMode = 'ticket';
    this.selectedTicket = ticket;
    this.sidenav.open();
  }

  public getTicketNumber = ShippingService.getTicketNumber;

  public async checkIn(ticket: ShippingTicket) {
    this.sidenavMode = 'checkIn';
    this.selectedTicket = ticket;
    this.sidenav.open();
  }

  public async checkOut(ticket: ShippingTicket) {
    this.sidenavMode = 'checkOut';
    this.selectedTicket = ticket;
    this.sidenav.open();
  }

  public isToday = isToday;

  public userIsManager() {
    return this.userService.canAccess('ShippingManager');
  }

  public forceUpdate = new BehaviorSubject<void>(null);
  public loadInfo() {
    this.forceUpdate.next();
  }

  public pageSubject = new BehaviorSubject<PageEvent>(null);

  public onPage(event: PageEvent) {
    this.pageSubject.next(event);
  }

  ngOnInit() {

    var buildingChanges = this.shippingService.$currentBuilding;
    var managerChanges = new BehaviorSubject(this.managerView.value);
    this.managerView.valueChanges.subscribe(managerChanges);

    const obs = combineLatest([
      buildingChanges,
      managerChanges,
      this.searchText.valueChanges.pipe(
        debounceTime(1000),
        startWith('')
      ),
      this.pageSubject,
      this.forceUpdate
    ]).pipe(
      map(([building, manager, searchText, page, _]) => ({
        building,
        manager,
        searchText,
        page
      })),
      startWith(null),
      pairwise(),
      // Skip this if old value is null. Otherwise, check if any of the fields that could change the result count have changed.
      // If so, reset page to 0 to ensure we aren't paginated past the total results.
      map(([o, n]) => {
        if (o === null) return n;
        // Compare old and new values.
        if (
          o.building !== n.building ||
          o.searchText !== n.searchText ||
          o.manager !== n.manager
        ) {
          if (n.page) {
            n.page.pageIndex = 0;
            this.pageIndex = 0;
          };
        }
        return n;
      }),
      shareReplay(1)
    );

    obs
      .pipe(
        tap(() => this.incomingTickets = null),
        mergeMap((item) => {
          if (!item.building) return of(null);
          return this.shippingService.getBuildingQueue(
            item.building.buildingId,
            "incoming",
            item.manager,
            item.searchText,
            item.page?.pageIndex ?? 0
          )
        })
      )
      .subscribe(r => {
          this.incomingTickets = r?.results;
          this.incomingCount = r?.resultCount;
      });

    obs
      .pipe(
        tap(() => this.outgoingTickets = null),
        mergeMap((item) => {
          if (!item.building) return of(null);
          return this.shippingService.getBuildingQueue(
            item.building.buildingId,
            "outgoing",
            item.manager,
            item.searchText,
            item.page?.pageIndex
          )
        })
      )
      .subscribe(r => {
          this.outgoingTickets = r?.results;
          this.outgoingCount = r?.resultCount;
      });

    obs
      .pipe(
        tap(() => this.historyTickets = null),
        mergeMap((item) => {
          if (!item.building) return of(null);
          return this.shippingService.getBuildingQueue(
            item.building.buildingId,
            "history",
            item.manager,
            item.searchText,
            item.page?.pageIndex ?? 0
          )
        })
      )
      .subscribe(r => {
          this.historyTickets = r?.results;
          this.historyCount = r?.resultCount;
      });
  }

  public currentBuilding = this.shippingService.$currentBuilding;

}


@Component({
  selector: 'shipping-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./shipping-queue.component.less']
})
export class ShippingQueueListComponent {

  @Input() tickets: TicketsByDate[]
  @Input() direction: "incoming" | "outgoing"

  @Output() update = new EventEmitter<void>();
  @Output() openTicketInfo = new EventEmitter<ShippingTicket>();
  @Output() finalize = new EventEmitter<ShippingTicket>();

  @ViewChild('assignUserDialogTemplate', { static: true }) assignUserDialogTemplate: TemplateRef<any>;
  @ViewChild('rescheduleDialogTemplate', { static: true }) rescheduleDialogTemplate: TemplateRef<any>;

  constructor(
    private userService: UserService,
    private shippingService: ShippingService,
    private dialog: MatDialog
  ) {
  }

  public getTicketNumber = ShippingService.getTicketNumber;

  public getRippleColor(g: TicketsByDate) {
    if (g.overdue) return 'rgb(220 53 69 / 10%)';
    else if (!g.day) return 'rgb(255 193 7 / 10%)';
    else if (this.isToday(g.day)) return 'rgb(23 162 184 / 10%)';
    else return 'rgba(0,0,0,.04)';
  }

  public isToday = isToday;

  public getDaysOverdue(ticket: ShippingTicket) {
    if (!ticket.arrivalDate) return 0;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.floor(Math.abs((new Date(ticket.arrivalDate).getTime() - new Date().getTime()) / oneDay));
  }

  public getOriginString(ticket: ShippingTicket) {
    const isInternal = ticket.originBuildingId !== null;
    if (isInternal) return `Internal shipment from ${ticket.originBuilding.name}`;
    else if (ticket.purchaseOrder) return `Vendor shipment from ${ticket.purchaseOrder.vendor.name}`
    else return "--"
  }

  public openTracking(ticket: ShippingTicket): void {
    if (ticket && ticket.shippingCarrier && ticket.trackingNumber) {
      window.open(ticket.shippingCarrier.trackingProvider + ticket.trackingNumber, "_blank");
    }
  }

  public getShippingCarrierIcon(ticket: ShippingTicket): string {
    if (!ticket || !ticket.shippingCarrier) return null;

    return `${(new URL(ticket.shippingCarrier.trackingProvider)).origin}/favicon.ico`;
  }

  public async assignTicket(ticket: ShippingTicket) {
    const dialogRef = this.dialog.open<any, any, User | false>(this.assignUserDialogTemplate, {
      disableClose: true,
      minWidth: 300,
      data: { ticket, employee: ticket.employee }
    });
    const user = await dialogRef.afterClosed().toPromise();
    if (user === false) return;
    await this.shippingService.assignTicket(ticket.shippingTicketId, user.userId).toPromise();
    this.update.emit();
  }

  public async rescheduleTicket(ticket: ShippingTicket) {
    const dialogRef = this.dialog.open<any, any, Date | false>(this.rescheduleDialogTemplate, {
      disableClose: true,
      minWidth: 300,
      data: { ticket, date: this.direction === 'incoming' ? ticket.arrivalDate : ticket.departureDate }
    });
    const date = await dialogRef.afterClosed().toPromise();
    if (date === false) return;
    if (this.direction === 'incoming') {
      ticket.arrivalDate = date;
    } else {
      ticket.departureDate = date;
    }
    await this.shippingService.save(ticket).toPromise();
    this.update.emit();
  }

  // TODO
  public userIsManager() {
    return this.userService.canAccess('Developer');
  }

}
