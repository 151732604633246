import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../../common/services/utility.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { MbscEventcalendarView } from '@mobiscroll/angular';
import { HttpClient } from '@angular/common/http';
import { MbscCalendarEvent, MbscDatepickerOptions, MbscEventcalendarOptions, MbscPopup, MbscPopupOptions, formatDate, Notifications, setOptions } from '@mobiscroll/angular';
import { FormControl, Validators } from '@angular/forms';
import { EmployeeShiftAssignmentService } from '../../services/employee-shift-assignment.service';
import { StaffUtilizationService } from '../../services/staff-utilization.service';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';
import { SearchList } from '../../../common/components/search-list/search-list';
import { Shift } from '../../resources/shift';
import { ResourceEmployee } from '../../resources/resource-employee';
import { EmployeeShiftAssignment } from '../../resources/employee-shift-assignment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RecurrenceFrequency, ShiftAssignmentRecurrence } from '../../resources/shift-assignment-recurrence';
import { MatChip } from '@angular/material/chips';
import { FloorService } from '../../../floor/services/floor.service';
import { FloorBuilding, Department } from '../../../floor/resources/building';

setOptions({
  theme: 'windows',
  themeVariant: 'light'
});


@Component({
  selector: 'staff-utilization.component',
  templateUrl: './staff-utilization.component.html',
  styleUrls: ['./staff-utilization.component.less']
})
export class StaffUtilizationComponent extends SearchList<User> {

  view = 'week';
  calView: MbscEventcalendarView = {
    timeline: {
      type: 'week',
      eventList: true
    }
  };

  public arrStaffUtilization: MbscCalendarEvent[];
  public arrAssignedMbscCalendarEvent: MbscCalendarEvent[];
  public arrAvailableShifts: Shift[];
  public myResourcesEmployee: ResourceEmployee[] | null = null;
  public myInvalids: any = null;

  public departments: FloorBuilding[];
  public buildings: Department[];
  public selectedDepartment: string = "0";
  public selectedBuilding: string = "0";


  constructor(private staffUtilizationService: StaffUtilizationService, private employeeShiftAssignmentService: EmployeeShiftAssignmentService, private userService: UserService,
    private floorService: FloorService, 
    private utilitySvc: UtilityService, private navService: NavigationService, private router: Router,
    private http: HttpClient, private notify: Notifications, private matSnackBar: MatSnackBar) {
    super('staff-utilization');
    this.navService.pushBreadcrumb("Staff Utilization");
    navService.setPageTitle("Staff Utilization");
  }

  ngOnInit(): void {
    this.getFilters();
    this.getData();
  }

  public async getFilters() {

    this.floorService.getAllFloorBuildings().subscribe(d => {
      this.departments = d.results;
    });

    this.floorService.search().subscribe(b => {
      this.buildings = b.results;
    });
  }


  public async getData() {
    this.myInvalids = this.employeeShiftAssignmentService.getCalendarBlockedOutDates();

    this.employeeShiftAssignmentService.getAvailableShifts().subscribe(data => {
      this.arrAvailableShifts = data;
      //console.log("getData() - getAvailableShifts() - this.arrAvailableShifts.length= " + this.arrAvailableShifts.length);
    });

    this.search();  //get Employees

    await this.staffUtilizationService.getStaffUtilization().then(() => {
      this.arrStaffUtilization = this.staffUtilizationService.arrStaffUtilization;
      //console.log("getData() - this.arrStaffUtilization.length= " + this.arrStaffUtilization.length);
    });
  }

  protected async search() {
    this.results = null;
    var filter = "";

    this.userService.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection, filter).subscribe(results => {
      //console.log("search() - Start - results.results.length= " + results.results.length);

      this.searchResults = results;
      this.results = results.results
      this.myResourcesEmployee = this.employeeShiftAssignmentService.map_ArrUser_To_ArryResourcesEmployee(this.results);

    });
  }

  public onSelect(): void {
    //this.router.navigate(['/staff-utilization-detail', record.userId]);
    console.log("onSelect() - Start");
  }


  rangeOptions: MbscDatepickerOptions = {
    controls: ['time'],
    select: 'range',
    display: 'anchored',
    showRangeLabels: false,
    touchUi: false,
    stepMinute: 30
  };


  calendarOptions: MbscEventcalendarOptions = {
    view: {
      timeline: {
        type: 'week',
        eventList: true,
        startDay: 1,
        endDay: 5
      }
    },
    dragToCreate: false,
    dragToResize: false,
    dragToMove: false,
    clickToCreate: 'double',
    resources: this.myResourcesEmployee,
    invalid: this.myInvalids,
    exclusiveEndDates: true,

    onEventClick: (event, inst) => {
      console.log("onEventClick() - ");
    },

    onEventCreated: (event, inst) => {
      console.log("onEventCreated() - ");

    },

  };



  changeView(): void {
    setTimeout(() => {
      switch (this.view) {
        case 'day':
          this.calView = {
            timeline: { type: 'day' }
          };
          break;
        case 'workweek':
          this.calView = {
            timeline: {
              type: 'week',
              eventList: true,
              startDay: 1,
              endDay: 5
            }
          };
          break;
        case 'week':
          this.calView = {
            timeline: {
              type: 'week',
              eventList: true
            }
          };
          break;
        case 'month':
          this.calView = {
            timeline: {
              type: 'month',
              eventList: true
            }
          };
          break;
      }
    });
  }



}
