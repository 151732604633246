import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../../order/resources/product';
import { CustomerPurchaseOrder } from '../../../order/resources/customer-po';
import { WorkOrder } from '../../resources/work-order';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { auditTime, debounceTime, map, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { StationService } from '../../../order/services/station.service';

import * as Highcharts from 'highcharts/highstock';
import gantt from 'highcharts/modules/gantt';
import HighchartsCustomEvents from 'highcharts-custom-events';
import { ProductTimingService, TimingData, TimingDataPurchasedItem, TimingDataWorkflow } from '../../../order/services/product-timing.service';
import { PlanningService } from '../../services/planning.service';
import { PlanningDetailService } from '../planning-detail.service';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';

gantt(Highcharts);
HighchartsCustomEvents(Highcharts);

var matColors = {
  "red": {
    "50": "#ffebee",
    "100": "#ffcdd2",
    "200": "#ef9a9a",
    "300": "#e57373",
    "400": "#ef5350",
    "500": "#f44336",
    "600": "#e53935",
    "700": "#d32f2f",
    "800": "#c62828",
    "900": "#b71c1c",
    "hex": "#f44336",
    "a100": "#ff8a80",
    "a200": "#ff5252",
    "a400": "#ff1744",
    "a700": "#d50000"
  },
  "pink": {
    "50": "#fce4ec",
    "100": "#f8bbd0",
    "200": "#f48fb1",
    "300": "#f06292",
    "400": "#ec407a",
    "500": "#e91e63",
    "600": "#d81b60",
    "700": "#c2185b",
    "800": "#ad1457",
    "900": "#880e4f",
    "hex": "#e91e63",
    "a100": "#ff80ab",
    "a200": "#ff4081",
    "a400": "#f50057",
    "a700": "#c51162"
  },
  "purple": {
    "50": "#f3e5f5",
    "100": "#e1bee7",
    "200": "#ce93d8",
    "300": "#ba68c8",
    "400": "#ab47bc",
    "500": "#9c27b0",
    "600": "#8e24aa",
    "700": "#7b1fa2",
    "800": "#6a1b9a",
    "900": "#4a148c",
    "hex": "#9c27b0",
    "a100": "#ea80fc",
    "a200": "#e040fb",
    "a400": "#d500f9",
    "a700": "#aa00ff"
  },
  "deepPurple": {
    "50": "#ede7f6",
    "100": "#d1c4e9",
    "200": "#b39ddb",
    "300": "#9575cd",
    "400": "#7e57c2",
    "500": "#673ab7",
    "600": "#5e35b1",
    "700": "#512da8",
    "800": "#4527a0",
    "900": "#311b92",
    "hex": "#673ab7",
    "a100": "#b388ff",
    "a200": "#7c4dff",
    "a400": "#651fff",
    "a700": "#6200ea"
  },
  "indigo": {
    "50": "#e8eaf6",
    "100": "#c5cae9",
    "200": "#9fa8da",
    "300": "#7986cb",
    "400": "#5c6bc0",
    "500": "#3f51b5",
    "600": "#3949ab",
    "700": "#303f9f",
    "800": "#283593",
    "900": "#1a237e",
    "hex": "#3f51b5",
    "a100": "#8c9eff",
    "a200": "#536dfe",
    "a400": "#3d5afe",
    "a700": "#304ffe"
  },
  "blue": {
    "50": "#e3f2fd",
    "100": "#bbdefb",
    "200": "#90caf9",
    "300": "#64b5f6",
    "400": "#42a5f5",
    "500": "#2196f3",
    "600": "#1e88e5",
    "700": "#1976d2",
    "800": "#1565c0",
    "900": "#0d47a1",
    "hex": "#2196f3",
    "a100": "#82b1ff",
    "a200": "#448aff",
    "a400": "#2979ff",
    "a700": "#2962ff"
  },
  "lightBlue": {
    "50": "#e1f5fe",
    "100": "#b3e5fc",
    "200": "#81d4fa",
    "300": "#4fc3f7",
    "400": "#29b6f6",
    "500": "#03a9f4",
    "600": "#039be5",
    "700": "#0288d1",
    "800": "#0277bd",
    "900": "#01579b",
    "hex": "#03a9f4",
    "a100": "#80d8ff",
    "a200": "#40c4ff",
    "a400": "#00b0ff",
    "a700": "#0091ea"
  },
  "cyan": {
    "50": "#e0f7fa",
    "100": "#b2ebf2",
    "200": "#80deea",
    "300": "#4dd0e1",
    "400": "#26c6da",
    "500": "#00bcd4",
    "600": "#00acc1",
    "700": "#0097a7",
    "800": "#00838f",
    "900": "#006064",
    "hex": "#00bcd4",
    "a100": "#84ffff",
    "a200": "#18ffff",
    "a400": "#00e5ff",
    "a700": "#00b8d4"
  },
  "teal": {
    "50": "#e0f2f1",
    "100": "#b2dfdb",
    "200": "#80cbc4",
    "300": "#4db6ac",
    "400": "#26a69a",
    "500": "#009688",
    "600": "#00897b",
    "700": "#00796b",
    "800": "#00695c",
    "900": "#004d40",
    "hex": "#009688",
    "a100": "#a7ffeb",
    "a200": "#64ffda",
    "a400": "#1de9b6",
    "a700": "#00bfa5"
  },
  "green": {
    "50": "#e8f5e9",
    "100": "#c8e6c9",
    "200": "#a5d6a7",
    "300": "#81c784",
    "400": "#66bb6a",
    "500": "#4caf50",
    "600": "#43a047",
    "700": "#388e3c",
    "800": "#2e7d32",
    "900": "#1b5e20",
    "hex": "#4caf50",
    "a100": "#b9f6ca",
    "a200": "#69f0ae",
    "a400": "#00e676",
    "a700": "#00c853"
  },
  "lightGreen": {
    "50": "#f1f8e9",
    "100": "#dcedc8",
    "200": "#c5e1a5",
    "300": "#aed581",
    "400": "#9ccc65",
    "500": "#8bc34a",
    "600": "#7cb342",
    "700": "#689f38",
    "800": "#558b2f",
    "900": "#33691e",
    "hex": "#8bc34a",
    "a100": "#ccff90",
    "a200": "#b2ff59",
    "a400": "#76ff03",
    "a700": "#64dd17"
  },
  "lime": {
    "50": "#f9fbe7",
    "100": "#f0f4c3",
    "200": "#e6ee9c",
    "300": "#dce775",
    "400": "#d4e157",
    "500": "#cddc39",
    "600": "#c0ca33",
    "700": "#afb42b",
    "800": "#9e9d24",
    "900": "#827717",
    "hex": "#cddc39",
    "a100": "#f4ff81",
    "a200": "#eeff41",
    "a400": "#c6ff00",
    "a700": "#aeea00"
  },
  "yellow": {
    "50": "#fffde7",
    "100": "#fff9c4",
    "200": "#fff59d",
    "300": "#fff176",
    "400": "#ffee58",
    "500": "#ffeb3b",
    "600": "#fdd835",
    "700": "#fbc02d",
    "800": "#f9a825",
    "900": "#f57f17",
    "hex": "#ffeb3b",
    "a100": "#ffff8d",
    "a200": "#ffff00",
    "a400": "#ffea00",
    "a700": "#ffd600"
  },
  "amber": {
    "50": "#fff8e1",
    "100": "#ffecb3",
    "200": "#ffe082",
    "300": "#ffd54f",
    "400": "#ffca28",
    "500": "#ffc107",
    "600": "#ffb300",
    "700": "#ffa000",
    "800": "#ff8f00",
    "900": "#ff6f00",
    "hex": "#ffc107",
    "a100": "#ffe57f",
    "a200": "#ffd740",
    "a400": "#ffc400",
    "a700": "#ffab00"
  },
  "orange": {
    "50": "#fff3e0",
    "100": "#ffe0b2",
    "200": "#ffcc80",
    "300": "#ffb74d",
    "400": "#ffa726",
    "500": "#ff9800",
    "600": "#fb8c00",
    "700": "#f57c00",
    "800": "#ef6c00",
    "900": "#e65100",
    "hex": "#ff9800",
    "a100": "#ffd180",
    "a200": "#ffab40",
    "a400": "#ff9100",
    "a700": "#ff6d00"
  },
  "deepOrange": {
    "50": "#fbe9e7",
    "100": "#ffccbc",
    "200": "#ffab91",
    "300": "#ff8a65",
    "400": "#ff7043",
    "500": "#ff5722",
    "600": "#f4511e",
    "700": "#e64a19",
    "800": "#d84315",
    "900": "#bf360c",
    "hex": "#ff5722",
    "a100": "#ff9e80",
    "a200": "#ff6e40",
    "a400": "#ff3d00",
    "a700": "#dd2c00"
  },
  "brown": {
    "50": "#efebe9",
    "100": "#d7ccc8",
    "200": "#bcaaa4",
    "300": "#a1887f",
    "400": "#8d6e63",
    "500": "#795548",
    "600": "#6d4c41",
    "700": "#5d4037",
    "800": "#4e342e",
    "900": "#3e2723",
    "hex": "#795548"
  },
  "grey": {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#eeeeee",
    "300": "#e0e0e0",
    "400": "#bdbdbd",
    "500": "#9e9e9e",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121",
    "hex": "#9e9e9e"
  },
  "blueGrey": {
    "50": "#eceff1",
    "100": "#cfd8dc",
    "200": "#b0bec5",
    "300": "#90a4ae",
    "400": "#78909c",
    "500": "#607d8b",
    "600": "#546e7a",
    "700": "#455a64",
    "800": "#37474f",
    "900": "#263238",
    "hex": "#607d8b"
  },
  "black": {
    "hex": "#000000"
  },
  "white": {
    "hex": "#ffffff"
  }
}
function shadeColor(color, percent) {

  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  // @ts-expect-error
  R = parseInt(R * (100 + percent) / 100);
  // @ts-expect-error
  G = parseInt(G * (100 + percent) / 100);
  // @ts-expect-error
  B = parseInt(B * (100 + percent) / 100);

  R = (R < 255) ? R : 255;
  G = (G < 255) ? G : 255;
  B = (B < 255) ? B : 255;

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
  var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
  var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

  return "#" + RR + GG + BB;
}
type Item = {
  itemId: string;
  type: string;
  segments: Segment[],
  originalData?: any
};

type Segment = {
  startAtHour: number,
  endAtHour: number,
  type: string,
};

const ganttBarOptions = {
  minPointLength: 5,
  pointPadding: 0.05,
  groupPadding: 0.05,
  pointWidth: 16,
}

@Component({
  selector: 'planning-waterfall-graph',
  templateUrl: './planning-waterfall-graph.component.html',
  styleUrls: ['./planning-waterfall-graph.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningWaterfallGraphComponent implements OnInit, AfterViewInit {
  @Input() workOrder: WorkOrder = <any>{ dueDate: "2024/10/08 " };
  @Input() productData$: Observable<Product>;

  public data$: Observable<TimingData>;
  public options: any;

  public get endDate() {
    // return new Date(this.workOrder.dueDate);
    const dueDateWithBuffer = (new Date(this.workOrder.dueDate));
    dueDateWithBuffer.setDate(dueDateWithBuffer.getDate() + 7)
    const today = new Date();
    if (today > dueDateWithBuffer) return today;
    else return dueDateWithBuffer;
  }

  public getTotalWorkHours(data: TimingData) {
    const lastStep = data.workflow[data.workflow.length - 1];
    const lastSegment = lastStep.segments[lastStep.segments.length - 1];
    return lastSegment.endAtHour;
  }

  public getStartDate(data: TimingData): Date {
    const dueDate = (new Date(this.workOrder.dueDate));
    return new Date(dueDate.getTime() - (this.getTotalWorkHours(data) * 60 * 60 * 1000));
  }

  public getTimeSpan(data: TimingData) {
    return this.endDate.getTime() - this.getStartDate(data).getTime();
  }

  public get today() {
    return new Date();
  }

  public get dueDate() {
    return new Date(this.workOrder.dueDate);
  }

  constructor(private stationService: StationService, private timingService: ProductTimingService, private detailService: PlanningDetailService, private quoteService: MaterialBidService) { }

  private productWithChildren$: Observable<Product>;
  ngOnInit(): void {
    this.timingService.resetData();
    this.timingService.setCallback(this.detailService.getQuote.bind(this.detailService));
    this.productWithChildren$ = this.productData$.pipe(map(product => {
      product.childAssemblies = this.detailService.getChildren(product.productId);
      return product;
    }));
    this.data$ = combineLatest([this.productWithChildren$, this.detailService.quotesMap]).pipe(
      auditTime(1000),
      switchMap(async ([prod, _quotes]) => {
        this.timingService.resetProduct(prod);
        const newTopLevel = this.detailService.getTopPartWithUpdate(prod);
        await this.timingService.updateDataForProduct(newTopLevel, newTopLevel.orderQuantity);
        const data = this.timingService.getDataForProduct(prod, newTopLevel.orderQuantity);
        return data;
      })
    )
  }

  public getItemName(type: 'material' | 'purchased' | 'workflow', item: TimingDataWorkflow | TimingDataPurchasedItem | TimingData['material'], product: Product) {
    switch (type) {
      case 'material':
        return 'Material';
      case 'purchased':
        // todo: check if this is purchasedItemId or productPurchasedItemId
        const pi = product.purchasedItems.find(i => i.purchasedItemId === (item as TimingDataPurchasedItem).itemId);
        return pi?.purchasedItem?.description ?? 'Unknown Purchased Item';
      case 'workflow':
        const step = product.workflow.workflowSteps.find(i => i.workflowStepId === (item as TimingDataWorkflow).stepId);
        if (!step) return 'Unknown Workflow Step';
        return this.stationService.stationList.find(s => s.stationId === step.stationId)?.name ?? 'Unknown Workflow Step';
      default:
        'Unknown Item';
    }
  }

  public getItemColor(data: TimingData, type: 'material' | 'purchased' | 'workflow', item: TimingDataWorkflow | TimingDataPurchasedItem | TimingData['material']) {
    switch (type) {
    case 'material':
      return '#FD7037';
    case 'purchased':
      const count = data.purchasedItems.length;
      const index = data.purchasedItems.findIndex(i => i.itemId === (item as TimingDataPurchasedItem).itemId);
      const pct = (index / count) * 100;
      return shadeColor('#0180ba', pct);
    case 'workflow':
      const quantity = Object.keys(matColors).length;
      const stepIdx = data.workflow.findIndex(i => i.stepId === (item as TimingDataWorkflow).stepId);
      var colorList = Object.values(matColors)[stepIdx % quantity];
      return colorList['500'];
    default:
      'Unknown Item';
    }

  }

  private hoursToMS(hours: number) {
    return hours * 60 * 60 * 1000;
  }

  private toGraphTime(hour: number, data: TimingData) {
    return this.getStartDate(data).getTime() + (this.hoursToMS(hour));
  }

  public getPurchasedItemsStart(data: TimingData): number {
    const allSegments = data.purchasedItems?.flatMap(pi => pi.segments);
    if (!allSegments || allSegments.length === 0) return 0;
    return this.toGraphTime(Math.min(...allSegments.map(seg => seg.startAtHour)), data);
  }

  public getPurchasedItemsEnd(data: TimingData): number {
    const allSegments = data.purchasedItems?.flatMap(pi => pi.segments);
    if (!allSegments || allSegments.length === 0) return 0;
    return this.toGraphTime(Math.max(...allSegments.map(seg => seg.endAtHour)), data);
  }
  public getSubassembliesStart(data: TimingData): number {
    const allSegments = data.subassemblies?.flatMap(s => s);
    if (!allSegments || allSegments.length === 0) return 0;
    return this.toGraphTime(Math.min(...allSegments.map(seg => seg.startAtHour)), data);
  }

  public getSubassembliesEnd(data: TimingData): number {
    const allSegments = data.subassemblies?.flatMap(s => s);
    if (!allSegments || allSegments.length === 0) return 0;
    return this.toGraphTime(Math.max(...allSegments.map(seg => seg.endAtHour)), data);
  }

  public 

  public chartOptions$: Observable<Highcharts.Options>;
  public Highcharts = Highcharts;
  public chart: Highcharts.Chart;
  public purchasedItemsToggled = new BehaviorSubject(true);
  public subassembliesToggled = new BehaviorSubject(true);
  public workflowToggled = new BehaviorSubject(true);
  @ViewChild('chartElement') chartElement: ElementRef<any>;
  ngAfterViewInit() {

    this.chartOptions$ = combineLatest([
      this.data$, this.productData$, this.purchasedItemsToggled, this.workflowToggled, this.subassembliesToggled
    ]).pipe(
      auditTime(1000),
      map(
      ([data, product, piToggle, workflowToggle, subassembliesToggle]) => {
      // data.subassemblies = [];
      // data.purchasedItems = [];
      const series = [
        {
          name: `Material`,
          id: 'material',
          type: <any>'gantt',
          y: 0,
          data: data.material ?  data.material.segments.map(seg => ({
            name: seg.tag === 'WARNING' ? 'Purchasing Warning Time' : 'Material',
            ...ganttBarOptions,
            color: seg.tag === 'WARNING' ? 'red' : '#FD7037',
            start: (this.getStartDate(data).getTime() + (this.hoursToMS(seg.startAtHour))),
            end: (this.getStartDate(data).getTime() + (this.hoursToMS(seg.endAtHour))),
          })) : []
        },
        {
          id: 'purchased-items-header',
          data: data.purchasedItems?.length ? [{
            name: 'Purchased Items',
            id: 'purchased-items-header-item',
            y: data.material ? 1 : 0,
            collapsed: true,
            pointWidth: 8,
            start: this.getPurchasedItemsStart(data),
            end: this.getPurchasedItemsEnd(data),
            color: '#0D47A1',
          }] : []
        },
        {
          type: <any>'gantt',
          id: 'purchased-items',
          data: piToggle ? data.purchasedItems.flatMap((i, y) => i.segments.map(seg => ({
            name: seg.tag === 'WARNING' ? 'Purchasing Warning Time' : this.getItemName('purchased', i, product),
            y: (data.material ? 1 : 0) + (data.purchasedItems?.length ? 1 : 0) + y,
            ...ganttBarOptions,
            color: seg.tag === 'WARNING' ? 'red' : shadeColor('#0180ba', (y / data.purchasedItems.length) * 100),
            start: this.toGraphTime(seg.startAtHour, data),
            end: this.toGraphTime(seg.endAtHour, data),
          }))) : []
        },
        {
          id: 'subassemblies-header',
          data: data.subassemblies?.length ? [{
            name: 'Subassemblies',
            id: 'subassemblies-header-item',
            y: (data.material ? 1 : 0) + (data.purchasedItems?.length ? 1 : 0) + (piToggle ? data.purchasedItems.length : 0),
            collapsed: true,
            pointWidth: 8,
            start: this.getSubassembliesStart(data),
            end: this.getSubassembliesEnd(data),
            color: '#263238',
          }] : []
        },
        {
          type: <any>'gantt',
          id: 'subassembly-items',
          data: subassembliesToggle ? data.subassemblies.map((i, y) => ({
            name: this.detailService.getProductName(i.productId),
            y: (data.material ? 1 : 0) + (data.purchasedItems?.length ? 1 : 0) + (piToggle ? data.purchasedItems.length : 0) + (data.subassemblies.length ? 1 : 0) + y,
            ...ganttBarOptions,
            start: this.toGraphTime(i.startAtHour, data),
            end: this.toGraphTime(i.endAtHour, data),
            color: shadeColor('#37474F', (y / data.subassemblies.length) * 100),
          })) : []
        },
        {
          id: 'workflow-header',
          data: (data.workflow?.length ? [{
            name: 'Workflow',
            y: (data.material ? 1 : 0) + (data.purchasedItems?.length ? 1 : 0) + (piToggle ? data.purchasedItems.length : 0) + (data.subassemblies.length ? 1 : 0) + (piToggle ? data.subassemblies.length : 0),
            pointWidth: 8,
            start: this.toGraphTime(data.workflow[0]?.segments[0].startAtHour, data),
            end: this.toGraphTime(data.workflow[data.workflow.length - 1].segments[data.workflow[data.workflow.length - 1].segments.length - 1].endAtHour, data),
            color: '#817616',
          }] : [])
        },
        {
          type: <any>'gantt',
          id: 'workflow-items',
          data: workflowToggle ? data.workflow.flatMap((i, y) => i.segments.map(seg => ({
            name: seg.tag === 'WARNING' ? 'Purchasing Warning Time' : this.getItemName('workflow', i, product),
            // id: this.getItemName('workflow', i, product).includes('Assembly') ? 'assembly' : null,
            y: (data.material ? 1 : 0) + (data.purchasedItems?.length ? 1 : 0) + (piToggle ? data.purchasedItems.length : 0) + (data.subassemblies.length ? 1 : 0) + (piToggle ? data.subassemblies.length : 0) + 1 + y,
            ...ganttBarOptions,
            start: this.toGraphTime(seg.startAtHour, data),
            end: this.toGraphTime(seg.endAtHour, data),
            color: seg.tag === 'WARNING' ? 'red' : undefined,
            dependency: this.getItemName('workflow', i, product).includes('Assembly') ? [
              { id: 'purchased-items-header-item', endMarker: { enabled: false } }, { id: 'subassemblies-header-item', endMarker: { enabled: false } }] : [],
          }))) : []
        },
      ];
      console.log(series.length);
      return <Highcharts.Options>{

        chart: <Highcharts.ChartOptions>{
          styledMode: false,
          style: {
            fontFamily: 'Roboto',
          },
          animation: false,
          zooming: {
            key: 'alt',
            type: 'x',
            resetButton: {
              position: {
                align: 'right', verticalAlign: 'top'
              },
              relativeTo: 'plotBox'
            },
            
            mouseWheel: {
              // enabled: false,
            }
          },
          panKey: 'ctrl',
          panning: {
            enabled: true,
            type: 'x'
          }
        },
        plotOptions: {
          series: {
            showInNavigator: true,
          }
        },
        // navigator: {
        //   enabled: true,
        //   series: series.filter((s) => ['material', 'purchased-items-header', 'subassembly-header', 'workflow-header'].includes(s.id)).map(() => ({
        //     type: 'gantt',
        //     pointPadding: 0.25,
        //     // pointWidth: 3
        //   })),
        //   yAxis: {
        //     // reversed: true,
        //   }
        // },
        rangeSelector: {
          enabled: true,
          inputEnabled: false,
          selected: 100,
          buttonPosition: {
            align: 'right',
            x: 0,
            y: 0
          },
          buttons: [
            { type: 'year', count: 1, text: '1y' },
            { type: 'ytd', text: 'YTD' },
            { type: 'month', count: 6, text: '6m' },
            { type: 'month', count: 3, text: '3m' },
            { type: 'month', count: 1, text: '1m' },
            { type: 'week', count: 2, text: '2w' },
            { type: 'week', count: 1, text: '1w' },
            {
              text: 'Now',
              title: 'From Now',
              events: {
                click: (ctx) => {
                  this.chart?.xAxis[0].setExtremes(new Date().getTime(), this.endDate.getTime())
                  return false
                }
              }
            },
            {
              text: 'WF',
              title: 'Workflow Only',
              events: {
                click: (ctx) => {
                  const firstStep = data.workflow[0];
                  if (!firstStep || !firstStep.segments[0]) return false;
                  const lastStep = data.workflow[data.workflow.length - 1];
                  const firstSegment = firstStep.segments[0];
                  const lastSegment = lastStep.segments[lastStep.segments.length - 1];
                  this.chart?.xAxis[0].setExtremes(this.toGraphTime(firstSegment.startAtHour, data), this.toGraphTime(lastSegment.endAtHour, data))
                  return false;
                }
              }
            },
            { type: 'all', text: 'all',
              events: {
                click: () => {
                  this.chart?.xAxis[0].setExtremes(this.getStartDate(data).getTime(), this.endDate.getTime())
                  return false
                }
              }
           },
          ]
        },
        xAxis: [null, null].map(() => (
          {
            min: this.getStartDate(data).getTime(),
            max: this.endDate.getTime() + this.getTimeSpan(data) * 0.05,
            gridLineWidth: 1,
            minorGridLineWidth: 1,
            grid: {
              // borderWidth: 0,
              cellHeight: 25,
              // enabled: false,
            },
            labels: {
              align: 'center',
              style: {
                fontSize: '11px',
                fontWeight: '600',
                height: 15,
                cursor: 'pointer'
              },
            },
            currentDateIndicator: {
              color: 'rgba(0,0,0,0.25)',
              zIndex: 5,
              width: 1,
              dashStyle: 'ShortDash',
              label: {
                // align: 'center',
                format: 'TODAY',
                y: 5,
                rotation: 90,
                textAlign: 'left',
                style: { fontSize: '10px', fontWeight: '600', color: 'rgba(0,0,0,0.35)' }
              }
            },
            plotLines: [{
              value: this.dueDate.getTime(),
              color: 'red',
              zIndex: 5,
              label: {
                y: 5,
                textAlign: 'left',
                text: 'DUE',
                style: { fontSize: '10px', fontWeight: '600', color: 'red' }
              }
            }],
            plotBands: !!data.components.bufferDays ? [{
              from: this.dueDate.getTime(),
              to: this.dueDate.getTime() - data.components.bufferDays * 24 * 60 * 60 * 1000,
              color: 'rgba(0,0,0,0.03)',
              zIndex: -1,
              label: {
                text: 'BUFFER',
                style: { fontSize: '10px', fontWeight: '600', color: 'rgba(0,0,0,0.25)' }
              }
            }] : [],
          }
        )),
        yAxis: {
          min: -1,
          minRange: 15,
          // @ts-ignore
          categories: [
            ...(data.material ? ['Material'] : []),
            ...(data.purchasedItems?.length ? ['Purchased Items'] : []),
            ...(piToggle ? data.purchasedItems.map((i, y) => this.getItemName('purchased', i, product)) : []),
            ...(data.subassemblies?.length ? ['Subassemblies'] : []),
            ...(subassembliesToggle ? data.subassemblies.map((i, y) => this.detailService.getProductName(i.productId)) : []),
            ...(data.workflow.length ? ['Workflow'] : []),
            ...(workflowToggle ? data.workflow.map((i, y) => this.getItemName('workflow', i, product)) : [])
          ],
          staticScale: 20,
          labels: {
            // align: 'right',
            style: {
              fontSize: '11px',
              fontWeight: '600',
            },
            formatter: (ctx) => isNaN(parseInt(ctx.value as string)) ? ctx.value : '',
            events: {
              click: (e) => {
                if (e.srcElement.textContent === 'Purchased Items') {
                  const series = this.chart.get('purchased-items') as Highcharts.Series;
                  const visible = series.visible;
                  console.log(visible);
                  series.setVisible(!visible, true);
                }
                if (e.srcElement.textContent === 'Workflow') {
                  // this.workflowToggled.pipe(take(1)).subscribe(val => this.workflowToggled.next(!val));
                }
              }
            }
          },
          max: 20,
          scrollbar: {
            enabled: true,
            showFull: false
          },
        },

        series,
      };
    }),
    tap((a) => {
      setTimeout(() => { if (this.chart) this.chart.update({ series: a.series }) })
    })
    );
  }

}
