<div class="container-fluid">
  <!-- Control buttons -->
  <a
    *ngIf="!editing"
    class="position-fixed fixed-bottom btn btn-badge has-icon"
    title="Edit Record"
    (click)="startEditing()">
      <i class="fas fa-edit"></i>
  </a>

  <a
    *ngIf="editing"
    class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6"
    title="Cancel Edit"
    (click)="stopEditing()">
      <i class="fas fa-times"></i>
  </a>

  <a
    *ngIf="editing"
    class="position-fixed fixed-bottom btn btn-badge bg-success has-icon"
    title="Save Record"
    (click)="saveChanges()">
      <i class="fas fa-save"></i>
  </a>

  <!-- Editor form -->
  <div *ngIf="editing" class="container">
    <div class="row">

        <div class="col-6">
          <div class="form-group">
            <label for="report-name-field">Report Name</label>
            <input
              required
              type="text"
              placeholder="Widget Report"
              id="report-name-field"
              class="form-control form-control-sm"
              [(ngModel)]="report.reportTitle" />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="report-guid-field">PowerBI Report GUID</label>
            <input
              required
              type="text"
              placeholder="a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6"
              id="report-guid-field"
              class="form-control form-control-sm"
              [(ngModel)]="report.reportId" />
          </div>
        </div>

    </div>
    <div class="row form-group">
      <div class="col-12">
        <label>Available to groups:</label>
      </div>
    </div>
    <div class="row">

      <div class="col-3" *ngFor="let group of availableGroups">
        <label>
          <mat-slide-toggle></mat-slide-toggle>
          {{ group.niceName }}
        </label>
      </div>

    </div>
  </div>

  <!-- Embed container -->
  <div *ngIf="!editing" class="row">
    <div #reportEmbedContainer id="report-embed-container"></div>
  </div>
</div>
