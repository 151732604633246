<div>
  <a *ngIf="record && !editing && showEditor==null && isEditable(record?.status)"
    class="position-fixed fixed-bottom btn btn-badge has-icon" title="Edit Record" (click)="toggleEditing()"><i
      class="fas fa-edit"></i></a>
  <a *ngIf="((record && editing) || !isEditable(record?.status) ) && showEditor==null"
    class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit"
    (click)="toggleEditing()"><i class="fas fa-times"></i></a>
  <a *ngIf="record && editing && showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-success has-icon"
    title="Save Record" (click)="saveChanges(true)"><i class="fas fa-save"></i></a>

  <div class="row">

    <loading *ngIf="!record"></loading>
    <loading *ngIf="saving" content="Saving..."></loading>

    <mat-sidenav-container *ngIf="record">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <app-customer-detail [hint]="nameHint" (customerChange)="setNewCustomer($event)" [sidenav]="sidenav"
          [id]="editCustomerId" *ngIf="showEditor=='customer'"></app-customer-detail>
        <product-detail [customer]="record.customer" [orderId]="record.orderSegmentId"
          [order]="record"
          [canEdit]="isEditable(record?.status)" [(product)]="selectedPart"
          (productUpdate)="onProductUpdate(selectedPart)" *ngIf="showEditor=='part'" [sidenav]="sidenav"
          [department]="((this.record.discriminator === 'Estimate') || (this.record.discriminator === 'RMAEstimate')) ? 'Estimating' : 'Quoting'"
          [shouldPromptHistory]="this.record.discriminator !== 'RFQ'"
          >
        </product-detail>
        <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav">
        </upload-file>
        <product-finder (partSelected)="addNewPart($event)" *ngIf="showEditor=='addProduct'" [sidenav]="sidenav">
        </product-finder>
        <po-acceptance *ngIf="showEditor == 'acceptance'" [record]="record" [sidenav]="sidenav" (done)="onPOAccepted()">
        </po-acceptance>
      </mat-sidenav>
      <mat-sidenav-content>

        <div class="row border border-bottom" *ngIf="userIsManager() && record.orderSegmentId != emptyGuid">
          <div class="col-12 text-center rounded p-4" [ngClass]="{'bg-info text-light': !record.assignedTo}">
            <div *ngIf="!record.assignedTo">
              <h3>Needs Assignment</h3>
              <p>This ticket needs to be assigned to an employee before it can be edited.</p>
            </div>
            <div class="row align-items-center justify-content-center">
              <div class="col-2 text-right" *ngIf="record.assignedTo">
                <span>Assigned To:</span>
              </div>
              <div class="col-6">
                <employee-select [editable]="true" (employeeChange)="assigningEmployee = $event"
                  [employee]="record.assignedUser"></employee-select>
              </div>
              <div class="col-2"><button [disabled]="!assigningEmployee" class="btn btn-success"
                  (click)="saveAssignment()"><span *ngIf="record.assignedTo">Re-</span>Assign</button></div>
            </div>
          </div>
        </div>

        <div *ngIf="record.status==0 && record.statusNote?.length>0" class="badge w-90 m-3 text-center badge-secondary">
          <p class="pt-3">Note: {{record.statusNote}}</p>
        </div>
        <div *ngIf="record.status!=0" class="badge w-90 m-3 text-center" [ngClass]="getStatusColorClass(record.status)">
          <h4>{{getStatusText(record.status)}}</h4>
          <p *ngIf="!isEditable(record.status)" class="font-weight-light">This order cannot be edited because of its
            status.</p>
          <div *ngIf="record.status==3" class="font-weight-light pb-2">
            {{modifiedByUser?.fullName || '[Unknown User]'}} rejected this order on {{record.updatedDate | date}}
          </div>
          <div *ngIf="record.status==19" class="font-weight-light pb-2">
            {{modifiedByUser?.fullName || '[Unknown User]'}} closed this order on {{record.updatedDate | date}}
          </div>
          <p *ngIf="record.statusNote?.length>0">Note: {{record.statusNote}}</p>
          <div *ngIf="record.status==1" class="font-weight-light">
            <span *ngIf="record.discriminator!='Quality'">{{modifiedByUser?.fullName || '[Unknown User]'}} marked this
              order as ready and awaiting final review on {{record.updatedDate | date}}</span>
            <div class="pt-4 mb-2" *ngIf="userIsManager() && !editing">
              <button class="btn btn-success mr-4" title="Approve this order" (click)="approveOrder()"><i
                  class="fas fa-check fa-fw fa-2x"></i> Approve</button>
              <button class="btn btn-danger" title="Reject this order" (click)="rejectOrder()"><i
                  class="fas fa-times fa-fw fa-2x"></i> Reject</button>
            </div>
          </div>
          <div *ngIf="record.status==5 || record.status==4" class="font-weight-light pb-2">
            {{modifiedByUser?.fullName || '[Unknown User]'}} gave their approval to this order on
            {{record.updatedDate | date}}
            <p *ngIf="record.status==5" class="small pt-2 font-weight-bold pb-0">Any changes to this order will require
              additional approval</p>
          </div>
          <div *ngIf="record.status==20 && record.discriminator=='Quote'" class="font-weight-light pb-2">
            <p>This quote has not yet been issued to the customer. You can mark it as sent or, if it needs changes, send
              it back to Estimating.</p>
            <div *ngIf="!editing">
              <button class="btn btn-success mr-4" title="Mark as Sent" (click)="markAsSent()">Mark as Sent</button>
              <button class="btn btn-danger" title="Return to Estimating" (click)="returnToEstimating()">Return to
                Estimating</button>
            </div>
          </div>
          <div *ngIf="record.status==8 && record.discriminator=='Quote'" class="font-weight-light pb-2">
            <p>This quote has been issued to the customer. If it needs pricing/quantity changes, you can void it and send it back to Estimating.</p>
            <div *ngIf="!editing">
              <button class="btn btn-danger" title="Return to Estimating" (click)="returnToEstimating()">Return to
                Estimating</button>
            </div>
          </div>
          <div *ngIf="record.status==20 && record.discriminator!='Quote'" class="font-weight-light pb-2">
            <p>This RFQ's equivalent quote has not yet been issued to the customer. Please go to the Quotes section to issue it.</p>
          </div>
        </div>
        <div class="w-90 m-3 text-center" *ngIf="(!editing || id === 'new') && record.discriminator !== 'Quote'">
          <button class="btn btn-primary" (click)="goToNewUi()">
            Switch to New UI</button>
        </div>
        <div class="w-90 m-3 text-center" *ngIf="canBeClosed">
          <button class="btn btn-danger" (click)="closeOrder()">
            Close this order</button>
        </div>
        <ng-template [ngIf]="true">
          <mat-stepper [selectedIndex]="selectedTab" (selectionChange)="setSelectedTab($event)"
            [linear]="false" #stepper>
            <ng-template matStepperIcon="edit">
              <i class="fas fa-check fa-fw"></i>
            </ng-template>
            <ng-template matStepperIcon="done">
              <i class="fas fa-check fa-fw"></i>
            </ng-template>
            <mat-step label="Order Information">
              <ng-container *ngTemplateOutlet="orderContentTemplate"></ng-container>
            </mat-step>
            <mat-step label="RMA Information" *ngIf="record.rmaTicket">
              <order-rma-detail [detail]="record"></order-rma-detail>
            </mat-step>
            <mat-step label="Pricing and Lead Times" *ngIf="record.discriminator==='Estimate'">
              <estimate-pricing #estimatePricing [record]="record" [editing]="editing" [orderProducts]="products"></estimate-pricing>
            </mat-step>
            <mat-step #pricingStep label="Formal Quoting and Acceptance" *ngIf="record.discriminator=='Quote'">
              <quote-info #quoteInfo [(record)]="record" [editing]="editing" (acceptance)="onAcceptance();">
              </quote-info>
            </mat-step>
            <mat-step label="Accepted Purchase Orders" *ngIf="record.discriminator=='Quote'">
              <quote-accepted-pos [record]="record"></quote-accepted-pos>
            </mat-step>
            <mat-step label="Sales Notes" *ngIf="record.salesProcessId">
              <div style="height: 85vh;">
                <sales-notes [processId]="record.salesProcessId"></sales-notes>
              </div>
            </mat-step>
            <!-- estimate approval tab - only visible if segment is an estimate, user is estimate manager, and status is awaiting review/tentative approval/ -->
            <mat-step label="Order Processing Status" *ngIf="record.discriminator=='SalesOrder'">
              <product-build-status [product]="p.product" [orderId]="record.orderSegmentId"
                *ngFor="let p of record.products"></product-build-status>
            </mat-step>
          </mat-stepper>
        </ng-template>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<ng-template #orderContentTemplate>
  <div class="ml-4 pt-4 container-fluid">
    <div class="col-3 float-right border-left border-secondary p-0 bg-light">
      <div class="text-center border-bottom border-dark">
        <h5 class="bg-secondary m-0 p-2">Associated Parts</h5>
      </div>
      <div class="m-3 partTree" *ngIf="products">
        <product-tree (selected)="selectProduct($event)" (delete)="deletePart($event)"
          (deleteChild)="deletePartChild($event)" [product]="product" [editing]="editing"
          *ngFor="let product of products"></product-tree>
        <p *ngIf="record.products?.length==0" class="font-italic text-muted text-center">
          -- No Parts Assigned --
        </p>
        <p *ngIf="editing" class="text-center">
          <button class="btn btn-sm btn-primary" (click)="addPart()"><i class="fas fa-plus"></i> Add Part</button>
        </p>
        <p *ngIf="editing" class="text-center">
          <button class="btn btn-sm btn-primary" (click)="copyPart()"><i class="fas fa-plus"></i> Copy Existing Part</button>
        </p>
      </div>
      <div class="p-3">
        <div *ngIf="record.products?.length>0" class="bg-secondary p-1 total font-weight-bold text-muted small">
          Total Cost:
          <i *ngIf="totalCost==null; else showCost" title="Calculating..."
            class="text-info fas fa-spin fa-lg fa-circle-notch"></i>
          <ng-template #showCost>
            <span>{{totalCost | currency}}</span>
          </ng-template>
        </div>
      </div>
    </div>
    <order-info-detail (showRMA)="showRMA()" (onDeleteDocument)="deleteDocument($event)"
      (showCustomer)="showAddCustomer($event, false)" (showCustomerContact)="showAddCustomer($event, true)"
      (openDocuments)="openDocumentWindow()" [(record)]="record" [editing]="editing"></order-info-detail>

  </div>
</ng-template>


<ng-template #closeOrderDialogTemplate let-data>
  <h2 mat-dialog-title>Closing This Order</h2>
  <mat-dialog-content class="mat-typography">
    <p>Are you sure you want to close this order? This cannot be undone. Related estimates and quotes will also be
      closed.</p>
    <p>If so, please provide a reasoning below.</p>
    <p class="text-muted">
      The order and all its parts will remain in the database to be used in future orders. If this data needs to be
      scrubbed from the database, please contact a developer or sysadmin.
    </p>
    <p>
      <textarea class="form-control form-control-sm" [(ngModel)]="data.reason"
        placeholder="Provide a reason for the order being closed..."></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-default btn-sm mr-3" [disabled]="!data.reason" [mat-dialog-close]="data">Close Order</button>
  </mat-dialog-actions>
</ng-template>