import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchList } from '../../../common/components/search-list/search-list';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { RMATicket } from '../../resources/rma-ticket';
import { RMAService } from '../../service/rma.service';

@Component({
  selector: 'rma-sales-list',
  templateUrl: './rma-sales-list.component.html',
  styleUrls: ['./rma-sales-list.component.less']
})
export class RmaSalesListComponent extends SearchList<RMATicket> {

  constructor(private userSvc: UserService, private rmaService: RMAService, private navService: NavigationService, private router: Router) {
    super('RmaSalesListComponent');
    navService.clearBreadCrumbs();
    navService.setPageTitle("Sales");
    navService.pushBreadcrumb("RMA Tickets");
  }

  public async search() {
    this.results = null;
    this.rmaService.searchSales(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection, this.managerView).subscribe(results => {
      this.searchResults = results;
      this.results = results.results;
    });
  }

  onSelect(record: RMATicket): void {
    this.router.navigate(['/sales/rma', record.rmaTicketId]);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getStatusColorClass(status: number): string {
    return RMATicket.getStatusColorClass(status);
  }

  public getStatusBackgroundColorClass(status: number): string {
    return RMATicket.getStatusBackgroundColorClass(status);
  }

  public getStatusText(status: number): string {
    return RMATicket.getStatusText(status);
  }

  public statusProgressMax = 8;
  public statusProgressRange = Array(this.statusProgressMax).fill(0).map((x,i)=>i);

  public getStatusProgressNumber(status: number): number {
    switch (status) {
        // unassigned
        case 2: return 0;
        // await sales review
        case 6: return 1;
        // await quality review
        case 7: return 2;
        // await customer response
        case 0: return 3;
        // in transit
        case 1: return 4;
        // await disposition
        case 3: return 5;
        // await fulfillment
        case 4: return 6;
        // rejected
        case 8:
        // complete
        case 5:
        return 7;
        default:
          return 0;
    }
  }

  public getStatusProgressClipPercent(status: number) {
    return ((this.statusProgressMax - (this.getStatusProgressNumber(status) + 1)) / this.statusProgressMax) * 100;
  }

}
