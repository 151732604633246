import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchList } from '../../../common/components/search-list/search-list';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { Requisition, RequisitionStatus } from '../../resources/requisition';
import { PurchasingService } from '../../services/purchasing.service';

@Component({
  selector: 'app-requisition-list',
  templateUrl: './requisition-list.component.html',
  styleUrls: ['./requisition-list.component.less']
})
export class RequisitionListComponent extends SearchList<Requisition> {

  constructor(private userSvc: UserService, private purchasingService: PurchasingService, private navService: NavigationService, private router: Router) {
    super('RequisitionListComponent');
    navService.setPageTitle("Requisitions");
  }

  public async search() {
    this.results = null;
    this.purchasingService.searchRequisitions(this.managerView, this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results;
    });
  }

  onSelect(record: Requisition): void {
    this.router.navigate(['/requisition', record.requisitionId]);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public getStatusColorClass(status: number): string {
    return RequisitionStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return RequisitionStatus.getStatusText(status);
  }

}
