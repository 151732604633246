import evalInScope from "../../../../util/evalInScope";

export class Material {
  materialId: string;
  density?: number;
  materialGroupId: string;
  materialTypeId: string;
  materialHardnessId: string;
  materialAlloyId: string;
  defaultLeadTimeDays: number;
  units: number;
  castingNumber: string;
  isFromCompare?: boolean = false;

  materialType: MaterialType;
  materialDimensions: MaterialDimension[];
  materialGroup: MaterialGroup;
  materialHardness: MaterialHardness;
  materialAlloy: MaterialAlloy;
  materialMaterialSpecifications: MaterialMaterialSpecification[];
  materialCertifications: MaterialMaterialCertification[];

  readonly materialName: string;

  public static dimensionsDisplay(material: Material): string | null {
    const { materialType, materialDimensions } = material;
    if (!materialType || !materialDimensions || !materialType.displayTemplate) return null;

    if (material.materialType.materialTypeDimensions == null || materialDimensions.some(md => md.value === null)) return '[MISSING DIMENSIONS]';

    return materialType.displayTemplate.replace(/{(\w+?)}/g, (_match, group) => {
      const matchingDimension = materialType.materialTypeDimensions.find(mtd =>
        mtd.dimensionType.label.toLowerCase().trim() === group.toLowerCase().trim()
      );
      if (!matchingDimension) return '--';

      const dimensionValue = material.materialDimensions.find(
        md => md.materialTypeDimensionId === matchingDimension.materialTypeDimensionId
      );

      const out = dimensionValue ? dimensionValue.value.toFixed(3) : 'null';

      return out;
    });

  }

  public static generatedName(material: Material): string {

    if (material.materialName) return material.materialName;

    // ORDER:
    // TYPE DIMENSIONS GROUP HARDNESS ALLOY ( SPEC / SPEC / SPEC ) CASTING

    const type = material.materialType && material.materialType.name;
    const dimensions = Material.dimensionsDisplay(material);
    const group = material.materialGroup && material.materialGroup.groupName;
    const hardness = material.materialHardness && material.materialHardness.name;
    const alloy = material.materialAlloy && material.materialAlloy.name;

    const specsArray = material.materialMaterialSpecifications && material.materialMaterialSpecifications.map(mms => mms.materialSpecification.name);
    const specsString = (specsArray && specsArray.length) > 0 ? `(${specsArray.join(' / ')})` : null;

    const casting = material.castingNumber;

    // Put all the parts of the material name in order in an array
    const templateOrder = [type, dimensions, group, hardness, alloy, specsString, casting];

    return templateOrder
      // Filter out all fields that are null
      .filter(x => !!x)
      // Turn them into a string, space-separated
      .join(' ');

  }

  static getVolume(material: Material): number {

    if (
      !material ||
      !material.materialType || !material.materialType.volumeFormula
    ) return 0;

    const cleanFormula = material.materialType.volumeFormula ? material.materialType.volumeFormula.trim().toLowerCase().replace(/\^/g, '**') : null;
    if (!cleanFormula) return 0;



    const scope = (material.materialType.materialTypeDimensions.map(mtd => {
      const label = mtd.dimensionType.label.toLowerCase();
      const dimension = material.materialDimensions.find(
        md => md.materialTypeDimensionId === mtd.materialTypeDimensionId
      );
      if (!dimension) return [label, 0];
      const { siEquivalent } = dimension.materialTypeDimension.dimensionUnit;
      const value = dimension.value;// * (siEquivalent !== null ? siEquivalent : 1)
      return [label, value];
    }))
      .reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {});

    return evalInScope(cleanFormula, scope);// * (product.useMetric ? 1 : 1550)

  }

}

export interface MaterialGroup {
  materialGroupId: string;
  groupName: string;
  removalRate?: number;
}

export interface MaterialType {
  materialTypeId: string;
  name: string;

  materialTypeDimensions: MaterialTypeDimension[];

  volumeFormula: string;
  displayTemplate: string;

}

// 

export interface DimensionType {
  dimensionTypeId: string;
  label: string;
}

export interface DimensionUnit {
  dimensionUnitId: string;
  label: string;
  abbreviation: string;
  siEquivalent: number;
}

export interface MaterialTypeDimension {
  materialTypeDimensionId: string;

  materialTypeId: string;
  materialType: MaterialType;

  dimensionTypeId: string;
  dimensionType: DimensionType;

  dimensionUnitId: string;
  dimensionUnit: DimensionUnit;
}

export interface MaterialDimension {
  materialId: string;
  material: Material;
  materialTypeDimensionId: string;
  materialTypeDimension: MaterialTypeDimension;
  value: number;
}

// 

export interface MaterialHardness {
  materialHardnessId: string;
  name: string;
}

export interface MaterialAlloy {
  materialAlloyId: string;
  name: string;
}

export interface MaterialSpecification {
  materialSpecificationId: string;
  name: string;
}

export interface MaterialMaterialSpecification {
  materialMaterialSpecificationId: string;
  materialId: string;
  materialSpecificationId: string;

  material: Material;
  materialSpecification: MaterialSpecification;
}

export interface MaterialCertification {
  materialCertificationId: string;
  name: string;
}

export interface MaterialMaterialCertification {
  materialId: string;
  materialCertificationId: string;

  certification: MaterialCertification;
}
