import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import 'chartjs-plugin-labels';
import { NavigationService } from '../../../common/services/navigation.service';
import { ChartComponent } from '../../../common/components/chart/chart.component';
import { ShippingService } from '../../services/shipping.service';
import { filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-shipping-overview',
  templateUrl: './shipping-overview.component.html',
  styleUrls: ['./shipping-overview.component.less']
})
export class ShippingOverviewComponent implements OnInit {

  @ViewChild('todayChart', { static: true }) todayChartArea: ElementRef<HTMLCanvasElement>;
  @ViewChild('futureChart', { static: true }) futureChartArea: ElementRef<HTMLCanvasElement>;

  constructor(navService: NavigationService, private shippingService: ShippingService) {
    navService.setPageTitle("Shipping");
    navService.pushBreadcrumb("Overview");

  }

  private dateFormat(date: Date) {
    const year = date.getFullYear();

    const month = (1 + date.getMonth()).toString();

    const day = date.getDate().toString();
    
    return month + '/' + day + '/' + year;
  }
  
  ngOnInit() {

    const todayChart = new Chart(this.todayChartArea.nativeElement, {
      type: 'pie',
      data: {
        datasets: [{
          data: [0, 0],
          backgroundColor: ['#5cb85c', '#d9534f']
        }],
        labels: [
          "Incoming", "Outgoing"
        ]
      },
      options: {
        responsive: true,
        legend: {
          labels: {
            // Prevent items with undefined labels from appearing in the legend
            filter: (item) => item.text !== undefined
          }
        },
        tooltips: {
          // Prevent items with undefined labels from showing tooltips
          filter: (item, chart) => chart.labels[item.index] !== undefined
        },
        plugins: {
          labels: [
            {
              render: function (args) {
                if (args.label) return args.value;
                else return 'No Tickets Today';
              },
              fontColor: function (args) {
                if (args.label) return '#ffffff';
                else return '#6c757d';
              },
              shadowColor: 'rgba(0,0,0,1)',
              overlap: false
            }
          ]
        }
      }
    });

    const futureChart = new Chart(this.futureChartArea.nativeElement, {
      type: 'bar',
      data: {
        labels: ["Tue 05/05", "Wed 05/06", "Thu 05/07", "Fri 05/08", "Mon 05/11", "Tue 05/12"],
        data: [
          {
            label: 'Internal',
            backgroundColor: ChartComponent.availableColors[0],
            barPercentage: 0.45,
            data: [
                15,
                10,
                13,
                18,
                20,
                16,
            ]
        }, {
            label: 'External',
            backgroundColor: ChartComponent.availableColors[1],
            barPercentage: 0.45,
            data: [
                5,
                6,
                4,
                7,
                3,
                6,
            ]
        }, {
            label: 'Other',
            backgroundColor: ChartComponent.availableColors[2],
            barPercentage: 0.45,
            data: [
                2,
                3,
                0,
                1,
                4,
                2,
            ]
        }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          labels: []
        },
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 10,
                  stepSize: 1
                }
            }]
        }
      }
    });

    this.shippingService.$currentBuilding.pipe(
      filter(b => !!b),
      mergeMap(b => this.shippingService.getBuildingSchedule(b.buildingId))
    ).subscribe(data => {
      

      const today = this.dateFormat(new Date());

      console.log(today);

      const todayData = data.find(d => d.day === today) || {
        day: today,
        incoming: 0,
        outgoing: 0
      };
      const futureData = data.filter(d => d.day !== today);

      const nextWeekDates = (new Array(6)).fill(undefined).map((_, i) => {
        const date = new Date();
        date.setDate(date.getDate() + (i + 1));
        return date;
      });

      const nextWeekDatesInternal = nextWeekDates.map(this.dateFormat);
      const nextWeekDatesLabels = nextWeekDates.map(d => d.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
        year: undefined
      }));

      const nextWeekIncoming = {
        label: 'Incoming',
        backgroundColor: '#5cb85c',
        barPercentage: 0.45,
        data: nextWeekDatesInternal.map(date => {
          const datum = futureData.find(d => d.day === date);
          if (!datum) return 0;
          else return datum.incoming;
        })
      }

      const nextWeekOutgoing = {
        label: 'Outgoing',
        backgroundColor: '#d9534f',
        barPercentage: 0.45,
        data: nextWeekDatesInternal.map(date => {
          const datum = futureData.find(d => d.day === date);
          if (!datum) return 0;
          else return datum.outgoing;
        })
      }

      futureChart.data = {
        labels: nextWeekDatesLabels,
        datasets: [nextWeekIncoming, nextWeekOutgoing]
      };
      console.log(futureChart);
      futureChart.update();

      todayChart.data.datasets[0].data = [todayData.incoming, todayData.outgoing];
      if (todayData.incoming === 0 && todayData.outgoing === 0) {
        todayChart.data.datasets[0].data.push(1);
        todayChart.data.datasets[0].backgroundColor.push('#efefef');
      }

      todayChart.update();

    });
  }

}
