import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SearchList } from '../../../common/components/search-list/search-list';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Order, OrderStatus } from '../../resources/order';
import { OrderService } from '../../services/order.service';
import { FilterParam } from '../../../common/components/search-list-new/search-list-new.component';
import { User } from '../../../common/resources/user';

@Component({
  selector: 'rma-estimate-list',
  templateUrl: './rma-estimate-list.component.html',
  styleUrls: ['./rma-estimate-list.component.less']
})
export class RmaEstimateListComponent {
  constructor(private userSvc: UserService) {
  }

  public userIsManager(user: User): boolean {
    return this.userSvc.canAccess('SalesManager', user);
  }

  public get isManager() { return this.userIsManager(this.userSvc.userData) }

  public readonly availableStatuses = [OrderStatus.IN_PROCESS, OrderStatus.AWAITING_REVIEW, OrderStatus.REJECTED, OrderStatus.TENT_APPROVED, OrderStatus.APPROVED, OrderStatus.CLOSED, OrderStatus.HISTORY] as const;
  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    }))
  }]
  public readonly defaultStatuses = [OrderStatus.IN_PROCESS, OrderStatus.AWAITING_REVIEW, OrderStatus.REJECTED] as const;
  public readonly defaultFilters = { status: this.defaultStatuses }

  public loading = true;
}