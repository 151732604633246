<div class="pt-3">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">
    <div class="row h-100">
      <div class="col-4 h-100 d-flex flex-column">
        <mat-form-field appearance="outline" dense class="w-100 dense-field">
          <mat-label>Filter Items</mat-label>
          <input matInput type="text" [(ngModel)]="filter" #filterModel="ngModel">
        </mat-form-field>
        <ul (scroll)="onListScroll($event)"
          class="list-group list-group-hover list-group-striped h-100 overflow-y-scroll relative">
          <div *ngIf="searching" class="searching-indicator">
            <i class="fa fa-lg fa-spin fa-circle-notch text-primary"></i>
            <div class="text-muted font-italic mt-1">Searching...</div>
          </div>
          <li (click)="setSelectedItem(item)" class="list-group-item mat-caption"
            [class.item-selected]="selectedItem && selectedItem.buildingId === item.buildingId"
            *ngFor="let item of items; index as i" [innerHTML]="item.name | highlight: filter">
          </li>
        </ul>
        <button [disabled]="isCreating" mat-flat-button color="primary" [class.bg-success]="!isCreating"
          class="mt-2 new-item-button" (click)="createNewItem()">
          New Item
        </button>
      </div>
      <div class="col-8 h-100">
        <mat-card class="h-100 mat-elevation-z0 border border-secondary" [class.p-0]="!selectedItem">
          <div *ngIf="!selectedItem"
            class="bg-light p-3 w-100 h-100 d-flex align-items-center justify-content-center text-muted font-italic">
            Choose or
            create a building from the left
            panel.</div>
          <ng-container *ngIf="!!selectedItem">
            <mat-card-header>
              <mat-card-title>
                {{ isCreating ? 'Creating' : 'Editing' }}
                Building</mat-card-title>
              <mat-card-subtitle>{{selectedItem?.name || '&nbsp;'}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <form #itemForm="ngForm">
                <div class="row">
                  <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Building Name</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.name" name="name"
                        required>
                      <mat-error *ngIf="itemForm.controls['name']?.errors?.required">
                        Name is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <h5>Address</h5>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Address</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.streetAddress" name="address">
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Second Line</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.streetAddress2" name="address2">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>City</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.city" name="city">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>State</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.state" name="state">
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Zip Code</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.postalCode" name="postalCode">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.phoneNumber" name="phoneNumber">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Fax Number</mat-label>
                      <input autocomplete="off" matInput type="text" [(ngModel)]="selectedItem.address.faxNumber" name="faxNumber">
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <h5>Root Inventory Location</h5>
                  </div>
                  <div class="col-12">
                    <inventory-tree-select
                    [(ngModel)]="selectedItem.rootInventoryLocation"
                    (ngModelChange)="onLocationChange($event)"
                    name="rootLocation"
                  >
                  </inventory-tree-select>
                  </div>
                </div>
              </form>
            </mat-card-content>
            <mat-card-actions align="end">
              <button type="button" mat-flat-button color="warn" class="mr-2" *ngIf="!isCreating"
                (click)="deleteSelected()">
                Delete
              </button>
              <button type="submit" mat-flat-button color="primary" class="mr-5"
                [disabled]="itemForm.invalid || itemForm.pristine" (click)="saveSelected()">
                Save
              </button>
            </mat-card-actions>
          </ng-container>
        </mat-card>
      </div>
    </div>
  </div>
</div>
