<div class="position-relative h-100-t overflow-y-scroll">
  <ng-container>
    <div class="mb-4 d-flex align-items-center border-bottom px-3 py-4 bg-white search-area" data-testid="search-area">
      <mat-form-field class="flex-grow-1 dense-field" appearance="outline">
        <mat-icon class="text-muted" matPrefix>search</mat-icon>
        <input ngModel #filterModel="ngModel" matInput type="text" placeholder="Search tickets..." data-testid="search-filter-input">
      </mat-form-field>
      <mat-form-field class="dense-field mx-2">
        <mat-label>User Filter</mat-label>
        <mat-select [(ngModel)]="userFilter" #userFilterModel="ngModel" data-testid="user-filter-select">
          <mat-option value="assigned">Assigned to Me</mat-option>
          <mat-option value="created">Created by Me</mat-option>
          <mat-option value="all">All Tickets</mat-option>
        </mat-select>
      </mat-form-field>
      <button [matMenuTriggerFor]="filterMenu" mat-flat-button class="mx-2 mat-square-button h-100">
        <mat-icon *ngLet="filters | async as selectedFilters"
          [matBadge]="selectedFilters?.length === 0 ? null : selectedFilters?.length" matBadgePosition="below after"
          matBadgeSize="small" svgIcon="filter"></mat-icon>
      </button>
      <mat-menu #filterMenu (click)="$event.stopPropagation()" class="p-0">
        <div class="w-100 p-3" (click)="$event.stopPropagation()">
          <mat-chip-list *ngLet="filters | async as selectedFilters">
            <mat-chip *ngFor="let status of [0,1,2,3]"
              [class.filter-disabled]="!filterIsEnabled(status, selectedFilters)"
              [ngClass]="getStatusColorClass(status)"
              class="button-text"
              [attr.data-testid]="'filter-menu-' + status"
              (click)="$event.stopPropagation(); toggleFilter(status)">{{ getStatusText(status) }}</mat-chip>
          </mat-chip-list>
        </div>
      </mat-menu>
      <button mat-fab class="extended-fab-button extended-fab-small button-text" color="primary"
        style="position: absolute; bottom: -1.25em; right: 1em; z-index: 3" (click)="create()"
        data-testid="create-microticket-button"
      >
        <mat-icon svgIcon="note-plus"></mat-icon>
        Create Microticket
      </button>
      <mat-progress-bar class="w-100 progress-bar" mode="indeterminate" style="position: absolute; bottom: 0; left: 0; width: 100%;" *ngIf="!(results$ | async)"></mat-progress-bar>
    </div>
    <div class="adding-overlay cdk-overlay-dark-backdrop" *ngIf="editing">

    </div>
    <div class="adding-container mat-elevation-z6 p-2"
      *ngIf="editing"
    >
      <new-microticket
          #microTicketForm
          [relatedTicketId]="relatedTicketId"
          [relatedTicketName]="relatedTicketName"
          [filterKey]="filterKey"
          [subItems]="subItems"
          [subItemName]="subItemName"
          [subItemDisplayWith]="subItemDisplayWith"
          [subItemCompareWith]="subItemCompareWith"
          (save)="onSave($event)"
          (cancel)="onCancel()"
          [isNew]="editingId === 'new'"
      ></new-microticket>
    </div>
    <div class="px-3" *ngIf="(results$ | async) as results" data-testid="microticket-list-container">
      <div *ngIf="results.length === 0" class="w-100 h-100 d-flex align-items-center justify-content-center font-italic text-muted">
        No microtickets were found matching your filters.
      </div>
      <div *ngFor="let ticket of results; trackBy: trackByFn" class="mb-3" data-testid="microticket-card" [attr.data-test-ticket-id]="ticket.microTicketId">
        <microticket-card [ticket]="ticket" [subItemTitle]="subItemName" *ngLet="subItems | async as subItemResults" [subItemName]="getSubItemName(ticket, subItemResults)"
        (edit)="edit(ticket)"
        (close)="close(ticket)"
        (markInProgress)="markInProgress(ticket)"
        (markComplete)="markComplete(ticket)"
        (reopen)="reopen(ticket)"
        (jumpToSubitem)="jumpToSubitem.emit(ticket.relatedSubItem)"
        (newComment)="newComment(ticket, $event)"
        (addFiles)="addFiles(ticket, $event)"
        [userNames]="userNames"
        [existingDocuments]="existingDocuments"
        ></microticket-card>
      </div>
    </div>
  </ng-container>
</div>
