<loading *ngIf="!record"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<div class="position-fixed" style="bottom: 15px; right: 10px; z-index: 1030" *ngIf="!sidenav.opened && record && !saving">
  <button mat-fab tabindex="-1" class="extended-fab-button text-white bg-success mr-2"
    *ngIf="!editing && (record.status === 0 || record.status === 3)" (click)="complete()">
    <mat-icon>send-variant</mat-icon>
    <span class="extended-fab-button__text">Complete</span>
  </button>
  <button mat-fab tabindex="-1" class="extended-fab-button mr-2" color="warn"
    *ngIf="!editing && (record.status === 11)" (click)="reject()">
    <mat-icon>close</mat-icon>
    <span class="extended-fab-button__text">Reject</span>
  </button>
  <button mat-fab color="warn" (click)="cancelEditing()">
    <mat-icon>close</mat-icon>
  </button>
  <button mat-fab class="bg-success text-white ml-2 mt-4" *ngIf="!editing && (record.status === 0 || record.status === 3)" (click)="editing = true">
    <mat-icon matPrefix>edit</mat-icon>
  </button>
  <button mat-fab class="bg-success text-white ml-2 mt-4" *ngIf="editing" (click)="saveChanges()">
    <mat-icon matPrefix>save</mat-icon>
  </button>
</div>

<mat-sidenav-container>
  <mat-sidenav class="p-3" #sidenav mode="over" position="end">
    <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav">
    </upload-file>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="w-100 d-flex flex-column p-3" style="height: 93vh;" *ngIf="record">
      <div class="w-100 rounded bg-danger text-white text-center mb-2 p-3 small" *ngIf="record.status == 3">
        <h3>Rejected</h3>
        <p>
          <b>{{ record.rejectedBy?.fullName ?? 'Unknown User'}}</b> rejected this programming ticket on <b>{{ record.rejectedDate ? (record.rejectedDate | date: 'shortDate') : 'Unknown Date' }}</b>
        </p>
        <p><b>Reason given:</b></p>
        <p>{{ record.rejectionReason }}</p>
      </div>
      <div class="w-100 rounded bg-success text-white text-center mb-2 p-3 small" *ngIf="record.status == 11">
        <h3>Fulfilled</h3>
        <p>
          This ticket was marked as complete on <b>{{ record.completedDate ? (record.completedDate | date: 'shortDate') : 'Unknown Date' }}</b>
        </p>
      </div>
      <div class="border rounded p-3" *ngIf="record">
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Work Order Number</mat-label>
              <input matInput readonly [value]=" record.workOrder.workOrderNumber " />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Customer</mat-label>
              <input matInput readonly [value]=" record.workOrder.order.customer.businessName " />
            </mat-form-field>
          </div>
          <div class="col-4 mb-2">
            <mat-form-field class="w-100">
              <mat-label>Programming Type</mat-label>
              <input matInput readonly [value]=" getProgrammingTypeName(record.type) " />
            </mat-form-field>
          </div>
          <div class="col-12 mb-2">
            <employee-search [readonly]="!editing" label="Assigned User" [(ngModel)]="record.assignedUser" name="assignedUser"
                    [placeholder]="record.assignedUser ? undefined : 'Unassigned'" floatLabel="always"
                    (selectedItemChange)="record.assignedUserId = $event?.userId"></employee-search>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="w-100" tabindex="-1" floatLabel="always">
              <mat-label>Due Date</mat-label>
              <input type="text" matInput readonly [value]="record.dueDate ? (record.dueDate | date: 'YYYY/MM/dd') : null" placeholder="Unknown">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="w-100" tabindex="-1" floatLabel="always">
              <mat-label>Date Assigned</mat-label>
              <input type="text" matInput readonly [value]="record.dateAssigned ? (record.dateAssigned | date: 'YYYY/MM/dd HH:mm a') : null" placeholder="Unknown">
            </mat-form-field>
          </div>
          <div class="col-4">
            <date-picker appearance="outline" class="w-100" placeholder="Promise Date" [(ngModel)]="record.promiseDate"
              [editable]="true" [readonly]="!editing"></date-picker>
          </div>
        </div>
      </div>
      <div class="border rounded mt-3 flex-grow-1">
        <mat-tab-group style="height: 100%;">
          <mat-tab label="Programming">
            <div class="row m-0 p-3 h-100-t">
              <div class="col-12">
                <mat-form-field class="w-100 h-100-t" appearance="outline">
                  <mat-label>Programming Notes</mat-label>
                  <textarea matInput placeholder="Programming Notes" [disabled]="!editing" [(ngModel)]="record.programmingNotes" rows="5" style="resize: none;"></textarea>
                </mat-form-field>
              </div>
              <div class="col-12 form-group">
                <label>Programming Files</label>
                <div class="border rounded p-3 position-relative h-100-t" style="min-height: 8em;">
                <file-display-grid [editable]="true" [files]="record.programmingFiles"></file-display-grid>
                  <div *ngIf="record.programmingFiles.length==0">
                    <p class="font-italic text-muted text-center">-- No Uploaded Programming Files --</p>
                  </div>
                  <button mat-fab *ngIf="editing" class="bg-primary text-white mat-elevation-z2" (click)="openDocumentWindow()" style="
                    position: absolute;
                    right: 16px;
                    top: -24px;
                    ">
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Workflow Data">
            <div class="row m-0 py-2 pr-2 h-100-t">
              <div class="col-4">
                <workflow-list [product]="record.workOrder.product" [steps]="record.workOrder.product.workflow.workflowSteps" [sortable]="false" (selected)="selectedStep = $event"></workflow-list>
              </div>
              <div class="col-8 d-flex flex-column py-3" *ngIf="selectedStep">
                <div *ngIf="selectedStep.specification && getSpecifications(selectedStep).length > 0">
                  <div class="pseudo-label">Specifications</div>
                  <div class="w-100">
                    <mat-chip class="bg-primary text-white mr-2 d-inline-block" *ngFor="let spec of getSpecifications(selectedStep)">
                      {{ spec }}
                    </mat-chip>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="w-100 form-field-readonly flex-grow-1">
                  <mat-label>Workflow Notes</mat-label>
                  <textarea matInput disabled [(ngModel)]="selectedStep.description" style="resize: none; height: 100%;"></textarea>
                </mat-form-field>
              </div>
              <div class="col-8 bg-light text-muted d-flex align-items-center justify-content-center" *ngIf="!selectedStep">
                Please select a workflow step on the right to view workflow information.
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Drawings and Documents">
            <div class="p-3 h-100-t">
              <file-display-grid [editable]="false" [files]="record.workOrder.product.documents"></file-display-grid>
              <p *ngIf="record.workOrder.product.documents?.length==0" class="text-muted text-center">
                <em>-- No Documents --</em>
              </p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #rejectTemplate let-data>
  <h2 mat-dialog-title>Rejecting Programming Ticket</h2>
  <mat-dialog-content class="mat-typography">
    <p>Please provide a reason for rejecting this ticket.</p>
    <mat-form-field class="w-100">
      <textarea rows="3" matInput [(ngModel)]="data.reason"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="warn" [mat-dialog-close]="data.reason" [disabled]="!data.reason">Reject</button>
    <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</ng-template>