<div>
  <a *ngIf="record && showEditor==null" class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit" (click)="cancelEditing()"><i class="fas fa-times"></i></a>

  <loading *ngIf="!record"></loading>
  <loading *ngIf="saving">Saving...</loading>

  <div class="row">

    <mat-sidenav-container *ngIf="record">
      <mat-sidenav disableClose (closedStart)="closeSideNav()" #sidenav mode="over" position="end">
        <vendor-select *ngIf="showEditor=='detail'" [sidenav]="sidenav" [orderId]="order?.orderSegmentId" [order]="order" [requisitionId]="req?.requisitionId" [product]="selectedProduct" [typeId]="detailId"></vendor-select>
        <quote-detail *ngIf="showEditor=='quote'" [customer]="order?.customer" [sidenav]="sidenav" [id]="selectedQuoteId"></quote-detail>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-stepper>
          <mat-step label="Quotes">
            <div class="col-12">
              <div class="row" [class.mx-2]="parentSidenav" [class.mt-5]="parentSidenav">
                <div class="col-9">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-6">
    
                        <div class="form-group" *ngIf="order">
                          <label>Customer</label>
                          <span class="d-block small">{{order.customer.businessName}}</span>
                        </div>
                        <div class="form-group" *ngIf="req">
                          <label>Department</label>
                          <span class="d-block small">{{req.department}}</span>
                        </div>
                        <div class="form-group" *ngIf="order">
                          <label>Order Number</label>
                          <span class="d-block small">{{order.orderNumber}}</span>
                        </div>
                        <div class="form-group" *ngIf="req">
                          <label>Requisition Number</label>
                          <span class="d-block small">{{req.requisitionNumber}}</span>
                        </div>
                        <div class="form-group" *ngIf="order">
                          <label>Date Received</label>
                          <span class="d-block small">{{(order.receivedDate | date) || '-- Not Entered --'}}</span>
                        </div>
                        <div class="form-group" *ngIf="order">
                          <label>Date Created</label>
                          <span class="d-block small" [ngClass]="{'text-muted':order.createdDate==null}">{{(order.createdDate | date) || 'N / A'}}</span>
                        </div>
    
                      </div>
                      <div class="col-6">
                        <div class="form-group" *ngIf="order">
                          <label>Contact</label>
                          <span class="d-block small">{{order.customerContact?.contact?.name || '-- No Contact Entered --'}}</span>
                        </div>
                        <div class="form-group" *ngIf="order">
                          <label>Customer RFQ#</label>
                          <span class="d-block small">{{order.customerRfqnumber || '-- No Customer RFQ --'}}</span>
                        </div>
                        <div class="form-group">
                          <label>
                            Date Required
                            <span *ngIf="requiredDate!=null" class="badge required p-1 pl-2 pr-2" [ngClass]="getRequiredColor(requiredDate)">
                              <span class="fa-lg">{{weeksLeft(requiredDate, true)||'--'}} Week(s) {{(weeksLeft(requiredDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                            </span>
                          </label>
                          <span class="d-block small">{{(requiredDate | date) || '-- Not Entered --'}}</span>
                        </div>
                        <div class="form-group" *ngIf="order">
                          <label>Sales Representative</label>
                          <span class="d-block small" [ngClass]="{'text-muted':order.customer?.salesPerson?.fullName==null}">{{order.customer?.salesPerson?.fullName || '-- No Sales Representative Assigned  --'}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-6">
                        <div class="bg-light">
                          <h5 class="w-100 p-2 bg-secondary">
                            Materials
                            <span class="float-right mat-small" *ngIf="type === 'order'">
                              <mat-slide-toggle (change)="togglePartDisplay()" [checked]="displayPerPart" labelPosition="before">Show per Part</mat-slide-toggle>
                            </span>
                          </h5>
                          <div class="p-1">
                            <table class="table border detailList">
                              <tbody>
                                <tr *ngFor="let m of materials" [ngClass]="{'bg-primary':selection?.item?.materialId==m.item?.materialId}" (click)="setSelection(m)">
                                  <td *ngIf="displayPerPart">{{m.partNumber}} Rev {{m.revision}}</td>
                                  <td>{{getMaterialName(m.item) || '--'}}</td>
                                  <td class="text-right">
                                    <button *ngIf="selection?.item?.materialId==m.item?.materialId" (click)="showDetail(m, m.item.materialGroup.materialGroupId)" class="btn btn-sm btn-primary">Get Quotes</button>
                                  </td>
                                </tr>
                                <tr *ngIf="!materialsLoading && materials.length==0">
                                  <td colspan="100%" class="font-italic text-muted text-center small">
                                    -- No Materials Need Quoted --
                                  </td>
                                </tr>
                                <tr *ngIf="materialsLoading">
                                  <td colspan="100%" class="text-center">
                                    <i class="text-primary fas fa-lg fa-circle-notch fa-spin"></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="bg-light">
                          <h5 class="w-100 p-2 bg-secondary">
                            Outsourced
                            <span class="float-right mat-small" *ngIf="type === 'order'">
                              <mat-slide-toggle (change)="togglePartDisplay()" [checked]="displayPerPart" labelPosition="before">Show per Part</mat-slide-toggle>
                            </span>
                          </h5>
                          <div class="p-1">
                            <table class="table border detailList">
                              <tbody>
                                <tr *ngFor="let o of outsourced" [ngClass]="{'bg-primary':selection?.item?.stationId==o.item?.stationId}" (click)="setSelection(o)">
                                  <td *ngIf="displayPerPart">{{o.partNumber}} Rev {{o.revision}}</td>
                                  <td>{{getStationName(o.item)}}</td>
                                  <td class="text-right">
                                    <button *ngIf="selection?.item?.stationId==o.item?.stationId" (click)="showDetail(o, o.item.stationId)" class="btn btn-sm btn-primary">Get Quotes</button>
                                  </td>
                                </tr>
                                <tr *ngIf="!outsourcedLoading && outsourced.length==0">
                                  <td colspan="100%" class="font-italic text-muted text-center small">
                                    --  No Outsourced Processes Need Quoted --
                                  </td>
                                </tr>
                                <tr *ngIf="outsourcedLoading">
                                  <td colspan="100%" class="text-center">
                                    <i class="text-primary fas fa-lg fa-circle-notch fa-spin"></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-6">
                        <div class="bg-light">
                          <h5 class="w-100 p-2 bg-secondary">
                            Purchased Items
                            <span class="float-right mat-small" *ngIf="type === 'order'">
                              <mat-slide-toggle (change)="togglePartDisplay()" [checked]="displayPerPart" labelPosition="before">Show per Part</mat-slide-toggle>
                            </span>
                          </h5>
                          <div class="p-1">
                            <table class="table border detailList">
                              <tbody>
                                <tr *ngFor="let p of purchased" [ngClass]="{'bg-primary':selection==p.item}" (click)="setSelection(p)">
                                  <td *ngIf="displayPerPart">{{p.partNumber}} Rev {{p.revision}}</td>
                                  <td>{{p.item.description||'--No Description--'}} (#{{p.quantity}})</td>
                                  <td class="text-right">
                                    <button *ngIf="selection==p" (click)="showDetail(p, 'purchased')" class="btn btn-sm btn-primary">Get Quotes</button>
                                  </td>
                                </tr>
                                <tr *ngIf="!purchasedItemsLoading && purchased.length==0">
                                  <td colspan="100%" class="font-italic text-muted text-center small">
                                    --  No Purchased Items Need Quoted --
                                  </td>
                                </tr>
                                <tr *ngIf="purchasedItemsLoading">
                                  <td colspan="100%" class="text-center">
                                    <i class="text-primary fas fa-lg fa-circle-notch fa-spin"></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="min-vh-80 bg-light border-left border-secondary">
                    <div class="text-center border-bottom border-dark">
                      <h5 class="bg-secondary m-0 p-2">Quotes</h5>
                    </div>
                    <div class="m-1">
                      <div class="mb-2">
                        <span>Filter Quotes:</span>
                        <mat-select class="small" [ngModel]="selection" (selectionChange)="setSelection($event.value)">
                          <mat-option *ngIf="selection!=null" [value]="null"><i class="text-danger mr-2 fas fa-fw fa-lg fa-window-close"></i><span class="font-weight-bold">Clear Selection</span></mat-option>
                          <mat-option disabled><div class="font-italic text-center">Materials</div></mat-option>
                          <mat-option disabled *ngIf="materials && materials.length==0">(No Materials to Quote)</mat-option>
                          <mat-option [value]="material" *ngFor="let material of materials">{{getMaterialName(material.item) || '--'}}</mat-option>
                          <mat-option disabled><div class="font-italic text-center">Processes</div></mat-option>
                          <mat-option disabled *ngIf="outsourced && outsourced.length==0">(No Outsourced Processes to Quote)</mat-option>
                          <mat-option [value]="process" *ngFor="let process of outsourced">{{getStationName(process.item) || '--'}}</mat-option>
                          <mat-option disabled><div class="font-italic text-center">Purchased Items</div></mat-option>
                          <mat-option disabled *ngIf="purchased && purchased.length==0">(No Purchased Items to Quote)</mat-option>
                          <mat-option [value]="item" *ngFor="let item of purchased">{{item.item.description || '--'}}</mat-option>
                        </mat-select>
                      </div>
                      <p *ngIf="!completedQuotes" class="text-center">
                        <i class="fas fa-2x fa-circle-notch fa-spin text-primary"></i>
                      </p>
                      <div *ngIf="completedQuotes">
                        <table class="table border detailList">
                          <tbody>
                            <tr *ngFor="let quote of completedQuotes" (click)="showQuote(quote)" [ngClass]="{'text-white':(quote.accepted || quote.rejected), 'bg-success':quote.accepted!=null, 'bg-danger':quote.rejected!=null}">
                              <td>
                                <span *ngIf="quote.answered==null; else received">
                                  <i class="fas text-muted fa-sign-in-alt fa-fw fa-lg fa-rotate-270" title="Sent"></i>
                                  {{quote.asked | date}}
                                </span>
                                <ng-template #received>
                                  <span *ngIf="quote.accepted==null && quote.rejected==null; else action">
                                    <i class="fas text-muted fa-sign-in-alt fa-fw fa-lg fa-rotate-90" title="Answered"></i>
                                    {{quote.answered | date}}
                                  </span>
                                  <ng-template #action>
                                    <span>
                                      <i [ngClass]="{'fa-check':quote.accepted!=null, 'fa-times':quote.rejected!=null}" class="fas fa-fw fa-lg" [title]="quote.accepted?'Accepted':'Rejected'"></i>
                                      {{(quote.accepted || quote.rejected) | date}}
                                    </span>
                                  </ng-template>
                                </ng-template>
                              </td>
                              <td>{{quote.vendor.name}}</td>
                              <td>
                                <span *ngIf="quote.material">
                                  {{ getMaterialName(quote.material) || '--' }}
                                </span>
                                <span *ngIf="quote.station">
                                  {{quote.station.name}}
                                </span>
                              </td>
                              <td>
                                {{((quote.perItemBid || (quote.totalBid/quote.qty)) | currency) || '--'}}
                              </td>
                            </tr>
                            <tr *ngIf="completedQuotes.length==0">
                              <td colspan="100%" class="font-italic text-muted text-center small">
                                --  No Existing Quotes --
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step label="PO Creation">
            <div class="col-12">
              <div class="row" [class.mx-2]="parentSidenav" [class.mt-5]="parentSidenav">
                <div class="col-8">
                  <h1 class="mat-card-title">Vendors with Approved Quotes</h1>
                  <div class="border rounded">
                    <table class='table table-striped table-borderless table-sm small mb-0' style="table-layout: fixed;">
                      <thead>
                        <tr>
                          <th style="width: 64px;">&nbsp;</th>
                          <th style="width: 40%;">Vendor/Item</th>
                          <th>Amount</th>
                          <th>Total Cost</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="approvedQuotes">
                          <ng-container *ngFor="let vendorGroup of approvedQuotes">
                            <tr class="cursor-pointer" (click)="vendorGroup.isOpen = !vendorGroup.isOpen">
                              <td>
                                <i class="text-muted fas fa-fw" [ngClass]="{'fa-plus': !vendorGroup.isOpen, 'fa-minus': vendorGroup.isOpen}"></i>
                              </td>
                              <td class="font-weight-bold">{{vendorGroup.vendor.name}}</td>
                              <td>{{vendorGroup.materialBids.length}}</td>
                              <td>{{ getTotalCost(vendorGroup.materialBids) | currency }}</td>
                              <td align="end">
                                <button
                                  (click)="$event.stopPropagation(); createPO(vendorGroup)"
                                  class="btn btn-primary btn-sm"
                                  [disabled]="vendorGroup.blacklist.length >= vendorGroup.materialBids.length">Create PO for Vendor</button>
                              </td>
                            </tr>
                            <ng-container *ngIf="vendorGroup.isOpen">
                              <tr *ngFor="let bid of vendorGroup.materialBids" [ngClass]="{
                                'text-muted': vendorGroup.blacklist.includes(bid.materialBidId),
                                'text-strike': vendorGroup.blacklist.includes(bid.materialBidId)
                              }">
                                <td>
                                  <mat-checkbox class="mr-2" #blacklisted [checked]="!vendorGroup.blacklist.includes(bid.materialBidId)" (change)="toggleBidBlacklist(vendorGroup, bid.materialBidId, $event)"></mat-checkbox>
                                </td>
                                <td>
                                  <item-type-chip class="mr-1" [item]="bid"></item-type-chip>
                                  {{ getProductInfo(bid) }}
                                </td>
                                <td>
                                  {{ bid.qty }}
                                </td>
                                <td>
                                  {{ getCost(bid) | currency }}
                                </td>
                                <td>&nbsp;</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <tr *ngIf="approvedQuotes.length === 0">
                          <td colspan="5" class="text-muted bg-light text-center font-italic small">-- No Approved Quotes --</td>
                        </tr>
                        <tr *ngIf="!approvedQuotes" class="text-center">
                          <td colspan="5"><i class="fas fa-2x fa-circle-notch fa-spin text-primary"></i></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-4">
                  <div class="min-vh-80 bg-light border-left border-secondary">
                    <div class="text-center border-bottom border-dark">
                      <h5 class="bg-secondary m-0 p-2">Created POs</h5>
                    </div>
                    <div class="m-1">
                      <p *ngIf="!existingPOs" class="text-center">
                        <i class="fas fa-2x fa-circle-notch fa-spin text-primary"></i>
                      </p>
                      <div *ngIf="existingPOs">
                        <table class="table border detailList">
                          <tbody>
                            <tr *ngFor="let po of existingPOs" (click)="openPO(po)">
                              <td>{{po.vendor.name}}</td>
                              <td class="font-weight-bold">
                                {{ po.purchaseOrderNumber }}
                              </td>
                              <td>
                                {{ po.purchaseOrderLineItems.length }} items
                              </td>
                              <td>
                                <span class="badge required" [ngClass]="getStatusColorClass(po.status)">
                                  {{getStatusText(po.status)}}
                                </span>
                              </td>
                            </tr>
                            <tr *ngIf="existingPOs.length==0">
                              <td colspan="100%" class="font-italic text-muted text-center small">
                                --  No POs created --
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </div>
</div>
