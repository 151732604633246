import { Component, ElementRef, Input, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import Muuri from 'muuri';
import IWidgetData from '../services/widgets/interface';
import { WidgetService, IWidgetInstance } from '../services/widget.service';
import { WidgetItemComponent } from '../widget-item/widget-item.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { first, map, take } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'widget-area',
  styleUrls: ['./widget-area.component.less'],
  templateUrl: './widget-area.component.html',
  animations: [
    // the fade-in/fade-out animation.
    trigger('fade', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(150)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(150, style({opacity: 0})))
    ])
  ]
})
export class WidgetAreaComponent implements OnInit {

  @Input() name: string;
  @Input() id: string;

  availableWidgets: Observable<IWidgetData[]>

  constructor(private widgetService: WidgetService) { }

  ngOnInit() {
    this.availableWidgets = this.widgetService.availableWidgets.pipe(
      map(widgets => 
        widgets.filter(w => !this.currentWidgetIDs.includes(w.id))
      )
    )
    this.loadItems()
  }

  loading = new BehaviorSubject(false);
  async loadItems() {
    this.loading.next(true)
    this.widgetItems = await this.widgetService.getWidgets(this.id)
    this.loading.next(false)
  }

  widgetById(id: string) {
    return this.widgetService.widgetById(id)
  }

  public widgetItems: IWidgetInstance[] = [];
  public saving$ = new BehaviorSubject(false)
  private async saveWidgets() {
    this.saving$.next(true)
    await this.widgetService.setWidgets(this.id, this.widgetItems)
    this.saving$.next(false)
  }

  get currentWidgetIDs() {
    return this.widgetItems.map(x => x.widget)
  }

  // get availableWidgets() {
  //   // all widgets except ones we already have
  //   return this.widgetService.getAvailableWidgets().filter(w => !this.currentWidgetIDs.includes(w.id));
  // }

  public grid: Muuri

  setDimensions(component: WidgetItemComponent, dimensions: {
    width?: number,
    height?: number
  }) {
    const { width, height } = dimensions
    const elem = component.nativeElement
    if (width) elem.style.width = `${width}px`;
    if (height) elem.style.height = `${height}px`;
    this.grid.refreshItems(elem).layout()
  }

  setDefaultDimensions(component: WidgetItemComponent) {
    this.setDimensions(component, {
      width: component.widget.minWidth,
      height: component.widget.minHeight
    })
  }


  @ViewChild('widgetArea', { static: true }) widgetAreaElement: ElementRef<HTMLElement>;
  @ViewChildren("widgetItem") widgetReferences: QueryList<WidgetItemComponent>;
  ngAfterViewInit() {


    // create the Muuri grid
    this.grid = new Muuri(this.widgetAreaElement.nativeElement, {
      dragEnabled: true,
      // drag handle class name
      dragStartPredicate: {
        handle: '.widgetDragHandle'
      },
      // stuff that makes dragging feel better
      dragSortHeuristics: {
        minDragDistance: 1
      },
      dragSortPredicate: {
        'threshold': 25
      }
    })

    this.grid.on('move', this.onSort.bind(this))

    // set the dimensions for all the restored or default widgets
    this.widgetReferences.changes.subscribe((components: QueryList<WidgetItemComponent>) => {
      const gridElements: HTMLElement[] = this.grid.getItems().map(i => i.getElement())
      components.forEach(comp => {
        if (!gridElements.includes(comp.nativeElement)) {
          this.grid.add(comp.nativeElement)
          const { dimensions } = comp.data
          if (!dimensions) this.setDefaultDimensions(comp)
          else (this.setDimensions(comp, dimensions))
        } else {
          console.log('skipping',comp.widget.id)
        }
      })
    })

  }

  remove(id: string, el: ElementRef) {
    // have to manually remove from the grid so muuri properly frees up space
    this.grid.remove(el.nativeElement)
    // then filter the item out of the list
    this.widgetItems = this.widgetItems.filter(x => x.widget !== id)
    this.saveWidgets()
  }

  onSort({ fromIndex, toIndex }: { fromIndex: number, toIndex: number }) {
    const item = this.widgetItems.splice(fromIndex, 1)[0]
    this.widgetItems.splice(toIndex, 0, item)
    this.saveWidgets();
  }

  onResize(element: HTMLElement, dimensions: { width?: number, height?: number }, widgetItem: IWidgetInstance) {
    this.grid.refreshItems(element).layout()
    widgetItem.dimensions = dimensions
    this.saveWidgets();
  }

  // Whether the user is currently adding a widget
  public adding = false;
  toggleAdding() {
    this.adding = !this.adding
  }

  addWidget(widget: IWidgetData) {
    // subscribe to the next change (which should be the )
    this.widgetItems = [...this.widgetItems, { widget: widget.id }]
    this.adding = false
    this.saveWidgets()
  }


}
