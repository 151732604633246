<div class='container-fluid my-app'>
  <app-messages></app-messages>
  <div class='row no-gutters'>
    <div role="navigation" class=''>
      <cots-nav-menu></cots-nav-menu>
    </div>
      <div class='outlet container-fluid pr-0' [ngClass]='{ "min": navExpanded, "max": (!navExpanded) }'>
        <nav-header></nav-header>
        <div>
          <router-outlet></router-outlet>
        </div>
      </div>
  </div>
</div>
