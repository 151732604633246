import { Component, OnInit, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaterialBid } from '../../../../purchasing/resources/materialBid';
import { MaterialBidService } from '../../../../purchasing/services/material-bid.service';
import { Product, ProductPurchasedItem } from '../../../resources/product';
import { PurchasedItem } from '../../../resources/purchased-item';
import { Station } from '../../../resources/station';
import { OrderService } from '../../../services/order.service';
import { StationService } from '../../../services/station.service';
import { WorkflowStep } from '../../../resources/workflow';
import { Material } from '../../../resources/material';

@Component({
  selector: 'estimate-approval-lead-time-breakdown',
  templateUrl: './estimate-approval-lead-time-breakdown.component.html',
  styleUrls: ['./estimate-approval-lead-time-breakdown.component.less']
})
export class EstimateApprovalLeadTimeBreakdownComponent implements OnInit, OnChanges {

  @Input() productId: string;
  @Input() product: Product;
  @Input() children: Product[];
  @Input() childLeadTimes: { [productId: string]: number };
  @Input() editing = false;
  @ViewChild('bufferUpdateDialogTemplate', { static: true }) bufferUpdateDialogTemplate: TemplateRef<any>;

  @Output() bufferChange = new EventEmitter<void>();
  @Output() foundMissingQuote = new EventEmitter<WorkflowStep | Material | ProductPurchasedItem>();

  public expanded: string | null = null;

  constructor(private orderService: OrderService, private materialBidService: MaterialBidService, private stationService: StationService, private dialog: MatDialog) { }

  public breakdown: {
    material: {
      leadTime: number,
      quote: MaterialBid,
    },
    purchased: {
      items: { item: PurchasedItem, quote: MaterialBid, quantity: number, leadTime: number }[],
      total: number,
    }
    outsourced: {
      items: { item: Station, quote: MaterialBid, leadTime: number }[],
      total: number,
    },
    labor: {
      items: { item: Station, hours: number }[],
      total: number,
    },
    children: {
      items: { item: Product, leadTime: number }[],
      highest: number
    }
    total: number,
  }

  public get isRepair(): boolean {
    return !!this.product.productRepairPlan;
  }

  public get firstQuantity(): number {
    if (this.product.parentAssemblyId && this.product.quantityAsChild) return this.product.quantityAsChild;
    if (this.product.orderQuantity) return this.product.orderQuantity;
    if (this.product.quantitiesMap == null) return 1;

    var quantities = this.product.quantitiesMap;

    if (quantities.length == 0) return 1;

    return quantities[0].value;
  }

  public async generateBreakdown() {
    var breakdown: EstimateApprovalLeadTimeBreakdownComponent["breakdown"] = {} as any;

    if (!this.isRepair && this.product.materialId) {
      if (this.product.selectedMaterialQuote) {
        var materialQuote = await this.materialBidService.get(this.product.selectedMaterialQuote).toPromise();
        if (!materialQuote) this.foundMissingQuote.emit(this.product.material);
        breakdown.material = {
          leadTime: materialQuote ? materialQuote.leadTimeDays : 0,
          quote: materialQuote,
        }
      }
      else {
        this.foundMissingQuote.emit(this.product.material);
        breakdown.material = {
          leadTime: (this.product.material && this.product.material.defaultLeadTimeDays) || 0,
          quote: null,
        }
      }
    } else {
      breakdown.material = {
        leadTime: 0,
        quote: null,
      }
    }

    const workingPurchasedItems = this.isRepair ? this.product.productRepairPlan.purchasedItems : this.product.purchasedItems;

    const purchasedItems: typeof breakdown["purchased"]["items"] = await Promise.all(workingPurchasedItems
      .map(async (ppi) => {
        if (ppi.selectedQuote) {
          var quote = await this.materialBidService.get(ppi.selectedQuote).toPromise()
          if (!quote) this.foundMissingQuote.emit(ppi);
          return {
            item: ppi.purchasedItem,
            quote,
            quantity: ppi.quantity,
            leadTime: quote?.leadTimeDays ?? 0
          }
        } else this.foundMissingQuote.emit(ppi);
      }));

    breakdown.purchased = {
      items: purchasedItems,
      total: purchasedItems.reduce((acc, x) => acc + x.leadTime, 0)
    }

    const workingWorkflow = this.isRepair ? this.product.productRepairPlan.workflow : this.product.workflow;

    const outsourced: typeof breakdown["outsourced"]["items"] = await Promise.all(workingWorkflow.workflowSteps
      .filter(s => s.outsourceMarkup > 0)
      .map(async (step) => {
        if (step.selectedQuote) {
          var quote = await this.materialBidService.get(step.selectedQuote).toPromise()
          if (!quote) this.foundMissingQuote.emit(step);
          var station = (this.stationService.stationList || []).find(s => s.stationId === step.stationId);
          return {
            item: station,
            quote,
            leadTime: quote?.leadTimeDays ?? 0
          }
        } else this.foundMissingQuote.emit(step);
      }));

    breakdown.outsourced = {
      items: outsourced,
      total: outsourced.reduce((acc, x) => acc + x?.leadTime, 0)
    }

    const labor: typeof breakdown["labor"]["items"] = await Promise.all(workingWorkflow.workflowSteps
      .filter(s => !s.outsourceMarkup || s.outsourceMarkup === 0)
      .map(async (step) => {
        var station = (this.stationService.stationList || []).find(s => s.stationId === step.stationId);

        var hours: number;
        if (step.runIsPerPart) {
          hours = (((step.runTime || 0) / 60) + ((step.hasSetup && step.perPieceSetupTime) ? (step.perPieceSetupTime / 60) : 0)) * this.firstQuantity;
        } else {
          hours = step.runTime || 0;
        }

        return {
          item: station,
          hours
        }
      }));

    breakdown.labor = {
      items: labor,
      total: Math.ceil(
        (labor.reduce((acc, x) => acc + x.hours, 0)) / 8
      )
    }

    const childrenItems: typeof breakdown["children"]["items"] = this.children.map(c => {
      return {
        item: c,
        leadTime: this.childLeadTimes[c.productId]
      }
    })

    breakdown.children = {
      items: childrenItems.sort((a, b) => b.leadTime - a.leadTime),
      highest: childrenItems.length === 0 ? 0 : Math.max(...childrenItems.map(x => x.leadTime))
    }


    breakdown.total = breakdown.material.leadTime + breakdown.purchased.total + breakdown.outsourced.total + breakdown.labor.total + breakdown.children.highest;

    this.breakdown = breakdown;


  }

  public setSelected(s: string) {
    if (this.expanded === s) this.expanded = null;
    else this.expanded = s;
  }

  public async editBuffer() {
    const ref = this.dialog.open<any, any, number | null>(this.bufferUpdateDialogTemplate, {
      disableClose: true,
      data: {
        product: this.product,
        buffer: this.product.leadTimeBuffer
      }
    });
    const result = await ref.afterClosed().toPromise();
    if (result === null) return;
    else {
      let newBuffer = parseInt(result.toString());
      if (isNaN(newBuffer)) newBuffer = 0;
      await this.orderService.setLeadTimeBuffer(this.product, newBuffer).toPromise();
      this.product.leadTimeBuffer = newBuffer;
      this.bufferChange.emit();
    }
  }


  ngOnInit() {
    this.generateBreakdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productId.previousValue !== changes.productId.currentValue) {
      if (this.product) {
        this.expanded = null;
        this.breakdown = null;
        this.generateBreakdown();
      }
    }
  }
}
