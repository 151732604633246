<search-result *ngIf="results; else loading" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)" emptySetMessage="-- No New Jobs --">
  <thead header-template>
    <tr class="text-uppercase text-muted">
      <th>&nbsp;</th>
      <th>WO#</th>
      <th>Station</th>
      <th>Hours</th>
      <th>Queue</th>
      <th>Date</th>
    </tr>
  </thead>
  <ng-template let-row>
    <td #trigger="matMenuTrigger" [matMenuTriggerFor]="departmentMenu"><i class="text-muted fas fa-fw fa-lg fa-caret-left"></i></td>
    <td>{{row.workOrder.workOrderNumber}}</td>
    <td>{{getStation(row.operation.stationId)?.name}}</td>
    <td>{{(row.operation.runTime) | number: '0.2-2'}}</td>
    <td>{{row.scheduledStart | date: 'HH:mm'}}</td>
    <td>{{row.scheduledStart | date: 'shortDate'}}</td>
  </ng-template>
</search-result>

<mat-menu #departmentMenu="matMenu">
  <div class="text-muted pl-2 pr-2 pb-2 pt-0 small font-italic text-center border-bottom border-secondary">Choose Building...</div>
  <button mat-menu-item [matMenuTriggerFor]="machineMenu" *ngFor="let floorBuilding of department.floorBuildings" (mouseenter)="setDepartment(floorBuilding)">{{floorBuilding.name}}</button>
  <div class="text-muted p-2 small text-center" *ngIf="department.floorBuildings.length==0">
    -- No Departments! --
  </div>
</mat-menu>

<mat-menu #machineMenu="matMenu">
  <ng-template matMenuContent>
    <div class="text-muted pl-2 pr-2 pb-2 pt-0 small font-italic text-center border-bottom border-secondary">Assign to Station</div>
    <div *ngIf="filteredMachines; else wait">
      <button (click)="assignTo(machine)" mat-menu-item *ngFor="let machine of filteredMachines">{{machine.name}}</button>
      <div class="text-muted p-2 small text-center" *ngIf="filteredMachines.length==0">
        -- No Capable Stations --
      </div>
    </div>
    <ng-template #wait>
      <div class="text-center text-primary">
        <i class="fas fa-lg fa-spin fa-circle-notch"></i>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>

<ng-template #loading>
  <div class="center-spinner">
    <mat-spinner [color]="'warn'"></mat-spinner>
  </div>
</ng-template>
