<div class="btn-group" *ngIf="editable; else readonly">  
  <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{selection?selection.name:'Select Department...'}}
  </a>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
    <a *ngFor="let department of departments" href="#" class="dropdown-item" (click)="selectDepartment(department)">{{department.name}}</a>
  </div>
</div>

<ng-template #readonly>
  <span class="text-muted small">{{selection?selection.name:'-- No Department Selected --'}}</span>
</ng-template>
