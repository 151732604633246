<a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel Edit" (click)="router.navigate(['/quality'])" *ngIf="!sidenav.opened"><i class="fas fa-times"></i></a>
<a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Renew Certificate" matTooltip="Renew Certificate" (click)="updateContract()" *ngIf="ticket?.status === 0 && !sidenav.opened"><i class="fas fa-arrows-rotate"></i></a>

<loading *ngIf="loading"></loading>
<loading *ngIf="saving" content="Saving..."></loading>

<mat-sidenav-container>
  <mat-sidenav disableClose #sidenav mode="over" position="end">
    <upload-file *ngIf="showEditor=='document'" (documentsLoaded)="addDocuments($event)" [sidenav]="sidenav"></upload-file>
  </mat-sidenav>
  <mat-sidenav-content *ngIf="ticket && vendor && certificate && !loading">
    <quality-assign
      class="my-1"
      [record]="ticket"
      (startLoading)="this.loading = true;"
      (finishLoading)="this.getDetail();"
    ></quality-assign>
    <div class="row">
      <div class="col-12 pr-0 mb-2">
        <div class="w-100 m-0 p-3 bg-light text-dark rounded border">
          <div class="row w-50">
            <div class="col-12">
              <h5 class="text-muted">Ticket Details</h5>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Vendor</label>
                <div class="ticket-info">{{ vendor.name }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Certificate Name</label>
                <div class="ticket-info">{{ certificate.certificateName }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Certificate Expires On</label>
                <div class="ticket-info">
                  {{certificate.expirationDate | date}}
                  <span *ngIf="certificate.expirationDate!=null" class="badge required" [ngClass]="getRequiredColor(certificate.expirationDate)">
                    <span class="d-block small">{{weeksLeft(certificate.expirationDate, true)||'--'}} Week(s) {{(weeksLeft(certificate.expirationDate, false)||0) >= 0 ? 'From Today':'Overdue'}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="bg-light rounded border h-100">
          <h5 class="w-100 p-2 bg-secondary">Old Certificate Documents</h5>
          <div class="p-2">
            <file-display-grid [editable]="false" [files]="certificate.vendorCertificateDocuments"></file-display-grid>
            <p *ngIf="certificate.vendorCertificateDocuments.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="bg-light rounded border h-100">
          <h5 class="w-100 p-2 bg-secondary">
            New Certificate Documents
          </h5>
          <div class="p-2">
            <file-display-grid (delete)="deleteDocument($event)" [editable]="true" [files]="ticket.qualityTicketDocuments"></file-display-grid>
            <p *ngIf="ticket.qualityTicketDocuments?.length==0" class="text-muted text-center"><em>-- No Documents --</em></p>
          </div>
          <a class="btn btn-badge bg-success has-icon float-right mb-2" title="Add Document" (click)="openDocumentWindow()"><i class="fas fa-plus mt-2"></i></a>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #none>
  <div class="text-muted">
    --
  </div>
</ng-template>


<ng-template #finalizeDialog>
  <h2 matDialogTitle>Finish Renewing Certificate</h2>
  <mat-dialog-content>
    <h6 class="text-center">To renew the certificate, please select a new expiration date for the <b>{{ certificate.certificateName }}</b> certificate.</h6>
    <div class="mt-3">
      <date-picker [min]="certificate.expirationDate" [required]="true" [placeholder]="'Date'" [editable]="true" #datePicker></date-picker>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose="null">Cancel</button>
    <button mat-button color="primary" [matDialogClose]="datePicker.date" [disabled]="datePicker.invalid">Finalize</button>
  </mat-dialog-actions>
</ng-template>