import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Building } from '../../../floor/resources/building';
import { FloorService } from '../../../floor/services/floor.service';

@Component({
  selector: 'building-dropdown',
  templateUrl: './building-dropdown.component.html',
  styleUrls: ['./building-dropdown.component.less']
})
export class BuildingDropdownComponent implements OnInit {
  @Input() selectedBuildingId: string;
  @Input() editable: boolean = false;
  public selection: Building;
  @Output() selectedBuildingChange: EventEmitter<Building> = new EventEmitter<Building>();
  public buildings: Building[] = null;

  constructor(private floorSvc: FloorService) {
  }

  public selectBuilding(bld: Building): void {
    event.preventDefault();
    this.selection = bld;
    this.selectedBuildingChange.emit(bld);
  }

  ngOnInit(): void {
    this.floorSvc.searchBuildings('').subscribe(result => {
      this.buildings = result.results;
      this.selection = this.buildings.find(b => b.buildingId == this.selectedBuildingId);
    });
  }

  ngOnChanges(_: SimpleChanges): void {
    if (!this.buildings) return;
    this.selectBuilding(this.buildings.find(b => b.buildingId == this.selectedBuildingId));
  }

}
