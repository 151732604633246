<div class="position-fixed app-messages">
  <div class="alert alert-dismissible fade show shadow" [ngClass]="getClass(message, 'alert')" role="alert" *ngFor="let message of messages">
    {{message.messageText}}
    <div class="small float-right" *ngIf="message.messagePayload">
      <button type="button" class="btn btn-sm" (click)="showErrorDetail(message)" [ngClass]="getClass(message, 'btn')" data-toggle="modal" [attr.data-target]="'#detailModal_'+message.messageId">Click Here For More Information</button>
    </div>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="acknowledge(message)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
