<mat-sidenav-container style="
padding-top: 15px;
margin-right: 0 !important;
margin-left: 0 !important;"
>
  <mat-sidenav-content class="border rounded">
      <mat-tab-group color="primary" backgroundColor="primary" class="h-100 queue-tab-group">
        <mat-tab [class.invisible]="!currentBuilding">
          <ng-template mat-tab-label>
            <!-- <mat-icon>today</mat-icon>
            <span class="tab-title">Today</span>
            <span class="badge bg-white text-primary mx-1" *ngIf="todayTickets?.length > 0">
              {{todayTickets.length}}
            </span> -->
            <span class="tab-title">All Tickets</span>
            <i class="ml-1 fas fa-circle-notch fa-spin" [class.invisible]="todayTickets"></i>
          </ng-template>
          <div class="bg-white">
            <mat-selection-list
              *ngIf="todayTickets">
              <mat-list-option disabled *ngIf="todayTickets.length === 0">
                <div class="text-muted font-italic text-center">
                  There are no active inspection tickets today.
                </div>
              </mat-list-option>
              <ng-container *ngFor="let ticket of todayTickets">
                <mat-list-option matRipple matRippleColor="rgba(0,0,0,.04)" #option class="ticket-line" [class.ticket-line-selected]="option.selected">
                  <div class="d-flex align-items-center item-flex">
                    <!-- Hidden temporarily for non-operator inspection -->
                    <!-- <div style="flex-basis: 10%;" *ngIf="!ticket.partAvailableTime">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase" *ngIf="!isOverdue(getExpectedTime(ticket))">
                        expected
                      </div>
                      <div class="mat-line font-weight-bold small text-uppercase" *ngIf="isOverdue(getExpectedTime(ticket))">
                        Work is Late
                      </div>
                      <div mat-line style="font-size: 2em; font-weight: bold; line-height: 1em;">
                        {{ getExpectedTime(ticket) | date: 'HH:mm' }}
                      </div>
                      <div class="mat-line font-weight-bold small text-uppercase" *ngIf="!isToday(getExpectedTime(ticket))">
                        {{ getExpectedTime(ticket) | date: 'yyyy/MM/dd' }}
                      </div>
                    </div>
                    <div style="flex-basis: 10%;" *ngIf="ticket.partAvailableTime" [class.text-danger]="isOverdue(ticket.partAvailableTime)">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase" *ngIf="!isOverdue(ticket.partAvailableTime)">
                        ready at
                      </div>
                      <div class="mat-line font-weight-bold small text-danger text-uppercase" *ngIf="isOverdue(ticket.partAvailableTime)">
                        Overdue
                      </div>
                      <div mat-line style="font-size: 2em; font-weight: bold; line-height: 1em;">
                        {{ ticket.partAvailableTime | date: 'HH:mm' }}
                      </div>
                      <div class="mat-line font-weight-bold small text-uppercase" *ngIf="!isToday(ticket.partAvailableTime)">
                        {{ ticket.partAvailableTime | date: 'yyyy/MM/dd' }}
                      </div>
                    </div>
                    <div style="flex-basis: 12%;">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        Status
                      </div>
                      <mat-chip class="text-white" [ngClass]="[getStatusBackgroundColorClass(ticket.status), getStatusColorClass(ticket.status)]">
                        {{ getStatusText(ticket.status) }}
                      </mat-chip>
                    </div> -->
                    <!--  -->
                    <div style="flex-basis: 18%;">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        Type
                      </div>
                      <mat-chip class="text-white" [ngClass]="{
                        'bg-first-part': ticket.inspectionType == 0 && !ticket.requiresReport,
                        'bg-fair': ticket.inspectionType == 0 && ticket.requiresReport,
                        'bg-lot-inspection': ticket.inspectionType == 1
                      }">
                        <i ngPrefix class="mr-1 fas" [ngClass]="{
                          'fa-box': ticket.inspectionType == 0 && !ticket.requiresReport,
                          'fa-box-archive': ticket.inspectionType == 0 && ticket.requiresReport,
                          'fa-boxes': ticket.inspectionType == 1
                        }">
                        </i>
                        {{ getInspectionTypeText(ticket) }}
                      </mat-chip>
                    </div>
                    <div style="flex-basis: 15%;">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        Method
                      </div>
                      <mat-chip class="text-white" [ngClass]="{
                        'bg-cmm': ticket.isCMM,
                        'bg-non-cmm': !ticket.isCMM
                      }">
                        <i ngPrefix class="mr-1 fas" [ngClass]="{
                          'fa-terminal': ticket.isCMM,
                          'fa-user-check': !ticket.isCMM
                        }">
                        </i>
                        {{ getInspectionMethodText(ticket) }}
                      </mat-chip>
                    </div>
                    <div style="flex-basis: 12%;">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        Operation
                      </div>
                      <div class="mat-line font-weight-bold">
                        {{ getStation(ticket.machineAssignment.operation.stationId)?.name }}
                      </div>
                    </div>
                    <div style="flex-grow: 1;">
                      <div class="mat-line font-weight-bold small text-muted text-uppercase">
                        Work order #
                      </div>
                      <div class="mat-line font-weight-bold">
                        {{ ticket.machineAssignment.workOrder.workOrderNumber }}
                      </div>
                    </div>
                    <div class="d-block action-bar-outer px-0" style="flex-grow: 2;">
                      <div class="d-flex align-items-center justify-content-start action-bar">
                          <!-- Condition for below should be *ngIf="ticket.partAvailableTime" when operator module is working -->
                          <button 
                          mat-raised-button 
                          matRippleColor="rgba(0,0,0,.04)" 
                          class="text-white bg-primary"
                          (click)="this.selectTicket(ticket)" *ngIf="true">
                              <mat-icon matPrefix>assignment</mat-icon>
                              Inspect
                          </button>
                          <!-- <button 
                          mat-raised-button 
                          matRippleColor="rgba(0,0,0,.04)"
                          *ngIf="!ticket.employee && userIsManager()"
                          class="text-white bg-danger"
                          (click)="$event.stopPropagation(); assignTicket(ticket)">
                              <mat-icon matPrefix>person_add_alt_1</mat-icon>
                              Assign
                          </button> -->
                      </div>
                  </div>
                  </div>
                </mat-list-option>
                <mat-divider ></mat-divider>
            </ng-container>
            </mat-selection-list>
          </div>
        </mat-tab>
        <!-- Building filter hidden until operator module is running -->
        <!-- <mat-tab disabled>
          {{ currentBuilding }}
          <ng-template mat-tab-label>
            <div class="btn-group m-2">
              <button class="btn btn-outline-light building-label" disabled>Building</button>
              <a class="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{currentBuilding?.name || "--"}}
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                <p class="text-center text-muted" *ngIf="!($buildings | async)">
                  <i class="fas fa-circle-notch fa-spin fa-fw text-primary"></i>
                  Loading...
                </p>
                <p class="text-center text-muted font-italic" *ngIf="($buildings | async)?.length==0">
                  <i class="fas fa-exclamation-triangle fa-fw text-danger"></i>
                  <small>No Departments Found!</small>
                </p>
                <a *ngFor="let b of ($buildings | async)" [ngClass]="{'active': (currentBuilding?.buildingId==b.buildingId)}" class="dropdown-item" (click)="selectBuilding(b)">{{b.name}}</a>
              </div>
            </div>
          </ng-template>
        </mat-tab> -->
        <mat-tab disabled>
          <ng-template mat-tab-label>
            <div class="m-2" style="width: 20vw;">
              <mat-form-field appearance="outline" class="wo-search-field w-100">
                <mat-label>Filter by WO #</mat-label>
                <input matInput type="text" [(ngModel)]="searchFilter" #filterModel="ngModel">
                <i matSuffix [class.invisible]="!searching" class="text-primary fas fa-spin fa-circle-notch" style="position: relative; top: -7px;"></i>
              </mat-form-field>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
  </mat-sidenav-content>
</mat-sidenav-container>