import { Component, OnInit, ViewChild } from '@angular/core';
import { Department } from '../../resources/building';
import { FloorService } from '../../services/floor.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'floor-main',
  templateUrl: './floor-main.component.html',
  styleUrls: ['./floor-main.component.less']
})
export class FloorMainComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  public departments: Department[] = null;
  public selectedAssignment: MachineAssignment;
  public showEditor: string = null;

  constructor(private navService: NavigationService, private floorService: FloorService, private router: Router) {
    navService.setPageTitle("Floor");
    navService.pushBreadcrumb("Overview");
  }

  public showBuilding(department: Department): void {
    event.preventDefault();

    this.router.navigate(['/floor/department', department.departmentId]);
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  public openAssignment(assignment: MachineAssignment): void {

    if (assignment.machineId != null) {
      this.selectedAssignment = assignment;
      this.showEditor = 'assignment';
      this.sidenav.open();
    }
    else {
      this.router.navigate(['floor/department', assignment.departmentId]);
    }
  }

  ngOnInit(): void {
    this.floorService.search("").subscribe(result => {
      this.departments = result.results;
    });
  }
}
