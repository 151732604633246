<div *ngIf="!results" class="center-spinner">
  <mat-spinner [color]="'warn'"></mat-spinner>
</div>
<search-result *ngIf="results" [results]="searchResults" class="w-100" (newPage)="getPage($event)" (selected)="onSelect($event)" emptySetMessage="-- No Tasks --">
  <thead header-template>
    <tr class="text-uppercase text-muted">
      <th>
        Work Order
      </th>
      <th>
        Task
      </th>
      <th>
        Hours
      </th>
    </tr>
  </thead>
  <ng-template let-row>
    <td draggable="true" (dragstart)="onDragStart($event, row)">{{row.workOrder.workOrderNumber}}</td>
    <td draggable="true" (dragstart)="onDragStart($event, row)">{{getStation(row.operation?.stationId)?.name}}</td>
    <td draggable="true" (dragstart)="onDragStart($event, row)">{{(row.operation.runTime || 0) | number: '1.0-2'}}</td>
  </ng-template>
</search-result>
