<loading *ngIf="saving" content="Saving..."></loading>
<div class="row mb-2">
  <div class="col flex-grow-1">
    <mat-form-field class="w-100" appearance="standard">
      <mat-select [(ngModel)]="materialTypeDimension.dimensionType" placeholder="Select Type" [class.border-danger]="typeInvalid" [compareWith]="compareType">
        <mat-select-trigger *ngIf="materialTypeDimension.dimensionType">
          {{materialTypeDimension.dimensionType.label}}
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search [formControl]="typesFilter" placeholderLabel="Search Type">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let type of filteredTypes" [value]="type">{{type.label}}</mat-option>
        <mat-option *ngIf="filteredTypes.length === 0" disabled>
          <div class="d-flex">
            <div class="ml-auto">No Matching Types Found</div>
            <div class="ml-auto">
              <button class="btn btn-primary" (click)="createType.emit(typesFilter.value)">Create</button>
            </div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col flex-grow-1">
    <mat-form-field class="w-100" appearance="standard">
      <mat-select [(ngModel)]="materialTypeDimension.dimensionUnit" placeholder="Select Unit" [class.border-danger]="unitInvalid" [compareWith]="compareUnit">
        <mat-select-trigger *ngIf="materialTypeDimension.dimensionUnit">
          {{materialTypeDimension.dimensionUnit.label}} <small>({{materialTypeDimension.dimensionUnit.abbreviation}})</small>
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search [formControl]="unitsFilter" placeholderLabel="Search Unit">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let unit of filteredUnits" [value]="unit">{{unit.label}} <small>({{unit.abbreviation}})</small></mat-option>
        <mat-option *ngIf="filteredUnits.length === 0" disabled>
          <div class="d-flex">
            <div class="ml-auto">No Matching Units Found</div>
            <div class="ml-auto">
              <button class="btn btn-primary" (click)="createUnit.emit(unitsFilter.value)">Create</button>
            </div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col flex-grow-0">
    <div class="btn btn-block btn-danger" (click)="this.deleted.emit()" style="height: 44px; line-height: 29px;">
      <i class="fas fa-times"></i>
    </div>
  </div>
</div>