<div class="w-100" *ngLet="userService.userList | async as userList">
    <div class="w-100 border-bottom p-3">
        <h3 class="mb-0 ml-4">Quote History</h3>
    </div>
    <search-list-new matSort [defaultSort]="{
        id: 'accepted',
        start: 'asc'
      }" [showManagerToggle]="false" [resultGetter]="search.bind(this)"
        [displayedColumns]="['vendor', 'price', 'quantity', 'accepted', 'user']"
        >
        <ng-container matColumnDef="vendor">
            <mat-header-cell *matHeaderCellDef> Vendor </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.vendor.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef> Price</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span><b>{{ ( row.perItemBid ? row.perItemBid : row.totalBid) | currency }}</b>{{ row.perItemBid ? '/item' : ' total '}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef> Quantity </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.qty}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accepted">
            <mat-header-cell *matHeaderCellDef> Accepted On </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="row.accepted"> {{ row.accepted | date: 'shortDate' }}</span>
                <span *ngIf="!row.accepted" class="text-muted">--</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef> Accepted By </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="row.acceptedBy"> {{ getUserName(row.acceptedBy, userList) }}</span>
                <span *ngIf="!row.acceptedBy" class="text-muted">Unknown User</span>
            </mat-cell>
        </ng-container>
     </search-list-new>
</div>