import { Component, Input, Output, EventEmitter, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { FloorBuilding, Department, FloorMachine, Building } from '../../resources/building';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { FloorService } from '../../services/floor.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { DepartmentTodayScheduleComponent } from '../../charts/department-today-schedule/department-today-schedule.component';
import { DepartmentFutureScheduleComponent } from '../../charts/department-future-schedule/department-future-schedule.component';
import { MachineAssignmentListComponent } from '../machine-assignment-list/machine-assignment-list.component';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { FloorAlert } from '../../resources/floor-alert';

@Component({
  selector: 'building-detail',
  templateUrl: './building-detail.component.html',
  styleUrls: ['./building-detail.component.less']
})
export class BuildingDetailComponent implements OnInit {
  @Input() building: FloorBuilding;
  @Output() departmentChange: EventEmitter<FloorBuilding> = new EventEmitter<FloorBuilding>();
  @Input() sidenav: MatSidenav;
  @ViewChild('insetnav', { static: true }) insetnav: MatSidenav;
  @ViewChild('todayData') todayData: DepartmentTodayScheduleComponent;
  @ViewChild('futureData') futureData: DepartmentFutureScheduleComponent;
  @ViewChild('machineAssignments') machineAssignments: MachineAssignmentListComponent;

  public record: FloorBuilding;
  public department: Department;
  public editing: boolean = false;
  public adding: boolean = false;
  public topDownView: boolean = false;
  public selectedMachine: FloorMachine;
  public showEditor: string = null;
  public saving: boolean = false;
  public hoursData: any = null;
  public detailDataDate: Date = new Date();
  public selectedAssignment: MachineAssignment;

  constructor(private navService: NavigationService, private floorSvc: FloorService, private usrService: UserService, private utilitySvc: UtilityService) { }

  public close(): void {
    this.sidenav.close();
  }

  public switchView(): void {
    this.topDownView = !this.topDownView;

    this.navService.popBreadCrumb();
    this.navService.pushBreadcrumb(this.record.name + (this.topDownView ? '' : ' (Detail)'));
  }

  public toggleEdit(): void {
    this.editing = !this.editing;
    this.adding = false;
    this.selectedMachine = null;
  }

  public saveLayout(): void {
    this.saving = true;

    if (this.selectedMachine != null) {
      //Make sure we save any machine changes too...
      this.floorSvc.saveMachine(this.selectedMachine).subscribe(res => {
        this.selectedMachine.machineId = res.machineId;
        this.selectedMachine = null;

        this.floorSvc.saveMachines(this.record, this.record.machines).subscribe(_ => {
          this.saving = false
          this.getData();

          this.editing = this.adding = false;
        });
      });

    }
    else {
      this.floorSvc.saveMachines(this.record, this.record.machines).subscribe(_ => {
        this.saving = false
        this.getData();

        this.editing = this.adding = false;
      });
    }
  }

  public cancelEdit(): void {
    this.editing = this.adding = false;
    this.getData();
  }

  public switchDepartment(dpt: FloorBuilding) {
    event.preventDefault();
    this.building = dpt;
    this.departmentChange.emit(dpt);

    this.getData();
  }

  public selectMachine(machine: FloorMachine): void {
    event.stopImmediatePropagation();

    this.clearMachineSelection();

    this.adding = (machine.machineId == UtilityService.emptyGuid);
    this.selectedMachine = machine;
  }

  public clearMachineSelection(): void {
    this.selectedMachine = null;
  }

  public openMachineDetails(): void {
    this.showEditor = 'machine';
    this.insetnav.open();
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getData();

    //NOTE: these might be null, depending on visibility
    if (this.todayData) this.todayData.reload();
    if (this.futureData) this.futureData.reload();
  }

  public saveMachine(): void {
    this.saving = true;
    this.floorSvc.saveMachine(this.selectedMachine).subscribe(res => {
      var i = this.record.machines.findIndex(m => m.machineId == this.selectedMachine.machineId);

      this.record.machines[i] = this.selectedMachine = res;
      this.saving = this.adding = false;
    });
  }

  public getAlertDetail(record: FloorAlert): void {
    if (record.floorMachineId) {
      this.selectedMachine = record.machine;
      this.openMachineDetails();
    }
    else {
      this.utilitySvc.showAlert("Alert Message", `<i class='fas fa-fw fa-2x ${(record.priority == 1) ? 'text-danger fa-exclamation-triangle' : (record.priority == 3 ? 'text-info fa-long-arrow-alt-down' : '')} '></i>&nbsp;` + record.message);
    }
  }

  public showHoursSum(machineId: string): number {
    if (!this.machineAssignments || this.machineAssignments.resultData == null) return 0;
    return this.machineAssignments.resultData.filter(d => d.machineId == machineId).reduce((previous, current, _index) => { return previous + ((current.operation.runTime || 0) / 60) }, 0);
  }

  public showAssignment(e: MachineAssignment): void {
    this.floorSvc.getAssignment(e.assignmentId).subscribe(result => {
      this.selectedAssignment = result;
      this.showEditor = 'assignment';
      this.insetnav.open();
    });
  }

  public removeMachine(): void {
    this.utilitySvc.showConfirmation(`Remove Machine '${this.selectedMachine.name}' Are you Sure?`, "<p class='text-center bg-danger text-white font-weight-bold p-2'><i class='fas fa-exclamation-triangle text-warning fa-2x pr-2'></i>WARNING: Removing a machine can have serious consequenses for planning.<i class='fas fa-exclamation-triangle text-warning fa-2x pl-2'></i></p><p class='text-muted'>Please check with planning before removing any machines. Once you're sure, click 'Okay' to remove this machine.</p>", r => {
      if (r) {
        this.saving = true;
        this.floorSvc.removeMachine(this.selectedMachine).subscribe(_ => {
          this.selectedMachine = null;
          this.saving = this.adding = false;

          this.getData();
        });
      }
    });
  }

  public addMachine(): void {
    this.adding = true;
    this.selectedMachine = <FloorMachine>{
      floorBuildingId: this.record.floorBuildingId,
      machineId: UtilityService.emptyGuid,
      name: 'New Machine',
      width: 200,
      height: 200,
      posX: 20,
      posY: 20,
      extendedData: {}
    };
    this.record.machines.push(this.selectedMachine);
  }

  public canEditLayout(): boolean {
    return this.usrService.canAccess("FloorManager");
  }

  public getData(): void {
    this.record = null;
    this.floorSvc.getFloorBuilding(this.building.floorBuildingId).subscribe(res => {
      this.record = res;

      this.navService.popBreadCrumb();
      this.navService.pushBreadcrumb(this.record.name + (this.topDownView ? '' : ' (Detail)'));

      this.floorSvc.get(res.departmentId).subscribe(bldg => {
        this.department = bldg;
      });
    });

    this.floorSvc.getBuildingHours(this.building).subscribe(hours => this.hoursData = hours);
  }

  public showAssignmentDetails(assignment: MachineAssignment): void {
    this.selectedAssignment = assignment;
    this.showEditor = 'assignment';
    this.insetnav.open();
  }

  public switchBuilding(b: FloorBuilding): void {
    this.building = b;
    this.departmentChange.emit(b);
  }

  public select(event: any): void {
    var dsData = event.chart.getDatasetAtEvent(event.event);

    if (dsData && dsData.length > 0) {
      var originalData = this.todayData.rawData[dsData[0]._datasetIndex];

      if (originalData.machineId) {
        this.floorSvc.getMachine(originalData.machineId).subscribe(m => {
          this.selectMachine(m);
          this.openMachineDetails();
        });
      }
      else {
        //NOTE: we have the WO here, but not an assignment...
        //we could maybe prompt for unassigned jobs in this workorder ??

        this.utilitySvc.showConfirmation("Return to Department Screen?", "<p>Unassigned tasks for work orders can be assigned from the 'Unassigned Jobs' section of the previous screen.<p><p>Click 'Okay' to return to the department screen, or 'Cancel' to stay here.</p>", r => {
          if (r) {
            this.close();
          }
        });
      }
    }
  }

  public returnToToday(): void {
    this.detailDataDate = this.getDate();
  }

  public switchDate(event: any): void {
    var dsData = event.chart.getDatasetAtEvent(event.event);
    if (dsData && dsData.length > 0) {
      this.detailDataDate = new Date(this.futureData.futureLabels[event.chart.getElementAtEvent(event.event)[0]._index]);
    }
    else if (event.clickedElement && event.clickedElement.length > 0 && event.clickedElement[0].day) {
      this.detailDataDate = new Date(event.clickedElement[0].day);
    }
  }

  public getDate(): Date {
    return new Date();
  }

  public isToday(date: Date): boolean {
    return date.toLocaleDateString() == this.getDate().toLocaleDateString();
  }

  ngOnInit(): void {
    this.navService.pushBreadcrumb(this.building.name);

    this.getData();
  }
}
