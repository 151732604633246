import { Component, OnInit, AfterViewInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTable } from '@angular/material/table';
import { PseudoPurchasedItemPartNumber, PurchaseOrder, PurchaseOrderLineItem, PurchaseOrderStatus } from '../../resources/purchaseOrder';
import { NavigationService } from '../../../common/services/navigation.service';
import { PurchasingService } from '../../services/purchasing.service';
import { UtilityService } from '../../../common/services/utility.service';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserService } from '../../../common/services/user.service';
import { PurchasedItem, PurchasedItemPartNumber } from '../../../order/resources/purchased-item';
import { MaterialBid } from '../../resources/materialBid';
import { WorkOrder } from '../../../planning/resources/work-order';
import { NewLineItemData, NewLineItemSelectComponent } from '../new-line-item-select/new-line-item-select.component';
import { Material } from '../../../order/resources/material';
import { Station } from '../../../order/resources/station';
import { ControlContainer, NgForm } from '@angular/forms';
import { StationService } from '../../../order/services/station.service';
import { OutsideProcessDescription, getOutsideProcessDescriptionShort, getOutsideProcessDescriptionString, getOutsideProcessOpsOnly } from '../../resources/outsideProcessDescription';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'po-line-items',
  templateUrl: './po-line-items.component.html',
  styleUrls: ['./po-line-items.component.less'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PoLineItemsComponent implements OnInit, AfterViewInit {
  @Input() purchaseOrder: PurchaseOrder;
  @Input() purchaseOrderId: string;
  @Input() editing = false;
  @ViewChild('splitItemDialogTemplate', { static: true }) splitItemDialogTemplate: TemplateRef<any>;
  @Input() records: PurchaseOrderLineItem[] = [];
  @Output() recordsChange = new EventEmitter<PurchaseOrderLineItem[]>();
  public showEditor: string | null = null;
  public editItem: InventoryItem | null = null;

  constructor(private stationService: StationService, private navService: NavigationService, private purchasingSvc: PurchasingService, private utilitySvc: UtilityService, private usrService: UserService, private dialog: MatDialog, private ngForm: NgForm) {
  }

  

  public async addLineItem(event: NewLineItemData) {
    console.log(event);
    var newItem = <PurchaseOrderLineItem>{
      purchaseOrderLineItemId: UtilityService.emptyGuid,
      purchasedItemId: event.type === 'PURCHASED' ? event.purchasedItemPartNumber.purchasedItemId : null,
      purchasedItemPartNumberId: event.type === 'PURCHASED' ? event.purchasedItemPartNumber.purchasedItemPartNumberId : null,
      purchasedItem: event.type === 'PURCHASED' ? (event.purchasedItemPartNumber as unknown as PurchasedItem) : null,
      materialId: event.type === 'MATERIAL' ? event.material.materialId : null,
      // stationId: event.type === 'OUTSOURCED' ? event.station.stationId : null,
      material: event.type === 'MATERIAL' ? event.material : null,
      // station: event.type === 'OUTSOURCED' ? event.station : null,
      outsideProcessDescriptionId: event.type === 'OUTSOURCED' ? event.outsideProcess.outsideProcessDescriptionId : null,
      outsideProcessDescription: event.type === 'OUTSOURCED' ? event.outsideProcess : null,
      isMisc: event.type === 'MISC',
      miscDescription: event.type === 'MISC' ? event.misc : null,
      unitPriceOverride: event.type === 'MISC' ? 0 : null,
      lineNumber: 999,
      quantity: 1,
      purchaseOrderId: this.purchaseOrderId,
      name: event.misc ?? event.purchasedItemPartNumber?.partNumber ?? event.purchasedItemPartNumber?.description ?? event.material?.materialName ?? (
        event.outsideProcess ? getOutsideProcessDescriptionString(event.outsideProcess) : ''
      )
    };
    this.records = [...this.records, newItem];
    this.recordsChange.emit(this.records);
  }

  public newItem: { type: NewLineItemSelectComponent['type'], purchasedItemPartNumber?: PseudoPurchasedItemPartNumber, material?: Material, station?: Station };
  public newItemChange = new EventEmitter<NewLineItemData>();
  @ViewChild('newItemDialogTemplate') newItemDialogTemplate: TemplateRef<any>;
  public addItem() {
    this.newItem = { type: 'PURCHASED' };
    this.dialog.open(this.newItemDialogTemplate, {
      disableClose: true,
      width: '45vw'
    });
  }

  @Input() removedLineItems: PurchaseOrderLineItem[] = [];
  @Output() removedLineItemsChange = new EventEmitter<PurchaseOrderLineItem[]>();
  public remove(item: PurchaseOrderLineItem, index: number) {
      if (item.purchaseOrderLineItemId !== UtilityService.emptyGuid) {
      this.removedLineItems = [...this.removedLineItems, item];
      this.removedLineItemsChange.emit(this.removedLineItems);
    }
    this.records = this.records.filter((_, i) => i !== index);
    this.recordsChange.emit(this.records);
  }
  
  public async split(item: PurchaseOrderLineItem) {
    const dialogRef = this.dialog.open(this.splitItemDialogTemplate, {
      disableClose: true,
      data: {
        item,
        splitAmount: 1,
        dueDate: item.dueDate
      }
    })
    const result = await dialogRef.afterClosed().toPromise();
    if (result == null) return;
    const { dueDate, splitAmount } = result;

    var newItem = <PurchaseOrderLineItem>{
      purchaseOrderLineItemId: UtilityService.emptyGuid,
      purchasedItemId: item.purchasedItemId,
      materialId: item.materialId,
      stationId: item.stationId,
      productId: item.productId,
      purchasedItem: item.purchasedItem,
      material: item.material,
      station: item.station,
      product: item.product,
      materialBidId: item.materialBidId,
      lineNumber: 999,
      quantity: splitAmount,
      dueDate: dueDate,
      name: item.purchasedItemPartNumber?.partNumber ?? item.purchasedItem?.description ?? item.material?.materialName ?? item.station?.name,
      purchaseOrderId: this.purchaseOrderId
    };
    this.records = [...this.records, newItem];
    this.setLineNumbers();
    this.recordsChange.emit(this.records);
    
    item.quantity = item.quantity - splitAmount;
  }

  @ViewChild('lineItemsTable') lineItemsTable: MatTable<PurchaseOrderLineItem>;
  public drop(e: CdkDragDrop<any>): void {
    UtilityService.array_move(this.records, e.previousIndex, e.currentIndex);
    this.lineItemsTable.renderRows();
    this.setLineNumbers();
  }

  private setLineNumbers(): void {
    this.records.forEach((item, index) => {
      item.lineNumber = index + 1;
    });
  }

  public canAccess(module: string): boolean {
    return this.usrService.canAccess(module);
  }

  ngOnInit() {
    console.log(this.records);
    this.records = [...this.records];
  }

  public getLineItemUnitPrice(item: PurchaseOrderLineItem) {
    if (item.isMisc) return item.unitPriceOverride ?? 0;
    if (item.unitPriceOverride !== null && item.unitPriceOverride !== undefined) return item.unitPriceOverride;
    const bid = item.materialBid;
    if (!bid) return 0;
    if (bid.totalBid) return bid.totalBid / (bid.qty || 1);
    else if (bid.perItemBid) return bid.perItemBid;
    else return 0;
  }

  public getLineItemExtPrice(item: PurchaseOrderLineItem) {
    const unitPrice = this.getLineItemUnitPrice(item);
    const qty = item.mfgrUnitsQty ? item.mfgrUnitsQty : item.quantity;
    return unitPrice * qty;
  }

  public displayedColumns = ['name', 'wo', 'quantity', 'unitsweight', 'unitPrice', 'quote', 'extprice', 'duedate', 'taxable', 'delete'];
  public displayedColumnsMisc = ['miscName', 'wo', 'quantity', 'unitsweight', 'miscPrice', 'extprice', 'duedate', 'taxable', 'delete'];

  public getExistingBids(item: PurchaseOrderLineItem): MaterialBid[] {
    const bids = this.purchaseOrder.purchaseOrderMaterialBids?.map(pomb => pomb.materialBid).filter(mb => !mb.isPoPseudoBid) ?? [];

    let matchingBids: MaterialBid[] = [];
    if (item.materialId) matchingBids = bids.filter(b => b.materialId === item.materialId);
    else if (item.purchasedItemId) matchingBids = bids.filter(b => b.purchasedItemId === item.purchasedItemId);
    else if (item.stationId) matchingBids = bids.filter(b => b.stationId === item.stationId);

    return matchingBids;
  }

  public getExistingBidsMinusCurrent(item: PurchaseOrderLineItem): MaterialBid[] {
    const existing = this.getExistingBids(item);
    return existing.filter(b => b.materialBidId !== item.materialBidId);
  }

  @ViewChild('selectQuoteDialogTemplate', { static: true }) selectQuoteDialogTemplate: TemplateRef<any>
  public async selectExistingQuote(item: PurchaseOrderLineItem) {
    const matchingBids = this.getExistingBids(item);
    const ref = this.dialog.open<any, any, MaterialBid | null>(this.selectQuoteDialogTemplate, {
      disableClose: true,
      minWidth: 750,
      height: '50vh',
      data: {
        item,
        bids: matchingBids,
        selectedBid: null
      }
    });
    const result = await ref.afterClosed().toPromise();
    if (!result) return;
    item.materialBid = result;
    item.materialBidId = result.materialBidId;
  }
  

  public newQuoteData: MaterialBid;
  public quotingItem: PurchaseOrderLineItem;
  public async quickQuote(item: PurchaseOrderLineItem): Promise<void> {

    this.quotingItem = item;
    this.newQuoteData = <MaterialBid>{
      purchaseOrderId: this.purchaseOrderId,
      materialBidId: UtilityService.emptyGuid,
      material: item.material,
      materialId: item.materialId,
      station: item.station,
      stationId: item.stationId,
      purchasedItem: item.purchasedItem,
      purchasedItemId: item.purchasedItemId,
      workOrderId: item.materialBid && item.materialBid.workOrderId,
      asked: new Date(),
      answered: new Date(),
      isVerbal: false
    };

    this.showEditor = "quickquote";
    this.navService.pushBreadcrumb("Add Quick Quote");
    // this.insetnav.toggle();
  }

  public async quickQuoteApproved(bid: MaterialBid) {
    const qi = this.records.find(x => x.purchaseOrderLineItemId === this.quotingItem.purchaseOrderLineItemId);
    qi.materialBid = bid;
    qi.materialBidId = bid.materialBidId;
  }

  public detailedQuoteId: string;
  public showQuoteDetail(quoteId: string): void {
    if (quoteId == null) {
      this.utilitySvc.showAlert("No Quote was Found", "<p>Something seems to have gone wrong - we couldn't find a quote for the item you requested.</p><p class='text-muted'>If you continue to receive this error, please alert a management team member.</p>");
      return;
    }

    this.detailedQuoteId = quoteId;
    this.showEditor = "quotedetail";
    // this.insetnav.open();
  }

  @ViewChild('selectWoDepartmentDialogTemplate', { static: true }) selectWoDepartmentDialogTemplate: TemplateRef<any>
  public async editWoDepartment(item: PurchaseOrderLineItem) {

    let type: 'workOrder' | 'department';
    if (item.workOrder) type = 'workOrder';
    else if (item.department) type = 'department';
    else type = 'workOrder';

    const dialogRef = this.dialog.open<any, any, { type: typeof type, department: string, workOrder: WorkOrder}>(this.selectWoDepartmentDialogTemplate, {
      disableClose: true,
      minWidth: 500,
      data: {
        item,
        type,
        workOrder: type === 'workOrder' ? item.workOrder : null,
        department: type === 'department' ? item.department : null,
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    else {
      if (result.type === 'workOrder') {
        item.workOrder = result.workOrder;
        item.workOrderId = result.workOrder.workOrderId;
        item.department = null;
      }
      else if (result.type === 'department') {
        item.department = result.department;
        item.workOrder = null;
        item.workOrderId = null;
      }
    }
  }

  public get subtotal() {
    return this.records.reduce((acc, x) => acc + this.getLineItemExtPrice(x), 0)
  }

  public get shippingFees() {
    return this.purchaseOrder.shippingHandlingFees;
  }

  public get taxes() {
    if (this.purchaseOrder.taxRate === 0) return 0; 
    const lineItemTaxes = this.records.filter(r => r.taxable).reduce((acc, x) => acc + (this.getLineItemExtPrice(x) * (this.purchaseOrder.taxRate / 100)), 0);
    const shippingTaxes = this.purchaseOrder.shippingTaxable ? ((this.shippingFees ?? 0) * (this.purchaseOrder.taxRate / 100)) : 0;
    return lineItemTaxes + shippingTaxes;
  }

  public get total() {
    return this.subtotal + (this.shippingFees || 0) + this.taxes;
  }

  public inputPricing(item: PurchaseOrderLineItem) {
    item.materialBidId = null;
    item.unitPriceOverride = 0;
  }

  public removePricing(item: PurchaseOrderLineItem) {
    item.unitPriceOverride = null;
  }

  public ngAfterViewInit() {
    console.log(this.records);
    this.records = [...this.records];
  }

  @ViewChild('editOutsideProcessNotesDialogTemplate', { static: true }) editOutsideProcessNotesDialogTemplate: TemplateRef<any>
  public async editOutsideProcess(item: PurchaseOrderLineItem) {
    const result = await this.dialog.open<any, any, OutsideProcessDescription | null>(this.editOutsideProcessNotesDialogTemplate, {
      data: {
        process: JSON.parse(JSON.stringify(item.outsideProcessDescription))
      },
      disableClose: true,
      minWidth: '45vw'
    }).afterClosed().toPromise();
    if (result === null) return;
    item.outsideProcessDescription = result;
    // @ts-ignore
    item.name = getOutsideProcessDescriptionString(result);
  }

  public outsideProcessValid(process: OutsideProcessDescription) {
    if (!process.product?.partNumber || !process.product?.revision) return false;
    return process.steps.every(step => !!step.stationId);
  }

  public isMisc(_, item: PurchaseOrderLineItem) {
    return item.isMisc;
  }

  public opSteps(op: OutsideProcessDescription) {
    return getOutsideProcessOpsOnly(op);
  }

  public opShortName(op: OutsideProcessDescription) {
    return getOutsideProcessDescriptionShort(op);
  }

  public opSelection = new SelectionModel<string>(true);
  public toggleOpDisplay(item: PurchaseOrderLineItem) {
    this.opSelection.toggle(item.purchaseOrderLineItemId);
  }

  public getOSPLabel(li: PurchaseOrderLineItem) {
    this.purchasingSvc.getOSPLabel(li).subscribe(doc => {
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

}
