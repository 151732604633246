import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PurchaseOrderLineItem } from '../../resources/purchaseOrder';
import { Observable } from 'rxjs';
import { PurchasingService } from '../../services/purchasing.service';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'purchasing-line-item-history',
  templateUrl: './purchasing-line-item-history.component.html',
  styleUrls: ['./purchasing-line-item-history.component.less']
})
export class PurchasingLineItemHistoryComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() itemId: string;

  constructor(private purchasingSvc: PurchasingService) { }

  public dataSource = new MatTableDataSource<PurchaseOrderLineItem>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public loading = false;
  public async getDetail() {
    this.dataSource.data = [];
    this.loading = true;
    const data = await this.purchasingSvc.getPricingDataForItem(this.itemId)
      // .pipe(map(d => d.concat(d).concat(d).concat(d)))
      .toPromise()
    ;
    this.loading = false;
    this.dataSource.data = data;
  }

  ngOnInit() {
    this.getDetail();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId) this.getDetail();
  }

  public getLineItemUnitPrice(item: PurchaseOrderLineItem) {
    if (item.isMisc) return item.unitPriceOverride ?? 0;
    if (item.unitPriceOverride !== null && item.unitPriceOverride !== undefined) return item.unitPriceOverride;
    const bid = item.materialBid;
    if (!bid) return 0;
    if (bid.totalBid) return bid.totalBid / (bid.qty || 1);
    else if (bid.perItemBid) return bid.perItemBid;
    else return 0;
  }

  public getLineItemExtPrice(item: PurchaseOrderLineItem) {
    const unitPrice = this.getLineItemUnitPrice(item);
    const qty = item.mfgrUnitsQty ? item.mfgrUnitsQty : item.quantity;
    return unitPrice * qty;
  }

  public getLineItemDateReceived(item: PurchaseOrderLineItem): Date | null {
    const tickets = item.shippingTickets?.filter(t => !!t.destinationBuildingId) ?? [];
    if (!tickets || tickets.length === 0) return null;
    // It's not received if there's still any outstanding tickets
    if (!tickets.every(t => !!t.arrivalDate)) return null;
    // Otherwise sort descending and get the latest date we got any
    else return tickets.sort((a, b) => (new Date(b.arrivalDate)).getTime() - (new Date(a.arrivalDate)).getTime()).at(0)?.arrivalDate ?? null;
  }

  public getLineItemShipDate(item: PurchaseOrderLineItem): Date | null {
    const tickets = item.shippingTickets?.filter(t => !!t.destinationBuildingId) ?? [];
    if (!tickets || tickets.length === 0) return null;
    return tickets.sort((a, b) => (new Date(b.departureDate)).getTime() - (new Date(a.departureDate)).getTime()).at(0)?.departureDate ?? null;
  }
  public readonly displayedColumns = [
    "dateOrdered",
    "vendor",
    "poNumber",
    "unitCost",
    "qty",
    "totalCost",
    "shipDate",
    "dateReceived"
  ];

}
