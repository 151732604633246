import { Component } from '@angular/core';
import { Customer } from './../../../customer/resources/customer';
import { CustomerService } from './../../../customer/services/customer.service';
import { Router } from '@angular/router';
import { NavigationService } from './../../../common/services/navigation.service';
import { UtilityService } from './../../../common/services/utility.service';
import { ReportEmbedService } from '../../services/report-embed.service';
import { ReportEmbed } from '../../resources/report-embed';
import { SearchList } from '../../../common/components/search-list/search-list';

@Component({
  selector: 'report-embed-list',
  templateUrl: './report-embed-list.component.html',
  styleUrls: ['./report-embed-list.component.less']
})
export class ReportEmbedListComponent extends SearchList<ReportEmbed> {

  constructor(navService: NavigationService, private reportEmbedService: ReportEmbedService, private router: Router) {
    super('ReportEmbedListComponent');
    navService.setPageTitle("Reports");
  }

  protected search(): void {
    this.results = null;
    this.reportEmbedService.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  public onSelect(record: ReportEmbed): void {
    this.router.navigate(['/report-embed/', record.reportEmbedId]);
  }
}
