import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { Route, RouterModule } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { } from 'rxjs';

import { AngMaterialModule } from '../angMaterial/angMaterial.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AngularDraggableModule } from 'angular2-draggable';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { WebcamModule } from 'ngx-webcam';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { CotsNavMenuComponent } from './main/components/nav-menu/nav-menu.component';
import { NavHeaderComponent } from './main/components/nav-header/nav-header.component';
import { SigninComponent } from './main/components/signin/signin.component';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { CustomerListComponent } from './customer/components/customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer/components/customer-detail/customer-detail.component';
import { EstimateListComponent } from './order/components/estimate-list/estimate-list.component';
import { EstimateScorecardComponent } from './order/components/estimate-scorecard/estimate-scorecard.component';
import { QuoteListComponent } from './order/components/quote-list/quote-list.component';
import { OrderListComponent } from './order/components/order-list/order-list.component';
import { MessagesComponent, ErrorDetailDialog } from './common/components/messages/messages.component';
import { LoadingComponent } from './common/components/loading/loading.component';
import { UserInfoComponent } from './main/components/user-info/user-info.component';
import { SearchPagerComponent } from './common/components/search-pager/search-pager.component';
import { SearchResultComponent } from './common/components/search-result/search-result.component';
import { SearchBarComponent } from './common/components/search-bar/search-bar.component';
import { RFQDoneConfirmationDialog, OrderDetailComponent, RejectReasoningDialog, StatusConfirmationDialog } from './order/components/order-detail/order-detail.component';
import { FileDisplayGridComponent } from './common/components/file-display-grid/file-display-grid.component';
import { CountrySelectComponent } from './common/components/country-select/country-select.component';
import { CertificateListComponent } from './supplier/components/certificate-list/certificate-list.component';
import { SalespersonSelectComponent } from './customer/components/salesperson-select/salesperson-select.component';
import { EmployeeSelectComponent } from './employee/components/employee-select/employee-select.component';
import { CustomerContactSelectComponent } from './order/components/customer-contact-select/customer-contact-select.component';
import { ProductTreeComponent } from './order/components/product-tree/product-tree.component';
import { PhoneNumberComponent } from './common/components/phone-number/phone-number.component';
import { DatePickerComponent } from './common/components/date-picker/date-picker.component';
import { CustomerSelectComponent } from './order/components/customer-select/customer-select.component';
import { ProductDetailComponent } from './order/components/product-detail/product-detail.component';
import { ConfirmDialogComponent } from './common/components/confirm-dialog/confirm-dialog.component';
import { FloorMainComponent } from './floor/components/floor-main/floor-main.component';
import { VendorChooserComponent } from './customer/components/vendor-chooser/vendor-chooser.component';
import { QuoteDetailComponent } from './purchasing/components/quote-detail/quote-detail.component';
import { VendorContactScreenComponent } from './supplier/components/vendor-contact-screen/vendor-contact-screen.component';
import { VendorSelectComponent } from './purchasing/components/vendor-select/vendor-select.component';
import { SupplierDetailComponent } from './purchasing/components/supplier-detail/supplier-detail.component';
import { ProductDialogComponent } from './order/components/product-dialog/product-dialog.component';
import { VendorCertDetailComponent } from './supplier/components/vendor-cert-detail/vendor-cert-detail.component';
import { SupplierListComponent } from './purchasing/components/supplier-list/supplier-list.component';
import { UserDetailComponent } from './main/components/user-detail/user-detail.component';
import { CallbackPipe } from './common/transforms/callback-pipe/callback-pipe.transform';
import { NoCommaPipe } from './common/transforms/no-comma-pipe/no-comma-pipe.transform';
import { ChartComponent } from './common/components/chart/chart.component';
import { StaticEditDirective } from './common/directives/static-edit.directive';
import { DragDropDirective } from './common/directives/drag-drop.directive';
import { NotNullValidatorDirective } from './common/directives/validation/not-null.validator.directive';
import { MinValidatorDirective } from './common/directives/validation/min.validator.directive';
import { MaxValidatorDirective } from './common/directives/validation/max.validator.directive';
import { ProductFinderComponent } from './order/components/product-finder/product-finder.component';
import { PurchasedItemListComponent } from './order/components/purchased-item-list/purchased-item-list.component';
import { WorkflowListComponent } from './order/components/workflow-list/workflow-list.component';
import { StationListComponent } from './order/components/station-list/station-list.component';
import { MultipleQuantityInputComponent } from './common/components/multiple-quantity-input/multiple-quantity-input.component';
import { MaterialSelectComponent, HighlightPipe } from './order/components/material-select/material-select.component';
import { UploadFileComponent } from './common/components/upload-file/upload-file.component';
import { WebcamSnapshotComponent } from './common/components/webcam/webcam-snapshot.component';
import { AlertDialogComponent } from './common/components/alert-dialog/alert-dialog.component';
import { VendorListComponent } from './supplier/components/vendor-list/vendor-list.component';
import { VendorDetailComponent } from './supplier/components/vendor-detail/vendor-detail.component';
import { AlertListComponent } from './floor/components/alert-list/alert-list.component';
import { WeekStatusListComponent } from './floor/components/week-status-list/week-status-list.component';
import { WidgetAreaComponent } from './widget/widget-area/widget-area.component';
import { WidgetItemComponent } from './widget/widget-item/widget-item.component';
import { DepartmentDetailComponent } from './floor/components/department-detail/department-detail.component';
import { BuildingDetailComponent } from './floor/components/building-detail/building-detail.component';
import { FloorLayoutBlockComponent } from './floor/components/floor-layout-block/floor-layout-block.component';
import { FilteredAlertListComponent } from './floor/components/filtered-alert-list/filtered-alert-list.component';
import { MachineDetailComponent } from './floor/components/machine-detail/machine-detail.component';
import { AdminPortalComponent } from './admin/components/admin-portal/admin-portal.component';
import { StationEditorComponent } from './admin/components/station-editor/station-editor.component';
import { MaterailEditorComponent } from './admin/components/material-editor/material-editor.component';
import { MaterialListComponent } from './admin/components/material-list/material-list.component';
import { MaterialDimensionTypeUnitSelectComponent } from './admin/components/material-dimension-type-unit-select/material-dimension-type-unit-select.component';
import { InventoryListComponent } from './inventory/components/inventory-list/inventory-list.component';
import { InventoryDetailComponent } from './inventory/components/inventory-detail/inventory-detail.component';
import { InventoryLocationDetailComponent } from './inventory/components/inventory-location-detail/inventory-location-detail.component';
import { InventoryLocationSelectionComponent } from './inventory/components/inventory-location-selection/inventory-location-selection.component';
import { MaterialGroupSelectComponent } from './admin/components/material-group-select/material-group-select.component';
import { MaterialAlloySelectComponent } from './admin/components/material-alloy-select/material-alloy-select.component';
import { DepartmentEditorComponent } from './admin/components/department-editor/department-editor.component';
import { DepartmentListComponent } from './admin/components/department-list/department-list.component';
import { MachineDataFormComponent } from './floor/components/machine-data-form/machine-data-form.component';
import { ResizableDirective } from './common/directives/resizable.directive';
import { ProductPricingTreeComponent } from './order/components/product-pricing-tree/product-pricing-tree.component';
import { EmployeeListComponent } from './employee/components/employee-list/employee-list.component';
import { PermissionSelectionComponent } from './employee/components/permission-selection/permission-selection.component';
import { PlanningListComponent } from './planning/components/planning-list/planning-list.component';
import { PlanningDetailComponent } from './planning/components/planning-detail/planning-detail.component';
import { DepartmentDropdownComponent } from './planning/components/department-dropdown/department-dropdown.component';
import { QuoteInfoComponent } from './order/components/quote-info/quote-info.component';
import { OrderInfoDetailComponent } from './order/components/order-info-detail/order-info-detail.component';
import { OperatorViewComponent } from './floor/components/operator-view/operator-view.component';
import { MachineOperatorControlsComponent } from './floor/components/machine-operator-controls/machine-operator-controls.component';
import { UnassignedJobsListComponent } from './floor/components/unassigned-jobs-list/unassigned-jobs-list.component';
import { FilteredVendorListComponent, VendorSearch } from './purchasing/components/filtered-vendor-list/filtered-vendor-list.component';
import { MachineAssignmentDetailComponent } from './floor/components/machine-assignment-detail/machine-assignment-detail.component';
import { OperatorDetailComponent } from './floor/components/operator-detail/operator-detail.component';
import { MachineAssignmentListComponent } from './floor/components/machine-assignment-list/machine-assignment-list.component';
import { MachineTodayScheduleComponent } from './floor/charts/machine-today-schedule/machine-today-schedule.component';
import { OperatorTodayScheduleComponent } from './floor/charts/operator-today-schedule/operator-today-schedule.component';
import { DepartmentTodayScheduleComponent } from './floor/charts/department-today-schedule/department-today-schedule.component';
import { DepartmentFutureScheduleComponent } from './floor/charts/department-future-schedule/department-future-schedule.component';
import { MachineFutureScheduleComponent } from './floor/charts/machine-future-schedule/machine-future-schedule.component';
import { ShippingOverviewComponent } from './shipping/components/shipping-overview/shipping-overview.component';
import { FloorUserSetupComponent } from './employee/components/floor-user-setup/floor-user-setup.component';
import { FilteredUserListComponent } from './employee/components/filtered-user-list/filtered-user-list.component';
import { RatingComponent } from './common/components/rating/rating.component';
import { ProductBuildStatusComponent } from './order/components/product-build-status/product-build-status.component';
import { ProductWaterfallComponent } from './order/charts/product-waterfall/product-waterfall.component';
import { WaitingOrdersWidgetComponent, AtRiskOrdersWidgetComponent, OverdueOrdersWidgetComponent } from './widget/services/widgets/orderStatusInfo/components';
import { FloorAlertsWidgetComponent } from './widget/services/widgets/floorAlerts/components';
import { CompanyMessagesComponent } from './widget/services/widgets/companyMessages/components';
import { PriceHistoryChartComponent } from './purchasing/charts/price-history-chart/price-history-chart.component';
import { PaintEditorComponent } from './admin/components/paint-editor/paint-editor.component';
import { PaintListComponent } from './admin/components/paint-list/paint-list.component';
import { PaintSelectComponent } from './order/components/paint-select/paint-select.component';
import { PurchasingQueueComponent } from './purchasing/components/purchasing-queue/purchasing-queue.component';
import { PurchaseOrderComponent } from './purchasing/components/purchase-order/purchase-order.component';
import { ShippingTicketDetailComponent } from './shipping/components/shipping-ticket-detail/shipping-ticket-detail.component';
import { ShippingCarrierSelectComponent } from './shipping/components/shipping-carrier-select/shipping-carrier-select.component';
import { ShippingCarrierDetailComponent } from './shipping/components/shipping-carrier-detail/shipping-carrier-detail.component';
import { ShippingPickLocationEditorComponent } from './shipping/components/shipping-pick-location-editor/shipping-pick-location-editor.component';
import { ShippingSplitTicketComponent } from './shipping/components/shipping-split-ticket/shipping-split-ticket.component';
import { ShippingCombineShipmentsComponent } from './shipping/components/shipping-combine-shipments/shipping-combine-shipments.component';
import { ShippingSplitViewComponent } from './shipping/components/shipping-split-view/shipping-split-view.component';
import { MaterialCertificationsComponent } from './admin/components/material-certifications/material-certifications.component';
import { MaterialSpecificationSelectComponent } from './admin/components/material-specification-select/material-specification-select.component';
import { MaterialTypeSelectComponent } from './admin/components/material-type-select/material-type-select.component';
import { MaterialHardnessSelectComponent } from './admin/components/material-hardness-select/material-hardness-select.component';
import { ShippingClassFilterComponent } from './shipping/components/shipping-class-filter/shipping-class-filter.component';
import { BidPurchaseOrderAssignment } from './purchasing/components/bid-po-assignment/bid-po-assignment.component';
import { PoLineItemsComponent } from './purchasing/components/po-line-items/po-line-items.component';
import { MaterailTypeEditorComponent } from './admin/components/material-type-editor/material-type-editor.component';
import { MaterialTypeListComponent } from './admin/components/material-type-list/material-type-list.component';
import { MaterialSpecListComponent } from './admin/components/material-spec-list/material-spec-list.component';
import { MaterailSpecEditorComponent } from './admin/components/material-spec-editor/material-spec-editor.component';
import { MaterialGroupListComponent } from './admin/components/material-group-list/material-group-list.component';
import { MaterailGroupEditorComponent } from './admin/components/material-group-editor/material-group-editor.component';
import { MaterailHardnessEditorComponent } from './admin/components/material-hardness-editor/material-hardness-editor.component';
import { MaterialHardnessListComponent } from './admin/components/material-hardness-list/material-hardness-list.component';
import { MaterialAlloyEditorComponent } from './admin/components/material-alloy-editor/material-alloy-editor.component';
import { MaterialAlloyListComponent } from './admin/components/material-alloy-list/material-alloy-list.component';
import { FindExistingItemComponent } from './inventory/components/find-existing-item/find-existing-item.component';
import { InventoryItemSelectComponent } from './inventory/components/inventory-item-select/inventory-item-select.component';
import { GenericSelectComponent } from './common/components/generic-select/generic-select.component';
import { GlobalRequisitionsComponent } from './purchasing/components/global-reqs/global-reqs.component';
import { BulkQuotesComponent, BulkQuoteFinishingDialog } from './purchasing/components/bulk-quotes/bulk-quotes.component';
import { BulkQuoteListComponent } from './purchasing/components/bulk-quote-list/bulk-quote-list.component';
import { VendorDropSelectComponent } from './supplier/vendor-drop-select/vendor-drop-select.component';
import { QuantityTableComponent } from './order/components/quantity-table/quantity-table.component';
import { AddressSelectorComponent } from './purchasing/components/address-selector/address-selector.component';
import { EstimateApprovalComponent } from './order/components/estimate-approval/estimate-approval.component';
import { EstimateApprovalCostChartComponent } from './order/components/estimate-approval/estimate-approval-cost-chart/estimate-approval-cost-chart.component';
import { FilteredMultiSelectComponent } from './common/filtered-multi-select/filtered-multi-select.component';
import { VolumeFormulaComponent } from './common/components/volume-formula/volume-formula.component';
import { ExistingQuoteSummaryComponent } from './purchasing/components/existing-quote-summary/existing-quote-summary.component';
import { PurchasedItemSelectComponent } from './order/components/purchased-item-select/purchased-item-select.component';
import { PurchasedItemEditorComponent } from './admin/components/purchased-item-editor/purchased-item-editor.component';
import { NewLineItemSelectComponent } from './purchasing/components/new-line-item-select/new-line-item-select.component';
import { ItemTypeChipComponent } from './inventory/components/item-type-chip/item-type-chip.component';
import { QcClauseEditorComponent } from './admin/components/qc-clause-editor/qc-clause-editor.component';
import { UnassignedPosListComponent } from './purchasing/components/unassigned-pos-list/unassigned-pos-list.component';
import { OverrideWorkflowPrompt, UnassignedPoDetailComponent } from './purchasing/components/unassigned-po-detail/unassigned-po-detail.component';
import { RequisitionListComponent } from './purchasing/components/requisition-list/requisition-list.component';
import { RequisitionInfoComponent, StationSelectComponent } from './purchasing/components/requisition-info/requisition-info.component';
import { DeliveryTimesComponent } from './order/components/delivery-times/delivery-times.component';
import { NotificationPreferredChannelComponent } from './notification/components/notification-preferred-channel/notification-preferred-channel.component';
import { RuleBuilderListComponent } from './rule-builder/components/rule-builder-list/rule-builder-list.component';
import { RuleBuilderDetailComponent } from './rule-builder/components/rule-builder-detail/rule-builder-detail.component';
import { MaterialSimpleSearchComponent } from './order/components/material-simple-search/material-simple-search.component';
import { BuildingEditorComponent } from './admin/components/building-editor/building-editor.component';
import { ShippingWrapperComponent } from './shipping/components/shipping-wrapper/shipping-wrapper.component';
import { BuildingSelectComponent, PoAddressSelectorComponent } from './purchasing/components/po-address-selector/po-address-selector.component';
import { ShippingQueueComponent, ShippingQueueListComponent } from './shipping/components/shipping-queue/shipping-queue.component';
import { ShippingCheckInComponent } from './shipping/components/shipping-check-in/shipping-check-in.component';
import { InventoryTreePickerComponent } from './shipping/components/inventory-tree-picker/inventory-tree-picker.component';
import { InventoryTreeSelectComponent } from './inventory/components/inventory-tree-select/inventory-tree-select.component';
import { ShippingCheckOutComponent } from './shipping/components/shipping-check-out/shipping-check-out.component';
import { QualityTicketListComponent } from './quality/components/quality-ticket-list/quality-ticket-list.component';
import { EmployeeShiftAssignmentComponent } from './employee/components/employee-shift-assignment/employee-shift-assignment.component';
import { StaffUtilizationComponent } from './employee/components/staff-utilization/staff-utilization.component';
import { MbscModule } from '@mobiscroll/angular';
import { ContractReviewComponent } from './quality/components/contract-review/contract-review.component';
import { TicketTypeFilterComponent } from './quality/components/quality-ticket-list/ticket-type-filter.component';
import { CertExpirationComponent } from './quality/components/cert-expiration/cert-expiration.component';
import { QualityAssignComponent } from './quality/components/quality-assign/quality-assign.component';
import { ReportOptionsDialog } from './order/components/rfq-list/report-options-dialog.component';
import { QualityShippingReceiptComponent } from './quality/components/quality-shipping-receipt/quality-shipping-receipt.component';
import { EstimateApprovalLeadTimeBreakdownComponent } from './order/components/estimate-approval/estimate-approval-lead-time-breakdown/estimate-approval-lead-time-breakdown.component';
import { SimilarPartOverlayComponent } from './order/components/similar-part-overlay/similar-part-overlay.component';
import { RmaSalesListComponent } from './rma/components/rma-sales-list/rma-sales-list.component';
import { PartNumberSearch, RmaSalesDetailComponent } from './rma/components/rma-sales-detail/rma-sales-detail.component';
import { QualityRmaDetailComponent } from './quality/components/quality-rma-detail/quality-rma-detail.component';
import { OrderRmaDetailComponent } from './order/components/order-rma-detail/order-rma-detail.component';
import { RmaEstimateListComponent } from './order/components/rma-estimate-list/rma-estimate-list.component';
import { WorkflowProgressComponent } from './floor/components/workflow-progress/workflow-progress.component';
import { BuildingDropdownComponent } from './planning/components/building-dropdown/building-dropdown.component';
import { ToolCribTicketListComponent } from './floor/components/tool-crib-ticket-list/tool-crib-ticket-list.component';
import { ToolCribFulfillComponent } from './floor/components/tool-crib-fulfill/tool-crib-fulfill.component';
import { WorkflowStepItemsComponent } from './planning/components/workflow-step-items/workflow-step-items.component';
import { EstimatePricingComponent } from './order/components/estimate-pricing/estimate-pricing.component';
import { RfqListComponent } from './order/components/rfq-list/rfq-list.component';
import { QualityRmaApprovalComponent } from './quality/components/quality-rma-approval/quality-rma-approval.component';
import { LeadTimeVerificationComponent } from './order/components/lead-time-verification/lead-time-verification.component';
import { PoAcceptanceComponent } from './order/components/po-acceptance/po-acceptance.component';
import { QuoteItemsTableComponent } from './order/components/quote-items-table/quote-items-table.component';
import { QuoteAcceptedPosComponent } from './order/components/quote-accepted-pos/quote-accepted-pos.component';
import { PoLineItemBuilderComponent } from './order/components/po-line-item-builder/po-line-item-builder.component';
import { FabSpeedDialComponent } from './common/components/fab-speed-dial/fab-speed-dial.component';
import { NewBatchRfqComponent } from './order/components/new-batch-rfq/new-batch-rfq.component';
import { CompanySearchComponent } from './common/components/company-search/company-search.component';
import { PreplanningDetailComponent } from './planning/components/preplanning-detail/preplanning-detail.component';
import { ProgrammingListComponent } from './planning/components/programming-list/programming-list.component';
import { ProgrammingDetailComponent } from './planning/components/programming-detail/programming-detail.component';
import { TravelerListComponent } from './floor/components/traveler-list/traveler-list.component';
import { InspectionQueueComponent } from './quality/components/inspection-queue/inspection-queue.component';
import { InspectionTicketComponent } from './quality/components/inspection-ticket/inspection-ticket.component';
import { FailedInspectionReviewComponent } from './floor/components/failed-inspection-review/failed-inspection-review.component';
import { WorkflowStepEditComponent } from './common/components/workflow-step-edit/workflow-step-edit.component';
import { SpecificationsInputComponent } from './order/components/specifications-input/specifications-input.component';
import { MessageDialogComponent } from './common/components/message-dialog/message-dialog.component';
import { ReportEmbedListComponent } from './report-embed/components/report-embed-list/report-embed-list.component';
import { ReportEmbedDetailComponent } from './report-embed/components/report-embed-detail/report-embed-detail.component';
import { ProductHistoryComponent } from './order/components/product-history/product-history.component';
import { PartReportOptionsDialog } from './order/components/quote-list/part-report-options-dialog.component';
import { ProductHierarchySortComponent } from './planning/components/product-hierarchy-sort/product-hierarchy-sort.component';
import { PlanningWoDetailComponent, WoPlanningConfirmationDialog } from './planning/components/planning-wo-detail/planning-wo-detail.component';
import { EmployeeSearchComponent } from './common/components/employee-search/employee-search.component';
import { CopyPartDialogComponent } from './order/components/copy-part-dialog/copy-part-dialog.component';
import { WorkOrderSearchComponent } from './common/components/work-order-search/work-order-search.component';
import { StationSearchComponent } from './common/components/station-search/station-search.component';
import { LineItemsPreviewTableComponent } from './purchasing/components/purchase-order/line-items-preview-table/line-items-preview-table.component';
import { VendorContactSearchComponent } from './common/components/vendor-contact-search/vendor-contact-search.component';
import { PurchasedItemPartNumberSelectComponent } from './purchasing/components/purchased-item-part-number-select/purchased-item-part-number-select.component';
import { PurchasingHomeComponent } from './purchasing/components/purchasing-home/purchasing-home.component';
import { AccountsReceivableQueueComponent } from './accounting/accounts-receivable-queue/accounts-receivable-queue.component';
import { AccountReceivableDetailComponent } from './accounting/account-receivable-detail/account-receivable-detail.component';
import { SearchListNewComponent } from './common/components/search-list-new/search-list-new.component';
import { PrerequisiteListComponent } from './purchasing/components/prerequisite-list/prerequisite-list.component';
import { GlobalConstantsEditorComponent } from './admin/components/global-constants-editor/global-constants-editor.component';
import { RequireSelectionDirective } from './common/directives/require-selection.directive';
import { PlanningStepNotesComponent } from './planning/components/planning-step-notes/planning-step-notes.component';
import { OrderDetailNewComponent } from './order/components/order-detail-new/order-detail-new.component';
import { OrderDetailFormComponent } from './order/components/order-detail-new/order-detail-form/order-detail-form.component';
import { ProductDetailNewComponent } from './order/components/order-detail-new/product-detail-new/product-detail-new.component';
import { ProductDetailFormComponent } from './order/components/order-detail-new/product-detail-new/product-detail-form/product-detail-form.component';
import { ProductQuantityInputComponent } from './order/components/order-detail-new/product-detail-new/product-detail-form/product-quantity-input/product-quantity-input.component';
import { MatIconRegistry } from '@angular/material/icon';
import { ProductMaterialComponent } from './order/components/order-detail-new/product-detail-new/product-material/product-material.component';
import { ProductQuotingComponent } from './order/components/order-detail-new/product-detail-new/product-quoting/product-quoting.component';
import { ProductQuotingNewQuoteComponent } from './order/components/order-detail-new/product-detail-new/product-quoting/product-quoting-new-quote/product-quoting-new-quote.component';
import { OpenOrderReportComponent } from './common/components/open-order-report/open-order-report.component';
import { OorContractReviewComponent } from './common/components/open-order-report/oor-contract-review/oor-contract-review.component';
import { OorPurchasingComponent } from './common/components/open-order-report/oor-purchasing/oor-purchasing.component';
import { OorPreplanningComponent } from './common/components/open-order-report/oor-preplanning/oor-preplanning.component';
import { OorPlanningComponent } from './common/components/open-order-report/oor-planning/oor-planning.component';
import { OorProductionComponent } from './common/components/open-order-report/oor-production/oor-production.component';
import { OorInspectionComponent } from './common/components/open-order-report/oor-inspection/oor-inspection.component';
import { OorShippingComponent } from './common/components/open-order-report/oor-shipping/oor-shipping.component';
import { OorGanttComponent } from './common/components/open-order-report/oor-gantt/oor-gantt.component';
import { OorGanttBarComponent } from './common/components/open-order-report/oor-gantt/oor-gantt-bar/oor-gantt-bar.component';
import { ProductWorkflowComponent } from './order/components/order-detail-new/product-detail-new/product-workflow/product-workflow.component';
import { ProductWorkflowStationListComponent } from './order/components/order-detail-new/product-detail-new/product-workflow/product-workflow-station-list/product-workflow-station-list.component';
import { ProductWorkflowWorkflowListComponent } from './order/components/order-detail-new/product-detail-new/product-workflow/product-workflow-workflow-list/product-workflow-workflow-list.component';
import { ProductWorkflowStepFormComponent } from './order/components/order-detail-new/product-detail-new/product-workflow/product-workflow-step-form/product-workflow-step-form.component';
import { ProductPurchasedItemsComponent, PurchasedItemSearch } from './order/components/order-detail-new/product-detail-new/product-purchased-items/product-purchased-items.component';
import { GenericSearchComponent } from './common/components/generic-search/generic-search.component';
import { OrderDetailDocumentsComponent } from './order/components/order-detail-new/order-detail-documents/order-detail-documents.component';
import { OrderDetailProductsComponent } from './order/components/order-detail-new/order-detail-products/order-detail-products.component';
import { OrderDetailProductsUploadComponent } from './order/components/order-detail-new/order-detail-products/order-detail-products-upload/order-detail-products-upload.component';
import { OrderDetailQuantityTableComponent } from './order/components/order-detail-new/order-detail-quantity-table/order-detail-quantity-table.component';
import { OrderDetailEstimatingScreenComponent } from './order/components/order-detail-new/order-detail-estimating-screen/order-detail-estimating-screen.component';
import { OrderDetailLeadTimeBreakdownComponent } from './order/components/order-detail-new/order-detail-lead-time-breakdown/order-detail-lead-time-breakdown.component';
import { LetDirective } from './common/directives/ng-let.directive';
import { CustomerSearch } from './common/components/search/customer-search/customer-search.component';
import { ClientsideSearchComponent } from './common/components/search/clientside-search/clientside-search.component';
import { MatAutocompleteNoDisplayDirective } from './common/directives/mat-autocomplete-no-display';
import { OrdersegmentListNewComponent } from './order/components/ordersegment-list-new/ordersegment-list-new.component';
import { OrderDetailRedirectorComponent } from './order/components/order-detail-redirector/order-detail-redirector.component';
import { PoOutsideProcessEditorComponent } from './purchasing/components/po-outside-process-editor/po-outside-process-editor.component';
import { OutsideProcessEditorComponent } from './admin/components/outside-process-editor/outside-process-editor.component';
import { PoOutsideProcessSelectComponent } from './purchasing/components/po-outside-process-select/po-outside-process-select.component';
import { RfqApprovalListComponent } from './order/components/rfq-approval-list/rfq-approval-list.component';
import { SalesNotesComponent } from './order/components/sales-notes/sales-notes.component';
import { SalesNoteCardComponent } from './order/components/sales-notes/sales-note-card/sales-note-card.component';
import { SalesNoteCreatorComponent } from './order/components/sales-notes/sales-note-creator/sales-note-creator.component';
import { OpSpecificationEditorComponent } from './admin/components/op-specification-editor/op-specification-editor.component';
import { PurchasingLineItemHistoryComponent } from './purchasing/components/purchasing-line-item-history/purchasing-line-item-history.component';
import { OrderDetailPricingBreakdownComponent } from './order/components/order-detail-new/order-detail-pricing-breakdown/order-detail-pricing-breakdown.component';
import { EstimatingProgressStatusComponent } from './order/components/order-detail-new/estimating-progress-status/estimating-progress-status.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ShortNumberPipe } from './common/transforms/short-number-pipe/short-number-pipe.transform';
import { NewMicroticketComponent } from './microtickets/components/new-microticket/new-microticket.component';
import { AutocompleteTypeComponent } from './common/formly-autocomplete-type/formly-autocomplete-type.component';
import { OrderDetailMicroticketScreenComponent } from './order/components/order-detail-new/order-detail-microticket-screen/order-detail-microticket-screen.component';
import { MicroticketListComponent } from './microtickets/components/microticket-list/microticket-list.component';
import { MicroticketCardComponent } from './microtickets/components/microticket-card/microticket-card.component';
import { MicroticketGlobalQueueComponent } from './microtickets/components/microticket-global-queue/microticket-global-queue.component';
import { MicroticketSubitemViewerComponent } from './microtickets/components/microticket-subitem-viewer/microticket-subitem-viewer.component';
import { UpcomingWorkListComponent } from './floor/components/upcoming-work-list/upcoming-work-list.component';
import { UpcomingWorkChartComponent } from './floor/components/upcoming-work-chart/upcoming-work-chart.component';
import { FormlyChipListTypeComponent } from './common/components/formly-chip-list-type/formly-chip-list-type.component';
import { PlanningTicketInfoComponent } from './planning/components/planning-ticket-info/planning-ticket-info.component';
import { PlanningWaterfallGraphComponent } from './planning/components/planning-waterfall-graph/planning-waterfall-graph.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProductEstimatingReviewComponent } from './order/components/order-detail-new/product-detail-new/product-estimating-review/product-estimating-review.component';
import { WorkflowGraphComponent } from './order/components/workflow-graph/workflow-graph.component';
import { ProductQuoteDetailNewComponent } from './order/components/order-detail-new/product-detail-new/product-quote-detail-new/product-quote-detail-new.component';
import { ItemQuoteHistoryComponent } from './order/components/common/item-quote-history/item-quote-history.component';

//import widgets from './widget/services/widgets'
//const widgetComponents = widgets.map(w => w.component as any);

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AngMaterialModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    AngularDraggableModule,
    ColorPickerModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    WebcamModule,
    IvyCarouselModule,
    MbscModule,
    HttpClientJsonpModule,
    MatSortModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'autocomplete',
          component: AutocompleteTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'chiplist',
          component: FormlyChipListTypeComponent,
          wrappers: ['form-field'],
        },
      ],
      validationMessages: [{ name: 'required', message: 'This field is required' }],
    }),
    FormlyMaterialModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    HighchartsChartModule,
  ],
  exports: [
    CotsNavMenuComponent,
    NavHeaderComponent,
    SigninComponent,
    RatingComponent,
    DashboardComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    EstimateListComponent,
    EstimateScorecardComponent,
    QuoteListComponent,
    OrderListComponent,
    MessagesComponent,
    LoadingComponent,
    UserInfoComponent,
    SearchPagerComponent,
    SearchResultComponent,
    SearchBarComponent,
    OrderDetailComponent,
    ErrorDetailDialog,
    FileDisplayGridComponent,
    StationSelectComponent,
    BuildingSelectComponent,
    CountrySelectComponent,
    CertificateListComponent,
    SalespersonSelectComponent,
    EmployeeSelectComponent,
    CustomerContactSelectComponent,
    ProductTreeComponent,
    ProductPricingTreeComponent,
    PhoneNumberComponent,
    DatePickerComponent,
    CustomerSelectComponent,
    ProductDetailComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    UploadFileComponent,
    WebcamSnapshotComponent,
    MaterialSelectComponent,
    MultipleQuantityInputComponent,
    StationListComponent,
    WorkflowListComponent,
    PurchasedItemListComponent,
    ProductFinderComponent,
    DragDropDirective,
    StaticEditDirective,
    NotNullValidatorDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    RequireSelectionDirective,
    MatAutocompleteNoDisplayDirective,
    LetDirective,
    ChartComponent,
    NoCommaPipe,
    CallbackPipe,
    HighlightPipe,
    ShortNumberPipe,
    UserDetailComponent,
    SupplierListComponent,
    VendorListComponent,
    VendorDetailComponent,
    VendorCertDetailComponent,
    ProductDialogComponent,
    StatusConfirmationDialog,
    RFQDoneConfirmationDialog,
    RejectReasoningDialog,
    SupplierDetailComponent,
    VendorSelectComponent,
    VendorContactScreenComponent,
    QuoteDetailComponent,
    VendorChooserComponent,
    FloorMainComponent,
    AlertListComponent,
    FilteredAlertListComponent,
    WeekStatusListComponent,
    WidgetAreaComponent,
    WidgetItemComponent,
    DepartmentDetailComponent,
    FloorLayoutBlockComponent,
    BuildingDetailComponent,
    MachineDetailComponent,
    AdminPortalComponent,
    StationEditorComponent,
    MaterailEditorComponent,
    MaterialListComponent,
    MaterialDimensionTypeUnitSelectComponent,
    MaterialGroupSelectComponent,
    MaterialAlloySelectComponent,
    MachineDataFormComponent,
    ResizableDirective,
    DepartmentEditorComponent,
    DepartmentListComponent,
    EmployeeListComponent,
    PermissionSelectionComponent,
    PlanningListComponent,
    PlanningDetailComponent,
    DepartmentDropdownComponent,
    InventoryListComponent,
    InventoryDetailComponent,
    InventoryLocationDetailComponent,
    InventoryLocationSelectionComponent,
    QuoteInfoComponent,
    OrderInfoDetailComponent,
    OperatorViewComponent,
    MachineOperatorControlsComponent,
    UnassignedJobsListComponent,
    FilteredVendorListComponent,
    MachineAssignmentDetailComponent,
    OperatorDetailComponent,
    MachineAssignmentListComponent,
    MachineTodayScheduleComponent,
    OperatorTodayScheduleComponent,
    DepartmentTodayScheduleComponent,
    DepartmentFutureScheduleComponent,
    MachineFutureScheduleComponent,
    ShippingOverviewComponent,
    ShippingTicketDetailComponent,
    FloorUserSetupComponent,
    FilteredUserListComponent,
    ProductBuildStatusComponent,
    ProductWaterfallComponent,
    WaitingOrdersWidgetComponent,
    OverdueOrdersWidgetComponent,
    AtRiskOrdersWidgetComponent,
    FloorAlertsWidgetComponent,
    CompanyMessagesComponent,
    PriceHistoryChartComponent,
    PaintEditorComponent,
    PaintListComponent,
    PaintSelectComponent,
    ShippingCarrierSelectComponent,
    ShippingCarrierDetailComponent,
    ShippingPickLocationEditorComponent,
    ShippingSplitTicketComponent,
    ShippingCombineShipmentsComponent,
    ShippingSplitViewComponent,
    MaterialCertificationsComponent,
    MaterialSpecificationSelectComponent,
    MaterialTypeSelectComponent,
    MaterialHardnessSelectComponent,
    ShippingClassFilterComponent,
    BidPurchaseOrderAssignment,
    PoLineItemsComponent,
    MaterailTypeEditorComponent,
    MaterialTypeListComponent,
    MaterailSpecEditorComponent,
    MaterialSpecListComponent,
    MaterialGroupListComponent,
    MaterailGroupEditorComponent,
    MaterailHardnessEditorComponent,
    MaterialHardnessListComponent,
    MaterialAlloyEditorComponent,
    MaterialAlloyListComponent,
    FindExistingItemComponent,
    InventoryItemSelectComponent,
    GlobalRequisitionsComponent,
    BulkQuotesComponent,
    BulkQuoteListComponent,
    VendorDropSelectComponent,
    BulkQuoteFinishingDialog,
    FilteredMultiSelectComponent,
    ExistingQuoteSummaryComponent,
    OverrideWorkflowPrompt,
    NotificationPreferredChannelComponent,
    RuleBuilderListComponent,
    RuleBuilderDetailComponent,
    RuleBuilderListComponent,
    RuleBuilderDetailComponent,
    EmployeeShiftAssignmentComponent,
    StaffUtilizationComponent,
    ContractReviewComponent,
    TicketTypeFilterComponent,
    ReportOptionsDialog,
    PartReportOptionsDialog,
    SimilarPartOverlayComponent,
    PoLineItemBuilderComponent,
    WoPlanningConfirmationDialog,
    ProgrammingListComponent,
    ProgrammingDetailComponent,
    SpecificationsInputComponent,
    ReportEmbedListComponent,
    ReportEmbedDetailComponent,
    PrerequisiteListComponent,
    GlobalConstantsEditorComponent,
    PlanningStepNotesComponent,
  ],
  declarations: [
    CotsNavMenuComponent,
    NavHeaderComponent,
    SigninComponent,
    RatingComponent,
    DashboardComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    EstimateListComponent,
    EstimateScorecardComponent,
    QuoteListComponent,
    OrderListComponent,
    MessagesComponent,
    LoadingComponent,
    UserInfoComponent,
    SearchPagerComponent,
    SearchResultComponent,
    SearchBarComponent,
    OrderDetailComponent,
    ErrorDetailDialog,
    FileDisplayGridComponent,
    StationSelectComponent,
    BuildingSelectComponent,
    CountrySelectComponent,
    CertificateListComponent,
    SalespersonSelectComponent,
    EmployeeSelectComponent,
    CustomerContactSelectComponent,
    ProductTreeComponent,
    ProductPricingTreeComponent,
    PhoneNumberComponent,
    DatePickerComponent,
    CustomerSelectComponent,
    ProductDetailComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    UploadFileComponent,
    WebcamSnapshotComponent,
    MaterialSelectComponent,
    MultipleQuantityInputComponent,
    StationListComponent,
    WorkflowListComponent,
    PurchasedItemListComponent,
    ProductFinderComponent,
    DragDropDirective,
    StaticEditDirective,
    NotNullValidatorDirective,
    MinValidatorDirective,
    MaxValidatorDirective,
    RequireSelectionDirective,
    MatAutocompleteNoDisplayDirective,
    LetDirective,
    ChartComponent,
    NoCommaPipe,
    CallbackPipe,
    HighlightPipe,
    ShortNumberPipe,
    UserDetailComponent,
    SupplierListComponent,
    VendorListComponent,
    VendorDetailComponent,
    VendorCertDetailComponent,
    ProductDialogComponent,
    StatusConfirmationDialog,
    RFQDoneConfirmationDialog,
    RejectReasoningDialog,
    SupplierDetailComponent,
    VendorSelectComponent,
    VendorContactScreenComponent,
    QuoteDetailComponent,
    VendorChooserComponent,
    FloorMainComponent,
    AlertListComponent,
    FilteredAlertListComponent,
    WeekStatusListComponent,
    WidgetAreaComponent,
    WidgetItemComponent,
    DepartmentDetailComponent,
    FloorLayoutBlockComponent,
    BuildingDetailComponent,
    MachineDetailComponent,
    AdminPortalComponent,
    StationEditorComponent,
    MaterailEditorComponent,
    MaterialListComponent,
    MaterialDimensionTypeUnitSelectComponent,
    MaterialGroupSelectComponent,
    MaterialAlloySelectComponent,
    MachineDataFormComponent,
    ResizableDirective,
    DepartmentEditorComponent,
    DepartmentListComponent,
    EmployeeListComponent,
    PermissionSelectionComponent,
    PlanningListComponent,
    PlanningDetailComponent,
    DepartmentDropdownComponent,
    InventoryListComponent,
    InventoryDetailComponent,
    InventoryLocationDetailComponent,
    InventoryLocationSelectionComponent,
    QuoteInfoComponent,
    OrderInfoDetailComponent,
    OperatorViewComponent,
    MachineOperatorControlsComponent,
    UnassignedJobsListComponent,
    FilteredVendorListComponent,
    MachineAssignmentDetailComponent,
    OperatorDetailComponent,
    MachineAssignmentListComponent,
    MachineTodayScheduleComponent,
    OperatorTodayScheduleComponent,
    DepartmentTodayScheduleComponent,
    DepartmentFutureScheduleComponent,
    MachineFutureScheduleComponent,
    ShippingOverviewComponent,
    ShippingTicketDetailComponent,
    FloorUserSetupComponent,
    FilteredUserListComponent,
    ProductBuildStatusComponent,
    ProductWaterfallComponent,
    WaitingOrdersWidgetComponent,
    OverdueOrdersWidgetComponent,
    AtRiskOrdersWidgetComponent,
    FloorAlertsWidgetComponent,
    CompanyMessagesComponent,
    PriceHistoryChartComponent,
    PaintEditorComponent,
    PaintListComponent,
    PaintSelectComponent,
    PurchasingQueueComponent,
    PurchaseOrderComponent,
    ShippingCarrierSelectComponent,
    ShippingCarrierDetailComponent,
    ShippingPickLocationEditorComponent,
    ShippingSplitTicketComponent,
    ShippingCombineShipmentsComponent,
    ShippingSplitViewComponent,
    MaterialCertificationsComponent,
    MaterialSpecificationSelectComponent,
    MaterialTypeSelectComponent,
    MaterialHardnessSelectComponent,
    ShippingClassFilterComponent,
    BidPurchaseOrderAssignment,
    PoLineItemsComponent,
    MaterailTypeEditorComponent,
    MaterialTypeListComponent,
    MaterailSpecEditorComponent,
    MaterialSpecListComponent,
    MaterialGroupListComponent,
    MaterailGroupEditorComponent,
    MaterailHardnessEditorComponent,
    MaterialHardnessListComponent,
    MaterialAlloyListComponent,
    MaterialAlloyEditorComponent,
    FindExistingItemComponent,
    InventoryItemSelectComponent,
    GlobalRequisitionsComponent,
    BulkQuotesComponent,
    BulkQuoteListComponent,
    VendorDropSelectComponent,
    AddressSelectorComponent,
    BulkQuoteFinishingDialog,
    QuantityTableComponent,
    VolumeFormulaComponent,
    EstimateApprovalComponent,
    EstimateApprovalCostChartComponent,
    FilteredMultiSelectComponent,
    ExistingQuoteSummaryComponent,
    PurchasedItemSelectComponent,
    PurchasedItemEditorComponent,
    NewLineItemSelectComponent,
    ItemTypeChipComponent,
    QcClauseEditorComponent,
    NotificationPreferredChannelComponent,
    RuleBuilderListComponent,
    RuleBuilderDetailComponent,
    UnassignedPosListComponent,
    UnassignedPoDetailComponent,
    OverrideWorkflowPrompt,
    RequisitionListComponent,
    RequisitionInfoComponent,
    DeliveryTimesComponent,
    MaterialSimpleSearchComponent,
    BuildingEditorComponent,
    ShippingWrapperComponent,
    PoAddressSelectorComponent,
    ShippingQueueComponent,
    ShippingQueueListComponent,
    ShippingCheckInComponent,
    InventoryTreePickerComponent,
    InventoryTreeSelectComponent,
    ShippingCheckOutComponent,
    QualityTicketListComponent,
    EmployeeShiftAssignmentComponent,
    StaffUtilizationComponent,
    ContractReviewComponent,
    TicketTypeFilterComponent,
    CertExpirationComponent,
    QualityAssignComponent,
    ReportOptionsDialog,
    PartReportOptionsDialog,
    VendorSearch,
    QualityShippingReceiptComponent,
    EstimateApprovalLeadTimeBreakdownComponent,
    SimilarPartOverlayComponent,
    RmaSalesListComponent,
    RmaSalesDetailComponent,
    CustomerSearch,
    PartNumberSearch,
    QualityRmaDetailComponent,
    OrderRmaDetailComponent,
    RmaEstimateListComponent,
    WorkflowProgressComponent,
    BuildingDropdownComponent,
    ToolCribTicketListComponent,
    ToolCribFulfillComponent,
    WorkflowStepItemsComponent,
    EstimatePricingComponent,
    RfqListComponent,
    QualityRmaApprovalComponent,
    LeadTimeVerificationComponent,
    PoAcceptanceComponent,
    QuoteItemsTableComponent,
    QuoteAcceptedPosComponent,
    PoLineItemBuilderComponent,
    FabSpeedDialComponent,
    NewBatchRfqComponent,
    CompanySearchComponent,
    PreplanningDetailComponent,
    CompanySearchComponent,
    WoPlanningConfirmationDialog,
    ProgrammingListComponent,
    ProgrammingDetailComponent,
    TravelerListComponent,
    InspectionQueueComponent,
    InspectionTicketComponent,
    FailedInspectionReviewComponent,
    WorkflowStepEditComponent,
    SpecificationsInputComponent,
    MessageDialogComponent,
    ReportEmbedListComponent,
    ReportEmbedDetailComponent,
    ProductHistoryComponent,
    ProductHistoryComponent,
    ProductHierarchySortComponent,
    PlanningWoDetailComponent,
    EmployeeSearchComponent,
    CopyPartDialogComponent,
    WorkOrderSearchComponent,
    StationSearchComponent,
    LineItemsPreviewTableComponent,
    VendorContactSearchComponent,
    PurchasedItemPartNumberSelectComponent,
    PurchasingHomeComponent,
    AccountsReceivableQueueComponent,
    AccountReceivableDetailComponent,
    SearchListNewComponent,
    PrerequisiteListComponent,
    GlobalConstantsEditorComponent,
    PlanningStepNotesComponent,
    OrderDetailNewComponent,
    OrderDetailFormComponent,
    ProductDetailNewComponent,
    ProductDetailFormComponent,
    ProductQuantityInputComponent,
    ProductMaterialComponent,
    ProductQuotingComponent,
    ProductQuotingNewQuoteComponent,
    OpenOrderReportComponent,
    OorContractReviewComponent,
    OorPurchasingComponent,
    OorPreplanningComponent,
    OorPlanningComponent,
    OorProductionComponent,
    OorInspectionComponent,
    OorShippingComponent,
    OorGanttComponent,
    OorGanttBarComponent,
    ProductWorkflowComponent,
    ProductWorkflowStationListComponent,
    ProductWorkflowWorkflowListComponent,
    ProductWorkflowStepFormComponent,
    ProductPurchasedItemsComponent,
    PurchasedItemSearch,
    OrderDetailDocumentsComponent,
    OrderDetailProductsComponent,
    OrderDetailProductsUploadComponent,
    OrderDetailQuantityTableComponent,
    OrderDetailEstimatingScreenComponent,
    OrderDetailLeadTimeBreakdownComponent,
    ClientsideSearchComponent,
    OrdersegmentListNewComponent,
    OrderDetailRedirectorComponent,
    PoOutsideProcessEditorComponent,
    OutsideProcessEditorComponent,
    PoOutsideProcessSelectComponent,
    RfqApprovalListComponent,
    SalesNotesComponent,
    SalesNoteCardComponent,
    SalesNoteCreatorComponent,
    OpSpecificationEditorComponent,
    PurchasingLineItemHistoryComponent,
    OrderDetailPricingBreakdownComponent,
    EstimatingProgressStatusComponent,
    NewMicroticketComponent,
    AutocompleteTypeComponent,
    OrderDetailMicroticketScreenComponent,
    MicroticketListComponent,
    MicroticketCardComponent,
    MicroticketGlobalQueueComponent,
    MicroticketSubitemViewerComponent,
    UpcomingWorkListComponent,
    UpcomingWorkChartComponent,
    FormlyChipListTypeComponent,
    PlanningTicketInfoComponent,
    PlanningWaterfallGraphComponent,
    ProductEstimatingReviewComponent,
    WorkflowGraphComponent,
    ProductQuoteDetailNewComponent,
    ItemQuoteHistoryComponent
  ]//.concat(widgetComponents)
  ,
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ]
})
export class Cots {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
  public static routingTable: Route[] = [
    { path: 'test', component: PlanningWaterfallGraphComponent },
    { path: '', component: SigninComponent, pathMatch: 'full' },
    { path: 'admin', component: AdminPortalComponent },
    { path: 'admin/stations', component: StationEditorComponent },
    { path: 'admin/materials', component: MaterailEditorComponent },
    { path: 'admin/materials/types', component: MaterailTypeEditorComponent },
    { path: 'admin/materials/specs', component: MaterailSpecEditorComponent },
    { path: 'admin/materials/groups', component: MaterailGroupEditorComponent },
    { path: 'admin/materials/hardness', component: MaterailHardnessEditorComponent },
    { path: 'admin/departments', component: DepartmentEditorComponent },
    { path: 'admin/paints', component: PaintEditorComponent },
    { path: 'admin/purchased', component: PurchasedItemEditorComponent },
    { path: 'admin/qcclause', component: QcClauseEditorComponent },
    { path: 'admin/process', component: OutsideProcessEditorComponent },
    { path: 'admin/constants', component: GlobalConstantsEditorComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'user', component: UserDetailComponent },
    { path: 'customer', component: CustomerListComponent },
    { path: 'customer/:id', component: CustomerDetailComponent },
    { path: 'estimating', component: EstimateListComponent },
    { path: 'estimating/scorecard', component: EstimateScorecardComponent },
    { path: 'estimating/:id', component: OrderDetailRedirectorComponent, data: { discriminator: 'estimating' } },
    { path: 'estimating/oldUi/:id', component: OrderDetailComponent, data: { discriminator: 'estimating' } },
    { path: 'estimating/newUi/:id', component: OrderDetailNewComponent, data: { discriminator: 'estimating' } },
    { path: 'rma-estimating', component: RmaEstimateListComponent },
    { path: 'rma-estimating/:id', component: OrderDetailRedirectorComponent, data: { discriminator: 'rma-estimating' } },
    { path: 'rma-estimating/oldUi/:id', component: OrderDetailComponent, data: { discriminator: 'rma-estimating'  } },
    { path: 'rma-estimating/newUi/:id', component: OrderDetailNewComponent, data: { discriminator: 'rma-estimating' } },
    { path: 'quoting', component: QuoteListComponent },
    { path: 'quoting/new-batch', component: NewBatchRfqComponent },
    // Quoting not ready for new UI yet
    { path: 'quoting/:id', component: OrderDetailComponent, data: { discriminator: 'quoting' } },
    // { path: 'quoting/:id', component: OrderDetailRedirectorComponent, data: { discriminator: 'quoting' } },
    // { path: 'quoting/oldUi/:id', component: OrderDetailComponent, data: { discriminator: 'quoting' } },
    // { path: 'quoting/newUi/:id', component: OrderDetailNewComponent, data: { discriminator: 'quoting' } },
    { path: 'rfqs', component: RfqListComponent },
    { path: 'rfqApproval', component: RfqApprovalListComponent },
    { path: 'rfqs/:id', component: OrderDetailRedirectorComponent, data: { discriminator: 'rfqs' } },
    { path: 'rfqs/oldUi/:id', component: OrderDetailComponent, data: { discriminator: 'rfqs' } },
    { path: 'rfqs/newUi/:id', component: OrderDetailNewComponent, data: { discriminator: 'rfqs' } },
    { path: 'order', component: OrderListComponent },
    { path: 'order/:id', component: OrderDetailRedirectorComponent, data: { discriminator: 'order' } },
    { path: 'order/oldUi/:id', component: OrderDetailComponent, data: { discriminator: 'order' } },
    { path: 'order/newUi/:id', component: OrderDetailNewComponent, data: { discriminator: 'order' } },
    { path: 'sales/rma', component: RmaSalesListComponent },
    { path: 'sales/rma/:id', component: RmaSalesDetailComponent },
    { path: 'sales/lead-times', component: LeadTimeVerificationComponent },
    { path: 'sales/travelers', component: TravelerListComponent },
    { path: 'supplier', component: VendorListComponent },
    { path: 'supplier/:id', component: VendorDetailComponent },
    { path: 'purchasing', component: PurchasingHomeComponent },
    { path: 'purchasing/quotes', component: SupplierListComponent },
    { path: 'purchasing/orders', component: PurchasingQueueComponent },
    { path: 'purchasing/orders/unassigned', component: UnassignedPosListComponent },
    { path: 'purchasing/orders/unassigned/:id', component: UnassignedPoDetailComponent },
    { path: 'purchasing/order/:id', component: PurchaseOrderComponent },
    { path: 'purchasing/quote/:type/:id', component: SupplierDetailComponent },
    { path: 'purchasing/global', component: GlobalRequisitionsComponent },
    { path: 'purchasing/bulkquotes', component: BulkQuoteListComponent },
    { path: 'purchasing/bulkquote/:id', component: BulkQuotesComponent },
    { path: 'purchasing/prerequisites', component: PrerequisiteListComponent },
    { path: 'floor', component: FloorMainComponent },
    { path: 'floor/department/:id', component: DepartmentDetailComponent },
    { path: 'floor/travelers', component: TravelerListComponent },
    { path: 'inventory', component: InventoryListComponent },
    { path: 'inventory/locations', component: InventoryLocationDetailComponent },
    { path: 'inventory/:id', component: InventoryDetailComponent },
    { path: 'floor/operator', component: OperatorViewComponent },
    { path: 'employee', component: EmployeeListComponent },
    { path: 'employee/:id', component: UserDetailComponent },
    { path: 'planning', component: PlanningListComponent },
    { path: 'planning/:id', component: PlanningDetailComponent },
    { path: 'programming', component: ProgrammingListComponent },
    { path: 'programming/:id', component: ProgrammingDetailComponent },
    { path: 'quality', component: QualityTicketListComponent },
    { path: 'quality/contract/:id', component: ContractReviewComponent },
    { path: 'quality/received/:id', component: QualityShippingReceiptComponent },
    { path: 'quality/cert-expire/:id', component: CertExpirationComponent },
    { path: 'quality/rma/:id', component: QualityRmaDetailComponent },
    { path: 'quality/rma-approve/:id', component: QualityRmaApprovalComponent },
    { path: 'quality/inspection', component: InspectionQueueComponent },
    { path: 'quality/inspection/:id', component: InspectionTicketComponent },
    { path: 'shipping', component: ShippingOverviewComponent },
    { path: 'shipping/ticket/:id', component: ShippingTicketDetailComponent },
    { path: 'shipping/queue', component: ShippingQueueComponent },
    { path: 'requisition', component: RequisitionListComponent },
    { path: 'requisition/:id', component: RequisitionInfoComponent },
    { path: 'rules-builder', component: RuleBuilderListComponent },
    { path: 'rule-builder-detail/:id', component: RuleBuilderDetailComponent },
    { path: 'employee-shift-assignment', component: EmployeeShiftAssignmentComponent },
    { path: 'staff-utilization', component: StaffUtilizationComponent },
    { path: 'workflowprogress/:id', component: WorkflowProgressComponent },
    { path: 'floor/tool-crib', component: ToolCribTicketListComponent },
    { path: 'report-embed', component: ReportEmbedListComponent },
    { path: 'report-embed/:id', component: ReportEmbedDetailComponent },
    { path: 'accounting/billing', component: AccountsReceivableQueueComponent },
    { path: 'accounting/billing/:id', component: AccountReceivableDetailComponent },
    { path: 'openorders', component: OpenOrderReportComponent },
    { path: 'purchasing/openorders', component: OpenOrderReportComponent },
    { path: 'floor/openorders', component: OpenOrderReportComponent },
    { path: 'microtickets', component: MicroticketGlobalQueueComponent },
    { path: 'floor/upcoming', component: UpcomingWorkListComponent },
    { path: 'floor/upcoming/:id', component: UpcomingWorkChartComponent },
  ];
}
