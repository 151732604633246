<div class="pt-3">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon" title="Close" (click)="close()"><i class="fas fa-times"></i></a>

  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid pb-4">

    <div class="p-0 m-0 row">
      <div class="col-4">
        <department-list (selected)="select($event)"></department-list>
        <div class="mt-2 text-right">
          <button class="btn btn-success" (click)="addDepartment()">
            <i class="fas fa-fw la-lg fa-plus"></i>
            Add Department
          </button>
        </div>
      </div>
      <div class="col-8">
        <div *ngIf="!selectedDepartment">
          <p class="text-muted text-center font-italic p-5">-- Select a Department to View its Buildings --</p>
        </div>
        <div *ngIf="selectedDepartment">
          <div class="form-group">
            <label>Building Name</label>
            <input #name="ngModel" minlength="3" [(ngModel)]="selectedDepartment.name" required placeholder="Building Name" type="text" max="100" class="form-control form-control-lg" appStaticEdit [editable]="true" />
          </div>
          <div class="border border-secondary rounded">
            <div>
              <h5 class="p-2 mb-0 text-center bg-secondary">Department Buildings</h5>
            </div>
            <div *ngFor="let building of buildingsSorted"
            class="px-3 py-2 border-top border-secondary large d-flex align-items-center"
            [ngClass]="{
              'text-muted': !buildingIsInDepartment(building),
              'bg-light': !buildingIsInDepartment(building)
            }"
            >
              <mat-checkbox
              [disabled]
              [disableRipple]="true"
              style="cursor: default !important;"
              class="mt-2 mr-auto building-checkbox"
              [checked]="buildingIsInDepartment(building)" 
              (click)="$event.preventDefault()">
                {{ building.name }}
              </mat-checkbox>
              <button mat-flat-button color="primary" class="bg-success" 
                *ngIf="!buildingIsInDepartment(building)"
                (click)="addBuildingToDepartment(building)"
              >
                Add to Department
              </button>
              <button
                mat-flat-button color="primary" class="bg-danger" 
                *ngIf="buildingIsInDepartment(building)"
                (click)="removeBuildingFromDepartment(building)"
              >
                Remove from Department
              </button>
            </div>
          </div>
          <div class="float-right mt-4 pt-4">
            <button class="btn btn-danger mr-3" *ngIf="canRemove()" (click)="remove()">
              <i class="fas fa-fw fa-lg fa-minus-circle"></i>
              Delete
            </button>
            <button class="btn btn-success" (click)="save()">
              <i class="fas fa-fw fa-lg fa-save"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
