<div class="position-relative rounded" style="
  overflow: hidden;
  overflow-y: scroll;
  height: 90vh;
  display: flex;
  flex-direction: column;
">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <div class="w-100 py-3 px-2" style="
    position: sticky;
    top: 0;
    background: white;
    z-index: 99;
  ">
    <mat-form-field appearance="outline" class="w-100 mat-input-no-message dense-field" id="search-field" #searchField>
      <mat-icon matPrefix class="text-muted">search</mat-icon>
      <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchChange()" #searchInput />
      <button mat-icon-button matSuffix class="text-muted" [ngStyle]="{opacity: (loading || searchText === '') ? '0.5' : '1' }" [disabled]="loading || searchText === ''" style="
        display: inline-block;
        position: relative;
      " (click)="clearSearch()">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-flat-button matSuffix [class.bg-primary]="!loading" [class.text-white]="!loading" class="button-text" [disabled]="loading" style="
        position: relative;
        top: -7px;
        line-height: 32px;
        margin-left: 6px;
        padding: 0 12px;
      " (click)="onSearchChange()">
        Search
      </button>
    </mat-form-field>
  </div>
  <mat-table #dataTable [dataSource]="dataSource" class="w-100 flex-grow-1" [class.loading]="loading">
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: rowIsGroupHeader" class="oor-row"></mat-row>
    <mat-row *matRowDef="let row; columns: rowDisplayedColumns; " class="oor-row"></mat-row>
    <div class="mat-row" *matNoDataRow>
      <div class="mat-cell" [attr.colspan]="displayedColumns.length" class="text-muted small font-italic text-center"
        *ngIf="!loading">
        No open orders matching the parameters were found.
      </div>
    </div>

    <ng-container matColumnDef="groupHeader">
      <mat-cell *matCellDef="let row" style="flex-basis: 100%">
        <!-- <button type="button" mat-icon-button class="mr-1" (click)="groupSelectionModel.toggle(row.groupId); dataTable.renderRows()">
          <mat-icon class="mat-icon-rtl-mirror">
          {{groupSelectionModel.isSelected(row.groupId) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button> -->
        <b>{{ row.groupName }}</b>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="wo">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 15%"> WO # </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 15%">
        <b>{{ row.workOrderNumber }}</b>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="customer">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 7.5%"> Customer </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 7.5%">
        {{ row.customer.businessName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="po">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 10%"> PO # </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 10%">
        {{ row.customerPurchaseOrder.purchaseOrderNumber }}
      </mat-cell>
    </ng-container>
    <!--  -->
    <ng-container matColumnDef="purchasing">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        [matTooltip]="'Purchasing'"> PC </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell purchasing" [class.oor-color]="isPurchasingComplete(row)">
        <div class="triangle" [@triangleInOutAnimation] *ngIf="this.expansionModel.isSelected(row.workOrderId)" [ngStyle]="{
          left: 'calc(-10px + ' + ((selectedIndex * 80) + (selectedIndex === 6 ? 9 : 0)) + 'px)'
        }"></div>
        <div class="oor-checkbox" (click)="selectSection(row, 'purchasing')" matRipple
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="isPurchasingComplete(row)"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="preplanning">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        [matTooltip]="'Preplanning'"> PP </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell preplanning" [class.oor-color]="isPreplanningComplete(row)">
        <div class="oor-checkbox" (click)="selectSection(row, 'preplanning')" matRipple
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="isPreplanningComplete(row)"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="planning">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above" [matTooltip]="'Planning'">
        PL </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell planning" [class.oor-color]="isPlanningComplete(row)">
        <div class="oor-checkbox" (click)="selectSection(row, 'planning')" matRipple
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="isPlanningComplete(row)"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="production">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        [matTooltip]="'Production'"> PR </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell production" [class.oor-color]="isProductionComplete(row)">
        <div class="oor-checkbox" (click)="selectSection(row, 'production')" matRipple
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="isProductionComplete(row)"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="qa">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above"
        [matTooltip]="'QA/Inspection'"> QA </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell qa" [class.oor-color]="isInspectionComplete(row)">
        <div class="oor-checkbox" (click)="selectSection(row, 'qa')" matRipple matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="isInspectionComplete(row)"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="shipping">
      <mat-header-cell *matHeaderCellDef class="oor-checkbox-header" matTooltipPosition="above" [matTooltip]="'Shipping'">
        SH </mat-header-cell>
      <mat-cell *matCellDef="let row" class="oor-checkbox-cell shipping" [class.oor-color]="isShippingComplete(row)">
        <div class="oor-checkbox" (click)="selectSection(row, 'shipping')" matRipple
          matRippleColor="rgba(255,255,255,0.15)">
          <mat-checkbox class="mat-checkbox-readonly" [checked]="isShippingComplete(row)"></mat-checkbox>
        </div>
      </mat-cell>
    </ng-container>
    <!--  -->
    <ng-container matColumnDef="gaant">
      <mat-header-cell *matHeaderCellDef style="width: 5rem;">
        &nbsp;</mat-header-cell>
      <mat-cell *matCellDef="let row" style="width: 5rem; display: flex;
      align-items: center;
      justify-content: center;" class="text-muted">
        <button mat-icon-button (click)="selectSection(row, 'gaant')">
          <mat-icon class="gantt-icon">
            view_timeline
          </mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%; padding-left: 1em;"> Due Date </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
        {{ row.dueDate | date: 'MM/dd/yyyy' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expansionPanel">
      <mat-cell *matCellDef="let row" class="oor-expansion-panel">
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="oor-tab-group"
          *ngIf="this.expansionModel.isSelected(row.workOrderId)" [@inOutAnimation]>
          <mat-tab label="PC">
            <div class="oor-tab purchasing" [class.oor-color]="isPurchasingComplete(row)" (click)="selectSection(row, 'purchasing')">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 purchasing oor-color text-white mb-2 px-3 py-2 mt-2">
                  Purchasing
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <oor-purchasing [record]="row"></oor-purchasing>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="PP">
            <div class="oor-tab preplanning" [class.oor-color]="isPreplanningComplete(row)"
              (click)="selectSection(row, 'preplanning')">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 preplanning oor-color text-white mb-2 px-3 py-2 mt-2">
                  Preplanning
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <oor-preplanning [record]="row"></oor-preplanning>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="PL">
            <div class="oor-tab planning" [class.oor-color]="isPlanningComplete(row)" (click)="selectSection(row, 'planning')">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 planning oor-color text-white mb-2 px-3 py-2 mt-2">
                  Planning
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <oor-planning [record]="row"></oor-planning>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="PR">
            <div class="oor-tab production" [class.oor-color]="isProductionComplete(row)" (click)="selectSection(row, 'production')">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 production oor-color text-white mb-2 px-3 py-2 mt-2">
                  Production
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <oor-production [record]="row"></oor-production>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="QA">
            <div class="oor-tab qa" [class.oor-color]="isInspectionComplete(row)" (click)="selectSection(row, 'qa')">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 qa oor-color text-white mb-2 px-3 py-2 mt-2">
                  QA/Inspection
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <oor-inspection [record]="row"></oor-inspection>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="SH">
            <div class="oor-tab shipping" [class.oor-color]="isShippingComplete(row)" (click)="selectSection(row, 'shipping')">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 shipping oor-color text-white mb-2 px-3 py-2 mt-2">
                  Shipping
                </h3>
                <div class="flex-grow-1" style="max-height: 500px;">
                  <oor-shipping [record]="row"></oor-shipping>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="GAANT Chart">
            <div class="oor-tab">
              <div class="overview-container rounded mat-elevation-z4 bg-white h-100 d-flex flex-column">
                <h3 class="mb-1 bg-transparent p-3">
                  Timeline
                </h3>
                <oor-gantt [record]="row"></oor-gantt>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-cell>
    </ng-container>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[15]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements"
  style="
  position: sticky;
  bottom: -1px;
  "               
  >
  </mat-paginator>
</div>