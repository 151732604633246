import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MaterialBid, MaterialBidDocument } from '../../../../../purchasing/resources/materialBid';

@Component({
  selector: 'product-quote-detail-new',
  templateUrl: './product-quote-detail-new.component.html',
  styleUrls: ['./product-quote-detail-new.component.less']
})
export class ProductQuoteDetailNewComponent implements OnInit {

  @Input() quote: MaterialBid;
  @Input() editing: boolean;

  @Output() newDocs = new EventEmitter<string[]>();

  public onNewDocs(documents: MaterialBidDocument[]) {
    this.newDocs.emit(documents.map(d => d.documentId));
  }

  constructor() { }

  ngOnInit(): void {
  }

}
