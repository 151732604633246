import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { httpService } from '../../common/services/http.service';
import { RuleBuilder } from '../resources/rule-builder';
import { SearchResult } from '../../common/resources/search-result';



@Injectable({
  providedIn: 'root',
})
export class RuleBuilderService extends httpService {
  private apiBase: string = 'api/rulebuilder';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "RuleBuilder";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<RuleBuilder>> {
    return this.http.get<SearchResult<RuleBuilder>>(this.apiUrl + '/search', { params: { searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } })
      .pipe(
        //tap(data => console.log('getRules() - rules.length= ' + data.length)),
        catchError(this.handleError('getRules', null))
      );
  }

  getRules(): Observable<RuleBuilder[]> {
    return this.http.get<RuleBuilder[]>(this.apiUrl)
      .pipe(
        //tap(data => console.log('getRules() - rules.length= ' + data.length)),
        catchError(this.handleError('getRules', []))
      );
  }

  getRule(ruleId: string): Observable<RuleBuilder> {
    return this.http.get<RuleBuilder>(this.apiUrl + '/' + ruleId)
      .pipe(
        //tap(data => console.log('getRule() - rule= ' + JSON.stringify(data))),
        catchError(this.handleError('getRule', null))
      );
  }
   
  addRuleBuilder(ruleBuilder: RuleBuilder): Observable<RuleBuilder> {

    const url = `${this.apiUrl}/new`;

    return this.http.post<RuleBuilder>(url, ruleBuilder).pipe(
      //tap(data => console.log(`addRuleBuilder() - Name=${ruleBuilder.name}`)),
      catchError(this.handleError<RuleBuilder>(`addRuleBuilder() - Name=${ruleBuilder.name}`))
    );
  }

  updateRuleBuilder(ruleBuilder: RuleBuilder): Observable<RuleBuilder> {

    if (ruleBuilder.ruleId != null && ruleBuilder.ruleId != "") {
      const url = `${this.apiUrl}/${ruleBuilder.ruleId}`;

      return this.http.put<RuleBuilder>(url, ruleBuilder).pipe(
        //tap(data => console.log(`updateRuleBuilder() - id=${ruleBuilder.ruleId}`)),
        catchError(this.handleError<RuleBuilder>(`updateRuleBuilder() - id=${ruleBuilder.ruleId}`))
      )
    }
  }


  deleteRuleBuilder(ruleId: string): Observable<RuleBuilder> {

    if (ruleId != null && ruleId != "") {
      const url = `${this.apiUrl}/${ruleId}`;

      return this.http.delete<RuleBuilder>(url).pipe(
        tap(data => console.log(`deleteRuleBuilder() - id=${ruleId}`)),
        catchError(this.handleError<RuleBuilder>(`deleteRuleBuilder() - id=${ruleId}`))
      )
    }
  }

   
}
