<div class="w-100 d-flex p-3 mb-3 position-relative">
  <div class="gantt-y-grid-holder">
    <div class="gantt-y-line" *ngFor="let i of rows">&nbsp;</div>
  </div>
  <div class="gantt-left mr-2">
    <div class="gantt-header">&nbsp;</div>
    <div class="gantt-header">Contract Review</div>
    <div class="gantt-header">Purchasing</div>
    <div class="gantt-header">Preplanning</div>
    <div class="gantt-header">Planning</div>
    <div class="gantt-header">Production</div>
    <ng-container *ngFor="let step of workflow">
      <div class="gantt-header">{{ getName(step) }}</div>
      <div class="gantt-header" *ngIf="workflowStepHasInspection(step)">Inspection</div>
    </ng-container>
    <div class="gantt-header">Shipping</div>
    <div class="gantt-header">&nbsp;</div>
  </div>
  <div class="flex-grow-1 position-relative">
    <div class="gantt-grid">
      <div class="gantt-grid-x-interval" *ngFor="let i of gridxIntervalArray" [ngStyle]="{
          left: (gridxIntervalPercent * i) + '%'
        }">
        <div class="grid-tick-text">{{ getGridTickDate(i) | date: 'shortDate' }}</div>
      </div>
      <div class="gantt-grid-indicator today" [ngStyle]="{
        left: getBarStartOffset(today)
      }">
        <div class="gantt-grid-indicator-text">Today</div>
      </div>
      <div class="gantt-grid-indicator dueDate" [ngStyle]="{
        left: getBarStartOffset(dueDate)
      }">
        <div class="gantt-grid-indicator-text">Due</div>
      </div>
    </div>
    <div class="gantt-bar">&nbsp;</div>
    <oor-gantt-bar colorClass="qa"
      [start]="getBarStartOffset(orderStartDate)"
      [width]="getBarWidth(orderStartDate, contractReviewEndDate)"
    >&nbsp;</oor-gantt-bar>
    <oor-gantt-bar colorClass="purchasing"
      [start]="getBarStartOffset(orderStartDate)"
      [width]="getBarWidth(orderStartDate, purchasingEndDate)"
    >&nbsp;</oor-gantt-bar>
    <oor-gantt-bar colorClass="preplanning"
      [start]="getBarStartOffset(orderStartDate)"
      [width]="getBarWidth(orderStartDate, preplanningEndDate)"
    >&nbsp;</oor-gantt-bar>
    <oor-gantt-bar colorClass="planning"
      [start]="canStartPlanning ? getBarStartOffset(preplanningEndDate) : 0"
      [width]="canStartPlanning ? getBarWidth(preplanningEndDate, planningEndDate) : 0"
    >&nbsp;</oor-gantt-bar>
    <oor-gantt-bar colorClass="overall"
      [start]="this.record.datePlanningFinished ? getBarStartOffset(planningEndDate) : 0"
      [width]="this.record.datePlanningFinished ? getBarWidth(planningEndDate, overallProductionEndDate) : 0"
    >&nbsp;</oor-gantt-bar>
    <ng-container *ngFor="let step of workflow">
      <oor-gantt-bar colorClass="production"
        [start]="this.record.datePlanningFinished ? getWorkflowStepStartOffset(step) : 0"
        [width]="this.record.datePlanningFinished ? getWorkflowStepBarWidth(step) : 0"
      >&nbsp;</oor-gantt-bar>
      <oor-gantt-bar colorClass="qa"
        [start]="this.record.datePlanningFinished ? getWorkflowStepStartOffset(step) : 0"
        [width]="this.record.datePlanningFinished ? getWorkflowStepBarWidth(step) : 0"
       *ngIf="workflowStepHasInspection(step)">&nbsp;</oor-gantt-bar>
    </ng-container>
    <oor-gantt-bar colorClass="shipping"
      [start]="getBarStartOffset(overallProductionEndDate)"
      [width]="getBarWidth(overallProductionEndDate, shippingEndDate)"
    >&nbsp;</oor-gantt-bar>
    <div class="gantt-bar">&nbsp;</div>
    <div class="gantt-bar">&nbsp;</div>
  </div>
</div>