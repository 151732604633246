<div class="loading position-fixed min-vh-100 w-100">
  <div class="container margin-top-15p">
    <div class="row justify-content-center align-self-center">
      <div class="col-sm-6">
        <div class="card shadow">
          <div class="card-header">{{title}}</div>
          <div class="card-body text-center">
            <div class="spinner-holder"><mat-spinner [color]="'primary'"></mat-spinner></div>
            <h2 class="text-muted">
              {{content}}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
