import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { debounceTime, filter, map, mergeMap, tap } from "rxjs/operators";
import { Product } from "../../resources/product";
import { OrderService } from "../../services/order.service";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'report-options-dialog',
  templateUrl: './report-options-dialog.component.html',
})
export class ReportOptionsDialog implements OnInit {

  constructor(private orderSvc: OrderService, @Inject(MAT_DIALOG_DATA) private data: { managerView: boolean }) {
  }

  public searchedParts: Observable<Product[]>;
  public filteredParts: Observable<Product[]>;

  public selectedParts: Product[] = [];


  public managerView: boolean;
  public showBlocked = true;

  public receivedFrom?: Date = null;
  public receivedTo?: Date = null;

  public requiredFrom?: Date = null;
  public requiredTo?: Date = null;

  public sortBy : 'customer' | 'required' = 'customer';

  public searchControl = new FormControl<string>('');

  public loading = false;

  selected(part: Product) {
    this.selectedParts = [...this.selectedParts, part];
    this.searchControl.setValue('');
  }

  ngOnInit() {
    
    this.managerView = this.data.managerView;

    this.searchedParts = this.searchControl.valueChanges.pipe(
        debounceTime(250),
        tap(() => {
            this.loading = true
        }),
        mergeMap(value => {
            this.loading = false;
            return this.orderSvc.searchProducts(value);
        }),
    );
    this.filteredParts = combineLatest([this.searchControl.valueChanges.pipe(filter(v => typeof v === 'string')), this.searchedParts]).pipe(
        map(([searchValue, parts]) => parts.filter(p => {
          return searchValue && p.partNumber && p.partNumber.toLowerCase().includes(searchValue.toLowerCase());
        })),
    );
    this.searchControl.setValue('');

  }

}
