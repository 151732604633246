import { SelectionModel } from '@angular/cdk/collections';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSidenav } from '@angular/material/sidenav';
import { MatStep } from '@angular/material/stepper';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { Address } from '../../../common/resources/address';
import { DocumentService } from '../../../common/services/document.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Order, QuoteLineItem } from '../../resources/order';
import { Product } from '../../resources/product';
import { OrderService } from '../../services/order.service';
import { DeliveryTimes } from '../delivery-times/delivery-times.component';
import { POLineItem, PoLineItemBuilderComponent } from '../po-line-item-builder/po-line-item-builder.component';
import { CustomerPurchaseOrder, CustomerPurchaseOrderType } from '../../resources/customer-po';

type QuoteLineItemMod = (QuoteLineItem & { number: number, parent?: number });

@Component({
  selector: 'po-acceptance',
  templateUrl: './po-acceptance.component.html',
  styleUrls: ['./po-acceptance.component.less']
})
export class PoAcceptanceComponent implements OnInit {

  @Input() sidenav: MatSidenav;
  @Input() record: Order;
  @Output() done = new EventEmitter<void>();

  public saving = false;

  public lotNumberStepReached = false;
  @ViewChild('lotNumberStep') lotNumberStep: MatStep;
  @ViewChild('finalizeStep') finalizeStep: MatStep;
  @ViewChild('lineItemBuilder', { static: true }) lineItemBuilder: PoLineItemBuilderComponent;


  public poNumber = '';
  public poFile: File;
  public shipToAddress: Address;
  public usingCustomAddress = false;

  private _poType: CustomerPurchaseOrderType;
  public set poType(value: CustomerPurchaseOrderType | CustomerPurchaseOrderType[] | null) {
    if (Array.isArray(value)) this._poType = value?.[0] ?? null;
    else this._poType = value;
  }
  public get poType(): CustomerPurchaseOrderType {
    return this._poType;
  }

  public releaseForPO: CustomerPurchaseOrder | null = null;

  public poLineItems = [];

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('fileNameModel', { static: true }) fileNameModel: NgModel;

  public openFileInput() {
    this.fileInput && this.fileInput.nativeElement.click();
  }

  public onFileChange() {
    const files = this.fileInput && this.fileInput.nativeElement.files;
    console.log(files)
    if (files.length > 0) {
      this.poFile = files[0];
      this.fileNameModel.control.setValue(this.poFile.name);
    }
  }


  public enableCustomAddress() {
    this.shipToAddress = {
      addressId: UtilityService.emptyGuid,
      nickname: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
      faxNumber: ''
    }
    this.usingCustomAddress = true;
  }

  public disableCustomAddress() {
    this.shipToAddress = null;
    this.usingCustomAddress = false;
  }

  constructor(private orderSvc: OrderService, private utilitySvc: UtilityService, private router: Router, private dialog: MatDialog, private documentService: DocumentService) {

  }

  ngOnInit() {
    // default address selection disabled on client request
    // if (this.record.customer.mailingAddressId) {
    //   const customerAddress = this.record.customer.customerAddresses.find(ca => ca.addressId == this.record.customer.mailingAddressId);
    //   this.shipToAddress = customerAddress && customerAddress.address;
    // }
  }

  public get poLineItemsSorted() {
    if (!this.poLineItems) return [];
    var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return [...this.poLineItems].sort((a, b) => {
      return collator.compare(a.lineNumber, b.lineNumber);
    });
  }

  public get lineItemsOutput() {
    return this.poLineItems && this.poLineItems.map((poli: POLineItem) => ({
      quoteLineItemId: poli.quoteLineItemId,
      lineItemNumber: poli.lineNumber,
      product: poli.product,
      station: poli.station,
      quantity: poli.quantity,
      unitPrice: poli.unitPrice,
      extPrice: poli.unitPrice * poli.quantity,
      dueDate: poli.dueDate,
      exceptions: poli.exceptions,
      releaseForPoLineItemId: poli.releaseForPoLineItemId
    }))
  }

  public async complete() {
    const r = await this.utilitySvc.showConfirmationPromise('Are you sure?',
      '<p>The purchase order will be accepted. This cannot be undone.</p>'
    );
    if (!r) return;
    this.saving = true;
    const doc = await this.documentService.upload(this.poFile as any).toPromise();
    await this.orderSvc.acceptPurchaseOrder(this.record, {
      purchaseOrderNumber: this.poNumber,
      addressIsCustom: this.usingCustomAddress,
      shipToAddress: this.shipToAddress,
      lineItems: this.lineItemsOutput,
      documentId: doc.documentId,
      poType: this.poType,
      releaseForId: this.releaseForPO?.customerPurchaseOrderId,
      qualityAnswers: this.poLineItems.filter((x: POLineItem) => !x.stationId &&  x.productId).reduce((acc, x: POLineItem) => {
        acc[x.productId] = { 'd27451dd-6397-4631-a9bd-049d0938b6ff': {
  '5f819966-b1cf-47a6-a9d5-14dff74fcd63': x.exceptions.rev ? 'Yes' : 'No',
  'c24afed5-308d-414b-b564-3f071b31551b': x.exceptions.pricingLotQuantities ? 'Yes' : 'No',
  'f704beba-33f5-44e2-a26a-8ed3fd67762c': x.exceptions.estimatedLeadTimeCorrect ? 'Yes' : 'No',
  '606c3771-26ba-4820-a4ab-41faaedc25db': x.exceptions.documents ? 'Yes' : 'No',
  'b278ff9e-d4a5-4421-a057-c79b4ee43aa0': x.exceptions.longLeadItems ? 'Yes' : 'No',
        }};
        return acc;
      }, {})
    }).toPromise();
    this.saving = false;
    this.done.emit();
    this.sidenav.close();
  }

  public printName(i: QuoteLineItem) {
    if (!i.station) return `${i.product.partNumber} Rev. ${i.product.revision}`;
    else return `${i.station.name} for ${i.product.partNumber} Rev. ${i.product.revision}`
  }

  public toWeeks(days: number) {
    return Math.ceil(days / 5)
  }

  public cancel() {
    this.sidenav.close();
  }

  public verifyLineItemColumns = [
    'lineNumber',
    'quantity',
    'description',
    'unitPrice',
    'extPrice',
    'dueDate',
    'verify',
  ]

  public poExceptionsMap: { [key: string]: { [key: string]: boolean } } = {};

  exceptionChange(event: MatSelectionListChange, poli: POLineItem) {
    const key = event.options[0].value;
    const value = event.options[0].selected;
    if (!poli.exceptions) poli.exceptions = {
      rev: false,
      pricingLotQuantities: false,
      documents: false,
      estimatedLeadTimeCorrect: false,
      longLeadItems: false,
      longLeadMaterial: false,
      longLeadHardware: false
    };
    poli.exceptions[key] = value;
  }

  public verification: { [key: string]: boolean } = {
    customer: false,
    terms: false,
    address: false
  }

  public getExceptionCount(poli: POLineItem) {
    if (!poli.exceptions) return 0;
    return Object.values(poli.exceptions).filter(v => v === true).length;
  }

  public onStepChange(e: StepperSelectionEvent) {
    // reset verification every time we move to the verification step
    if (e.selectedIndex === 3) {
      this.verification = {
        customer: false,
        terms: false,
        address: false
      }
    }
  }

  public get allVerified(): boolean {
    return this.verification.customer === true && this.verification.terms && true && this.verification.address === true &&
      this.poLineItems.every(i => this.verification[i.poLineItemGuid] === true)
  }

  public get posForRelease(): CustomerPurchaseOrder[] {
    return this.record?.quotePurchaseOrders?.filter(q => q.type === CustomerPurchaseOrderType.BLANKET || q.type === CustomerPurchaseOrderType.LTA) ?? [];
  }

  public getPurchaseOrderNumber(po: CustomerPurchaseOrder): string {
    return po?.purchaseOrderNumber ?? "";
  }

}
