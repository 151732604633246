import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { Paint } from '../../../order/resources/paint';
import { PaintService } from '../../../order/services/paint.service';

@Component({
  selector: 'paint-editor',
  templateUrl: './paint-editor.component.html',
  styleUrls: ['./paint-editor.component.less']
})
export class PaintEditorComponent {
  public selectedPaint: Paint = null;
  public saving: boolean = false;
  @Input() sidenav: MatSidenav;
  @ViewChild('name') name: NgModel;

  constructor(private navService: NavigationService, private paintSvc: PaintService, private utilitySvc: UtilityService, private router: Router) {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Paint Editor");
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public select(paint: Paint): void {
    this.selectedPaint = paint;
  }

  public addPaint(): void {
    this.selectedPaint = <Paint>{
      paintId: UtilityService.emptyGuid,
      name: 'New Paint',
      sampleColor: "#cccccc",
      purchaseVolume: 1
    };
  }

  public get paintSheens(): any[] {
    return Paint.PaintSheen;
  }

  public get volumeList(): number[] {
    return Object.keys(Paint.PaintVolumes).map(i => parseInt(i));
  }

  public getVolume(pints: number): string {
    return Paint.PaintVolumes[pints];
  }

  public getSize(paint: Paint): string {
    if (!paint) return '';
    return Paint.PaintVolumes[paint.purchaseVolume];
  }

  public save(): void {
    if (this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Paint Name is Required", "<p>Please add a paint name before saving.</p>");
      return;
    }

    this.saving = true;
    this.paintSvc.save(this.selectedPaint).subscribe(result => {
      this.selectedPaint = result;
      this.saving = false;
    });
  }

  public canRemove(): boolean {
    return this.selectedPaint != null && this.selectedPaint.paintId != UtilityService.emptyGuid;
  }

  public remove(): void {
    this.utilitySvc.showConfirmation(`Remove Paint '${this.selectedPaint.name}'?`, "<p>Are you sure you want to remove this paint?</p><p class='text-muted'><i class='fas fa-fw text-info fa-info-circle'></i>This action will not affect in-process work orders.</p>", r => {
      if (r) {
        this.saving = true;
        this.paintSvc.remove(this.selectedPaint.paintId).subscribe(_ => {
          this.selectedPaint = null;
          this.saving = false;
        });
      }
    });
  }
}
