import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { WorkOrderService } from '../../services/work-order.service';
import { UtilityService } from '../../../common/services/utility.service';
import { OrderStatus } from '../../../order/resources/order';
import { UserService } from '../../../common/services/user.service';
import { ProgrammingTicket, ProgrammingType } from '../../resources/programming-ticket';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { FilterParam, SearchData } from '../../../common/components/search-list-new/search-list-new.component';

@Component({
  selector: 'programming-list',
  templateUrl: './programming-list.component.html',
  styleUrls: ['./programming-list.component.less']
})
export class ProgrammingListComponent {

  constructor(private stationService: StationService, navService: NavigationService, private service: WorkOrderService, private router: Router, private userSvc: UserService) {

  }
  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  public getStatusText(status: number): string {
    if (status === OrderStatus.TENT_APPROVED) return "Tent. Approved";
    if (status === OrderStatus.AWAITING_REVIEW) return "Aw. Review";
    if (status === OrderStatus.AWAIT_ESTIMATE) return "Aw. Estimate";
    return OrderStatus.getStatusText(status);
  }

  public search({ searchText, page, sort, forAllUsers, filters }: SearchData) {
    return this.service.programmingSearch(forAllUsers, searchText, page.pageIndex, sort.active, sort.direction, filters);
  }

  onSelect(record: ProgrammingTicket): void {
    this.router.navigate(['/programming/', (<ProgrammingTicket>record).programmingTicketId]);
  }

  public userIsManager() {
    // TODO
    return true;
  }

  public readonly availableStatuses = [OrderStatus.IN_PROCESS, OrderStatus.FULFILLED, OrderStatus.REJECTED] as const;

  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    }))
  }];

  public readonly defaultStatuses = [OrderStatus.IN_PROCESS, OrderStatus.REJECTED] as const;

  public readonly defaultFilters = { status: this.defaultStatuses }

  public getProgrammingTypeName(type: ProgrammingType) {
    switch (type) {
      case ProgrammingType.CMMProgramming: return 'CMM';
      case ProgrammingType.CNCProgramming: return 'CNC';
      case ProgrammingType.NCProgramming: return 'NC';
    }
  }

  public getDaysLate(ticket: ProgrammingTicket) {
    const till = ticket.completedDate ? ticket.completedDate : new Date();
    const since = (ticket.promiseDate && new Date(ticket.promiseDate) < new Date(ticket.dueDate)) ? ticket.promiseDate : ticket.dueDate;
    if (!since) return null;
    return Math.max(0, Math.floor((+new Date(till) - +new Date(since))/(24*60*60*1000)));
  }
  
  public getHighlightClass(due: string, now: any) {
    if (!due) {
      return '';
    }

    const target = new Date(due);
    if (!now) now = new Date();
    else now = new Date(now);
    const daysBetween = (+target - +now) / (24*60*60*1000);

    if (daysBetween < 1) {
      return 'bg-danger text-white';
    } else if (daysBetween < 3) {
      return 'bg-warning';
    }

    return '';
  }

}
