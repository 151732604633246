<search-list-new
  matSort
  [defaultSort]="{
    id: 'dueDate',
    start: 'asc'
  }"
  [showManagerToggle]="true"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['workOrderNumber', 'dateAssigned', 'promiseDate', 'dueDate', 'completedDate', 'late', 'programmingType', 'customer', 'user', 'status']"
  (itemSelected)="onSelect($event)"
  [fieldSearchFields]="[
    { field: 'customer', code: 'customer' },
    { field: 'user', code: 'user' },
    { field: 'user', code: 'assigned' },
    { field: 'workOrderNumber', code: 'wo' }
  ]"
  [fieldSearchNames]="{
    customer: 'Customer',
    user: 'Assigned User',
    workOrderNumber: 'WO #'
  }"
  localStorageId="programming-queue"
  [isManager]="userIsManager.bind(this)"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
  <ng-container matColumnDef="workOrderNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="number">WO #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.workOrder.workOrderNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dateAssigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dateAssigned">Assigned On</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.dateAssigned">
        {{row.dateAssigned | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dueDate">Due Date</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.dueDate" [ngClass]="getHighlightClass(row.dueDate, row.completedDate)" class="p-1 rounded">
        {{row.dueDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="promiseDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="promiseDate">Promise Date</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.promiseDate" [ngClass]="getHighlightClass(row.promiseDate, row.completedDate)" class="p-1 rounded">
        {{row.promiseDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="completedDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="completedDate">Completed On</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.completedDate">
        {{row.completedDate | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="late">
    <mat-header-cell *matHeaderCellDef>Late</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngLet="getDaysLate(row) as daysLate">
        {{daysLate !== null ? daysLate : ''}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="programmingType">
    <mat-header-cell *matHeaderCellDef mat-sort-header="programmingType">Type</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span>
        {{ getProgrammingTypeName(row.type) }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef mat-sort-header="customer">Customer</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span>
        {{ row.workOrder?.order?.customer?.businessName }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="user">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned">User</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.assignedUser" class="d-flex align-items-center text-muted font-weight-bold">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.assignedUser.fullName }}</span>
      </span>
      <span *ngIf="!row.assignedUser" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
</search-list-new>