<div class="row m-0 h-100-t d-flex flex-column" style="overflow: hidden">
  <div class="d-flex border-bottom py-2" *ngIf="progressService?.shouldShowIndicators()">
    <div class="ml-auto">
      <estimating-progress-status label="Workflow Status" [status]="getWorkflowTaskStatus()" (statusChange)="setWorkflowTaskStatus($event)" [disabled]="!editing"></estimating-progress-status>
    </div>
  </div>
  <div class="row m-0 p-0 flex-grow-1" style="height: 0">
    <div class="col-4 h-100-t p-0 border-right">
        <div class="h-100-t" style="
            display: flex;
            flex-direction: column;">
          <div class="w-100 d-flex align-items-center mt-3 p-3 border-bottom" style="padding-bottom: 19px !important;">
            <div style="font-size: 1.15rem" class="font-weight-medium">Step Detail</div>
            <div style="font-size: 0.9em; margin-top: 4px;" class="text-muted font-weight-medium mx-2" *ngIf="selectedStep">
              {{ getStation(selectedStep)?.name }}
            </div>
            <button mat-stroked-button color="warn" class="ml-auto" *ngIf="selectedStep && editing"
              (click)="deleteStep()">Delete</button>
          </div>
          <div class="p-4 text-center small text-muted" *ngIf="!selectedStep">Select a step to edit.</div>
          <mat-tab-group *ngIf="selectedStep" class="flex-grow-1">
            <mat-tab label="Step Data">
              <product-workflow-step-form [editing]="editing" (newOPSpec)="onNewOPSpec($event)" (edited)="onStepEdited()" [record]="record" [product]="product" [step]="selectedStep" (deleteStep)="deleteStep()" class="h-100-t" #stepFormComponent></product-workflow-step-form>
            </mat-tab>
            <mat-tab label="Microtickets" *ngIf="record && record.salesProcessId">
              <div class="pt-4 microticket-area">
                <microticket-subitem-viewer [relatedTicketId]="record.salesProcessId"
                  [relatedTicketName]="service.nameForMicrotickets" [relatedTicketType]="'SalesProcess'"
                  [filterKey]="service.microticketFilterKey"
                  [subItem]="service.generateSubItemNavigationId(product.productId, 'workflow', selectedStep.workflowStepId)"
                  (loadingChange)="service.loading = $event"
                  [microTickets]="service.sharedMicroticketSubject">
                </microticket-subitem-viewer>
              </div>
            </mat-tab>
          </mat-tab-group>
      </div>      
    </div>
    <div class="col-4 h-100-t p-0 d-flex flex-column" [class.col-4]="editing" [class.col-8]="!editing">
      <div style="font-size: 1.25rem" class="font-weight-normal mt-3 p-3 border-bottom d-flex align-items-center">
        <div>Workflow</div>
        <mat-button-toggle-group class="ml-auto" [(ngModel)]="workflowView">
          <mat-button-toggle value="list">
            <mat-icon svgIcon="list-box"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="chart">
            <mat-icon svgIcon="chart-pie"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
      <product-workflow-workflow-list class="h-100-t" (stationDraggedIn)="addStep($event.index, $event.station)" [(selectedStepId)]="selectedStepId" *ngIf="workflowView === 'list'"></product-workflow-workflow-list>
      <workflow-graph *ngIf="workflowView === 'chart'" [data$]="service.selectedProduct$" [quantityGetter]="quantityGetter" [(selectedStepId)]="selectedStepId"></workflow-graph>
    </div>
    <div class="col-4 p-0 pt-3 border-left h-100-t" *ngIf="editing">
      <product-workflow-station-list (stationDblClick)="addStep(product.workflow.workflowSteps.length, $event)"></product-workflow-station-list>
    </div>
  </div>
</div>