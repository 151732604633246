import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Material } from '../../../order/resources/material';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { ShippingInventoryItem, ShippingTicket } from '../../../shipping/resources/shipping-ticket';
import { QualityTicket } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { UtilityService } from '../../../common/services/utility.service';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'quality-shipping-receipt',
  templateUrl: './quality-shipping-receipt.component.html',
  styleUrls: ['./quality-shipping-receipt.component.less'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ transform: 'translateX(0px)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms', style({ transform: 'translateX(-400px)' }))
      ])
    ]
    )
  ],
})
export class QualityShippingReceiptComponent implements OnInit {

  public showEditor: string | null = null;

  public checklist_items = ['paperwork', 'quantity', 'cert', 'visual']

  public saving = false;
  public loading = false;
  public id: string;
  public ticket: QualityTicket | null = null;

  public locationParents: InventoryLocation[] = null;

  public checklist: {
    [key: string]: boolean 
  } | null = null;

  constructor(private qualityService: QualityService, private inventoryService: InventoryService, private utilitySvc: UtilityService, private router: Router, route: ActivatedRoute) {
    this.id = route.snapshot.paramMap.get('id');
  }

  public get record(): ShippingTicket {
    return this.ticket.shippingTicketPlaceLocation.shippingTicket;
  }

  public get allDone(): boolean {
    return this.checklist && Object.values(this.checklist).every(a => a === true);
  }

  public getCheckedCount() {
    return Object.values(this.checklist).filter(x => x === true).length;
  }

  public async getDetail() {
    this.loading = true;
    this.ticket = await this.qualityService.getDetail(this.id).toPromise();
    this.loading = false;

    // get location parents for display
    this.inventoryService.getLocationParents(this.ticket.shippingTicketPlaceLocation.inventoryItemLocation.inventoryLocationId).subscribe(parents => {
      this.locationParents = parents;
    })

  }

  public setupChecklist() {

    if (!this.record.inventoryItem.productId) {
      this.checklist_items = this.checklist_items.filter(i => i !== 'visual')
    }

    this.checklist = this.checklist_items.reduce((accx, item) => {
      accx[item] = false;
      return accx;
    }, {});

    console.log(this.checklist);
  }

  public getItemName(i: InventoryItem): string {
    if (i.materialId) {
      //Material
      return Material.generatedName(i.material);
    }
    else if (i.productId) {
      //product
      return i.productId && `${i.product.partNumber} Rev. ${i.product.revision}`;
    }
    else {
      //purchased item
      return i.purchasedItem && i.purchasedItem.description;
    }
  }

  public addDocuments(documents: VirtualDocument[]): void {
    this.saving = true;

    this.qualityService.addDocuments(this.ticket, documents).subscribe((_) => {
      this.saving = false;
      this.getDetail();
    });
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.qualityService.removeDocument(this.ticket, document).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  public openPurchaseOrderDocument() {
    window.open(`/document/${this.record.purchaseOrder.latestDocumentId}?inline=true`, "_blank")
  }

  public heatLot = '';
  public expirationDate: Date | null = null;
  public async finish() {

    const r = await this.utilitySvc.showConfirmationPromise('Are you done?', 'Has this shipment passed quality checks?');
    if (!r) return;

    this.saving = true;
    await this.qualityService.approveReceivingTicket(this.ticket, {
      heatLot: this.heatLot,
      expirationDate: this.expirationDate
    }).toPromise();
    this.saving = false;
    this.router.navigate(['/quality']);
  }

  ngOnInit() {
    this.getDetail().then(_ => this.setupChecklist());
  }

}

