<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel" (click)="cancelEditing()"><i class="fas fa-times"></i></a>
  <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Continue" (click)="addProduct()"><i class="fas fa-check"></i></a>

  <div class="row justify-content-center">
    <div class="col-6 mt-5" *ngIf="!adding">
      <div class="text-center border-bottom border-dark mb-3">
        <h5 class="bg-secondary m-0 p-2">Find an Existing Part</h5>
      </div>
      <div class="form-group">
        <label>Product Search</label>
        <div class="position-relative">
          <span class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="fas" [ngClass]="{'text-muted fa-chevron-down':!searching, 'fa-lg fa-circle-notch fa-spin text-info': searching}"></i></span>
          <input required minlength="4" #autoCompleteTrigger="matAutocompleteTrigger" #partInputText="ngModel" matInput placeholder="Search for a Part" class="form-control" [ngModel]="newPart!=null ? (newPart?.partNumber + ' Rev. ' + newPart?.revision) : ''" (keyup)="getPartList($event)" type="text" max="100" [matAutocomplete]="partList" />
          <mat-autocomplete (opened)="getPartList($event)" autoActiveFirstOption #partList="matAutocomplete" (optionSelected)="setValue($event.option.value)">
            <mat-option *ngFor="let part of parts" [value]="part">
              {{part.partNumber}} <span class="small text-muted">Rev.</span> {{part.revision}}
            </mat-option>
            <mat-option *ngIf="parts?.length==0">
              <button class="btn btn-sm btn-primary float-right mt-2" (click)="switchToAdding()">
                <i class="fas fa-plus"></i> Add a Part
              </button>
              <div class="text-muted text-center font-italic">-- No Matching Parts Found --</div>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="col-6 mt-5" *ngIf="adding">
      <div class="text-center border-bottom border-dark mb-3">
        <h5 class="bg-secondary m-0 p-2">Create a New Part</h5>
      </div>
      <div class="form-group">
        <label>Part Number</label>
        <input #partNumber="ngModel" type="text" required minlength="2" class="form-control" [(ngModel)]="newPart.partNumber" placeholder="Part Number" />
      </div>
      <div class="form-group">
        <label>Revision</label>
        <input #partRevision="ngModel" type="text" required class="form-control" [(ngModel)]="newPart.revision" placeholder="Revision" />
      </div>
    </div>
  </div>
</div>
