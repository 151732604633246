<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="mt-2">Floor Operations Setup</h5>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row mt-4">
      <div class="container-fluid">
        <div class="row form-group">
          <div class="col">
            <label>Home Department</label>
            <div *ngIf="!editable; else buildingSelect" class="pl-3">
              <span class="small text-muted">{{getDepartment(getBuilding(metaData?.primaryDepartmentId)?.departmentId)?.name}} / </span>
              <span>{{getBuilding(metaData?.primaryDepartmentId)?.building?.name || '-- Not Set --'}}</span>
            </div>
            <ng-template #buildingSelect>
              <mat-select [(value)]="metaData.primaryDepartmentId" placeholder="Select Department...">
                <mat-option *ngFor="let building of buildings" [value]="building.floorBuildingId">
                  <span class="small text-muted">{{getDepartment(building.departmentId)?.name}} / </span>
                  <span>{{building?.building?.name}}</span>
                </mat-option>
              </mat-select>
            </ng-template>
          </div>
        </div>
        <div class="row form-group">
          <div class="col">
            <label>Machine Proficiency</label>
            <ul class="list-unstyled">
              <li class="pl-3" *ngFor="let m of machines">
                <div class="text-primary font-weight-bold" *ngIf="m.floorBuildingId!=lastBuildingId">
                  <span class="small">{{getDepartment(getBuilding(m.floorBuildingId)?.departmentId)?.name}}</span>
                  / {{getBuilding(m.floorBuildingId, true)?.building?.name}}
                </div>
                <div class="p-2 pl-3 hoverStripe">
                  <span class="d-inline-block machineName">{{m.name}}</span>
                  <span><rating [labels]="['Trained', 'Experienced', 'Expert']" [editable]="editable" (valueChange)="setRating(m, $event)" [value]="getRatingFor(m)"></rating></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
