<loading *ngIf="!constants || saving"></loading>

<ng-container *ngIf="constants">
    <div class="d-flex" style="position: absolute; right: 1em; z-index: 15;" [ngStyle]="{
        top: sidenav ? '1.25em' : '5.25em'
    }" >
        <button mat-fab class="extended-fab-button text-white mr-2" [ngClass]="{ 'bg-light': form?.invalid || !form?.dirty, 'bg-success': form?.valid && form?.dirty }" [disabled]="form?.invalid || !form?.dirty" (click)="save()" (mousedown)="form?.markAsDirty()">
          <mat-icon>save</mat-icon>
          <span class="extended-fab-button__text">Save Changes</span>
        </button>
      </div>
    <mat-tab-group style="height: 90vh;" class="mat-tab-group-colgrow mat-tab-group-headerstyle" ngForm #form="ngForm">
        <mat-tab label="Purchasing">
            <mat-list class="py-0">
                <mat-list-item class="border-bottom py-2 h-auto">
                    <div style="min-width: 30%" class="font-weight-bold mr-2">{{ getConstant('po_qc_document_name')?.title }}</div>
                    <mat-form-field appearance="outline" class="dense-field" style="min-width: 15%">
                        <input matInput type="text" [(ngModel)]="getConstant('po_qc_document_name').value" name="po_qc_document_name" required />
                    </mat-form-field>
                </mat-list-item>
                <mat-list-item class="border-bottom py-2 h-auto">
                        <div style="min-width: 30%" class="font-weight-bold mr-2">{{ getConstant('po_approval_threshold')?.title }}</div>
                    <mat-form-field appearance="outline" class="dense-field" style="min-width: 15%">
                        <span matPrefix>$</span>
                        <input matInput type="number" [(ngModel)]="getConstant('po_approval_threshold').value" min="0" name="po_approval_threshold" required />
                    </mat-form-field>
                </mat-list-item>
            </mat-list>
        </mat-tab>
    </mat-tab-group>
</ng-container>