import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { VendorService } from '../../../supplier/services/vendor.service';
import { Vendor } from '../../../supplier/resources/vendor';

@Component({
  selector: 'vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.less']
})
export class VendorListComponent extends SearchList<Vendor> {

  constructor(navService: NavigationService, private service: VendorService, private router: Router) {
    super('VendorListComponent');
    navService.setPageTitle("Suppliers");
  }

  search(): void {
    this.results = null;
    this.service.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    });
  }

  onSelect(record: Vendor): void {
    this.router.navigate(['/supplier', record.vendorId]);
  }

  public getScoreStyle(score: number): any {
    return VendorService.getScoreStyles(score, true);
  }

  public getStatusColorClass(date: Date): string {
    return VendorService.getStatusColorClass(date);
  }

}
