import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MaterialBidService } from '../../services/material-bid.service';
import { MaterialBid, MaterialBidDocument } from '../../resources/materialBid';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { NgModel } from '@angular/forms';
import { Customer } from '../../../customer/resources/customer';
import { Vendor, VendorContact } from '../../../supplier/resources/vendor';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Contact } from '../../../common/resources/contact';
import { VendorService } from '../../../supplier/services/vendor.service';
import { MatDialog } from '@angular/material/dialog';
import { DocumentService } from '../../../common/services/document.service';

@Component({
  selector: 'quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.less']
})
export class QuoteDetailComponent implements OnInit {
  @Input() id: string;
  @Input() data: MaterialBid;
  @Input() sidenav: MatSidenav;
  @Input() customer: Customer | null;
  @Input() forceVendor: Vendor | null;
  public record: MaterialBid;
  public saving: boolean = false;
  public modifiedBy: User;
  public showEditor: string = null;
  public quoteWasDeleted = false;
  @ViewChild('insetnav', { static: true }) insetnav: MatSidenav;
  @ViewChild('leadTime') leadTime: NgModel;
  @Output() approved = new EventEmitter<MaterialBid>();
  @Output() documentsAdded = new EventEmitter<MaterialBidDocument[]>();

  @Input() directQuote: MaterialBid;
  @Input() editing: boolean = true;

  constructor(private navService: NavigationService, private quoteService: MaterialBidService, private userService: UserService, private vendorService: VendorService, private utilitySvc: UtilityService, private documentService: DocumentService, private dialog: MatDialog) { }

  public exit(): void {
    this.sidenav.close();
  }

  public bidType: "perItem" | "total" = "total"

  public save(bypass?: boolean): void {

    if ((!this.record.materialBidDocuments || this.record.materialBidDocuments.length == 0) && !bypass) {
      this.utilitySvc.showConfirmation("No Documentation Provided", "<p>You have not provided any documentation for this quote.</p><p class='text-muted'>Quotes cannot be accepted without documentation.</p><p class='pt-2'>Are you sure you want to save?</p>", r => {
        if (r) {
          this.save(true);
        }
      });
      return;
    }

    if ((this.record.perItemBid || 0) > 0) {
      if ((this.record.qty || 0) == 0) {
        this.utilitySvc.showAlert("Quantity Required", "<p>You must enter a quantity when quoting per item.</p><p class='text-muted'>You can also enter the total bid instead of the per item bid.</p>");
        return;
      }
    }

    if ((this.record.perItemBid || this.record.totalBid || 0) == 0) {
      this.utilitySvc.showAlert("Bid Required", "<p>You must enter a bid amount prior to saving.</p>");
      return;
    }

    if (this.leadTime.invalid || this.record.leadTimeDays == null) {
      this.leadTime.control.markAsTouched();
      this.utilitySvc.showAlert("Lead Time Required", "<p>You must enter a valid lead time.</p><p class='text-muted'>You can enter '0' for items that can be processed immediately.</p>");
      return;
    }

    this.saving = true;
    this.quoteService.save(this.record).subscribe(r => {
      this.record = r;
      this.saving = false;

      this.exit();
    });

  }

  public sidebarClosing(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  public openDocumentWindow(): void {
    if (this.record.vendor == null) {

      this.utilitySvc.showAlert("A Vendor is Required", "<p>Please make sure you select a vendor before continuing.</p>");
      return;
    }

    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.insetnav.toggle();
  }

  public openAddVendor(): void {
    this.showEditor = "addVendor";
    this.navService.pushBreadcrumb("Add Vendor");
    this.insetnav.toggle();
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.quoteService.removeDocument(this.record, document).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  public getTypeId(): string {
    return (this.record.material && this.record.material.materialGroupId) || this.record.stationId || 'purchased';
  }

  public getTypeName(): string {
    return (this.record.material && this.record.material.materialGroup.groupName) || (this.record.station && this.record.station.name) || 'Hardware';
  }

  public async addDocuments(documents: VirtualDocument[]) {
    this.saving = true;

    if (!this.directQuote) {
      this.record = await this.quoteService.save(this.record).toPromise();
      this.id = this.record.materialBidId;
      this.quoteService.addDocuments(this.record, documents).subscribe(
        _ => {
          this.saving = false;
          this.getDetail();
        }
      );
    } else {
      const newDocs = documents.map(doc => ({ materialBidId: this.record.materialBidId, documentId: doc.documentId, document: doc }));
      this.record.materialBidDocuments = [...this.record.materialBidDocuments, ...newDocs];
      this.documentsAdded.emit(newDocs);
    }
  }

  public approve(): void {

    if (!this.record.isVerbal && (!this.record.materialBidDocuments || this.record.materialBidDocuments.length == 0)) {
      this.utilitySvc.showAlert("Documentation Required", "<p>This quote cannot be accepted without documentation.</p>");
      return;
    }

    if (this.record.isVerbal && ((this.contacts.length > 0 && !this.record.contact) || !this.record.note)) {
      this.utilitySvc.showAlert("Verbal Quote Info Required", "<p>A verbal quote cannot be accepted without a contact or without explanatory notes.</p>");
      return;
    }

    if ((this.record.perItemBid || 0) > 0) {
      if ((this.record.qty || 0) == 0) {
        this.utilitySvc.showAlert("Quantity Required", "<p>You must enter a quantity when quoting per item.</p><p class='text-muted'>You can also enter the total bid instead of the per item bid.</p>");
        return;
      }
    }

    if ((this.record.perItemBid || this.record.totalBid || 0) == 0) {
      this.utilitySvc.showAlert("Bid Required", "<p>You must enter a bid amount prior to saving.</p>");
      return;
    }

    if (this.leadTime.invalid || this.record.leadTimeDays == null) {
      this.leadTime.control.markAsTouched();
      this.utilitySvc.showAlert("Lead Time Required", "<p>You must enter a valid lead time.</p><p class='text-muted'>You can enter '0' for items that can be processed immediately.</p>");
      return;
    }

    this.utilitySvc.showConfirmation("Accept this Quote?", "<p>Accept this quote, and use these prices for the related estimate?</p><p class='text-muted'>Any previously accepted quotes for this item will be disapproved.</p>", (r => {
      if (r) {

        this.saving = true;
        this.quoteService.save(this.record).subscribe(r => {
          this.record = r;

          this.quoteService.accept(this.record).subscribe(_ => {
            this.saving = false;
            this.approved.emit(this.record);
            this.exit();
          });
        });
      }
    }));
  }

  public reject(): void {

    this.utilitySvc.showConfirmation("Reject this Quote?", "<p>Are you sure you want to reject this quote?</p>", (r => {
      if (r) {

        this.saving = true;
        this.quoteService.save(this.record).subscribe(r => {
          this.record = r;

          this.quoteService.reject(this.record).subscribe(_ => {
            this.saving = false;

            this.exit();
          });
        });
      }
    }));
  }

  public isManager(): boolean {
    return this.userService.canAccess("PurchasingManager");
  }

  private getDetail(): void {
    if (this.directQuote) {
      this.record = this.directQuote;
      if (this.record.perItemBid != null) this.bidType = 'perItem';
      return;
    }
    this.quoteService.get(this.id).subscribe(result => {
      if (!result) {
        this.quoteWasDeleted = true;
        return;
      }
      this.record = result;
      if (this.record.perItemBid != null) this.bidType = 'perItem';
      if (this.record.acceptedBy != null || this.record.rejectedBy != null)
        this.userService.getUser(this.record.acceptedBy || this.record.rejectedBy).subscribe(r => this.modifiedBy = r);
    });
  }

  public get contacts(): Contact[] {
    return this.record.vendor.vendorContacts.map(vc => vc.contact);
  }

  @ViewChild('newVendorContactDialogTemplate', { static: true }) newVendorContactDialogTemplate: TemplateRef<any>;
  public async newVendorContact(name: string) {
    const dialogRef = this.dialog.open<Contact, any, Contact>(this.newVendorContactDialogTemplate, { 
      disableClose: false,
      minWidth: 500,
      data: {
        contactId: UtilityService.emptyGuid,
        name,
        title: '',
        phoneNumber: '', phoneExtension: '',
        cellNumber: '', fax: '',
        email: '', personalEmail: ''
    }});
    
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    this.saving = true;
    const createdContact = await this.vendorService.saveContact(this.record.vendor, <VendorContact>{
      vendorContactId: UtilityService.emptyGuid,
      useForQuoting: true,
      contact: result,
    }).toPromise();
    this.record.vendor.vendorContacts = [...this.record.vendor.vendorContacts, createdContact];
    this.record.contact = createdContact.contact;
    this.record.contactId = createdContact.contactId;
    this.saving = false;

  }

  ngOnInit(): void {
    if (this.data) {
      //Allows for passing of NEW data for our Quick Quotes
      this.record = this.data;
      this.record.vendor = this.forceVendor;
    }
    else {
      this.getDetail();
    }
  }

  public bidTypeChange(type: typeof this.bidType) {
    if (type === 'perItem') this.record.totalBid = null;
    if (type === 'total') this.record.perItemBid = null;
  }
}
