import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { SearchList } from '../../../common/components/search-list/search-list';

@Component({
  selector: 'inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.less']
})
export class InventoryListComponent extends SearchList<InventoryItem> {
  private locationId: string;
  private selectedList: InventoryLocation[];
  private locationList: InventoryLocation[];

  constructor(navService: NavigationService, private service: InventoryService, private router: Router) {
    super('InventoryListComponent');
    navService.setPageTitle("Inventory");
    this.selectedList = [];
    this.service.getLocationChildren(null).subscribe(data => {
      this.locationList = data;
    });
  }

  search(): void {
    this.results = null;
    this.service.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection, this.locationId).subscribe(results => {
      this.searchResults = results;
      this.results = results.results;
    });
  }

  setLocationFilter(location: InventoryLocation): void {
    if (location == null) {
      this.locationId = "";
      this.selectedList = [];
    } else {
      this.locationId = location.inventoryLocationId;
      this.selectedList.push(location);
    }
    this.service.getLocationChildren(location).subscribe(data => {
      this.locationList = data;
      this.search();
    });
  }

  onSelect(record: InventoryItem): void {
    this.router.navigate(['/inventory', record.inventoryItemId]);
  }
}
