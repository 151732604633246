import { Component, OnInit, ViewChild } from '@angular/core';
import { MicroTicket, MicroTicketStatus } from '../../resources/microticket';
import { MicroTicketService } from '../../services/microticket.service';
import { FilterParam, SearchData, SearchListNewComponent } from '../../../common/components/search-list-new/search-list-new.component';
import { UtilityService } from '../../../common/services/utility.service';
import { UserService } from '../../../common/services/user.service';

@Component({
  selector: 'microticket-global-queue',
  templateUrl: './microticket-global-queue.component.html',
  styleUrls: ['./microticket-global-queue.component.less']
})
export class MicroticketGlobalQueueComponent implements OnInit {

  constructor(public service: MicroTicketService, private userService: UserService) { }

  ngOnInit(): void {
  }

  daysLeft(date: string, abs: boolean): number | null {
    if (date == null) return null;

    return UtilityService.getDaysRemaining(new Date(date), abs);
  }

  public daysLeftColorClass(date: string) {
    const days = this.daysLeft(date, false);
    if (days < 0) return 'bg-danger text-dark';
    if (days <= 2) return 'bg-danger text-white';
    else if (days <= 7) return 'bg-warning text-dark';
    else return 'bg-info text-white';
  }

  public getStatusText = MicroTicketService.getStatusText;
  public getStatusColorClass = MicroTicketService.getStatusColorClass;

  public onSelect(ticket: MicroTicket) {
    window.location.href = `/api/microTicketNavigate/${ticket.microTicketId}`;
  }

  public isCCd(row: MicroTicket) {
    return row.carbonCopiesList.includes(this.userService.userData?.userId);
  }

  private forAllUsers: boolean = false;
  public search(data: SearchData) {
    this.forAllUsers = data.forAllUsers ?? false;
    return this.service.searchTicketsGlobal(data);
  }

  public get displayedColumns() {
    if (this.forAllUsers) return ['assigned', 'department', 'relatedTicketName', 'creator', 'text', 'dueDate', 'status'];
    else return ['department', 'relatedTicketName', 'creator', 'text', 'dueDate', 'status'];
  }

  public readonly availableStatuses = [MicroTicketStatus.OPEN, MicroTicketStatus.IN_PROGRESS, MicroTicketStatus.COMPLETE, MicroTicketStatus.CLOSED] as const;
  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: MicroTicketService.getStatusColorClass(s),
      title: MicroTicketService.getStatusText(s),
      value: s
    }))
  }]
  public readonly defaultStatuses = [MicroTicketStatus.OPEN, MicroTicketStatus.IN_PROGRESS] as const;
  public readonly defaultFilters = { status: this.defaultStatuses }

  public isAssignedToMe(row: MicroTicket) {
    return this.userService.userData?.userId === row?.assignedUserId;
  }

  @ViewChild('searchList') searchList: SearchListNewComponent<MicroTicket>;

  public markInProgress(row: MicroTicket) {
    this.service.markTicketInProgress(row).toPromise().then(() => this.searchList.forceUpdate());
  }
  public markComplete(row: MicroTicket) {
    this.service.markTicketComplete(row).toPromise().then(() => this.searchList.forceUpdate());
  }

}
