<div class="p-0 bg-light">
  <div class="text-center border-bottom border-dark">
    <h5 class="bg-secondary m-0 p-2">Step Detail</h5>
  </div>
  <div class="mt-1" *ngIf="step && isRepair && canEditWorkflowStep">
    <h3 class="small font-weight-bold text-center">Repair Step Type</h3>
    <mat-radio-group [(ngModel)]="step.stepType">
      <div class="px-2 py-1">
        <mat-radio-button [value]="1">
          One-time
          <i class="fas fa-circle-question ml-1" matTooltipPosition="after" matTooltip="One-time repair step, will not be added to product workflow in future orders."></i>
        </mat-radio-button>
      </div>
      <div class="px-2 py-1">
        <mat-radio-button [value]="2">
          Amend Workflow
          <i class="fas fa-circle-question ml-1" matTooltipPosition="after" matTooltip="Step will be permanently added to product's workflow after approval."></i>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="p-2">
    <p *ngIf="step==null" class="pt-5 text-muted small text-center font-italic">
      -- Select a Workflow Action --
    </p>
    <div *ngIf="step!=null">
      <h6 class="text-center m-3">
        {{getStation(step?.stationId)?.name}}
      </h6>
      <div class="form-group" *ngIf="getStation(step?.stationId)?.isOutsourceStep">
        <label [ngClass]="{'text-danger':!(step?.specification)}">Specification(s)</label>
        <input type="text" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                [(ngModel)]="step.specification"
                class="form-control form-control-sm form-inline">
      </div>
      <div class="form-group">
        <div class="text-center">
          <div class="col">
            <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="step.isStandalone" [ngClass]="{'text-danger':step?.compareTo_isStandalone!=null}">
              Standalone Step
            </mat-checkbox>
            <span class="text-danger" *ngIf="step?.compareTo_isStandalone!=null">&nbsp;(<i class="fas fa-fw" [ngClass]="{'fa-square':!(step?.compareTo_isStandalone),'fa-square-check':(step?.compareTo_isStandalone)}"></i>)</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="text-center">
          <mat-radio-group (change)="setWorkflowDirty()"
                            [disabled]="!canEditWorkflowStep || step.isStandalone"
                            [(ngModel)]="step.runIsPerPart" aria-label="Select an option">
            <mat-radio-button [value]="true" [ngClass]="{'text-danger':step?.compareTo_runIsPerPart===true}">Per Part</mat-radio-button>
            <mat-radio-button [value]="false" [ngClass]="{'text-danger':step?.compareTo_runIsPerPart===false}" class="ml-2">Per Job</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div>
        <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="step.hasSetup" [ngClass]="{'text-danger':step?.compareTo_hasSetup!=null}">
          Needs Setup
        </mat-checkbox>
        <span class="text-danger" *ngIf="step?.compareTo_hasSetup!=null">&nbsp;(<i class="fas fa-fw" [ngClass]="{'fa-square':!(step?.compareTo_hasSetup),'fa-square-check':(step?.compareTo_hasSetup)}"></i>)</span>
        <div class="form-group" style="min-height: 80px;" *ngIf="step.hasSetup">
          <label [ngClass]="{'text-danger':step?.compareTo_setupTime}">Standard Setup Time</label>
          <div class="input-group input-group-sm">
            <span *ngIf="step?.compareTo_setupTime" class="small text-danger compareTo">{{step.compareTo_setupTime}}</span>
            <input min="0.0" step="0.01" [(ngModel)]="step.setupTime" (ngModelChange)="setWorkflowDirty()" placeholder="Station Setup Time"
                    type="number" class="text-right form-control form-control-sm form-inline" appStaticEdit
                    [editable]="true" [disabled]="!step.hasSetup || !canEditWorkflowStep"  />
            <div class="input-group-append">
              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                hrs
              </span>
            </div>
          </div>
        </div>
        <div class="form-group" style="min-height: 80px;" *ngIf="step.hasSetup">
          <label>Per-Piece Setup Time</label>
          <div class="input-group input-group-sm">
            <input min="0.0" step="1" [(ngModel)]="step.perPieceSetupTime" placeholder="Per-Peice Setup Time"
                    type="number" class="text-right form-control form-control-sm form-inline" appStaticEdit
                    [editable]="true" [disabled]="!step.hasSetup || !canEditWorkflowStep" />
            <div class="input-group-append">
              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                min
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group"
            *ngIf="!(getStation(step?.stationId)?.isOutsourceStep) && !getStation(step?.stationId)?.isPainting">
        <label [ngClass]="{'text-danger':step?.compareTo_runTime}">Run Time</label>
        <div class="input-group input-group-sm">
          <span *ngIf="step?.compareTo_runTime" class="small text-danger compareTo">{{step.compareTo_runTime}}</span>
          <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                  [(ngModel)]="step.runTime"
                  class="form-control form-control-sm form-inline text-right">
          <div class="input-group-append">
            <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
              {{step?.runIsPerPart ? 'min':'hr'}}
            </span>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="getStation(step?.stationId)?.isOutsourceStep">
        <label [ngClass]="{'text-danger':step?.compareTo_outsourceMarkup}">Outsource Markup</label>
        <div class="input-group input-group-sm">
          <span *ngIf="step?.compareTo_outsourceMarkup" class="small text-danger compareTo">{{step.compareTo_outsourceMarkup}}</span>
          <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                  [(ngModel)]="step.outsourceMarkup"
                  class="form-control form-control-sm form-inline text-right">
          <div class="input-group-append">
            <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
              %
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <ng-container *ngIf="!getStation(step?.stationId)?.isPainting">
          <label [ngClass]="{'text-danger':step?.compareTo_runPrice}">
            {{getStation(step?.stationId)?.isOutsourceStep ? '' : 'Run '}}Rate
            <!-- <button class="btn btn-link"
                    *ngIf="isPurchasingUser() && getStation(step?.stationId)?.isOutsourceStep && step.selectedQuote != null"
                    (click)="showQuoteDetail(step.selectedQuote)">
              View Quote
            </button> -->
          </label>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text"
                    [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(step?.stationId)?.isOutsourceStep)}">
                $
              </span>
            </div>
            <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                    [editable]="canEditWorkflowStep && !(getStation(step?.stationId)?.isOutsourceStep)"
                    [(ngModel)]="step.runPrice"
                    class="form-control form-control-sm form-inline">
            <div class="input-group-append"
                  *ngIf="!(getStation(step?.stationId)?.isOutsourceStep)">
              <span *ngIf="step?.compareTo_runPrice" class="small text-danger compareTo before">{{step.compareTo_runPrice}}</span>
              <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                / hr
              </span>
            </div>
          </div>
          <div *ngIf="getStation(step?.stationId)?.isOutsourceStep">
            <!-- <div *ngIf="isPurchasingUser()">
              <existing-quote-summary [editable]="canEditWorkflowStep" (delete)="deleteQuote($event, 'workflow')" (edit)="showQuoteDetail($event.materialBidId)" (selectionChanged)="saveWorkflowQuoteSelection($event)"
                                      [orderId]="orderId" [filterId]="step?.stationId"
                                      [(selectionId)]="step.selectedQuote"></existing-quote-summary>
            </div> -->

            <div class="text-right" *ngIf="canEditWorkflowStep">
              <!-- <button class="btn btn-sm btn-primary"
                      (click)="showQuickQuote(null,step,null)">
                <i class="fas fa-fw fa-lg fa-quote-right"></i>
                Quick Quote
              </button> -->
            </div>
            <div>
              <label>Process Price History</label>
              <price-history-chart [stationId]="step?.stationId"></price-history-chart>
            </div>
          </div>
        </ng-container>
        <div class="pt-2" *ngIf="getStation(step?.stationId)?.isPainting">
          <div class="form-group">
            <label [ngClass]="{'text-danger':step?.compareTo_paint}">Paint Selection</label>
            <div class="container-fluid row p-0 m-0">
              <div class="col-2">
                <span class="d-inline-block paintSwatch"
                      [style.background]="step.paint?.sampleColor"></span>
              </div>
              <div class="col-10 p-0">
                <paint-select [editable]="canEditWorkflowStep" (addPaint)="addPaint($event)"
                              [paint]="step.paint" (paintChange)="assignPaint($event)"></paint-select>
              </div>
            </div>
            <div class="container-fluid row p-0 m-0 border-top border-bottom border-left border-right border-danger pt-2" *ngIf="step?.compareTo_paint">
              <div class="col-2">
                <span class="d-inline-block paintSwatch border-danger"
                      [style.background]="step.compareTo_paint?.sampleColor"></span>
              </div>
              <div class="col-10 p-0">
                {{step.compareTo_paint?.name}}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label [ngClass]="{'text-danger':step?.compareTo_paintedArea}">Painted Area</label>
            <div class="input-group input-group-sm">
              <span *ngIf="step?.compareTo_paintedArea" class="small text-danger compareTo">{{step.compareTo_paintedArea}}</span>
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                      [(ngModel)]="step.paintedArea"
                      class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  in<sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label [ngClass]="{'text-danger':step?.compareTo_paintCoats}">Number of Coats</label>
            <span *ngIf="step?.compareTo_paintCoats" class="small text-danger compareTo">{{step.compareTo_paintCoats}}</span>
            <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                    [(ngModel)]="step.paintCoats"
                    class="form-control form-control-sm form-inline text-right">
          </div>
          <div class="form-group">
            <label [ngClass]="{'text-danger':step?.compareTo_runPrice}">Labor Cost</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  $
                </span>
              </div>
              <span *ngIf="step?.compareTo_runPrice" class="small text-danger compareTo pl-4 ml-2">{{step.runPrice}}</span>
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                      [(ngModel)]="step.runPrice"
                      class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  / in<sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label [ngClass]="{'text-danger':step?.compareTo_paintCost}">Paint Cost</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  $
                </span>
              </div>
              <span *ngIf="step?.compareTo_paintCost" class="small text-danger compareTo pl-4 ml-2">{{step.paintCost}}</span>
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                      [(ngModel)]="step.paintCost"
                      class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  / in<sup>2</sup>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label [ngClass]="{'text-danger':step?.compareTo_paintMinPrice}">Minimum Charge</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  $
                </span>
              </div>
              <input type="number" (change)="setWorkflowDirty()" step="0.01" appStaticEdit [editable]="canEditWorkflowStep"
                      [(ngModel)]="step.paintMinPrice"
                      class="form-control form-control-sm form-inline">
              <span *ngIf="step?.compareTo_paintMinPrice" class="small text-danger compareTo before">{{step.compareTo_paintMinPrice}}</span>
            </div>
          </div>
        </div>
        <!--
        <div class="form-group" *ngIf="getStation(step?.stationId)?.isOutsourceStep">
          <div class="text-right mr-2 mt-1">
            <mat-radio-group (change)="setWorkflowDirty()" [disabled]="!canEditWorkflowStep" [(ngModel)]="step.outsourceIsFinal" aria-label="Select an option">
              <mat-radio-button [value]="true">Quoted</mat-radio-button>
              <mat-radio-button [value]="false" class="ml-2">Tentative</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        -->
        <!-- <div class="bg-light mt-2" *ngIf="shippingAssignments!=null && (getStation(step?.stationId)?.isOutsourceStep || getStation(step?.stationId)?.isShipping)">
          <div class="w-100 p-1 bg-secondary text-center text-secondary">Shipping</div>
          <div class="p-1">
            <h6 class="mt-2 font-weight-bold text-secondary">Shipping Ticket</h6>
            <div *ngIf="step?.outgoingShippingTicket">
              <a class="text-primary text-underline" [href]="'/shipping/ticket/' + step?.outgoingShippingTicket?.shippingTicketId" target="_blank">{{ getShippingTicketNumber(step?.outgoingShippingTicket) }} <i class="fas fa-fw fa-lg fa-external-link-alt text-underline pl-2"></i></a>
              <div class="my-1" *ngIf="getStation(step?.stationId)?.isOutsourceStep">
                <div *ngIf="step?.outgoingShippingTicket?.purchaseOrder">
                  <span class="badge badge-small mr-1">Vendor</span>
                  {{ step?.outgoingShippingTicket?.purchaseOrder?.vendor?.name }}
                </div>
                <div *ngIf="!step?.outgoingShippingTicket?.purchaseOrder">
                  <span class="text-muted" *ngIf="!step?.selectedQuote">-- No Quote Accepted --</span>
                  <span class="text-muted" *ngIf="step?.selectedQuote">-- No Purchase Order Issued --</span>
                </div>
              </div>
              <div>
                <label>Shipping From</label>
                <div>
                  <building-dropdown [editable]="canEditWorkflowStep"
                                      [selectedBuildingId]="getShippingAssignment(step)?.sendingBuildingId"
                                      (selectedBuildingChange)="setShippingAssignmentOutgoing(step, $event)">
                  </building-dropdown>
                </div>
              </div>
              <div>
                <label>Shipping Date</label>
                <div>
                  <div class="small text-muted" *ngIf="!getShippingAssignment(step) || !getShippingAssignment(step)?.sendingBuildingId">Select a building first.</div>
                  <date-picker *ngIf="getShippingAssignment(step) && getShippingAssignment(step).sendingBuildingId" [placeholder]="'Departure Date'" [editable]="canEditWorkflowStep && !!getShippingAssignment(step)" [(date)]="getShippingAssignment(step).sendingDateTime"></date-picker>
                </div>
              </div>
              <div *ngIf="!getStation(step?.stationId)?.isOutsourceStep">
                <label>Shipping To</label>
                <div>
                  <building-dropdown [editable]="canEditWorkflowStep"
                                      [selectedBuildingId]="getShippingAssignment(step)?.receivingBuildingId"
                                      (selectedBuildingChange)="setShippingAssignmentIncoming(step, $event)">
                  </building-dropdown>
                </div>
              </div>
              <div *ngIf="!getStation(step?.stationId)?.isOutsourceStep">
                <label>Arrival Date</label>
                <div>
                  <div class="small text-muted" *ngIf="!getShippingAssignment(step) || !getShippingAssignment(step)?.receivingBuildingId">Select a building first.</div>
                  <date-picker *ngIf="getShippingAssignment(step) && getShippingAssignment(step).receivingBuildingId" [placeholder]="'Arrival Date'" [editable]="canEditWorkflowStep && !!getShippingAssignment(step)" [(date)]="getShippingAssignment(step).receivingDateTime"></date-picker>
                </div>
              </div>
            </div>
            <div *ngIf="!step?.outgoingShippingTicket" class="text-muted">
              -- No Shipping Ticket --
            </div>
            <div class="mt-2" *ngIf="getStation(step?.stationId)?.isOutsourceStep">
              <h6 class="font-weight-bold text-secondary">Return Shipping Ticket</h6>
              <div *ngIf="step?.incomingShippingTicket">
                <a class="text-primary text-underline" [href]="'/shipping/ticket/' + step?.incomingShippingTicket?.shippingTicketId" target="_blank">{{ getShippingTicketNumber(step?.incomingShippingTicket) }} <i class="fas fa-fw fa-lg fa-external-link-alt text-underline pl-2"></i></a>

                <div>
                  <label>Shipping To</label>
                  <div>
                    <building-dropdown [editable]="canEditWorkflowStep"
                                        [selectedBuildingId]="getShippingAssignment(step)?.receivingBuildingId"
                                        (selectedBuildingChange)="setShippingAssignmentIncoming(step, $event)">
                    </building-dropdown>
                  </div>
                </div>
                <div>
                  <label>Arrival Date</label>
                  <div>
                    <div class="small text-muted" *ngIf="!getShippingAssignment(step) || !getShippingAssignment(step)?.receivingBuildingId">Select a building first.</div>
                    <date-picker *ngIf="getShippingAssignment(step) && getShippingAssignment(step).receivingBuildingId" [placeholder]="'Arrival Date'" [editable]="canEditWorkflowStep && !!getShippingAssignment(step)" [(date)]="getShippingAssignment(step).receivingDateTime"></date-picker>
                  </div>
                </div>
              </div>
              <div *ngIf="!step?.outgoingShippingTicket" class="text-muted">
                -- No Shipping Ticket --
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <mat-checkbox *ngIf="!(getStation(step?.stationId)?.isOutsourceStep)" [disabled]="!canEditWorkflowStep" [(ngModel)]="step.hasFirstPartInspection" (ngModelChange)="setWorkflowDirty()">Has First Part Inspection</mat-checkbox>
      <mat-checkbox *ngIf="!(getStation(step?.stationId)?.isOutsourceStep)" [disabled]="!canEditWorkflowStep" [(ngModel)]="step.inspectionIsCMM" (ngModelChange)="setWorkflowDirty()">Inspection Is CMM<mat-icon class="text-muted" matTooltipPosition="after" matTooltip="Whether inspection requires a coordinate-measuring machine. Affects both first-part and full inspections. No effect if neither inspection type is performed.">info_outline</mat-icon></mat-checkbox>
      <ng-container *ngIf="!(getStation(step?.stationId)?.isOutsourceStep)">
        <div class="w-100 p-1 bg-secondary text-center text-secondary">Programming
          <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="step.hasProgramming" (ngModelChange)="onToggleProgramming($event)" class="float-right"></mat-checkbox>
        </div>
        <div *ngIf="step.hasProgramming">
          <div class="form-group">
            <label>Programming Run Time</label>
            <div class="input-group input-group-sm">
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                    [(ngModel)]="step.programmingTime"
                    class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  hr
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Programming Run Rate</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text"
                      [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(step?.stationId)?.isOutsourceStep)}">
                  $
                </span>
              </div>
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                      [editable]="canEditWorkflowStep && !(getStation(step?.stationId)?.isOutsourceStep)"
                      [(ngModel)]="step.programmingRate"
                      class="form-control form-control-sm form-inline">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  / hr
                </span>
              </div>
            </div>
          </div>
          <div class="font-weight-bold small text-right text-muted mb-1">
            Total Programming Cost: {{ (step.programmingRate || 0) * (step.programmingTime || 0) | currency }}
          </div>
        </div>
        <div class="w-100 p-1 bg-secondary text-center text-secondary">Full Inspection
          <mat-checkbox [disabled]="!canEditWorkflowStep" [(ngModel)]="step.hasInspection" (ngModelChange)="onToggleInspection($event)" class="float-right"></mat-checkbox>
        </div>
        <div *ngIf="step.hasInspection">
          <div class="form-group">
            <label>Inspection Run Time</label>
            <div class="input-group input-group-sm">
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                    [(ngModel)]="step.inspectionTime"
                    class="form-control form-control-sm form-inline text-right">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  min
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Inspection Run Rate</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text"
                      [ngClass]="{'disabled':(!canEditWorkflowStep || getStation(step?.stationId)?.isOutsourceStep)}">
                  $
                </span>
              </div>
              <input type="number" (change)="setWorkflowDirty()" appStaticEdit
                      [editable]="canEditWorkflowStep && !(getStation(step?.stationId)?.isOutsourceStep)"
                      [(ngModel)]="step.inspectionRate"
                      class="form-control form-control-sm form-inline">
              <div class="input-group-append">
                <span class="input-group-text" [ngClass]="{'disabled':!canEditWorkflowStep}">
                  / hr
                </span>
              </div>
            </div>
          </div>
          <div class="font-weight-bold small text-right text-muted mb-1">
            Total Inspection Cost: {{ this.getInspectionCost() | currency }}
          </div>
        </div>
      </ng-container>
      <div class="bg-light">
        <div class="w-100 p-1 bg-secondary text-center text-secondary">Notes</div>
        <div class="p-1">
          <textarea rows="6" (change)="setWorkflowDirty()" appStaticEdit [editable]="canEditWorkflowStep"
                    class="form-control form-control-sm" [(ngModel)]="step.description"
                    placeholder="Station/Process Notes"></textarea>
        </div>
      </div>
      <!-- TODO: Lead Time from Quote here -->
      <div class="form-group text-right" *ngIf="canEditWorkflowStep">
        <button class="btn btn-success" (click)="onSave()">
          <i class="fas fa-save fa-lg fa-fw"></i> Save
        </button>
      </div>
    </div>
  </div>
</div>