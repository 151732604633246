import { Component, Input, TemplateRef, ContentChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MessageType } from '../../../common/resources/message';
import { MessageService } from '../../../common/services/message.service';
import { SearchResult } from '../../../common/resources/search-result';

@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.less']
})

export class SearchResultComponent<T> implements OnChanges {
  @Input() results: SearchResult<T> = null;
  @Input() emptySetMessage: string = null;
  @Output() selected: EventEmitter<T> = new EventEmitter<T>();
  @Output() newPage: EventEmitter<number> = new EventEmitter<number>();
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  constructor(private messageService: MessageService) { }

  selectResult(result: any): void {
    this.selected.emit(result);
  }

  getPage(page: number): void {
    this.newPage.emit(page);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.messageService.add(`${this.results.resultCount} Results Found`, MessageType.GENERAL, true);
  }
}
