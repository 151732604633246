import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { InventoryService } from '../../../inventory/services/inventory.service';
import { Order, OrderStatus } from '../../../order/resources/order';
import { RMADispositionLot, RMATicket } from '../../../rma/resources/rma-ticket';
import { RMAService } from '../../../rma/service/rma.service';
import { QualityTicket } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';

@Component({
  selector: 'quality-rma-approval',
  templateUrl: './quality-rma-approval.component.html',
  styleUrls: ['./quality-rma-approval.component.less']
})
export class QualityRmaApprovalComponent implements OnInit {

  private ticketId: string;
  public loading = false;
  public saving = false;
  public qualityTicket: QualityTicket;
  public record: RMATicket;

  constructor(
    route: ActivatedRoute,
    public router: Router,
    public qualitySvc: QualityService,
    public rmaSvc: RMAService,
    public userSvc: UserService,
    public navService: NavigationService,
    public inventoryService: InventoryService,
    public utilitySvc: UtilityService,
    public dialog: MatDialog
  ) {
    this.ticketId = route.snapshot.paramMap.get('id');
  }

  @ViewChild('rejectReasonTemplate', { static: true }) rejectReasonTemplate: TemplateRef<any>;

  async getDetail() {
    this.loading = true;
    this.qualityTicket = await this.qualitySvc.getDetail(this.ticketId).toPromise();
    this.navService.setPageTitle('Quality Ticket Detail');
    this.navService.pushBreadcrumb(this.qualityTicket.qualityTicketNumber);
    this.loading = false;
  }

  public async approve() {
    const r = await this.utilitySvc.showConfirmationPromise(
      'Are you sure?',
      `<p>Are you sure you want to approve this RMA request?</p><p>This cannot be undone.</p>`
      );
    if (!r) return;
    this.saving = true;
    await this.qualitySvc.approveRMARequest(this.qualityTicket).toPromise();
    this.saving = false;
    this.router.navigate(['/quality']);
  }

  public async reject() {
    const ref = this.dialog.open(this.rejectReasonTemplate, {
      disableClose: false,
      data: {
        reason: ''
      }
    });
    const result = await ref.afterClosed().toPromise();
    if (result === null) return;

    this.saving = true;
    await this.qualitySvc.rejectRMARequest(this.qualityTicket, result).toPromise();
    this.saving = false;
    this.router.navigate(['/quality']);
  }

  ngOnInit() {
    this.getDetail();
  }

}
