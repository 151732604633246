import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { Product } from '../../resources/product';

@Component({
  selector: 'product-tree',
  templateUrl: './product-tree.component.html',
  styleUrls: ['./product-tree.component.less']
})
export class ProductTreeComponent implements OnInit {
  @Input() product: Product;
  @Input() editing = false;
  childrenLoaded: boolean = true;
  isExpanded: boolean = false;
  @Output() selected: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() delete = new EventEmitter<Product>();
  @Output() deleteChild = new EventEmitter<{
    child: Product, parent: Product
  }>();

  constructor(private orderSvc: OrderService) { }

  public getIcon(): string {
    return (this.product.childAssemblies == null || this.product.childAssemblies.length == 0) ? "fa-box" : "fa-lg fa-boxes";
  }

  public get firstQuantity(): string {
    if (this.product.quantityAsChild) return this.product.quantityAsChild.toString();
    if (this.product.orderQuantity) return this.product.orderQuantity.toString();
    if (this.product.quantitiesMap == null) return null;

    var quantities = this.product.quantitiesMap;

    if (quantities.length == 0) return null;

    return quantities[0].value.toString();
  }

  public get hasChildren(): boolean {
    if (this.product == null || this.product.childAssemblies == null) return false;

    return this.product.childAssemblies.length > 0;
  }

  public toggleExpansion(event: MouseEvent): void {
    this.isExpanded = !this.isExpanded;
    event.stopImmediatePropagation();
  }

  public bubbleSelection(p: Product): void {
    this.selected.emit(p);
    event.stopImmediatePropagation();
  }

  public deleteProduct(p: Product, event?: MouseEvent) {
    if (event) event.stopPropagation();
    this.delete.emit(p);
  }

  public deleteChildProduct(parent: Product, child: Product, event?: MouseEvent) {
    console.log(this.product.partNumber, 'deleteChildProduct', parent.partNumber, child.partNumber)
    if (event) event.stopPropagation();
    this.deleteChild.emit({ parent, child });
  }


  ngOnInit(): void {
  }
}
