import { EventEmitter, Input, OnInit, AfterViewInit, Output, ViewChild, Directive, Injector, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl, NgForm, NgModel } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatFormField } from "@angular/material/form-field";
import { combineLatest, Observable, Subscription } from "rxjs";
import { debounceTime, filter, map, mergeMap, share, startWith, tap } from "rxjs/operators";

@Directive()
export abstract class GenericSearchComponent<SearchType> implements OnInit, AfterViewInit, ControlValueAccessor {

  _ngForm: NgForm;

  constructor(private injector: Injector) {
  }

  public filteredItems: Observable<SearchType[]>;

  public searchValue: string = '';

  public abstract placeholder: string;

  public abstract get addItemText(): string;
  public abstract get noItemsText(): string;


  @Input() dense: boolean;
  @Input() hint: string;
  @Input() label: string;
  @Input() readonly = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() selectedItem: SearchType | null = null;
  @Output() selectedItemChange = new EventEmitter<SearchType | null>();
  @Output() onAddItem: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchModel', { static: true }) searchModel: NgModel;

  public loading = false;

  private onChange: (value: SearchType) => void = (_) => {};
  private onTouched: () => void = () => {};

  public searchValueField(item: SearchType) {
    return this.getSearchField(item);
  };
  selected(item: SearchType) {
    this.writeValue(item);
    this.selectedItemChange.emit(item);
    this.onChange(item);
  }

  writeValue(item: SearchType): void {
    this.selectedItem = item;
    this.searchValue = this.searchValueField(item);
  }

  public registerOnChange(fn: (value: SearchType) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public abstract doSearch(searchText: string): Observable<SearchType[]>;
  public abstract getSearchField(item: SearchType): string;
  public abstract canAdd(): boolean;
  public onAdd(e: Event): void {
    e.stopImmediatePropagation();
    this.onAddItem.emit(this.searchValue);
  }

  public displayWith(e: SearchType | string): string {
    if (typeof e === 'string') { return e; }
    if (this.getSearchField) { return this.getSearchField(e);}
    return '';
  }

  ngOnInit() {
  }

  private request: Subscription;

  public searchedItems$: Observable<SearchType[]>;
  public searchedStartWith: Observable<string>;
  public filteredItems$: Observable<SearchType[]>;
  @ViewChild('formField') formField: MatFormField;
  ngAfterViewInit() {
    this.searchedStartWith = this.searchModel.valueChanges.pipe(startWith(''));
    this.searchedItems$ = this.searchModel.valueChanges.pipe(
        debounceTime(350),
        startWith(''),
        filter((val) => {
          return typeof val === 'string'
        }),
        tap(() => { this.loading = true; }),
        mergeMap((val) => {
          return this.doSearch(val);
        }),
        tap(() => { this.loading = false; }),
        share()
    );

    this.filteredItems$ = combineLatest([
      this.searchedItems$,
      this.searchedStartWith
    ]).pipe(
      map(([results, filter]) => {
        if (!filter || typeof filter !== 'string') return results;
        else return results.filter(i => {
          const field = this.getSearchField(i).toLowerCase();
          const keywords = filter.toLowerCase().split(' ');
          return keywords.some(kw => field.includes(kw));
        })
      })
    )

    if (this.selectedItem) {
      this.searchValue = this.getSearchField(this.selectedItem)
    } else {
      // 
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem) {
      const change = changes.selectedItem;
      if (change.firstChange) return;
      this.searchValue = this.getSearchField(change.currentValue);
    }
  }

  onBlur(auto: MatAutocomplete) {
    // we only care if the value is a string or an object
    // const value = this.selectedItem;
    // if (!value) return;
    // const matchingOptions = auto.options.find(
    //   (option) => JSON.stringify(option.value) == JSON.stringify(value)
    // );
    // if (!matchingOptions) {
    //   this.selectedItem = null;
    //   this.selectedItemChange.emit(null);
    //   this.onChange(null);
    // }
  }


}