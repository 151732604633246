import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { Address } from '../../../common/resources/address';
import { AddressSearchResult, AddressService } from '../../../common/services/address.service';
import { Customer } from '../../../customer/resources/customer';
import { CustomerService } from '../../../customer/services/customer.service';
import { FloorService } from '../../../floor/services/floor.service';

@Component({
  selector: 'app-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.less']
})
export class AddressSelectorComponent {

  @Input() filter: ('ourBuildings' | 'customers' | 'vendors')[] = null;
  @ViewChild('inputText', { static: true }) inputTxt: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;

  private request: Subscription = null;
  public searching: boolean = false;

  public results: AddressSearchResult[] = [];

  @Input() public address: Address;
  @Output() addressChange = new EventEmitter<Address>();

  constructor(private addressService: AddressService) { }

  setValue(result: AddressSearchResult): void {
    this.address = result.address;
    this.addressChange.emit(result.address);
    if (result == null)
      return;

    this.testSelection();
  }

  public testSelection(): void {
    this.inputTxt.nativeElement.value = this.address.nickname;
  }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.doSearch('');
      this.autoCompleteTrigger.openPanel();
    }
  }

  getChipName(r: AddressSearchResult) {
    if (r.type === "building") return 'APM Building';
    else if (r.type === "customer") return 'Customer';
  }

  getChipColor(r: AddressSearchResult) {
    if (r.type === "building") return '#FD7037';
    else if (r.type === "customer") return '#3D405B';
  }

  async doSearch(_: any) {
    this.searching = true;
    if (this.request != null) {
      this.request.unsubscribe();
    }

    const ourBuildings = this.filter ? this.filter.includes('ourBuildings') : true;
    const customers = this.filter ? this.filter.includes('customers') : true;
    const vendors = this.filter ? this.filter.includes('vendors') : true;

    const results = await this.addressService.search(this.inputTxt.nativeElement.value, "asc", 0, 50, ourBuildings, customers, vendors).toPromise();
    this.results = results.results;
    this.searching = false;
      
  }
}