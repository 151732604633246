<div class="w-100 p-3">
    <div class="row m-0" ngForm #form="ngForm">
        <div class="col-4">
            <mat-form-field class="w-100">
                <mat-label>Planning Ticket Number</mat-label>
                <input class="font-weight-bold" matInput [readonly]="true" [value]="record.planningTicketNumber">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="w-100">
                <mat-label>Customer</mat-label>
                <input matInput [readonly]="true" [value]="record.purchaseOrder.customer.businessName">
            </mat-form-field>
        </div>
        <div class="col-4 mb-2">
            <mat-form-field class="w-100">
                <mat-label>Customer PO Number</mat-label>
                <input matInput [readonly]="true" [value]="record.purchaseOrder.purchaseOrderNumber">
            </mat-form-field>
        </div>
        <div class="col-8">
            <employee-search [readonly]="!editing" label="Assigned To" [(ngModel)]="record.assignedUser" name="assignedUser"
                    [placeholder]="record.assignedUser ? undefined : 'Unassigned'" floatLabel="always"
                    (selectedItemChange)="record.assignedUserId = $event?.userId"></employee-search>
        </div>
        <div class="col-4 mb-2">
            <mat-form-field appearance="outline" class="w-100" tabindex="-1" floatLabel="always">
              <mat-label>Date Assigned</mat-label>
              <input type="text" matInput readonly [value]="record.dateAssigned ? (record.dateAssigned | date: 'YYYY/MM/dd HH:mm a') : null" placeholder="Unknown">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100" tabindex="-1" floatLabel="always">
              <mat-label>Due Date</mat-label>
              <input type="text" matInput readonly [value]="record.requiredDate ? (record.requiredDate| date: 'YYYY/MM/dd') : null" placeholder="Unknown">
            </mat-form-field>
        </div>
        <div class="col-4">
        <date-picker appearance="outline" class="w-100" placeholder="Planning Promise Date" [(ngModel)]="record.planningPromiseDate" name="promiseDate"
          [editable]="true" [readonly]="!editing"></date-picker>
        </div>
    </div>
</div>