<search-list-new
  matSort
  [defaultSort]="{
    active: 'orderNumber',
    direction: 'desc'
  }"
  [showManagerToggle]="showManagerToggle"
  [displayedColumns]="displayedColumns"
  [resultGetter]="search.bind(this)"
  (itemSelected)="onSelect($event)"
  [fieldSearchFields]="[
    { field: 'customer', code: 'customer' },
    { field: 'orderNumber', code: '#' },
    { field: 'customerRfqnumber', code: 'rfq' },
    { field: 'partNumber', code: 'pn' },
    { field: 'partNumber', code: 'part' },
    { field: 'assignedUser', code: 'user' },
    { field: 'assignedUser', code: 'assigned' }
  ]"
  [fieldSearchNames]="{
    customer: 'Customer',
    orderNumber: orderNumberTitle + ' #',
    customerRfqnumber: 'Customer RFQ#',
    partNumber: 'Part Number',
    assignedUser: 'Assigned To'
  }"
  [localStorageId]="localStorageId"
  [isManager]="isManager"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
  [groupableFields]="[null, { name: 'customer', title: 'Customer' }, { name: 'status', title: 'Status' }]"
  [alwaysManager]="alwaysManager"
>
  <ng-content select="[preSearchContent]" preSearchContent></ng-content>

  <ng-container matColumnDef="quoteNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header="quoteNumber" class="number-cell" style="flex-basis: 5%">Quote #</mat-header-cell>
    <mat-cell *matCellDef="let row" class="number-cell" style="flex-basis: 5%">
      <span class="font-weight-bold">
        {{truncateQuoteNumber(row.quoteNumber)}}
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 4%;">Created</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 4%">
      <span *ngIf="row.createdDate; else none">{{row.createdDate | date:'shortDate' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="received">
    <mat-header-cell *matHeaderCellDef mat-sort-header="received" style="flex-basis: 4%;">Received</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 4%;">
      <span *ngIf="row.receivedDate; else none">{{row.receivedDate | date:'shortDate' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="required">
    <mat-header-cell *matHeaderCellDef mat-sort-header="required" style="flex-basis: 4%;">Required</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 4%;">
      <span *ngIf="row.requiredDate; else none">{{row.requiredDate | date:'shortDate' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="estimatedEstimateCompletionDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="estimatedEstimateCompletionDate" style="flex-basis: 4%;">Est. Estimate Completion Date</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 4%;">
      <span [ngClass]="getHighlightClass(row.estimatedEstimateCompletionDate)" *ngIf="row.estimatedEstimateCompletionDate; else none">{{row.estimatedEstimateCompletionDate | date:'shortDate' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="estimateApprovalDate">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 4%;">Estimate Approved</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 4%">
      <span *ngIf="row.estimateApprovalDate; else none">{{row.estimateApprovalDate | date:'shortDate' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status" style="flex-basis: 8%;">Status</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="rfqApprovalDate">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 6%;">RFQ Approved</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 6%">
      <span *ngIf="row.rfqApprovalDate; else none">{{row.rfqApprovalDate | date:'shortDate' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="estimateLate">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 3%;">Late</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 3%">
      <span *ngIf="row.estimatedEstimateCompletionDate; else none">{{getDaysLate(row.estimatedEstimateCompletionDate) | number: '1.0-0'}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="daysToQuote">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 3%;">Days To Quote</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 3%">
      <span *ngIf="row.requiredDate && row.receivedDate; else none">{{getDaysBetween(row.receivedDate, row.requiredDate) | number: '1.0-0'}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="estimateProgress">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 6%;">Progress</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 6%; padding-right: 1em">
      <div *ngLet="getProgress(row) as progress" class="estimate-progress-bar">
        <ng-container *ngFor="let status of possibleProgressStatuses">
          <div *ngIf="progress[status] > 0"
            [ngClass]="getProgressColorClass(status)"
            [ngStyle]="{
              width: ((progress[status] / progress.total) * 100) + '%'
            }"
            matTooltipPosition="above"
            [matTooltip]="((progress[status] / progress.total) * 100 | number: '1.0-1') + '% ' + getProgressStatusText(status)"
          >&nbsp;
          </div>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="partHistory">
    <mat-header-cell *matHeaderCellDef mat-sort-header="partHistory" style="flex-basis: 8%">
      Part Priority
      <mat-icon class="ml-1" [matTooltip]="'Only the highest priority among parts in the ' + (orderNumberTitle === 'RFQ' ? orderNumberTitle : (orderNumberTitle | lowercase)) + ' is shown.'" matTooltipPosition="after">info</mat-icon>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <mat-chip class="button-text" [ngClass]="getQuoteHistoryChipClass(row.partHistory)">
        {{ getQuoteHistoryText(row.partHistory) }}
      </mat-chip>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef mat-sort-header="customer" style="flex-basis: 6%">Customer</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 6%">
      <div class="font-weight-medium mat-light-text customer-abbr">
        <div class="business-name" title="{{row.businessName}}">{{row.businessName}}</div>
        <span *ngIf="row.customerTotal; else none">${{row.customerTotal | shortNumber}}</span>
        <span *ngIf="row.customerPercentTotal; else none">{{row.customerPercentTotal | number:'1.2-2' }}%</span>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="hasIncomplete">
    <mat-header-cell *matHeaderCellDef style="flex-basis: 7%">
    &nbsp;
    </mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 7%">
      <mat-chip class="button-text bg-warning text-dark" *ngIf="row.reviewStatus === 1">
        Incomplete
      </mat-chip>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="assigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned" style="flex-basis: 8%">Assigned To</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 8%">
      <span *ngIf="row.assignedTo" class="d-flex align-items-center text-muted font-weight-bold" title="{{row.fullName}}">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.fullName }}</span>
      </span>
      <span *ngIf="!row.assignedTo" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>

  <ng-template #groupHeaderTemplate let-item>
    <mat-chip *ngIf="item.groupField === 'status'; else normalHeader" class="status button-text" disableRipple [ngClass]="getStatusColorClass(toNumber(item.groupName))">
      {{getStatusText(toNumber(item.groupName))}}
    </mat-chip>
    <ng-template #normalHeader>
      <span class="group-header">{{ item.groupName }}</span>
    </ng-template>
  </ng-template>
</search-list-new>

<ng-template #none>
  <span class="text-muted">—</span>
</ng-template>
