import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';

@Component({
  selector: 'employee-select',
  templateUrl: './employee-select.component.html',
  styleUrls: ['./employee-select.component.less']
})
export class EmployeeSelectComponent implements OnInit {

  public employees: User[];
  @Input() employee: User;
  @Output() employeeChange: EventEmitter<User> = new EventEmitter<User>();
  @Input() editable: boolean;
  @ViewChild('employeeInputText', { static: true }) inputTxt: ElementRef;
  @ViewChild('autoCompleteTrigger', { static: true }) autoCompleteTrigger: MatAutocompleteTrigger;
  private searching: boolean = false;

  constructor(private usrService: UserService) { }

  getEmployeeList(event: KeyboardEvent) {
    this.searching = true;
    this.usrService.search(this.inputTxt.nativeElement.value).subscribe(results => {
      this.searching = false;
      this.employees = results.results;
    });
  }

  toggleDropdown(): void {
    event.stopImmediatePropagation();
    if (this.autoCompleteTrigger.panelOpen) {
      this.autoCompleteTrigger.closePanel();
    }
    else {
      this.getEmployeeList(null);
      this.autoCompleteTrigger.openPanel();
    }
  }

  public testSelection(_: any): void {
    if (!this.employee) {
      this.inputTxt.nativeElement.value = "";
      return;
    }
    this.inputTxt.nativeElement.value = this.employee.fullName;
  }

  setEmployee(employee: any) {
    this.employee = employee;
    if (employee == null)
      return;

    this.employeeChange.emit(this.employee);
  }

  ngOnInit(): void {
  }
}
