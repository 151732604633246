import { Component, ElementRef, Inject, Injector, Input, OnInit, Output, TemplateRef, ViewChild, EventEmitter, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { GenericSearchComponent } from '../../../common/components/generic-search/generic-search.component';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Customer } from '../../../customer/resources/customer';
import { CustomerService } from '../../../customer/services/customer.service';
import { CustomerPurchaseOrder } from '../../../order/resources/customer-po';
import { Product } from '../../../order/resources/product';
import { OrderService } from '../../../order/services/order.service';
import { WorkOrder } from '../../../planning/resources/work-order';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { RMATicket } from '../../resources/rma-ticket';
import { RMAService } from '../../service/rma.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
  selector: 'rma-sales-detail',
  templateUrl: './rma-sales-detail.component.html',
  styleUrls: ['./rma-sales-detail.component.less']
})
export class RmaSalesDetailComponent implements OnInit {

  @Input() id: string;
  @Input() isQuality = false;
  public record: RMATicket;
  @Input() editing = false;
  public saving = false;
  public loading = false;
  public showEditor: string | null = null;
  public matchedPurchaseOrders: CustomerPurchaseOrder[] | null = [];
  public matchedWorkOrders: WorkOrder[] | null = [];

  public debitValueLoading = false;
  public debitValue: number = null;
  
  public uploadedPhotos: VirtualDocument[] = [];

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('partNumberSearch') partNumberSearch: PartNumberSearch;
  @ViewChild('shippingDetailsDialogTemplate', { static: true }) shippingDetailsDialogTemplate: TemplateRef<any>;
  @ViewChild('rejectReasonTemplate', { static: true }) rejectReasonTemplate: TemplateRef<any>;
  

  constructor(
    public rmaService: RMAService,
    public orderService: OrderService,
    public utilitySvc: UtilityService,
    public navService: NavigationService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public userSvc: UserService
  ) {
    if (!this.id) this.id = this.route.snapshot.paramMap.get("id");
  }

  public get canEdit(): boolean {
    return RMATicket.canEdit(this.record.status);
  }

  public get isValid(): boolean {
    if (!this.record.customerId || !this.record.quantity || !this.record.createdDate) return false;
    if (this.record.isNewDataInput) return !!(this.record.newDataPONumber && this.record.newDataWorkOrderNumber && this.record.newDataPartNumber)
    else return !!(this.record.productId && this.record.customerPurchaseOrderId && this.record.workOrderId);
  }

  public edit() {
    this.editing = true;
    if (this.isQuality && !this.record.qualityAssignee) {
      this.record.qualityAssignee = this.userSvc.userData;
      this.record.qualityAssigneeId = this.userSvc.userData?.userId;
    }
  }

  private getDetail(): void {
    this.record = null;
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;

      this.record = <RMATicket>{
        rmaTicketId: UtilityService.emptyGuid,
        status: 6,
        customer: null,
        documents: [],
        customerRejectionNumber: '',
        returnReason: '',
        notes: '',
        createdDate: new Date(),
        additionalRMAFee: 0,
        salesPerson: this.userSvc.userData,
        salesPersonId: this.userSvc.userData?.userId,
      };
      this.setPageTitle();
    } else {
      this.loading = true;
      this.rmaService.getDetail(this.id).subscribe((detail) => {
        this.record = detail;
        if (detail.workOrder) {
          this.matchedWorkOrders = [detail.workOrder];
        }
        if (detail.debit && !detail.debitOverride) this.getDebitValue();
        this.matchedPurchaseOrders = [detail.customerPurchaseOrder]
        this.setPageTitle();
        this.loading = false;
      });
    }
  }

  public async save() {

    if (!this.isValid) {
      this.utilitySvc.showAlert('Form Incomplete', 'Please make sure all required fields are filled out before saving.')
      return;
    }

    this.saving = true;
    const n = await this.rmaService.save(this.record).toPromise();
    this.saving = false;
    if (this.id === 'new') {
      this.id = n.rmaTicketId
      this.router.navigate(['/sales/rma', n.rmaTicketId])
    }
    this.editing = false;
    this.getDetail();
  }

  public async openDocumentsWindow() {
    if (!this.isValid) {
      this.utilitySvc.showAlert('Form Incomplete', 'Please make sure all required fields are filled out before adding a document.')
      return;
    }
    this.showEditor = 'document';
    this.sidenav.open()
  }

  public async addDocuments(documents: VirtualDocument[]) {
    if (!this.isValid) {
      this.utilitySvc.showAlert('Form Incomplete', 'Please make sure all required fields are filled out before adding a document.')
      return;
    }

    this.saving = true;
    const n = await this.rmaService.save(this.record).toPromise();
    if (this.id === 'new') {
      this.id = n.rmaTicketId
      this.router.navigate(['/sales/rma', n.rmaTicketId])
    }

    this.record = n;

    await this.rmaService.addDocuments(this.record, documents).toPromise();
    this.saving = false;
    this.getDetail();
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.rmaService.removeDocument(this.record, document).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  private setPageTitle(): void {
    this.navService.setPageTitle("RMA Detail");
    this.navService.pushBreadcrumb(
      this.record.rmaNumber || "New RMA"
    );
  }

  public async assignCustomer(c: Customer) {
    this.record.customerId = c.customerId;
    this.record.customer = c;
  }

  @ViewChild('purchaseOrderInput') purchaseOrderInput: ElementRef<HTMLInputElement>
  public onCustomerChange(customer: Customer, override = false) {
    if (this.record.customerId !== (customer && customer.customerId)) {
      this.record.customerPurchaseOrder = null;
      this.record.customerPurchaseOrderId = null;
      this.matchedPurchaseOrders = [];
      this.record.workOrder = null;
      this.record.workOrderId = null;
      this.matchedWorkOrders = [];
      if (this.purchaseOrderInput && this.purchaseOrderInput.nativeElement) {
        this.purchaseOrderInput.nativeElement.value = '';
      }
      if (customer) {
        this.record.customerId = customer.customerId;
        this.matchedPurchaseOrders = null
        this.orderService.getPOsForCustomer(customer.customerId).subscribe(pos => {
          this.matchedPurchaseOrders = pos;
        })
      } else {
        this.record.customerId = null;
      }
    }
  }

  public purchaseOrderSearch = '';
  public get filteredPurchaseOrders() {
    if (!this.matchedPurchaseOrders) return [];
    if (typeof this.purchaseOrderSearch != 'string') return [];
    if (!this.purchaseOrderSearch.trim()) return this.matchedPurchaseOrders;
    else return this.matchedPurchaseOrders.filter(p => p.purchaseOrderNumber.toLowerCase().includes(this.purchaseOrderSearch.toLowerCase()))
  }

  public onPurchaseOrderChange(po: CustomerPurchaseOrder) {
    this.record.customerPurchaseOrder = po;
    if (this.record.customerPurchaseOrderId !== (po && po.customerPurchaseOrderId)) {
      this.record.workOrder = null;
      this.record.workOrderId = null;
      this.matchedWorkOrders = [];
      if (po) {
        this.record.customerPurchaseOrderId = po.customerPurchaseOrderId;
        this.matchedWorkOrders = null
        this.orderService.getWOsForPO(po.customerPurchaseOrderId).subscribe(wos => {
          this.matchedWorkOrders = wos;
        })
      } else {
        this.record.customerPurchaseOrderId = null;
      }
    }
  }

  public setWorkOrder(wo: WorkOrder) {
    this.record.product = wo.product;
    this.record.productId = wo.productId;
    this.partNumberSearch.searchValue = wo.product.partNumber;
  }

  public async getDebitValue() {
    this.debitValueLoading = true;
    if (!this.record.workOrderId || !this.record.quantity) this.debitValue = null;
    else {
      const val = await this.rmaService.getDebitValue(this.record.workOrderId, this.record.quantity).toPromise();
      this.debitValue = val && parseFloat(val.toFixed(2));
    }
    this.debitValueLoading = false;
  }

  public onDebitParamChange() {
    console.log('debitParamChange');
    if (!this.record.debit) this.record.debitOverrideAmount = null; 
    this.getDebitValue();
  }

  public overrideDebitValue() {
    this.record.debitOverride = true;
    this.record.debitOverrideAmount = 0;
  }

  public removeDebitOverride() {
    this.record.debitOverrideAmount = null;
    this.record.debitOverride = false;
    this.onDebitParamChange();
  }

  @Output() approved = new EventEmitter<void>();
  public async approve() {
    this.approved.emit();
  }

  @Output() rejected = new EventEmitter<void>();
  public async reject() {
    this.rejected.emit();
  }

  public async close() {
    const r = await this.utilitySvc.showConfirmationPromise(
      'Are you sure?',
      `<p>Are you sure you want to permanently close this RMA request?</p><p>This cannot be undone.</p>`
      );
    if (!r) return;
    this.saving = true;
    this.record.status = 9;
    await this.rmaService.save(this.record).toPromise();
    this.saving = false;
    this.router.navigate(['/sales/rma']);
  }

  public async sendToQuality() {
    const r = await this.utilitySvc.showConfirmationPromise(
      'Are you sure?',
      `<p>The ticket will be sent to Quality, which will be in charge of approving or rejecting it.</p><p>This cannot be undone.</p>`
    );
    if (!r) return;
    this.saving = true;
    await this.rmaService.sendToQuality(this.record).toPromise();
    this.saving = false;
    this.getDetail();
  }

  public async markAsShipped() {
    const r = await this.utilitySvc.showConfirmationPromise(
      'Are you sure?',
      `<p>Are you sure you want to mark this RMA ticket as shipped by the customer?</p><p>This cannot be undone and the ticket cannot be edited afterwards.</p>`
      );
    if (!r) return;

    const ref = this.dialog.open(this.shippingDetailsDialogTemplate, {
      disableClose: true,
      width: '50vw',
      data: {
        shippingCarrier: null,
        shippingCarrierId: null,
        trackingNumber: '',
        shippingMethod: null,
        departureDate: null,
        arrivalDate: null,
        destinationAddress: null,
        destinationAddressId: null
      }
    });

    const result = await ref.afterClosed().toPromise();
    if (result === null) return;

    console.log(result);
    this.saving = true;
    await this.rmaService.setAsShipped(this.record, {
      destinationAddressId: result.destinationAddressId,
      trackingNumber: result.trackingNumber,
      arrivalDate: result.arrivalDate,
      departureDate: result.departureDate,
      shippingCarrierId: result.shippingCarrierId,
      shippingMethod: result.shippingMethod
    }).toPromise();
    this.saving = false;
    this.router.navigate(['/sales/rma']);

  }

  public getStatusColorClass(status: number): string {
    return RMATicket.getStatusBackgroundColorClass(status);
  }

  public getStatusText(status: number): string {
    return RMATicket.getStatusText(status);
  }

  public getLabel() {
    this.saving = true;
    this.rmaService.getCustomerLabel(this.record).subscribe(doc => {
      this.saving = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  public displayWithPO(po: CustomerPurchaseOrder) {
    return po && po.purchaseOrderNumber;
  }

  ngOnInit() {
    this.getDetail();
  }

  public openPhotosWindow(): void {
    this.showEditor = 'photo';
    this.sidenav.open();
  }

  public async addPhotos(photos: VirtualDocument[]) {
    if (!this.isValid) {
      this.utilitySvc.showAlert('Form Incomplete', 'Please make sure all required fields are filled out before adding a document.')
      return;
    }

    this.saving = true;
    const n = await this.rmaService.save(this.record).toPromise();
    if (this.id === 'new') {
      this.id = n.rmaTicketId
      this.router.navigate(['/sales/rma', n.rmaTicketId])
    }

    this.record = n;

    await this.rmaService.addDocuments(this.record, photos).toPromise();
    this.saving = false;
    this.getDetail();
  }
}

@Component({
  selector: 'part-number-search',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../common/components/generic-search/generic-search.component.less'],
})
export class PartNumberSearch extends GenericSearchComponent<Product> {

  placeholder = 'Part Number'

  constructor(private orderSvc: OrderService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.orderSvc.searchProducts(searchText)
  }

  public canAdd(): boolean { return false }

  public get addItemText(): string {
     return '';
  }

  public get noItemsText(): string {
    return 'No part numbers found.';
  }

  public getSearchField(p: Product): string {
    return p && p.partNumber;
  }
}
