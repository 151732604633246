import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DocumentService } from '../../../common/services/document.service';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { UtilityService } from '../../../common/services/utility.service';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less']
})
export class UploadFileComponent {
  files: any[] = [];
  @Input() sidenav: MatSidenav;
  @Output() documentsLoaded: EventEmitter<VirtualDocument[]> = new EventEmitter<VirtualDocument[]>();
  private documents: VirtualDocument[] = [];
  saving: boolean = false;
  public currentFileName: string = "Saving...";

  constructor(private documentService: DocumentService, private utilitySvc: UtilityService) { }

  addFile(event: any): void {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push({ document: element });
    }
  }

  removeFile(index: number): void {
    this.files.splice(index, 1)
  }

  cancelEditing(): void {
    if (this.files.length > 0) {
      this.utilitySvc.showConfirmation("Are you Sure?",
        "<p>You have documents selected that have not yet been uploaded.</p><p class='text-muted'>Canceling now will cause these documents to be lost.</p>",
        (r => {
          if (r == true) {
            if (this.sidenav)
              this.sidenav.close();
          }
        }));
    }
    else {
      if (this.sidenav)
        this.sidenav.close();
    }
  }

  doUploads(): void {
    this.saving = true;

    if (this.files.length > 0) {
      var file = this.files.pop();

      this.upload(file).subscribe(d => {
        this.documents.push(d);
        this.doUploads();
      });
    }
    else {
      this.saving = false;
      this.documentsLoaded.emit(this.documents);
      if (this.sidenav)
        this.sidenav.close();
    }
  }

  public get emptyFiles(): any[] {
    return this.files.filter(f => !f.document || f.document.size === 0);
  }

  private upload(file: any): Observable<VirtualDocument> {
    this.currentFileName = `Saving '${file.document.name}'`;
    var subject = new Subject<VirtualDocument>();
    this.documentService.upload(file.document)
      .subscribe(r => {
        subject.next(r);
      });

    return subject;
  }
}
