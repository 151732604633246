<loading *ngIf="saving" content="Saving..."></loading>
<mat-tab-group style="margin: -24px; max-width: calc(100% + 48px); min-height: 40vh;" (selectedTabChange)="selectedTabChange($event)">
  <mat-tab class="p-3" label="Purchased Item/Hardware">
      <div class="p-3">
      <purchased-item-part-number-select (onAddItem)="onAddItem()" [(selectedItem)]="item.purchasedItemPartNumber" (selectedItemChange)="setPurchased($event)"></purchased-item-part-number-select>
      </div>
  </mat-tab>
  <mat-tab class="p-3" label="Raw Material">
    <div class="p-3">
      <material-simple-search [(ngModel)]="item.material" (selectedItemChange)="setMaterial($event)"></material-simple-search>
    </div>
  </mat-tab>
  <mat-tab class="p-3" label="Outside Process">
    <div class="p-3">
      <po-outside-process-select *ngIf="!item.outsideProcess" (selected)="onOPSelected($event)"></po-outside-process-select>
      <po-outside-process-editor *ngIf="item.outsideProcess" [(ngModel)]="item.outsideProcess" (ngModelChange)="setOutsideProcess($event)"></po-outside-process-editor>
    </div>
  </mat-tab>
  <mat-tab class="p-3" label="Misc.">
    <div class="p-3">
      <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
        <mat-label>Misc. Line Item Name</mat-label>
        <input matInput required type="text" max="200"
          [(ngModel)]="item.misc" (ngModelChange)="itemChange.emit(item)" />
      </mat-form-field>
      <div class="text-dark p-2 d-flex w-100 align-items-center rounded bg-warning">
        <mat-icon class="mr-2 flex-shrink-0">warning</mat-icon>
        <span class="small flex-grow-1">Miscellaneous line items do not generate shipping tickets or pricing history. Only use this for extra charges for another line item, or for ancillary charges with no associated physical item.</span>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>


<ng-template #newPurchasedItemDialogTemplate let-data>
  <h2 mat-dialog-title>New Purchased Item</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Purchased Item Name</mat-label>
          <input matInput required placeholder="Purchased Item Name" type="text" max="100"
            [(ngModel)]="data.item.name" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100 mat-input-no-message">
          <mat-label>Vendor PN (optional)</mat-label>
          <input matInput placeholder="Vendor PN (optional)" type="text" max="100"
            [(ngModel)]="data.item.partNumber" />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button class="button-text" class="btn btn-default btn-sm mr-3" [matDialogClose]="null">Cancel</button>
    <button mat-flat-button class="button-text" [disabled]="!data.item.name" [matDialogClose]="data.item">Add Contact</button>
  </mat-dialog-actions>
</ng-template>