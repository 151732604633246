<loading *ngIf="saving" content="Saving..."></loading>

<div class="container-fluid">
  <a class="position-fixed fixed-bottom btn btn-badge bg-danger has-icon mr-6" title="Cancel" (click)="cancel()"><i class="fas fa-times"></i></a>
  <a class="position-fixed fixed-bottom btn btn-badge bg-success has-icon" title="Save" (click)="save()"><i class="fas fa-save"></i></a>

  <h2 class="text-primary">Split Ticket</h2>

  <div class="row form-lnline">
    <div class="col text-right">
      <span class="text-primary">New Ticket</span>
      <div>
        <input class="form-control form-control-sm" type="number" step="1" min="1" [max]="totalQuantityRequired" [(ngModel)]="newQuantity" />
      </div>
    </div>
    <div class="col">
      <span class="text-primary">This Ticket</span>
      <div class="font-weight-bold">
        {{remainingQty}}
      </div>
    </div>
  </div>
  <div class="row font-weight-bold text-muted">
    <div class="col text-right">
      Total:
    </div>
    <div class="col">
      {{totalQuantityRequired}}
    </div>
  </div>

</div>
