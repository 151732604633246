import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchData } from '../../common/components/search-list-new/search-list-new.component';
import { NavigationService } from '../../common/services/navigation.service';
import { UserService } from '../../common/services/user.service';
import { UtilityService } from '../../common/services/utility.service';
import { Order, OrderStatus } from '../../order/resources/order';
import { AccountingService } from '../services/accounting.service';
import { AccountReceivable } from '../resources/account-receivable';

@Component({
  selector: 'accounts-receivable-queue',
  templateUrl: './accounts-receivable-queue.component.html',
  styleUrls: ['./accounts-receivable-queue.component.less']
})
export class AccountsReceivableQueueComponent implements OnInit {

  constructor(private accountingService: AccountingService, navService: NavigationService, private router: Router, private userSvc: UserService) {
    navService.setPageTitle("Accounting");
    navService.pushBreadcrumb('Billing');
  }
  
  public loading = false;
  public results: any[] = [];
  public resultCount = 0;

  public search({ searchText, page, sort }: SearchData) {
    return this.accountingService.searchAccountsReceivable(searchText, page.pageIndex, page.pageSize, sort.active, sort.direction);
  }

  onSelect(record: AccountReceivable): void {
    this.router.navigate(['accounting/billing', record.accountReceivableId]);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public getInvoiceStatusColorClass(status: number) {
    return AccountingService.getStatusColorClass(status);
  }

  public getInvoiceStatusText(status: number) {
    return AccountingService.getStatusText(status);
  }

  public getOrderStatusColorClass(status: number): string {
    if (status === 0) return 'bg-warning text-dark';
    else if (status === 10) return 'bg-info text-white';
    if (status === 11) return 'bg-success text-white';
  }

  public getOrderStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

  ngOnInit(): void {
  }
}
