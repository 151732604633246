<div class="pt-3">
  <loading *ngIf="saving" content="Saving..."></loading>

  <div class="container-fluid">
    <div class="sortable-container">
      <ul class="sortable-list">
        <li *ngIf="!locationList">
          <p class="text-center">
            <i class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
          </p>
        </li>
        <li *ngIf="addressList">
          <button class="btn btn-sm btn-info" (click)="return()" [disabled]="loading || !addressList[addressList.length - 1]">
            <i class="fas fa-fw la-lg fa-arrow-left"></i>
            {{ parent ? parent.name : 'Root' }}
          </button>
        </li>
        <li *ngFor="let location of locationList" (click)="select(location)" [class.disabled]="loading">
          <div>
            <button class="btn btn-sm btn-danger" [disabled]="loading" (click)="delete(location)">Delete</button>
            <span>{{location.name}}</span>
            <span class="float-right small text-muted">{{location.use}}</span>
          </div>
        </li>
        <li>
          <button class="btn btn-sm btn-success" *ngIf="!adding" (click)="addNewLocation()" placeholder="Add location" [disabled]="loading">
            <i class="fas fa-fw la-lg fa-plus"></i>
            Add Location
          </button>
          <input *ngIf="adding" type="text" placeholder="Name" #locationInput />
          <input *ngIf="adding" type="text" placeholder="Notes (optional)" #notesInput />
          <select *ngIf="adding" #useInput>
            <option value="" selected="selected">Use (optional)</option>
            <option [value]="use" *ngFor="let use of uses">{{use}}</option>
          </select>
          <button class="btn btn-sm btn-success" *ngIf="adding" (click)="add()">Add</button>
          <button class="btn btn-sm btn-danger" *ngIf="adding" (click)="cancel()">Cancel</button>
        </li>
      </ul>
    </div>
    <div class="address-list-container">
      <ol class="address-list">
        <li><h4>Selected Address</h4></li>
        <li (click)="clear()"><button class="btn btn-sm btn-warning">Clear</button></li>
        <li *ngFor="let location of addressList">
          <div>
            <span (click)="select(location)" [ngClass]="{'change-parent': location.inventoryLocationId != lastParent.inventoryLocationId}">{{location.name}}</span>
          </div>
        </li>
        <li class="address-selection"></li>
      </ol>
    </div>
    <div class="details-container">
      <div class="form-group">
        <label>Quantity</label>
        <input type="number" placeholder="0" class="form-control form-control-sm" [(ngModel)]="quantity" />
      </div>
      <div class="form-group">
        <label>Safety Quantity</label>
        <input type="number" placeholder="0" class="form-control form-control-sm" [(ngModel)]="safetyQuantity" />
      </div>
      <div class="form-group">
        <label>Expiration Date</label>
        <input type="date" placeholder="1/1/9999" class="form-control form-control-sm" [ngModel]="expirationDate | date:'yyyy-MM-dd'" (ngModelChange)="expirationDate = $event"/>
      </div>
    </div>
  </div>

  <a class="btn btn-badge float-right bg-success has-icon" *ngIf="locationPanel" title="Save" (click)="save()"><i class="fas fa-save"></i></a>
  <a class="btn btn-badge float-right bg-danger has-icon mr-2" *ngIf="locationPanel" title="Close" (click)="close()"><i class="fas fa-times"></i></a>
</div>
