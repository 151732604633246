import { Component, ElementRef, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilityService } from './../../../common/services/utility.service';
import { InventoryItem } from '../../../inventory/resources/inventory-item';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { InventoryItemLocation } from '../../../inventory/resources/inventory-item-location';
import { InventoryService } from '../../../inventory/services/inventory.service';

@Component({
  selector: 'inventory-location-detail',
  templateUrl: './inventory-location-detail.component.html',
  styleUrls: ['./inventory-location-detail.component.less']
})
export class InventoryLocationDetailComponent implements OnInit {
  @Input() locationPanel: MatSidenav;
  @Input() item: InventoryItem;
  @Output() locationSelected = new EventEmitter<InventoryItemLocation>();
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
  @ViewChild('notesInput') notesInput: ElementRef<HTMLInputElement>;
  @ViewChild('useInput') useInput: ElementRef<HTMLInputElement>;
  private uses: string[] = ['Tooling', 'Raw Material', 'Inspection Tooling', 'Fixturing', 'Finished Goods', 'In Process Part', 'Backup Job Inventory', 'Hardware'];
  public adding: boolean;
  public previous: InventoryLocation | null = null;
  public currentLocation: InventoryLocation | null = null;
  public lastParent: InventoryLocation;
  public locationList: InventoryLocation[];
  public addressList: InventoryLocation[];
  public quantity: number;
  public safetyQuantity: number;
  public expirationDate: Date;
  public itemLocationId: string;
  public itemId: string;
  public saving: boolean = false;
  public loading = false;

  constructor(private service: InventoryService) { }

  addNewLocation() : void {
    this.adding = true;
  }

  add(): void {
    let newLocation: InventoryLocation;

    newLocation = <InventoryLocation>{
      inventoryLocationId: UtilityService.emptyGuid,
      name: this.locationInput.nativeElement.value,
      notes: this.notesInput.nativeElement.value,
      use: this.useInput.nativeElement.value ? this.useInput.nativeElement.value : null,
      parentId: this.lastParent ? this.lastParent.inventoryLocationId : null,
    }

    this.service.saveLocation(newLocation)
      .subscribe(data => {
        this.adding = false;
        this.locationList.push(data);
        this.locationInput.nativeElement.value = '';
      });
  }

  public get parent() {
    return this.addressList && this.addressList[this.addressList.length - 2]
  }

  return() {
    this.select(this.parent);
  }

  select(loc: InventoryLocation | null): void {
    this.previous = this.currentLocation;
    this.currentLocation = loc;
    this.lastParent = loc;
    this.loading = true;
    this.service.getLocationChildren(loc).subscribe(data => {
      this.locationList = data ? data : [];
      this.loading = false;
    });
    if (loc !== null) {
      this.service.getLocationParents(loc.inventoryLocationId).subscribe(data => {
        this.addressList = data;
      });
    }
  }

  setValues(itemLoc: InventoryItemLocation): void {
    this.itemLocationId = itemLoc.inventoryItemLocationId;
    this.itemId = itemLoc.inventoryItemId;
    this.quantity = itemLoc.quantity;
    this.safetyQuantity = itemLoc.safetyQuantity;
    this.expirationDate = itemLoc.expirationDate;
  }

  clear(): void {
    this.lastParent = null;
    this.addressList = [];
    this.itemLocationId = UtilityService.emptyGuid;
    this.service.getLocationChildren(null).subscribe(data => this.locationList = data);
  }

  reset(): void {
    this.clear();
    this.quantity = 0;
    this.safetyQuantity = null;
    this.expirationDate = null;
  }

  delete(loc: InventoryLocation): void {
    event.stopPropagation();
    this.service.deleteLocation(loc).subscribe(_ => {
      this.locationList = this.locationList.filter(listLoc => listLoc.inventoryLocationId !== loc.inventoryLocationId)
    });
  }

  cancel(): void {
    this.adding = false;
    this.locationInput.nativeElement.value = '';
  }

  close(): void {
    this.reset();
    this.itemLocationId = UtilityService.emptyGuid;
    this.locationPanel.close();
  }

  save(): void {
    let savedItemLocation: InventoryItemLocation;

    savedItemLocation = <InventoryItemLocation>{
      inventoryItemLocationId: this.itemLocationId,
      inventoryItemId: this.itemLocationId,
      inventoryLocationId: this.lastParent.inventoryLocationId,
      quantity: this.quantity,
      address: this.addressList,
      safetyQuantity: this.safetyQuantity,
      expirationDate: this.expirationDate,
    }

    this.locationSelected.emit(savedItemLocation);
    this.locationPanel.close();
  }

  ngOnInit(): void {
    this.addressList = [];
    this.quantity = 0;
    this.itemLocationId = UtilityService.emptyGuid;
    this.service.getLocationChildren(null).subscribe(data => this.locationList = data);
  }
}
