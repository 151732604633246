<microticket-list
    [relatedTicketId]="record.salesProcessId"
    [relatedTicketName]="service.nameForMicrotickets"
    [relatedTicketType]="'SalesProcess'"
    [filterKey]="service.microticketFilterKey"
    [subItems]="partNumbers"
    [existingDocuments]="documents"
    [subItemName]="'Associated Part/Section'"
    [subItemDisplayWith]="partNumberDisplayWith.bind(this)"
    [subItemCompareWith]="partNumberCompareWith.bind(this)"
    (loadingChange)="service.loading = $event"
    (jumpToSubitem)="jumpToSubitem($event)"
    [subject]="service.sharedMicroticketSubject"
></microticket-list>
