import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { OrderService } from '../../../order/services/order.service';
import { Order, OrderStatus } from '../../../order/resources/order';
import { SearchList } from '../../../common/components/search-list/search-list';
import { UtilityService } from '../../../common/services/utility.service';
import { UserService } from '../../../common/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Product, getQuoteHistoryChipClass, getQuoteHistoryText } from '../../resources/product';
import { PartReportOptionsDialog } from './part-report-options-dialog.component';
import { User } from '../../../common/resources/user';
import { FilterParam } from '../../../common/components/search-list-new/search-list-new.component';

@Component({
  selector: 'quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.less']
})
export class QuoteListComponent {
  constructor(private userSvc: UserService, private dialog: MatDialog, private service: OrderService) {
  }

  public userIsManager(user: User): boolean {
    return this.userSvc.canAccess('SalesManager', user);
  }

  public get isManager() { return this.userIsManager(this.userSvc.userData) }

  public readonly availableStatuses = [OrderStatus.AWAIT_ISSUANCE, OrderStatus.AWAIT_PO, OrderStatus.EXPIRED, OrderStatus.PO_AWARDED, OrderStatus.ON_ORDER, OrderStatus.CLOSED, OrderStatus.HISTORY] as const;
  public readonly filterParams: FilterParam[] = [{
    categoryName: 'status',
    categoryTitle: 'Status',
    options: this.availableStatuses.map(s => ({
      class: OrderStatus.getStatusColorClassChip(s),
      title: OrderStatus.getStatusText(s),
      value: s
    }))
  },  
  // {
  //   categoryName: 'partHistory',
  //   categoryTitle: 'Part Priority',
  //   options: [0, 1, 2, 3, 4, 100].map(s => ({
  //     class: getQuoteHistoryChipClass(s),
  //     title: getQuoteHistoryText(s),
  //     value: s
  //   })),
  // }
  ]
  public readonly defaultStatuses = [OrderStatus.AWAIT_ISSUANCE, OrderStatus.AWAIT_PO, OrderStatus.ON_ORDER] as const;
  public readonly defaultFilters = { status: this.defaultStatuses, 
    // partHistory: [] 
  }

  public loading = false;
  public async generateReport() {

    const dialogRef = this.dialog.open<PartReportOptionsDialog, any, {
      issuedFrom?: Date,
      issuedTo?: Date,
      selectedParts: Product[],
      sortBy: 'customer' | 'required'
    }>(PartReportOptionsDialog, {
      minWidth: 700,
    });
  
    const opts = await dialogRef.afterClosed().toPromise();
    if (!opts) return;
  
    const { issuedFrom, issuedTo, selectedParts } = opts;
  
    this.loading = true;
    this.service.getQuotePartHistory({
      issuedFrom,
      issuedTo,
      selectedParts
    }).subscribe(doc => {
      this.loading = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  
  }
}