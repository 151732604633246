import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../common/services/navigation.service';
import { StationService } from '../../../order/services/station.service';
import { Station } from '../../../order/resources/station';
import { UtilityService } from '../../../common/services/utility.service';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { OpSpecificationEditorComponent } from '../op-specification-editor/op-specification-editor.component';

@Component({
  selector: 'station-editor',
  templateUrl: './station-editor.component.html',
  styleUrls: ['./station-editor.component.less']
})
export class StationEditorComponent implements OnInit {
  public selectedStation: Station = null;
  public saving: boolean = false;
  @Input() sidenav: MatSidenav;
  @Output() saved = new EventEmitter<Station>()
  @ViewChild('name') name: NgModel;
  @ViewChild('cost') cost: NgModel;

  constructor(private navService: NavigationService, private stationSvc: StationService, private utilitySvc: UtilityService, private router: Router) {
    this.navService.clearBreadCrumbs();
    this.navService.pushBreadcrumb("Station Editor");
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
    else
      this.router.navigate(['/admin']);
  }

  public select(station: Station): void {
    this.selectedStation = station;
  }

  public hasExtendedData(station: Station): boolean {
    return Object.keys(station.extendedFields).length > 0;
  }

  public addMachineField(): void {
    this.selectedStation.extendedFields['New Field'] = 'text';
  }

  public removeMachineField(key: string): void {
    this.utilitySvc.showConfirmation("Remove Field?", "<p>Are you sure you want to remove this machine field?</p>", r => {
      if (r) {
        delete this.selectedStation.extendedFields[key];
      }
    });
  }

  public setNewKey(e: any, oldKey: string): void {
    const dType = this.selectedStation.extendedFields[oldKey];
    delete this.selectedStation.extendedFields[oldKey];

    this.selectedStation.extendedFields[e.target.value] = dType;
  }

  public assignType(key: string, e: any): void {
    this.selectedStation.extendedFields[key] = e.value;
  }

  public addStation(): void {
    this.selectedStation = <Station>{
      stationId: UtilityService.emptyGuid,
      name: 'New Station',
      extendedFields: {}
    };
  }


  @ViewChild(OpSpecificationEditorComponent) specEditor: OpSpecificationEditorComponent;
  public async save() {

    if (this.name.invalid) {
      this.name.control.markAsTouched();
      this.utilitySvc.showAlert("A Valid Station Name is Required", "<p>Please add a station name before saving.</p>");
      return;
    }
    if (this.cost.invalid) {
      this.utilitySvc.showAlert("A Cost is Required", "<p>Please add a valid number for the material cost before saving.</p>");
      return;
    }

    if (this.specEditor) {
      if (this.specEditor.addingNew?.trim()) {
        this.saving = true;
        await this.specEditor.saveNew(this.specEditor.addingNew);
      }
    }

    this.saving = true;
    
    this.stationSvc.save(this.selectedStation).subscribe(result => {
      this.selectedStation = result;
      this.saving = false;
      this.saved.emit();
    });
  }

  public canRemove(): boolean {
    return this.selectedStation != null && this.selectedStation.stationId != UtilityService.emptyGuid;
  }

  public get shouldShowMachineData(): boolean {
    return !(this.selectedStation.isOutsourceStep || this.selectedStation.isPurchasing || this.selectedStation.isShipping || this.selectedStation.isPainting);
  }

  public remove(): void {
    this.utilitySvc.showConfirmation(`Remove Station '${this.selectedStation.name}'?`, "<p>Are you sure you want to remove this station?</p><p class='text-muted'>Removing stations will impact ALL orders and workflows using this station.</p>", r => {
      if (r) {
        this.saving = true;
        this.stationSvc.remove(this.selectedStation.stationId).subscribe(_ => {
          this.selectedStation = null;
          this.saving = false;
        });
      }
    });
  }

  ngOnInit(): void {
  }
}
