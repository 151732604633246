<div class="position-relative">
  <span *ngIf="editable" class="position-absolute dropdown-indicator" (click)="toggleDropdown()"><i class="fas" [ngClass]="{'text-muted fa-chevron-down':!searching, 'fa-lg fa-circle-notch fa-spin text-info': searching}"></i></span>
  <input required #autoCompleteTrigger="matAutocompleteTrigger" tabindex="-1" (focusout)="testSelection($event)" #inputText matInput placeholder="Paint Name" [readonly]="!editable" (change)="setValue($event.value)" [ngClass]="{'form-control-plaintext':!editable}" [ngModel]="paint?.name" class="form-control form-control-sm" (keyup)="search($event)" type="text" max="100" [matAutocomplete]="paintList" />
  <mat-autocomplete [panelWidth]="900" class="paint" (opened)="search($event)" autoActiveFirstOption #paintList="matAutocomplete" (optionSelected)="setValue($event.option.value)">
    <mat-option *ngFor="let p of paints" [value]="p">
      <span class="swatch float-left" [style.background]="p.sampleColor"></span>
      <span class="name d-inline-block float-left">{{p.name}} <span class="small text-muted">({{getSize(p)}})</span></span>
      <span class="text-muted float-right">{{getSheen(p)}}</span>
    </mat-option>
    <mat-option *ngIf="paints.length==0">
      <button *ngIf="canAdd()" class="float-right btn btn-primary btn-sm mt-4" (click)="onAdd()">
        <i class="fas fa-fw fa-lg fa-plus"></i> Add New Paint
      </button>
      <p class="text-muted small text-center mt-3">
        <em>-- No Matches --</em>
      </p>
    </mat-option>
  </mat-autocomplete>
</div>
