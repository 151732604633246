import { OnInit, Directive } from '@angular/core';
import { SearchResult } from '../../../common/resources/search-result';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class SearchList<T> implements OnInit {
  public searchResults: SearchResult<T>;
  public results: T[];
  public searchString: string = "";
  public sortTerm: string = null;
  public sortDirection: string = null;
  protected pageNumber: number = 0;
  public managerView: boolean = false;

  constructor(public key: string) {  }

  protected abstract search(): void;

  //Resets page number and search text (if passed)
  public doSearch(newTerm?: string): void {
    this.pageNumber = 0;
    if (newTerm != null) this.searchString = newTerm;
    this.search();
  }

  public setManagerView(isManagerView: boolean): void {
    this.managerView = isManagerView;
    const key = this.getStorageKey();
    const existing = localStorage.setItem(key, JSON.stringify(isManagerView));
    this.doSearch();
  }

  public abstract onSelect(record: T): void;

  public getPage(page: number): void {
    this.pageNumber = page;
    this.search();
  }

  public sortBy(method: string): void {
    if (this.sortTerm != null && this.sortTerm == method) {
      this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
    }
    else {
      this.sortTerm = method;
      this.sortDirection = "asc";
    }

    this.search();
  }

  public getSortIconFor(method: string) {
    var value = "fas fa-fw";

    if (this.sortTerm == null) {
      value += " fa-sort";
    }
    else if (this.sortTerm == method) {
      if (this.sortDirection == "asc") {
        value += " fa-caret-up";
      }
      else {
        value += " fa-caret-down";
      }
    }

    return value;
  }

  private getStorageKey() {
    const routeName = this.key;
    return `managerView-${routeName}`;
  }


  ngOnInit(): void {
    const key = this.getStorageKey();
    const existing = localStorage.getItem(key);
    try {
      if (existing) {
        var existingVal = JSON.parse(existing);
        this.managerView = existingVal;
      }
    } catch (e) {
      localStorage.removeItem(key);
    }
    this.doSearch();
  }

}
