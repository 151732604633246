import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Station } from '../../../order/resources/station';
import { StationService } from '../../../order/services/station.service';
import { WorkflowStep, WorkflowStepType } from '../../../order/resources/workflow';
import { UtilityService } from '../../../common/services/utility.service';
import { Product, ProductRepairPlan } from '../../resources/product';

@Component({
  selector: 'workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.less']
})
/** station-list component*/
export class WorkflowListComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() steps: WorkflowStep[] = null;
  @Input() sortable: boolean = false;
  @Input() quantity: number = 1;
  @Input() repairPlan: ProductRepairPlan;
  @Output() repairPlanChange: EventEmitter<ProductRepairPlan> = new EventEmitter<ProductRepairPlan>();
  @Output() stepsChange: EventEmitter<WorkflowStep[]> = new EventEmitter<WorkflowStep[]>();
  @Output() selected: EventEmitter<WorkflowStep> = new EventEmitter<WorkflowStep>();
  @Output() delete: EventEmitter<WorkflowStep> = new EventEmitter<WorkflowStep>();
  @Output() added: EventEmitter<WorkflowStep> = new EventEmitter<WorkflowStep>();
  public selectedItem: WorkflowStep;
  public draggedIndex: number = -1;
  public onDragOverIndex: number = -1;
  private checkInterval: any = null;
  private stationList: Station[] = null;

  constructor(private stationService: StationService, private utilitySvc: UtilityService) { }

  public get firstQuantity() {
    if (this.product.parentAssemblyId && this.product.quantityAsChild) return this.product.quantityAsChild;
    if (this.product.quantitiesMap == null) return 1;

    var quantities = this.product.quantitiesMap;

    if (quantities.length == 0) return 1;

    return quantities[0].value;
  }

  public calculateCost(item: WorkflowStep): number {
    return WorkflowStep.calculateCostForQty(item, this.firstQuantity) / this.firstQuantity
  }

  public calculatePercentage(item: WorkflowStep): number {
    const targetSteps = (this.repairPlan && item.workflowId === this.repairPlan.workflowId) ? this.repairPlan.workflow.workflowSteps : this.steps;
    return this.calculateCost(item) / targetSteps.reduce((p, c) => { return p += this.calculateCost(c); }, 0);
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  private notifyChange(): void {
    if (!this.isRepair) {
      this.stepsChange.emit(this.steps)
    } else {
      this.repairPlanChange.emit(this.repairPlan);
    }
  }

  public selectItem(item: any) {
    this.selectedItem = item;
    this.selected.emit(this.selectedItem);

    //If we just selected, we're clearly not dragging...
    this.draggedIndex = -1;
    this.onDragOverIndex = -1;
  }

  public hasComparisonData(obj: any): boolean {
    var keys: string[] = Object.keys(obj);

    return keys.filter(k => k.startsWith("compareTo_")).length > 0;
  }

  public deleteItem(): void {
    const targetSteps = this.isRepair ? this.repairPlan.workflow.workflowSteps : this.steps;
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this workflow action?</p><p class='text-muted'>You'll need to add it back if you change your mind.</p>", r => {
      if (r) {
        const index = targetSteps.indexOf(this.selectedItem);
        this.delete.emit(targetSteps[index]);
        targetSteps.splice(index, 1);
        this.selectItem(null);
        this.notifyChange();
      }
    });
  }

  public moveUp() {
    const targetSteps = this.isRepair ? this.repairPlan.workflow.workflowSteps : this.steps;
    const index = targetSteps.indexOf(this.selectedItem);
    if (index === 0) {
      return;
    }
    this.swapElements(index, index - 1);
    this.notifyChange();
  }

  public moveDown() {
    const targetSteps = this.isRepair ? this.repairPlan.workflow.workflowSteps : this.steps;
    const index = targetSteps.indexOf(this.selectedItem);
    if (index === this.steps.length - 1) {
      return;
    }
    this.swapElements(index, index + 1);
    this.notifyChange();
  }

  public onDrop($event: any, index: number) {
    $event.stopImmediatePropagation();
    // index is of the element on which the item is dropped
    this.handleDrop(index);
    this.notifyChange();
  }

  public allowDrop($event: any, index: number) {
    $event.stopImmediatePropagation();
    // index is of the item on which the item is currently hovered
    this.onDragOverIndex = index;
    $event.preventDefault();
  }

  public onDragStart(_event: any, station: Station, index: number) {
    sessionStorage.setItem('station-list-drag-data', JSON.stringify(station));
    this.draggedIndex = index;

    if (this.checkInterval == null) {
      this.checkInterval = setInterval(_ => {
        if (sessionStorage.getItem('station-list-drag-data') == null) {
          this.draggedIndex = -1;
          this.onDragOverIndex = -1;
        }
      }, 500);
    }
  }

  public handleDrop(droppedIndex: number) {
    const targetWorkflowSteps = this.isRepair ? this.repairPlan.workflow.workflowSteps : this.steps;
    if (this.sortable) {
      var item = targetWorkflowSteps[this.draggedIndex];
      if (item == null) {
        var station = <Station>JSON.parse(sessionStorage.getItem('station-list-drag-data'));
        sessionStorage.removeItem('station-list-drag-data');

        item = <WorkflowStep>{
          workflowStepId: UtilityService.emptyGuid,
          stationId: station.stationId,
          runPrice: station.isOutsourceStep ? null : station.stdCostPerHour,
          runIsPerPart: station.perPart,
          isStandalone: station.isStandalone || false,
          isAdministrative: station.isAdministrative,
          isAssembly: station.isAssembly,
          hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
          setupTime: station.defaultSetupTime || 0,
          outsourceMarkup: station.isOutsourceStep ? UtilityService.defaultMarkup : null,
          stepOrder: droppedIndex,
          stepType: this.isRepair ? WorkflowStepType.Repair : WorkflowStepType.Standard
        };

        if (station.isPainting) {
          item.runTime = 1;
          item.runPrice = 0.065;
          item.paintCost = 0.125;
          item.paintMinPrice = 350;
        }

        this.added.emit(item);
      }
      else {
        targetWorkflowSteps.splice(this.draggedIndex, 1);
      }

      targetWorkflowSteps.splice(droppedIndex, 0, item);
      this.draggedIndex = -1;
      this.onDragOverIndex = -1;
      this.notifyChange();
    }
  }

  public swapElements(oldIndex: number, newIndex: number) {
    const targetSteps = this.isRepair ? this.repairPlan.workflow.workflowSteps : this.steps;
    const temp = targetSteps[oldIndex];
    targetSteps[oldIndex] = targetSteps[newIndex];
    targetSteps[newIndex] = temp;
  }

  private sortWorkflow(): void {
    this.steps = this.steps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
    if (this.repairPlan) this.repairPlan.workflow.workflowSteps = this.repairPlan.workflow.workflowSteps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
  }

  public get isRepair(): boolean {
    return !!this.repairPlan;
  }

  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }

    this.sortWorkflow();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.sortWorkflow();
  }

  public getSpecsString(item: WorkflowStep) {
    const allSpecs = this.getStation(item).outsideProcessSpecifications;
    const translatedSpecs = item.outsideProcessSpecifications.map(s => allSpecs.find(as => as.outsideProcessSpecificationId === s));
    return translatedSpecs.map(s => s.name).join(' / ')
  }
}
