<search-list-new
  matSort
  [defaultSort]="{
    id: 'dueDate',
    start: 'asc'
  }"
  [showManagerToggle]="true"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['type', 'number', 'subticket', 'required', 'assigned', 'status']"
  (itemSelected)="onSelect($event)"
  localStorageId="quality-queue"
  [isManager]="userIsManager.bind(this)"
  [filterParams]="filterParams"
  [defaultFilters]="defaultFilters"
>
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="text-white text-uppercase font-weight-bold"
        matTooltipPosition="above"
        disableRipple
        [ngClass]="getTypeColorClass(row.ticketType)"
      >
        <mat-icon class="mr-2">
          {{ getTypeIcon(row.ticketType) }}
        </mat-icon>
        <span>{{getTypeText(row.ticketType)}}</span>
      </mat-chip>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="number">
    <mat-header-cell *matHeaderCellDef mat-sort-header="ticket">Ticket #</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="font-weight-bold mat-light-text">
        {{row.qualityTicketNumber}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="subticket">
    <mat-header-cell *matHeaderCellDef mat-sort-header="subticket" style="flex-basis: 12%">Relevant Ticket</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-basis: 12%">
      <span class="font-weight-bold mat-light-text">
        {{getSubticketNumber(row)}}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="required">
    <mat-header-cell *matHeaderCellDef mat-sort-header="required">Required</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.dateRequired" [ngClass]="getHighlightClass(row.dateRequired)" class="p-1 rounded">
        {{row.dateRequired | date: 'shortDate' }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="assigned">
    <mat-header-cell *matHeaderCellDef mat-sort-header="assigned">Assigned To</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.assignedUser" class="d-flex align-items-center text-muted font-weight-bold">
        <mat-icon class="mr-1">person</mat-icon><span>{{ row.assignedUser.fullName }}</span>
      </span>
      <span *ngIf="!row.assignedUser" class="d-flex align-items-center text-danger font-weight-bold">
        <mat-icon class="mr-1">person_off</mat-icon><span>Unassigned</span>
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip class="status button-text" disableRipple [ngClass]="getStatusColorClass(row.status)">
        {{getStatusText(row.status)}}
      </mat-chip>
    </mat-cell>
  </ng-container>
</search-list-new>