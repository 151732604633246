import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../common/services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../../common/services/errorHandler.service'
import { SearchResult } from '../../common/resources/search-result';
import { httpService } from '../../common/services/http.service';
import { FloorAlert } from '../resources/floor-alert';
import { MachineAssignment } from '../../planning/resources/machine-assignment';

@Injectable({
  providedIn: 'root',
})
export class FloorAlertService extends httpService {
  private apiBase: string = 'api/flooralert';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Alerts";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  search(searchString?: string, pageNumber?: number, sortTerm?: string, sortDirection?: string): Observable<SearchResult<FloorAlert>> {
    return this.http.get<SearchResult<FloorAlert>>(this.apiUrl + '/search', { params: { searchText: searchString || "", pageIndex: (pageNumber || 0).toString(), orderBy: sortTerm||"name", direction: sortDirection||"asc", pageSize: "5" } }).pipe(
      catchError(this.handleError<any>("Get Alerts (Floor) Search Results", null))
    );
  }

  filter(searchString: string, pageNumber: number, sortTerm: string, sortDirection: string, buildingId: string, departmentId?: string, machineId?: string): Observable<SearchResult<FloorAlert>> {
    return this.http.get<SearchResult<FloorAlert>>(this.apiUrl + '/filter', { params: { searchText: searchString || "", pageIndex: pageNumber.toString(), orderBy: sortTerm, direction: sortDirection, pageSize: "5", 'buildingId': buildingId||"", 'departmentId': departmentId, 'machineId': machineId||"" } }).pipe(
      catchError(this.handleError<any>("Get Alerts (Floor) Search Results", null))
    );
  }

  getThisWeeksData(pageNumber: number): Observable<SearchResult<MachineAssignment>> {
    return this.http.get<SearchResult<MachineAssignment>>(this.apiUrl + '/thisWeek', { params: { pageIndex: pageNumber.toString()}}).pipe(
      catchError(this.handleError<any>("Get Current Week Schedule", null))
    );
  }
   
  getScheduledTasks(pageNumber: number, pageSize: number): Observable<SearchResult<MachineAssignment>> {
    return this.http.get<SearchResult<MachineAssignment>>(this.apiUrl + '/getScheduledTasks', { params: { pageIndex: pageNumber.toString(), pageSize:  pageSize.toString()} })
      .pipe(
        //tap(data => console.log('getScheduledTasks() - pageNumber= ' + pageNumber)),
        catchError(this.handleError<any>("Get Scheduled Tasks", null))
    );
  }

}
