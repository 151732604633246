import { Directive, Input, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from "@angular/forms";

@Directive({
  selector: '[maxValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }
  ]
})
export class MaxValidatorDirective implements Validator, OnChanges {

  @Input() maxValidator: number;
 
  constructor(private el: ElementRef<Element>) {
    
  }

  private maxOverride = false;
 
  ngOnChanges(changes: SimpleChanges) {
    const { nativeElement } = this.el;
    if (changes.maxValidator.isFirstChange()) {
      if (nativeElement && nativeElement.tagName === 'INPUT' && (nativeElement as HTMLInputElement).type === 'number') {
        if ((nativeElement as HTMLInputElement).max) {
          this.maxOverride = true;
        }
      }
    }
    if (this.maxOverride) return;
    if (nativeElement && nativeElement.tagName === 'INPUT' && (nativeElement as HTMLInputElement).type === 'number') {
      (nativeElement as HTMLInputElement).max = (this.maxValidator || 0).toString();
    }
  }
 
  validate(c: FormControl<number>): ValidationErrors | null {
 
    if (isNaN(this.maxValidator) || c.value > this.maxValidator) {
      return { max: true };
    }
    return null;
  }
}
