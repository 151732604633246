import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../../common/services/navigation.service';
import { RuleBuilder } from '../../resources/rule-builder';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RuleBuilderService } from '../../services/rule-builder.service';
import { SearchList } from '../../../common/components/search-list/search-list';


@Component({
  selector: 'rule-builder-list',
  templateUrl: './rule-builder-list.component.html',
  styleUrls: ['./rule-builder-list.component.less']
})
export class RuleBuilderListComponent extends SearchList<RuleBuilder>  {
  
  constructor(navService: NavigationService, private ruleBuilderService: RuleBuilderService, private router: Router) {
    super('RuleBuilderListComponent');
    navService.setPageTitle("Rule Builder");
  }

  protected search(): void {
    this.results = null;
    this.ruleBuilderService.search(this.searchString, this.pageNumber, this.sortTerm, this.sortDirection).subscribe(results => {
      this.searchResults = results;
      this.results = results.results
    }); 
  }


  public onSelect(record: RuleBuilder): void {
    this.router.navigate(['/rule-builder-detail', record.ruleId]);
  }

}
