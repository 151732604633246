import { WaitingOrdersWidgetComponent, OverdueOrdersWidgetComponent, AtRiskOrdersWidgetComponent } from './components'
import IWidgetData from '../interface';

const orderStatusWidgets: IWidgetData[] = [
  {
    id: 'waitingOrders',
    name: 'Waiting Orders',
    component: WaitingOrdersWidgetComponent,
    access: ["EstimateManager"],
    minHeight: 370,
    minWidth: 300,
  },
  {
    id: 'overdueOrders',
    name: 'Over Due Orders',
    component: OverdueOrdersWidgetComponent,
    access: ["EstimateManager"],
    minHeight: 370,
    minWidth: 300,
  },
  {
    id: 'atRiskOrders',
    name: 'At Risk Orders',
    component: AtRiskOrdersWidgetComponent,
    access: ["EstimateManager"],
    minHeight: 370,
    minWidth: 300,
  },
]

export default orderStatusWidgets;