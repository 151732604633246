import { Component, OnInit, Input } from '@angular/core';
import { WorkOrderOverview } from '../../../../planning/resources/work-order';
import { PurchasingSheetItem } from '../../../../purchasing/resources/purchasingSheet';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'oor-purchasing',
  templateUrl: './oor-purchasing.component.html',
  styleUrls: ['./oor-purchasing.component.less']
})
export class OorPurchasingComponent implements OnInit {

  @Input() record: WorkOrderOverview;

  public get prerequisites(): PurchasingSheetItem[] {
    return this.record?.purchasingSheet?.purchasingSheetItems ?? [];
  }

  public getQuantityAssigned(prereq: PurchasingSheetItem) {
    return prereq.assignments?.reduce((acc, a) => acc + a.qtyAssigned, 0) ?? 0;
  }

  public isFullyAssigned(prereq: PurchasingSheetItem) {
    return this.getQuantityAssigned(prereq) >= prereq.qty;
  }

  public expansionModel = new SelectionModel<string>(true); 

  constructor() { }

  ngOnInit(): void {
    // Expand all by default
    this.expansionModel.select(...this.prerequisites.filter(p => p.assignments.length > 0).map(p => p.purchasingSheetItemId));
  }

}
