import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FloorMachine } from '../../resources/building';
import { NavigationService } from '../../../common/services/navigation.service';
import { FloorService } from '../../services/floor.service';
import { MachineAssignment } from '../../../planning/resources/machine-assignment';
import { UtilityService } from '../../../common/services/utility.service';
import { MachineOperatorControlsComponent } from '../machine-operator-controls/machine-operator-controls.component';
import { OperatorTodayScheduleComponent } from '../../charts/operator-today-schedule/operator-today-schedule.component';

@Component({
  selector: 'operator-detail',
  templateUrl: './operator-detail.component.html',
  styleUrls: ['./operator-detail.component.less']
})
export class OperatorDetailComponent implements OnInit {
  @Input() machineId: string = null;
  @Input() sidenav: MatSidenav;
  @ViewChild('operatorControls') operatorControls: MachineOperatorControlsComponent;
  @ViewChild('operatorSchedule') operatorSchedule: OperatorTodayScheduleComponent;
  public record: FloorMachine = null;
  @Output() badMachine = new EventEmitter<void>();

  constructor(navService: NavigationService, private floorSvc: FloorService, private utilitySvc: UtilityService) {
    navService.pushBreadcrumb("Operator Assignments");
  }

  public close(): void {
    if (this.sidenav)
      this.sidenav.close();
  }

  public getData(): void {
    this.floorSvc.getMachine(this.machineId).subscribe(m => {
      if (!m) this.badMachine.emit();
      else this.record = m;
    });
  }

  public selectAssignment(chartClick: any): void {
    var assignmentId = this.operatorSchedule.chartData[chartClick.clickedElement[0]._datasetIndex].assignmentId;
    if (assignmentId == null) return;

    const assignment: MachineAssignment = this.operatorSchedule.rawChartData.find(d => d.assignmentId == assignmentId);
    if (assignment == null) return;

    this.utilitySvc.showConfirmation("Switch Work Order?", `Would you like to switch to Work Order ${assignment.workOrder.workOrderNumber}?`, r => {
      if (r) {
        this.record = null;
        this.record = assignment.machine;
        this.operatorControls.setAssignmentId(assignmentId);
      }
    })
  }

  public getDate(): Date {
    return new Date();
  }

  ngOnInit(): void {
    this.getData();
  }
}
