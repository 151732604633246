import { VirtualDocument } from "../../common/resources/virtual-document";
import { WorkOrder } from "../../planning/resources/work-order";
import { User } from "../../common/resources/user";
import { InventoryItem } from "../../inventory/resources/inventory-item";
import { PurchaseOrder } from "../../purchasing/resources/purchaseOrder";
import { InventoryItemLocation } from "../../inventory/resources/inventory-item-location";
import { Building } from "../../floor/resources/building";
import { RMATicket } from "../../rma/resources/rma-ticket";

export interface ShippingDocument {
  shippingTicketId: string;
  documentId: string;

  document?: VirtualDocument
}

export interface ShippingInventoryItem {
  shippingTicketInventoryItemId: string;
  shippingTicketId: string;
  inventoryItemLocationId: string;
  inventoryItemLocation: InventoryItemLocation;
  quantity: number;
}

export enum ShippingTicketStatus {
  AWAIT_WO = 0,
  UNASSIGNED = 1,
  IN_PROCESS = 2,
  PACKAGED = 3,
  SHIPPED = 4,
  RECEIVED = 5,
  VOID = 6
}

export enum ShippingClass {
    INBOUND,
    OUTGOING,
    INTERNAL,
}

export enum ShippingMethod {
  OTHER,
  FREIGHT_COLLECT,
  FREE_FREIGHT,
  PREPAY_ADD,
  SHIPPING_HANDLING_ONLY,
  WILL_CALL
}

export interface ShippingCarrier {
  shippingCarrierId: string,
  name: string
  trackingProvider: string;
  accountNumber: string;
}

export interface ShippingTicketPlaceLocation
{
    shippingTicketPlaceLocationId: string;
    shippingTicketId: string;
    inventoryItemLocationId: string;
    quantity: number;
    inventoryItemLocation: InventoryItemLocation;
    shippingTicket: ShippingTicket;
}

export interface ShippingTicket {
  shippingTicketId: string;
  workOrderId?: string;
  purchaseOrderId?: string;
  employeeId?: string;
  shippingBatchId?: string;
  shippingCarrierId: string;
  ticketNumber: number;
  trackingNumber?: string;
  notes: string;
  quantityRequired: number;
  departureDate?: Date;
  arrivalDate?: Date;
  requiredDate?: Date;
  inventoryItemId: string;
  receivedOrSentById: string;
  rmaTicketId?: string;
  legacyWorkOrderOrDeptCode?: string;

  readonly subticketNumber: string;
  
  originBuildingId?: string;
  destinationBuildingId?: string;
  originBuilding?: Building;
  destinationBuilding?: Building;
  rmaTicket?: RMATicket;

  workOrder: WorkOrder;
  purchaseOrder: PurchaseOrder;
  status: ShippingTicketStatus;
  shippingClass: ShippingClass;
  shippingMethod: string;
  employee: User;
  receivedOrSentBy?: User;
  shippingCarrier: ShippingCarrier;
  inventoryItem: InventoryItem;

  shippingTicketDocuments: ShippingDocument[];
  shippingTicketPickLocations: ShippingInventoryItem[];
  shippingTicketPlaceLocations: ShippingTicketPlaceLocation[];
}
