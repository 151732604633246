import { Component, Inject, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { debounceTime, filter, map, mergeMap, tap } from "rxjs/operators";
import { Product } from "../../resources/product";
import { OrderService } from "../../services/order.service";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'part-report-options-dialog',
  templateUrl: './part-report-options-dialog.component.html',
  styleUrls: ['./part-report-options-dialog.less']
})
export class PartReportOptionsDialog implements OnInit {

  constructor(private orderSvc: OrderService) {
  }

  public searchedParts: Observable<Product[]>;
  public filteredParts: Observable<Product[]>;

  public selectedParts: Product[] = [];

  public issuedFrom?: Date = null;
  public issuedTo?: Date = null;

  public searchControl = new FormControl<string>('');

  public loading = false;

  @ViewChild('chipInput', { static: true }) chipInput: ElementRef<HTMLInputElement>;

  selected(part: Product) {
    this.selectedParts = [...this.selectedParts, part];
    this.searchControl.setValue('');
    this.chipInput.nativeElement.value = '';
  }

  removePart(part: Product) {
    this.selectedParts = this.selectedParts.filter(p => p.productId !== part.productId);
  }

  ngOnInit() {
    
    this.searchedParts = this.searchControl.valueChanges.pipe(
        debounceTime(250),
        tap(() => {
            this.loading = true
        }),
        mergeMap(value => {
            this.loading = false;
            return this.orderSvc.searchProducts(value);
        }),
    );
    this.filteredParts = combineLatest([this.searchControl.valueChanges.pipe(filter(v => typeof v === 'string')), this.searchedParts]).pipe(
        map(([searchValue, parts]) => parts.filter(p => {
          return !(this.selectedParts.map(x => x.partNumber).includes(p.partNumber)) && searchValue && p.partNumber && p.partNumber.toLowerCase().includes(searchValue.toLowerCase());
        })),
    );
    this.searchControl.setValue('');

  }

}
