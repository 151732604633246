<div class="my-2 d-flex w-100 align-items-center">
    <div class="mat-card-title">Specifications</div>
    <button mat-stroked-button color="primary" class="ml-auto" (click)="startNew()">
        New Specification
    </button>
</div>
<div class="border rounded">
    <div class="border-bottom d-flex align-items-center p-2 mb-1" *ngIf="addingNew != null">
        <mat-form-field appearance="outline" class="flex-grow-1 dense-field mat-input-no-message" >
            <mat-label>Specification Name</mat-label>
            <input matInput autofocus type="text" [(ngModel)]="addingNew">
        </mat-form-field>
        <button mat-flat-button class="mat-square-button mx-2" color="accent" (click)="cancelEditing()">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-flat-button class="mat-square-button" color="primary" (click)="saveNew(addingNew)">
            <mat-icon>save</mat-icon>
        </button>
    </div>
    <div class="border-bottom d-flex align-items-center p-2 mb-1" *ngFor="let spec of specs">
        <mat-form-field appearance="outline" class="flex-grow-1 dense-field mat-input-no-message" *ngIf="isEditing(spec)">
            <mat-label>Specification Name</mat-label>
            <input matInput autofocus type="text" [(ngModel)]="spec.name">
        </mat-form-field>
        <div *ngIf="!isEditing(spec)" class="flex-grow-1">
            {{spec.name}}
        </div>
        <button mat-flat-button class="mat-square-button mx-2" color="accent" *ngIf="!isEditing(spec)" (click)="edit(spec)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-flat-button class="mat-square-button" color="warn" *ngIf="!isEditing(spec)" (click)="deleteSpec(spec)">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-flat-button class="mat-square-button mx-2" color="accent" *ngIf="isEditing(spec)" (click)="cancelEditing()">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-flat-button class="mat-square-button" color="primary" *ngIf="isEditing(spec)" (click)="saveEdit(spec)">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>