import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.less']
})
export class RatingComponent implements OnInit {
  @Input() max: number = 3;
  @Input() value: number;
  @Input() editable: boolean = false;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() selectedIconClass: string = "fas fa-fw fa-lg fa-star";
  @Input() notSelectedIconClass: string = "far fa-fw fa-lg fa-star";
  @Input() highlightClass: string = "text-primary";
  @Input() hoverClass: string = "text-muted";
  @Input() basicClass: string = "text-secondary see-thru";
  @Input() labels: string[] = ['One', 'Two', 'Three'];

  public ratingsArray: number[] = [];
  private hoverRating: number = -1;

  constructor() { }

  public isRatingSelected(n: number): boolean {
    return this.value > n;
  }

  public clearRating(): void {
    this.value = -1;
    this.valueChange.emit(this.value);
  }

  public select(n: number): void {
    if (!this.editable) return;

    this.value = n + 1;
    this.valueChange.emit(this.value);
  }

  public getTitle(n: number): string {
    return this.labels[n];
  }

  public getStyle(n: number): string[] {
    var appliedStyles: string[] = [];

    if (this.editable && this.hoverRating >= 0) {
      appliedStyles.push(this.hoverClass);
      if (n <= this.hoverRating)
        appliedStyles.push(this.selectedIconClass);
      else
        appliedStyles.push(this.notSelectedIconClass);
    }
    else {
      if (this.isRatingSelected(n)) {
        appliedStyles.push(this.selectedIconClass);
        appliedStyles.push(this.highlightClass);
      }
      else {
        appliedStyles.push(this.notSelectedIconClass);
        appliedStyles.push(this.basicClass);
      }
    }

    if (this.editable)
      appliedStyles.push('cursor-pointer');

    return appliedStyles;
  }

  public clearHover(): void {
    this.hoverRating = -1;
  }

  public setHover(n: number): void {
    this.hoverRating = n;
  }

  ngOnInit(): void {
    this.ratingsArray = Array.from(Array(this.max).keys());
  }
}
