import { AfterViewInit, Component, ElementRef, forwardRef, Inject, Injector, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Material } from '../../resources/material';
import { MaterialService } from '../../services/material.service';
import { GenericSearchComponent } from '../../../common/components/generic-search/generic-search.component';

const PAGESIZE = 20;

@Component({
  selector: 'material-simple-search',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../common/components/generic-search/generic-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialSimpleSearchComponent),
      multi: true
    }
  ]
})
export class MaterialSimpleSearchComponent extends GenericSearchComponent<Material> implements OnInit, AfterViewInit, ControlValueAccessor {

  constructor(private service: MaterialService, private dialog: MatDialog, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  @Input() placeholder = 'Material';

  doSearch(searchText: string) {
    return this.service.search(searchText || '', 0, PAGESIZE).pipe(
      map((v) => v.results)
    )
  }

  public canAdd(): boolean {
    return false;
  }

  public get addItemText(): string {
     return 'Add Material';
  }

  public get noItemsText(): string {
    return 'No materials found.';
  }


  public getSearchField(v: Material): string {
    return v && v.materialName;
  }

}
