import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { SearchResult } from '../../../common/resources/search-result';

@Component({
  selector: 'search-pager',
  templateUrl: './search-pager.component.html',
  styleUrls: ['./search-pager.component.less']
})

export class SearchPagerComponent implements OnChanges {
  @Input() results: SearchResult<any> = null;
  @Output() newPage: EventEmitter<number> = new EventEmitter<number>();

  public page = 0;
  public pages: number[] = [];
  public lastPage = 1;
  public canDoNext: boolean = false;
  public canDoPrev: boolean = false;
  public canDoLast: boolean = false;
  public canDoFirst: boolean = false;

  private pagerSize: number = 10;

  constructor() { }

  public clickPage(page: number): void {
    this.page = page;
    this.newPage.emit(page);
  }

  private getPages(): void {
    this.pages = [];
    this.canDoNext = false;
    this.canDoPrev = false;
    this.canDoLast = false;
    this.canDoFirst = false;

    if (this.results == null) return;

    this.page = this.results.pageIndex;
    var totalPages = Math.floor(this.results.resultCount / this.results.pageSize) + 1;
    this.lastPage = totalPages;

    this.canDoFirst = this.results.pageIndex >= 2;
    this.canDoPrev = this.results.pageIndex >= 1;
    this.canDoLast = this.results.pageIndex < totalPages - 2;
    this.canDoNext = this.results.pageIndex < totalPages - 1;

    if (totalPages <= this.pagerSize) {
      for (var i: number = 0; i < totalPages; i++) {
        this.pages.push(i);
      }
    }
    else {
      var start: number = this.results.pageIndex - this.pagerSize / 2;
      start = start < 0 ? 0 : start;
      var end: number = this.results.pageIndex + this.pagerSize / 2;
      end = end > totalPages ? totalPages : end;

      for (var i: number = start; i < end; i++) {
        this.pages.push(i);
      }
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.getPages();
  }
}
